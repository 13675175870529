import { Icon } from "@iconify/react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Titulo } from "../components/Titulo";
import { removeAccents } from "../utils/converter";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { TablaHead, TablaToolbar } from "../components/_dashboard/tablas";
import * as locales from "@material-ui/core/locale";
import Page from "../components/Page";
import Label from "../components/Label";
import AgregarEmpleado from "../components/AdministrarEmpleados/AgregarEmpleado";
import { Alerta } from "../components/Alerta";
import StoreIcon from "@material-ui/icons/Store";
import SucursalesEmpleado from "src/components/AdministrarEmpleados/SucursalesEmpleado";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

const TABLE_HEAD = [
  { id: "nombre", label: "Nombre Empleado", alignRight: false },
  { id: "apellidos", label: "Apellidos Empleado", alignRight: false },
  { id: "rut", label: "Rut Empleado", alignRight: false },
  { id: "telefono", label: "Teléfono", alignRight: false },
  { id: "correo", label: "Correo", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        removeAccents(_user.nombre.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_user.apellidos.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _user.rut.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.telefono.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.correo.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Empleados() {
  const {
    sucursal: sucursalObtenida,
    rol: rolObtenido,
    empleado: user,
  } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const sucursal = sucursalObtenida.refSucursal;
  const [showAlert, setShowAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRelacionSucursal, setOpenRelacionSucursal] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [elementoSeleccionado, setElementoSeleccionado] = useState(null);
  const [locale] = useState("esES");

  const {
    data: empleados = [],
    isLoading: loadingTable
  } = empleadosApi.useGetEmpleadosQuery(sucursal)

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = empleados.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - empleados.length) : 0;

  const filteredUsers = applySortFilter(
    empleados,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Grid container>
            <Grid item xs md></Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Administración de Empleados
              </Typography>
            </Grid>
            <Grid item xs md align="end">
              {rolObtenido === "SUPER_ADMIN" && (
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}>
                  Agregar Empleado
                </Button>
              )}
            </Grid>
          </Grid>
        </Titulo>

        <Card>
          <TablaToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {!loadingTable ? (
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={empleados.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, idx) => {
                        const {
                          nombre,
                          apellidos,
                          rut,
                          telefono,
                          correo,
                          estado,
                        } = row;
                        const isItemSelected = selected.indexOf(nombre) !== -1;

                        return (
                          <TableRow
                            hover
                            key={nombre + "_" + idx}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              onClick={() => {
                                if (rolObtenido === "SUPER_ADMIN") {
                                  navigate(`/dashboard/empleados/${rut}`);
                                }
                              }}
                              style={{ cursor: "pointer" }}>
                              <Typography variant="subtitle2" noWrap>
                                {nombre}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                if (rolObtenido === "SUPER_ADMIN") {
                                  navigate(`/dashboard/empleados/${rut}`);
                                }
                              }}
                              style={{ cursor: "pointer" }}>
                              {apellidos}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                if (rolObtenido === "SUPER_ADMIN") {
                                  navigate(`/dashboard/empleados/${rut}`);
                                }
                              }}
                              style={{ cursor: "pointer" }}>
                              {formatRut(rut, RutFormat.DASHS)}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                if (rolObtenido === "SUPER_ADMIN") {
                                  navigate(`/dashboard/empleados/${rut}`);
                                }
                              }}
                              style={{ cursor: "pointer" }}>
                              {telefono}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                if (rolObtenido === "SUPER_ADMIN") {
                                  navigate(`/dashboard/empleados/${rut}`);
                                }
                              }}
                              style={{ cursor: "pointer" }}>
                              {correo}
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (estado === "INACTIVO" && "error") ||
                                  "success"
                                }>
                                {sentenceCase(estado)}
                              </Label>
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                mb={5}>
                                {rolObtenido === "SUPER_ADMIN" && (
                                  <Tooltip title="Sucursales">
                                    <Button
                                      color="info"
                                      onClick={() => {
                                        setElementoSeleccionado(row);
                                        setOpenRelacionSucursal(true);
                                      }}>
                                      <StoreIcon />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={empleados.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      </Container>
      <Alerta
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        color="success"
        message="Datos del empleado guardados"
      />
      {open && (
        <AgregarEmpleado
          open={open}
          setOpen={setOpen}
          nombre=""
          apellidos=""
          rut=""
          telefono=""
          correo=""
          refRol=""
          estado=""
          fechaIngresoNueva={new Date()}
          sucursal={sucursal}
        />
      )}

      {openRelacionSucursal && (
        <SucursalesEmpleado
          open={openRelacionSucursal}
          setOpen={setOpenRelacionSucursal}
          empleadoDato={elementoSeleccionado}
        />
      )}
    </Page>
  );
}
