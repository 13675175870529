import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { Box, Button, Typography, Container } from "@material-ui/core";
// components
import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const user = useSelector((state) => state.auth.user);
  
  return (
    <RootStyle title="TAXTIC">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Lo sentimos, ¡Página no encontrada!
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              Lo sentimos, no pudimos encontrar la página que busca. Quizás no
              tengas acceso a ver la página o asegúrese de revisar la url
              ingresada
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button
              to={
                user
                  ? user.rol === "SUPER_ADMIN"
                    ? "/dashboard/estadisticas"
                    : "/dashboard/inicio"
                  : "/login"
              }
              size="large"
              variant="contained"
              component={RouterLink}
            >
              {user ? <p>Ir a Inicio</p> : <p>Ir a Iniciar Sesión</p>}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
