import React, { useCallback, useEffect, useState } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { saveAs } from "file-saver";
import { validateRut } from "@fdograph/rut-utilities";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Alert,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Skeleton,
  MenuItem,
  Select,
  Autocomplete,
  Grid,
  IconButton,
  Divider,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import {
  API_KEY_OPEN_FACTURA_DEV,
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_DEV,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
  URL_ORIGEN,
} from "../../utils/urlBack_End";
import { Box } from "@material-ui/system";
import { formatRut } from "@fdograph/rut-utilities";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";
import { ajustesApi } from "src/services/ajustesApi";
import { dteApi } from "src/services/dteApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import handleError from "src/utils/manejo-errores.js";
import { obtenerValorMoneda } from "src/utils/sharedFunctions";
import { AddCommentOutlined as IconoDescripcion } from "@material-ui/icons";

export default function FacturarDialog(props) {
  const { open, setOpen, tarea, monedas } = props;
  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const [cargandoAgregar, setCargandoAgregar] = useState(false);

  const [cargandoValorTipoMoneda, setCargandoTipoMoneda] = useState(false);
  const [cargandoEmpresa, setCargandoEmpresa] = useState(false);

  const [documentoTributario, setDocumentoTributario] = useState("34");

  const [tipoFacturacion, setTipoFacturacion] = useState("MismaEmpresa");

  const [tareaObtenida, setTareaObtenida] = useState(null);

  const [clienteSeleccionadoFactura, setClienteSeleccionadoFactura] =
    useState(null);
  const [informacionEmpresa, setInformacionEmpresa] = useState(null);

  const [descuentoTarea, setDescuentoTarea] = useState(0);
  const [totalTarea, setTotalTarea] = useState(0);
  const [totalPagarTarea, setTotalPagarTarea] = useState(0);
  const [tipoMonedaTarea, setTipoMonedaTarea] = useState(0);
  const [totalFacturado, setTotalFacturado] = useState(0);
  const [totalRestante, setTotalRestante] = useState(0);
  const [porcentaje, setPorcentaje] = useState("");

  const [valorTipoMonedaObtenida, setValorTipoMonedaObtenida] = useState(null);

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(null);

  const [mensajeError, setMensajeError] = useState(false);
  const [agregarDescripcionGlosa, setAgregarDescripcionGlosa] = useState(false);
  const [descripcionGlosaFactura, setDescripcionGlosaFactura] = useState("");
  const cantidadMaximaCaracteresDescripcionGlosa = 1000;

  const [montoFacturar, setMontoFacturar] = useState(0);
  const [montoPagado, setMontoPagado] = useState("NO");
  const [tipoEmpresa, setTipoEmpresa] = useState("EMPRESA EXISTENTE");
  const [metodoDePago, setMetodoDePago] = useState("");

  const [cargandoObtenerClienteNuevo, setCargandoObtenerClienteNuevo] =
    useState(false);
  const [rutClienteIngresado, setRutClienteIngresado] = useState("");

  const [rutInvalido, setRutInvalido] = useState(false);
  const [mensajeErrorRutInvalido, setMensajeErrorRutInvalido] = useState(null);
  const [mostrarDatosEmpresa, setMostrarDatosEmpresa] = useState(false);
  const [formatoPago, setFormatoPago] = useState("2");
  const [abono, setAbono] = useState("NO");
  const [glosaFactura, setGlosaFactura] = useState(tarea.nombre);
  const cantidadMaximaCaracteresGlosa = 80;

  const [actualizarEstadoFacturadoTarea] =
    tareasApi.useUpdateTareaFacturadaMutation();

  const [crearDTE] = dteApi.useCrearDTEMutation();
  const { data: comunas = [] } = ajustesApi.useGetComunasQuery();

  const handleChangeMontoPagado = (event) => {
    setMontoPagado(event.target.value);
  };

  const handleChangeTipoEmpresa = (event) => {
    setInformacionEmpresa(null);
    setRutClienteIngresado("");
    setMensajeErrorRutInvalido(null);
    setMostrarDatosEmpresa(null);
    setRutInvalido(false);
    setClienteSeleccionado(null);
    setComunaSeleccionada(null);
    setDireccionSeleccionada(null);
    setTipoEmpresa(event.target.value);
  };

  const handleChangeMetodoDePago = (event) => {
    setMetodoDePago(event.target.value);
  };

  const handleChangeDescripcionGlosa = (event) => {
    if (event.target.value.length <= cantidadMaximaCaracteresDescripcionGlosa) {
      setDescripcionGlosaFactura(event.target.value);
    }
  };

  const handleChangeGlosaFactura = (event) => {
    if (event.target.value.length <= cantidadMaximaCaracteresGlosa) {
      setGlosaFactura(event.target.value);
    }
  };

  const handleChangeRutClienteIngresado = (event) => {
    setRutClienteIngresado(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDocumentoTributario = (event) => {
    setDocumentoTributario(event.target.value);
  };

  const handleChangeFormatoPago = (event) => {
    setFormatoPago(event.target.value);
  };

  const handleChangeAbono = (event) => {
    setAbono(event.target.value);
  };

  const calcularRestante = useCallback(() => {
    const totalFacturaEnPesos = Math.round(
      totalPagarTarea * valorTipoMonedaObtenida
    );
    const restante = totalFacturaEnPesos - totalFacturado;

    setTotalRestante(restante);
  }, [totalFacturado, totalPagarTarea, valorTipoMonedaObtenida]);

  useEffect(() => {
    calcularRestante();
  }, [calcularRestante]);

  const calcularPorcentajeAbono = useCallback(() => {
    if (abono === "SI") {
      const valorDocumentoTributario = Math.round(
        totalRestante * (Number(porcentaje) / 100)
      );
      setMontoFacturar(valorDocumentoTributario);
    } else {
      setMontoFacturar(0);
      setPorcentaje("");
    }
  }, [abono, porcentaje, totalRestante]);

  useEffect(() => {
    calcularPorcentajeAbono();
  }, [calcularPorcentajeAbono]);

  const handleChangeTipoFacturacion = (event) => {
    if (event.target.value === "MismaEmpresa") {
      axios
        .get(
          URL_ORIGEN.includes("http://localhost:3000")
            ? `${API_OPEN_FACTURA_DEV}/v2/dte/taxpayer/${formatRut(
                tarea.refCliente
              )}`
            : `${API_OPEN_FACTURA_PROD}/v2/dte/taxpayer/${formatRut(
                tarea.refCliente
              )}`,
          {
            headers: {
              apikey: URL_ORIGEN.includes("http://localhost:3000")
                ? API_KEY_OPEN_FACTURA_DEV
                : API_KEY_OPEN_FACTURA_PROD,
            },
          }
        )
        .then((data) => {
          setInformacionEmpresa(data.data);
        })
        .finally(() => {
          setTipoFacturacion(event.target.value);
        });
    } else {
      setTipoFacturacion(event.target.value);
    }
  };

  const manejarClickDescripcion = () => {
    if (agregarDescripcionGlosa && descripcionGlosaFactura) {
      AlertaModal({
        title:
          "¿Estás seguro de que no quieres incluir la descripción? Los datos añadidos se eliminarán. ¿Deseas continuar?",
        fullText: true,
        confirmButtonText: "Sí",
        target: document.getElementById("dialogFactura"),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            setAgregarDescripcionGlosa(false);
            setDescripcionGlosaFactura("");
          }
        });
    } else {
      setAgregarDescripcionGlosa(!agregarDescripcionGlosa);
    }
  };

  const agregar = () => {
    setMensajeError(false);
    setCargandoAgregar(true);

    let stringMontoFacturar = String(montoFacturar);
    let montoFacturarAux = stringMontoFacturar.replaceAll(".", "");
    montoFacturarAux = stringMontoFacturar.replaceAll(",", "");

    if (glosaFactura === "" || glosaFactura === " ") {
      setMensajeError("Se debe ingresar la glosa de la factura.");
      setCargandoAgregar(false);
    } else if (
      abono === "SI" &&
      (porcentaje === 0 ||
        porcentaje === 0.0 ||
        porcentaje === "" ||
        porcentaje === " ")
    ) {
      setMensajeError("Asegúrese de colocar un porcentaje válido");
      setCargandoAgregar(false);
    } else if (
      parseFloat(montoFacturarAux) === 0.0 ||
      parseFloat(montoFacturarAux) === 0
    ) {
      setMensajeError("El monto debe ser mayor a 0");
      setCargandoAgregar(false);
    } else if (montoPagado === "SI" && metodoDePago === "") {
      setMensajeError("Se debe seleccionar el método de pago.");
      setCargandoAgregar(false);
    } else if (
      tipoFacturacion === "OtraEmpresa" &&
      (clienteSeleccionado === null ||
        !clienteSeleccionado.rut ||
        clienteSeleccionado.rut === null)
    ) {
      setMensajeError("Se debe seleccionar el cliente a facturar.");
      setCargandoAgregar(false);
    } else if (
      Number(montoFacturar) + totalFacturado >
      Math.round(totalPagarTarea * valorTipoMonedaObtenida)
    ) {
      setMensajeError(
        "El monto a pagar es mayor al total restante de la factura"
      );
      setCargandoAgregar(false);
    } else {
      let clienteAFacturar;
      if (tipoFacturacion === "OtraEmpresa") {
        clienteAFacturar = clienteSeleccionado.rut;
      } else {
        clienteAFacturar = tareaObtenida.refCliente;
      }

      montoFacturarAux = parseFloat(montoFacturarAux);

      let cerrarTarea = false;
      if (Number(montoFacturar) === Number(totalRestante)) {
        cerrarTarea = true;
      }

      AlertaModal({
        title: "generar el documento tributario",
        confirmButtonText: "Sí, generar",
        target: document.getElementById("dialogFactura"),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            const body = {
              refClienteReceptor: clienteAFacturar,
              montoFactura: montoFacturarAux,
              porcentajeIVA: "19",
              tipoDTE: 34,
              detalle: [
                {
                  nombreDetalle: glosaFactura,
                  cantidad: 1,
                  precio: montoFacturarAux,
                  monto: montoFacturarAux,
                  descripcion: descripcionGlosaFactura,
                },
              ],
              tipoFactura: "TAREA",
              formatoPago: formatoPago,
              montoPagado: montoPagado,
              metodoPago: metodoDePago,
              idTarea: tareaObtenida.id,
              sucursal: sucursalObtenida.refSucursal,
            };

            crearDTE(body)
              .unwrap()
              .then(async (response) => {
                if (response.error) {
                  AlertaToast({
                    action: "Error al generar el documento tributario",
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogFactura"),
                  }).fire();
                  setCargandoAgregar(false);
                } else {
                  const body = {
                    id: tarea.id,
                    cerrarTarea,
                  };
                  actualizarEstadoFacturadoTarea(body)
                    .then(() => {})
                    .catch((error) => {
                      handleError(
                        "Error al emitirFactura (FacturarDialog:316)",
                        error
                      );
                      AlertaToast({
                        action: "Error desconocido al emitir Factura",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogFactura"),
                      }).fire();
                      setCargandoAgregar(false);
                    })
                    .finally(() => {
                      setMensajeError(false);
                      saveAs(
                        "data:application/pdf;base64," + response.PDF,
                        documentoTributario +
                          "_" +
                          tareaObtenida.refCliente +
                          ".pdf"
                      );
                      AlertaToast({
                        element: "documento tributario",
                        action: "creado",
                      }).fire();
                      setCargandoAgregar(false);
                      setOpen(false);
                    });
                }
              })
              .catch((error) => {
                if (error.data?.message) {
                  AlertaToast({
                    action: `Error: ${error.data?.message}`,
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogFactura"),
                  }).fire();
                  setCargandoAgregar(false);
                } else {
                  AlertaToast({
                    action: "Error al generar el documento tributario",
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogFactura"),
                  }).fire();
                  setCargandoAgregar(false);
                }
              });
          } else {
            setCargandoAgregar(false);
          }
        });
    }
  };

  const handleChangeMontoFacturar = (event) => {
    setMontoFacturar(event.target.value);
  };
  const handleChangePorcentaje = (event) => {
    setPorcentaje(event.target.value);
  };

  const calcularTotalFacturasEmitidas = useCallback(() => {
    const sumFacturasEmitidas = tarea.facturas.reduce((total, factura) => {
      if (
        factura.estadoFactura !== "ANULADO" &&
        factura.estadoFactura !== "INCOBRABLE"
      ) {
        return total + factura.total;
      }
      return total;
    }, 0);

    setTotalFacturado(sumFacturasEmitidas);
  }, [tarea.facturas]);

  useEffect(() => {
    calcularTotalFacturasEmitidas();
  }, [calcularTotalFacturasEmitidas]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tareas/${tarea.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();

        const { data } = response;

        if (!data) {
          console.error("Error al obtener los datos de la tarea");
          throw Error("Error al obtener los datos de la tarea");
        }
        const { descuento, valorTarea, tipoMonedaTarea } = data;

        setDescuentoTarea(descuento);
        setTotalTarea(valorTarea);
        setTipoMonedaTarea(tipoMonedaTarea);

        const valorMonedaObtenida =
          tipoMonedaTarea === "PESOS"
            ? 1
            : obtenerValorMoneda(monedas, tipoMonedaTarea);

        if (!valorMonedaObtenida) {
          console.error("Error al obtener las monedas en Facturar Dialog");
          throw Error("Error al obtener las monedas");
        }

        setValorTipoMonedaObtenida(valorMonedaObtenida);

        setTotalPagarTarea(valorTarea - valorTarea * descuento * 0.01);
        setTareaObtenida(data);
      })
      .catch(() => {})
      .finally(() => {
        setCargandoTipoMoneda(true);
      });
  }, [monedas, tarea.id]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClientes(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/${tarea.refCliente}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClienteSeleccionadoFactura(response.data[0]);
      });
  }, [tarea.refCliente]);

  useEffect(() => {
    axios
      .get(
        URL_ORIGEN.includes("http://localhost:3000")
          ? `${API_OPEN_FACTURA_DEV}/v2/dte/taxpayer/${formatRut(
              tarea.refCliente
            )}`
          : `${API_OPEN_FACTURA_PROD}/v2/dte/taxpayer/${formatRut(
              tarea.refCliente
            )}`,
        {
          headers: {
            apikey: URL_ORIGEN.includes("http://localhost:3000")
              ? API_KEY_OPEN_FACTURA_DEV
              : API_KEY_OPEN_FACTURA_PROD,
          },
        }
      )
      .then((data) => {
        setCargandoEmpresa(true);
        setInformacionEmpresa(data.data);
      });
  }, [tarea.refCliente]);

  return (
    <Dialog id="dialogFactura" open={open} fullWidth maxWidth="md">
      <DialogTitle>Generar Documento Tributario</DialogTitle>
      <DialogContent>
        {!cargandoValorTipoMoneda || !cargandoEmpresa ? (
          <Skeleton variant="rectangular" width="100%" height={500} />
        ) : (
          <Stack style={{ marginTop: "10px" }} spacing={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Documento Tributario</FormLabel>
              <RadioGroup
                row
                value={documentoTributario}
                onChange={handleChangeDocumentoTributario}
              >
                <FormControlLabel
                  value="34"
                  control={<Radio />}
                  label="Factura Exenta"
                />
                {/*<FormControlLabel
                  value="Factura"
                  control={<Radio />}
                  label="Factura"
        />*/}
                {/*tarea.tipoTarea !== "ESPECIAL" && (
                  <React.Fragment>
                    <FormControlLabel
                      value="BoletaExenta"
                      control={<Radio />}
                      label="Boleta Exenta"
                    />
                    <FormControlLabel
                      value="Boleta"
                      control={<Radio />}
                      label="Boleta"
                    />

                </React.Fragment>


                )*/}
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel component="legend">Empresa a facturar</FormLabel>
              <RadioGroup
                row
                value={tipoFacturacion}
                onChange={handleChangeTipoFacturacion}
              >
                <FormControlLabel
                  value="MismaEmpresa"
                  control={<Radio />}
                  label="Facturar a la misma Empresa"
                />
                <FormControlLabel
                  value="OtraEmpresa"
                  control={<Radio />}
                  label="Facturar a otra empresa"
                />
              </RadioGroup>
            </FormControl>

            {tipoFacturacion === "OtraEmpresa" && (
              <React.Fragment>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo Cliente</FormLabel>
                  <RadioGroup
                    row
                    value={tipoEmpresa}
                    onChange={handleChangeTipoEmpresa}
                  >
                    <FormControlLabel
                      value="EMPRESA EXISTENTE"
                      control={<Radio />}
                      label="EMPRESA EXISTENTE"
                    />
                    <FormControlLabel
                      value="EMPRESA NO EXISTENTE"
                      control={<Radio />}
                      label="EMPRESA NO EXISTENTE"
                    />
                  </RadioGroup>
                </FormControl>
                {tipoEmpresa === "EMPRESA EXISTENTE" ? (
                  <Autocomplete
                    style={{ marginTop: "10px" }}
                    value={clienteSeleccionado}
                    options={clientes}
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        axios
                          .get(
                            URL_ORIGEN.includes("http://localhost:3000")
                              ? `${API_OPEN_FACTURA_DEV}/v2/dte/taxpayer/${formatRut(
                                  newValue.rut
                                )}`
                              : `${API_OPEN_FACTURA_PROD}/v2/dte/taxpayer/${formatRut(
                                  newValue.rut
                                )}`,
                            {
                              headers: {
                                apikey: URL_ORIGEN.includes(
                                  "http://localhost:3000"
                                )
                                  ? API_KEY_OPEN_FACTURA_DEV
                                  : API_KEY_OPEN_FACTURA_PROD,
                              },
                            }
                          )
                          .then((data) => {
                            setInformacionEmpresa(data.data);
                          })
                          .finally(() => {
                            setClienteSeleccionado(newValue);
                          });
                      } else {
                        setClienteSeleccionado(null);
                        axios
                          .get(
                            URL_ORIGEN.includes("http://localhost:3000")
                              ? `${API_OPEN_FACTURA_DEV}/v2/dte/taxpayer/${formatRut(
                                  tarea.refCliente
                                )}`
                              : `${API_OPEN_FACTURA_PROD}/v2/dte/taxpayer/${formatRut(
                                  tarea.refCliente
                                )}`,
                            {
                              headers: {
                                apikey: URL_ORIGEN.includes(
                                  "http://localhost:3000"
                                )
                                  ? API_KEY_OPEN_FACTURA_DEV
                                  : API_KEY_OPEN_FACTURA_PROD,
                              },
                            }
                          )
                          .then((data) => {
                            setInformacionEmpresa(data.data);
                          })
                          .finally(() => {
                            setClienteSeleccionado(newValue);
                          });
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          "& > span": { mr: "10px", fontSize: 18 },
                        }}
                        {...props}
                      >
                        {option.nombre} {option.apellidos}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Seleccionar Cliente"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                ) : (
                  <div>
                    <Grid container>
                      <Grid item xs={10} md={10}>
                        <Stack>
                          <TextField
                            value={rutClienteIngresado}
                            onChange={handleChangeRutClienteIngresado}
                            style={{ marginRight: "20px" }}
                            fullWidth
                            label="Rut"
                            variant="outlined"
                          />
                          {rutInvalido && (
                            <Alert severity="error">
                              {mensajeErrorRutInvalido}
                            </Alert>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <LoadingButton
                          loading={cargandoObtenerClienteNuevo}
                          style={{ marginLeft: "20px", marginTop: "10px" }}
                          onClick={() => {
                            setCargandoObtenerClienteNuevo(true);
                            if (
                              !validateRut(rutClienteIngresado.toLowerCase())
                            ) {
                              setMensajeErrorRutInvalido("Rut Inválido");
                              setRutInvalido(true);
                              setCargandoObtenerClienteNuevo(false);
                            } else {
                              setRutInvalido(false);

                              axios
                                .get(
                                  URL_ORIGEN.includes("http://localhost:3000")
                                    ? `${API_OPEN_FACTURA_DEV}/v2/dte/taxpayer/${formatRut(
                                        rutClienteIngresado.toLowerCase()
                                      )}`
                                    : `${API_OPEN_FACTURA_PROD}/v2/dte/taxpayer/${formatRut(
                                        rutClienteIngresado.toLowerCase()
                                      )}`,
                                  {
                                    headers: {
                                      apikey: URL_ORIGEN.includes(
                                        "http://localhost:3000"
                                      )
                                        ? API_KEY_OPEN_FACTURA_DEV
                                        : API_KEY_OPEN_FACTURA_PROD,
                                    },
                                  }
                                )
                                .then((data) => {
                                  let datosObtenidosEmpresa = data.data;
                                  setInformacionEmpresa(data.data);
                                  setMostrarDatosEmpresa(true);
                                  setComunaSeleccionada(
                                    datosObtenidosEmpresa.comuna
                                  );
                                  setDireccionSeleccionada(
                                    datosObtenidosEmpresa.direccion
                                  );
                                  setClienteSeleccionado({
                                    comentarios: "",
                                    comuna: datosObtenidosEmpresa.comuna,
                                    contacto: "",
                                    contrasena: "",
                                    correo: datosObtenidosEmpresa.email,
                                    direccion: datosObtenidosEmpresa.direccion,
                                    estado: "",
                                    fechaIngreso: "",
                                    fechaTermino: "",
                                    grupo: null,
                                    nombre: datosObtenidosEmpresa.razonSocial,
                                    rut: datosObtenidosEmpresa.rut,
                                    telefono: datosObtenidosEmpresa.telefono,
                                  });
                                })
                                .catch(() => {
                                  setMensajeErrorRutInvalido(
                                    "Error al obtener los datos."
                                  );
                                })
                                .finally(() => {
                                  setCargandoObtenerClienteNuevo(false);
                                });
                            }
                          }}
                          variant="contained"
                        >
                          Buscar
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    {informacionEmpresa &&
                      !rutInvalido &&
                      mostrarDatosEmpresa && (
                        <React.Fragment>
                          <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12} md={12}>
                              <TextField
                                value={informacionEmpresa.razonSocial}
                                disabled
                                style={{ marginRight: "20px" }}
                                fullWidth
                                label="Razón Social"
                                variant="filled"
                              />
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={6} md={6}>
                              <TextField
                                disabled={
                                  informacionEmpresa.direccion ? true : false
                                }
                                value={direccionSeleccionada}
                                onChange={(eve) => {
                                  clienteSeleccionado.direccion =
                                    eve.target.value;
                                  setDireccionSeleccionada(eve.target.value);
                                }}
                                style={{ marginRight: "20px" }}
                                fullWidth
                                label="Dirección"
                                variant={
                                  informacionEmpresa.direccion
                                    ? "filled"
                                    : "outlined"
                                }
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              {informacionEmpresa &&
                              informacionEmpresa.comuna ? (
                                <TextField
                                  disabled
                                  value={informacionEmpresa.comuna}
                                  style={{ marginRight: "20px" }}
                                  fullWidth
                                  label="Dirección"
                                  variant="filled"
                                />
                              ) : (
                                <Autocomplete
                                  disablePortal
                                  options={comunas}
                                  value={comunaSeleccionada}
                                  isOptionEqualToValue={(option, value) => {
                                    return option.nombre === value;
                                  }}
                                  getOptionLabel={(option) =>
                                    option.nombre ? option.nombre : option
                                  }
                                  onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                      clienteSeleccionado.comuna =
                                        newValue.nombre;
                                      setComunaSeleccionada(newValue.nombre);
                                    }
                                  }}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Seleccione Comuna"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )}
                  </div>
                )}
              </React.Fragment>
            )}

            <TextField
              label="Glosa Factura"
              value={glosaFactura}
              fullWidth
              variant="outlined"
              onChange={handleChangeGlosaFactura}
              helperText={`${glosaFactura.length}/${cantidadMaximaCaracteresGlosa} caracteres`}
              FormHelperTextProps={{ style: { textAlign: "right" } }}
              InputProps={{
                endAdornment: (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <Tooltip title="Agregar descripción glosa">
                      <IconButton
                        color="primary"
                        sx={{ p: "10px" }}
                        aria-label="directions"
                        onClick={manejarClickDescripcion}
                      >
                        <IconoDescripcion />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              }}
            />
            {agregarDescripcionGlosa && (
              <TextField
                label="Descripción glosa"
                value={descripcionGlosaFactura}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                onChange={handleChangeDescripcionGlosa}
                helperText={`${descripcionGlosaFactura.length}/${cantidadMaximaCaracteresDescripcionGlosa} caracteres`}
                FormHelperTextProps={{ style: { textAlign: "right" } }}
              />
            )}

            <Stack direction="row" spacing={2}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount1">
                  Total Tarea
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount1"
                  value={totalTarea}
                  disabled
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Total Tarea"
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField disabled label="Descuento" value={descuentoTarea} />
              </FormControl>
            </Stack>

            <Stack direction="row" spacing={2}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount1">
                  Total a Pagar
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount1"
                  value={totalPagarTarea}
                  disabled
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Total a Pagar"
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  disabled
                  label="Tipo Moneda"
                  value={tipoMonedaTarea}
                />
              </FormControl>
              {valorTipoMonedaObtenida && (
                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    disabled
                    label="Valor Tipo Moneda"
                    value={valorTipoMonedaObtenida}
                  />
                </FormControl>
              )}
            </Stack>

            {tipoMonedaTarea !== "PESOS" && valorTipoMonedaObtenida && (
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount2">
                  {`${tipoMonedaTarea} a Pesos`}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount2"
                  disabled
                  value={Math.round(totalPagarTarea * valorTipoMonedaObtenida)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label={`${tipoMonedaTarea} a Pesos`}
                />
              </FormControl>
            )}

            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount2">
                Total Facturado
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount2"
                disabled
                value={totalFacturado}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Total Facturado"
              />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount2">
                Total restante
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount2"
                disabled
                value={totalRestante}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Total Facturado"
              />
            </FormControl>

            <Grid container alignItems="center" justifyContent="center">
              <FormControl component="fieldset">
                <FormLabel component="legend">¿Abonar porcentaje?</FormLabel>
                <RadioGroup row value={abono} onChange={handleChangeAbono}>
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {abono === "SI" && (
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount2">
                  Porcentaje total restante
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount2"
                  value={porcentaje}
                  type="number"
                  onChange={handleChangePorcentaje}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  label="Total Facturado"
                />
              </FormControl>
            )}

            {tipoMonedaTarea !== "PESOS" && valorTipoMonedaObtenida ? (
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount2">
                  Monto Documento Tributario
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount3"
                  disabled={
                    !(
                      totalFacturado <=
                      totalPagarTarea * valorTipoMonedaObtenida
                    ) || abono === "SI"
                  }
                  value={montoFacturar}
                  type="number"
                  onChange={handleChangeMontoFacturar}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Monto Documento Tributario"
                />
              </FormControl>
            ) : (
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amoun3">
                  Monto Documento Tributario
                </InputLabel>
                <OutlinedInput
                  autoFocus
                  id="outlined-adornment-amount3"
                  type="number"
                  disabled={
                    !(totalFacturado <= totalPagarTarea) || abono === "SI"
                  }
                  value={montoFacturar}
                  onChange={handleChangeMontoFacturar}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Monto Documento Tributario"
                />
              </FormControl>
            )}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Forma de Pago
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formatoPago}
                label="Forma de Pago"
                onChange={handleChangeFormatoPago}
              >
                <MenuItem value={"1"}>Contado</MenuItem>
                <MenuItem value={"2"}>Crédito</MenuItem>
                <MenuItem value={"3"}>Sin costo (entrega gratuita)</MenuItem>
              </Select>
            </FormControl>

            {/* <FormControl component="fieldset">
              <FormLabel component="legend">¿Documento Pagado?</FormLabel>
              <RadioGroup
                row
                value={montoPagado}
                onChange={handleChangeMontoPagado}
              >
                <FormControlLabel value="SI" control={<Radio />} label="SI" />
                <FormControlLabel value="NO" control={<Radio />} label="NO" />
              </RadioGroup>
            </FormControl>

            {montoPagado === "SI" && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  MÉTODO DE PAGO
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={metodoDePago}
                  label="MÉTODO DE PAGO"
                  onChange={handleChangeMetodoDePago}
                >
                  <MenuItem value="">SELECCIONAR MÉTODO DE PAGO</MenuItem>
                  <MenuItem value="EFECTIVO">EFECTIVO</MenuItem>
                  <MenuItem value="TRANSFERENCIA">TRANSFERENCIA</MenuItem>
                  <MenuItem value="TARJETA">TARJETA</MenuItem>
                  <MenuItem value="WEBPAY">WEBPAY</MenuItem>
                </Select>
              </FormControl>
            )} */}
          </Stack>
        )}
      </DialogContent>
      {mensajeError && (
        <Box width="100%">
          <Alert severity="error">{mensajeError}</Alert>
        </Box>
      )}
      <DialogActions>
        <LoadingButton onClick={handleClose} loading={cargandoAgregar}>
          Cancelar
        </LoadingButton>
        <LoadingButton
          onClick={agregar}
          autoFocus
          loading={cargandoAgregar}
          variant="contained"
        >
          Generar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
