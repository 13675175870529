import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import AppTablaDetalleDistribucionClientes from "src/components/estadisticas/app-tabla-detalle-distribucion-clientes";
import PropTypes from "prop-types";

const ModalDetalleDivisionClientes = ({
  isOpen,
  setOpen,
  title,
  detalleDivisionclientes,
  cargandoDetalleDivisionClientes,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-modal-detalle-division-clientes"
      id="dialog-modal-detalle-division-clientes"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle htmlFor="dialog-modal-detalle-division-clientes">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <AppTablaDetalleDistribucionClientes
              loading={cargandoDetalleDivisionClientes}
              title="Detalle clientes"
              rows={
                !cargandoDetalleDivisionClientes && detalleDivisionclientes
                  ? detalleDivisionclientes
                  : []
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ModalDetalleDivisionClientes.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  detalleDivisionclientes: PropTypes.array,
  cargandoDetalleDivisionClientes: PropTypes.bool,
};

export default ModalDetalleDivisionClientes;
