// Converts a string representing a date in format year/day/month to format

import moment from "moment/moment";

// day/month/year
export const convertYearMonthDayToDayMonthYear = (str) => {
  if (str === "") {
    return "00/00/0000";
  } else {
    const splitted = str.split("/");
    let day = splitted[0];
    let month = splitted[1];
    let year = splitted[2];

    if (day.length === 1) day = "0" + day;
    if (month.length === 1) month = "0" + month;

    return `${year}/${month}/${day}`;
  }
};

// Converts a string representing a date in format day/month/year to format
// year/day/month
export const convertDayMonthYearToYearMonthDay = (str) => {
  if (str === "") {
    return "0000/00/00";
  } else {
    const splitted = str.split("/");
    let day = splitted[0];
    let month = splitted[1];
    let year = splitted[2];

    if (day.length === 1) day = "0" + day;
    if (month.length === 1) month = "0" + month;

    return `${year}/${month}/${day}`;
  }
};

export const formatDate = (str) => {
  if (!str) return "";
  const splitted = str.split("/");
  let year = splitted[0];
  let month = splitted[1];
  let day = splitted[2];

  if (day.length === 1) day = "0" + day;
  if (month.length === 1) month = "0" + month;

  return `${year}/${month}/${day}`;
};

export const reverseStr = (str) => {
  if (!str) return;
  return str.split("").reverse().join("");
};

export const toPascalCase = (str) =>
  str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");

export const toTitleCase = (str) => {
  return (
    str
      .replace(/([A-Z])/g, " $1")
      .charAt(0)
      .toUpperCase() + str.replace(/([A-Z])/g, " $1").slice(1)
  );
};

export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function calcularDiasHastaHoy(fecha) {
  let fechaHoy = new Date();
  let fechaInicial =
    fechaHoy.getFullYear() +
    "/" +
    (fechaHoy.getMonth() + 1) +
    "/" +
    fechaHoy.getDate();

  let fechaActual = moment(fechaInicial);
  let fechaFinal = moment(fecha);
  return fechaActual.diff(fechaFinal, "days");
}

export function calcularDiasEntreFechas(fechaInicial, fechaFinal) {
  if (fechaFinal) {
    const formatosFecha = ["DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"];
    const fechaInicio = moment(fechaInicial, formatosFecha);
    const fechaTermino = moment(fechaFinal, formatosFecha);
    const diferenciaDias = fechaTermino.diff(fechaInicio, "days");

    return diferenciaDias;
  } else {
    return null;
  }
}

export const calcularDiasTranscurridos = (
  fechaInicio,
  fechaTermino,
  estado
) => {
  let fechaActual;
  let fechaTarea;
  if (estado === "TERMINADA") {
    fechaTarea = new Date(
      parseInt(fechaInicio.split("/", 3)[0]),
      parseInt(fechaInicio.split("/", 3)[1]) - 1,
      parseInt(fechaInicio.split("/", 3)[2])
    );
    fechaActual = new Date(
      parseInt(fechaTermino.split("/", 3)[0]),
      parseInt(fechaTermino.split("/", 3)[1]) - 1,
      parseInt(fechaTermino.split("/", 3)[2])
    );
  } else {
    fechaActual = new Date();
    fechaTarea = new Date(
      parseInt(fechaInicio.split("/", 3)[0]),
      parseInt(fechaInicio.split("/", 3)[1]) - 1,
      parseInt(fechaInicio.split("/", 3)[2])
    );
  }

  const dias = Math.trunc(
    (fechaActual.getTime() - fechaTarea.getTime()) / (1000 * 3600 * 24)
  );
  if (dias === 1) {
    return dias;
  } else {
    return dias;
  }
};

export const calcularDiasLimites = (fechaInicio, fechaLimite) => {
  if (fechaLimite === null || fechaLimite === "") {
    return "";
  } else {
    let fechaLimiteAUX;
    let fechaTarea;
    fechaLimiteAUX = new Date(fechaLimite);
    fechaTarea = new Date(fechaInicio);

    const dias = Math.trunc(
      (fechaLimiteAUX.getTime() - fechaTarea.getTime()) / (1000 * 3600 * 24)
    );

    if (dias === 1) {
      return dias + " Día";
    } else if (dias === 0) {
      return dias + " Día";
    } else if (dias < 0) {
      return "0";
    } else {
      return dias + " Días";
    }
  }
};
