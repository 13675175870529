import React, { useState, useEffect, useRef } from "react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {
  createTheme,
  ThemeProvider,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Container,
  TextField,
  Stack,
  Fab,
  Button,
} from "@material-ui/core";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../Scrollbar";
import { TablaHead } from "../_dashboard/tablas";
import { filter } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { API_SERVER } from "../../utils/urlBack_End";
import axios from "axios";
import plusFill from "@iconify/icons-eva/plus-fill";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useNavigate } from "react-router-dom";
import { formatRut } from "@fdograph/rut-utilities";
import AgregarSocio from "src/components/gestionCliente/AgregarSocio";
import AgregarClavesPortales from "src/components/gestionCliente/AgregarClavesPortales";

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "1rem",
    color: theme.palette.primary.main,
  },
  textfield: {
    width: "100%",
  },
  grid: {
    xs: 12,
    alignItems: "center",
    justify: "center",
    marginBottom: "1rem",
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
    marginTop: 12,
  },
}));

const TABLE_HEAD = [
  { id: "usuario", label: "Nombre Usuario", alignRight: false },
  { id: "clave", label: "Contraseña", alignRight: false },
  { id: "institucion", label: "Institución", alignRight: false },
];

const TABLE_HEAD2 = [
  { id: "rutSocio", label: "Rut Socio", alignRight: false },
  { id: "nombreSocio", label: "Nombre Socio", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ClavesCliente(props) {
  const { tarea, setShowAlert, setColor, setMessage } = props;
  const [clavesPortales, setClavesPortales] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");
  const classes = styles();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cliente, setCliente] = useState(null);
  const [archivoObtenido, setArchivoObtenido] = useState(null);
  const [open, setOpen] = useState(false);
  const [sociosEmpresas, setSociosEmpresas] = useState([]);
  const [openAgregarSocio, setOpenAgregarSocio] = useState(false);
  const isMounted = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/clavesPortales/${tarea.refCliente}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setClavesPortales(response.data);
          let clave = response.data.find(
            (datos) => datos.institucion === "SITAX"
          );

          if (clave !== undefined) {
            axios
              .post(
                `${API_SERVER}/clientes/clavesPortales/sitax`,
                {
                  clave: clave.clave,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then(function (response) {
                if (isMounted.current) {
                  setPassword(response.data);
                }
              });
          }
        }
      });
  }, [tarea.refCliente]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/socios/${tarea.refCliente}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setSociosEmpresas(response.data);
        }
      });
  }, [tarea.refCliente]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/${tarea.refCliente}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setCliente(response.data);
        }
      });
  }, [tarea.refCliente]);

  useEffect(() => {
    if (cliente) {
      axios
        .get(`${API_SERVER}/clientes/poder/${cliente.rut}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          if (isMounted.current) {
            setArchivoObtenido(response.data[0]);
          }
        });
    }
  }, [tarea.refCliente, cliente]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clavesPortales.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - clavesPortales.length)
      : 0;

  const filteredUsers = applySortFilter(
    clavesPortales,
    getComparator(order, orderBy),
    ""
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handlePassword = (clave) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{showPassword ? password : "**************"}</Grid>
          <Grid item>
            <IconButton onClick={handleShowPassword} edge="end">
              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Container>
      <RootStyle>
        {cliente && cliente.rut && (
          <Card
            className={classes.root}
            variant="outlined"
            sx={{ padding: "1rem" }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Grid container>
                  <Grid item xs={2} md={2}>
                    <Typography variant="p">Poder</Typography>
                  </Grid>

                  <Grid item xs={10} md={10}>
                    {archivoObtenido && archivoObtenido.urlPoder ? (
                      <Grid
                        container
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={10}>
                          <Fab
                            style={{ width: "90%" }}
                            variant="extended"
                            size="medium"
                            color="primary"
                            aria-label="add"
                            onClick={() => {
                              window.open(archivoObtenido.urlPoder, "_blank");
                            }}
                          >
                            DESCARGAR PODER
                          </Fab>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography variant="p">
                        No existe poder para descargar
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      value={cliente.rut}
                      label="Rut Cliente"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      value={cliente.nombre}
                      label="Nombre Cliente"
                      variant="outlined"
                      multiline
                      rows={2}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      value={cliente.correo}
                      label="Correo Cliente"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      value={cliente.correoFacturacion}
                      label="Correo facturación"
                      variant="outlined"
                      error={cliente.correoFacturacion ? false : true}
                      helperText={cliente.correoFacturacion ? null : "Falta correo de facturación"}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      value={cliente.contacto}
                      label="Contacto o Representante Legal"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      value={cliente.telefono}
                      label="Teléfono Cliente"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </CardContent>
          </Card>
        )}

        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                >
                  Socios
                </Typography>
              </Grid>
              <Grid item xs={12} align="end">
                <Button
                  onClick={() => {
                    setOpenAgregarSocio(true);
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Agregar Socio
                </Button>
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TablaHead headLabel={TABLE_HEAD2} />
                  <TableBody>
                    {sociosEmpresas.map((row, idx) => {
                      const { rutCliente, nombreCliente } = row;

                      return (
                        <TableRow
                          hover
                          key={rutCliente + nombreCliente + idx}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">
                            {formatRut(rutCliente)}
                          </TableCell>
                          <TableCell align="left">{nombreCliente}</TableCell>
                          <TableCell align="left">
                            <Button
                              aria-label="delete"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + rutCliente);
                              }}
                            >
                              <VisibilityIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </CardContent>
        </Card>

        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                >
                  Claves Portales
                </Typography>
              </Grid>
              <Grid item xs={12} align="end">
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Agregar Claves
                </Button>
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={clavesPortales.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const { usuario, clave, institucion } = row;
                        const isItemSelected = selected.indexOf(usuario) !== -1;

                        return (
                          <TableRow
                            hover
                            key={usuario + clave + institucion}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{usuario}</TableCell>
                            <TableCell align="left">
                              {institucion === "SITAX"
                                ? handlePassword(clave)
                                : clave}
                            </TableCell>
                            <TableCell align="left">{institucion}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          No hay claves que mostrar.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <ThemeProvider
              theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={clavesPortales.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          </CardContent>
        </Card>
        {openAgregarSocio && (
          <AgregarSocio
            open={openAgregarSocio}
            setOpen={setOpenAgregarSocio}
            setSocios={setSociosEmpresas}
            refCliente={tarea.refCliente}
            setShowMessage={setShowAlert}
            setColor={setColor}
            setMessage={setMessage}
          />
        )}

        {open && (
          <AgregarClavesPortales
            open={open}
            setOpen={setOpen}
            setClavesPortales={setClavesPortales}
            refCliente={tarea.refCliente}
            setShowAlert={setShowAlert}
            setColor={setColor}
            setMessage={setMessage}
          />
        )}
      </RootStyle>
    </Container>
  );
}
