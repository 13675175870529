import { memo } from "react";
import { Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import FormProrrateo from "./FormProrrateo";

const ModalProrrateo = memo(
  ({ open, setStateModal, data, editar, statesModal }) => {
    return (
      <Dialog
        open={open}
        aria-labelledby="dialog-prorrateo"
        id="dialogProrrateo"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={styles.title} htmlFor="dialog-prorrateo">
          {data.sucursal
            ? `Modificar prorrateo: ${data.sucursal.nombre}`
            : "Ingresar nuevo prorrateo"}
        </DialogTitle>
        <DialogContent>
          <Divider />
          <FormProrrateo
            data={data}
            editar={editar}
            setStateModal={setStateModal}
            statesModal={statesModal}
          />
        </DialogContent>
      </Dialog>
    );
  }
);

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default ModalProrrateo;
