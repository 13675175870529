import { DialogActions, Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { prorrateosApi } from "src/services/prorrateosApi";
import * as Yup from "yup";
import AutoCompleteField from "../sharedFields/AutocompleteField";
import { useCallback, useState } from "react";
import { LoadingButton } from "@material-ui/lab";
import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { facturasApi } from "src/services/facturasApi";
import { AlertaToast } from "src/components/AlertaSwall";

const FormEnlaceMasivoSucursal = ({
  data,
  setOpen,
  statesModal,
  setIndiceCargandoEnlaceSucursal,
  setSelectedRows,
}) => {
  const { data: sucursales = [], isLoading: isLoadingSucursales } =
    prorrateosApi.useObtenerSucursalesConProrrateoQuery();
  const [enlazarFacturasASucursalMasivamente] =
    facturasApi.useEnlazarFacturasASucursalMasivamenteMutation();

  const [cargando, setCargando] = useState(false);

  const ProrrateoSchema = Yup.object().shape({
    sucursal: Yup.object().when("tipoFactura.id", {
      is: "PLAN",
      then: Yup.object()
        .shape({
          id: Yup.number().required(),
          nombre: Yup.string().required(),
        })
        .test(
          "tareaRequerida",
          "La tarea es requerida",
          (value) => value !== null
        ),
      otherwise: Yup.object().nullable(true),
    }),
  });

  const formik = useFormik({
    initialValues: {
      sucursal: !isLoadingSucursales ? sucursales[0] : null,
    },
    validationSchema: ProrrateoSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const guardarDatos = async () => {
    setCargando(true);

    const body = {
      facturas: data,
      sucursal: values.sucursal.nombre,
    };
    const { data: respuestaQuery, error } =
      await enlazarFacturasASucursalMasivamente(body);

    if (error) {
      console.error(error);
      AlertaToast({
        action: 'Error al enlazar facturas',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialog-enlace-sucursal'),

      }).fire()
    } else if (respuestaQuery) {
      AlertaToast({
        element: "Enlace masivo",
        action: "añadido correctamente",
      }).fire();
      setSelectedRows([]);
      handleClose();
    }

    setCargando(false);
  };

  const { values, touched, errors, handleSubmit, isSubmitting, setFieldValue } =
    formik;

  const handleSucursalSelection = useCallback(
    (event, newValue) => {
      setFieldValue("sucursal", newValue);
    },
    [setFieldValue]
  );

  const handleOptionLabelSucursal = useCallback((option) => option.nombre, []);

  const handleIsOptionEqualToValueSucursal = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  const handleClose = () => {
    setOpen({
      ...statesModal,
      abrirModal: false,
    });
    setIndiceCargandoEnlaceSucursal(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={1} marginBottom={1}>
          <AutoCompleteField
            disableClearable={true}
            id="autocomplete-sucursal"
            label="Seleccione sucursal"
            value={values.sucursal}
            xs={12}
            handleSucursalSelection={handleSucursalSelection}
            options={!isLoadingSucursales ? sucursales : []}
            touched={touched.sucursal}
            error={errors.sucursal}
            getOptionLabel={handleOptionLabelSucursal}
            isOptionEqualToValue={handleIsOptionEqualToValueSucursal}
            typeRenderInput="loading"
            isLoading={isLoadingSucursales}
            disabled={isLoadingSucursales}
          />
        </Grid>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cancelar
          </LoadingButton>
          <LoadingButton
            type="submit"
            disabled={isSubmitting || !formik.isValid}
            startIcon={<Icon icon={saveFill} />}
            color="primary"
            loading={cargando}
          >
            Enlazar
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default FormEnlaceMasivoSucursal;
