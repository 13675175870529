import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import arrowFill from "@iconify/icons-eva/arrow-back-fill";
import PropTypes from "prop-types";
import {
  Stack,
  Container,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import axios from "axios";
import Page from "../components/Page";
import VerPropuestaPlanes from "src/components/verpropuesta/VerPropuestaPlanes";
import { API_SERVER } from "../utils/urlBack_End";
import VerPropuestaDatosSolicitante from "src/components/verpropuesta/VerPropuestaDatosSolicitante";
import VerPropuestaDatos from "src/components/verpropuesta/VerPropuestaDatos";
import VerPropuestaArchivos from "src/components/verpropuesta/VerPropuestaArchivos";
import Page404 from "./Page404";
import EmpleadosAsociadosPropuesta from "src/components/verpropuesta/EmpleadosAsociadosPropuesta";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <label>{children}</label>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VerPropuesta() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [propuesta, setPropuesta] = useState({});
  const [existe, setExiste] = useState(1);
  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/propuestas/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        let propuestaAux = response.data;
        setPropuesta(propuestaAux);

        axios
          .get(`${API_SERVER}/empleados/empleadoPropuesta/${propuestaAux.id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((data) => {
            if (data.data === -2) window.location.reload();
            if (data.data && Array.isArray(data.data)) {
              if (
                rolObtenido === "SUPER_ADMIN" ||
                rolObtenido === "ADMIN" ||
                rolObtenido === "SUPERVISOR" ||
                user.rut === propuestaAux.refEmpleado ||
                data.data.filter((e) => e.rut === user.rut).length >= 1
              ) {
                setExiste(1);
              } else {
                setExiste(-1);
              }
            } else {
              setExiste(-1);
            }
          })
          .catch((er) => { });
      });
  }, [id, rolObtenido, user.rut]);

  return (
    <Page title="TAXTIC">
      <Container>
        {existe === 1 ? (
          <div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}>
              <Button
                variant="contained"
                color="info"
                startIcon={<Icon icon={arrowFill} />}
                onClick={() => {
                  navigate("/dashboard/propuestas");
                }}>
                Volver
              </Button>
              <Typography variant="h4" gutterBottom>
                Propuesta de: {propuesta && propuesta.nombreSolicitante}
              </Typography>
              <Box></Box>
            </Stack>
            {propuesta && propuesta.rutSolicitante && (
              <div>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example">
                      <Tab label="Datos Solicitante" {...a11yProps(0)} />
                      <Tab
                        label="Datos Generales Propuesta"
                        {...a11yProps(1)}
                      />
                      <Tab label="Empleados Asociados" {...a11yProps(2)} />

                      <Tab
                        label="Planes"
                        disabled={
                          (propuesta.estado === "ACEPTADA" ||
                            propuesta.estado === "ENVIADA") &&
                          true
                        }
                        {...a11yProps(3)}
                      />
                      <Tab label="Archivos" {...a11yProps(4)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <div style={{ marginTop: "10px" }}>
                      <VerPropuestaDatosSolicitante
                        setPropuesta={setPropuesta}
                        propuesta={propuesta}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div style={{ marginTop: "10px" }}>
                      <VerPropuestaDatos
                        setPropuesta={setPropuesta}
                        propuesta={propuesta}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div style={{ marginTop: "10px" }}>
                      <EmpleadosAsociadosPropuesta propuesta={propuesta} />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <div style={{ marginTop: "10px" }}>
                      <VerPropuestaPlanes propuesta={propuesta} />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <div style={{ marginTop: "10px" }}>
                      <VerPropuestaArchivos
                        propuesta={propuesta}
                        setPropuesta={setPropuesta}
                      />
                    </div>
                  </TabPanel>
                </Box>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Page404 />
          </div>
        )}
      </Container>
    </Page>
  );
}
