import {
  Card,
  CardHeader,
  Skeleton,
  styled,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import useChart from "../charts/use-chart";
import { fNumber } from "src/utils/formatNumber";
import Chart from "../charts/chart";
import { useCallback } from "react";

const CHART_HEIGHT = 490;

const LEGEND_HEIGHT = 162;

const StyledChart = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": {
    height: CHART_HEIGHT,
  },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppDetalleDistribucionTareas.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  tipo: PropTypes.string,
  subheader: PropTypes.string,
  loading: PropTypes.bool,
  onClickLabelGrafico: PropTypes.func,
};

export default function AppDetalleDistribucionTareas({
  title,
  subheader,
  chart,
  loading = false,
  onClickLabelGrafico = null,
  tipo,
  ...other
}) {
  const theme = useTheme();

  const { series, options } = chart;

  const chartSeries = series.map((i) => i.value);

  const manejarClickGrafico = useCallback(
    (labelSeleccionado) => {
      onClickLabelGrafico(labelSeleccionado, tipo);
    },
    [onClickLabelGrafico, tipo]
  );

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (onClickLabelGrafico) {
            const { dataPointIndex } = config;
            const {
              w: {
                config: { labels },
              },
            } = chartContext;

            const seleccionado = labels[dataPointIndex];

            manejarClickGrafico(seleccionado);
          }
        },
        dataPointMouseEnter: (event, chartContext, config) => {
          if (onClickLabelGrafico) {
            event.target.style.cursor = "pointer";
          }
        },
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.error.main,
      theme.palette.warning.main,
    ],
    labels: series.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
    noData: {
      text: "No hay datos disponibles",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#888",
        fontSize: "16px",
      },
    },
    ...options,
  });

  if (loading) {
    return (
      <Card {...other}>
        <CardHeader
          title={
            <Skeleton
              animation="wave"
              height={25}
              width="80%"
              style={{ marginBottom: 2 }}
            />
          }
          subheader={<Skeleton animation="wave" height={25} width="40%" />}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={CHART_HEIGHT}
          style={{ marginTop: theme.spacing(2) }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={LEGEND_HEIGHT}
          style={{ marginTop: theme.spacing(1) }}
        />
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChart dir="ltr">
        <Chart
          type="pie"
          series={chartSeries}
          options={chartOptions}
          height={280}
        />
      </StyledChart>
    </Card>
  );
}
