import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  blur: {
    filter: "blur(4px)",
    pointerEvents: "none",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    zIndex: 1,
  },
  message: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
  },
}));

const ComingSoon = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.blur}>{children}</Box>
      <Box className={classes.overlay}>
        <Paper className={classes.message}>
          <Typography variant="h4" component="h2" align="center">
            Próximamente
          </Typography>
          <Typography variant="body1" align="center">
            Esta función estará disponible en breve.
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default ComingSoon;
