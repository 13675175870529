import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";

export const actualizarFolioFactura = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/pagos/`,
      {
        id: datos.id,
        folio: datos.folio,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      error = response.data;
    });
  return error;
};

export const actualizarFolioFacturaTarea = async (datos) => {
  let error = false;
  await axios
    .put(
      API_SERVER + "/pagos/facturaTareas/",
      {
        id: datos.id,
        folio: datos.folio,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      error = response.data;
    });
  return error;
};

export const actualizarEstadoFactura = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/facturas/planes/${datos.id}`,
      {
        folio: datos.folio,
        estadoFactura: datos.estadoFactura,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function () {
      error = true;
    })
    .catch(() => {
      error = false;
    });
  return error;
};

export const actualizarEstadoFacturaAfecta = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/facturas/planes/facturaAfecta/${datos.id}`,
      {
        folio: datos.folio,
        estadoFactura: datos.estadoFactura,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function () {
      error = true;
    })
    .catch(() => {
      error = false;
    });
  return error;
};

export const actualizarEstadoFacturaTarea = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/facturas/tareas/${datos.id}`,
      {
        folio: datos.folio,
        estadoFactura: datos.estadoFactura,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function () {
      error = true;
    })
    .catch(() => {
      error = false;
    });
  return error;
};

export const actualizarEstadoCorreoFactura = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/facturas/planes/empleado/${datos.id}`,
      {
        empleadoEnviaCorreo: datos.empleadoEnviaCorreo,
        estadoCorreo: datos.estadoCorreo,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function () {
      error = true;
    })
    .catch(() => {
      error = false;
    });
  return error;
};

export const actualizarEstadoFacturaCorreoAfecta = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/facturas/afecta/empleado/${datos.id}`,
      {
        empleadoEnviaCorreo: datos.empleadoEnviaCorreo,
        estadoCorreo: datos.estadoCorreo,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function () {
      error = true;
    })
    .catch(() => {
      error = false;
    });
  return error;
};

export const actualizarEstadoFacturaCorreoTarea = async (datos) => {
  let error = false;
  await axios
    .put(
      `${API_SERVER}/facturas/tareas/empleado/${datos.id}`,
      {
        empleadoEnviaCorreo: datos.empleadoEnviaCorreo,
        estadoCorreo: datos.estadoCorreo,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function () {
      error = true;
    })
    .catch(() => {
      error = false;
    });
  return error;
};

export const obtenerRelacionPlanCliente = async (id) => {
  const plan = [];
  await axios
    .get(`${API_SERVER}/planes/relacionPlanCliente/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      plan.push(...response.data);
    });

  return plan;
};
