import {
  Card,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@material-ui/lab";
import { fDateNumber } from "src/utils/formatTime";

export default function AppTaskFinished({
  title,
  subheader,
  list,
  loading = false,
  ...other
}) {
  const headerCard = loading ? (
    <CardHeader
      title={<Skeleton animation="wave" height={20} width="60%" />}
      subheader={<Skeleton animation="wave" height={10} width="40%" />}
    />
  ) : (
    <CardHeader title={title} subheader={subheader} />
  );

  return (
    <Card sx={{height: 1}} {...other}>
      {headerCard}
      <Timeline
        sx={{
          m: 0,
          p: 3,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {loading ? (
          <OrderItemSkeleton />
        ) : (
          list.map((item, index) => (
            <OrderItem
              key={item.id}
              item={item}
              lastTimeline={index === list.length - 1}
            />
          ))
        )}
      </Timeline>
    </Card>
  );
}

// ----------------------------------------------------------------------

function OrderItem({ item, lastTimeline }) {
  const { type, title, dateEnd, days, branch } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (type === "order1" && "primary") ||
            (type === "order2" && "success") ||
            (type === "order3" && "info") ||
            (type === "order4" && "warning") ||
            "error"
          }
        />
        {lastTimeline ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction="column" justifyContent="space-between">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2" sx={{ width: "85%" }}>
              {title}
            </Typography>
            <Typography variant="subtitle2">{days}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              {branch}
            </Typography>
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              {fDateNumber(dateEnd)}
            </Typography>
          </Stack>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
}

function OrderItemSkeleton() {
  const itemsToRender = [1, 2, 3];

  return (
    <>
      {itemsToRender.map((_, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot>
              <Skeleton variant="circle" width={24} height={24} />
            </TimelineDot>
            {index === itemsToRender.length - 1 ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Stack direction="column" justifyContent="space-between">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">
                  <Skeleton animation="wave" height={16} width="11vw" />
                </Typography>
                <Typography variant="subtitle2">
                  <Skeleton animation="wave" height={16} width="3vw" />
                </Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption">
                  <Skeleton animation="wave" height={12} width="11vw" />
                </Typography>
                <Typography variant="caption">
                  <Skeleton animation="wave" height={12} width="3vw" />
                </Typography>
              </Stack>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </>
  );
}
