import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";
import { useCallback, useState } from "react";
import AppTarjetaFacturas from "src/components/estadisticas/app-tarjeta-facturas";
import { informesApi } from "src/services/informesApi";
import PropTypes from "prop-types";
import ModalDetalleFacturas from "../modalDetalleFacturas";

const ModalEstadisticasFacturacionMensual = ({
  isOpen,
  setOpen,
  fechaActual,
  rut,
}) => {
  const {
    data: detalleFacturasMensuales,
    isLoading: cargandoDetalleFacturasMensuales,
  } = informesApi.useGetDetalleFacturasMensualesQuery({ fechaActual, rut });
  const [
    obtenerDetalleFacturas,
    { data: detalleFacturas, isFetching: cargandoDetalleFacturas },
  ] = informesApi.useLazyGetDetalleRecaudacionFacturasQuery();

  const [abrirModalDetalleFacturas, setAbrirModalDetalleFacturas] =
    useState(false);
  const [tipoAccionFactura, setTipoAccionFactura] = useState("");
  const [tituloModal, setTituloModal] = useState("");

  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const manejarClicTarjetaFacturas = useCallback(
    (accion, tipoFactura) => {
      const accionFactura = accion.toLowerCase();
      const tipo = tipoFactura.toLowerCase();

      const tipoTitulo =
        tipo === "plan" ? "Facturas de planes" : "Facturas de tareas";
      const accionTitulo =
        accionFactura === "recaudado" ? "con recaudaciones" : "pendientes";
      const titulo = `${tipoTitulo} ${accionTitulo}`;

      setTituloModal(titulo);
      setTipoAccionFactura(accionFactura);

      const body = {
        tipoFactura: tipo,
        accion: accionFactura,
        fechaActual,
        rut,
      };

      obtenerDetalleFacturas(body);
      setAbrirModalDetalleFacturas(true);
    },
    [fechaActual, obtenerDetalleFacturas, rut]
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-estadisticas-facturacion"
        id="dialog-estadisticas-facturacion"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={styles.title} htmlFor="dialog-gav">
          Detalle facturas mensuales
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <AppTarjetaFacturas
                title="Facturas por planes"
                loading={cargandoDetalleFacturasMensuales}
                handleClick={manejarClicTarjetaFacturas}
                chart={{
                  series:
                    !cargandoDetalleFacturasMensuales &&
                    detalleFacturasMensuales
                      ? detalleFacturasMensuales?.infoPlanes
                      : [
                          {
                            label: "Recaudado",
                            value: 0,
                            type: "plan",
                          },
                          {
                            label: "Pendiente",
                            value: 0,
                            type: "plan",
                          },
                        ],
                  colors: [
                    theme.palette.primary.light,
                    theme.palette.primary.main,
                  ],
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppTarjetaFacturas
                title="Facturas por tareas"
                loading={cargandoDetalleFacturasMensuales}
                handleClick={manejarClicTarjetaFacturas}
                chart={{
                  series:
                    !cargandoDetalleFacturasMensuales &&
                    detalleFacturasMensuales
                      ? detalleFacturasMensuales?.infoTareas
                      : [
                          {
                            label: "Recaudado",
                            value: 0,
                            type: "tarea",
                          },
                          {
                            label: "Pendiente",
                            value: 0,
                            type: "tarea",
                          },
                        ],
                  colors: [theme.palette.green.light, theme.palette.green.main],
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {abrirModalDetalleFacturas && (
        <ModalDetalleFacturas
          isOpen={abrirModalDetalleFacturas}
          setOpen={setAbrirModalDetalleFacturas}
          cargandoDatos={cargandoDetalleFacturas}
          rows={detalleFacturas}
          tipo={tipoAccionFactura}
          title={tituloModal}
        />
      )}
    </>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalEstadisticasFacturacionMensual.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  fechaActual: PropTypes.string,
  rut: PropTypes.string,
};

export default ModalEstadisticasFacturacionMensual;
