import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { lowerCase, startCase } from "lodash";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Step,
  StepLabel,
  Stepper,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Alert,
  Snackbar,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  FormLabel,
  InputAdornment,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import { round } from "lodash";
import { validateRut } from "@fdograph/rut-utilities";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close"
import AddIcon from "@material-ui/icons/Add";
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import { es } from "date-fns/locale";
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useSelector } from "react-redux";
import { ajustesApi } from "src/services/ajustesApi";
const steps = ["Vendedor", "Cliente", "Tarea", "Nota", "Total"];

export default function AgregarTareaMeson(props) {
  const { openTareaNormal, setOpenTareaNormal, monedas } = props;
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido
  } = useSelector((state) => state.auth.user);
  const sucursal = sucursalObtenida.refSucursal;
  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [tareas, setTareas] = useState([]);
  const [mostrarCircular, setMostrarCircular] = useState(false);

  const [empleados, setEmpleados] = useState([]);

  const [empleadosAdicionales, setEmpleadosAdicionales] = useState([]);

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [nombreTareaSeleccionada, setNombreTareaSeleccionada] = useState(null);

  const [labelChecked, setLabelChecked] = useState("Cliente Existente");
  const [labelChecked2, setLabelChecked2] = useState("Tarea Existente");

  const [skipped, setSkipped] = useState(new Set());
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(true);

  const [vendedorSeleccionado, setVendedorSeleccionado] = useState("");
  const [rutCliente, setRutCliente] = useState("");
  const [nombreCliente, setNombreCliente] = useState("");
  const [direccionCliente, setDireccionCliente] = useState("");
  const [comunaCliente, setComunaCliente] = useState("");
  const [telefonoCliente, setTelefonoCliente] = useState("");
  const [correoCliente, setCorreoCliente] = useState("");
  const [nombreTarea, setNombreTarea] = useState("");
  const [valorTarea, setValorTarea] = useState("");
  const [tipoMonedaTarea, setTipoMonedaTarea] = useState("");
  const [descripcionTarea, setDescripcionTarea] = useState("");
  const [descuentoAdicional, setDescuentoAdicional] = useState(0);
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [rutEmpleado, setRutEmpleado] = useState("");
  const [rutEmpleadoAdicional, setRutEmpleadoAdicional] = useState("");
  const [rutSupervisor, setRutSupervisor] = useState("");
  const [fechaLimiteTarea, setFechaLimiteTarea] = useState(null);

  const [comisionEmpleado, setComisionEmpleado] = useState(0);
  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);

  const [empleadoSelect, setEmpleadoSelect] = useState(null);
  const [empleadoPrincipalSelect, setEmpleadoPrincipalSelect] = useState(null);
  const [supervisorSelect, setSupervisorSelect] = useState(null);
  const [empleadoAdicionalSelect, setEmpleadoAdicionalSelect] = useState(null);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  const {
    data: comunas = []
  } = ajustesApi.useGetComunasQuery()

  useEffect(() => {
    axios.get(
      `${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      }
    )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
    axios
      .get(`${API_SERVER}/clientes/`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClientes(response.data);
      });

    axios
      .get(`${API_SERVER}/tipos/tipoTareas/NORMAL`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setTareas(response.data);
      });
    setRutEmpleado(user.rut);
    setVendedorSeleccionado(user.nombre + " " + user.apellidos);
    setEmpleadoSelect({
      label: user.nombre + " " + user.apellidos,
      value: user.rut,
    });
  }, [sucursal, user.apellidos, user.nombre, user.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/ajustes/comisionesPredefinida/tipoTarea/NORMAL`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (response.data[0].comision) {
          setComisionEmpleado(response.data[0].comision);
        } else {
          setComisionEmpleado(0);
        }
      });
  }, []);

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    const buscarEmpleado = () => {
      let empleado = null;
      let supervisor = null;
      if (rutEmpleado !== "" && empleadoOptions.length > 0) {
        empleado = empleadoOptions.find((item) => item.value === rutEmpleado);

        setEmpleadoPrincipalSelect(empleado !== undefined ? empleado : null);

        const supervisores =
          empleado !== undefined
            ? empleadoOptions.filter((item) => item.value !== empleado.value)
            : [];

        setSupervisorOptions([...supervisores]);
      }

      if (rutSupervisor !== "" && empleadoOptions.length > 0) {
        supervisor = empleadoOptions.find(
          (item) => item.value === rutSupervisor
        );
        setSupervisorSelect(supervisor !== undefined ? supervisor : null);
      }
    };
    if (supervisorSelect === null && empleadoPrincipalSelect === null)
      buscarEmpleado();
  }, [
    empleadoOptions,
    empleadoPrincipalSelect,
    supervisorSelect,
    rutEmpleado,
    rutSupervisor,
  ]);

  function formatNumber(n) {
    n = String(n).replace(/\D/g, "");
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  function verificarNumero(n) {
    n = String(n);
    let expreg = /^[0-9]+\.[0-9]*$|^[0-9]+$/;
    return expreg.test(n);
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChange = (event) => {
    if (checked) {
      setClienteSeleccionado(null);

      setLabelChecked("Cliente Nuevo");
    } else {
      setLabelChecked("Cliente Existente");
    }
    setRutCliente("");
    setNombreCliente("");
    setDireccionCliente("");
    setComunaCliente("");
    setTelefonoCliente("");
    setCorreoCliente("");
    setChecked(event.target.checked);
  };

  const handleChange2 = (event) => {
    if (checked2) {
      setNombreTareaSeleccionada(null);
      setLabelChecked2("Tarea Nueva");
    } else {
      setLabelChecked2("Tarea Existente");
    }
    setNombreTarea("");
    setValorTarea("");
    setTipoMonedaTarea("");
    setDescripcionTarea("");
    setChecked2(event.target.checked);
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const handleNext = () => {
    if (
      activeStep === 0 &&
      (vendedorSeleccionado === "" || vendedorSeleccionado === " ")
    ) {
      setMensajeNotificacion("Vendedor Requerido");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (
      activeStep === 1 &&
      (rutCliente === "" ||
        rutCliente === " " ||
        nombreCliente === "" ||
        nombreCliente === " " ||
        direccionCliente === "" ||
        direccionCliente === " " ||
        comunaCliente === "" ||
        comunaCliente === " ")
    ) {
      if (!validateRut(rutCliente.toLowerCase())) {
        setMensajeNotificacion("Rut Invalido");
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else {
        setMensajeNotificacion("Completar Datos Requeridos");
        setColorNotificacion("info");
        setOpenNotificacion(true);
      }
    } else if (
      activeStep === 2 &&
      (nombreTarea === "" ||
        nombreTarea === " " ||
        valorTarea === "" ||
        valorTarea === " " ||
        tipoMonedaTarea === "" ||
        tipoMonedaTarea === " ")
    ) {
      setMensajeNotificacion("Completar Datos Requeridos");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (activeStep === 2 && Number.isNaN(comisionEmpleado)) {
      setMensajeNotificacion("La comision del empleado debe ser un número");
      setColorNotificacion("error");
      setOpenNotificacion(true);
    } else if (
      activeStep === 2 &&
      (rutEmpleado === "" || rutEmpleado === " ")
    ) {
      setMensajeNotificacion("Seleccionar Empleado");
      setColorNotificacion("error");
      setOpenNotificacion(true);
    } else {
      if (activeStep === 2) {
        setTotalAPagar(valorTarea);
      }
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelar = () => {
    setOpenTareaNormal(false);
  };

  const guardar = () => {
    setMostrarCircular(true);

    let mesActual, diaActual, anioActual;
    let fechaLimiteFinal;

    if (fechaLimiteTarea !== null) {
      let fechaLimiteAux = new Date(fechaLimiteTarea);
      mesActual = fechaLimiteAux.getMonth() + 1;
      diaActual = fechaLimiteAux.getDate();
      anioActual = fechaLimiteAux.getFullYear();

      if (mesActual === 1) {
        mesActual = "01";
      }
      else if (mesActual === 2) {
        mesActual = "02";
      }
      else if (mesActual === 3) {
        mesActual = "03";
      }
      else if (mesActual === 4) {
        mesActual = "04";
      }
      else if (mesActual === 5) {
        mesActual = "05";
      }
      else if (mesActual === 6) {
        mesActual = "06";
      }
      else if (mesActual === 7) {
        mesActual = "07";
      }
      else if (mesActual === 8) {
        mesActual = "08";
      }
      else if (mesActual === 9) {
        mesActual = "09";
      }
      else {
        mesActual = mesActual.toString();
      }

      if (diaActual === 1) {
        diaActual = "01";
      }
      else if (diaActual === 2) {
        diaActual = "02";
      }
      else if (diaActual === 3) {
        diaActual = "03";
      }
      else if (diaActual === 4) {
        diaActual = "04";
      }
      else if (diaActual === 5) {
        diaActual = "05";
      }
      else if (diaActual === 6) {
        diaActual = "06";
      }
      else if (diaActual === 7) {
        diaActual = "07";
      }
      else if (diaActual === 8) {
        diaActual = "08";
      }
      else if (diaActual === 9) {
        diaActual = "09";
      }
      else {
        diaActual = diaActual.toString();
      }
      fechaLimiteFinal = anioActual + "/" + mesActual + "/" + diaActual

    }
    else {
      mesActual = "";
      diaActual = "";
      anioActual = "";
      fechaLimiteFinal = "";

    }

    let empleadosAdiAux = [];
    empleadosAdicionales.map((e) => empleadosAdiAux.push(e.rut));

    let valorTareaAux = 0;
    if (comisionEmpleado === "" || comisionEmpleado === "") {
      setComisionEmpleado(0);
    }
    if (tipoMonedaTarea.toUpperCase() === "PESOS") {
      valorTareaAux = valorTarea.replaceAll(",", "");
      valorTareaAux = valorTareaAux.replaceAll(".", "");
      valorTareaAux = parseFloat(round(valorTareaAux, 2));
    } else {
      valorTareaAux = valorTarea;
      valorTareaAux = parseFloat(round(valorTareaAux, 2));
    }
    let tarea = {
      empleados: JSON.stringify(empleadosAdiAux),
      refCliente: rutCliente,
      refEmpleado: rutEmpleado,
      nombre: nombreTarea.toUpperCase(),
      sucursal: sucursal.toUpperCase(),
      descripcionTarea: descripcionTarea.toUpperCase(),
      vendedor: vendedorSeleccionado,
      descuento: descuentoAdicional,
      valorTarea: valorTareaAux,
      tipoMonedaTarea: tipoMonedaTarea.toUpperCase(),
      supervisor: rutSupervisor,
      nombreCliente: nombreCliente.toUpperCase(),
      direccion: direccionCliente.toUpperCase(),
      comuna: comunaCliente.toUpperCase(),
      telefono: telefonoCliente,
      correo: correoCliente,
      fechaLimite: fechaLimiteFinal
    };

    axios
      .post(`${API_SERVER}/tareas/normal`, {
        ...tarea,
        comisionEmpleado: comisionEmpleado,
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        setMostrarCircular(false);
        setOpenTareaNormal(false);
      })
      .catch(function (error) {
        setMostrarCircular(false);
        setOpenTareaNormal(false);
      });
  };

  const handleChangeRutCliente = (event) => {
    setRutCliente(event.target.value);
  };

  const handleChangeNombreCliente = (event) => {
    setNombreCliente(event.target.value);
  };

  const handleChangeDireccionCliente = (event) => {
    setDireccionCliente(event.target.value);
  };

  const handleChangeTelefonoCliente = (event) => {
    setTelefonoCliente(event.target.value);
  };

  const handleChangeCorreoCliente = (event) => {
    setCorreoCliente(event.target.value);
  };

  const handleChangeNombreTarea = (event) => {
    setNombreTarea(event.target.value);
  };
  const handleChangeComisionEmpleado = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionEmpleado(event.target.value);
    } else {
      setComisionEmpleado(0);
    }
  };

  const handleChangeDescuentoAdicional = (event) => {
    let valor = valorTarea - valorTarea * event.target.value * 0.01;
    if (tipoMonedaTarea === "PESOS") {
    } else {
      valor = round(valor, 2);
    }

    setTotalAPagar(valor);
    setDescuentoAdicional(event.target.value);
  };

  const handleChangValorTarea = (event) => {
    if (verificarNumero(event.target.value)) {
      setValorTarea(event.target.value);
    } else {
      setValorTarea("");
    }
  };

  const handleChangeTipoMoneda = (event) => {
    if (event.target.value === "PESOS" && String(valorTarea).includes(".")) {
      setValorTarea(String(valorTarea).replaceAll(".", ""));
    }
    setTipoMonedaTarea(event.target.value);
  };

  const handleChangeDescripcionTarea = (event) => {
    setDescripcionTarea(event.target.value);
  };

  const handleChangeSupervisores = (value) => {
    const supervisores = empleadoOptions.filter(
      (item) => item.value !== value.value
    );

    setSupervisorOptions([...supervisores]);
  };

  return (
    <>
      <Dialog
        open={openTareaNormal}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid item xs={11} md={11}>
              Nueva Tarea Mesón
            </Grid>
            <Grid item xs={1} md={1} alignContent="end">
              <Button
                variant="contained"
                onClick={cancelar}
              >
                <CloseIcon />
              </Button>

            </Grid>

          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  ¿Generar Tarea?
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {mostrarCircular ? (
                    <CircularProgress />
                  ) : (
                    <div>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Volver
                      </Button>
                      <Button onClick={guardar} color="primary">
                        Generar
                      </Button>
                    </div>
                  )}
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Vendedor
                    </Typography>
                    <FormControl fullWidth style={{ marginTop: "30px" }}>
                      <Autocomplete
                        disabled={!autorizado}
                        value={empleadoSelect}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setVendedorSeleccionado(
                              newValue.label.toUpperCase()
                            );
                            setEmpleadoSelect(newValue);
                          } else {
                            setVendedorSeleccionado("");
                            setEmpleadoSelect(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-empleados"
                        options={empleadoOptions}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            type="rut"
                            label="Vendedor Asignado"
                            required
                            value={vendedorSeleccionado}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}

                {activeStep === 1 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Cliente
                    </Typography>

                    <FormControlLabel
                      style={{ marginTop: "30px" }}
                      control={
                        <Switch checked={checked} onChange={handleChange} />
                      }
                      label={labelChecked}
                    />

                    {checked ? (
                      <Autocomplete
                        value={clienteSeleccionado}
                        options={clientes}
                        autoHighlight
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setClienteSeleccionado(newValue);
                            setNombreCliente(newValue.nombre);
                            setRutCliente(newValue.rut);
                            setDireccionCliente(newValue.direccion);
                            setComunaSeleccionada({ nombre: newValue.comuna });
                            setComunaCliente(newValue.comuna);
                            setTelefonoCliente(newValue.telefono);
                            setCorreoCliente(newValue.correo);
                          } else {
                            setClienteSeleccionado(null);
                            setNombreCliente("");
                            setRutCliente("");
                            setDireccionCliente("");
                            setComunaCliente("");
                            setTelefonoCliente("");
                            setCorreoCliente("");
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              "& > span": { mr: "10px", fontSize: 18 },
                            }}
                            {...props}
                          >
                            {option.nombre} {option.apellidos}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Seleccionar Cliente"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        style={{ marginTop: "30px" }}
                        fullWidth
                        required
                        label="Nombre Cliente"
                        value={nombreCliente}
                        onChange={handleChangeNombreCliente}
                      />
                    )}

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Rut Cliente"
                      value={rutCliente}
                      required={!checked && true}
                      disabled={checked && true}
                      onChange={handleChangeRutCliente}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      required
                      label="Direccion Cliente"
                      value={direccionCliente}
                      onChange={handleChangeDireccionCliente}
                    />

                    <Autocomplete
                      style={{ marginTop: "30px" }}
                      value={comunaSeleccionada}
                      disablePortal
                      options={comunas}
                      isOptionEqualToValue={(option) => option.nombre}
                      getOptionLabel={(option) => option.nombre}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setComunaSeleccionada(newValue);
                        } else {
                          setComunaSeleccionada(null);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setComunaCliente(newInputValue);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField {...params} label="Comuna" />
                      )}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Teléfono Cliente"
                      value={telefonoCliente}
                      onChange={handleChangeTelefonoCliente}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Correo Cliente"
                      value={correoCliente}
                      onChange={handleChangeCorreoCliente}
                    />
                  </div>
                )}

                {activeStep === 2 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Tarea
                    </Typography>

                    <Stack>
                      <FormControlLabel
                        style={{ marginTop: "30px" }}
                        control={
                          <Switch checked={checked2} onChange={handleChange2} />
                        }
                        label={labelChecked2}
                      />
                    </Stack>

                    {checked2 ? (
                      <Autocomplete
                        style={{ marginTop: "30px" }}
                        value={nombreTareaSeleccionada}
                        disablePortal
                        options={tareas}
                        isOptionEqualToValue={(option) => option.nombre}
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setNombreTareaSeleccionada(newValue);
                            setNombreTarea(newValue.nombre);
                            setValorTarea(newValue.valor);
                            setTipoMonedaTarea(newValue.tipoMoneda);
                            setDescripcionTarea(newValue.descripcion);
                          } else {
                            setNombreTareaSeleccionada(null);
                            setNombreTarea("");
                            setValorTarea("");
                            setTipoMonedaTarea("");
                            setDescripcionTarea("");
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setNombreTarea(newInputValue);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Nombre Tarea" />
                        )}
                      />
                    ) : (
                      <TextField
                        style={{ marginTop: "30px" }}
                        fullWidth
                        required
                        label="Nombre Tarea"
                        value={nombreTarea}
                        onChange={handleChangeNombreTarea}
                      />
                    )}
                    <FormControl style={{ marginTop: "10px", width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Tipo Moneda (*)
                      </InputLabel>
                      <Select
                        style={{ marginTop: "10px" }}
                        value={tipoMonedaTarea}
                        disabled={checked2}
                        onChange={handleChangeTipoMoneda}
                      >
                        <MenuItem value="">
                          <em>Ninguno</em>
                        </MenuItem>
                        {monedas.map((element, index) => {
                          return (
                            <MenuItem key={index} value={element.nombre}>
                              {element.nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <TextField
                      margin="dense"
                      label="Valor Tarea"
                      value={valorTarea}
                      disabled={checked2}
                      onChange={handleChangValorTarea}
                      type="number"
                      fullWidth
                      required
                    />

                    <FormControl fullWidth style={{ marginTop: "30px" }}>
                      <Autocomplete
                        disabled={!autorizado}
                        disablePortal
                        value={empleadoPrincipalSelect}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setRutEmpleado(newValue.value);
                            setEmpleadoPrincipalSelect(newValue);
                            setRutSupervisor("");
                            setSupervisorSelect(null);
                            handleChangeSupervisores(newValue);
                          } else {
                            setRutEmpleado("");
                            setEmpleadoPrincipalSelect(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-empleados"
                        options={empleadoOptions}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            type="rut"
                            label="Empleado Principal"
                            required
                            value={rutEmpleado}
                          />
                        )}
                      />
                    </FormControl>

                    {rolObtenido !== "EMPLEADO" && (
                      <FormControl fullWidth style={{ marginTop: "30px" }}>
                        <Autocomplete
                          disabled={
                            empleadoPrincipalSelect === null || !autorizado
                          }
                          disablePortal
                          value={supervisorSelect}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setRutSupervisor(newValue.value);
                              setSupervisorSelect(newValue);
                            } else {
                              setRutSupervisor("");
                              setSupervisorSelect(null);
                            }
                          }}
                          fullWidth
                          id="combo-box-Supervisor"
                          options={supervisorOptions}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              type="rut"
                              label="Supervisor Asignado"
                              value={rutSupervisor}
                            />
                          )}
                        />
                      </FormControl>
                    )}

                    <Stack direction="row" style={{ marginTop: "10px" }}>
                      <TextField
                        margin="dense"
                        label="Comisión Empleado"
                        value={comisionEmpleado}
                        disabled={
                          rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN"
                            ? false
                            : true
                        }
                        onChange={handleChangeComisionEmpleado}
                        type="number"
                        fullWidth
                        required
                      />
                    </Stack>

                    {rolObtenido !== "EMPLEADO" && (
                      <Grid container>
                        <Grid item xs={10} md={10}>
                          <FormControl fullWidth style={{ marginTop: "30px" }}>
                            <Autocomplete
                              disabled={!autorizado}
                              disablePortal
                              value={empleadoAdicionalSelect}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setRutEmpleadoAdicional(
                                    empleados.find(
                                      (empleado) =>
                                        empleado.rut === newValue.value
                                    )
                                  );
                                  setEmpleadoAdicionalSelect(newValue);
                                } else {
                                  setRutEmpleadoAdicional("");
                                  setEmpleadoAdicionalSelect(null);
                                }
                              }}
                              fullWidth
                              id="combo-box-empleados"
                              options={empleadoOptions}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  type="rut"
                                  label="Empleados Adicionales"
                                  required
                                  value={rutEmpleadoAdicional}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} md={2} style={{ marginTop: "30px" }}>
                          <Button
                            fullWidth
                            style={{ height: "100%" }}
                            variant="outlined"
                            onClick={() => {
                              if (rutSupervisor === rutEmpleadoAdicional.rut) {
                              } else if (
                                rutEmpleado === rutEmpleadoAdicional.rut
                              ) {
                              } else {
                                let existe = empleadosAdicionales.filter(
                                  (e) => e.rut === rutEmpleadoAdicional.rut
                                )[0];
                                if (existe) {
                                } else {
                                  setEmpleadosAdicionales([
                                    ...empleadosAdicionales,
                                    rutEmpleadoAdicional,
                                  ]);
                                }
                              }
                            }}
                          >
                            <AddIcon /> Agregar
                          </Button>
                        </Grid>
                      </Grid>
                    )}

                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre Empleado</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {empleadosAdicionales.map((row, idx) => (
                            <TableRow key={"empleadosAdicional_" + idx}>
                              <TableCell component="th" scope="row">
                                {row.nombre + " " + row.apellidos}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    let existe = empleadosAdicionales.filter(
                                      (e) => e.rut !== row.rut
                                    );
                                    setEmpleadosAdicionales(existe);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {activeStep === 3 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Nota o Descripción Tarea
                    </Typography>

                    <FormLabel component="legend">No Obligatorio</FormLabel>

                    <TextField
                      style={{ marginTop: "30px" }}
                      label="Descripción Tarea"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={descripcionTarea}
                      fullWidth
                      onChange={handleChangeDescripcionTarea}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={es}
                      style={{ marginTop: "30px" }}
                    >
                      <DatePicker

                        label="Fecha Limite Tarea (No Obligatorio)"
                        views={['year', 'month', 'day']}
                        value={fechaLimiteTarea}
                        minDate={new Date()}
                        onChange={(newValue) => {
                          setFechaLimiteTarea(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={params?.inputProps?.placeholder} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                )}

                {activeStep === 4 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Total a Pagar
                    </Typography>

                    <TextField
                      style={{ marginTop: "30px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Descuento adicional"
                      variant="outlined"
                      value={descuentoAdicional}
                      fullWidth
                      type="number"
                      onChange={handleChangeDescuentoAdicional}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      label="Total a Pagar"
                      variant="outlined"
                      value={
                        tipoMonedaTarea === "PESOS"
                          ? formatNumber(totalAPagar)
                          : totalAPagar
                      }
                      fullWidth
                      disabled
                      type="text"
                    />
                  </div>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep === 0 ? (
                    <Button color="inherit" onClick={cancelar} sx={{ mr: 1 }}>
                      Cancelar
                    </Button>
                  ) : (
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Volver
                    </Button>
                  )}

                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}
      >
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}
        >
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
      ;
    </>
  );
}
