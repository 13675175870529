import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import DialogVerEmpleadosActividad from "./DialogVerEmpleadosActividad";
import moment from "moment";
import DialogAgregarActividad from "./DialogAgregarActividad";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  grid: {
    border: "2px solid",
    borderRadius: ".5rem",
    borderColor: "white",
    backgroundColor: "#f5f5f5",
    minHeight: "200px",
  },
  grid2: {
    border: "2px solid",
    borderRadius: ".5rem",
    borderColor: "white",
    backgroundColor: "#ff802e",
    minHeight: "200px",
    color: "#FFFFFF",
  },
}));

export default function ColumnaDia(props) {
  const {
    dia,
    actividades,
    estado,
    compartido,
    eliminarDatos = null,
    aceptarInvitacion = null,
  } = props;
  const classes = useStyles();
  const { empleado: user } = useSelector((state) => state.auth.user);

  const [mostrarDialogEditar, setMostrarDialogEditar] = useState(false);
  const [mostrarDialogAgregar, setMostrarDialogAgregar] = useState(false);
  const [idActividad, setIdActividad] = useState(null);
  const [mostrarAgregar, setMostrarAgregar] = useState(false);
  const [diaSemana, setDiaSemana] = useState(-1);
  const [diaSemanaTexto, setDiaSemanaTexto] = useState("");

  useEffect(() => {
    if (!compartido) {
      if (moment(dia, "DD/MM/YYYY") <= moment()) {
        setMostrarAgregar(false);
      } else {
        setMostrarAgregar(true);
      }
    }
  }, [dia, compartido]);

  useEffect(() => {
    const diasSemanaArray = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];

    setDiaSemanaTexto(diasSemanaArray[moment(dia, "DD/MM/YYYY").day()]);
    setDiaSemana(moment(dia, "DD/MM/YYYY").day());
  }, [dia]);

  return (
    <Grid
      key={dia}
      container
      item
      xs={6}
      sm={4}
      md={2}
      className={estado ? classes.grid2 : classes.grid}
    >
      <Droppable droppableId={dia}>
        {(provided, _) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ ...provided.droppableProps.style, width: "97%" }}
          >
            <Typography
              variant="p"
              align="center"
              component="div"
              style={{ marginTop: "5px" }}
            >
              {diaSemanaTexto}
            </Typography>
            <Typography variant="p" component="div" align="center">
              {dia}
              <br />
            </Typography>
            {diaSemana !== 0 && diaSemana !== 6 && mostrarAgregar && (
              <Card style={{ marginBottom: ".7rem" }}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setMostrarDialogAgregar(true)}
                >
                  <AddIcon /> Nuevo Evento
                </Button>
              </Card>
            )}

            {actividades?.map((actividad, i) => (
              <Draggable
                key={actividad.id}
                draggableId={"draggable-1" + actividad.id}
                index={i}
              >
                {(provided, _) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      marginBottom: ".7rem",
                    }}
                  >
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container>
                          <Grid item xs={5} md={5}>
                            <Typography variant="p">
                              {`ID: ${actividad.id}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xs={compartido ? 12 : 9}
                            md={compartido ? 12 : 9}
                          >
                            <Typography variant="p" fontWeight="500">
                              {actividad.nombreCita}
                            </Typography>
                          </Grid>
                          {user.rut === actividad.refEmpleado && (
                            <Grid item xs={3} md={3}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  eliminarDatos(actividad.id);
                                }}
                              >
                                X
                              </Button>
                            </Grid>
                          )}
                        </Grid>

                        <div
                          onClick={() => {
                            setMostrarDialogEditar(true);
                            setIdActividad(actividad.id);
                          }}
                        >
                          {compartido ? (
                            <Typography variant="p">
                              {"Encargado: " + actividad.empleado.nombre}
                              <br />
                            </Typography>
                          ) : (
                            <>
                              {user.rut === actividad.refEmpleado ? (
                                <Typography variant="p" color="primary">
                                  {"Encargado: PROPIA"}
                                  <br />
                                </Typography>
                              ) : (
                                actividad.empleado &&
                                !compartido && (
                                  <Typography variant="p">
                                    {`Encargado: ${actividad.empleado.nombre}`}
                                    <br />
                                  </Typography>
                                )
                              )}

                              <Typography variant="p">
                                {"Sucursal: " + actividad.sucursal.nombre}
                                <br />
                              </Typography>
                            </>
                          )}

                          <Typography variant="p">
                            {"Hora Inicio: " + actividad.horaInicio}
                            <br />
                          </Typography>

                          <Typography variant="p">
                            {"Hora Término: " + actividad.horaTermino}
                            <br />
                          </Typography>
                        </div>
                      </CardContent>
                      <CardActions>
                        {!compartido &&
                          !actividad.aceptado &&
                          user.rut !== actividad.refEmpleado && (
                            <Button
                              variant="contained"
                              onClick={() => {
                                aceptarInvitacion(actividad.id);
                              }}
                            >
                              Aceptar
                            </Button>
                          )}
                        {actividad.link && (
                          <Button
                            onClick={() => {
                              window.open(actividad.link, "_blank");
                            }}
                          >
                            IR a la URL
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {mostrarDialogEditar && idActividad && (
        <DialogVerEmpleadosActividad
          open={mostrarDialogEditar}
          setOpen={setMostrarDialogEditar}
          id={idActividad}
        />
      )}

      {mostrarDialogAgregar && (
        <DialogAgregarActividad
          open={mostrarDialogAgregar}
          setOpen={setMostrarDialogAgregar}
          fecha={dia}
        />
      )}
    </Grid>
  );
}
