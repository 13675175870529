import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { lowerCase, startCase } from "lodash";
// material
import {
  TextField,
  Card,
  Stack,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@material-ui/core";
import axios from "axios";
import { useSelector } from "react-redux";
import { planesApi } from "src/services/planesApi";

export default function VerPlanDatos(props) {
  const { setPlan, plan } = props;
  const { sucursal: sucursalObtenida, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  const sucursal = sucursalObtenida.refSucursal;

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [mostrarCircular, setMostrarCircular] = useState(false);

  const [estado, setEstado] = useState(plan ? plan.estado : "");
  const [empleado, setEmpleado] = useState(plan ? plan.refEmpleado : "");
  const [supervisor, setSupervisor] = useState(plan ? plan.supervisor : "");
  const [contratoFirmado, setContratoFirmado] = useState(
    plan ? plan.contratoFirmado : ""
  );

  const [comentarios, setComentarios] = useState(plan ? plan.comentarios : "");

  const [empleados, setEmpleados] = useState([]);

  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);

  const [empleadoPrincipalSelect, setEmpleadoPrincipalSelect] = useState(null);
  const [supervisorSelect, setSupervisorSelect] = useState(null);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  const [actualizarDatosPlan] = planesApi.useActualizarDatosPlanMutation();

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
  }, [sucursal]);

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    if (empleado !== "" && empleadoOptions.length > 0) {
      setSupervisorOptions(
        empleadoOptions.filter((option) => option.value !== empleado)
      );
    }
  }, [empleado, empleadoOptions]);

  useEffect(() => {
    const buscarEmpleado = () => {
      let empleadoPrincipal = null;
      let refSupervisor = null;
      if (empleado !== "" && empleadoOptions.length > 0) {
        empleadoPrincipal = empleadoOptions.find(
          (item) => item.value === empleado
        );

        setEmpleadoPrincipalSelect(
          empleadoPrincipal !== undefined ? empleadoPrincipal : null
        );

        const supervisores =
          empleadoPrincipal !== undefined
            ? empleadoOptions.filter(
                (item) => item.value !== empleadoPrincipal.value
              )
            : [];

        setSupervisorOptions([...supervisores]);
      }

      if (supervisor !== "" && empleadoOptions.length > 0) {
        refSupervisor = empleadoOptions.find(
          (item) => item.value === supervisor
        );
        setSupervisorSelect(refSupervisor !== undefined ? refSupervisor : null);
      }
    };
    if (supervisorSelect === null && empleadoPrincipalSelect === null)
      buscarEmpleado();
  }, [
    empleadoOptions,
    empleadoPrincipalSelect,
    supervisorSelect,
    empleado,
    supervisor,
  ]);

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const handleChangeEstado = (event) => {
    setEstado(event.target.value);
  };

  const handleChangeContratoFirmado = (event) => {
    setContratoFirmado(event.target.value);
  };

  const handleChangeComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const guardarCambios = () => {
    setMostrarCircular(true);
    if (
      estado === "" ||
      estado === " " ||
      empleado === "" ||
      empleado === " " ||
      contratoFirmado === "" ||
      contratoFirmado === " "
    ) {
      setMensajeNotificacion("Estado, Empleado, Contrato son OBLIGATORIOS");
      setColorNotificacion("error");
      setOpenNotificacion(true);
      setMostrarCircular(false);
    } else {
      let planAux = { ...plan };
      let planAux2 = { ...plan };
      planAux.refEmpleado = empleado;
      planAux.estado = estado;
      planAux.comentarios = comentarios;
      planAux.supervisor = supervisor;
      planAux.contratoFirmado = contratoFirmado;
      setPlan(planAux);

      let actualizacion = {
        id: planAux.id,
        refEmpleado: planAux.refEmpleado,
        estado: planAux.estado,
        comentarios: planAux.comentarios,
        supervisor: planAux.supervisor,
        urlPropuesta: planAux.urlPropuesta,
        urlContrato: planAux.urlContrato,
        contratoFirmado: planAux.contratoFirmado,
      };

      actualizarDatosPlan(actualizacion)
        .then(() => {
          setMensajeNotificacion("Datos guardados correctamente");
          setColorNotificacion("success");
          setOpenNotificacion(true);
        })
        .catch((error) => {
          console.error(
            "Error al actualizar datos Plan (guardarCambios): ",
            error
          );
          setPlan(planAux2);
          setMensajeNotificacion("Error al guardar los datos.");
          setColorNotificacion("error");
          setOpenNotificacion(true);
        })
        .finally(() => {
          setMostrarCircular(false);
        });
    }
  };

  const handleChangeSupervisores = (value) => {
    const supervisores = empleadoOptions.filter(
      (item) => item.value !== value.value
    );

    setSupervisorOptions([...supervisores]);
  };

  return (
    <>
      <Card>
        <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
          Datos del Plan
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px" }}
          mb={5}
        >
          <TextField
            margin="dense"
            label="Vendedor"
            type="text"
            value={plan && plan.vendedor}
            fullWidth
            disabled
          />
        </Stack>

        {rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN" ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
            style={{ marginLeft: "25px", marginRight: "25px" }}
            mb={5}
          >
            <FormControl fullWidth>
              <InputLabel>Estado Plan</InputLabel>
              <Select
                value={estado}
                onChange={handleChangeEstado}
                label="Estado Plan"
              >
                <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                <MenuItem value="INACTIVO">INACTIVO</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
            style={{ marginLeft: "25px", marginRight: "25px" }}
            mb={5}
          >
            <TextField
              margin="dense"
              label="Estado Plan"
              type="text"
              value={estado}
              fullWidth
              disabled
            />
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px" }}
          mb={5}
        >
          {rolObtenido === "SUPER_ADMIN" ||
          rolObtenido === "ADMIN" ||
          rolObtenido === "SUPERVISOR" ? (
            <FormControl fullWidth>
              <Autocomplete
                disabled={!autorizado}
                disablePortal
                value={empleadoPrincipalSelect}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setEmpleado(newValue.value);
                    setEmpleadoPrincipalSelect(newValue);
                    handleChangeSupervisores(newValue);
                  } else {
                    setEmpleado("");
                    setEmpleadoPrincipalSelect(null);
                  }
                }}
                fullWidth
                id="combo-box-empleados"
                options={empleadoOptions}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    type="rut"
                    label="Empleado"
                    required
                    value={empleado}
                  />
                )}
              />
            </FormControl>
          ) : (
            <TextField
              margin="dense"
              label="Empleado"
              type="text"
              value={empleado}
              fullWidth
              disabled
            />
          )}

          {rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN" ? (
            <FormControl fullWidth>
              <Autocomplete
                disabled={!autorizado}
                disablePortal
                value={supervisorSelect}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setSupervisor(newValue.value);
                    setSupervisorSelect(newValue);
                  } else {
                    setSupervisor("");
                    setSupervisorSelect(null);
                  }
                }}
                fullWidth
                id="combo-box-Supervisor"
                options={supervisorOptions}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    type="rut"
                    label="Supervisor Asignado"
                    value={supervisor}
                  />
                )}
              />
            </FormControl>
          ) : (
            <TextField
              margin="dense"
              label="Supervisor"
              type="text"
              value={supervisor}
              fullWidth
              disabled
            />
          )}
        </Stack>

        {(rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") && (
          <div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={3}
              style={{ marginLeft: "25px", marginRight: "25px" }}
              mb={5}
            >
              {plan.contratoFirmado === "NO" ? (
                <FormControl fullWidth>
                  <InputLabel>Contrato Firmado</InputLabel>
                  <Select
                    value={contratoFirmado}
                    onChange={handleChangeContratoFirmado}
                    label="Contrato Firmado"
                  >
                    <MenuItem value="NO">NO</MenuItem>
                    <MenuItem value="SI">SI</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <Alert severity="success">Contrato Firmado</Alert>
              )}
            </Stack>
          </div>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px" }}
          mb={5}
        >
          <TextField
            margin="dense"
            label="Comentarios"
            type="text"
            value={comentarios}
            onChange={handleChangeComentarios}
            fullWidth
            multiline
            rows={4}
            maxRows={4}
          />
        </Stack>

        <div
          style={{
            textAlign: "right",
            marginRight: "25px",
            marginBottom: "25px",
          }}
        >
          {mostrarCircular ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={guardarCambios}
            >
              Guardar Cambios
            </Button>
          )}
        </div>
      </Card>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}
      >
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}
        >
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
    </>
  );
}
