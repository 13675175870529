import React, { useState } from "react";
import { Icon } from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";
import { empleadosApi } from "src/services/empleadosApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { ajustesApi } from "src/services/ajustesApi";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
  },
}));

export default function FormDialog(props) {
  const {
    open,
    setOpen,
    nombre,
    apellidos,
    rut,
    telefono,
    correo,
    refRol,
    estado,
    sucursal,
    fechaIngresoNueva,
  } = props;
  const [state, setState] = useState(estado);
  const [rol, setRol] = useState(refRol);
  const classes = useStyles();
  const [fechaIngreso, setFechaIngreso] = useState(fechaIngresoNueva);

  const [agregarEmpleado] = empleadosApi.useAddEmpleadoMutation();

  const {
    data: roles = []
  } = ajustesApi.useGetRolesQuery();


  /**
   * Se encarga de cerrar el modal de agregar empleado
   */
  const handleClose = () => {
    setOpen(false);
  };

  const validarTelefono = (value) => {
    if (value?.length === 9) return true;
    else return false;
  };

  /**
   * Se encarga de hacer las verificaciones para los distinso campos del
   * formulario de agregar empleados.
   */
  const LoginSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre del empleado requerido"),
    apellidos: Yup.string().required("Apellidos del empleado requeridos"),
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    telefono: Yup.string().test(
      "telefono test",
      "El teléfono debe tener 9 digitos",
      (value) => validarTelefono(value)
    ),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
  });

  /**
   * Objeto encargado de validar los valores ingresados al formulario, según las
   * validaciones entregadas por LoginSchema.
   */
  const formik = useFormik({
    initialValues: {
      nombre: nombre,
      apellidos: apellidos,
      rut: rut,
      telefono: telefono,
      correo: correo,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  /**
   *
   * @param {} e, es el evento
   * Cambia el valor del rol al seleccionarlo en el componente Select.
   */
  const handleChangeRol = (e) => {
    setRol(e.target.value);
  };

  /**
   *
   * @param {} e, es el evento
   * Cambia el valor del estado del empleado al seleccionarlo en el componente Select.
   */
  const handleChangeEstado = (e) => {
    setState(e.target.value);
  };

  /**
   * Se encargar de guardar los datos ingresados por el usuario, ya validados
   * a la variable empleado, que es la que se guardará en la bd.
   */
  const guardarDatos = () => {
    if (state !== "" && rol !== "") {
      let empleado;
      empleado = {
        apellidos: getFieldProps("apellidos").value.toUpperCase(),
        contrasena:
          deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
          deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
        correo: getFieldProps("correo").value,
        estado: state,
        nombre: getFieldProps("nombre").value.toUpperCase(),
        rut:
          deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
          deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
        telefono: getFieldProps("telefono").value.toString().toUpperCase(),
      };

      AlertaModal({
        title: 'agregar el empleado',
        confirmButtonText: 'Sí, agregar',
        target: document.getElementById('dialogCrearEmpleado'),
      })
        .fire()
        .then((result) => {

          if (result.isConfirmed) {
            agregarEmpleado({
              ...empleado,
              fechaIngreso:
                new Date(fechaIngreso).getFullYear() +
                "/" +
                (new Date(fechaIngreso).getMonth() + 1) +
                "/" +
                new Date(fechaIngreso).getDate(),
              refSucursal: sucursal,
              refRol: rol,
            })
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: 'Nuevo empleado',
                  action: 'agregado',
                  target: document.getElementById('dialogCrearEmpleado'),
                }).fire()
                handleClose();
              }).catch(() => {
                AlertaToast({
                  action: 'Error en el servidor o el empleado ya existe',
                  fullText: true,
                  type: 'error',
                  target: document.getElementById('dialogCrearEmpleado'),
                }).fire()
                setSubmitting(false);
              })

          }
        })
    } else {
      AlertaToast({
        action: 'El empleado debe tener rol y estado',
        fullText: true,
        type: 'warning',
        target: document.getElementById('dialogCrearEmpleado'),
      }).fire()
      setSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearEmpleado"
        >
          <DialogTitle id="form-dialog-title">Empleados</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información del empleado
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
                <TextField
                  required
                  margin="dense"
                  id="apellidos"
                  label="Apellidos"
                  fullWidth
                  {...getFieldProps("apellidos")}
                  error={Boolean(touched.apellidos && errors.apellidos)}
                  helperText={touched.apellidos && errors.apellidos}
                />
                <TextField
                  required
                  margin="dense"
                  id="rut"
                  label="Rut"
                  fullWidth
                  {...getFieldProps("rut")}
                  error={Boolean(touched.rut && errors.rut)}
                  helperText={touched.rut && errors.rut}
                />
                <TextField
                  required
                  margin="dense"
                  id="telefono"
                  label="Teléfono"
                  type="number"
                  fullWidth
                  {...getFieldProps("telefono")}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />
                <TextField
                  required
                  margin="dense"
                  id="correo"
                  label="Correo"
                  type="email"
                  fullWidth
                  {...getFieldProps("correo")}
                  error={Boolean(touched.correo && errors.correo)}
                  helperText={touched.correo && errors.correo}
                />
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Seleccione Rol
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Seleccione Rol"
                    value={rol}
                    onChange={(e) => handleChangeRol(e)}>
                    <MenuItem value={rol}>{rol}</MenuItem>
                    {roles.filter(e => e.nombre !== "CLIENTE").map((cargo, index) =>
                      cargo.nombre !== rol ? (
                        <MenuItem key={index} value={cargo.nombre}>
                          {cargo.nombre}
                        </MenuItem>
                      ) : (
                        false
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Seleccione Estado
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Seleccione Estado"
                    id="demo-simple-select"
                    value={state}
                    onChange={(e) => handleChangeEstado(e)}>
                    <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                    <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                  <DatePicker
                    label="Fecha Ingreso Contrato"
                    views={["year", "month", "day"]}
                    value={fechaIngreso}
                    onChange={(newValue) => {
                      setFechaIngreso(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={params?.inputProps?.placeholder}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>

                <DialogActions>
                  <LoadingButton onClick={handleClose} color="error">
                    Cancelar
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    startIcon={<Icon icon={saveFill} />}
                    loading={isSubmitting}>
                    Guardar Empleado
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
