import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";


export default function DetalleFacturaCliente(props) {
  const { open, setOpen, factura } = props;

  const [detalleFactura, setDetalleFactura] = useState([]);



  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/facturas/detalleFacturaPlanes/${factura.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {

        if (response.data === 2) window.location.reload();

        setDetalleFactura(response.data);

      });

  }, [factura.id])

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;

    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }






  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Detalle Factura
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre Plan</TableCell>
                <TableCell>Valor Plan</TableCell>
                <TableCell>Empleado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detalleFactura && detalleFactura.map((row, index) => (
                <TableRow
                  key={"tablaDetallePlan_" + index}
                >
                  <TableCell>
                    {row.nombrePlan}
                  </TableCell>
                  <TableCell >{formatNumber(row.valorPlan)}</TableCell>
                  <TableCell >{row.nombreEmpleado + " " + row.apellidoEmpleado}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog >
  );

}
