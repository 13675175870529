import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import arrowFill from "@iconify/icons-eva/arrow-back-fill";
import PropTypes from "prop-types";
import {
  Stack,
  Container,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Skeleton,
} from "@material-ui/core";
import Page from "../components/Page";
import Page404 from "./Page404";
import VerPlanDatosCliente from "../components/verplan/VerPlanDatosCliente";
import VerPlanPropuesta from "../components/verplan/VerPlanPropuesta";
import VerPlanDatos from "../components/verplan/VerPlanDatos";
import VerPlanArchivo from "../components/verplan/verPlanArchivos/VerPlanArchivos";
import EmpleadosAsociadosPlan from "../components/verplan/EmpleadosAsociadosPlan";
import { useSelector } from "react-redux";
import { planesApi } from "src/services/planesApi";
import { empleadosApi } from "src/services/empleadosApi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <label>{children}</label>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VerPlan() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [plan, setPlan] = useState(null);
  const [existe, setExiste] = useState(1);

  const [
    getPlan,
    {
      data: planesData,
      isSuccess: isSuccessPlanesData,
      isLoading: isLoadingPlanes,
    },
  ] = planesApi.useLazyGetPlanQuery();

  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [user.rut]);

  useEffect(() => {
    getPlan(id);

    if (isSuccessPlanesData && !isLoadingPlanes) {
      setPlan(planesData);
      if (planesData.empleadosPlan && Array.isArray(planesData.empleadosPlan)) {
        if (
          rolObtenido === "SUPER_ADMIN" ||
          rolObtenido === "ADMIN" ||
          rolObtenido === "SUPERVISOR" ||
          user.rut === planesData.refEmpleado ||
          planesData.empleadosPlan.filter((e) => e.rut === user.rut).length >= 1
        ) {
          setExiste(1);
        } else {
          setExiste(-1);
        }
      }
    }
  }, [id, isSuccessPlanesData, isLoadingPlanes]);

  return (
    <Page title="TAXTIC">
      <Container>
        {existe === 1 && plan && !isLoadingPlanes ? (
          <div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Button
                variant="contained"
                color="info"
                startIcon={<Icon icon={arrowFill} />}
                onClick={() => {
                  navigate("/dashboard/planes");
                }}
              >
                Volver
              </Button>
              <Typography variant="h4" gutterBottom>
                Plan de: {plan.cliente.nombre}
              </Typography>
              <Box></Box>
            </Stack>
            <div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab label="Datos Cliente" {...a11yProps(0)} />
                    <Tab label="Datos Generales del Plan" {...a11yProps(1)} />
                    <Tab label="Empleados Asociados" {...a11yProps(2)} />
                    <Tab label="Planes Asociados" {...a11yProps(3)} />
                    {plan.contratoFirmado === "NO" && (
                      <Tab label="Exportar Contrato" {...a11yProps(4)} />
                    )}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div style={{ marginTop: "10px" }}>
                    <VerPlanDatosCliente plan={plan} />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div style={{ marginTop: "10px" }}>
                    <VerPlanDatos plan={plan} setPlan={setPlan} />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div style={{ marginTop: "10px" }}>
                    <EmpleadosAsociadosPlan plan={plan} />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div style={{ marginTop: "10px" }}>
                    <VerPlanPropuesta id={id} />
                  </div>
                </TabPanel>
                {plan.contratoFirmado === "NO" && (
                  <TabPanel value={value} index={4}>
                    <div style={{ marginTop: "10px" }}>
                      <VerPlanArchivo plan={plan} setPlan={setPlan} />
                    </div>
                  </TabPanel>
                )}
              </Box>
            </div>
          </div>
        ) : isLoadingPlanes ? (
          <Skeleton variant="rectangular" width="100%" height="500px" />
        ) : (
          existe === -1 && (
            <div>
              <Page404 />
            </div>
          )
        )}
      </Container>
    </Page>
  );
}
