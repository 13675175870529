import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import FormCategoria from "./FormCategoria";

const ModalCategoria = ({ isOpen, setOpen, data, agregar, statesModal }) => {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="dialog-prorrateo"
      id="dialogProrrateo"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={styles.title} htmlFor="dialog-prorrateo">
        {agregar
          ? statesModal.type === 0
            ? "Agregar categoría"
            : "Agregar subcategoría"
          : statesModal.type === 0
          ? "Editar categoría"
          : "Editar subcategoría"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={1}>
          {agregar
            ? statesModal.type === 0
              ? "Complete los datos para añadir una nueva categoría"
              : "Complete los datos para añadir una nueva subcategoría"
            : statesModal.type === 0
            ? "Modifique los datos de la categoría"
            : "Modifique los datos de la subcategoría"}
        </DialogContentText>
        <Divider />
        <FormCategoria
          setOpen={setOpen}
          agregar={agregar}
          data={data}
          statesModal={statesModal}
        />
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default ModalCategoria;
