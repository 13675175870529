import { FormControl, TextField } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import { useField } from "formik";
import { es } from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import React from "react";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(es);
dayjs.tz.setDefault("America/Santiago");

const FormikDatePicker = ({
  label,
  views = ["day", "month", "year"],
  helperText,
  inputFormat = "dd/MM/yyyy",
  options,
  size = "medium",
  minDate = null,
  maxDate = null,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (date) => {
    const fecha = dayjs(date);
    helpers.setValue(fecha);
  };

  return (
    <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <DatePicker
          siz
          label={label}
          value={field.value || null}
          onChange={handleChange}
          views={views}
          disableMaskedInput
          inputFormat={inputFormat}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
              size={size}
              fullWidth
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default React.memo(FormikDatePicker);
