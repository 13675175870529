// import axios from "axios";
import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { useNavigate } from "react-router-dom";
// material
import { alpha } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@material-ui/core";
// components
import MenuPopover from "../../components/MenuPopover";
import { Alerta } from "../../components/Alerta";
import ConfigurarCuenta from "src/components/cuenta/ConfigurarCuenta";
import ConfigurarCuentaCliente from "src/components/cuenta/ConfigurarCuentaCliente";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "src/reducers/authReducers";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Cuenta",
    icon: settings2Fill,
    linkTo: "#",
  },
];

// const LOCAL_STORAGE_KEY = "taxtic.";
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { empleado: user, rol: rolUsuario, cliente: clienteObtenido } = useSelector((state) => state.auth.user);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [openCuenta, setOpenCuenta] = useState(false);
  const [openCuentaCliente, setOpenCuentaCliente] = useState(false);

  const account = rolUsuario === "CLIENTE" ? {
    displayName: clienteObtenido?.nombre,
    email: clienteObtenido?.correo,
    photoURL: "/static/mock-images/avatars/avatar_default.jpg",
  } : {
    displayName: user?.nombre,
    email: user?.correo,
    photoURL: "/static/mock-images/avatars/avatar_default.jpg",
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCuenta = () => {
    if (rolUsuario === "CLIENTE") {
      setOpenCuentaCliente(true);
    } else {
      setOpenCuenta(true);
    }
    setOpen(false);
  };

  const handleLogout = async () => {
    setError("");
    try {
      dispatch(logout())
      localStorage.clear();
      navigate("/login", { replace: true });
    } catch (ex) {
      setError(
        "Ocurrió un error al cerrar sesión. Por favor intente nuevamente."
      );
      setShowError(true);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {account.displayName}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={handleCuenta}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            color="inherit"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Cerrar Sesión
          </Button>
        </Box>
      </MenuPopover>

      <Alerta
        showAlert={showError}
        setShowAlert={setShowError}
        message={error}
        color="error"
      />
      {openCuenta && (
        <ConfigurarCuenta open={openCuenta} setOpen={setOpenCuenta} />
      )}
      {openCuentaCliente && (
        <ConfigurarCuentaCliente
          open={openCuentaCliente}
          setOpen={setOpenCuentaCliente}
        />
      )}
    </>
  );
}
