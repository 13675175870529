import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { API_SERVER, dbx } from "../../../utils/urlBack_End";
import saveFill from "@iconify/icons-eva/save-outline";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  Grid,
  Alert,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";
import axios from "axios";

const styles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 7,
    marginTop: 12,
  },
  gridContainer: {},
  Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  textField: {
    width: "100%",
    marginBottom: 18,
    marginTop: 15,
  },
  textFieldComentario: {
    marginBottom: 18,
    marginTop: 20,
  },
  hidden: {
    display: "none",
  },
}));

export default function CrearContrato(props) {
  const {
    open,
    setOpen,
    empleado,
    titulo,
    setShowMessagePermiso,
    setColor,
    setMessage,
    setContratos,
  } = props;
  const classes = styles();
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const actualizar = () => {
    if (titulo === "Adjuntar Contrato del Empleado") {
      axios
        .get(`${API_SERVER}/empleados/contrato/${empleado.rut}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setContratos(response.data);
        });
    } else {
      axios
        .get(`${API_SERVER}/clientes/contrato/${empleado.rut}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setContratos(response.data);
        });
    }
  };

  const guardarDatos = async () => {
    setIsSubmitting(true);
    if (file !== null) {
      const ruta =
        titulo === "Adjuntar Contrato del Empleado" ? "Empleados" : "Clientes";
      await uploadFile(ruta);
    } else {
      setIsSubmitting(false);
      setMessage("Debe subir un archivo");
      setColor("info");
      setShowMessagePermiso(true);
    }
  };

  const cerrar = () => {
    handleClose();
  };

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    if (fileAux.type !== "application/pdf") {
      setMessage("Solo se admiten documentos en formato PDF.");
      setColor("error");
      setShowMessagePermiso(true);
      return;
    }
    setFile(fileAux);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  async function uploadFile(ruta) {
    if (!file) return false;
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      return false;
    }

    try {
      await dbx.filesUpload({
        path: `/${ruta}/${empleado.rut}/Contratos/` + file.name,
        contents: file,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: `/${ruta}/${empleado.rut}/Contratos/` + file.name,
        });
        let url = shareResponse.result.url;

        if (titulo === "Adjuntar Contrato del Empleado") {
          const contrato = {
            refEmpleado: empleado.rut,
            urlArchivo: url,
            nombreArchivo: file.name,
          };

          axios
            .post(
              `${API_SERVER}/empleados/contrato`,
              {
                ...contrato,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              actualizar();
              setFile(null);
              setShowMessagePermiso(true);
              setColor("success");
              setMessage("Contrato Subido Correctamente");
              setOpen(false);
            });
        } else {
          const contrato = {
            refCliente: empleado.rut,
            urlArchivo: url,
            nombreArchivo: file.name,
          };

          axios
            .post(
              `${API_SERVER}/clientes/contrato`,
              {
                ...contrato,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              actualizar();
              setFile(null);
              setShowMessagePermiso(true);
              setColor("success");
              setMessage("Contrato Subido Correctamente");
              setOpen(false);
            });
        }
      } catch (ex) {
        setShowMessagePermiso(true);
        setColor("error");
        setMessage("El archivo ya existe en el sistema.");
        setIsSubmitting(false);
        const { url } = ex.error.error.shared_link_already_exists.metadata;
        return url;
      } finally {
      }
    } catch (ex) {
      return false;
    }
  }

  return (
    <>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            <Card style={{ padding: "1rem" }}>
              <Alert severity="info">
                Haga click en el botón 'Seleccionar Archivo' para subir un
                documento en formato PDF.
              </Alert>

              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={4} md={4}>
                  <input
                    accept="application/pdf"
                    className={classes.hidden}
                    id="contained-button-file"
                    onChange={handleFileInput}
                    type="file"
                  />

                  <label htmlFor={"contained-button-file"}>
                    <Button
                      fullWidth
                      component="span"
                      m={1}
                      variant="contained">
                      Seleccionar Archivo
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={6} md={6}>
                  {file && (
                    <span>
                      <span>
                        <> {file.name}</>
                      </span>

                      <span>
                        <IconButton
                          onClick={handleDeleteFile}
                          aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </span>
                  )}
                </Grid>
              </Grid>
            </Card>

            <DialogActions>
              <LoadingButton onClick={() => cerrar()} color="error">
                Cancelar
              </LoadingButton>
              <LoadingButton
                type="submit"
                color="primary"
                startIcon={<Icon icon={saveFill} />}
                loading={isSubmitting}
                onClick={guardarDatos}>
                Guardar Cambios
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
