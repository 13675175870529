import React, { useEffect, useState } from "react";
import { Box, Container, TextField, Stack, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import axios from "axios";

import { API_SERVER } from "src/utils/urlBack_End";
import { empleadosApi } from "src/services/empleadosApi";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const styles = makeStyles(() => ({
  containerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 100,
  },
  typography: {
    textAlign: "end",
    marginTop: 50,
  },
  typographyMargin: {
    textAlign: "end",
    marginTop: 50,
    marginBottom: 22,
  },
}));

export default function GraficoTareas() {
  const classes = styles();
  const navigate = useNavigate();
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    });
  }, []);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      remember: true,
    },
    validationSchema: LoginSchema,
  });

  const { errors, touched, getFieldProps } = formik;

  const consultar = () => {
    if (!Boolean(touched.rut && errors.rut)) {
      const rut =
        deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
        deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier;
      navigate(`/dashboard/graficoTareas/${rut}`);
    }
  };

  return (
    <div>
      <Container className={classes.containerLogo}>
        {" "}
        <Box component="img" src="/static/logo.png" sx={{ height: 90 }} />
      </Container>

      <LoadScript googleMapsApiKey="AIzaSyD6xPa2oaJo9q7aO07EXzxFVpRUmxkesPA">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          <Marker position={center}></Marker>
        </GoogleMap>
        <Stack spacing={2} style={{ marginTop: "10px" }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Ingresar Rut"
            className={classes.textField}
            {...getFieldProps("rut")}
            error={Boolean(touched.rut && errors.rut)}
            helperText={touched.rut && errors.rut}
          />

          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => consultar()}>
            Revisar
          </Button>
        </Stack>
      </LoadScript>
    </div>
  );
}
