import React, { useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import SimpleModal from "../../layouts/SimpleModal";
import { toPascalCase } from "../../utils/converter";

export default function ModalChangeState(props) {
  const { object, onSubmit, onClose, states, open, title, state, disabled } =
    props;

  const [value, setValue] = useState(`${states.indexOf(object[state])}`);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const disabledAcceptButton = () => {
    return disabled === undefined
      ? false
      : typeof disabled[value] === "boolean"
      ? disabled[value]
      : true;
  };

  return (
    <SimpleModal
      open={open}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(states[value])}
      disabledAcceptButton={disabledAcceptButton()}
      title={title}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">Estado</FormLabel>
        <RadioGroup
          aria-label="estados"
          name="estado"
          onChange={handleChange}
          value={value}
        >
          {states.map((s, idx) => (
            <FormControlLabel
              disabled={
                disabled === undefined
                  ? false
                  : typeof disabled[idx] === "boolean"
                  ? disabled[idx]
                  : true
              }
              control={<Radio />}
              key={idx}
              label={`${toPascalCase(s)}`}
              value={`${idx}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </SimpleModal>
  );
}

ModalChangeState.propTypes = {
  object: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  disabled: PropTypes.array,
};
