import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { formatAsCLP } from "src/utils/formatNumber";
import { limitarDecimalesSinRelleno } from "src/utils/sharedFunctions";
import FormikTextField from "src/components/hook-form/FormikTextField";
import FormikSelect from "src/components/hook-form/FormikSelect";
import { AddCommentOutlined } from "@material-ui/icons";
import Iconify from "src/components/iconify";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import plusFill from "@iconify/icons-eva/plus-fill";
import ReferenciaFactura from "./ReferenciaFactura";
import icDel from "@iconify/icons-ant-design/delete-outlined";
import { fechaActual } from "src/utils/formatTime";

const FacturaItem = React.memo(
  ({
    item,
    index,
    handleChangePrice,
    handleChangeQuantity,
    handleChangeSelect,
    handleToggleDescripcion,
    handleRemove,
    showDescripcion,
    helperText,
    TIPOS_MONEDA,
    cantidadMaximaCaracteresGlosa,
    cantidadMaximaCaracteresDescripcionGlosa,
    obteniendoMonedas,
  }) => {
    const onChangePrice = useCallback(
      (event) => {
        handleChangePrice(event, item.moneda, index);
      },
      [handleChangePrice, item.moneda, index]
    );

    const onChangeQuantity = useCallback(
      (event) => {
        handleChangeQuantity(event, item.moneda, index);
      },
      [handleChangeQuantity, item.moneda, index]
    );

    const onChangeSelect = useCallback(
      (event) => {
        handleChangeSelect(event, index);
      },
      [handleChangeSelect, index]
    );

    const onToggleDescripcion = useCallback(() => {
      handleToggleDescripcion(index);
    }, [handleToggleDescripcion, index]);

    const onRemove = useCallback(() => {
      handleRemove(index);
    }, [handleRemove, index]);

    return (
      <Stack key={index} alignItems="flex-end" spacing={1.5}>
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          spacing={2}
          sx={{ width: 1 }}
        >
          <FormikTextField
            name={`items.${index}.glosa`}
            size="small"
            disabled={obteniendoMonedas}
            label="Glosa"
            InputLabelProps={{ shrink: true }}
            helperText={`${item.glosa.length}/${cantidadMaximaCaracteresGlosa} caracteres`}
            FormHelperTextProps={{
              style: { textAlign: "right" },
            }}
            maxLength={80}
          />
          <FormikSelect
            name={`items.${index}.moneda`}
            label="Moneda"
            shrink={true}
            loading={obteniendoMonedas}
            options={TIPOS_MONEDA}
            helperText={helperText || ""}
            size="small"
            onChange={onChangeSelect}
          />
          <FormikTextField
            name={`items.${index}.precio`}
            label="Precio"
            size="small"
            disabled={obteniendoMonedas}
            InputLabelProps={{ shrink: true }}
            placeholder="0"
            onChange={onChangePrice}
            inputAdornment="otraMoneda"
            tipoMoneda={item.moneda}
          />
          <FormikTextField
            name={`items.${index}.cantidad`}
            label="Cantidad"
            size="small"
            disabled={obteniendoMonedas}
            InputLabelProps={{ shrink: true }}
            placeholder="0"
            onChange={onChangeQuantity}
          />
          <FormikTextField
            disabled
            name={`items.${index}.total`}
            label="Total"
            size="small"
            InputLabelProps={{ shrink: true }}
            placeholder="0"
            inputAdornment="PESO"
          />
          <div style={{ marginBottom: "20px" }}>
            <Tooltip title="Agregar descripción glosa">
              <IconButton
                aria-label="agregar-descripcion-glosa"
                onClick={onToggleDescripcion}
              >
                <AddCommentOutlined />
              </IconButton>
            </Tooltip>
          </div>
        </Stack>
        {showDescripcion[index] && (
          <FormikTextField
            name={`items.${index}.descripcion`}
            label="Descripción"
            size="small"
            multiline
            rows={4}
            disabled={obteniendoMonedas}
            InputLabelProps={{ shrink: true }}
            helperText={`${item.descripcion.length}/${cantidadMaximaCaracteresDescripcionGlosa} caracteres`}
            FormHelperTextProps={{
              style: { textAlign: "right" },
            }}
            maxLength={1000}
          />
        )}
        <Button
          size="small"
          color="error"
          startIcon={<Iconify icon={trash2Outline} />}
          onClick={onRemove}
        >
          Eliminar
        </Button>
      </Stack>
    );
  }
);

const DetalleFacturas = ({
  monedas,
  loading,
  obteniendoMonedas,
  seccionReferencia,
  setMostrarBotonAgregarSeccion,
}) => {
  const cantidadMaximaCaracteresGlosa = 80;
  const cantidadMaximaCaracteresDescripcionGlosa = 1000;
  const {
    values: { items, referencias, mostrarSeccionReferencia },
    setFieldValue,
  } = useFormikContext();
  const [helpersText, setHelpersText] = useState(
    Array(items.length).fill(null)
  );
  const [showDescripcion, setShowDescripcion] = useState(
    Array(items.length).fill(false)
  );
  const [showObservation, setShowObservation] = useState(
    Array(referencias.length).fill(false)
  );

  const TIPOS_MONEDA = useMemo(() => {
    return (
      monedas.map((moneda) => ({
        label: moneda.moneda,
        value: moneda.moneda,
      })) || []
    );
  }, [monedas]);

  const totalPrice = useMemo(
    () =>
      items.reduce(
        (acc, currentValue) => Number(acc) + Number(currentValue.total),
        0
      ),
    [items]
  );

  const handleRemove = useCallback(
    (index) => {
      setFieldValue(
        "items",
        items.filter((_, i) => i !== index)
      );
    },
    [items, setFieldValue]
  );

  const handleAddItem = useCallback(() => {
    setFieldValue("items", [
      ...items,
      {
        glosa: "",
        moneda: "PESOS",
        cantidad: 1,
        precio: "",
        total: 0,
      },
    ]);
  }, [items, setFieldValue]);

  const handleChangeQuantity = useCallback(
    (event, tipoMoneda, index) => {
      const newQuantity = Number(event.target.value);
      setFieldValue(`items[${index}].cantidad`, newQuantity);
      const precio = Number(items[index].precio);
      const valorMoneda =
        tipoMoneda === "PESOS"
          ? 1
          : monedas.find((moneda) => moneda.moneda === tipoMoneda)?.valor || 0;
      const newTotal = Math.round(newQuantity * precio * valorMoneda);
      setFieldValue(`items[${index}].total`, String(newTotal));
    },
    [monedas, setFieldValue, items]
  );

  const handleChangePrice = useCallback(
    (event, tipoMoneda, index) => {
      const userInput = event.target.value;
      const contieneCaracteresExtranios = /[^0-9,.]/.test(userInput);

      if (!contieneCaracteresExtranios) {
        const newPrice = userInput.replace(",", ".");
        setFieldValue(`items[${index}].precio`, newPrice);
        const precio = Number(newPrice);
        const valorMoneda =
          tipoMoneda === "PESOS"
            ? 1
            : monedas.find((moneda) => moneda.moneda === tipoMoneda)?.valor ||
              0;

        const newTotal = Math.round(
          items[index].cantidad *
            limitarDecimalesSinRelleno(precio * Number(valorMoneda))
        );
        setFieldValue(`items[${index}].total`, String(newTotal));
      } else {
        setFieldValue(`items[${index}].precio`, "");
        setFieldValue(`items[${index}].total`, "0");
      }
    },
    [monedas, setFieldValue, items]
  );

  const handleChangeSelect = useCallback(
    (event, index) => {
      const tipoMoneda = event.target.value;
      if (tipoMoneda !== "PESOS") {
        const helperText = `Valor ${tipoMoneda}: ${formatAsCLP(
          monedas.find((moneda) => moneda.moneda === tipoMoneda).valor
        )}`;
        const newHelpersText = [...helpersText];
        newHelpersText[index] = helperText;
        setHelpersText(newHelpersText);
      } else {
        const newHelpersText = [...helpersText];
        newHelpersText[index] = null;
        setHelpersText(newHelpersText);
      }
      setFieldValue(`items[${index}].moneda`, tipoMoneda);
      setFieldValue(`items[${index}].precio`, "");
      setFieldValue(`items[${index}].cantidad`, 1);
      setFieldValue(`items[${index}].total`, 0);
    },
    [helpersText, monedas, setFieldValue]
  );

  const handleToggleDescripcion = useCallback(
    (index) => {
      setShowDescripcion((prevState) => {
        const newState = [...prevState];
        newState[index] = !newState[index];
        return newState;
      });
      setFieldValue(`items[${index}].descripcion`, "");
    },
    [setFieldValue]
  );

  useEffect(() => {
    setFieldValue("precioTotal", totalPrice);
  }, [setFieldValue, totalPrice]);

  const resetReferencia = useCallback(() => {
    setFieldValue("referencias", [
      {
        TpoDocRef: "801",
        FolioRef: "",
        FchRef: fechaActual(),
        RazonRef: "",
      },
    ]);
  }, [setFieldValue]);

  const eliminarSeccionReferencia = useCallback(() => {
    resetReferencia();
    setFieldValue("mostrarSeccionReferencia", false);
    setMostrarBotonAgregarSeccion(true);
  }, [resetReferencia, setFieldValue, setMostrarBotonAgregarSeccion]);

  const handleScrollToSection = useCallback(() => {
    if (mostrarSeccionReferencia && seccionReferencia.current) {
      seccionReferencia.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [mostrarSeccionReferencia, seccionReferencia]);

  useEffect(() => {
    handleScrollToSection();
  }, [handleScrollToSection]);

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
          Detalle:
        </Typography>
        <Stack
          divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
          spacing={3}
        >
          {[...Array(3)].map((_, index) => (
            <Stack key={index} alignItems="flex-end" spacing={1.5}>
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                sx={{ width: 1 }}
              >
                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    width: "60%",
                    height: 20,
                    bgcolor: "grey.300",
                    borderRadius: 1,
                  }}
                />
                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    width: "40%",
                    height: 20,
                    bgcolor: "grey.300",
                    borderRadius: 1,
                  }}
                />
                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    width: "60%",
                    height: 20,
                    bgcolor: "grey.300",
                    borderRadius: 1,
                  }}
                />
                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    width: "40%",
                    height: 20,
                    bgcolor: "grey.300",
                    borderRadius: 1,
                  }}
                />
                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    width: "30%",
                    height: 20,
                    bgcolor: "grey.300",
                    borderRadius: 1,
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
        Detalle:
      </Typography>

      <FieldArray name="items">
        {() => (
          <>
            <Stack
              divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
              spacing={3}
            >
              {items.map((item, index) => (
                <FacturaItem
                  key={index}
                  item={item}
                  index={index}
                  handleChangePrice={handleChangePrice}
                  handleChangeQuantity={handleChangeQuantity}
                  handleChangeSelect={handleChangeSelect}
                  handleToggleDescripcion={handleToggleDescripcion}
                  handleRemove={handleRemove}
                  showDescripcion={showDescripcion}
                  helperText={helpersText[index]}
                  TIPOS_MONEDA={TIPOS_MONEDA}
                  cantidadMaximaCaracteresGlosa={cantidadMaximaCaracteresGlosa}
                  cantidadMaximaCaracteresDescripcionGlosa={
                    cantidadMaximaCaracteresDescripcionGlosa
                  }
                  obteniendoMonedas={obteniendoMonedas}
                />
              ))}
            </Stack>
            <Divider sx={{ my: 3, borderStyle: "dashed" }} />
            <Stack
              spacing={2}
              direction={{ xs: "column-reverse", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              <Button
                size="small"
                startIcon={<Iconify icon={plusFill} />}
                onClick={handleAddItem}
                sx={{ flexShrink: 0 }}
              >
                Agregar ítem
              </Button>
            </Stack>
          </>
        )}
      </FieldArray>
      {mostrarSeccionReferencia && (
        <>
          <Divider sx={{ my: 3 }} />
          <Stack
            ref={seccionReferencia}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography
              variant="h6"
              sx={{
                color: "text.disabled",
                mb: 3,
              }}
            >
              Referencias:
            </Typography>
            <Tooltip title="Eliminar sección">
              <IconButton
                onClick={() => eliminarSeccionReferencia()}
                color="error"
              >
                <Iconify icon={icDel} />
              </IconButton>
            </Tooltip>
          </Stack>
          <ReferenciaFactura
            referencias={referencias}
            setFieldValue={setFieldValue}
            setShowObservation={setShowObservation}
            showObservation={showObservation}
          />
        </>
      )}
      <Stack spacing={2} sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="flex-end">
          <Typography variant="h6">Precio total :</Typography>
          <Typography variant="h6" sx={{ textAlign: "right", width: 120 }}>
            {formatAsCLP(totalPrice) || "-"}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default React.memo(DetalleFacturas);
