import React, { useState } from "react";
import * as XLSX from "xlsx";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { toTitleCase } from "src/utils/converter";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";

export default function ExportToExcel(props) {
  const {
    data,
    filename,
    sheetName,
    setShowAlert,
    setColor,
    setMessage,
    type,
    sx,
  } = props;

  const [loadingExport, setLoadingExport] = useState(false);

  const generarInfo = (data, tipo) => {
    let cabecera = [];
    Object.entries(data)
      .filter(
        (info) =>
          info[0] !== "contrasena" &&
          info[0] !== "id" &&
          info[0] !== "refEmpleado" &&
          info[0] !== "supervisor" &&
          info[0] !== "vendedor" &&
          info[0] !== "refIdFactura" &&
          info[0] !== "urlPropuesta" &&
          info[0] !== "refPlan" &&
          info[0] !== "refTarea"
      )
      .forEach((info) => {
        if (tipo !== "Cabecera") {
          cabecera.push(info[1]);
        } else {
          cabecera.push(toTitleCase(info[0]));
        }
      });
    return cabecera;
  };

  const formatData = (data) => {
    let excel;
    if (type !== "objects") {
      excel = [
        generarInfo(data.find((info) => info.length > 0)[0], "Cabecera"),
      ];
      data.map((info) =>
        info.length > 0
          ? info.map(
              (informacion) =>
                (excel = [...excel, generarInfo(informacion, "Datos")])
            )
          : false
      );
    } else {
      excel = [generarInfo(data[0], "Cabecera")];
      data.map((info) => (excel = [...excel, generarInfo(info, "Datos")]));
    }
    return excel;
  };

  const promisePool = async (tasks, poolSize) => {
    const results = [];
    const executing = new Set();

    const enqueue = () => {
      if (tasks.length === 0) return Promise.resolve();

      const task = tasks.shift(); // Toma la siguiente tarea en la cola
      const p = Promise.resolve().then(() => task()); // Envuelve la tarea en una promesa
      results.push(p);

      const e = p.then(() => executing.delete(e)); // Elimina la tarea del conjunto de tareas en ejecución cuando termina
      executing.add(e); // Añade la tarea al conjunto de tareas en ejecución

      let r = Promise.resolve();
      if (executing.size >= poolSize) {
        r = Promise.race(executing); // Espera a que al menos una tarea en ejecución termine
      }

      return r.then(enqueue); // Encola la siguiente tarea
    };

    return enqueue().then(() => Promise.all(results)); // Espera a que todas las tareas se completen
  };

  const exportar = async () => {
    setLoadingExport(true);
    if (data?.length > 0) {
      let mayorContrasena = { nElementos: 0, elemento: {} };
      const clientesCompletos = [];

      const tasks = data.map(
        (cliente, index) => () =>
          axios
            .get(
              `${API_SERVER}/clientes/clavesPortales/${
                sheetName === "Clientes" ? cliente.rut : cliente.refCliente
              }`,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              let claves = response.data.filter(
                (clave) => clave.institucion !== "SITAX"
              );
              if (claves.length > mayorContrasena.nElementos) {
                mayorContrasena = {
                  nElementos: claves.length,
                  elemento: cliente,
                };
              }
              let clavesTransformadas = {};
              claves.forEach((clave, index) => {
                let credenciales = {
                  ["usuario" + index]: clave.usuario,
                  ["clave" + index]: clave.clave,
                  ["institucion" + index]: clave.institucion,
                };
                clavesTransformadas = {
                  ...clavesTransformadas,
                  ...credenciales,
                };
              });
              clientesCompletos.push({ ...cliente, ...clavesTransformadas });
            })
      );

      await promisePool(tasks, 5);

      let primerElemento;
      let clientesOrdenados;
      if (sheetName === "Clientes") {
        primerElemento = clientesCompletos.find(
          (cliente) => cliente.rut === mayorContrasena.elemento.rut
        );
        clientesOrdenados = clientesCompletos.filter(
          (cliente) => cliente.rut !== mayorContrasena.elemento.rut
        );
      } else {
        primerElemento = clientesCompletos.find(
          (cliente) => cliente.id === mayorContrasena.elemento.id
        );
        clientesOrdenados = clientesCompletos.filter(
          (cliente) => cliente.id !== mayorContrasena.elemento.id
        );
      }
      clientesOrdenados = [{ ...primerElemento }, ...clientesOrdenados];

      let worksheet = XLSX.utils.aoa_to_sheet(formatData(clientesOrdenados));
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName);
      XLSX.writeFile(new_workbook, filename + ".xlsx");
      setLoadingExport(false);
    } else {
      setLoadingExport(false);
      if (setShowAlert !== undefined) {
        setColor("error");
        setMessage("No hay datos que exportar");
        setShowAlert(true);
      }
    }
  };

  return (
    <LoadingButton
      variant="contained"
      onClick={exportar}
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px" }}
      loading={loadingExport}
      sx={sx}
    >
      Exportar clientes a Excel
    </LoadingButton>
  );
}
