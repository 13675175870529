import React, { useMemo, useState } from "react";
import {
  Card,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Grid,
  CardHeader,
  Skeleton,
  Tooltip,
  TableFooter,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as locales from "@material-ui/core/locale";
import { tareasApi } from "src/services/tareasApi";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import Scrollbar from "../Scrollbar";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { fNumber } from "src/utils/formatNumber";
import SearchNotFound from "../SearchNotFound";
import CustomTablePagination from "../CustomTablePagination";
import dayjs from "dayjs";
import "dayjs/locale/es.js";
import { useNavigate } from "react-router-dom";
dayjs.locale("es");

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
  },
  pos: {
    marginBottom: 12,
  },
}));

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 10,
  order: "desc",
  orderBy: "fechaInicio",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "empleado", label: "Empleado", alignRight: false },
  { id: "sucursal", label: "Sucursal", alignRight: false },
  { id: "nombreTarea", label: "Tarea", alignRight: false },
  { id: "tipoTarea", label: "Tipo", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "fechaInicio", label: "Fecha inicio", alignRight: false },
  { id: "fechaTermino", label: "Fecha termino", alignRight: false },
  { id: "diasTranscurridos", label: "Días transcurridos", alignRight: false },
  { id: "" },
];

export default function TareasAsocidasCliente(props) {
  const { value, cliente, title, subheader } = props;
  const classes = styles();

  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const navigate = useNavigate();

  const { data: tareas = [], isLoading: cargandoTareas } =
    tareasApi.useGetClienteQuery(cliente.rut);

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  const rowsFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["nombre", "sucursal", "nombreEmpleado"];
    return applySortFilter(
      tareas,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [statesTable.filterName, statesTable.order, statesTable.orderBy, tareas]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(rowsFiltradas.length / newRowsPerPage);
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const seEncontroElemento = rowsFiltradas.length === 0;

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            tareas.length
        )
      : 0;

  const handleClickWatchTask = (id) => {
    navigate("/dashboard/tareas/" + id);
  };

  return (
    <div className={classes.pos}>
      {value === 5 && (
        <Card className={classes.root} variant="outlined">
          <Grid container>
            <Grid item xs={12} md={8} lg={8}>
              <CardHeader title={title} subheader={subheader} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{
                display: "grid",
                justifyContent: { xs: "center", md: "right", lg: "right" },
                alignItems: "flex-end",
              }}
            >
              <TablaToolbar
                filterName={statesTable.filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer component={Paper}>
              {!cargandoTareas ? (
                <Table
                  sx={{ minWidth: classes.root }}
                  size="small"
                  aria-label="tabla con tareas empleado"
                >
                  <TablaHead
                    order={statesTable.order}
                    orderBy={statesTable.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tareas.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rowsFiltradas
                      .slice(
                        statesTable.currentPage *
                          statesTable.rowsPerPageOptions,
                        statesTable.currentPage *
                          statesTable.rowsPerPageOptions +
                          statesTable.rowsPerPageOptions
                      )
                      .map((row, index) => (
                        <TableRow hover key={row.id}>
                          <TableCell>
                            {statesTable.currentPage *
                              statesTable.rowsPerPageOptions +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {capitalizarPalabras(row.nombreEmpleado)}
                            </Typography>
                          </TableCell>
                          <TableCell>{row.sucursal}</TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {capitalizarPalabras(row.nombre)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {capitalizarPalabras(row.tipoTarea)}
                          </TableCell>
                          <TableCell>
                            {capitalizarPalabras(row.estado)}
                          </TableCell>
                          <TableCell>
                            {row.fechaInicio
                              ? dayjs(row.fechaInicio).format("DD/MM/YYYY")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {row.fechaTermino
                              ? dayjs(row.fechaTermino).format("DD/MM/YYYY")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {fNumber(row.diasTranscurridos)}
                          </TableCell>
                          <TableCell>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                <Tooltip title="Ver tarea">
                                  <Button
                                    color="primary"
                                    onClick={() => handleClickWatchTask(row.id)}
                                  >
                                    <VisibilityIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}

                    {filasVacias > 0 && (
                      <TableRow style={{ height: 53 * filasVacias }}>
                        <TableCell colSpan={9} />
                      </TableRow>
                    )}
                  </TableBody>
                  {seEncontroElemento && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <SearchNotFound
                            searchQuery={statesTable.filterName}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow>
                      <ThemeProvider
                        theme={(outerTheme) =>
                          createTheme(outerTheme, locales[statesTable.locale])
                        }
                      >
                        <CustomTablePagination
                          count={rowsFiltradas.length}
                          rowsPerPage={statesTable.rowsPerPageOptions}
                          page={statesTable.currentPage}
                          colSpan={9}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </ThemeProvider>
                    </TableRow>
                  </TableFooter>
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          {/* <CardActions>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Empleado</TableCell>
                    <TableCell align="center">Sucursal</TableCell>
                    <TableCell align="center">Nombre Tarea</TableCell>
                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Fecha Inicio</TableCell>
                    <TableCell align="center">Fecha Término</TableCell>
                    <TableCell align="center">Días Transcurridos</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tareas &&
                    empleados &&
                    tareas.map((element, index) => {
                      let empleado = empleados.filter(
                        (elemento) => elemento.rut === element.refEmpleado
                      )[0];
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {empleado &&
                              empleado.nombre + " " + empleado.apellidos}
                          </TableCell>
                          <TableCell align="center">
                            {element.sucursal}
                          </TableCell>
                          <TableCell align="center">{element.nombre}</TableCell>
                          <TableCell align="center">{element.estado}</TableCell>
                          <TableCell align="center">
                            {element.fechaInicio === ""
                              ? element.fechaInicio
                              : moment(
                                  element.fechaInicio,
                                  "YYYY/MM/DD"
                                ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {element.fechaTermino === ""
                              ? element.fechaTermino
                              : moment(
                                  element.fechaTermino,
                                  "YYYY/MM/DD"
                                ).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {element.diasTranscurridos}
                          </TableCell>
                          <TableCell align="right">
                            <Link to={"/dashboard/tareas/" + element.id}>
                              <Button
                                color="primary"
                                disabled={
                                  element.sucursal !== sucursal &&
                                  rolObtenido === "EMPLEADO" &&
                                  user.rut !== element.refEmpleado &&
                                  true
                                }
                              >
                                <VisibilityIcon />
                              </Button>
                            </Link>{" "}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardActions> */}
        </Card>
      )}
    </div>
  );
}
