import { memo } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import FormGav from "./FormGav";

const ModalGav = memo((props) => {
  const { isOpen, setOpen, data, agregar, statesModal } = props;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="dialog-gav"
      id="dialog-gav"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle style={styles.title} htmlFor="dialog-gav">
        {agregar ? "Agregar GAV" : "Editar GAV"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={1}>
          Complete los datos para añadir un nuevo GAV
        </DialogContentText>
        <Divider />
        <FormGav
          setOpen={setOpen}
          agregar={agregar}
          data={data}
          statesModal={statesModal}
        />
      </DialogContent>
    </Dialog>
  );
});

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default ModalGav;
