import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { AlertaToast } from "../AlertaSwall";

const SubtareasComponent = ({ subtareas, setSubtareas }) => {
  const [nuevaSubtarea, setNuevaSubtarea] = useState("");

  const handleChangeNuevaSubtarea = (event) => {
    setNuevaSubtarea(String(event.target.value).toUpperCase());
  };

  const agregarSubtarea = () => {
    if (nuevaSubtarea && nuevaSubtarea.trim()) {
      let existe = subtareas.filter((e) => e === nuevaSubtarea)[0];
      if (!existe) {
        setSubtareas([...subtareas, nuevaSubtarea]);
        setNuevaSubtarea("");
        return;
      }
      AlertaToast({
        action: `La tarea: ${nuevaSubtarea} ya fue creada`,
        fullText: true,
        type: "error",
        target: document.getElementById("dialog-agregar-tarea-interna"),
      }).fire();
    }
  };

  const eliminarSubtarea = (row) => {
    let existe = subtareas.filter((e) => e !== row);
    setSubtareas(existe);
  };

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        style={{ textAlign: "center", marginTop: "10px" }}
      >
        Subtareas
      </Typography>

      <Grid container>
        <Grid item xs={10} md={10}>
          <TextField
            fullWidth
            label="Subtarea"
            value={nuevaSubtarea}
            onChange={handleChangeNuevaSubtarea}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <Button
            fullWidth
            style={{ height: "100%" }}
            variant="outlined"
            onClick={agregarSubtarea}
          >
            <Add /> Agregar Subtarea
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre Subtarea</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subtareas.map((row, idx) => (
              <TableRow key={"subTareas_" + idx}>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => eliminarSubtarea(row)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SubtareasComponent.propTypes = {
  subTareas: PropTypes.array,
  setSubTareas: PropTypes.func,
};

export default React.memo(SubtareasComponent);
