import React, { useState } from "react";
import {
  Dialog,
  StepLabel,
  Step,
  Stepper,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Paper,
  CircularProgress,
  Stack,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import DatosIniciales from "./DatosIniciales";
import DatosSolicitante from "./DatosSolicitante";
import DatosEmpresa from "./DatosEmpresa";
import Alert from "@material-ui/lab/Alert";
import { validateRut } from "@fdograph/rut-utilities";
import Planes from "./Planes";
import Comentarios from "./Comentarios";
import { LoadingButton } from "@material-ui/lab";
import EmpleadosAdicionales from "./EmpleadosAdicionales";
import { useSelector } from "react-redux";
import { propuestasApi } from "src/services/propuestasApi";
import { AlertaToast } from "../AlertaSwall";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSteps() {
  return [
    "Encargados",
    "Adicionales",
    "Solicitante",
    "Empresa",
    "Planes",
    "Comentarios",
  ];
}

const correoValido = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// Función para formatear números telefónicos
const formatPhoneNumber = (phone) => {
  if (!phone) return "";

  // Limpiar el número primero
  const cleanPhone = phone.toString().replace(/[\s\-()+.]/g, "");

  // Si es un número chileno (comienza con 56 o 9)
  if (cleanPhone.startsWith("56") || cleanPhone.startsWith("9")) {
    // Formato chileno: +56 9 XXXX XXXX
    if (cleanPhone.startsWith("56")) {
      return cleanPhone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "+$1 $2 $3 $4");
    } else {
      return cleanPhone.replace(/(\d{1})(\d{4})(\d{4})/, "+56 $1 $2 $3");
    }
  }

  // Para otros números internacionales, formato básico
  return `+${cleanPhone.replace(/(\d{2})(\d{3})(\d{3,})/, "$1 $2 $3")}`;
};

// Función principal para validar números telefónicos
const validatePhoneNumber = (phone) => {
  if (!phone) return false;

  // Eliminar espacios, guiones, paréntesis y otros caracteres especiales
  const cleanPhone = phone.toString().replace(/[\s\-()+.]/g, "");

  // Validar que solo contenga números
  if (!/^\d+$/.test(cleanPhone)) {
    return false;
  }

  // Validar longitud (la mayoría de los números telefónicos tienen entre 8 y 15 dígitos)
  // Esto cubre números internacionales con código de país
  if (cleanPhone.length < 8 || cleanPhone.length > 15) {
    return false;
  }

  return true;
};

// Función para obtener mensaje de error específico
const getPhoneErrorMessage = (phone) => {
  if (!phone) return "El número de teléfono es requerido";

  const cleanPhone = phone.toString().replace(/[\s\-()+.]/g, "");

  if (!/^\d+$/.test(cleanPhone)) {
    return "El número de teléfono solo debe contener dígitos";
  }

  if (cleanPhone.length < 8) {
    return "El número de teléfono es demasiado corto";
  }

  if (cleanPhone.length > 15) {
    return "El número de teléfono es demasiado largo";
  }

  return "Número de teléfono inválido";
};

// Separar las validaciones en funciones específicas
const validations = {
  validateInitialData: (vendedor, empleado) => {
    const errors = [];
    if (!vendedor) errors.push("Falta ingresar Vendedor");
    if (!empleado) errors.push("Falta ingresar empleado");
    return errors;
  },

  validateSolicitante: (
    rutSolicitante,
    nombreSolicitante,
    correoSolicitante,
    telefonoSolicitante
  ) => {
    const errors = [];

    if (!rutSolicitante || !validateRut(rutSolicitante)) {
      errors.push("Rut inválido");
    }
    if (!nombreSolicitante) {
      errors.push("Falta ingresar el nombre del solicitante");
    }
    if (
      !correoSolicitante ||
      !correoSolicitante.includes("@") ||
      correoSolicitante.toLowerCase().includes("taxtic")
    ) {
      errors.push("Correo del solicitante inválido");
    }
    if (!validatePhoneNumber(telefonoSolicitante)) {
      errors.push(getPhoneErrorMessage(telefonoSolicitante));
    }
    return errors;
  },

  validateEmpresa: (data) => {
    const {
      rutEmpresa,
      nombreEmpresa,
      correoFacturacionCliente,
      correoEmpresa,
      telefonoEmpresa,
      comunaEmpresa,
      direccionEmpresa,
    } = data;

    const errors = [];

    if (!validateRut(rutEmpresa)) errors.push("Rut empresa inválido");
    if (!nombreEmpresa.trim()) errors.push("Nombre de la empresa requerido");
    if (!correoValido(correoFacturacionCliente.trim())) {
      errors.push(
        correoFacturacionCliente.trim() === ""
          ? "Correo de facturación requerido"
          : "Correo de facturación inválido"
      );
    }
    if (!correoValido(correoEmpresa.trim())) {
      errors.push(
        correoEmpresa.trim() === ""
          ? "Correo empresa requerido"
          : "Correo empresa inválido"
      );
    }
    if (correoEmpresa.includes("taxtic.com")) {
      errors.push("Correo empresa no debe ser institucional");
    }
    if (!validatePhoneNumber(telefonoEmpresa)) {
      errors.push(getPhoneErrorMessage(telefonoEmpresa));
    }
    if (!comunaEmpresa) errors.push("Comuna empresa requerida");
    if (!direccionEmpresa.trim()) errors.push("Dirección empresa requerida");

    return errors;
  },

  validatePlanes: (planesSeleccionados) => {
    const errors = [];
    if (!planesSeleccionados || planesSeleccionados.length === 0) {
      errors.push("Se debe seleccionar al menos un plan");
    }
    return errors;
  },
};

export default function NuevaPropuesta(props) {
  const { open, setOpen } = props;

  const [cargandoDatos, setCargandoDatos] = useState(false);

  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const sucursal = sucursalObtenida.refSucursal;

  const [vendedor, setVendedor] = useState(null);
  const [empleado, setEmpleado] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const [empleadosAdicionales, setEmpleadosAdicionales] = useState([]);

  const [rutSolicitante, setRutSolicitante] = useState(null);
  const [nombreSolicitante, setNombreSolicitante] = useState(null);
  const [correoSolicitante, setCorreoSolicitante] = useState(null);
  const [telefonoSolicitante, setTelefonoSolicitante] = useState(null);

  const [rutEmpresa, setRutEmpresa] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [correoEmpresa, setCorreoEmpresa] = useState("");
  const [correoFacturacionCliente, setCorreoFacturacionCliente] = useState("");
  const [telefonoEmpresa, setTelefonoEmpresa] = useState("");
  const [comunaEmpresa, setComunaEmpresa] = useState("");
  const [direccionEmpresa, setDireccionEmpresa] = useState("");
  const [sinRut, setSinRut] = useState(false);

  const [comentarios, setComentarios] = useState("");

  const [planesSeleccionados, setPlanesSeleccionados] = useState([]);

  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [colorAlerta, setColorAlerta] = useState("");
  const [addPropuesta] = propuestasApi.useAddPropuestaMutation();

  const handleClose = () => {
    setOpen(false);
  };
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  // Función auxiliar para mostrar errores
  const showErrors = (errors) => {
    if (errors.length > 0) {
      setColorAlerta("error");
      setMensajeAlerta(errors[0]); // Mostrar el primer error
      setMostrarAlerta(true);
      return true;
    }
    return false;
  };

  // Validar el paso actual
  const validateCurrentStep = () => {
    let errors = [];

    switch (activeStep) {
      case 0:
        errors = validations.validateInitialData(vendedor, empleado);
        break;
      case 2:
        errors = validations.validateSolicitante(
          rutSolicitante,
          nombreSolicitante,
          correoSolicitante,
          telefonoSolicitante
        );
        break;
      case 3:
        errors = validations.validateEmpresa({
          rutEmpresa,
          nombreEmpresa,
          correoFacturacionCliente,
          correoEmpresa,
          telefonoEmpresa,
          comunaEmpresa,
          direccionEmpresa,
        });
        break;
      case 4:
        errors = validations.validatePlanes(planesSeleccionados);
        break;
      default:
        return true;
    }

    return !showErrors(errors);
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setMostrarAlerta(false);
    }
  };

  // Función para limpiar datos del RUT
  const cleanRutFormat = (rut) => {
    return String(rut).replace(/\./g, "").replace(/-/g, "").toUpperCase();
  };

  const handleFinalizar = async () => {
    try {
      setCargandoDatos(true);

      if (!empleado?.value) {
        throw new Error("Empleado no seleccionado");
      }

      const empleadosAdicionalAux = empleadosAdicionales
        .filter((e) => e.value)
        .map((e) => e.value);

      const body = {
        empleados: empleadosAdicionalAux,
        planes: planesSeleccionados,
        refCliente: cleanRutFormat(rutEmpresa),
        nombreEmpresa: nombreEmpresa.toUpperCase(),
        correoEmpresa,
        correoFacturacion: correoFacturacionCliente,
        telefonoEmpresa,
        comunaEmpresa: comunaEmpresa.toUpperCase(),
        direccionEmpresa: direccionEmpresa.toUpperCase(),
        refEmpleado: empleado.value,
        nombreSolicitante: nombreSolicitante.toUpperCase(),
        rutSolicitante: cleanRutFormat(rutSolicitante),
        telefonoSolicitante,
        correoSolicitante,
        comentarios: comentarios.toUpperCase(),
        vendedor: vendedor?.value || "",
        sucursal,
        supervisor: supervisor?.value || "",
        rutAuxiliar: sinRut,
      };

      await addPropuesta(body);

      setOpen(false);
      AlertaToast({
        element: "Propuesta",
        action: "creada",
      }).fire();
    } catch (error) {
      setColorAlerta("error");
      setMensajeAlerta("Error al crear la propuesta: " + error.message);
      setMostrarAlerta(true);
    } finally {
      setCargandoDatos(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setMostrarAlerta(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Nueva Propuesta
            </Typography>
          </Toolbar>
        </AppBar>
        <Stepper activeStep={activeStep} style={{ margin: "10px" }}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {mostrarAlerta && (
            <Alert severity={colorAlerta}>{mensajeAlerta}</Alert>
          )}
          <Grid container>
            <Grid item xs={2} md={2}>
              {activeStep === 0 ? (
                <></>
              ) : (
                <IconButton
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{
                    top: "50%",
                    marginTop: "-50px",
                    left: "50%",
                  }}
                >
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={8} md={8}>
              {activeStep === steps.length ? (
                <Paper
                  style={{
                    height: window.screen.height - 350,
                    margin: "10px",
                    overflow: "auto",
                  }}
                >
                  {cargandoDatos ? (
                    <Stack alignItems="center">
                      <Typography variant="h4" color="primary">
                        Cargando...
                      </Typography>
                      <CircularProgress
                        style={{ marginTop: "100px", alignItems: "center" }}
                        color="primary"
                      />
                    </Stack>
                  ) : (
                    <Stack>
                      <Typography variant="h4" align="center">
                        ¿Esta seguro de generar la propuesta?
                      </Typography>
                      <Typography variant="p" align="center">
                        Presione finalizar si desea continuar...
                      </Typography>

                      <LoadingButton
                        variant="contained"
                        style={{ marginTop: "100px" }}
                        loading={cargandoDatos}
                        onClick={handleFinalizar}
                      >
                        Finalizar
                      </LoadingButton>
                    </Stack>
                  )}
                </Paper>
              ) : (
                <div style={{ height: "100%" }}>
                  <DatosIniciales
                    mostrarAlerta={mostrarAlerta}
                    vendedor={vendedor}
                    empleado={empleado}
                    supervisor={supervisor}
                    empleadosAdicionales={empleadosAdicionales}
                    setVendedor={setVendedor}
                    setEmpleado={setEmpleado}
                    setSupervisor={setSupervisor}
                    setEmpleadosAdicionales={setEmpleadosAdicionales}
                    valor={activeStep}
                  />
                  <EmpleadosAdicionales
                    empleadosAdicionales={empleadosAdicionales}
                    setEmpleadosAdicionales={setEmpleadosAdicionales}
                    valor={activeStep}
                  />
                  <DatosSolicitante
                    rutSolicitante={rutSolicitante}
                    setRutSolicitante={setRutSolicitante}
                    nombreSolicitante={nombreSolicitante}
                    setNombreSolicitante={setNombreSolicitante}
                    correoSolicitante={correoSolicitante}
                    setCorreoSolicitante={setCorreoSolicitante}
                    telefonoSolicitante={telefonoSolicitante}
                    setTelefonoSolicitante={setTelefonoSolicitante}
                    validatePhoneNumber={validatePhoneNumber}
                    formatPhoneNumber={formatPhoneNumber}
                    valor={activeStep}
                  />
                  <DatosEmpresa
                    rutEmpresa={rutEmpresa}
                    setRutEmpresa={setRutEmpresa}
                    nombreEmpresa={nombreEmpresa}
                    setNombreEmpresa={setNombreEmpresa}
                    correoEmpresa={correoEmpresa}
                    correoFacturacionCliente={correoFacturacionCliente}
                    setCorreoEmpresa={setCorreoEmpresa}
                    setCorreoFacturacionCliente={setCorreoFacturacionCliente}
                    telefonoEmpresa={telefonoEmpresa}
                    setTelefonoEmpresa={setTelefonoEmpresa}
                    validatePhoneNumber={validatePhoneNumber}
                    formatPhoneNumber={formatPhoneNumber}
                    comunaEmpresa={comunaEmpresa}
                    setComunaEmpresa={setComunaEmpresa}
                    direccionEmpresa={direccionEmpresa}
                    setDireccionEmpresa={setDireccionEmpresa}
                    sinRut={sinRut}
                    setSinRut={setSinRut}
                    valor={activeStep}
                  />

                  <Planes
                    planesSeleccionados={planesSeleccionados}
                    setPlanesSeleccionados={setPlanesSeleccionados}
                    valor={activeStep}
                  />
                  <Comentarios
                    comentarios={comentarios}
                    setComentarios={setComentarios}
                    valor={activeStep}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={2} md={2}>
              {activeStep === steps.length ? (
                <></>
              ) : (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  style={{
                    top: "50%",
                    marginTop: "-50px",
                    left: "50%",
                  }}
                >
                  <ArrowForwardIos fontSize="large" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
