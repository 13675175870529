import {
  Button,
  Card,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";
import * as locales from "@material-ui/core/locale";
import { Alerta } from "../Alerta";
import FilaTabla from "./FilaTabla";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import ModalCategoria from "../Modal/modalCategoria";

const TablaCategoria = ({
  tabIndex,
  data: categoriasYsubcategorias,
  loading: isLoadingCategoriasYsubcategorias,
}) => {
  const [state, setState] = useState(INITIAL_STATE_TABLE);
  const [showAlert, setShowAlert] = useState(false);
  const [statesModal, setStateModal] = useState(INITIAL_STATE_MODAL);
  const [rowSelected, setRowSelected] = useState({});

  const sucursalesFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["nombre"];
    return applySortFilter(
      categoriasYsubcategorias,
      getComparator(state.order, state.orderBy),
      state.filterName,
      propiedadesParaFiltrar
    );
  }, [categoriasYsubcategorias, state.filterName, state.order, state.orderBy]);

  const handleClickAdd = useCallback(() => {
    setRowSelected({});
    setStateModal({
      ...statesModal,
      abrirModal: true,
      seAgregaCategoria: true,
      type: 0,
    });
  }, [statesModal]);

  if (tabIndex !== 1) return null;

  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  const handleFilterByName = (event) => {
    setState({ ...state, filterName: event.target.value });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const esSucursalNoEncontrada = sucursalesFiltradas.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const emptyRows =
    state.currentPage > 0
      ? Math.max(
          0,
          (1 + state.currentPage) * state.rowsPerPageOptions -
            categoriasYsubcategorias.length
        )
      : 0;

  return (
    <>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs="auto">
            <TablaToolbar
              filterName={state.filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" align="center">
              Categorías
            </Typography>
          </Grid>
          <Grid item xs md textAlign="right" marginRight={2}>
            <Button
              onClick={handleClickAdd.bind(null)}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Agregar categoría
            </Button>
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer>
            {!isLoadingCategoriasYsubcategorias ? (
              <Table>
                <TablaHead
                  order={state.order}
                  orderBy={state.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={categoriasYsubcategorias?.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {sucursalesFiltradas
                    .slice(
                      state.currentPage * state.rowsPerPageOptions,
                      state.currentPage * state.rowsPerPageOptions +
                        state.rowsPerPageOptions
                    )
                    .map((item) => (
                      <FilaTabla
                        key={item.id}
                        row={item}
                        setStateModal={setStateModal}
                        setRowSelected={setRowSelected}
                        statesModal={statesModal}
                      />
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
                {esSucursalNoEncontrada && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={state.filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
          <ThemeProvider
            theme={(outerTheme) =>
              createTheme(outerTheme, locales[state.locale])
            }
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sucursalesFiltradas.length}
              rowsPerPage={state.rowsPerPageOptions}
              page={state.currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Scrollbar>
      </Card>
      <Alerta
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        color="success"
        message="Categoría configurada correctamente"
      />
      {statesModal.abrirModal && (
        <ModalCategoria
          isOpen={statesModal.abrirModal}
          setOpen={setStateModal}
          data={rowSelected}
          agregar={statesModal.seAgregaCategoria}
          statesModal={statesModal}
        />
      )}
    </>
  );
};

const TABLE_HEAD = [
  { id: "action", label: "", alignRight: false },
  { id: "nombre", label: "Nombre", alignRight: false },
  { id: "" },
];

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

const INITIAL_STATE_MODAL = {
  abrirModal: false,
  seAgregaCategoria: false,
  type: null, // 0 para categoría y 1 para subcategoría
};

export default TablaCategoria;
