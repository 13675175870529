import React, { useState, useEffect } from "react";
import { ACCESS_TOKEN, API_SERVER } from "../../utils/urlBack_End";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  Grid,
  Alert,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import axios from "axios";
import { TemplateHandler } from "easy-template-x";
import { Alerta } from "../Alerta";
import { useSelector } from "react-redux";
import { capitalizarPalabras } from "src/utils/sharedFunctions";

export default function GenerarPoderDialog(props) {
  const { open, setOpen, cliente } = props;
  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const [empleados, setEmpleados] = useState([]);

  const [empleadosSeleccionados, setEmpleadosSeleccionados] = useState([]);

  const [tipoPoder, setTipoPoder] = useState("");
  const [rutRepresentanteLegal, setRutRepresentanteLegal] = useState("");
  const [alertaValidateRut, setAlertaValidateRut] = useState(false);
  const [nombreRepresentanteLegal, setNombreRepresentanteLegal] = useState("");
  const [cargando, setCargando] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [color, setColor] = useState("error");
  const [message, setMessage] = useState("Error al generar el poder.");

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/sucursal/${sucursal}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setEmpleados(response.data);
      });
  }, [sucursal]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTipoPoder = (event) => {
    setTipoPoder(event.target.value);
  };

  function saveFile(filename, blob) {
    const blobUrl = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  const guardarDatos = async () => {
    setCargando(true);
    if (validateRut(rutRepresentanteLegal)) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let yyyy = today.getFullYear();

      let meses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      today = dd + " de " + meses[today.getMonth()] + " de " + yyyy;

      let nombreArchivoUsar = "PODER_ESPECIAL_EMPRESA.docx";
      let data = {
        ciudad: "Curicó",
        fechaActual: today,
        representanteLegal: nombreRepresentanteLegal,
        rutRepresentanteLegal: rutRepresentanteLegal,
        nombreEmpresa: cliente.nombre,
        rutEmpresa: formatRut(cliente.rut, RutFormat.DOTS_DASH),
        domicilio: cliente.direccion,
        comuna: cliente.comuna,
        empleados: empleadosSeleccionados,
      };
      if (tipoPoder !== "EMPRESA") {
        nombreArchivoUsar = "PODER_ESPECIAL_PERSONA.docx";
        data = {
          ciudad: capitalizarPalabras(sucursalObtenida.comuna),
          fechaActual: today,
          representanteLegal: nombreRepresentanteLegal,
          rutRepresentanteLegal: rutRepresentanteLegal,
          domicilio: cliente.direccion,
          comuna: cliente.comuna,
          empleados: empleadosSeleccionados,
        };
      }
      let dropboxEndpoint1 = "https://content.dropboxapi.com/2/files/download";
      let dropBoxApiArg = { path: `/Archivos/${nombreArchivoUsar}` };
      let postOptions1 = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
          "Dropbox-API-Arg": JSON.stringify(dropBoxApiArg),
        },
      };
      const response = await fetch(dropboxEndpoint1, postOptions1);
      const templateFile = await response.blob();

      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      saveFile("PoderEspecial " + cliente.nombre + ".docx", doc);
      setColor("success");
      setMessage("Poder Generado con éxito");
      setShowAlert(true);
      setCargando(false);
      setOpen(false);
    } else {
      setColor("success");
      setMessage("Error al generar poder");
      setShowAlert(true);
      setCargando(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Generar Poder</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Tipo Poder
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={tipoPoder}
                onChange={handleChangeTipoPoder}
              >
                <FormControlLabel
                  value="PERSONA NATURAL"
                  control={<Radio />}
                  label="PERSONA NATURAL"
                />
                <FormControlLabel
                  value="EMPRESA"
                  control={<Radio />}
                  label="EMPRESA"
                />
              </RadioGroup>
            </FormControl>

            {tipoPoder === "EMPRESA" || tipoPoder === "PERSONA NATURAL" ? (
              <>
                <TextField
                  label="Rut Representante Legal"
                  value={rutRepresentanteLegal}
                  onChange={(e) => {
                    if (validateRut(String(e.target.value))) {
                      setAlertaValidateRut(false);
                    } else {
                      setAlertaValidateRut(true);
                    }
                    setRutRepresentanteLegal(e.target.value);
                  }}
                  required
                  fullWidth
                />
                {alertaValidateRut && (
                  <Alert severity="error">Rut Inválido</Alert>
                )}
                <TextField
                  label="Nombre Representante Legal"
                  value={nombreRepresentanteLegal}
                  onChange={(event) => {
                    setNombreRepresentanteLegal(
                      String(event.target.value).toUpperCase()
                    );
                  }}
                  required
                  fullWidth
                />
              </>
            ) : (
              <></>
            )}

            {(tipoPoder === "EMPRESA" || tipoPoder === "PERSONA NATURAL") &&
              rutRepresentanteLegal !== "" &&
              nombreRepresentanteLegal !== "" && (
                <Grid container height={"500px"} style={{ overflowY: "auto" }}>
                  <Grid item xs={12} md={12}>
                    <Alert severity="success">
                      Cantidad de Personas: {empleadosSeleccionados.length}
                    </Alert>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      {empleados
                        .filter((empleado) => empleado.estado === "ACTIVO")
                        .map((value, idx) => {
                          return (
                            <ListItem
                              key={"listaEmpleados_" + idx}
                              disablePadding
                            >
                              <ListItemButton role={undefined} dense>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      empleadosSeleccionados.filter(
                                        (e) =>
                                          e.rutEmpleado ===
                                          formatRut(
                                            value.rut,
                                            RutFormat.DOTS_DASH
                                          )
                                      )[0]
                                        ? true
                                        : false
                                    }
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        setEmpleadosSeleccionados([
                                          ...empleadosSeleccionados,
                                          {
                                            nombreEmpleado: String(
                                              value.nombre +
                                                " " +
                                                value.apellidos
                                            ).toUpperCase(),
                                            rutEmpleado: formatRut(
                                              value.rut,
                                              RutFormat.DOTS_DASH
                                            ),
                                          },
                                        ]);
                                      } else {
                                        let empleadosAUX =
                                          empleadosSeleccionados.filter(
                                            (e) =>
                                              e.rutEmpleado !==
                                              formatRut(
                                                value.rut,
                                                RutFormat.DOTS_DASH
                                              )
                                          );
                                        setEmpleadosSeleccionados(empleadosAUX);
                                      }
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={value.nombre + " " + value.apellidos}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                    </List>
                  </Grid>
                </Grid>
              )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={cargando} onClick={handleClose}>
            Cerrar
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={cargando}
            onClick={guardarDatos}
          >
            Generar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {showAlert && (
        <Alerta
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          color={color}
          message={message}
        />
      )}
    </div>
  );
}
