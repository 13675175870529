import React, { useState, useEffect } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Stack,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Autocomplete,
} from "@material-ui/core";
import TimePicker from "@material-ui/lab/TimePicker";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import { API_SERVER } from "src/utils/urlBack_End";
import axios from "axios";
import { useSelector } from "react-redux";
import { LoadingButton } from "@material-ui/lab";
import { calendarioApi } from "src/services/calendarioApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

export default function DialogAgregarActividad({ open, setOpen, fecha }) {
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const [empleados, setEmpleados] = useState([]);
  const [horaInicio, setHoraInicio] = useState(new Date());
  const [horaTermino, setHoraTermino] = useState(new Date());

  const [agregarCalendario] = calendarioApi.useAddCalendarioMutation();

  const [nombreEvento, setNombreEvento] = useState("");
  const [linkEvento, setLinkEvento] = useState("");
  const [urlArchivo, setUrlArchivo] = useState("");

  const [privado, setPrivado] = useState(true);

  const [empleadosData, setEmpleadosData] = useState([]);
  const [empleado, setEmpleado] = useState(null);
  const [empleadoNombre, setEmpleadoNombre] = useState("");

  useEffect(() => {
    axios
      .get(
        `${API_SERVER}/empleados/empleadoSucursal/${sucursalObtenida.refSucursal}/TODOS`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setEmpleadosData(response.data);
      });
  }, [sucursalObtenida.refSucursal]);

  const handleClose = () => {
    setOpen(false);
  };

  const crearDato = () => {
    if (
      nombreEvento === "" ||
      !horaInicio ||
      !horaTermino ||
      String(nombreEvento).length > 100
    ) {
      AlertaToast({
        action: "Datos requeridos: nombre evento, hora inicio y término.",
        fullText: true,
        type: "info",
        target: document.getElementById("dialogNuevoEvento"),
      }).fire();
    } else {
      const horaInicioAux = new Date(horaInicio);
      const horaTerminoAux = new Date(horaTermino);
      const empleadoAux = [];

      empleados.forEach((item) => {
        empleadoAux.push(item.rut);
      });
      const body = {
        nombreCita: nombreEvento,
        link: linkEvento,
        urlArchivo: urlArchivo,
        fecha: fecha,
        privado: privado,
        refEmpleado: user.rut,
        refSucursal: sucursalObtenida.refSucursal,
        horaInicio: `${horaInicioAux.getHours()}:${horaInicioAux.getMinutes()}`,
        horaTermino: `${horaTerminoAux.getHours()}:${horaTerminoAux.getMinutes()}`,
        empleados: empleadoAux,
      };

      AlertaModal({
        title: "agregar el empleado",
        confirmButtonText: "Sí, agregar",
        target: document.getElementById("dialogNuevoEvento"),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            agregarCalendario(body)
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: "Nuevo evento",
                  action: "agregado",
                  target: document.getElementById("dialogNuevoEvento"),
                }).fire();
                handleClose();
              })
              .catch(() => {
                AlertaToast({
                  action: "Error en el servidor",
                  fullText: true,
                  type: "error",
                  target: document.getElementById("dialogNuevoEvento"),
                }).fire();
              });
          }
        });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" id="dialogNuevoEvento">
      <DialogTitle>Nuevo Evento</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            style={{ marginTop: "10px" }}
            value={nombreEvento}
            onChange={(e) => setNombreEvento(e.target.value)}
            label="Nombre Cita"
            helperText="Máximo 100 caracteres"
            required
          />

          <TextField
            value={linkEvento}
            onChange={(e) => setLinkEvento(e.target.value)}
            label="Link"
            helperText="No requerido"
            color="info"
          />
          <TextField
            value={urlArchivo}
            onChange={(e) => setUrlArchivo(e.target.value)}
            label="URL Archivo"
            helperText="No requerido"
            color="info"
          />

          <FormControl>
            <FormLabel id="radio-button-privado">Privado</FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-button-privado"
              value={privado}
              onChange={(e) => {
                let valorPrivado = e.target.value;
                setPrivado(valorPrivado === "false" ? false : true);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="SI" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          <TimePicker
            disablePast
            label="Seleccione Hora de Inicio"
            value={horaInicio}
            onChange={(newValue) => {
              setHoraInicio(newValue);
            }}
            renderInput={(params) => (
              <TextField required {...params} helperText={null} />
            )}
          />
          <TimePicker
            disablePast
            label="Seleccione Hora de Término"
            value={horaTermino}
            onChange={(newValue) => {
              setHoraTermino(newValue);
            }}
            renderInput={(params) => (
              <TextField required {...params} helperText={null} />
            )}
          />
        </Stack>

        <Grid container marginTop={10}>
          <Grid item xs={10} md={10}>
            <Autocomplete
              value={empleado}
              options={empleadosData}
              isOptionEqualToValue={(option, value) => option.rut === value.rut}
              getOptionLabel={(option) =>
                `${option.nombre} ${option.apellidos}`
              }
              onChange={(event, newValue) => {
                setEmpleado(newValue);
              }}
              inputValue={empleadoNombre}
              onInputChange={(event, newInputValue) => {
                setEmpleadoNombre(newInputValue);
              }}
              fullWidth
              ListboxProps={{ style: { maxHeight: "15rem" } }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione Participantes" />
              )}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <Button
              fullWidth
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (!empleado) {
                  AlertaToast({
                    action: "Debe seleccionar un participante de la lista.",
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogNuevoEvento"),
                  }).fire();
                  return;
                }
                const existeEmpleado = empleados.filter(
                  (e) => e.rut === empleado.rut
                )[0];
                if (existeEmpleado) {
                  setEmpleado(null);
                  setEmpleadoNombre("");
                  AlertaToast({
                    action: "Persona ya ingresada.",
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogNuevoEvento"),
                  }).fire();
                } else {
                  setEmpleados([...empleados, empleado]);
                  setEmpleadoNombre("");
                  setEmpleado(null);
                }
              }}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>

        <List sx={{ pt: 0 }}>
          {empleados.map((item, idx) => (
            <ListItem key={`empleadosAsociados_${idx}`}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${item.nombre} ${item.apellidos}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <LoadingButton onClick={crearDato} autoFocus>
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
