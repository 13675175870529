import React, { useState, useEffect } from "react";

import TablaContrato from "../gestionEmpleado/gestionLaboral/TablaContrato";
import { API_SERVER, dbx } from "../../utils/urlBack_End";
import axios from "axios";

export default function ContratosCliente(props) {
  const { value, cliente, setShowMessage, setColor, setMessage } = props;

  const [contratos, setContratos] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/contrato/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setContratos(response.data);
      });
  }, [cliente.rut]);

  useEffect(() => {
    if (cliente.rut !== undefined) {
      dbx
        .filesListFolder({ path: `/Clientes/${cliente.rut}/Contratos` })
        .then(() => {})
        .catch(() => {
          dbx
            .filesCreateFolderV2({
              path: `/Clientes/${cliente.rut}/Contratos`,
            })
            .then((response) => {})
            .catch((error) => {});
        })
        .finally(() => {});
    }
  }, [cliente.rut]);
  return (
    <>
      {value === 7 && (
        <>
          <TablaContrato
            contratos={contratos}
            setContratos={setContratos}
            empleado={cliente}
            setShowMessagePermiso={setShowMessage}
            setColor={setColor}
            setMessage={setMessage}
            vista={undefined}
          />
        </>
      )}
    </>
  );
}
