import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import TablaPlanMasivoExcel from "./TablaPlanMasivoExcel";
import DownloadIcon from "@material-ui/icons/Download";
import * as XLSX from "xlsx";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Step,
  StepLabel,
  Stepper,
  Alert,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { LoadingButton } from "@material-ui/lab";
import { validateRut } from "@fdograph/rut-utilities";
import { useSelector } from "react-redux";

const styles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  Container: {
    marginTop: 40,
  },
  space: {
    marginTop: 10,
  },
}));

export default function ModalPlanMasivo(props) {
  const {
    openSubirExcel,
    setOpenSubirExcel,
    setColor,
    setMessage,
    setAlerta,
    setOpenPlanMasivo,
  } = props;

  const steps = ["Plantilla", "Subida Excel", "Tabla Plan"];
  const [activeStep, setActiveStep] = useState(0);

  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const classes = styles();

  const [options, setOptions] = useState([]);
  const loading = options.length === 0;

  const [datosPlanes, setDatosPlanes] = useState([]);

  const [guardando, setGuardando] = useState(false);
  const [archivo, setArchivo] = useState(null);
  const [archivoSubido, setArchivoSubido] = useState(null);

  function createOptions(data) {
    const options = [];
    data.map((value) => {
      options.push({ nombre: value.nombre });
    });

    return options;
  }

  useEffect(() => {
    let active = true;
    let data = [];

    if (!loading) {
      return undefined;
    }

    (async () => {
      await axios
        .get(`${API_SERVER}/tipos/tipoPlanes`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          data = response.data;
        });

      if (active) {
        setOptions([...createOptions(data)]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const generarPlanes = () => {
    let planesAux = [];
    archivoSubido.map((archivo) =>
      archivo[0] !== "Rut Cliente"
        ? planesAux.push({
            rutCliente: archivo[0],
            rutEmpleado: archivo[1],
            nombrePlan: archivo[2],
            descuento: archivo[3],
            valor: archivo[4],
            valorFijo: archivo[5],
            mesCargaTareas: archivo[6],
            tipoFrecuencia: archivo[7],
          })
        : false
    );
    setDatosPlanes(planesAux);
  };

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    if (
      fileAux.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setMessage("Solo se admiten documentos en formato xlsx.");
      setColor("error");
      setAlerta(true);
      return;
    }
    setArchivo(fileAux);
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setArchivoSubido(dataParse);
    };
    reader.readAsBinaryString(fileAux);
  };

  const guardarDatos = () => {
    setGuardando(true);
    axios
      .post(
        `${API_SERVER}/planes`,
        {
          sucursal: sucursalObtenida.refSucursal,
          planes: JSON.stringify(datosPlanes),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (response.data.length === 0) {
          setColor("success");
          setMessage("Planes Guardados con Éxito");
        } else {
          setColor("error");
          setMessage(response.data);
        }
        setOpenSubirExcel(false);
        setOpenPlanMasivo(false);
        setAlerta(true);
      });
  };

  const validar = () => {
    const isValidRutCliente = !datosPlanes.some(
      (plan) => validateRut(plan.rutCliente + "") === false
    );

    const isValidRutEmpleado = !datosPlanes.some(
      (plan) => validateRut(plan.rutEmpleado + "") === false
    );
    const isValidNombrePlan = !datosPlanes.some(
      (plan) =>
        options.some((option) => option.nombre === plan.nombrePlan) === false
    );

    const isValidDescuento = !datosPlanes.some(
      (plan) => Number.isNaN(parseInt(plan.descuento)) === true
    );

    const isValidValor = !datosPlanes.some(
      (plan) => Number.isNaN(parseInt(plan.valor)) === true
    );

    const isValidValorFijo = !datosPlanes.some(
      (plan) =>
        valorFijoOptions.some((option) => option.value === plan.valorFijo) ===
        false
    );

    const isValidMesCargaTareas = !datosPlanes.some(
      (plan) =>
        MonthOptions.some(
          (month) => Number(month.value) === Number(plan.mesCargaTareas)
        ) === false
    );

    if (
      isValidRutCliente &&
      isValidRutEmpleado &&
      isValidNombrePlan &&
      isValidDescuento &&
      isValidValor &&
      isValidValorFijo &&
      isValidMesCargaTareas
    ) {
      guardarDatos();
    } else {
      mostrarAlerta(
        isValidRutCliente,
        isValidRutEmpleado,
        isValidNombrePlan,
        isValidDescuento,
        isValidValor,
        isValidValorFijo,
        isValidMesCargaTareas
      );
    }
  };

  const mostrarAlerta = (
    isValidRutCliente,
    isValidRutEmpleado,
    isValidNombrePlan,
    isValidDescuento,
    isValidValor,
    isValidValorFijo,
    isValidMesCargaTareas
  ) => {
    if (!isValidRutCliente) {
      setMessage("El rut de alguno de los clientes no es válido");
    } else if (!isValidRutEmpleado) {
      setMessage("El rut de alguno de los empleados no es válido");
    } else if (!isValidNombrePlan) {
      setMessage("El nombre de algún plan no está registrado");
    } else if (!isValidDescuento) {
      setMessage("El valor de descuento debe ser un número");
    } else if (!isValidValor) {
      setMessage("El valor debe ser un número");
    } else if (!isValidValorFijo) {
      setMessage("Las opciones para el valor fijo debe ser SI o NO");
    } else if (!isValidMesCargaTareas) {
      setMessage(
        "El valor de mes carga tareas debe tener dos cifras desde 01 a 12"
      );
    }
    setColor("error");
    setAlerta(true);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      if (archivo !== null) {
        generarPlanes();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setColor("info");
        setMessage("Debe seleccionar un archivo");
        setAlerta(true);
      }
    }
    if (activeStep === 2) {
      validar();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenSubirExcel(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeleteFile = () => {
    setArchivo(null);
  };

  const descargarExcel = () => {
    let worksheet = XLSX.utils.aoa_to_sheet([
      [
        "Rut Cliente",
        "Rut Empleado",
        "Nombre Plan",
        "Descuento",
        "Valor",
        "Valor Fijo (SI/NO)",
        "Mes Carga Tareas (01 al 12)",
        "Frecuencia Plan (MES, AÑO)",
      ],
    ]);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Plantilla Planes");
    XLSX.writeFile(new_workbook, "Plantilla Planes.xlsx");
  };

  return (
    <>
      <Dialog
        open={openSubirExcel}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}>
        <DialogTitle id="form-dialog-title">Creación Plan Masivo</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 ? (
              <React.Fragment>
                <Container className={classes.space}>
                  <label htmlFor={"contained-button-file"}>
                    <Button
                      fullWidth
                      component="span"
                      m={1}
                      variant="contained"
                      onClick={() => descargarExcel()}>
                      <DownloadIcon /> Descargar Archivo
                    </Button>
                  </label>
                </Container>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 1 && (
                  <React.Fragment>
                    <Container className={classes.Container}>
                      <Alert severity="info">
                        Haga click en el botón 'Seleccionar Archivo' para subir
                        un archivo Excel.
                      </Alert>
                    </Container>
                    <Container className={classes.space}>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        className={classes.hidden}
                        id="contained-button-file"
                        onChange={handleFileInput}
                        type="file"
                      />

                      <label htmlFor={"contained-button-file"}>
                        <Button
                          fullWidth
                          component="span"
                          m={1}
                          variant="contained">
                          Seleccionar Archivo
                        </Button>
                      </label>
                      {archivo && (
                        <span>
                          <span>
                            <> {archivo.name}</>
                          </span>

                          <span>
                            <IconButton
                              onClick={handleDeleteFile}
                              aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </span>
                      )}
                    </Container>
                  </React.Fragment>
                )}
                {activeStep === 2 && (
                  <TablaPlanMasivoExcel
                    planes={datosPlanes}
                    setPlanes={setDatosPlanes}
                  />
                )}
              </React.Fragment>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Container style={{ margin: 20 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
              <Button
                color="inherit"
                onClick={handleBack}
                sx={{ mr: 1 }}
                disabled={guardando}>
                Volver
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <LoadingButton onClick={handleNext} loading={guardando}>
                {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
              </LoadingButton>
              <Button></Button>
            </Box>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
}

const valorFijoOptions = [{ value: "SI" }, { value: "NO" }];
const MonthOptions = [
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];
