import React, { useEffect, useState } from "react";
// material
import {
  Card,
  Typography,
  CardContent,
  Stack,
  Grid,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { sucursalesApi } from "../../services/sucursalesApi";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
  pos: {
    marginBottom: 15,
  },
}));

export default function AtributosEmpleados(props) {
  const { value, empleado, tareas, setTareas } = props;
  const classes = styles();

  const { sucursal } = useSelector((state) => state.auth.user);

  const [firmaContrato, setFirmaContrato] = useState(false);
  const [vacaciones, setVacaciones] = useState(false);
  const [permisos, setPermisos] = useState(false);

  const [atributos, setAtributos] = useState([]);

  const [sucursalSeleccionada, setSucursalSeleccionada] = useState(
    sucursal.refSucursal
  );

  const [
    getSucursales,
    { data: sucursalesEmpleados = [], isSuccess: isSuccessSucursalesEmpleados },
  ] = sucursalesApi.useLazyGetSucursalesEmpleadosQuery();

  const [
    getAtributos,
    { data: atributosEmpleado = null, isSuccess: isSuccessAtributosEmpleado },
  ] = empleadosApi.useLazyGetAtributosEmpleadoQuery();

  const [actualizarAtributo] = empleadosApi.useUpdateAtributoEmpleadoMutation();

  const handleChangeSucursalSeleccionada = (event) => {
    getAtributos({
      id: empleado.rut,
      refSucursal: event.target.value,
    });
    setSucursalSeleccionada(event.target.value);
  };

  const handleChangeAtributo = (atributo) => {
    AlertaModal({
      title: "cambiar estado",
      confirmButtonText: "Sí, cambiar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          actualizarAtributo({
            id: empleado.rut,
            refSucursal: sucursalSeleccionada,
            atributo,
          })
            .unwrap()
            .then((response) => {
              AlertaToast({
                element: "atributo",
                action: "modificado",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  useEffect(() => {
    getSucursales(empleado.rut);
  }, [empleado.rut, getSucursales]);

  useEffect(() => {
    getAtributos({
      id: empleado.rut,
      refSucursal: sucursal.refSucursal,
    });
  }, [empleado, getAtributos, sucursal]);

  return (
    <div className={classes.pos}>
      {value === 1 &&
        isSuccessSucursalesEmpleados &&
        isSuccessAtributosEmpleado && (
          <Card className={classes.root} variant="outlined">
            <Typography variant="h5" component="h2" className={classes.title}>
              Atributos disponibles
            </Typography>
            <CardContent>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-sucursales">
                    Sucursal Seleccionada
                  </InputLabel>
                  <Select
                    labelId="select-sucursales"
                    value={sucursalSeleccionada}
                    label="Sucursal Seleccionada"
                    onChange={handleChangeSucursalSeleccionada}
                  >
                    {sucursalesEmpleados.map((item, key) => (
                      <MenuItem value={item.refSucursal} key={key}>
                        {item.refSucursal}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Grid container direction="row">
                  <Grid item>
                    <Typography>
                      Permitir recibir información de contratos
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1}></Grid>
                  <Grid item xs={2} md={2}>
                    <Typography
                      color={
                        atributosEmpleado &&
                        !atributosEmpleado.contrato &&
                        "primary"
                      }
                    >
                      NO
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Switch
                      checked={
                        atributosEmpleado && atributosEmpleado.contrato
                          ? atributosEmpleado.contrato === 1
                            ? true
                            : false
                          : false
                      }
                      onChange={() => handleChangeAtributo("contrato")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Typography
                      color={
                        atributosEmpleado &&
                        atributosEmpleado.contrato &&
                        "primary"
                      }
                    >
                      SI
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item>
                    <Typography>
                      Permitir recibir información de permisos
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1}></Grid>
                  <Grid item xs={2} md={2}>
                    <Typography
                      color={
                        atributosEmpleado &&
                        !atributosEmpleado.permiso &&
                        "primary"
                      }
                    >
                      NO
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Switch
                      checked={
                        atributosEmpleado && atributosEmpleado.permiso
                          ? atributosEmpleado.permiso
                          : false
                      }
                      onChange={() => handleChangeAtributo("permiso")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Typography
                      color={
                        atributosEmpleado &&
                        atributosEmpleado.permiso &&
                        "primary"
                      }
                    >
                      SI
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item>
                    <Typography>
                      Permitir recibir información de vacaciones
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1}></Grid>
                  <Grid item md={2}>
                    <Typography
                      color={
                        atributosEmpleado &&
                        !atributosEmpleado.vacacion &&
                        "primary"
                      }
                    >
                      NO
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Switch
                      checked={
                        atributosEmpleado && atributosEmpleado.vacacion
                          ? atributosEmpleado.vacacion
                          : false
                      }
                      onChange={() => handleChangeAtributo("vacacion")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography
                      color={
                        atributosEmpleado &&
                        atributosEmpleado.vacacion &&
                        "primary"
                      }
                    >
                      SI
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </CardContent>
          </Card>
        )}
    </div>
  );
}
