import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";

const FormikTextField = ({
  label,
  helperText,
  onSearchClick,
  tipoMoneda,
  inputAdornment,
  loadingSearch,
  maxWidth,
  maxLength,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  const handleSearchClick = useCallback(() => {
    if (onSearchClick) {
      onSearchClick(field.value);
    }
  }, [field.value, onSearchClick]);

  const inputProps = useMemo(() => {
    if (inputAdornment === "search") {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={loadingSearch} onClick={handleSearchClick}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      };
    } else if (inputAdornment === "otraMoneda") {
      return {
        startAdornment: (
          <InputAdornment position="start">
            {tipoMoneda !== "PESOS" ? tipoMoneda : "$"}
          </InputAdornment>
        ),
      };
    }
  }, [handleSearchClick, inputAdornment, loadingSearch, tipoMoneda]);

  const textFieldStyle = useMemo(
    () => ({
      maxWidth: maxWidth || "100%",
      width: "100%",
    }),
    [maxWidth]
  );

  return (
    <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
      <TextField
        {...field}
        {...props}
        style={textFieldStyle}
        fullWidth
        error={Boolean(meta.error) && meta.touched}
        label={label}
        helperText={meta.touched && meta.error ? meta.error : helperText}
        InputProps={inputProps}
        inputProps={{ maxLength }}
      />
    </FormControl>
  );
};
export default React.memo(FormikTextField);
