import { memo } from "react";
import { Grid, TextField } from "@material-ui/core";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";

const CalendarField = memo(
  ({
    id,
    label,
    value,
    onChange,
    inputFormat,
    touched,
    errors,
    tamanio = 6,
    views = ["day", "month", "year"],
    minDate = null,
    maxDate = null,
  }) => {
    return (
      <Grid item xs={tamanio}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <DatePicker
            id={`${id}-date-picker`}
            label={label}
            value={value}
            onChange={onChange}
            views={views}
            disableMaskedInput
            inputFormat={inputFormat}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                helperText={touched && errors}
                error={touched && Boolean(errors)}
                fullWidth
              />
            )}
            minDate={minDate}
            maxDate={maxDate}
          />
        </LocalizationProvider>
      </Grid>
    );
  }
);

export default CalendarField;
