import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../Scrollbar";
import axios from "axios";
import checkCircleOutlined from "@iconify/icons-ant-design/check-circle-outlined";
import warningOutlined from "@iconify/icons-ant-design/warning-outlined";
import {
  API_KEY_OPEN_FACTURA_DEV,
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_DEV,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
  URL_ORIGEN,
} from "src/utils/urlBack_End";
import { LoadingButton } from "@material-ui/lab";
import DownloadIcon from "@material-ui/icons/Download";
import { saveAs } from "file-saver";
import PagosFacturaAsociadoCliente from "./PagosFacturaAsociadoCliente";
import DetalleFacturaCliente from "./DetalleFacturaCliente";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExportToExcelFacturasClientes from "../ExportToExcelFacturasClientes";
import moment from "moment";
import { filter } from "lodash";
import { facturasApi } from "src/services/facturasApi";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import SearchNotFound from "../SearchNotFound";
import { CardAColor } from "../CardAColor";
import { formatAsCLP } from "src/utils/formatNumber";
import {
  calcularDiasEntreFechas,
  calcularDiasHastaHoy,
} from "src/utils/converter";
import { decodeHtmlEntities } from "src/utils/sharedFunctions";
import { calcularDiasRetraso } from "src/utils/formatTime";
import Iconify from "../iconify";
import { sentenceCase } from "change-case";
import Label from "../Label";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "10px",
  },
  pos: {
    marginBottom: 12,
  },
}));

const TABLE_HEAD = [
  { id: "sucursal", label: "Sucursal", alignRight: false },
  { id: "nombreTarea", label: "Factura", alignRight: false },
  { id: "folio", label: "Folio", alignRight: false },
  { id: "glosa", label: "Glosa", alignRight: false },
  { id: "totalFacturado", label: "Total Facturado", alignRight: false },
  { id: "estadoFactura", label: "Estado Factura", alignRight: false },
  { id: "estadoCorreo", label: "Estado Correo", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha Facturación", alignRight: false },
  { id: "fechaPago", label: "Fecha pago", alignRight: false },
  { id: "diasRetraso", label: "Retraso pago", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      if (!_user.nombreTarea) {
        _user = { ..._user, nombreTarea: _user.tipo };
      }
      return (
        _user.sucursal.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.nombreTarea.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        String(_user.folio).indexOf(query.toLowerCase()) !== -1 ||
        String(_user.valorPesos).indexOf(query.toLowerCase()) !== -1 ||
        _user.estadoFactura.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estadoCorreo.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FacturasAsociadasCliente(props) {
  const { value, cliente } = props;

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("fechaFacturacion");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");

  const { data: facturas = [], isLoading: cargandoDatosFacturas } =
    facturasApi.useGetFacturasClienteQuery(cliente.rut);

  const classes = styles();

  const [loadingDescargar, setLoadingDescargar] = useState(false);
  const [mostrarPagos, setMostrarPagos] = useState(false);
  const [tipoFactura, setTipoFactura] = useState("");

  const [facuraSeleccionada, setFacturaSeleccionada] = useState(null);
  const [mostrarDetalleFactura, setMostrarDetalleFactura] = useState(false);

  const [cargadoPeriodo, setCargadoPeriodo] = useState(false);

  const [periodoFacturacion, setPeriodoFacturacion] = useState(30);

  const handleChangePeriodoFacturacion = (event) => {
    axios
      .put(
        API_SERVER + "/facturas/vencimientoFactura",
        {
          refCliente: cliente.rut,
          periodo: Number.parseInt(event.target.value),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setCargadoPeriodo(false);
        axios
          .get(`${API_SERVER}/facturas/vencimientoFactura/${cliente.rut}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response2) {
            if (response2.data === -2) window.location.reload();
            setPeriodoFacturacion(response2.data);
            setCargadoPeriodo(true);
          });
      });
  };

  const facturasPagadas = () => {
    return facturas.filter((e) => e.estadoFactura === "PAGADO");
    // let facturasFinal = [];

    // let facturasTareasPagadas = facturas.filter(
    //   (e) => e.estadoFactura === "PAGADO"
    // );
    // // let facturasPlanesPagadas = facturas.filter(
    // //   (e) => e.estadoFactura === "PAGADO"
    // // );
    // facturasTareasPagadas.forEach((e) => {
    //   facturasFinal.push(e);
    // });
    // // facturasPlanesPagadas.forEach((e) => {
    // //   facturasFinal.push(e);
    // // });

    // return facturasFinal;
  };
  const facturasPendientes = () => {
    return facturas.filter(
      (e) =>
        e.estadoFactura !== "PAGADO" &&
        e.estadoFactura !== "ANULADO" &&
        e.estadoFactura !== "INCOBRABLE"
    );
    // let facturasFinal = [];
    // let facturasTareasPagadas = facturas.filter(
    //   (e) => e.estadoFactura !== "PAGADO"
    // );
    // let facturasPlanesPagadas = facturas.filter(
    //   (e) => e.estadoFactura !== "PAGADO"
    // );
    // facturasTareasPagadas.forEach((e) => {
    //   facturasFinal.push(e);
    // });
    // facturasPlanesPagadas.forEach((e) => {
    //   facturasFinal.push(e);
    // });

    // return facturasFinal;
  };

  const facturasTotal = () => {
    return facturas;
  };

  const calculartiempoPromedioPago = () => {
    let facturasFiltradas = facturas.filter(
      (e) => e.estadoFactura !== "ANULADO" && e.estadoFactura !== "INCOBRABLE"
    );

    let facturasPendientes = facturasFiltradas.filter(
      (e) => e.estadoFactura !== "PAGADO"
    );

    let facturasPagadas = facturasFiltradas.filter(
      (e) => e.estadoFactura === "PAGADO"
    );

    let sumaDiasPendientes = facturasPendientes.map((factura) =>
      calcularDiasHastaHoy(factura.fechaFacturacion)
    );

    let sumaDiasPagados = facturasPagadas.map((factura) =>
      calcularDiasEntreFechas(factura.fechaFacturacion, factura.fechaPago)
    );

    if (sumaDiasPendientes.length + sumaDiasPagados.length !== 0) {
      return (
        Math.round(
          (sumaDiasPendientes.reduce((accum, current) => accum + current, 0) +
            sumaDiasPagados.reduce((accum, current) => accum + current, 0)) /
            (sumaDiasPendientes.length + sumaDiasPagados.length)
        ) + " Días"
      );
    } else {
      return "Sin facturas";
    }
  };

  const totalPagadasFacturas =
    facturasPagadas().reduce(
      (accum, current) => accum + current.valorPesos,
      0
    ) +
    facturasPendientes().reduce((accum, current) => accum + current.abono, 0);

  const totalPendientesFacturas = facturasPendientes().reduce(
    (accum, current) => accum + current.deuda,
    0
  );

  const totalFacturas = facturasTotal().reduce((acumulador, factura) => {
    if (
      factura.estadoFactura === "PAGADO" ||
      factura.estadoFactura === "ABONO" ||
      factura.estadoFactura === "FACTURADO"
    ) {
      return acumulador + factura.valorPesos;
    } else {
      return acumulador + 0;
    }
  }, 0);

  const tiempoPromedioPago = calculartiempoPromedioPago();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = facturas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const filteredUsers = applySortFilter(
    facturas,
    getComparator(order, orderBy),
    filterName
  );
  const isUserNotFound = filteredUsers === 0;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - facturas.length) : 0;

  return (
    <div className={classes.pos}>
      {value === 6 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" className={classes.title}>
              Facturas
            </Typography>

            {cargadoPeriodo && (
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Período Vencimiento Facturación
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={periodoFacturacion}
                          label="Período Vencimiento Facturación"
                          onChange={handleChangePeriodoFacturacion}
                        >
                          <MenuItem value={30}>30 Días</MenuItem>
                          <MenuItem value={60}>60 Días</MenuItem>
                          <MenuItem value={90}>90 Días</MenuItem>
                          <MenuItem value={120}>120 Días</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}

            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} md={6}>
                <CardAColor
                  valor={
                    totalPagadasFacturas !== 0
                      ? formatAsCLP(totalPagadasFacturas)
                      : "$0"
                  }
                  titulo={"Total Facturas Pagadas"}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CardAColor
                  valor={
                    totalPendientesFacturas !== 0
                      ? formatAsCLP(totalPendientesFacturas)
                      : "$0"
                  }
                  titulo={"Total Facturas Pendientes"}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CardAColor
                  valor={
                    totalFacturas !== 0 ? formatAsCLP(totalFacturas) : "$0"
                  }
                  titulo={"Total Facturas"}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CardAColor
                  valor={tiempoPromedioPago}
                  titulo={"Tiempo Promedio Pago"}
                />
              </Grid>
              <br />
              <br />
              <br />
              <br />
              <br />

              <Grid item xs={4} md={4}>
                <ExportToExcelFacturasClientes
                  data={facturasPagadas()}
                  filename={"Facturas Pagadas_" + cliente.nombre}
                  nombreBoton={"Facturas Pagadas"}
                  cliente={cliente}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <ExportToExcelFacturasClientes
                  data={facturasPendientes()}
                  filename={"Facturas Pendientes " + cliente.nombre}
                  nombreBoton={"Facturas Pendientes"}
                  cliente={cliente}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <ExportToExcelFacturasClientes
                  data={facturasTotal()}
                  filename={"Facturas " + cliente.nombre}
                  nombreBoton={"Facturas"}
                  cliente={cliente}
                />
              </Grid>
            </Grid>

            <Card style={{ marginTop: "30px" }}>
              <TablaToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {!cargandoDatosFacturas && (
                    <Table>
                      <TablaHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={facturas.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const diasRetraso =
                              row.estadoFactura.toLowerCase() !== "pagado" &&
                              row.estadoFactura.toLowerCase() !== "anulado" &&
                              row.estadoFactura.toLowerCase() !== "incobrable"
                                ? calcularDiasRetraso(row.fechaFacturacion)
                                : 0;

                            return (
                              <TableRow key={"facturas_" + index}>
                                <TableCell component="th" scope="row">
                                  {row.sucursal}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {sentenceCase(row.tipo)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.folio}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.detalleFactura
                                    ? decodeHtmlEntities(
                                        row.detalleFactura,
                                        document
                                      )
                                    : "S/I"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {formatAsCLP(row.valorPesos)}
                                </TableCell>
                                <TableCell>
                                  <Label
                                    variant="ghost"
                                    color={
                                      row.estadoFactura === "FACTURADO"
                                        ? "success"
                                        : row.estadoFactura === "PAGADO"
                                        ? "info"
                                        : "error"
                                    }
                                  >
                                    {sentenceCase(row.estadoFactura)}
                                  </Label>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {sentenceCase(row.estadoCorreo)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.fechaFacturacion === ""
                                    ? row.fechaFacturacion
                                    : moment(
                                        row.fechaFacturacion,
                                        "YYYY/MM/DD"
                                      ).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.fechaPago ? row.fechaPago : ""}
                                </TableCell>
                                <TableCell align="left">
                                  {diasRetraso > 0 ? (
                                    <Chip
                                      variant="outlined"
                                      icon={
                                        <Iconify
                                          width={14}
                                          icon={warningOutlined}
                                        />
                                      }
                                      label={`${diasRetraso} días`}
                                      color="error"
                                      size="small"
                                    />
                                  ) : (
                                    <Chip
                                      variant="outlined"
                                      icon={
                                        <Iconify
                                          width={14}
                                          icon={checkCircleOutlined}
                                        />
                                      }
                                      label="Sin retraso"
                                      color="success"
                                      size="small"
                                    />
                                  )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.token && (
                                    <LoadingButton
                                      color="primary"
                                      loading={loadingDescargar}
                                      onClick={() => {
                                        setLoadingDescargar(true);
                                        axios
                                          .get(
                                            URL_ORIGEN.includes(
                                              "http://localhost:3000"
                                            )
                                              ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${row.token}/pdf`
                                              : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${row.token}/pdf`,
                                            {
                                              headers: {
                                                apikey: URL_ORIGEN.includes(
                                                  "http://localhost:3000"
                                                )
                                                  ? API_KEY_OPEN_FACTURA_DEV
                                                  : API_KEY_OPEN_FACTURA_PROD,
                                              },
                                            }
                                          )
                                          .then((response) => {
                                            saveAs(
                                              "data:application/pdf;base64," +
                                                response.data.pdf,
                                              row.folio + ".pdf"
                                            );
                                            setLoadingDescargar(false);
                                          })
                                          .catch(() => {
                                            setLoadingDescargar(false);
                                          });
                                      }}
                                    >
                                      <DownloadIcon
                                        style={{ marginRight: "0.5rem" }}
                                      />{" "}
                                      Descargar
                                    </LoadingButton>
                                  )}

                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      setFacturaSeleccionada(row);
                                      setTipoFactura(row.tipo);
                                      setMostrarPagos(true);
                                    }}
                                  >
                                    <VisibilityIcon
                                      style={{ marginRight: "0.5rem" }}
                                    />{" "}
                                    Ver Pagos
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  )}
                </TableContainer>
              </Scrollbar>

              <ThemeProvider
                theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={!cargandoDatosFacturas ? facturas.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </ThemeProvider>
            </Card>
          </CardContent>
        </Card>
      )}
      {mostrarPagos && (
        <PagosFacturaAsociadoCliente
          open={mostrarPagos}
          setOpen={setMostrarPagos}
          factura={facuraSeleccionada}
          tipoFactura={tipoFactura}
        />
      )}
      {mostrarDetalleFactura && (
        <DetalleFacturaCliente
          open={mostrarDetalleFactura}
          setOpen={setMostrarDetalleFactura}
          factura={facuraSeleccionada}
        />
      )}
    </div>
  );
}
