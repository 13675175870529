import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const dteApi = createApi({
  reducerPath: "dteApi",
  baseQuery: baseQuery(`${API_SERVER}/dte`),
  tagTypes: ["DTE"],
  endpoints: (builder) => ({
    crearDTE: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DTE"],
    }),
    generarDTEMasivoExento: builder.mutation({
      query: ({ id, tipoFactura }) => ({
        url: `/masivo/exento/${id}`,
        method: "POST",
        params: {
          tipoFactura,
        },
      }),
      invalidatesTags: ["DTE"],
    }),
    obtenerInfoContribuyente: builder.query({
      query: (rut) => ({
        url: `/infoContribuyente/${rut}`,
      }),
    }),
  }),
});

export const {
  useObtenerInfoContribuyenteQuery,
  useLazyObtenerInfoContribuyenteQuery,
  useCrearDTEMutation,
  useGenerarDTEMasivoExentoMutation,
} = dteApi;
