import React, { useState, useEffect, useRef } from "react";
import { API_SERVER, ACCESS_TOKEN } from "../../utils/urlBack_End";
// material
import {
  Card,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Alert,
} from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import { TemplateHandler } from "easy-template-x";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";

import LoadingButton from "@material-ui/lab/LoadingButton";

import { dbx } from "../../utils/urlBack_End";
import { useSelector } from "react-redux";
import { capitalizarPalabras } from "src/utils/sharedFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    minWidth: "100px",
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
  hidden: {
    display: "none",
  },
}));

function saveFile(filename, blob) {
  const blobUrl = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.download = filename;
  link.href = blobUrl;

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
    link = null;
  }, 0);
}
const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

export default function VerPropuestaArchivos(props) {
  const { propuesta, setPropuesta } = props;
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const classes = useStyles();

  const [datosAsociados, setDatosAsociados] = useState([]);

  const [file, setFile] = useState(null);
  const [showFile, setShowFile] = useState(!!propuesta.urlPropuesta);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/propuestas/relacionPlan/exportar/${propuesta.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setDatosAsociados(response.data);
        }
      });
  }, [propuesta.id]);

  const generateDocument = async () => {
    let dropboxEndpoint1 = "https://content.dropboxapi.com/2/files/download";
    let dropBoxApiArg = { path: "/Archivos/PropuestaMODELO.docx" };
    let postOptions1 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Dropbox-API-Arg": JSON.stringify(dropBoxApiArg),
      },
    };
    const response = await fetch(dropboxEndpoint1, postOptions1);
    const templateFile = await response.blob();
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let yyyy = today.getFullYear();

    let planesAux = [];

    datosAsociados.forEach((e) => {
      let descripcionTareaAUX = "";
      if (e.descripcion !== "") {
        descripcionTareaAUX = e.descripcion;
      }
      planesAux.push({
        descripcion: descripcionTareaAUX,
        nombreCliente: e.nombreCliente,
        nombrePlan: e.nombrePlan,
        tipoMoneda: e.tipoMoneda,
        valor: e.valor,
      });
    });

    let meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    today = dd + " de " + meses[today.getMonth()] + " de " + yyyy;
    const data = {
      ciudad: capitalizarPalabras(sucursalObtenida.comuna),
      fechaActual: today,
      nombreCliente: capitalizarPalabras(propuesta.nombreSolicitante),
      nombreEmpleado: capitalizarPalabras(`${user.nombre} ${user.apellidos}`),
      Planes: planesAux,
    };
    const handler = new TemplateHandler();
    const doc = await handler.process(templateFile, data);
    saveFile("Propuesta " + propuesta.nombreSolicitante + ".docx", doc);
  };

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    if (fileAux.type !== "application/pdf") {
      return;
    }
    setFile(fileAux);
    setShowFile(true);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setShowFile(false);
  };

  const actualizarBaseDatos = async (task) => {
    const update = {
      refEmpleado: task.refEmpleado,
      nombreSolicitante: task.nombreSolicitante,
      rutSolicitante: task.rutSolicitante,
      telefonoSolicitante: task.telefonoSolicitante,
      correoSolicitante: task.correoSolicitante,
      estado: task.estado,
      fechaEnvioCorreo: task.fechaEnvioCorreo,
      comentarios: task.comentarios,
      supervisor: task.supervisor,
      urlPropuesta: task.urlPropuesta,
    };
    try {
      await axios.put(`${API_SERVER}/propuestas/${task.id}`, update, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
    } catch (ex) {}
  };

  const deleteFileFromDropbox = () => {
    return dbx.filesDeleteV2({ path: `/Propuestas/${propuesta.id}.pdf` });
  };

  const deleteFileFromDB = async () => {
    const update = {
      refEmpleado: propuesta.refEmpleado,
      nombreSolicitante: propuesta.nombreSolicitante,
      rutSolicitante: propuesta.rutSolicitante,
      telefonoSolicitante: propuesta.telefonoSolicitante,
      correoSolicitante: propuesta.correoSolicitante,
      estado: propuesta.estado,
      fechaEnvioCorreo: propuesta.fechaEnvioCorreo,
      comentarios: propuesta.comentarios,
      supervisor: propuesta.supervisor,
      urlPropuesta: "",
    };

    axios
      .put(
        `${API_SERVER}/propuestas/${propuesta.id}`,
        {
          ...update,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data === -2) window.location.reload();
      });
  };

  const removeFile = async () => {
    const propuestaAux = { ...propuesta };
    propuestaAux.urlArchivo = "";
    setPropuesta(propuestaAux);
    try {
      await deleteFileFromDropbox();
      await deleteFileFromDB();
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  async function uploadFile() {
    setLoading(true);
    if (!file && propuesta.urlPropuesta) return removeFile();
    if (!file) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      return;
    }

    try {
      await dbx.filesUpload({
        path: "/Propuestas/" + propuesta.id + ".pdf",
        contents: file,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: "/Propuestas/" + propuesta.id + ".pdf",
        });
        let url = shareResponse.result.url;
        let propuestaAux = { ...propuesta };
        propuestaAux.urlPropuesta = url;
        setPropuesta(propuestaAux);
        actualizarBaseDatos(propuestaAux);
      } catch (ex) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;
        let propuestaAux = { ...propuesta };
        propuestaAux.urlPropuesta = url;
        setPropuesta(propuestaAux);
        actualizarBaseDatos(propuestaAux);
      } finally {
        setLoading(false);
      }
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            marginTop: "30px",
          }}
        >
          <Card>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="/static/icons/word.svg"
                onClick={generateDocument}
              />
              <CardContent>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  component="p"
                  style={{ textAlign: "center" }}
                >
                  Exportar Propuesta Word
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card>
            <CardContent>
              <RootStyle>
                <div style={{ padding: "1rem" }}>
                  <Alert severity="info">
                    Haga click en el botón 'Seleccionar Archivo' para subir un
                    documento en formato PDF asociado a esta tarea.
                  </Alert>

                  {propuesta.estado === "ENVIADA" && (
                    <Alert severity="info">
                      La propuesta ya se encuentra enviada, solo se puede
                      visualizar.
                    </Alert>
                  )}

                  <Alert severity="warning">
                    Precaución: Solo se puede adjuntar un archivo por tarea.
                  </Alert>

                  <div style={{ marginTop: "10px" }}>
                    <input
                      accept="application/pdf"
                      className={classes.hidden}
                      id="contained-button-file"
                      onChange={handleFileInput}
                      type="file"
                      disabled={
                        (propuesta.estado === "ACEPTADA" ||
                          propuesta.estado === "ENVIADA") &&
                        true
                      }
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        component="span"
                        m={1}
                        variant="contained"
                        disabled={
                          (propuesta.estado === "ACEPTADA" ||
                            propuesta.estado === "ENVIADA") &&
                          true
                        }
                      >
                        Seleccionar Archivo
                      </Button>
                    </label>

                    {showFile && (propuesta.urlPropuesta || file) && (
                      <span>
                        <span
                          style={
                            propuesta.urlPropuesta ? { cursor: "pointer" } : {}
                          }
                        >
                          {propuesta.urlPropuesta ? (
                            <a
                              href={propuesta.urlPropuesta}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              {propuesta.id}
                              {".pdf"}
                            </a>
                          ) : (
                            <>
                              {" "}
                              {propuesta.id}
                              {".pdf"}
                            </>
                          )}
                        </span>

                        <span>
                          <IconButton
                            onClick={handleDeleteFile}
                            aria-label="delete"
                            disabled={
                              (propuesta.estado === "ACEPTADA" ||
                                propuesta.estado === "ENVIADA") &&
                              true
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </span>
                    )}
                    <Stack direction="row" justifyContent="flex-end" mb={5}>
                      <LoadingButton
                        loading={loading}
                        onClick={uploadFile}
                        variant="contained"
                        disabled={
                          (propuesta.estado === "ACEPTADA" ||
                            propuesta.estado === "ENVIADA") &&
                          true
                        }
                      >
                        Guardar Archivos Adjuntos
                      </LoadingButton>
                    </Stack>
                  </div>
                </div>
              </RootStyle>
            </CardContent>
          </Card>
        </Stack>
      </Card>
    </>
  );
}
