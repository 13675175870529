import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../../utils/urlBack_End";
import axios from "axios";
import TablaBitacora from "./TablaBitacora";

export default function Bitacora(props) {
  const {
    valueLaboral,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage,
    vista,
  } = props;

  const [bitacoras, setBitacoras] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/bitacora/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setBitacoras(response.data);
      });
  }, [empleado.rut]);
  return (
    <>
      {valueLaboral === 2 && (
        <>
          <TablaBitacora
            bitacoras={bitacoras}
            setBitacoras={setBitacoras}
            empleado={empleado}
            setShowMessagePermiso={setShowMessagePermiso}
            setColor={setColor}
            setMessage={setMessage}
            vista={vista}
          />
        </>
      )}
    </>
  );
}
