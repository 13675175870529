import React, { useState } from "react";
import { API_SERVER } from "../../../utils/urlBack_End";

import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import axios from "axios";
import { useSelector } from "react-redux";

export default function EditarBitacora(props) {
  const {
    open,
    setOpen,
    setBitacoras,
    bitacora,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage
  } = props;

  const [descripcionBitacora, setDescripcionBitacora] = useState(bitacora.descripcion)
  const [cargando, setCargando] = useState(false);

  const {
    empleado: user,
  } = useSelector((state) => state.auth.user);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDescripcion = (event) => {
    setDescripcionBitacora(event.target.value);
  };


  const guardarDatos = () => {
    setCargando(true);
    axios
      .put(`${API_SERVER}/empleados/bitacora/${bitacora.fecha}/${empleado.rut}`, {
        descripcion: String(descripcionBitacora).toUpperCase(),
      },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
          }
        })
      .then(function (response) {
        axios
          .get(`${API_SERVER}/empleados/bitacora/${empleado.rut}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem("token")
            }
          })
          .then(function (response2) {
            if (response2.data === -2) window.location.reload();
            setBitacoras(response2.data);
            setMessage("Bitácora ingresada correctamente.");
            setColor("success");
            setCargando(false);
            setShowMessagePermiso(true);
            setCargando(false);
            setOpen(false);
          }).catch(() => {
            setMessage("Bitácora ingresada correctamente.");
            setColor("success");
            setCargando(false);
            setShowMessagePermiso(true);
            setCargando(false);
            setOpen(false);

          });

      }).catch(() => {
        setMessage("Error al ingresar la bitácora");
        setColor("error");
        setCargando(false);
        setShowMessagePermiso(true);
        setCargando(false);

      });

  };
  const cerrar = () => {
    handleClose();
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Editar Bitácora</DialogTitle>
          <DialogContent>
            <TextField
              value={descripcionBitacora}
              onChange={handleChangeDescripcion}
              required
              autoFocus
              multiline
              margin="dense"
              label="Descripción"
              fullWidth
              rows={10}
            />

          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={cargando}
              onClick={cerrar}
            >
              Cerrar
            </LoadingButton>
            <LoadingButton
              loading={cargando}
              onClick={guardarDatos}
              variant="contained"
            >
              Agregar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
