import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    cargandoSimpleModal: false,
  },
  reducers: {
    uiCargandoSimpleModal: (state, param) => {
      const { payload } = param;
      state.user = payload;
    },
    uiRemoveCargandoSimpleModal: (state) => {
      state.cargandoSimpleModal = false;
    },
  },
});

export const { uiCargandoSimpleModal, uiRemoveCargandoSimpleModal } =
  settingsSlice.actions;
export default settingsSlice.reducer;
