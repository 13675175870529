import React, { useRef, useState, useEffect } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  Skeleton,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import * as locales from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Titulo } from "../components/Titulo";
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  TareasListHead,
  TareasListToolbar,
} from "../components/_dashboard/tareas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ModifyTaskStateModal from "../components/_dashboard/tareas/ModifyTaskStateModal";
import { Alerta } from "../components/Alerta";
import { URL_ORIGEN } from "../utils/urlBack_End";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ArchivoAdjuntoDialogPropuesta from "src/components/verpropuesta/ArchivoAdjuntoDialogPropuesta";
import ExportToExcel from "../components/ExportToExcel";
import NuevaPropuesta from "src/components/NuevaPropuesta/NuevaPropuesta";
import { cleanRut, validateRut } from "@fdograph/rut-utilities";
import { useSelector } from "react-redux";
import moment from "moment";
import { propuestasApi } from "src/services/propuestasApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { empleadosApi } from "src/services/empleadosApi";
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_el) => {
      return (
        _el.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _el.nombreSolicitante.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _el.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _el.apellidos.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _el.fechaCreacion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _el.fechaEnvioCorreo.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _el.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _el.correoSolicitante.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Propuestas() {
  const navigate = useNavigate();
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("tipoTarea");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");
  const [propuestas, setPropuestas] = useState([]);
  const [showModifyTaskState, setShowModifyTaskState] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState();
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const tableHead = useState(defineTableHeader())[0];
  const [loadingTable, setLoadingTable] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [color, setColor] = useState("success");
  const [openModalArchivo, setOpenModalArchivo] = useState(false);
  const [modalPropuesta, setOpenModalPropuesta] = useState(false);
  const [cargandoDatos, setCargandoDatos] = useState(false);

  const [
    getPropuestasEmpleado,
    {
      data: empleadoData,
      isSuccess: isSuccessEmpleadoData,
      isFetching: isFetchingEmpleadoData,
    },
  ] = propuestasApi.useLazyGetPropuestasEmpleadoQuery();

  const [
    getPropuestasSucursal,
    {
      data: sucursalData,
      isSuccess: isSuccessSucursalData,
      isFetching: isFetchingSucursalData,
    },
  ] = propuestasApi.useLazyGetPropuestasSucursalesQuery();

  useEffect(() => {
    if (
      rolObtenido === "SUPER_ADMIN" ||
      rolObtenido === "ADMIN" ||
      rolObtenido === "SUPERVISOR"
    ) {
      getPropuestasSucursal(sucursalObtenida.refSucursal);
    } else {
      getPropuestasEmpleado({
        usuario: user.rut,
        id: sucursalObtenida.refSucursal,
      });
    }
  }, [
    getPropuestasEmpleado,
    getPropuestasSucursal,
    rolObtenido,
    sucursalObtenida,
    user,
  ]);

  useEffect(() => {
    if (
      rolObtenido === "SUPER_ADMIN" ||
      rolObtenido === "ADMIN" ||
      rolObtenido === "SUPERVISOR"
    ) {
      setLoadingTable(true);
      if (isSuccessSucursalData) {
        setPropuestas(sucursalData);
        setLoadingTable(false);
      }
    }
  }, [
    isFetchingSucursalData,
    isSuccessSucursalData,
    rolObtenido,
    sucursalData,
  ]);

  useEffect(() => {
    if (
      rolObtenido !== "SUPER_ADMIN" &&
      rolObtenido !== "ADMIN" &&
      rolObtenido !== "SUPERVISOR"
    ) {
      setLoadingTable(true);
      if (isSuccessEmpleadoData) {
        setPropuestas(empleadoData);
        setLoadingTable(false);
      }
    }
  }, [
    empleadoData,
    isFetchingEmpleadoData,
    isSuccessEmpleadoData,
    rolObtenido,
  ]);

  const [eliminarPropuesta] = propuestasApi.useDeletePropuestaMutation();
  const [actualizarPropuesta] =
    propuestasApi.useUpdateEstadoPropuestaMutation();
  const [crearHistorialPropuesta] =
    empleadosApi.useAddHistorialAccionesMutation();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (URL_ORIGEN.includes("http://localhost:3000")) {
    } else {
      crearHistorialPropuesta({
        user: user.rut,
        body: {
          urlAccion: URL_ORIGEN,
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  }, [crearHistorialPropuesta, user.rut]);

  function defineTableHeader() {
    const tableHeadAux = [
      {
        id: "nombreSolicitante",
        label: "Nombre Solicitante",
        alignRight: false,
      },
      {
        id: "correoSolicitante",
        label: "Correo Solicitante",
        alignRight: false,
      },
      {
        id: "telefonoSolicitante",
        label: "Teléfono Solicitante",
        alignRight: false,
      },
      { id: "fechaCreacion", label: "Fecha Creación", alignRight: false },
      {
        id: "fechaEnvioCorreo",
        label: "Fecha Envío Correo",
        alignRight: false,
      },
      { id: "estado", label: "Estado", alignRight: false },
      { id: "", label: "", alignRight: false },
    ];

    if (
      rolObtenido === "ADMIN" ||
      rolObtenido === "SUPER_ADMIN" ||
      rolObtenido === "SUPERVISOR"
    ) {
      tableHeadAux.splice(
        0, // start at index 0
        0, // remove 0 items
        // add the following item
        {
          id: "nombreEmpleado",
          label: "Nombre Empleado",
          alignRight: false,
        }
      );
    }
    return tableHeadAux;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEliminarPropuesta = (id) => {
    AlertaModal({
      title: "eliminar la propuesta",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarPropuesta(id)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "propuesta",
                action: "eliminada",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = propuestas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangeTaskState = (id) => {
    const selectedTaskAux = propuestas.find((t) => t.id === id);
    setSelectedProposal(selectedTaskAux);
    setShowModifyTaskState(true);
  };

  const doChangeTaskState = (newState, rutEmpresa) => {
    setCargandoDatos(true);
    if (
      newState === "ENVIADA" &&
      (selectedProposal.urlPropuesta === "" ||
        selectedProposal.urlPropuesta === " ")
    ) {
      setError("Debes adjuntar propuesta.");
      setShowError(true);
      setCargandoDatos(false);
    } else if (
      (selectedProposal.rutAuxiliar ||
        selectedProposal.rutAuxiliar === "1" ||
        selectedProposal.rutAuxiliar === 1) &&
      newState === "ACEPTADA" &&
      rutEmpresa === "" &&
      !validateRut(rutEmpresa)
    ) {
      setError("Debes ingresar un RUT válido.");
      setShowError(true);
      setCargandoDatos(false);
    } else {
      if (selectedProposal.estado === newState) {
        setCargandoDatos(false);
        return setShowModifyTaskState(false);
      }
      updateProposalInDB(selectedProposal.id, newState, rutEmpresa);
    }
  };

  const updateProposalInDB = async (id, state, rutEmpresa) => {
    let rutEmpresaRealAUX = "";

    let request = {
      id: id,
      body: {
        estado: state,
      },
    };
    if (rutEmpresa !== "") {
      rutEmpresaRealAUX = String(rutEmpresa).toUpperCase();
      rutEmpresaRealAUX = cleanRut(rutEmpresaRealAUX);
      request = {
        ...request,
        body: {
          ...request.body,
          rutNuevo: rutEmpresaRealAUX,
        },
      };
    }
    actualizarPropuesta(request)
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Propuesta",
          action: "actualizada",
        }).fire();
      })
      .catch(() => {
        AlertaToast({
          action: "No se pudo actualizar la propuesta en la base de datos.",
          fullText: true,
          type: "error",
        }).fire();
      })
      .finally(() => {
        setCargandoDatos(false);
        setShowModifyTaskState(false);
      });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - propuestas.length) : 0;

  const filteredItems = applySortFilter(
    propuestas,
    getComparator(order, orderBy),
    filterName
  );

  const isElementNotFound = filteredItems.length === 0;

  const handleOpenModalPropuesta = () => {
    setOpenModalPropuesta(true);
  };

  /*const handleCloseModalPropuesta = () => {
    setOpenModalPropuesta(false);
  };*/

  const renderTableBody = () => {
    if (rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") {
      return filteredItems
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, idx) => {
          const {
            id,
            nombreSolicitante,
            correoSolicitante,
            telefonoSolicitante,
            fechaCreacion,
            fechaEnvioCorreo,
            estado,
            nombre,
            apellidos,
          } = row;
          const isItemSelected = selected.indexOf(id) !== -1;

          return (
            <TableRow
              hover
              key={idx}
              tabIndex={-1}
              role="checkbox"
              selected={isItemSelected}
              aria-checked={isItemSelected}
            >
              {(rolObtenido === "SUPER_ADMIN" ||
                rolObtenido === "ADMIN" ||
                rolObtenido === "SUPERVISOR") && (
                <TableCell
                  align="left"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/dashboard/propuestas/${id}`);
                  }}
                >
                  {nombre + " " + apellidos}
                </TableCell>
              )}
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
                align="left"
              >
                {nombreSolicitante}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                align="left"
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
              >
                {correoSolicitante}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
                align="left"
              >
                {telefonoSolicitante}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                align="left"
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
              >
                {fechaCreacion === ""
                  ? fechaCreacion
                  : moment(fechaCreacion, "YYYY/MM/DD").format("DD/MM/YYYY") !==
                    "Invalid date"
                  ? moment(fechaCreacion).format("DD/MM/YYYY")
                  : fechaCreacion}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                align="left"
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
              >
                {fechaEnvioCorreo === ""
                  ? fechaEnvioCorreo
                  : moment(fechaEnvioCorreo, "YYYY/MM/DD").format(
                      "DD/MM/YYYY"
                    ) !== "Invalid date"
                  ? moment(fechaEnvioCorreo).format("DD/MM/YYYY")
                  : fechaEnvioCorreo}
              </TableCell>
              <TableCell align="left">
                {estado !== "ACEPTADA" && estado !== "RECHAZADA" ? (
                  <div>
                    <Label
                      onClick={() => handleChangeTaskState(id)}
                      style={{ cursor: "pointer" }}
                      variant="ghost"
                      color={(estado === "CREADA" && "warning") || "info"}
                    >
                      {sentenceCase(estado)}
                    </Label>
                    {estado !== "ENVIADA" && (
                      <Tooltip title="Adjuntar">
                        <IconButton
                          onClick={() => {
                            setSelectedProposal(row);
                            setOpenModalArchivo(true);
                          }}
                        >
                          <AttachmentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <Label
                    variant="ghost"
                    color={
                      (estado === "RECHAZADA" && "error") ||
                      (estado === "CREADA" && "warning") ||
                      (estado === "ACEPTADA" && "success") ||
                      "info"
                    }
                  >
                    {sentenceCase(estado)}
                  </Label>
                )}
              </TableCell>
              <TableCell align="left">
                {estado === "CREADA" && (
                  <IconButton
                    onClick={() => {
                      handleEliminarPropuesta(row.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        });
    } else {
      return filteredItems
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, idx) => {
          const {
            id,
            nombreSolicitante,
            correoSolicitante,
            telefonoSolicitante,
            fechaCreacion,
            fechaEnvioCorreo,
            estado,
            nombre,
            apellidos,
          } = row;
          const isItemSelected = selected.indexOf(id) !== -1;

          return (
            <TableRow
              hover
              key={idx}
              tabIndex={-1}
              role="checkbox"
              selected={isItemSelected}
              aria-checked={isItemSelected}
            >
              {(rolObtenido === "SUPER_ADMIN" ||
                rolObtenido === "ADMIN" ||
                rolObtenido === "SUPERVISOR") && (
                <TableCell
                  align="left"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/dashboard/propuestas/${id}`);
                  }}
                >
                  {nombre + " " + apellidos}
                </TableCell>
              )}
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
                align="left"
              >
                {nombreSolicitante}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                align="left"
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
              >
                {correoSolicitante}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
                align="left"
              >
                {telefonoSolicitante}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                align="left"
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
              >
                {fechaCreacion === ""
                  ? fechaCreacion
                  : moment(fechaCreacion, "YYYY/MM/DD").format("DD/MM/YYYY") !==
                    "Invalid date"
                  ? moment(fechaCreacion).format("DD/MM/YYYY")
                  : fechaCreacion}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                align="left"
                onClick={() => {
                  navigate(`/dashboard/propuestas/${id}`);
                }}
              >
                {fechaEnvioCorreo === ""
                  ? fechaEnvioCorreo
                  : moment(fechaEnvioCorreo, "YYYY/MM/DD").format(
                      "DD/MM/YYYY"
                    ) !== "Invalid date"
                  ? moment(fechaEnvioCorreo).format("DD/MM/YYYY")
                  : fechaEnvioCorreo}
              </TableCell>
              <TableCell align="left">
                {estado !== "ACEPTADA" && estado !== "RECHAZADA" ? (
                  <div>
                    <Label
                      onClick={() => handleChangeTaskState(id)}
                      style={{ cursor: "pointer" }}
                      variant="ghost"
                      color={(estado === "CREADA" && "warning") || "info"}
                    >
                      {sentenceCase(estado)}
                    </Label>
                    {estado !== "ENVIADA" && (
                      <Tooltip title="Adjuntar">
                        <IconButton
                          onClick={() => {
                            setSelectedProposal(row);
                            setOpenModalArchivo(true);
                          }}
                        >
                          <AttachmentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <Label
                    variant="ghost"
                    color={
                      (estado === "RECHAZADA" && "error") ||
                      (estado === "CREADA" && "warning") ||
                      (estado === "ACEPTADA" && "success") ||
                      "info"
                    }
                  >
                    {sentenceCase(estado)}
                  </Label>
                )}
              </TableCell>
              <TableCell align="left">
                {estado === "CREADA" && (
                  <IconButton
                    onClick={() => {
                      handleEliminarPropuesta(row.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        });
    }
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Propuestas
          </Typography>
        </Titulo>

        <Card>
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} md={3}>
              <TareasListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginLeft: "1.5rem" }}>
              <Button
                variant="contained"
                style={{ height: "3.4rem" }}
                onClick={handleOpenModalPropuesta}
              >
                <FontAwesomeIcon
                  icon={faCopy}
                  width={25}
                  height={25}
                  style={{ marginRight: "0.5rem" }}
                />{" "}
                Crear Propuesta
              </Button>
            </Grid>
            <Grid item xs md style={{ marginTop: "1rem" }}>
              <Grid container direction="row-reverse">
                <Grid item xs={12} md={6}>
                  <Container style={{ marginLeft: ".5rem" }}>
                    {!loadingTable && propuestas && (
                      <ExportToExcel
                        data={propuestas.map((propuesta) => {
                          return {
                            ...propuesta,
                            nombreEmpleado: null,
                          };
                        })}
                        filename="propuestas"
                        sheetName="propuestas"
                        setShowAlert={setShowAlert}
                        setColor={setColor}
                        setMessage={setError}
                        type="objects"
                      />
                    )}
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {!loadingTable ? (
                <Table>
                  <TareasListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={propuestas.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {renderTableBody()}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isElementNotFound && propuestas.length > 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {propuestas.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          No hay propuestas que mostrar.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>
          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>

        {selectedProposal && (
          <ModifyTaskStateModal
            task={selectedProposal}
            onSubmit={doChangeTaskState}
            onClose={() => setShowModifyTaskState(false)}
            states={
              selectedProposal.estado === "CREADA"
                ? ["CREADA", "ENVIADA"]
                : selectedProposal.estado === "ENVIADA"
                ? ["ACEPTADA", "RECHAZADA"]
                : ["CREADA", "ENVIADA", "ACEPTADA", "RECHAZADA"]
            }
            open={showModifyTaskState}
            tipoEstado="PROPUESTA"
            cargandoDatos={cargandoDatos}
          />
        )}

        {openModalArchivo && (
          <ArchivoAdjuntoDialogPropuesta
            setShowError={setShowError}
            setError={setError}
            propuesta={selectedProposal}
            openModalArchivo={openModalArchivo}
            setOpenModalArchivo={setOpenModalArchivo}
          />
        )}

        {showError && (
          <Alerta
            showAlert={showError}
            setShowAlert={setShowError}
            message={error}
            color="error"
          />
        )}

        {showAlert && (
          <Alerta
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            message={error}
            color={color}
          />
        )}
        {modalPropuesta && (
          <NuevaPropuesta
            open={modalPropuesta}
            setOpen={setOpenModalPropuesta}
            getPropuestasEmpleado={getPropuestasEmpleado}
            getPropuestasSucursal={getPropuestasSucursal}
          />
        )}
      </Container>
    </Page>
  );
}
