import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Button
} from "@material-ui/core";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { subtareasApi } from "src/services/subtareasApi";


export default function AgregarSubTareaDialog(props) {
  const { open, setOpen, tarea } = props;
  const [agregarSubtarea] = subtareasApi.useAddSubtareaMutation();
  const [nombreSubTarea, setNombreSubTarea] = useState("");


  const handleClose = () => {
    setOpen(false);
  };

  const agregar = () => {
    AlertaModal({
      title: 'crear la subtarea',
      confirmButtonText: 'Sí, crear',
      target: document.getElementById('dialogAgregarSubtarea'),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          agregarSubtarea({
            nombre: String(nombreSubTarea).toUpperCase(),
            refTarea: tarea.id
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: 'Subtarea',
                action: 'creada',
              }).fire()
              setOpen(false);

            })
            .catch(() => {
              AlertaToast({
                action: 'Error en el servidor',
                fullText: true,
                target: document.getElementById('dialogAgregarSubtarea'),
              }).fire()
            })

        }
      })


  };

  const handleChangeNombreSubTarea = (event) => {

    setNombreSubTarea(event.target.value);
  };

  return (
    <Dialog
      open={open}
      id="dialogAgregarSubtarea"

    >
      <DialogTitle>
        Nueva Subtarea
      </DialogTitle>
      <DialogContent>

        <Stack style={{ marginTop: "10px" }}>
          <TextField
            value={nombreSubTarea}
            onChange={handleChangeNombreSubTarea}
            label="Nombre Subtarea"
            variant="outlined"
          />

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={agregar}
          autoFocus
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );

}
