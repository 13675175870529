import React, { useState } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
  Typography,
} from "@material-ui/core";
import { filter } from "lodash";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../Scrollbar";
import { TablaHead } from "../_dashboard/tablas";

const TABLE_HEAD = [
  { id: "vendedor", label: "Vendedor", alignRight: false },
  { id: "nombreCliente", label: "Cliente", alignRight: false },
  { id: "tarea", label: "Tarea", alignRight: false },
  { id: "descuento", label: "Descuento", alignRight: false },
  { id: "valorTarea", label: "Valor Tarea", alignRight: false },
  { id: "total", label: "Total", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Tabla(props) {
  const { empleados, tarea, setTarea, datosTarea, setDatosTarea } = props;

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const filterName = "";
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = empleados?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tarea?.length) : 0;

  const filteredUsers = applySortFilter(
    tarea,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const eliminar = (row) => {
    const tareas = [...tarea];
    const datosTareas = [...datosTarea];
    const index = tareas.indexOf(row);
    tareas.splice(index, 1);
    datosTareas.splice(index, 1);
    setTarea(tareas);
    setDatosTarea(datosTareas);
  };
  return (
    <div>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TablaHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tarea?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      vendedor,
                      nombreCliente,
                      nombre,
                      descuento,
                      valorTarea,
                      tipoMoneda,
                      total,
                    } = row;
                    const isItemSelected = selected.indexOf(vendedor) !== -1;

                    return (
                      <TableRow
                        hover
                        key={vendedor + nombreCliente + nombre + descuento}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography variant="subtitle2" noWrap>
                            {vendedor}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{nombreCliente}</TableCell>
                        <TableCell align="left">{nombre}</TableCell>
                        <TableCell align="left">
                          {descuento} {" %"}
                        </TableCell>
                        <TableCell align="left">
                          {valorTarea} {tipoMoneda}
                        </TableCell>
                        <TableCell align="left">
                          {total} {tipoMoneda}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="error"
                            onClick={() => {
                              eliminar(row);
                            }}
                          >
                            Eliminar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No se han agregado elementos
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={tarea?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
}
