import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const ajustesApi = createApi({
  reducerPath: "ajustesApi",
  baseQuery: baseQuery(`${API_SERVER}/ajustes`),
  tagTypes: [
    "ComisionPredefinida",
    "RelacionSelectorElemento",
    "RelacionColumnaCliente",
    "Etiquetas",
  ],
  endpoints: (builder) => ({
    getComplejidadesCliente: builder.query({
      query: () => `/complejidadesClientes`,
    }),
    getComisionesPredefinidas: builder.query({
      query: () => `/comisionesPredefinida`,
      providesTags: ["ComisionPredefinida"],
    }),
    getComisionesPredefeinidaTarea: builder.query({
      query: (tipoTarea) => `/comisionesPredefinida/tipoTarea/${tipoTarea}`,
      providesTags: ["ComisionPredefinida"],
    }),
    getComunas: builder.query({
      query: () => `/comunas`,
    }),
    getEtiqueta: builder.query({
      query: () => `/etiquetas`,
      providesTags: ["Etiquetas"],
    }),
    getRoles: builder.query({
      query: () => `/roles`,
    }),
    getRelacionColumnaCliente: builder.query({
      query: (nombreColumna) => `/relacionColumnaCliente/${nombreColumna}`,
      providesTags: ["RelacionColumnaCliente"],
    }),
    getSelectorElemento: builder.query({
      query: (refComponente) => `/relacionSelectorElemento/${refComponente}`,
      providesTags: ["RelacionSelectorElemento"],
    }),
    deleteEtiqueta: builder.mutation({
      query: (nombreEtiqueta) => ({
        url: `/etiquetas/${nombreEtiqueta}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Etiquetas"],
    }),
    deleteRelacionSelectorElemento: builder.mutation({
      query: (refComponente) => ({
        url: `/relacionSelectorElemento/${refComponente}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RelacionSelectorElemento"],
    }),
    deleteRelacionColumnaCliente: builder.mutation({
      query: (nombreColumna) => ({
        url: `/relacionColumnaCliente/${nombreColumna}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RelacionColumnaCliente"],
    }),
    addEtiqueta: builder.mutation({
      query: (body) => ({
        url: `/etiquetas`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Etiquetas"],
    }),
    addRelacionSelectorElemento: builder.mutation({
      query: (body) => ({
        url: `/relacionSelectorElemento`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RelacionSelectorElemento"],
    }),
    addRelacionColumnaCliente: builder.mutation({
      query: (body) => ({
        url: `/relacionColumnaCliente`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RelacionColumnaCliente"],
    }),
    updateComisionesPredefinidas: builder.mutation({
      query: ({ id, body }) => ({
        url: `/comisionesPredefinida/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ComisionPredefinida"],
    }),
  }),
});

export const {
  useGetComisionesPredefeinidaTareaQuery,
  useGetComisionesPredefinidasQuery,
  useGetComunasQuery,
  useGetEtiquetaQuery,
  useGetComplejidadesClienteQuery,
  useLazyGetComplejidadesClienteQuery,
  useGetRelacionColumnaClienteQuery,
  useGetRolesQuery,
  useGetSelectorElementoQuery,
  useLazyGetComisionesPredefeinidaTareaQuery,
  useLazyGetComisionesPredefinidasQuery,
  useLazyGetComunasQuery,
  useLazyGetEtiquetaQuery,
  useLazyGetRelacionColumnaClienteQuery,
  useLazyGetRolesQuery,
  useLazyGetSelectorElementoQuery,
  useAddRelacionSelectorElementoMutation,
} = ajustesApi;
