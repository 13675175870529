import React, { useState, useEffect } from "react";

import TablaContrato from "./TablaContrato";
import { API_SERVER, dbx } from "../../../utils/urlBack_End";
import axios from "axios";

export default function Contratos(props) {
  const {
    valueLaboral,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage,
    vista,
  } = props;

  const [contratos, setContratos] = useState([]);
  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/contrato/${empleado.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setContratos(response.data);
      });
  }, [empleado.rut]);

  useEffect(() => {
    if (empleado.rut !== undefined) {
      dbx
        .filesListFolder({ path: `/Empleados/${empleado.rut}/Contratos` })
        .then(() => { })
        .catch(() => {
          dbx
            .filesCreateFolderV2({
              path: `/Empleados/${empleado.rut}/Contratos`,
            })
            .then((response) => { })
            .catch((error) => { });
        })
        .finally(() => { });
    }
  }, [empleado.rut]);
  return (
    <>
      {valueLaboral === 1 && (
        <>
          <TablaContrato
            contratos={contratos}
            setContratos={setContratos}
            empleado={empleado}
            setShowMessagePermiso={setShowMessagePermiso}
            setColor={setColor}
            setMessage={setMessage}
            vista={vista}
          />
        </>
      )}
    </>
  );
}
