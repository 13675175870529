const capitalizarPrimeraLetra = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const obtenerValorMoneda = (monedas, tipoMoneda) => {
  return monedas.find(({ moneda }) => moneda === tipoMoneda).valor || null;
};

const decodeHtmlEntities = (str, document) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

const limitarDecimalesSinRelleno = (precio, decimales = 6) => {
  // Redondear el precio a los decimales especificados
  const factor = Math.pow(10, decimales);
  const precioRedondeado = Math.round(precio * factor) / factor;

  // Convertir a string para eliminar ceros innecesarios
  const strPrecio = precioRedondeado.toString();

  // Verificar si hay decimales
  if (strPrecio.indexOf(".") !== -1) {
    // Eliminar ceros innecesarios al final de los decimales
    return parseFloat(strPrecio);
  } else {
    // Retornar el número sin cambios si no hay decimales
    return precioRedondeado;
  }
};

const capitalizarPalabras = (texto) => {
  return texto
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, function (letra) {
      return letra.toUpperCase();
    })
    .replace(/\b([EIRLSA]\.)\w*\b/g, function (abreviatura) {
      return abreviatura.toUpperCase();
    });
};

const limpiarString = (texto) => {
  return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const isEmptyString = (str) => {
  return str.trim().length === 0;
};

export {
  capitalizarPrimeraLetra,
  capitalizarPalabras,
  limpiarString,
  isEmptyString,
  obtenerValorMoneda,
  limitarDecimalesSinRelleno,
  decodeHtmlEntities,
};
