import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const propuestasApi = createApi({
  reducerPath: "propuestasApi",
  baseQuery: baseQuery(`${API_SERVER}/propuestas`),
  tagTypes: ["Propuestas"],
  endpoints: (builder) => ({
    getPropuestasSucursales: builder.query({
      query: (id) => `/sucursales/${id}`,
      providesTags: ["Propuestas"],
    }),
    getResumenPorEstadoYSucursal: builder.query({
      query: ({ rut, fecha }) => {
        return {
          url: `/resumenPorEstadoYSucursal/${rut}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    getPropuestasPorSucursalYEstado: builder.query({
      query: ({ sucursal, estado, fecha }) => {
        return {
          url: `/propuestasPorSucursalYEstado/${sucursal}/${estado}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    getPropuestasEmpleado: builder.query({
      query: ({ usuario, id }) => `/empleado/${usuario}/${id}`,
      providesTags: ["Propuestas"],
    }),
    addPropuesta: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
    deletePropuesta: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Propuestas"],
    }),
    updateEstadoPropuesta: builder.mutation({
      query: ({ id, body }) => ({
        url: `/estado/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),

    updatePropuesta: builder.mutation({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Propuestas"],
    }),
  }),
});

export const {
  useGetPropuestasEmpleadoQuery,
  useGetPropuestasSucursalesQuery,
  useGetResumenPorEstadoYSucursalQuery,
  useGetPropuestasPorSucursalYEstadoQuery,
  useLazyGetPropuestasPorSucursalYEstadoQuery,
  useLazyGetPropuestasEmpleadoQuery,
  useLazyGetPropuestasSucursalesQuery,
  useLazyGetResumenPorEstadoYSucursalQuery,
  useDeletePropuestaMutation,
  useAddPropuestaMutation,
  useUpdateEstadoPropuestaMutation,
  useUpdatePropuestaMutation,
} = propuestasApi;
