import { filter } from "lodash";
import { sentenceCase } from "change-case";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Alerta } from "../components/Alerta";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { TablaHead, TablaToolbar } from "../components/_dashboard/tablas";
import * as locales from "@material-ui/core/locale";
import Page from "../components/Page";
import Label from "../components/Label";
import AgregarCliente from "src/components/gestionCliente/AgregarCliente";
import ExportToExcelPass from "../components/ExportToExcelPass";
import { Titulo } from "../components/Titulo";
import { removeAccents } from "../utils/converter";
import AsociarEmpresas from "src/components/AsociarEmpresas";
import { useSelector } from "react-redux";
import { clientesApi } from "src/services/clientesApi";
import { empleadosApi } from "src/services/empleadosApi";
import ExportToExcelPromedioPagosClientes from "src/components/ExportToExcelPromedioPagosClientes";
import { facturasApi } from "src/services/facturasApi";
import handleError from "src/utils/manejo-errores";
import {
  activarCalculoAutomatico,
  aplicarBordeUltimaFila,
  guardarArchivo,
} from "src/utils/excel-plantillas";
import ExcelJS from "exceljs";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";

const TABLE_HEAD = [
  { id: "rut", label: "Rut Cliente", alignRight: false },
  { id: "grupo", label: "Grupo", alignRight: false },
  { id: "nombre", label: "Nombre Cliente", alignRight: false },
  { id: "comuna", label: "Comuna", alignRight: false },
  { id: "telefono", label: "Teléfono", alignRight: false },
  { id: "correo", label: "Correo", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.rut.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (_user.grupo &&
          _user.grupo !== "" &&
          removeAccents(_user.grupo.toLowerCase()).indexOf(
            removeAccents(query.toLowerCase())
          ) !== -1) ||
        removeAccents(_user.nombre.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_user.comuna.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _user.telefono.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.correo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function isRoleVisible(role) {
  const visibleRoles = ["ADMIN", "SUPER_ADMIN", "SUPERVISOR", "EMPLEADO"];
  return visibleRoles.includes(role);
}

function getFilename(role, user) {
  return role === "EMPLEADO"
    ? `Clientes ${user.nombre} ${user.apellidos}`
    : "Clientes";
}

export default function Clientes() {
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const [open, setOpen] = useState(false);
  const [openAsociarEmpresa, setOpenAsociarEmpresa] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState(
    localStorage.getItem("buscador") ? localStorage.getItem("buscador") : ""
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clientes, setClientes] = useState([]);
  const [locale] = useState("esES");
  const [loadingTable, setLoadingTable] = useState(true);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");
  const [cargandoExportacionPromedioPago, setCargandoExportacionPromedioPago] =
    useState(false);

  const [
    getClientes,
    {
      data: clientesData,
      isSuccess: isSuccessClientesData,
      isFetching: isFetchingClientesData,
    },
  ] = clientesApi.useLazyGetClientesQuery();

  const [
    getClientesEmpleado,
    {
      data: clientesEmpleadosData,
      isSuccess: isSuccessClientesEmpleadosData,
      isFetching: isFetchingClientesEmpleadosData,
    },
  ] = clientesApi.useLazyGetClientesEmpleadoQuery();

  const [obtenerPromedioPagoClientes] =
    facturasApi.useLazyGetFacturasClientesQuery();

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  useEffect(() => {
    if (rolObtenido === "EMPLEADO") {
      getClientesEmpleado({
        rut: user.rut,
        sucursal: sucursalObtenida.refSucursal,
      });
    } else {
      getClientes({ estado: "todos" });
    }
  }, [getClientes, getClientesEmpleado, rolObtenido, sucursalObtenida, user]);

  useEffect(() => {
    if (rolObtenido === "EMPLEADO") {
      setLoadingTable(true);
      if (isSuccessClientesEmpleadosData) {
        setClientes(clientesEmpleadosData);
        setLoadingTable(false);
      }
    }
  }, [
    clientesEmpleadosData,
    isFetchingClientesEmpleadosData,
    isSuccessClientesEmpleadosData,
    rolObtenido,
  ]);

  useEffect(() => {
    if (rolObtenido !== "EMPLEADO") {
      setLoadingTable(true);
      if (isSuccessClientesData) {
        setClientes(clientesData);
        setLoadingTable(false);
      }
    }
  }, [
    clientesData,
    isFetchingClientesData,
    isSuccessClientesData,
    rolObtenido,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clientes.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    localStorage.setItem("buscador", event.target.value);

    setFilterName(localStorage.getItem("buscador"));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientes.length) : 0;

  const filteredUsers = applySortFilter(
    clientes,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  /**
   *
   * @param {*} rut rut obtenido desde la base de datos
   * @returns retorna el rut en el formato xxxxxxxx-y, ya que
   * el rut es guardado sin guión en la base de datos
   */
  const formatearRut = (rut) => {
    let verificador = rut.charAt(rut.length - 1);
    let digitos = rut.substr(0, rut.length - 1);
    return digitos + "-" + verificador;
  };

  const manejarExportacionExcel = async () => {
    setCargandoExportacionPromedioPago(true);

    try {
      const { data: datosClientes } = await obtenerPromedioPagoClientes();

      if (datosClientes.length > 0) {
        const templatePath =
          "/static/plantillas/PLANTILLA_PROMEDIO_PAGOS_CLIENTES.xlsx";

        const workbook = await rellenarPlantilla(templatePath, datosClientes);

        await guardarArchivo(workbook, `Promedio de pago clientes.xlsx`);
        setCargandoExportacionPromedioPago(false);
      } else {
        lanzarError("No existen datos para exportar", "warning");
      }
    } catch (error) {
      lanzarError("Ocurrió un error desconocido al exportar Excel", "error");
      handleError("manejarExportacionExcel", error);
    }
  };

  const lanzarError = (mensaje, color) => {
    setColor(color);
    setMessage(mensaje);
    setShowAlert(true);
    setCargandoExportacionPromedioPago(false);
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Grid container>
            <Grid item xs={12} md></Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Clientes
              </Typography>
            </Grid>
            <Grid item xs={12} md>
              <Grid container direction="column">
                <Grid item>
                  {(rolObtenido === "ADMIN" ||
                    rolObtenido === "SUPER_ADMIN") && (
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      variant="contained"
                      startIcon={<Icon icon={plusFill} />}
                      style={{ marginBottom: "1rem" }}
                    >
                      Agregar Cliente
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Titulo>
        <Card>
          <Stack>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TablaToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
              {isRoleVisible(rolObtenido) && (
                <>
                  <ExportToExcelPass
                    data={clientes}
                    filename={getFilename(rolObtenido, user)}
                    sheetName="Clientes"
                    setShowAlert={setShowAlert}
                    setColor={setColor}
                    setMessage={setMessage}
                    type="objects"
                    sx={{ marginRight: 2, marginLeft: { xs: 2 } }}
                  />
                  {rolObtenido !== "EMPLEADO" && (
                    <ExportToExcelPromedioPagosClientes
                      obtenerDatos={obtenerPromedioPagoClientes}
                      setShowAlert={setShowAlert}
                      setColor={setColor}
                      setMessage={setMessage}
                      exportarDatos={manejarExportacionExcel}
                      isLoading={cargandoExportacionPromedioPago}
                      filename="Promedio pagos"
                      type="objects"
                      title="Exportar promedio de pago clientes"
                      sx={{
                        marginRight: 2,
                        marginTop: { xs: 2, md: 0, lg: 0, xl: 0 },
                        marginLeft: { xs: 2 },
                      }}
                    />
                  )}
                </>
              )}
            </Grid>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {!loadingTable ? (
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={clientes.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          rut,
                          grupo,
                          nombre,
                          comuna,
                          telefono,
                          correo,
                          estado,
                        } = row;
                        const isItemSelected = selected.indexOf(nombre) !== -1;

                        return (
                          <TableRow
                            hover
                            key={rut}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + row.rut);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {formatearRut(rut)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + row.rut);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {grupo ? grupo : ""}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + row.rut);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {nombre}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + row.rut);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {comuna}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + row.rut);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {telefono}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => {
                                navigate("/dashboard/clientes/" + row.rut);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {correo}
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (estado === "INACTIVO" && "error") ||
                                  "success"
                                }
                              >
                                {sentenceCase(estado)}
                              </Label>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={clientes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      </Container>

      {open && (
        <AgregarCliente
          open={open}
          setOpen={setOpen}
          setShowAlert={setShowAlert}
          setColor={setColor}
          setMessage={setMessage}
        />
      )}

      {openAsociarEmpresa && (
        <AsociarEmpresas
          open={openAsociarEmpresa}
          setOpen={setOpenAsociarEmpresa}
        />
      )}

      {showAlert && (
        <Alerta
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          color={color}
          message={message}
        />
      )}
    </Page>
  );
}

const rellenar = (
  worksheet,
  datosTabla,
  tituloTabla,
  filaInicial,
  columnas
) => {
  try {
    const {
      HEADER_TITLE,
      TITLE_FONT_STYLE,
      HEADER_FILL,
      HEADER_FONT_STYLE,
      DATA_FONT_STYLE,
    } = CONSTANTES_EXCEL;

    const primeraColumna = columnas[0];
    const ultimaColumna = columnas[columnas.length - 1];

    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).value =
      tituloTabla;
    worksheet.mergeCells(
      `${primeraColumna.id}${filaInicial - 1}`,
      `${ultimaColumna.id}${filaInicial - 1}`
    );
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).fill =
      HEADER_TITLE;
    worksheet.getCell(`${primeraColumna.id}${filaInicial - 1}`).font =
      TITLE_FONT_STYLE;

    columnas.forEach((columna) => {
      worksheet.getCell(`${columna.id}${filaInicial}`).value = columna.titulo;
      worksheet.getCell(`${columna.id}${filaInicial}`).fill = HEADER_FILL;
      worksheet.getCell(`${columna.id}${filaInicial}`).font = HEADER_FONT_STYLE;
      worksheet.getCell(`${columna.id}${filaInicial}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    datosTabla.forEach((dato, index) => {
      const rowIndex = filaInicial + 1 + index;
      columnas.forEach((columna) => {
        const valor = dato[columna.propiedad];
        const cell = worksheet.getCell(`${columna.id}${rowIndex}`);
        cell.font = DATA_FONT_STYLE;
        cell.value = valor;

        // Aplicar formato numérico si el valor es un número
        if (!isNaN(valor)) {
          cell.value = valor;
          cell.numFmt = "0";
        }
        if (columna.propiedad === "refCliente") {
          const rut = formatRut(valor, RutFormat.DOTS_DASH);
          cell.value = rut;
          cell.numFmt = "@"; // Se mantiene formato string en Excel
        }
        if (columna.propiedad === "nombreCliente") {
          cell.alignment = {
            vertical: "middle",
            horizontal: "left",
          };
        } else {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
        }
      });
    });

    worksheet.columns.forEach((column, columnIndex) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const text = cell.text || "";
        maxLength = Math.max(maxLength, text.length);
      });
      column.width = maxLength + 7;
    });
  } catch (error) {
    handleError("rellenar", error);
  }
};

const rellenarTabla = (worksheet, datos, tituloTabla) => {
  try {
    let columnas = [
      { id: "E", titulo: "RUT cliente", propiedad: "refCliente" },
      { id: "F", titulo: "Cliente", propiedad: "nombreCliente" },
      { id: "G", titulo: "Mediana días de pago", propiedad: "medianaDiasPago" },
    ];

    const filaInicialTabla = 8;

    rellenar(worksheet, datos, tituloTabla, filaInicialTabla, columnas);
  } catch (error) {
    handleError("rellenarTabla", error);
  }
};

const rellenarPlantilla = async (templatePath, datos) => {
  try {
    // Crear un nuevo objeto Workbook de ExcelJS
    const workbook = new ExcelJS.Workbook();

    // Cargar la plantilla Excel desde el archivo en la ruta especificada
    const templateBuffer = await fetch(templatePath).then((response) =>
      response.arrayBuffer()
    );
    await workbook.xlsx.load(templateBuffer);

    // Habilitar el cálculo automático en la hoja
    activarCalculoAutomatico(workbook);

    const worksheetFacturaPlanes = workbook.worksheets[0]; // Hoja de Factura Planes

    const nombre = "Promedio de pagos";
    worksheetFacturaPlanes.name = nombre;
    rellenarTabla(worksheetFacturaPlanes, datos, nombre, "PLAN");
    aplicarBordeUltimaFila(worksheetFacturaPlanes, 0, 11);

    workbook.views = {
      activeTab: 0,
    };
    return workbook;
  } catch (error) {
    handleError("rellenarPlantilla", error);
  }
};

const CONSTANTES_EXCEL = {
  HEADER_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
    bold: true,
    color: { argb: "44546A" },
  },
  TITLE_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
    bold: true,
    color: { argb: "FFFFFF" },
  },
  DATA_FONT_STYLE: {
    name: "Arial Nova Cond",
    size: 12,
  },
  HEADER_FILL: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ED7D31" },
  },
  HEADER_TITLE: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  },
};
