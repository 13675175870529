import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Label from "../Label";
import * as locales from "@material-ui/core/locale";
// material
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";

const TABLE_HEAD = [
  { id: "empleadoRecibePago", label: "Pago Recibido Por", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "fecha", label: "Fecha", alignRight: false },
  { id: "fechaCreacion", label: "Fecha Creacion", alignRight: false },
  { id: "fechaPago", label: "Fecha Pago", alignRight: false },
  { id: "metodoPago", label: "Método de Pago", alignRight: false },
  { id: "valorPesos", label: "Valor En Pesos", alignRight: false },
  { id: "deudaTotal", label: "Deuda Total", alignRight: false },

  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.empleadoRecibePago.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaCreacion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaPago.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.metodoPago.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        String(_user.valorPesos).toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.deudaTotal.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function formatNumber(n) {
  n = n > 0 ? String(n).replace(/\D/g, "") : n;
  return n === "" ? n : Number(n).toLocaleString("es-CL");
}

const styles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
}));

export default function TablaPagosFacturas(props) {
  const { pagos } = props;
  const [locale] = useState("esES");
  const classes = styles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombreTarea");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingTable, setLoadingTable] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = pagos?.map((n) => n.nombreTarea);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagos?.length) : 0;

  const filteredUsers = applySortFilter(
    pagos,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  useEffect(() => {
    if (pagos !== undefined) {
      setLoadingTable(false);
    }
  }, [pagos]);
  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Pagos Factura
      </Typography>

      <Grid
        container
        style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}>
        <Grid item xs={12} md={3}>
          <TablaToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        </Grid>
      </Grid>
      <Scrollbar>
        <TableContainer>
          {!loadingTable ? (
            <Table>
              <TablaHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={pagos?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      empleadoRecibePago,
                      estado,
                      fecha,
                      fechaCreacion,
                      fechaPago,
                      metodoPago,
                      valorPesos,
                      deudaTotal,
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">
                          <Typography variant="subtitle2">
                            {empleadoRecibePago}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={"info"}>
                            {sentenceCase(estado)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{fecha}</TableCell>
                        <TableCell align="left">{fechaCreacion}</TableCell>
                        <TableCell align="left">{fechaPago}</TableCell>
                        <TableCell align="left">{metodoPago}</TableCell>
                        <TableCell align="left">
                          {"$ " + formatNumber(valorPesos)}
                        </TableCell>

                        <TableCell align="left">{deudaTotal}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <SearchNotFound
                        searchFilter={true}
                        searchQuery={filterName}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </TableContainer>
      </Scrollbar>

      <ThemeProvider
        theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={pagos?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </>
  );
}

/*
         

*/
