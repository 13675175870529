import PropTypes from "prop-types";
import Scrollbar from "../Scrollbar";
import {
  Card,
  CardHeader,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
  createTheme,
} from "@material-ui/core";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import { ThemeProvider } from "@material-ui/styles";
import CustomTablePagination from "../CustomTablePagination";
import { useMemo, useState } from "react";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import SearchNotFound from "../SearchNotFound";
import * as locales from "@material-ui/core/locale";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import { fDateLong } from "src/utils/formatTime";
import { formatAsCLP } from "src/utils/formatNumber";

AppTablaDetalleClientesFacturas.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  rows: PropTypes.array,
  loading: PropTypes.bool,
};

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombre", label: "Nombre", alignRight: false },
  { id: "correo", label: "Correo", alignRight: false },
  { id: "fechaIngreso", label: "Fecha ingreso", alignRight: false },
  { id: "telefono", label: "Celular", alignRight: false },
  { id: "totalFacturas", label: "Facturas totales", alignRight: false },
  { id: "" },
];

export default function AppTablaDetalleClientesFacturas({
  rows,
  title,
  subheader,
  loading = false,
}) {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(rowsFiltradas.length / newRowsPerPage);
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const rowsFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["rut", "nombre"];
    return applySortFilter(
      rows,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [rows, statesTable.filterName, statesTable.order, statesTable.orderBy]);

  const seEncontroElemento = rowsFiltradas.length === 0;

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            rows.length
        )
      : 0;

  return (
    <Card>
      <Grid container>
        <Grid item xs={12} md={8} lg={8}>
          <CardHeader title={title} subheader={subheader} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "grid",
            justifyContent: { xs: "center", md: "right", lg: "right" },
            alignItems: "flex-end",
          }}
        >
          <TablaToolbar
            filterName={statesTable.filterName}
            onFilterName={handleFilterByName}
          />
        </Grid>
      </Grid>
      <Scrollbar>
        <TableContainer component={Paper}>
          {!loading ? (
            <Table>
              <TablaHead
                order={statesTable.order}
                orderBy={statesTable.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rowsFiltradas
                  .slice(
                    statesTable.currentPage * statesTable.rowsPerPageOptions,
                    statesTable.currentPage * statesTable.rowsPerPageOptions +
                      statesTable.rowsPerPageOptions
                  )
                  .map((row, index) => (
                    <TableRow hover key={row.rut}>
                      <TableCell>
                        {statesTable.currentPage *
                          statesTable.rowsPerPageOptions +
                          index +
                          1}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" noWrap>
                          {formatRut(row.rut, RutFormat.DOTS_DASH)}
                        </Typography>
                      </TableCell>
                      <TableCell>{capitalizarPalabras(row.nombre)}</TableCell>
                      <TableCell>{row.correo.toLowerCase()}</TableCell>
                      <TableCell>{fDateLong(row.fechaIngreso)}</TableCell>
                      <TableCell>{row.telefono}</TableCell>
                      <TableCell>{formatAsCLP(row.totalFacturas)}</TableCell>
                    </TableRow>
                  ))}

                {filasVacias > 0 && (
                  <TableRow style={{ height: 53 * filasVacias }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
              {seEncontroElemento && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={statesTable.filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <ThemeProvider
                    theme={(outerTheme) =>
                      createTheme(outerTheme, locales[statesTable.locale])
                    }
                  >
                    <CustomTablePagination
                      count={rowsFiltradas.length}
                      rowsPerPage={statesTable.rowsPerPageOptions}
                      page={statesTable.currentPage}
                      colSpan={8}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </ThemeProvider>
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
