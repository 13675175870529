import React, { useState } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Autocomplete,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../../utils/urlBack_End";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { tareasApi } from "src/services/tareasApi";
import { AlertaToast } from "src/components/AlertaSwall";

export default function ObtenerFacturasPlanes(props) {
  const { open, setOpen } = props;
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);

  const documentos = [
    {
      nombre: "34 Factura no afecta o exenta electrónica",

      id: 34,
    },
    { nombre: "56 Nota de débito electrónica", id: 56 },
    { nombre: "61 Nota de crédito electrónica", id: 61 },
  ];
  const tiposFacturas = ["Facturas Planes", "Facturas Tareas"];

  const [cargandoObtencion, setCargandoObtencion] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState(false);

  const LoginSchema = Yup.object().shape({
    tipoFactura: Yup.string().required("Tipo factura requerida").nullable(),
    folio: Yup.string().required("Folio requerido"),
    tipoDocumento: Yup.object()
      .required("Tipo de documento requerido")
      .nullable(),
    tarea: Yup.object().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      tipoFactura: null,
      folio: "",
      tipoDocumento: null,
      tarea: null,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      obtener();
    },
  });

  const { data: tareas = [], isLoading: loadingTareas } =
    tareasApi.useGetTareasParaFacturarQuery({
      sucursal: sucursalObtenida.refSucursal,
      rolObtenido: rolObtenido,
    });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const handleClose = () => {
    setOpen(false);
  };

  const DEFAULT_RESPONSE = "Factura Obtenida con Éxito";
  const RESPONSES = {
    "Ese folio ya existe": "Ese folio ya existe",
    "Referencia a la factura no encontrada":
      "Referencia a la factura no encontrada",
    "No se ha encontrado el folio de la factura en OpenFactura":
      "No se ha encontrado el folio de la factura en OpenFactura",
  };
  const obtener = () => {
    setMensajeAlerta(false);
    if (values.tipoFactura === null) {
      setMensajeAlerta("Debe ingresar el tipo de factura");
    } else if (!values.folio.trim()) {
      setMensajeAlerta("Ingrese Folio");
    } else if (!Number(values.folio)) {
      setMensajeAlerta("El folio debe ser un número");
    } else if (values.tipoDocumento === null) {
      setMensajeAlerta("Seleccione Tipo Documento");
    } else if (
      values.tipoFactura === "Facturas Tareas" &&
      values.tarea === null
    ) {
      setMensajeAlerta("Seleccione seleccione tarea");
    } else {
      setCargandoObtencion(true);

      axios
        .post(
          `${API_SERVER}/facturas/obtenerFacturas`,
          {
            folio: values.folio,
            tipoDocumento: values.tipoDocumento.id,
            tipoFactura: values.tipoFactura,
            sucursal: sucursalObtenida.refSucursal,
            rutEmpleado: user.rut,
            nombreEmpleado: user.nombre + " " + user.apellidos,
            tarea: values.tarea,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          const respuesta = RESPONSES[response.data] || DEFAULT_RESPONSE;

          if (respuesta === "Factura Obtenida con Éxito") {
            AlertaToast({
              element: "Factura",
              action: "obtenida",
            }).fire();
            setOpen(false);
          } else {
            setMensajeAlerta(respuesta);
          }
        })
        .catch((error) => {
          AlertaToast({
            action: "Error, documento no encontrado",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogObtencionFacturas"),
          }).fire();
          setCargandoObtencion(false);
        })
        .finally(() => {
          setCargandoObtencion(false);
        });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" id="dialogObtencionFacturas">
      <DialogTitle>Obtener Facturas</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Autocomplete
              style={{ marginTop: 10 }}
              {...getFieldProps("tipoFactura")}
              autoHighlight
              autoComplete
              disablePortal
              options={tiposFacturas}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setFieldValue("tipoFactura", newValue);
                }
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  id="tipoFactura"
                  {...params}
                  error={Boolean(touched.tipoFactura && errors.tipoFactura)}
                  fullWidth
                  helperText={touched.tipoFactura && errors.tipoFactura}
                  label="Seleccione tipo factura"
                  name="tipoFactura"
                  variant="outlined"
                />
              )}
            />

            {values.tipoFactura === "Facturas Tareas" && (
              <Autocomplete
                style={{ marginTop: 10 }}
                noOptionsText="Sin opciones"
                disablePortal
                autoHighlight
                autoComplete
                {...getFieldProps("tarea")}
                options={tareas}
                loading={loadingTareas}
                isOptionEqualToValue={(option) => option.id}
                getOptionLabel={(option) =>
                  option.nombreTarea +
                  " Cliente: " +
                  option.nombreCliente +
                  " Empleado: " +
                  option.nombreEmpleado
                }
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setFieldValue("tarea", newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Seleccione Tarea"
                    name="Tarea"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingTareas && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            <TextField
              required
              margin="dense"
              id="folio"
              label="Folio"
              fullWidth
              {...getFieldProps("folio")}
              error={Boolean(touched.folio && errors.folio)}
              helperText={touched.folio && errors.folio}
            />
            <Autocomplete
              style={{ marginTop: 10 }}
              {...getFieldProps("tipoDocumento")}
              autoHighlight
              autoComplete
              disablePortal
              options={documentos}
              isOptionEqualToValue={(option, value) => {
                return option.nombre === value.nombre;
              }}
              getOptionLabel={(option) =>
                option.nombre ? option.nombre : option
              }
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setFieldValue("tipoDocumento", newValue);
                }
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  id="tipoDocumento"
                  {...params}
                  error={Boolean(touched.tipoDocumento && errors.tipoDocumento)}
                  fullWidth
                  helperText={touched.tipoDocumento && errors.tipoDocumento}
                  label="Seleccione tipo documento"
                  name="tipoDocumento"
                  variant="outlined"
                />
              )}
            />
          </DialogContent>
          {mensajeAlerta && <Alert severity="error">{mensajeAlerta}</Alert>}

          <DialogActions>
            <LoadingButton onClick={handleClose} loading={cargandoObtencion}>
              Cancelar
            </LoadingButton>
            <LoadingButton
              onClick={obtener}
              autoFocus
              loading={cargandoObtencion}
              variant="contained">
              Obtener Factura
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
