import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';

const token = localStorage.getItem('token');

const jwtVerify = (tokenString) => jwt.verify(
    tokenString,
    'G4OH9PDGyhgfrnlc58zI',
    (err, decoded) => ({}),
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: jwtVerify(token),
    reducers: {
        login: (state, param) => {
            const { payload } = param;
            state.user = payload;
        },
        logout: (state) => {
            state.user = null;
            localStorage.clear();
        },
    },
});

export const {
    login,
    logout,
} = authSlice.actions;
export default authSlice.reducer;