import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";
import { isArray } from "lodash";

export const facturasApi = createApi({
  reducerPath: "facturasApi",
  baseQuery: baseQuery(`${API_SERVER}/facturas`),
  tagTypes: [
    "FacturaPlanes",
    "FacturaAfecta",
    "FacturaTareas",
    "ProcesoFacturacion",
    "FacturasSinSucursal",
  ],
  endpoints: (builder) => ({
    getDetalleFacturaPlanes: builder.query({
      query: (id) => `/detalleFacturaPlanes/${id}`,
      providesTags: ["FacturaPlanes"],
    }),
    obtenerPromedioFacturasEmitidasPorCliente: builder.query({
      query: () => "/promedioFacturasEmitidasPorCliente",
    }),
    obtenerClientesMayorCantidadFacturasPagadas: builder.query({
      query: (tipo) => `/clientesMayorCantidadFacturasPagadas/${tipo}`,
    }),
    getFacturasDeTareasEmpleadoEmitidas: builder.query({
      query: (rutEmpleado) =>
        `/facturasDeTareasEmpleadoEmitidas/${rutEmpleado}`,
    }),
    getPendientePorFacturarTareasEmpleado: builder.query({
      query: (rutEmpleado) =>
        `/pendientePorFacturarTareasEmpleado/${rutEmpleado}`,
    }),
    getRecaudadoTareasEspecialesEmpleado: builder.query({
      query: (rutEmpleado) =>
        `/recaudadoTareasEspecialesEmpleado/${rutEmpleado}`,
    }),
    obtenerFacturasEmitidas: builder.query({
      query: ({ rut, estadoFactura, fecha }) => {
        return {
          url: `/facturasEmitidas/${rut}`,
          method: "GET",
          params: {
            estadoFactura,
            fecha,
          },
        };
      },
    }),
    getFacturasPlanes: builder.query({
      query: ({
        id,
        page = 0,
        perPage = 10,
        mesInicio = new Date().getMonth() + 1 <= 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
        anioInicio = new Date().getFullYear(),
        mesFin = new Date().getMonth() + 1 <= 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
        anioFin = new Date().getFullYear(),
        search = null,
      }) => {
        return {
          url: `/planes/sucursal/${id}`,
          method: "GET",
          params: {
            page: page,
            perPage: perPage,
            mesInicio: mesInicio,
            anioInicio: anioInicio,
            mesFin: mesFin,
            anioFin: anioFin,
            search: search,
          },
        };
      },
      providesTags: ["FacturaPlanes"],
    }),
    getFacturasPorSucursal: builder.query({
      query: ({
        sucursales,
        page = 0,
        perPage = 10,
        mesInicio = new Date().getMonth() + 1 <= 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
        anioInicio = new Date().getFullYear(),
        mesFin = new Date().getMonth() + 1 <= 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
        anioFin = new Date().getFullYear(),
        search = null,
      }) => {
        return {
          url: `/facturasPorSucursal`,
          method: "GET",
          params: {
            page: page,
            perPage: perPage,
            mesInicio: mesInicio,
            anioInicio: anioInicio,
            mesFin: mesFin,
            anioFin: anioFin,
            search: search,
            sucursales: isArray(sucursales)
              ? sucursales.map((sucursal) => sucursal.refSucursal)
              : sucursales,
          },
        };
      },
      providesTags: ["FacturaPlanes"],
    }),

    getFacturasPlanesExportar: builder.query({
      query: (id) => {
        return {
          url: `/planes/sucursal/exportar/${id}`,
          method: "GET",
        };
      },
      providesTags: ["FacturaPlanes"],
    }),
    getFacturasTotalExportar: builder.query({
      query: ({ sucursales, mesInicio, anioInicio, mesFin, anioFin }) => {
        return {
          url: `facturasTotal/sucursal/exportar`,
          method: "GET",
          params: {
            sucursales: isArray(sucursales)
              ? sucursales.map((sucursal) => sucursal.refSucursal)
              : sucursales,
            mesInicio,
            anioInicio,
            mesFin,
            anioFin,
          },
        };
      },
      providesTags: ["FacturaPlanes"],
    }),

    getFacturasAfecta: builder.query({
      query: (id) => `/afecta/sucursal/${id}`,
      providesTags: ["FacturaAfecta"],
    }),
    getFacturasPlanesCliente: builder.query({
      query: (refCliente) => `/planes/cliente/${refCliente}`,
      providesTags: ["FacturaPlanes"],
    }),
    obtenerFacturasSinSucursal: builder.query({
      query: () => "/facturasSinSucursal",
      providesTags: ["FacturasSinSucursal"],
    }),
    getFacturasPlanesEmpleado: builder.query({
      query: (refEmpleado) => `/planes/empleado/${refEmpleado}`,
      providesTags: ["FacturaPlanes"],
    }),
    getFacturasTareasCliente: builder.query({
      query: (refCliente) => `/tareas/cliente/${refCliente}`,
      providesTags: ["FacturaTareas"],
    }),
    getFacturasTareasEmpleado: builder.query({
      query: (refEmpleado) => `/tareas/empleado/${refEmpleado}`,
      providesTags: ["FacturaTareas"],
    }),
    getFacturaTareasID: builder.query({
      query: ({ sucursal, refTarea }) => `/tareas/${sucursal}/${refTarea}`,
      providesTags: ["FacturaTareas"],
    }),
    getFacturasTareas: builder.query({
      query: ({
        id,
        page = 0,
        perPage = 10,
        mesInicio = new Date().getMonth() + 1 <= 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
        anioInicio = new Date().getFullYear(),
        mesFin = new Date().getMonth() + 1 <= 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
        anioFin = new Date().getFullYear(),
        search = null,
      }) => {
        return {
          url: `/tareas/sucursal/${id}`,
          method: "GET",
          params: {
            page: page,
            perPage: perPage,
            mesInicio: mesInicio,
            anioInicio: anioInicio,
            mesFin: mesFin,
            anioFin: anioFin,
            search: search,
          },
        };
      },
      providesTags: ["FacturaTareas"],
    }),
    getVencimientoFacturas: builder.query({
      query: (refCliente) => `/vencimientoFactura/${refCliente}`,
      providesTags: ["FacturaPlanes"],
    }),
    getProcesoFacturaPlanes: builder.query({
      query: (sucursal) => `/procesoFacturaPlanes/${sucursal}`,
      providesTags: ["ProcesoFacturacion"],
    }),
    getPeriodoFacturacion: builder.query({
      query: (sucursal) => `/periodoFacturacion/${sucursal}`,
      providesTags: ["ProcesoFacturacion"],
    }),
    getTokenNotaCredito: builder.query({
      query: (folioFactura) => `/tokenNotaCredito/${folioFactura}`,
    }),
    getTokenFolioRef: builder.query({
      query: (params) =>
        `/facturasPlanesTareas/${params.folio}/${params.tipoFactura}`,
      providesTags: ["ProcesoFacturacion"],
    }),
    getFacturaOpenFactura: builder.query({
      query: ({ fechaEmision, folio, codigoFactura, sucursal }) =>
        `/facturaOpenFactura/${fechaEmision}/${folio}/${codigoFactura}/${sucursal}`,
    }),
    getFacturasCliente: builder.query({
      query: (refCliente) => `/cliente/${refCliente}`,
    }),
    getFacturasClientes: builder.query({
      query: () => `/clientes`,
    }),
    addFacturarPorPartes: builder.mutation({
      query: ({ sucursal, body }) => ({
        url: `/procesoFacturaPorPartes/${sucursal}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["FacturaPlanes"],
    }),
    facturarManual: builder.mutation({
      query: (body) => ({
        url: `/facturarManual`,
        method: "POST",
        body,
      }),
    }),
    addCrearFacturaPlan: builder.mutation({
      query: (body) => ({
        url: `/planes`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["FacturaPlanes"],
    }),
    addCrearFacturaTarea: builder.mutation({
      query: (body) => ({
        url: `/tareas`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["FacturaTareas"],
    }),
    generarDTE: builder.mutation({
      query: ({ sucursal, body }) => ({
        url: `/obtenerFolios/${sucursal}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProcesoFacturacion"],
    }),
    addVencimientoFactura: builder.mutation({
      query: (body) => ({
        url: `/vencimientoFactura`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["FacturaPlanes"],
    }),
    updateVencimientoFactura: builder.mutation({
      query: (body) => ({
        url: `/vencimientoFactura`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaPlanes"],
    }),

    updateEstadoFacturaTarea: builder.mutation({
      query: ({ id, body }) => ({
        url: `/tareas/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaTareas"],
    }),
    updateEstadoFacturaPlan: builder.mutation({
      query: ({ id, body }) => ({
        url: `/planes/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaPlanes"],
    }),
    updateEstadoFacturaAfecta: builder.mutation({
      query: ({ id, body }) => ({
        url: `/planes/facturaAfecta/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaAfecta"],
    }),

    updateEstadoCorreFacturaPlanes: builder.mutation({
      query: ({ id, body }) => ({
        url: `/tareas/empleado/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaTareas"],
    }),
    updateEstadoCorreFacturaAfecta: builder.mutation({
      query: ({ id, body }) => ({
        url: `/planes/empleado/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaAfecta"],
    }),
    updateEstadoCorreFacturaTareas: builder.mutation({
      query: ({ id, body }) => ({
        url: `/afecta/empleado/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturaTareas"],
    }),
    actualizarYeliminarProcesoFactura: builder.mutation({
      query: (id) => ({
        url: `/procesoFacturaPlanes/actualizarPeriodo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProcesoFacturacion"],
    }),
    obtenerDocumentoPorToken: builder.query({
      query: (token) => ({
        url: `/facturaOpenFactura/obtenerDocumentoPorToken/${token}`,
      }),
    }),
    obtenerDocumentoReferencia: builder.query({
      query: (folio) => ({
        url: `/facturaOpenFactura/obtenerDocumentoReferencia/${folio}`,
      }),
    }),
    enlazarFacturaASucursal: builder.mutation({
      query: ({ idFactura, ...body }) => ({
        url: `/enlazarFacturaASucursal/${idFactura}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturasSinSucursal"],
    }),
    enlazarFacturasASucursalMasivamente: builder.mutation({
      query: (body) => ({
        url: `/enlazarFacturasASucursalMasivamente`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["FacturasSinSucursal"],
    }),
  }),
});

export const {
  useGenerarDTEMutation,
  useActualizarYeliminarProcesoFacturaMutation,
  useGetDetalleFacturaPlanesQuery,
  useGetFacturaOpenFacturaQuery,
  useGetFacturaTareasIDQuery,
  useGetFacturasAfectaQuery,
  useGetFacturasPlanesClienteQuery,
  useGetFacturasPlanesEmpleadoQuery,
  useGetFacturasPlanesQuery,
  useGetFacturasTareasClienteQuery,
  useGetFacturasTareasEmpleadoQuery,
  useGetFacturasTareasQuery,
  useObtenerFacturasSinSucursalQuery,
  useGetProcesoFacturaPlanesQuery,
  useGetTokenNotaCreditoQuery,
  useGetVencimientoFacturasQuery,
  useGetTokenFolioRefQuery,
  useGetFacturasPorSucursalQuery,
  useObtenerFacturasEmitidasQuery,
  useGetFacturasDeTareasEmpleadoEmitidasQuery,
  useGetFacturasTotalExportarQuery,
  useGetPendientePorFacturarTareasEmpleadoQuery,
  useGetRecaudadoTareasEspecialesEmpleadoQuery,
  useObtenerPromedioFacturasEmitidasPorClienteQuery,
  useObtenerDocumentoPorTokenQuery,
  useObtenerDocumentoReferenciaQuery,
  useObtenerClientesMayorCantidadFacturasPagadasQuery,
  useEnlazarFacturaASucursalMutation,
  useEnlazarFacturasASucursalMasivamenteMutation,
  useLazyObtenerFacturasEmitidasQuery,
  useLazyObtenerClientesMayorCantidadFacturasPagadasQuery,
  useLazyObtenerDocumentoReferenciaQuery,
  useLazyObtenerPromedioFacturasEmitidasPorClienteQuery,
  useLazyGetRecaudadoTareasEspecialesEmpleadoQuery,
  useLazyGetPendientePorFacturarTareasEmpleadoQuery,
  useLazyGetFacturasDeTareasEmpleadoEmitidasQuery,
  useLazyObtenerDocumentoPorTokenQuery,
  useLazyObtenerFacturasSinSucursalQuery,
  useLazyGetFacturasTotalExportarQuery,
  useLazyGetFacturasPorSucursalQuery,
  useLazyGetTokenFolioRefQuery,
  useLazyGetDetalleFacturaPlanesQuery,
  useLazyGetFacturaOpenFacturaQuery,
  useLazyGetFacturaTareasIDQuery,
  useLazyGetFacturasAfectaQuery,
  useLazyGetFacturasPlanesClienteQuery,
  useLazyGetFacturasPlanesEmpleadoQuery,
  useLazyGetFacturasPlanesQuery,
  useLazyGetFacturasTareasClienteQuery,
  useLazyGetFacturasTareasEmpleadoQuery,
  useLazyGetFacturasTareasQuery,
  useLazyGetProcesoFacturaPlanesQuery,
  useLazyGetTokenNotaCreditoQuery,
  useLazyGetVencimientoFacturasQuery,
  useAddCrearFacturaPlanMutation,
  useAddCrearFacturaTareaMutation,
  useFacturarManualMutation,
  useAddVencimientoFacturaMutation,
  useUpdateEstadoCorreFacturaAfectaMutation,
  useUpdateEstadoCorreFacturaPlanesMutation,
  useUpdateEstadoCorreFacturaTareasMutation,
  useUpdateEstadoFacturaAfectaMutation,
  useUpdateEstadoFacturaPlanMutation,
  useUpdateEstadoFacturaTareaMutation,
  useUpdateVencimientoFacturaMutation,
  useGetFacturasPlanesExportarQuery,
  useLazyGetFacturasPlanesExportarQuery,
  useGetFacturasClienteQuery,
  useGetFacturasClientesQuery,
  useLazyGetFacturasClienteQuery,
  useLazyGetFacturasClientesQuery,
  useGetPeriodoFacturacionQuery,
  useLazyGetPeriodoFacturacionQuery,
} = facturasApi;
