import {
  Box,
  Card,
  CardHeader,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  alpha,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { fNumber } from "src/utils/formatNumber";

AppEstadisticasTareaEspecial.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default function AppEstadisticasTareaEspecial({
  title,
  subheader,
  data,
  loading = false,
  handleClick = null,
  ...other
}) {
  if (loading) {
    return (
      <Card>
        <CardHeader
          title={<Skeleton animation="wave" width={200} />}
          subheader={<Skeleton animation="wave" width={100} />}
        />
        <Stack spacing={3} sx={{ px: 3, my: 5 }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <LinearProgress
              key={index}
              variant="indeterminate"
              sx={{
                height: 8,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.16),
              }}
            />
          ))}
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          sx={{ px: { xs: 1, sm: 3 }, pb: 3 }}
        >
          {Array.from({ length: 5 }).map((_, index) => (
            <Stack key={index} alignItems="center" sx={{ mb: 1 }}>
              <Skeleton variant="circular" />
              <Typography
                variant="subtitle2"
                sx={{ color: "text.secondary", mt: 1 }}
              >
                <Skeleton animation="wave" width={60} />
              </Typography>
              <Typography variant="h6">
                <Skeleton animation="wave" width={40} />
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Stack spacing={2} sx={{ px: 3, my: 5 }}>
        {data.map((progress) => (
          <LinearProgressWithLabel
            key={progress.estado}
            value={progress.porcentaje}
            color={
              (progress.estado === "Pendientes" && "warning") ||
              (progress.estado === "Canceladas" && "error") ||
              (progress.estado === "Totales" && "secondary") ||
              (progress.estado === "En proceso" && "info") ||
              "success"
            }
            sx={{
              height: 8,
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.16),
            }}
          />
        ))}
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        sx={{ px: { xs: 1, sm: 3 }, pb: 3 }}
      >
        {data.map((progress) => (
          <Stack key={progress.estado} alignItems="center" sx={{ mb: 1 }}>
            <Box
              sx={{
                width: { xs: 8, sm: 12 },
                height: { xs: 8, sm: 12 },
                borderRadius: 0.5,
                bgcolor:
                  progress.estado === "Pendientes"
                    ? "warning.main"
                    : progress.estado === "Canceladas"
                    ? "error.main"
                    : progress.estado === "Totales"
                    ? "secondary.main"
                    : progress.estado === "En proceso"
                    ? "info.main"
                    : "success.main",
              }}
            />
            <Typography
              variant="subtitle2"
              sx={{
                color: "text.secondary",
                mt: 1,
                cursor: progress.estado !== "Totales" && "pointer",
              }}
              onClick={
                progress.estado !== "Totales"
                  ? () => handleClick(progress.estado)
                  : null
              }
            >
              {progress.estado}
            </Typography>
            <Typography variant="h6">
              {fNumber(progress.cantidad_tareas)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
