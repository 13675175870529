import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { validateRut } from "@fdograph/rut-utilities";
import Alert from "@material-ui/core/Alert";
import { LoadingButton } from "@material-ui/lab";

import ForgotPassword from "./ForgotPassword";
import LoginOptionsModal from "./LoginOptionsModal";
import { API_SERVER } from "../../../utils/urlBack_End";
import { reverseStr } from "../../../utils/converter";
import { Alerta } from "../../Alerta";
import { useDispatch } from "react-redux";
import { login } from "../../../reducers/authReducers";
import { decodeToken } from "react-jwt";
// ----------------------------------------------------------------------
const LOCAL_STORAGE_KEY = "taxtic.";

export default function LoginForm(props) {
  const { isClient } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [serverError, setServerError] = useState("");
  const [showLoginOptions, setShowLoginOptions] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordMsg, setForgotPasswordMsg] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [userObtenido, setUserObtenido] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("El correo electrónico es inválido.")
      .required("Este campo es obligatorio."),
    password: Yup.string().required("Este campo es obligatorio."),
  });

  const LoginSchemaCliente = Yup.object().shape({
    rutCliente: Yup.string()
      .test("rut test", "Rut no válido", (value) => validateRut(value))
      .required("Este campo es obligatorio."),
    password: Yup.string().required("Este campo es obligatorio."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  });

  const formikCliente = useFormik({
    initialValues: {
      rutCliente: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchemaCliente,
    onSubmit: handleLoginCliente,
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    !isClient ? formik : formikCliente;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  async function handleLogin(e, { props, setSubmitting, setErrors }) {
    setServerError("");
    setLoginError("");
    try {
      let newUser;
      axios
        .post(`${API_SERVER}/login`, {
          headers: {
            "Content-Type": "application/json",
          },
          correo: e.email,
          contrasena: e.password,
        })
        .then(function (response) {
          localStorage.setItem("token", response.data);
          const myDecodedToken = decodeToken(response.data);
          newUser = myDecodedToken;
          newUser.sucursal = myDecodedToken.sucursales[0];
          setSucursales(myDecodedToken.sucursales);
          const reverseRut = reverseStr(myDecodedToken.empleado.rut);
          // Guardar el rut invertido en el local storage
          localStorage.setItem(LOCAL_STORAGE_KEY + "id", reverseRut);

          if (newUser.sucursales.length > 1) {
            dispatch(login(newUser));
            setUserObtenido(newUser);
            setShowLoginOptions(true);
          } else {
            newUser.rol = newUser.sucursal.refRol;
            // Guardar la sucursal en el local storage
            localStorage.setItem(
              LOCAL_STORAGE_KEY + "sucursal",
              newUser.sucursal.refSucursal
            );
            localStorage.setItem("sucursal", newUser.sucursal.refSucursal);
            localStorage.setItem("rol", newUser.sucursal.refRol);
            dispatch(login(newUser));
            navigate("/dashboard", { replace: true });
          }
        })
        .catch(function (error) {
          setLoginError(
            "No se encontró un usuario activo con esta combinación de usuario" +
              " y contraseña."
          );
        });
    } catch (ex) {
      setServerError(
        "Ha ocurrido un error en el servidor. " +
          "Por favor reintente más tarde."
      );
    }
  }

  async function handleLoginCliente(e, { props, setSubmitting, setErrors }) {
    setServerError("");
    setLoginError("");
    try {
      let res = await axios.post(`${API_SERVER}/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        correo: e.rutCliente,
        contrasena: e.password,
        cliente: true,
      });

      const respuesta = res.data;

      if (respuesta.ok) {
        setLoginError(respuesta.err.message);
      } else {
        localStorage.setItem("token", respuesta);
        localStorage.setItem("sucursal", "CL");
        localStorage.setItem(LOCAL_STORAGE_KEY + "token", respuesta);
        const myDecodedToken = decodeToken(respuesta);
        const rol = "CLIENTE";
        localStorage.setItem("rol", "CLIENTE");
        let newUser = { ...myDecodedToken, rol };
        const reverseRut = reverseStr(myDecodedToken.cliente.rut);
        localStorage.setItem(LOCAL_STORAGE_KEY + "id", reverseRut);
        localStorage.setItem(LOCAL_STORAGE_KEY + "sucursal", "CL");
        dispatch(login(newUser));
        navigate("/dashboard", { replace: true });
      }
    } catch (ex) {
      setServerError(
        "Ha ocurrido un error en el servidor. " +
          "Por favor reintente más tarde."
      );
    }
  }
  const handleCloseForgotPassword = (msg) => {
    setForgotPassword(false);
    setForgotPasswordMsg(msg);
    setShowSnackbar(true);
  };

  return (
    <>
      <FormikProvider value={!isClient ? formik : formikCliente}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            {serverError && <Alert severity="error">{serverError}</Alert>}
            {!isClient ? (
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Correo"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            ) : (
              <TextField
                fullWidth
                autoComplete="username"
                label="Rut"
                {...getFieldProps("rutCliente")}
                error={Boolean(touched.rutCliente && errors.rutCliente)}
                helperText={touched.rutCliente && errors.rutCliente}
              />
            )}

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <p
              onClick={() => {
                setForgotPassword(true);
                setForgotPasswordMsg("");
              }}
              style={{
                color: "#ff802e",
                cursor: "pointer",
                fontSize: "0.9rem",
              }}
            >
              ¿Olvidaste tu contraseña?
            </p>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Ingresar
          </LoadingButton>
        </Form>
      </FormikProvider>
      {sucursales.length > 0 && (
        <LoginOptionsModal
          sucursales={sucursales}
          open={showLoginOptions}
          user={userObtenido}
        />
      )}

      {forgotPassword && (
        <ForgotPassword
          open={forgotPassword}
          onClose={handleCloseForgotPassword}
        />
      )}

      {forgotPasswordMsg && (
        <Alerta
          showAlert={showSnackbar}
          setShowAlert={setShowSnackbar}
          color="success"
          message={forgotPasswordMsg}
        />
      )}
    </>
  );
}
