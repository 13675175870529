import React, { useState, useEffect, useCallback } from "react";
import { API_SERVER, ACCESS_TOKEN } from "../../utils/urlBack_End";
import { lowerCase, startCase } from "lodash";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Step,
  StepLabel,
  Stepper,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Alert,
  Snackbar,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  styled,
  FormGroup,
} from "@material-ui/core";
import axios from "axios";
import { round } from "lodash";
import { validateRut } from "@fdograph/rut-utilities";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DownloadIcon from "@material-ui/icons/Download";
import CloseIcon from "@material-ui/icons/Close";
import { TemplateHandler } from "easy-template-x";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { useSelector } from "react-redux";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { ajustesApi } from "src/services/ajustesApi";
import dayjs from "dayjs";
import { tareasApi } from "src/services/tareasApi";
import GraficoComisionesEmpleado from "../graficos/GraficoComisionesEmpleado";

const steps = [
  "Vendedor",
  "Cliente",
  "Tarea",
  "Subtareas",
  "Adicionales",
  "Participaciones",
  "Nota",
  "Total",
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

// Función para convertir la cadena a formato deseado
const formatearNombre = (nombre) => {
  // Dividir la cadena en palabras
  const palabras = nombre.split(" ");

  // Convertir la primera letra de cada palabra a mayúscula y el resto a minúscula
  const palabrasFormateadas = palabras.map(
    (palabra) =>
      palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
  );

  // Unir las palabras formateadas en una nueva cadena
  const nombreFormateado = palabrasFormateadas.join(" ");

  return nombreFormateado;
};

const validarFormatoCorreoElectronico = (email) => {
  // Expresión regular para validar el formato de un correo electrónico
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export default function AgregarTareaEspecial(props) {
  const { openTareaNormal, setOpenTareaNormal, monedas } = props;
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);
  const sucursal = sucursalObtenida.refSucursal;
  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [tareas, setTareas] = useState([]);
  const [mostrarCircular, setMostrarCircular] = useState(false);

  const [empleados, setEmpleados] = useState([]);

  const [empleadosAdicionales, setEmpleadosAdicionales] = useState([]);

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [nombreTareaSeleccionada, setNombreTareaSeleccionada] = useState(null);

  const [labelChecked, setLabelChecked] = useState("Cliente Existente");
  const [labelChecked2, setLabelChecked2] = useState("Tarea Existente");

  const [skipped, setSkipped] = useState(new Set());
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(true);

  const [vendedorSeleccionado, setVendedorSeleccionado] = useState(null);
  const [mostrarAutocompleteVendedor, setMostrarAutocompleteVendedor] =
    useState(true);
  const [vendidoTaxtic, setVendidoTaxtic] = useState(false);
  const [rutCliente, setRutCliente] = useState("");
  const [nombreCliente, setNombreCliente] = useState("");
  const [direccionCliente, setDireccionCliente] = useState("");
  const [comunaCliente, setComunaCliente] = useState("");
  const [telefonoCliente, setTelefonoCliente] = useState("");
  const [correoCliente, setCorreoCliente] = useState("");
  const [correoFacturacionCliente, setCorreoFacturacionCliente] = useState("");
  const [nombreTarea, setNombreTarea] = useState("");
  const [valorTarea, setValorTarea] = useState("");
  const [tipoMonedaTarea, setTipoMonedaTarea] = useState("");
  const [descripcionTarea, setDescripcionTarea] = useState("");
  const [descuentoAdicional, setDescuentoAdicional] = useState(0);
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [rutEmpleado, setRutEmpleado] = useState("");
  const [rutEmpleadoAdicional, setRutEmpleadoAdicional] = useState("");
  const [rutSupervisor, setRutSupervisor] = useState("");
  const [nuevaSubtarea, setNuevaSubtarea] = useState("");

  const [comisionTaxtic, setComisionTaxtic] = useState(0);
  const [comisionVendedor, setComisionVendedor] = useState(0);
  const [comisionSupervisor, setComisionSupervisor] = useState(0);
  const [comisionEmpleado, setComisionEmpleado] = useState(0);
  const [porcentajeUtilidad, setPorcentajeUtilidad] = useState(0);

  const [participacionTaxtic, setParticipacionTaxtic] = useState(0);
  const [participacionVendedor, setParticipacionVendedor] = useState(0);
  const [participacionEmpleado, setParticipacionEmpleado] = useState(0);
  const [participacionSupervisor, setParticipacionSupervisor] = useState(0);
  const [utilidad, setUtilidad] = useState(0);

  const [acumuladoPorcentaje, setAcumuladoPorcentaje] =
    useState(comisionTaxtic);
  const [participacionesInformacion, setParticipacionesInformacion] = useState(
    []
  );
  const [labelParticipaciones, setLabelParticipaciones] = useState([]);

  const [subTareas, setSubTareas] = useState([]);

  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);

  const [empleadoSelect, setEmpleadoSelect] = useState(null);
  const [empleadoPrincipalSelect, setEmpleadoPrincipalSelect] = useState(null);
  const [supervisorSelect, setSupervisorSelect] = useState(null);
  const [empleadoAdicionalSelect, setEmpleadoAdicionalSelect] = useState(null);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  const [fechaLimiteTarea, setFechaLimiteTarea] = useState(null);

  const [noAvanza, setNoAvanza] = useState(false);

  const { data: comunas = [] } = ajustesApi.useGetComunasQuery();
  const [agregarTareaEspecial] = tareasApi.useAgregarTareaEspecialMutation();

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
    axios
      .get(`${API_SERVER}/clientes/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClientes(response.data);
      });

    axios
      .get(`${API_SERVER}/tipos/tipoTareas/ESPECIAL`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setTareas(response.data);
      });
    setRutEmpleado(user.rut);
    setVendedorSeleccionado({
      label: user.nombre + " " + user.apellidos,
      value: user.rut,
    });
    setEmpleadoSelect({
      label: user.nombre + " " + user.apellidos,
      value: user.rut,
    });
    setEmpleadoPrincipalSelect({
      label: user.nombre + " " + user.apellidos,
      value: user.rut,
    });
  }, [sucursal, user.apellidos, user.nombre, user.rut]);

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    if (rutEmpleado !== "" && empleadoOptions.length > 0) {
      setSupervisorOptions(
        empleadoOptions.filter((option) => option.value !== rutEmpleado)
      );
    }
  }, [rutEmpleado, empleadoOptions]);

  useEffect(() => {
    const buscarEmpleado = () => {
      let empleado = null;
      let supervisor = null;
      if (rutEmpleado !== "" && empleadoOptions.length > 0) {
        empleado = empleadoOptions.find((item) => item.value === rutEmpleado);

        setEmpleadoPrincipalSelect(empleado !== undefined ? empleado : null);

        const supervisores =
          empleado !== undefined
            ? empleadoOptions.filter((item) => item.value !== empleado.value)
            : [];

        setSupervisorOptions([...supervisores]);
      }

      if (rutSupervisor !== "" && empleadoOptions.length > 0) {
        supervisor = empleadoOptions.find(
          (item) => item.value === rutSupervisor
        );
        setSupervisorSelect(supervisor !== undefined ? supervisor : null);
      }
    };
    if (supervisorSelect === null && empleadoPrincipalSelect === null)
      buscarEmpleado();
  }, [
    empleadoOptions,
    empleadoPrincipalSelect,
    supervisorSelect,
    rutEmpleado,
    rutSupervisor,
  ]);

  function formatNumber(n) {
    n = String(n).replace(/\D/g, "");
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  function verificarNumero(n) {
    n = String(n);
    let expreg = /^[0-9]+\.[0-9]*$|^[0-9]+$/;
    return expreg.test(n);
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChange = (event) => {
    if (checked) {
      setClienteSeleccionado(null);

      setLabelChecked("Cliente Nuevo");
    } else {
      setLabelChecked("Cliente Existente");
    }
    setRutCliente("");
    setNombreCliente("");
    setDireccionCliente("");
    setComunaCliente("");
    setTelefonoCliente("");
    setCorreoCliente("");
    setCorreoFacturacionCliente("");
    setChecked(event.target.checked);
  };

  const handleChange2 = (event) => {
    if (checked2) {
      setNombreTareaSeleccionada(null);
      setLabelChecked2("Tarea Nueva");
    } else {
      setLabelChecked2("Tarea Existente");
    }
    setNombreTarea("");
    setValorTarea("");
    setTipoMonedaTarea("");
    setDescripcionTarea("");
    setChecked2(event.target.checked);
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  function saveFile(filename, blob) {
    const blobUrl = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  function capitalizarPalabras(val) {
    return val
      .toLowerCase()
      .trim()
      .split(". ")
      .map((v) => v[0].toUpperCase() + v.substr(1))
      .join(". ");
  }

  const generateDocument = async () => {
    let dropboxEndpoint1 = "https://content.dropboxapi.com/2/files/download";
    let dropBoxApiArg = { path: "/Archivos/PropuestaMODELO.docx" };
    let postOptions1 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Dropbox-API-Arg": JSON.stringify(dropBoxApiArg),
      },
    };
    const response = await fetch(dropboxEndpoint1, postOptions1);
    const templateFile = await response.blob();
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let yyyy = today.getFullYear();

    let planesAux = [];
    let decripcionTareaAUX = "";

    if (descripcionTarea !== "") {
      decripcionTareaAUX = capitalizarPalabras(descripcionTarea);
    }

    planesAux.push({
      descripcion: decripcionTareaAUX,
      nombreCliente: nombreCliente,
      nombrePlan: nombreTarea,
      tipoMoneda: tipoMonedaTarea,
      valor: valorTarea,
    });

    let meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    today = dd + " de " + meses[today.getMonth()] + " de " + yyyy;
    const data = {
      ciudad: capitalizarPalabras(sucursalObtenida.comuna),
      fechaActual: today,
      nombreCliente: nombreCliente,
      nombreEmpleado: user.nombre + " " + user.apellidos,
      Planes: planesAux,
    };
    const handler = new TemplateHandler();
    const doc = await handler.process(templateFile, data);
    saveFile("Propuesta " + nombreCliente + ".docx", doc);
  };

  const handleNext = () => {
    if (
      activeStep === 0 &&
      mostrarAutocompleteVendedor &&
      (!vendedorSeleccionado || !vendedorSeleccionado)
    ) {
      setMensajeNotificacion("Vendedor Requerido");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (activeStep === 1) {
      if (!validateRut(rutCliente.toLowerCase())) {
        setMensajeNotificacion("Rut Inválido");
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else if (nombreCliente.trim() === "") {
        setMensajeNotificacion("El nombre del cliente es requerido");
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else if (direccionCliente.trim() === "") {
        setMensajeNotificacion("La dirección del cliente es requerida");
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else if (comunaCliente.trim() === "") {
        setMensajeNotificacion("La comuna del cliente es requerida");
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else if (correoFacturacionCliente.trim() === "") {
        setMensajeNotificacion(
          "El correo de facturación del cliente es requerido"
        );
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else if (!validarFormatoCorreoElectronico(correoFacturacionCliente)) {
        setMensajeNotificacion("Formato de correo electrónico inválido");
        setColorNotificacion("error");
        setOpenNotificacion(true);
      } else {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (
      activeStep === 2 &&
      (nombreTarea === "" ||
        nombreTarea === " " ||
        valorTarea === "" ||
        valorTarea === " " ||
        tipoMonedaTarea === "" ||
        tipoMonedaTarea === " ")
    ) {
      setMensajeNotificacion("Completar Datos Requeridos");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (
      activeStep === 2 &&
      (!empleadoPrincipalSelect || !supervisorSelect)
    ) {
      const textoAMostrar = !empleadoPrincipalSelect
        ? "Debe seleccionar un empleado principal"
        : "Debe seleccionar un supervisor a cargo";
      setMensajeNotificacion(textoAMostrar);
      setColorNotificacion("error");
      setOpenNotificacion(true);
    } else if (activeStep === 5 && acumuladoPorcentaje > 100) {
      setMensajeNotificacion(
        "El porcentaje total de las participaciones, no debe ser mayor a 100"
      );
      setColorNotificacion("error");
      setOpenNotificacion(true);
    } else if (activeStep === 6 && !fechaLimiteTarea) {
      setMensajeNotificacion("Debe seleccionar una fecha límite para la tarea");
      setColorNotificacion("error");
      setOpenNotificacion(true);
    } else {
      if (activeStep === 2) {
        setTotalAPagar(valorTarea);
      }
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelar = () => {
    setOpenTareaNormal(false);
  };

  // Función para limpiar y convertir un valor a punto flotante con dos decimales
  const cleanAndParseFloat = (value) => {
    const cleanedValue = String(value).replace(/[,.]/g, "");
    return parseFloat(round(cleanedValue, 2));
  };

  // Función para manejar la actualización de comisiones
  const updateCommission = (commission, setter) => {
    const cleanedCommission = String(commission).trim();
    setter(
      cleanedCommission === "" ? 0 : parseFloat(Number(cleanedCommission))
    );
  };

  const onSubmitTareaEspecial = () => {
    setMostrarCircular(true);
    const fechaLimiteFinal = dayjs(fechaLimiteTarea).format("YYYY/MM/DD");

    AlertaModal({
      title: "crear la tarea",
      confirmButtonText: "Sí, crear",
      target: document.getElementById("dialogAgregarTareaEspecial"),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          //Si la comisión llega vacía, se coloca en cero.
          updateCommission(comisionEmpleado, setComisionEmpleado);
          updateCommission(comisionSupervisor, setComisionSupervisor);
          updateCommission(comisionVendedor, setComisionVendedor);

          // Se procesa el valor de la tarea segun su tipo
          let valorTareaAux =
            tipoMonedaTarea.toUpperCase() === "PESOS"
              ? cleanAndParseFloat(valorTarea)
              : parseFloat(round(valorTarea, 2));

          let body = {
            subTareas: subTareas,
            empleados: empleadosAdicionales.map((empleado) => empleado.rut),
            refCliente: rutCliente,
            refEmpleado: rutEmpleado,
            nombre: nombreTarea.toUpperCase(),
            sucursal: sucursal.toUpperCase(),
            comentario: descripcionTarea.toUpperCase(),
            vendedor: vendedorSeleccionado,
            descuento: descuentoAdicional,
            valorTarea: valorTareaAux,
            tipoMonedaTarea: tipoMonedaTarea.toUpperCase(),
            supervisor: rutSupervisor,
            nombreCliente: nombreCliente.toUpperCase(),
            direccion: direccionCliente.toUpperCase(),
            comuna: comunaCliente.toUpperCase(),
            telefono: telefonoCliente,
            correo: correoCliente,
            correoFacturacion: correoFacturacionCliente,
            vendidoTaxtic: vendidoTaxtic,
            comisionTaxtic: Number(comisionTaxtic),
            comisionVendedor: Number(comisionVendedor),
            comisionEmpleado: Number(comisionEmpleado),
            comisionSupervisor: Number(comisionSupervisor),
            porcentajeUtilidad: Number(porcentajeUtilidad),
            participacionTaxtic: Number(participacionTaxtic),
            participacionVendedor: Number(participacionVendedor),
            participacionEmpleado: Number(participacionEmpleado),
            participacionSupervisor: Number(participacionSupervisor),
            utilidad: Number(utilidad),
            fechaLimite: fechaLimiteFinal,
          };

          agregarTareaEspecial(body)
            .then((response) => {
              if (response.data) {
                AlertaToast({
                  element: "Tarea especial",
                  action: "creada",
                }).fire();
              } else {
                AlertaToast({
                  action: "Error al crear la tarea especial",
                  fullText: true,
                  type: "error",
                }).fire();
              }
            })
            .catch((error) => {
              console.error("Error en guardar() agregarTareaEspecial", error);
              AlertaToast({
                action: "Ocurrió un error desconocido en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            })
            .finally(() => {
              setMostrarCircular(false);
              setOpenTareaNormal(false);
            });
        }
      });
  };

  const handleChangeNuevaSubtarea = (event) => {
    setNuevaSubtarea(String(event.target.value).toUpperCase());
  };

  const handleChangeRutCliente = (event) => {
    setRutCliente(event.target.value);
  };

  const handleChangeNombreCliente = (event) => {
    setNombreCliente(event.target.value);
  };

  const handleChangeDireccionCliente = (event) => {
    setDireccionCliente(event.target.value);
  };

  const handleChangeTelefonoCliente = (event) => {
    setTelefonoCliente(event.target.value);
  };

  const handleChangeCorreoCliente = (event) => {
    setCorreoCliente(event.target.value);
  };

  const handleChangeCorreoFacturacionCliente = (event) => {
    setCorreoFacturacionCliente(event.target.value);
  };

  const handleChangeNombreTarea = (event) => {
    setNombreTarea(event.target.value);
  };

  const handleChangeComisionVendedor = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionVendedor(event.target.value);
    } else {
      setComisionVendedor(0);
    }
  };

  const handleChangeComisionTaxtic = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionTaxtic(event.target.value);
    } else {
      setComisionTaxtic(0);
    }
  };
  const handleChangeComisionEmpleado = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionEmpleado(event.target.value);
    } else {
      setComisionEmpleado(0);
    }
  };
  const handleChangeComisionSupervisor = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionSupervisor(event.target.value);
    } else {
      setComisionSupervisor(0);
    }
  };

  const handleChangeDescuentoAdicional = (event) => {
    let valor = valorTarea - valorTarea * event.target.value * 0.01;
    if (tipoMonedaTarea === "PESOS") {
    } else {
      valor = round(valor, 2);
    }

    setTotalAPagar(valor);
    setDescuentoAdicional(event.target.value);
  };

  const handleChangValorTarea = (event) => {
    if (verificarNumero(event.target.value)) {
      setValorTarea(event.target.value);
    } else {
      setValorTarea("");
    }
  };

  const handleChangeTipoMoneda = (event) => {
    if (event.target.value === "PESOS" && String(valorTarea).includes(".")) {
      setValorTarea(String(valorTarea).replaceAll(".", ""));
    }
    setTipoMonedaTarea(event.target.value);
  };

  const handleChangeDescripcionTarea = (event) => {
    setDescripcionTarea(event.target.value);
  };

  const handleChangeSupervisores = (value) => {
    const supervisores = empleadoOptions.filter(
      (item) => item.value !== value.value
    );

    setSupervisorOptions([...supervisores]);
  };

  const handleChangeVendidoTaxtic = () => {
    setVendidoTaxtic(!vendidoTaxtic);
    setMostrarAutocompleteVendedor(!mostrarAutocompleteVendedor);
  };

  const reiniciarValoresParticipaciones = useCallback(() => {
    setVendedorSeleccionado(null);
    setEmpleadoSelect(null);
    setComisionVendedor(0);
    setComisionEmpleado(0);
    setComisionSupervisor(0);
    setParticipacionVendedor(0);
    setParticipacionEmpleado(0);
    setParticipacionSupervisor(0);
  }, []);

  useEffect(() => {
    if (vendidoTaxtic) {
      reiniciarValoresParticipaciones();
    }
  }, [reiniciarValoresParticipaciones, vendidoTaxtic]);

  const calcularParticipacion = (valor, porcentaje) => {
    return valor * (porcentaje / 100);
  };

  const construirLabelsGrafico = useCallback(() => {
    const labels = [
      "Taxtic (15%)",
      `${
        vendedorSeleccionado
          ? formatearNombre(vendedorSeleccionado.label) +
            " (" +
            comisionVendedor +
            "%)"
          : "Vendedor (0%)"
      }`,
      `${formatearNombre(
        empleadoPrincipalSelect.label
      )} (${comisionEmpleado}%)`,
      `${formatearNombre(supervisorSelect.label)} (${comisionSupervisor}%)`,
      `Utilidad (${porcentajeUtilidad}%)`,
    ];
    setLabelParticipaciones(labels);
  }, [
    comisionEmpleado,
    comisionSupervisor,
    comisionVendedor,
    empleadoPrincipalSelect?.label,
    porcentajeUtilidad,
    supervisorSelect?.label,
    vendedorSeleccionado,
  ]);

  const calcularParticipaciones = useCallback(() => {
    // Se calcula el porcentaje restante, para ver la utilidad que se genera
    const utilidadPorcentaje = 100 - acumuladoPorcentaje;
    setPorcentajeUtilidad(utilidadPorcentaje);

    setParticipacionTaxtic(calcularParticipacion(valorTarea, comisionTaxtic));
    setParticipacionEmpleado(
      calcularParticipacion(valorTarea, comisionEmpleado)
    );
    setParticipacionSupervisor(
      calcularParticipacion(valorTarea, comisionSupervisor)
    );

    if (!vendidoTaxtic) {
      setParticipacionVendedor(
        calcularParticipacion(valorTarea, comisionVendedor)
      );
    }
    setUtilidad(calcularParticipacion(valorTarea, porcentajeUtilidad));

    const arrayParticipaciones = [
      participacionTaxtic,
      participacionVendedor,
      participacionEmpleado,
      participacionSupervisor,
      utilidad,
    ];
    setParticipacionesInformacion(arrayParticipaciones);
  }, [
    acumuladoPorcentaje,
    comisionEmpleado,
    comisionSupervisor,
    comisionTaxtic,
    comisionVendedor,
    participacionEmpleado,
    participacionSupervisor,
    participacionTaxtic,
    participacionVendedor,
    porcentajeUtilidad,
    utilidad,
    valorTarea,
    vendidoTaxtic,
  ]);

  const calcularComisiones = useCallback(() => {
    setComisionTaxtic(15);

    // Calcular el total de las comisiones
    const acumulado =
      Number(comisionTaxtic) +
      Number(comisionEmpleado) +
      Number(comisionSupervisor) +
      Number(comisionVendedor);
    setAcumuladoPorcentaje(acumulado);

    // Como máximo, un vendedor puede tener 10% de participación.
    if (comisionVendedor > 15) {
      handleNotification(
        "La participación del vendedor no debe ser mayor a 15",
        "error"
      );
      setNoAvanza(true);
    } else {
      setNoAvanza(false);
    }

    if (acumuladoPorcentaje > 100) {
      handleNotification(
        "El porcentaje total de las participaciones no debe ser mayor a 100",
        "error"
      );
    } else {
      construirLabelsGrafico();
      calcularParticipaciones();
    }
  }, [
    acumuladoPorcentaje,
    construirLabelsGrafico,
    calcularParticipaciones,
    comisionEmpleado,
    comisionSupervisor,
    comisionTaxtic,
    comisionVendedor,
  ]);

  const manejarDatosGrafico = useCallback(() => {
    if (activeStep === 5) {
      if (valorTarea === 0) {
        reiniciarDatosGrafico();
      } else {
        calcularComisiones();
      }
    }
  }, [activeStep, calcularComisiones, valorTarea]);

  const reiniciarDatosGrafico = () => {
    const labels = [
      "Taxtic (15%)",
      "Vendedor (0%)",
      "Empleado (0%)",
      "Supervisor (0%)",
      "Utilidad (0%)",
    ];
    setComisionTaxtic(15);
    setParticipacionesInformacion([]);
    setLabelParticipaciones(labels);
  };

  const handleNotification = (message, color) => {
    setMensajeNotificacion(message);
    setColorNotificacion(color);
    setOpenNotificacion(true);
  };

  useEffect(() => {
    manejarDatosGrafico();
  }, [manejarDatosGrafico]);

  const chart = {
    series: participacionesInformacion,
    options: {
      chart: {
        type: "polarArea",
      },
      labels: labelParticipaciones,
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      noData: {
        text: "No hay datos disponibles",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontWeight: 700,
          fontFamily: undefined,
        },
      },
    },
  };

  return (
    <>
      <Dialog
        open={openTareaNormal}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
        id="dialogAgregarTareaEspecial"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid item xs={11} md={11}>
              Nueva Tarea Especial
            </Grid>
            <Grid item xs={1} md={1} alignContent="end">
              <Button variant="contained" onClick={cancelar}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <div style={{ overflow: "auto", height: "500px" }}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  ¿Generar Tarea?
                </Typography>

                <Button
                  onClick={generateDocument}
                  variant="contained"
                  fullWidth
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <DownloadIcon /> Descargar Prototipo Propuesta
                </Button>
              </div>
            ) : (
              <React.Fragment>
                {activeStep === 0 && (
                  <Grid
                    container
                    direction="column"
                    style={{ overflow: "auto", height: "500px" }}
                  >
                    <FormGroup>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={3}
                      >
                        <Typography variant="h4" gutterBottom>
                          Vendedor
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        marginTop={3}
                      >
                        <Grid item>
                          <Typography>¿Vendido por Taxtic?</Typography>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography marginRight={1}>No</Typography>
                          <AntSwitch
                            checked={vendidoTaxtic}
                            inputProps={{ "aria-label": "ant design" }}
                            onChange={() => handleChangeVendidoTaxtic()}
                          />
                          <Typography marginLeft={1}>Si</Typography>
                        </Grid>
                      </Grid>
                      {mostrarAutocompleteVendedor && (
                        <Grid item marginTop={3}>
                          <FormControl fullWidth>
                            <Autocomplete
                              value={empleadoSelect}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setVendedorSeleccionado(newValue);
                                  setEmpleadoSelect(newValue);
                                } else {
                                  setVendedorSeleccionado(null);
                                  setEmpleadoSelect(null);
                                }
                              }}
                              fullWidth
                              id="combo-box-empleados"
                              options={empleadoOptions}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  type="rut"
                                  label="Vendedor Asignado"
                                  required
                                  value={vendedorSeleccionado}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </FormGroup>
                  </Grid>
                )}

                {activeStep === 1 && (
                  <div style={{ overflow: "auto", height: "500px" }}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Cliente
                    </Typography>

                    <FormControlLabel
                      style={{ marginTop: "30px" }}
                      control={
                        <Switch checked={checked} onChange={handleChange} />
                      }
                      label={labelChecked}
                    />

                    {checked ? (
                      <Autocomplete
                        value={clienteSeleccionado}
                        options={clientes}
                        autoHighlight
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setClienteSeleccionado(newValue);
                            setNombreCliente(newValue.nombre);
                            setRutCliente(newValue.rut);
                            setDireccionCliente(newValue.direccion);
                            setComunaSeleccionada({ nombre: newValue.comuna });
                            setComunaCliente(newValue.comuna);
                            setTelefonoCliente(newValue.telefono);
                            setCorreoCliente(newValue.correo);
                            setCorreoFacturacionCliente(
                              newValue.correoFacturacion
                            );
                          } else {
                            setClienteSeleccionado(null);
                            setNombreCliente("");
                            setRutCliente("");
                            setDireccionCliente("");
                            setComunaCliente("");
                            setTelefonoCliente("");
                            setCorreoCliente("");
                            setCorreoFacturacionCliente("");
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              "& > span": { mr: "10px", fontSize: 18 },
                            }}
                            {...props}
                          >
                            {option.nombre} {option.apellidos}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Seleccionar Cliente"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        style={{ marginTop: "30px" }}
                        fullWidth
                        required
                        label="Nombre Cliente"
                        value={nombreCliente}
                        onChange={handleChangeNombreCliente}
                      />
                    )}

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Rut Cliente"
                      value={rutCliente}
                      required={!checked && true}
                      disabled={checked && true}
                      onChange={handleChangeRutCliente}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      required
                      label="Direccion Cliente"
                      value={direccionCliente}
                      onChange={handleChangeDireccionCliente}
                    />

                    <Autocomplete
                      style={{ marginTop: "30px" }}
                      value={comunaSeleccionada}
                      disablePortal
                      options={comunas}
                      isOptionEqualToValue={(option) => option.nombre}
                      getOptionLabel={(option) => option.nombre}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setComunaSeleccionada(newValue);
                        } else {
                          setComunaSeleccionada(null);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setComunaCliente(newInputValue);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField {...params} label="Comuna" />
                      )}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Teléfono Cliente"
                      value={telefonoCliente}
                      onChange={handleChangeTelefonoCliente}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Correo de facturación*"
                      value={correoFacturacionCliente}
                      onChange={handleChangeCorreoFacturacionCliente}
                    />
                    <TextField
                      style={{ marginTop: "30px" }}
                      fullWidth
                      label="Correo personal cliente"
                      value={correoCliente}
                      onChange={handleChangeCorreoCliente}
                    />
                  </div>
                )}

                {activeStep === 2 && (
                  <div style={{ overflow: "auto" }}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Tarea
                    </Typography>

                    <Stack>
                      <FormControlLabel
                        style={{ marginTop: "30px", width: "200px" }}
                        control={
                          <Switch checked={checked2} onChange={handleChange2} />
                        }
                        label={labelChecked2}
                      />
                    </Stack>

                    {checked2 ? (
                      <Autocomplete
                        style={{ marginTop: "30px" }}
                        value={nombreTareaSeleccionada}
                        disablePortal
                        options={tareas}
                        isOptionEqualToValue={(option) => option.nombre}
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setNombreTareaSeleccionada(newValue);
                            setNombreTarea(newValue.nombre);
                            axios
                              .get(
                                `${API_SERVER}/subtareas/tipoSegunTipoTarea/${newValue.nombre}/ESPECIAL`,
                                {
                                  headers: {
                                    Authorization:
                                      "Bearer " + localStorage.getItem("token"),
                                  },
                                }
                              )
                              .then(function (response) {
                                if (response.data === -2) return;

                                if (Array.isArray(response.data)) {
                                  let arreglo = response.data;

                                  let datos = [];

                                  arreglo.forEach((e) => {
                                    if (
                                      e.nombre &&
                                      subTareas.find(
                                        (subTarea) => subTarea === e.nombre
                                      ) === undefined
                                    ) {
                                      datos.push(e.nombre);
                                    }
                                  });
                                  setSubTareas(datos);
                                }
                              });
                            setValorTarea(newValue.valor);
                            setTipoMonedaTarea(newValue.tipoMoneda);
                            setDescripcionTarea(newValue.descripcion);
                          } else {
                            setNombreTareaSeleccionada(null);
                            setSubTareas([]);
                            setNombreTarea("");
                            setValorTarea("");
                            setTipoMonedaTarea("");
                            setDescripcionTarea("");
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setNombreTarea(newInputValue);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Nombre Tarea" />
                        )}
                      />
                    ) : (
                      <TextField
                        style={{ marginTop: "30px" }}
                        fullWidth
                        required
                        label="Nombre Tarea"
                        value={nombreTarea}
                        onChange={handleChangeNombreTarea}
                      />
                    )}
                    <FormControl style={{ marginTop: "10px", width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Tipo Moneda (*)
                      </InputLabel>
                      <Select
                        style={{ marginTop: "10px" }}
                        value={tipoMonedaTarea}
                        disabled={checked2}
                        onChange={handleChangeTipoMoneda}
                      >
                        <MenuItem value="">
                          <em>Ninguno</em>
                        </MenuItem>
                        {monedas.map((element, index) => {
                          return (
                            <MenuItem key={index} value={element.nombre}>
                              {element.nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <TextField
                      margin="dense"
                      label="Valor Tarea"
                      value={valorTarea}
                      disabled={checked2}
                      onChange={handleChangValorTarea}
                      type="number"
                      fullWidth
                      required
                    />

                    <FormControl fullWidth style={{ marginTop: "30px" }}>
                      <Autocomplete
                        disabled={!autorizado}
                        disablePortal
                        value={empleadoPrincipalSelect}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setRutEmpleado(newValue.value);
                            setEmpleadoPrincipalSelect(newValue);
                            setRutSupervisor("");
                            setSupervisorSelect(null);
                            handleChangeSupervisores(newValue);
                          } else {
                            setRutEmpleado("");
                            setEmpleadoPrincipalSelect(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-empleados"
                        options={empleadoOptions}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            type="rut"
                            label="Empleado Principal"
                            required
                            value={rutEmpleado}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "30px" }}>
                      <Autocomplete
                        disabled={empleadoPrincipalSelect === null}
                        disablePortal
                        value={supervisorSelect}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setRutSupervisor(newValue.value);
                            setSupervisorSelect(newValue);
                          } else {
                            setRutSupervisor("");
                            setSupervisorSelect(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-Supervisor"
                        options={supervisorOptions}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            type="rut"
                            label="Supervisor Asignado *"
                            value={rutSupervisor}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}

                {activeStep === 3 && (
                  <div style={{ overflow: "auto", height: "500px" }}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "10px" }}
                    >
                      Subtareas
                    </Typography>

                    <Grid container>
                      <Grid item xs={10} md={10}>
                        <TextField
                          fullWidth
                          label="Subtarea"
                          value={nuevaSubtarea}
                          onChange={handleChangeNuevaSubtarea}
                        />
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <Button
                          fullWidth
                          style={{ height: "100%" }}
                          variant="outlined"
                          onClick={() => {
                            if (nuevaSubtarea === "" || nuevaSubtarea === " ") {
                            } else {
                              let existe = subTareas.filter(
                                (e) => e === nuevaSubtarea
                              )[0];
                              if (existe) {
                              } else {
                                setSubTareas([...subTareas, nuevaSubtarea]);
                              }
                            }
                          }}
                        >
                          <AddIcon /> Agregar Subtarea
                        </Button>
                      </Grid>
                    </Grid>

                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre Subtarea</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subTareas.map((row, idx) => (
                            <TableRow key={"subTareas_" + idx}>
                              <TableCell component="th" scope="row">
                                {row}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    let existe = subTareas.filter(
                                      (e) => e !== row
                                    );
                                    setSubTareas(existe);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {activeStep === 4 && (
                  <div style={{ overflow: "auto", height: "500px" }}>
                    <Grid container>
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        Empleados Adicionales
                      </Typography>
                      <Grid item xs={10} md={10}>
                        <FormControl fullWidth style={{ marginTop: "30px" }}>
                          <Autocomplete
                            disablePortal
                            value={empleadoAdicionalSelect}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                setRutEmpleadoAdicional(
                                  empleados.find(
                                    (empleado) =>
                                      empleado.rut === newValue.value
                                  )
                                );
                                setEmpleadoAdicionalSelect(newValue);
                              } else {
                                setRutEmpleadoAdicional("");
                                setEmpleadoAdicionalSelect(null);
                              }
                            }}
                            fullWidth
                            id="combo-box-empleados"
                            options={empleadoOptions}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                type="rut"
                                label="Empleados Adicionales"
                                required
                                value={rutEmpleadoAdicional}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} md={2} style={{ marginTop: "30px" }}>
                        <Button
                          fullWidth
                          style={{ height: "100%" }}
                          variant="outlined"
                          onClick={() => {
                            if (rutSupervisor === rutEmpleadoAdicional.rut) {
                            } else if (
                              rutEmpleado === rutEmpleadoAdicional.rut
                            ) {
                            } else {
                              let existe = empleadosAdicionales.filter(
                                (e) => e.rut === rutEmpleadoAdicional.rut
                              )[0];
                              if (existe) {
                              } else {
                                setEmpleadosAdicionales([
                                  ...empleadosAdicionales,
                                  rutEmpleadoAdicional,
                                ]);
                              }
                            }
                          }}
                        >
                          <AddIcon /> Agregar
                        </Button>
                      </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre Empleado</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {empleadosAdicionales.map((row, idx) => (
                            <TableRow key={"empleadosAdicional_" + idx}>
                              <TableCell component="th" scope="row">
                                {row.nombre + " " + row.apellidos}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    let existe = empleadosAdicionales.filter(
                                      (e) => e.rut !== row.rut
                                    );
                                    setEmpleadosAdicionales(existe);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {activeStep === 5 && (
                  <GraficoComisionesEmpleado
                    comisionTaxtic={comisionTaxtic}
                    handleChangeComisionTaxtic={handleChangeComisionTaxtic}
                    comisionVendedor={comisionVendedor}
                    vendidoTaxtic={vendidoTaxtic}
                    valorTarea={valorTarea}
                    handleChangeComisionVendedor={handleChangeComisionVendedor}
                    comisionEmpleado={comisionEmpleado}
                    handleChangeComisionEmpleado={handleChangeComisionEmpleado}
                    comisionSupervisor={comisionSupervisor}
                    handleChangeComisionSupervisor={
                      handleChangeComisionSupervisor
                    }
                    tipoMonedaTarea={tipoMonedaTarea}
                    chart={chart}
                  />
                )}

                {activeStep === 6 && (
                  <div style={{ overflow: "auto", height: "500px" }}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Antecedentes
                    </Typography>

                    {/* <FormLabel component="legend">No Obligatorio</FormLabel> */}

                    <TextField
                      style={{ marginTop: "30px" }}
                      label="Antecendentes tarea"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={descripcionTarea}
                      fullWidth
                      onChange={handleChangeDescripcionTarea}
                    />

                    <div style={{ marginTop: "30px" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={es}
                      >
                        <DatePicker
                          label="Fecha Limite Tarea *"
                          views={["year", "month", "day"]}
                          value={fechaLimiteTarea}
                          minDate={new Date()}
                          onChange={(newValue) => {
                            setFechaLimiteTarea(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={params?.inputProps?.placeholder}
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}

                {activeStep === 7 && (
                  <div style={{ overflow: "auto", height: "500px" }}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Total a Pagar
                    </Typography>

                    <TextField
                      style={{ marginTop: "30px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Descuento adicional"
                      variant="outlined"
                      value={descuentoAdicional}
                      fullWidth
                      type="number"
                      onChange={handleChangeDescuentoAdicional}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      label="Total a Pagar"
                      variant="outlined"
                      value={
                        tipoMonedaTarea === "PESOS"
                          ? formatNumber(totalAPagar)
                          : totalAPagar
                      }
                      fullWidth
                      disabled
                      type="text"
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Box>
        </DialogContent>

        {activeStep === steps.length ? (
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            {mostrarCircular ? (
              <CircularProgress />
            ) : (
              <div>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Volver
                </Button>
                <Button onClick={onSubmitTareaEspecial} color="primary">
                  Generar
                </Button>
              </div>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
              margin: "30px",
            }}
          >
            {activeStep === 0 ? (
              <Button color="inherit" onClick={cancelar} sx={{ mr: 1 }}>
                Cancelar
              </Button>
            ) : (
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Volver
              </Button>
            )}

            <Box sx={{ flex: "1 1 auto" }} />

            <Button disabled={noAvanza} onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
            </Button>
          </Box>
        )}
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}
      >
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}
        >
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
      ;
    </>
  );
}
