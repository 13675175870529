import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "src/utils/urlBack_End";

export const prorrateosApi = createApi({
  reducerPath: "prorrateosApi",
  baseQuery: baseQuery(`${API_SERVER}/prorrateos`),
  tagTypes: ["infoProrrateo"],

  endpoints: (builder) => ({
    obtenerInformacionProrrateo: builder.query({
      query: () => "/",
      providesTags: ["infoProrrateo"],
    }),
    obtenerEmpleadosProrrateo: builder.query({
      query: () => "/empleados",
    }),
    crearProrrateo: builder.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["infoProrrateo"],
    }),
    editarProrrateo: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["infoProrrateo"],
    }),
    obtenerSucursalesConProrrateo: builder.query({
      query: () => "/sucursales",
    }),
  }),
});

export const {
  useObtenerInformacionProrrateoQuery,
  useObtenerEmpleadosProrrateoQuery,
  useLazyObtenerEmpleadosProrrateoQuery,
  useLazyObtenerInformacionProrrateoQuery,
  useCrearProrrateoMutation,
  useEditarProrrateoMutation,
  useObtenerSucursalesConProrrateoQuery,
  useLazyObtenerSucursalesConProrrateoQuery,
} = prorrateosApi;
