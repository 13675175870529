import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  blur: {
    filter: "blur(4px)",
    pointerEvents: "none",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    zIndex: 1,
  },
  message: {
    textAlign: "center",
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const ComingSoonMinimal = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.blur}>{children}</Box>
      <Box className={classes.overlay}>
        <Box className={classes.message}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Próximamente
          </Typography>
          <Typography variant="body1">
            Esta función estará disponible en breve.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ComingSoonMinimal;
