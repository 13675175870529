import React, { useState } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
// material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import axios from "axios";
import { useSelector } from "react-redux";

export default function ModalContrato(props) {
  const {
    openDialog,
    setOpenDialog,
    estadoContratoSeleccionado,
    setPlanes,
    idPlanSeleccionado,
  } = props;
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido
  } = useSelector((state) => state.auth.user);
  const sucursal = sucursalObtenida.refSucursal;

  const [estado, setEstado] = useState(estadoContratoSeleccionado);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const guardarCambios = () => {
    axios
      .put(`${API_SERVER}/planes/actualizarContrato/${idPlanSeleccionado}`, {
        contratoFirmado: estado
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") {
          axios
            .get(`${API_SERVER}/planes/sucursal/${sucursal}`, {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
              }
            })
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              setPlanes(response.data);
              setOpenDialog(false);
            })
            .catch(function () {
              setOpenDialog(false);
            });
        } else {
          axios
            .get(`${API_SERVER}/planes/empleado/${sucursal}/${user.rut}`, {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
              }
            })
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              setPlanes(response.data);
              setOpenDialog(false);
            })
            .catch(function () {
              setOpenDialog(false);
            });
        }
      })
      .catch(function () {
        setOpenDialog(false);
      });
  };

  const handleChangeEstado = (event) => {
    setEstado(event.target.value);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">Cambiar Estado Plan</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup value={estado} onChange={handleChangeEstado}>
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancelar
          </Button>
          <Button onClick={guardarCambios} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
}
