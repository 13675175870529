import { makeStyles } from "@material-ui/styles";
import palette from "./palette";
// Función para definir los estilos del tablero Kanban
export const useStyles = makeStyles((theme) => ({
  kanbanColumn: {
    width: "270px",
    padding: "7px",
    margin: "7px",
    backgroundColor: "#f2f2f2",
    borderRadius: "5px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
  },
  container: {
    marginTop: "10px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(270px, 1fr))",
    gridGap: "7px",
  },
  columnHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    backgroundColor: "#ff802e",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  columnTypography: {
    cursor: "pointer",
  },
  subtituloTypography: {
    fontSize: "12px",
    color: "#777",
  },
  card: {
    padding: "7px",
    marginBottom: "7px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noTasks: {
    textAlign: "center",
    padding: "7px",
    color: "gray",
  },
}));

// Función para asignar un color de borde a las tareas del tablero, basado en el tipo de tarea
export const getBorderColor = (tipo) => {
  switch (tipo) {
    case "AÑO":
      return palette.primary["main"];
    case "INTERNA":
      return palette.error["main"];
    case "MES":
      return palette.warning["main"];
    case "ESPECIAL":
      return palette.info["main"];
    default:
      return "transparent";
  }
};
