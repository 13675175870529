import { useTheme } from "@material-ui/styles";
import { useResponsive } from "src/hooks/use-responsive";
import useChart from "../charts/use-chart";
import { Card, Divider, Skeleton, Stack, Typography } from "@material-ui/core";
import Chart from "../charts/chart";
import { formatAsCLP } from "src/utils/formatNumber";

const CHART_SIZE = {
  width: 106,
  height: 106,
};

export default function AppWidgetsFacturacionRecaudacionPendientes({
  chart,
  loading,
  ...other
}) {
  const theme = useTheme();

  const isDesktop = useResponsive("up", "sm");

  const { series, options } = chart;

  const chartOptionsCheckIn = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
    colors: [theme.palette.chart.blue[0]],
    ...options,
  });

  if (loading) {
    return (
      <Card {...other}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderStyle: "dashed" }}
            />
          }
        >
          {[1, 2, 3].map((_, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={3}
              sx={{ width: 1, py: 5 }}
            >
              <Skeleton variant="circular" width={90} height={90} />

              <div>
                <Typography variant="h4">
                  <Skeleton variant="text" width={100} />
                </Typography>

                <Typography variant="body2">
                  <Skeleton variant="text" width={80} />
                </Typography>
              </div>
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  const chartOptionsCheckOut = {
    ...chartOptionsCheckIn,
    colors: [theme.palette.chart.yellow[0]],
  };

  const recaudacionOptionsChart = {
    ...chartOptionsCheckIn,
    colors: [theme.palette.chart.red[0]],
  };

  return (
    <Card {...other}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        divider={
          <Divider
            orientation={isDesktop ? "vertical" : "horizontal"}
            flexItem
            sx={{ borderStyle: "dashed" }}
          />
        }
      >
        {series.map((item, index) => (
          <Stack
            key={item.label}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            sx={{ width: 1, py: 5 }}
          >
            <Chart
              type="radialBar"
              series={[item.percent]}
              options={
                index === 1
                  ? chartOptionsCheckOut
                  : index === 0
                  ? chartOptionsCheckIn
                  : recaudacionOptionsChart
              }
              {...CHART_SIZE}
            />

            <div>
              <Typography variant="h4" sx={{ mb: 0.5 }}>
                {formatAsCLP(item.total)}
              </Typography>

              <Typography variant="body2" sx={{ opacity: 0.72 }}>
                {item.label}
              </Typography>
            </div>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
