import { LoadingButton } from "@material-ui/lab";
import { memo } from "react";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";

const ExportarAExcel = memo(({ nombreBoton, loadingExport, onClick }) => {
  return (
    <LoadingButton
      type="submit"
      variant="contained"
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px" }}
      loading={loadingExport}
      disabled={loadingExport}
      onClick={onClick}
    >
      Exportar {nombreBoton}
    </LoadingButton>
  );
});

export default ExportarAExcel;
