import { format, formatDistanceToNow, parse } from "date-fns";
import { es } from "date-fns/locale";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import esDayjs from "dayjs/locale/es.js";
import handleError from "./manejo-errores";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(esDayjs);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("America/Santiago");

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy", {
    locale: es,
  });
}

export function fechaActual() {
  return dayjs();
}

export function convertirFechaAFormatoNumerico(fechaString) {
  try {
    // Parsear la fecha
    const fecha = parse(fechaString, "yyyy/MMMM", new Date(), { locale: es });

    // Formatear la fecha al formato deseado
    return format(fecha, "yyyy/MM");
  } catch (error) {
    handleError("convertirFechaAFormatoNumerico", error);
  }
}

export function fDateLong(date) {
  return format(new Date(date), "dd 'de' MMMM 'del' yyyy", {
    locale: es,
  });
}

export function fDateNumber(date) {
  return format(new Date(date), "dd/MM/yyyy", {
    locale: es,
  });
}

export function formatearFecha(fecha, formato) {
  try {
    // Verificar si la fecha es un objeto Date de JavaScript
    if (fecha instanceof Date) {
      fecha = dayjs(fecha); // Convertir la fecha a un objeto Dayjs
    }

    // Formatos que vienen de la BD
    const formatosPosibles = ["YYYY/MM/DD", "YYYY/MM", "DD/MM/YYYY"];

    let fechaDayjs;
    for (const formatoPosible of formatosPosibles) {
      fechaDayjs = dayjs(fecha, formatoPosible, true);
      if (fechaDayjs.isValid()) break;
    }

    // Si no es válida, lanzar un error
    if (!fechaDayjs.isValid()) {
      throw new Error("Fecha no válida");
    }

    // Formatear la fecha al formato deseado
    return fechaDayjs.format(formato);
  } catch (error) {
    handleError("formatearFecha", error);
  }
}

// Función para calcular los días de retraso de una factura
export function calcularDiasRetraso(
  fechaEmisionStr,
  formatoEntrada = "YYYY/MM/DD",
  sumarDias = 30
) {
  // Parsear la fecha de emisión de la factura
  const fechaEmision = dayjs(fechaEmisionStr, formatoEntrada);

  // Sumar 30 días a la fecha de emisión
  const fechaLimite = fechaEmision.add(sumarDias, "day");

  // Obtener la fecha actual
  const fechaActual = dayjs();

  // Calcular los días de retraso
  let diasRetraso = 0;
  if (fechaActual.isAfter(fechaLimite)) {
    diasRetraso = fechaActual.diff(fechaLimite, "day");
  }

  return diasRetraso;
}

export function ajustarFecha(
  fecha,
  cantidad,
  unidad,
  formatoEntrada = "YYYY/MM",
  formatoSalida = "YYYY/MM"
) {
  try {
    const fechaDayjs = dayjs(fecha, formatoEntrada);
    if (!fechaDayjs.isValid()) {
      throw new Error("Fecha no válida");
    }

    let fechaAjustada;
    if (cantidad >= 0) {
      fechaAjustada = fechaDayjs.add(cantidad, unidad);
    } else {
      fechaAjustada = fechaDayjs.subtract(Math.abs(cantidad), unidad);
    }

    return fechaAjustada.format(formatoSalida);
  } catch (error) {
    handleError("ajustarFecha", error);
  }
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm", {
    locale: es,
  });
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  try {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: es,
    });
  } catch (error) {
    console.error("Error in fToNow", error.message);
  }
}

// Función para calcular la distancia en días entre dos fechas
export const calcularDistanciaDias = (fechaInicioStr, fechaFinStr) => {
  // Parsear las fechas
  const fechaInicio = dayjs(fechaInicioStr, "YYYY/MM/DD");
  const fechaFin = dayjs(fechaFinStr, "YYYY/MM/DD");

  // Calcular la diferencia en días
  const distanciaDias = fechaFin.diff(fechaInicio, "day");

  return distanciaDias;
};

export const validarFormatoFecha = (value, formato) => {
  let formattedDate;
  let regex;

  if (formato === "DD/MM/YYYY") {
    formattedDate = dayjs(value).format("DD/MM/YYYY");
    regex = /^\d{2}\/\d{2}\/\d{4}$/;
  }
  if (formato === "MM/YYYY") {
    formattedDate = dayjs(value).format("MM/YYYY");
    regex = /^\d{2}\/\d{4}$/;
  }
  return regex.test(formattedDate);
};
