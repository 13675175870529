import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  Grid,
  Tooltip,
  Container,
  CardContent,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import eyeFill from "@iconify/icons-eva/eye-fill";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ModifyTaskStateModal from "../../_dashboard/tareas/ModifyTaskStateModal";
import Label from "../../Label";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../../Scrollbar";
import { TablaHead, TablaToolbar } from "../../_dashboard/tablas";
import EditarPermiso from "./EditarPermiso";
import MyConfirmationDialog from "../../MyConfirmationDialog";
import { API_SERVER } from "../../../utils/urlBack_End";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

const TABLE_HEAD = [
  { id: "motivo", label: "Motivo", alignRight: false },
  { id: "fechaInicio", label: "Fecha Inicio", alignRight: false },
  { id: "horaInicio", label: "Hora Inicio", alignRight: false },
  { id: "fechaTermino", label: "Fecha Término", alignRight: false },
  { id: "horaTermino", label: "Hora Término", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.motivo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.horaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaTermino.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.horaTermino.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const styles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
  icon: {
    fontSize: "28px",
  },
}));

export default function TablaPermisos(props) {
  const {
    permisos,
    setPermisos,
    permisosSolicitadas,
    setPermisosSolicitadas,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage,
    vista,
  } = props;

  const theme = useTheme();
  const [editar, setEditar] = useState(false);
  const [solicitar, setSolicitar] = useState(false);
  const [editingEmpleado, setEditingEmpleado] = useState(false);
  const [permiso, setPermiso] = useState("");
  const [permisoSolicitud, setPermisoSolicitud] = useState({
    fechaInicio: "",
    fechaTermino: "",
    horaInicio: "",
    horaTermino: "",
    refEmpleado: empleado.rut,
    urlArchivo: "",
    motivo: "",
    comentario: "",
    empleadoAcepta: "",
  });
  const [mostrarEstado, setMostrarEstado] = useState(false);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState(null);
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const [visualizaEmpleado, setVisualizaEmpleado] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { empleado: user } = useSelector((state) => state.auth.user);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");
  const classes = styles();

  const [puedeSolicitar, setPuedeSolicitar] = useState(false);

  useEffect(() => {
    function calculateTimeDifference(horaInicio, horaTermino) {
      const time1 = new Date("2023-05-18T" + horaInicio + ":00");
      const time2 = new Date("2023-05-18T" + horaTermino + ":00");

      const diffMs = time2 - time1;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      const diffFormatted = `${diffHours
        .toString()
        .padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}`;

      return diffFormatted;
    }

    let totalSum = 0;
    const anioActual = new Date().getFullYear();
    const permisosAux = permisos.filter(
      (permiso) =>
        new Date(permiso.fechaInicio).getFullYear() === anioActual &&
        (permiso.estado !== "RECHAZADO" || permisos.estado === "PENDIENTE")
    );

    for (let i = 0; i < permisosAux.length; i++) {
      const { horaInicio, horaTermino } = permisosAux[i];
      const difference = calculateTimeDifference(horaInicio, horaTermino);
      const [hours, minutes] = difference.split(":");
      const timeInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      totalSum += timeInMinutes;
    }
    const totalSumHours = Math.floor(totalSum / 60);

    setPuedeSolicitar(totalSumHours < 24);
  }, [permisos]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = permisos?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - permisos?.length) : 0;

  const filteredUsers = applySortFilter(
    permisos,
    getComparator(order, orderBy),
    filterName
  );

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  const editarPermiso = (row) => {
    //cambiar por user.rol === "ADMIN" || user.rol === "SUPER_ADMIN"
    if (vista !== "EMPLEADO") {
      setEditar(true);
      setPermiso(row);
    } else {
      if (row.estado === "PENDIENTE") {
        setSolicitar(true);
        setEditingEmpleado(true);
        setVisualizaEmpleado(false);
        setPermisoSolicitud(row);
      } else {
        setSolicitar(true);
        setEditingEmpleado(true);
        setVisualizaEmpleado(true);
        setPermisoSolicitud(row);
      }
    }
  };

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/empleados/permisos/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setPermisos(response.data);
      });
  };

  const cambiarEstado = (nuevoEstado) => {
    if (nuevoEstado !== "PENDIENTE") {
      const permission = {
        estado: nuevoEstado,
        id: estadoSeleccionado.id,
        comentario: estadoSeleccionado.comentario,
        refEmpleadoSolicita: estadoSeleccionado.refEmpleado,
        refEmpleadoAcepta: user.nombre + " " + user.apellidos,
      };
      axios
        .put(`${API_SERVER}/empleados/permisos`, permission, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setEstadoSeleccionado(null);
          actualizar();
          setShowMessagePermiso(true);
          setColor("success");
          setMessage("Permiso Actualizado Correctamente");
        });
    } else {
      setEstadoSeleccionado(null);
    }
  };

  const handleChangeState = (row) => {
    if (row.estado === "PENDIENTE" && vista !== "EMPLEADO") {
      setEstadoSeleccionado(row);
      setMostrarEstado(true);
    }
  };

  const handleDeletePermiso = (id) => {
    setShowRemovalConfirmation(true);
    setSelectedId(id);
  };

  const eliminarPermiso = () => {
    setPermisos((permisos) => permisos.filter((p) => p.id !== selectedId));

    axios
      .delete(`${API_SERVER}/empleados/permisos/${selectedId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setShowRemovalConfirmation(false);
        setShowMessagePermiso(true);
        setColor("success");
        setMessage("Permiso Eliminado Correctamente");
      });
  };

  const solicitarPermiso = () => {
    setPermisoSolicitud({
      fechaInicio: "",
      fechaTermino: "",
      horaInicio: "",
      horaTermino: "",
      refEmpleado: empleado.rut,
      urlArchivo: "",
      motivo: "",
      comentario: "",
      empleadoAcepta: "",
    });
    setSolicitar(true);
  };

  return (
    <div>
      <Card>
        <Typography variant="h5" component="h2" className={classes.title}>
          {vista === "EMPLEADO"
            ? "Solicitud de Permisos"
            : "Permisos del Empleado"}
        </Typography>
        <Grid
          container
          style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}>
          <Grid item xs md>
            <TablaToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>

          {vista === "EMPLEADO" && (
            <Grid item xs md>
              <Grid container direction="row-reverse">
                <Grid item xs={12} md={6}>
                  <Container style={{ marginLeft: ".5rem" }}>
                    <Button
                      variant="contained"
                      disabled={!puedeSolicitar}
                      onClick={() => solicitarPermiso()}
                      startIcon={<Icon icon={plusFill} />}
                      style={{ minWidth: "170px" }}>
                      Solicitar Permiso
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={5} md={5}>
            <Card
              color="primary"
              style={{
                backgroundColor: theme.palette.primary.main,
                marginLeft: 10,
              }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  color="white"
                  gutterBottom>
                  {permisosSolicitadas < 1
                    ? `No posee permisos aceptados`
                    : permisosSolicitadas === 1
                    ? `${permisosSolicitadas} Día solicitado y aceptado`
                    : `${permisosSolicitadas} Días solicitados y aceptados`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={5} md={5}></Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TablaHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={permisos?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      motivo,
                      fechaInicio,
                      horaInicio,
                      fechaTermino,
                      horaTermino,
                      estado,
                      urlArchivo,
                    } = row;
                    const isItemSelected = selected.indexOf(motivo) !== -1;

                    return (
                      <TableRow
                        hover
                        key={
                          motivo +
                          fechaInicio +
                          horaInicio +
                          fechaTermino +
                          horaTermino
                        }
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {motivo}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{fechaInicio}</TableCell>
                        <TableCell align="left">{horaInicio}</TableCell>
                        <TableCell align="left">{fechaTermino}</TableCell>
                        <TableCell align="left">{horaTermino}</TableCell>
                        <TableCell align="left">
                          <Label
                            onClick={() => handleChangeState(row)}
                            style={{
                              cursor:
                                vista !== "EMPLEADO" &&
                                row.estado === "PENDIENTE"
                                  ? "pointer"
                                  : false,
                            }}
                            variant="ghost"
                            color={
                              (estado === "RECHAZADO" && "error") ||
                              (estado === "PENDIENTE" && "warning") ||
                              (estado === "ACEPTADO" && "success") ||
                              "info"
                            }>
                            {sentenceCase(estado)}
                          </Label>
                        </TableCell>
                        <TableCell align="center">
                          {estado === "PENDIENTE" ? (
                            <Tooltip title="Editar">
                              <Button
                                color="primary"
                                onClick={() => {
                                  editarPermiso(row);
                                }}>
                                <EditIcon />
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Visualizar">
                              <Button
                                color="primary"
                                onClick={() => {
                                  editarPermiso(row);
                                }}>
                                <Icon icon={eyeFill} className={classes.icon} />
                              </Button>
                            </Tooltip>
                          )}

                          {urlArchivo !== "" &&
                            urlArchivo !== " " &&
                            urlArchivo && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  window.open(urlArchivo, "_blank");
                                }}>
                                Archivo
                              </Button>
                            )}

                          {vista === "EMPLEADO" && estado === "PENDIENTE" && (
                            <Tooltip title="Eliminar">
                              <Button
                                color="error"
                                onClick={() => handleDeletePermiso(row.id)}>
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No se han agregado elementos
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={permisos?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>
      {editar && (
        <EditarPermiso
          open={editar}
          setOpen={setEditar}
          editing={true}
          editingEmpleado={false}
          setEditingEmpleado={setEditingEmpleado}
          permiso={permiso}
          setPermiso={setPermiso}
          empleado={empleado}
          titulo={"Solicitud de Permiso del Empleado"}
          setShowMessagePermiso={setShowMessagePermiso}
          setColor={setColor}
          setMessage={setMessage}
          setPermisos={setPermisos}
          visualizaEmpleado={false}
          setVisualizaEmpleado={setVisualizaEmpleado}
          setPermisosSolicitadas={setPermisosSolicitadas}
          permisos={permisos}
        />
      )}

      {solicitar && (
        <EditarPermiso
          open={solicitar}
          setOpen={setSolicitar}
          editing={false}
          editingEmpleado={editingEmpleado}
          setEditingEmpleado={setEditingEmpleado}
          permiso={permisoSolicitud}
          setPermiso={setPermisoSolicitud}
          empleado={empleado}
          titulo={"Solicitud de Permiso"}
          setShowMessagePermiso={setShowMessagePermiso}
          setColor={setColor}
          setMessage={setMessage}
          setPermisos={setPermisos}
          visualizaEmpleado={visualizaEmpleado}
          setVisualizaEmpleado={setVisualizaEmpleado}
          setPermisosSolicitadas={setPermisosSolicitadas}
          permisos={permisos}
        />
      )}

      {estadoSeleccionado !== null && (
        <ModifyTaskStateModal
          task={estadoSeleccionado}
          onSubmit={cambiarEstado}
          onClose={() => setMostrarEstado(false)}
          states={["RECHAZADO", "PENDIENTE", "ACEPTADO"]}
          open={mostrarEstado}
          cargandoDatos={false}
        />
      )}

      <MyConfirmationDialog
        open={showRemovalConfirmation}
        onSubmit={() => eliminarPermiso()}
        onClose={() => setShowRemovalConfirmation(false)}
        title="Eliminar Permiso"
        content="¿Está seguro de eliminar este permiso?"
      />
    </div>
  );
}
