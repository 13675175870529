import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
// material
import {
  Container,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
// components
import Page from "../components/Page";
import FacturaPlanes from "src/components/facturas/facturaPlanes/FacturaPlanes";
import { Titulo } from "../components/Titulo";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import FacturasSinSucursal from "src/components/facturas/facturasSinSucursal";
import FacturarManual from "src/components/facturas/facturarManual";
import { facturasApi } from "src/services/facturasApi";
import { sucursalesApi } from "src/services/sucursalesApi";
import { tiposApi } from "src/services/tiposApi";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <label>{children}</label>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(label, index) {
  return {
    id: `${label}-${index}`,
    "aria-controls": `${label}-${index}`,
  };
}

export default function Facturas() {
  const [tabIndex, setTabIndex] = useState(0);
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const [
    obtenerSucursalesEmpleado,
    { data: sucursalesEmpleado = [], isFetching: realizandoPeticionSucursales },
  ] = sucursalesApi.useLazyGetSucursalesEmpleadosQuery();

  const [
    obtenerIndicadores,
    { data: monedas = [], isLoading: obteniendoMonedas },
  ] = tiposApi.useLazyGetIndicadoresQuery();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();
  const [
    obtenerFacturasSinSucursal,
    { data: facturasSinSucursal = [], isLoading: cargandoFacturasSinSucursal },
  ] = facturasApi.useLazyObtenerFacturasSinSucursalQuery();

  const verificarIndiceTab = useCallback(async () => {
    if (tabIndex === 1) {
      obtenerFacturasSinSucursal();
    } else if (tabIndex === 2) {
      await Promise.all([
        obtenerIndicadores(),
        obtenerSucursalesEmpleado(user.rut),
      ]);
    }
  }, [
    obtenerFacturasSinSucursal,
    obtenerIndicadores,
    obtenerSucursalesEmpleado,
    tabIndex,
    user.rut,
  ]);

  useEffect(() => {
    verificarIndiceTab();
  }, [verificarIndiceTab]);

  // TODO: Pasarlo a un hook personalizado y llamarlo en los componentes que se realice lo mismo
  const agregarHistorialAccionEmpleado = useCallback(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  useEffect(() => {
    agregarHistorialAccionEmpleado();
  }, [agregarHistorialAccionEmpleado]);

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Facturación
          </Typography>
        </Titulo>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="tabs-configuracion-liquidaciones"
          >
            <Tab label="Facturas" {...a11yProps("tab-facturas", 0)} />
            <Tab
              label="Facturas sin sucursal"
              {...a11yProps("tab-factura-sin-sucursal", 1)}
            />
            <Tab
              label="Facturar manual"
              {...a11yProps("tab-facturar-manual", 2)}
            />
          </Tabs>
        </AppBar>

        <FacturaPlanes tabIndex={tabIndex} />

        <FacturasSinSucursal
          tabIndex={tabIndex}
          data={facturasSinSucursal}
          isLoadingData={cargandoFacturasSinSucursal}
        />

        <FacturarManual
          sucursalObtenida={sucursalObtenida.refSucursal}
          rutEmpleado={user.rut}
          nombreEmpleado={`${user.nombre} ${user.apellidos}`}
          tabIndex={tabIndex}
          sucursales={sucursalesEmpleado}
          monedas={monedas}
          obteniendoMonedas={obteniendoMonedas}
          cargandoSucursales={realizandoPeticionSucursales}
        />

        {/* <Box sx={{ width: "100%" }}>
          <FacturaPlanes />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Facturas Planes" {...a11yProps(0)} />
              <Tab label="Facturas Tareas" {...a11yProps(1)} />
              <Tab label="Facturas Afectas" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FacturaTareas tipoMonedasObtenidas={tipoMonedasObtenidas} />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <FacturaAfecta />
          </TabPanel>
        </Box> */}
      </Container>
    </Page>
  );
}
