import { Card, Skeleton, Typography, alpha } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import Iconify from "../iconify";
import { bgGradient } from "src/utils/cssStyles";
import { fNumber, formatAsCLP } from "src/utils/formatNumber";

AnalyticsWidgetSummarySkeleton.propTypes = {
  color: PropTypes.string,
  skeletonOpacity: PropTypes.number,
  sx: PropTypes.object,
};

function AnalyticsWidgetSummarySkeleton({
  color = "primary",
  skeletonOpacity = 0.3,
  sx,
  ...other
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: "center",
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        height: 1,
        ...sx,
      }}
      {...other}
    >
      <Skeleton
        variant="circular"
        width={64}
        height={64}
        sx={{
          mb: 3,
          mx: "auto",
          backgroundColor: alpha(theme.palette[color].dark, skeletonOpacity),
        }}
      />

      <Skeleton
        variant="rectangular"
        width={100}
        height={40}
        sx={{
          mb: 1,
          mx: "auto",
          backgroundColor: alpha(theme.palette[color].dark, skeletonOpacity),
        }}
      />

      <Skeleton
        variant="rectangular"
        width={160}
        height={20}
        sx={{
          mx: "auto",
          backgroundColor: alpha(theme.palette[color].dark, skeletonOpacity),
        }}
      />
    </Card>
  );
}

AnalyticsWidgetSummary.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.object,
  color: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  total: PropTypes.number,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
};

export default function AnalyticsWidgetSummary({
  title,
  total,
  icon,
  color = "primary",
  type = "numero",
  loading = false,
  cursor = "default",
  onClick = undefined,
  sx,
  ...other
}) {
  const theme = useTheme();

  if (loading) {
    return <AnalyticsWidgetSummarySkeleton color={color} sx={sx} {...other} />;
  }

  return (
    <Card
      onClick={onClick}
      sx={{
        py: 5,
        boxShadow: 0,
        height: 1,
        textAlign: "center",
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        cursor: cursor,
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          mb: 3,
          p: 2.5,
          width: 64,
          height: 64,
          borderRadius: "50%",
          color: theme.palette[color].dark,
          ...bgGradient({
            direction: "135deg",
            startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
            endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`,
          }),
        }}
      />

      <Typography variant="h3">
        {type === "numero" ? fNumber(total) : formatAsCLP(total)}
      </Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.64, ml: 0.6, mr: 0.6 }}>
        {title}
      </Typography>
    </Card>
  );
}
