import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Button,
  Grid,
  Stack,
  CardHeader,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { API_SERVER, dbx } from "src/utils/urlBack_End";
import { LoadingButton } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import AgregarPortafolio from "./AgregarPortafolio";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import { formatoFecha } from "../../utils/formatNumber";
import { useSelector } from "react-redux";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
  },
  pos: {
    marginBottom: 12,
  },
  hidden: {
    display: "none",
  },
}));

export default function Portafolio(props) {
  const { value, cliente } = props;
  const classes = styles();
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [agregarPortafolio, setAgregarPortafolio] = useState(false);

  const [portafolios, setPortafolios] = useState([]);
  const [portafolioSeleccionado, setPortafolioSeleccionado] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const [archivo, setArchivo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [loadingEliminar, setLoadingEliminar] = useState(false);

  let fechaActual = formatoFecha();

  const handleChange = (panel, portafolio) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPortafolioSeleccionado(isExpanded ? portafolio : null);
  };

  function capitalizarPrimeraLetra(str) {
    str = str.toLowerCase();
    let resultado = str.split(" ");

    let cadena = "";
    for (let index = 0; index < resultado.length; index++) {
      const element = resultado[index];
      cadena += element.charAt(0).toUpperCase() + element.slice(1) + " ";
    }
    return cadena;
  }

  function modificacionFecha(str) {
    let mes = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    let resultado = str.split("/");
    return (
      resultado[2] +
      " de " +
      mes[Number(resultado[1]) - 1] +
      " de " +
      resultado[0]
    );
  }

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    setArchivo(fileAux);
  };

  const handleDeleteFile = () => {
    setArchivo(null);
  };

  useEffect(() => {
    dbx
      .filesListFolder({ path: `/Clientes/${cliente.rut}/Portafolio` })
      .then(() => {})
      .catch(() => {
        dbx
          .filesCreateFolderV2({ path: `/Clientes/${cliente.rut}/Portafolio` })
          .then((response) => {})
          .catch((error) => {});
      })
      .finally(() => {});
  }, [cliente.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/portafolio/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data === -2) window.location.reload();

        if (Array.isArray(res.data)) {
          setPortafolios(res.data);
        }
      })
      .catch(() => {});
  }, [cliente.rut]);

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/clientes/portafolio/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data === -2) window.location.reload();

        if (Array.isArray(res.data)) {
          setPortafolios(res.data);
        }
      })
      .catch(() => {});
  };

  async function uploadFile() {
    setLoading(true);
    if (!archivo) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (archivo.size >= UPLOAD_FILE_SIZE_LIMIT) {
      setLoading(false);
      return;
    }

    if (!portafolioSeleccionado) {
      setLoading(false);
      return;
    }

    try {
      await dbx.filesUpload({
        path: `/Clientes/${cliente.rut}/Portafolio/` + archivo.name,
        contents: archivo,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: `/Clientes/${cliente.rut}/Portafolio/` + archivo.name,
        });
        let url = shareResponse.result.url;

        if (portafolioSeleccionado && archivo) {
          axios
            .post(
              `${API_SERVER}/clientes/portafolio/archivos`,
              {
                refPortafolioCliente: portafolioSeleccionado.id,
                urlArchivo: url,
                nombreArchivo: archivo.name,
                empleadoIngresaPortafolio: user.nombre + " " + user.apellidos,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
            });
        }
      } catch (ex) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;
        if (portafolioSeleccionado && archivo) {
          axios
            .post(
              `${API_SERVER}/clientes/portafolio/archivos`,
              {
                refPortafolioCliente: portafolioSeleccionado.id,
                urlArchivo: url,
                nombreArchivo: archivo.name,
                empleadoIngresaPortafolio: user.nombre + " " + user.apellidos,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
            });
        }
      } finally {
        actualizar();
        setLoading(false);
      }
    } catch (ex) {
    } finally {
      actualizar();
      setArchivo(null);
      setLoading(false);
    }
  }

  async function deleteFile(archivo) {
    setLoadingEliminar(true);
    try {
      await dbx.filesDeleteV2({
        path: `/Clientes/${cliente.rut}/Portafolio/` + archivo.nombreArchivo,
      });

      axios
        .delete(
          `${API_SERVER}/clientes/portafolio/archivos/archivo.id/${
            archivo.refPortafolioCliente
          }/${user.nombre + " " + user.apellidos}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          actualizar();
        });
    } catch {
    } finally {
      actualizar();
      setLoadingEliminar(false);
    }
  }

  return (
    <div className={classes.pos}>
      {value === 3 && (
        <Card className={classes.root} variant="outlined">
          <CardHeader>
            <Typography variant="h5" component="h2" className={classes.title}>
              Portafolio
            </Typography>
          </CardHeader>

          <CardContent>
            <Button
              variant="contained"
              style={{ margin: "10px" }}
              onClick={() => {
                setAgregarPortafolio(true);
              }}>
              Agregar
            </Button>
            <Stack>
              <Grid container>
                <Grid item xs={12}>
                  {portafolios &&
                    portafolios
                      .sort((a, b) => {
                        if (a.fecha < b.fecha) {
                          return 1;
                        }
                        if (a.fecha > b.fecha) {
                          return -1;
                        }
                        // a must be equal to b
                        return 0;
                      })
                      .map((e, index) => {
                        const historialPortafoliosAUX = e.historialPortafolios;
                        const archivosPortafolioAUX = e.archivos;
                        let ultimaModificacion = historialPortafoliosAUX.filter(
                          (element) => element.refPortafolioCliente === e.id
                        );
                        ultimaModificacion = ultimaModificacion.sort((a, b) => {
                          if (a.hora < b.hora) {
                            return 1;
                          }
                          if (a.hora > b.hora) {
                            return -1;
                          }
                          // a must be equal to b
                          return 0;
                        });

                        let archivos = archivosPortafolioAUX.filter(
                          (element) => element.refPortafolioCliente === e.id
                        );

                        return (
                          <Accordion
                            key={"portafolios_" + index}
                            expanded={expanded === "panel" + index}
                            onChange={handleChange("panel" + index, e)}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header">
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                <strong>{modificacionFecha(e.fecha)}</strong>
                              </Typography>
                              {ultimaModificacion &&
                                ultimaModificacion[0].hora && (
                                  <Typography
                                    sx={{
                                      color: "text.secondary",
                                      width: "62%",
                                    }}>
                                    <strong>Última modificación: </strong>{" "}
                                    {ultimaModificacion[0].hora} por{" "}
                                    {capitalizarPrimeraLetra(
                                      String(ultimaModificacion[0].empleado)
                                    )}
                                  </Typography>
                                )}
                              {fechaActual === e.fecha && (
                                <IconButton
                                  sx={{ width: "5%" }}
                                  onClick={() => {
                                    setPortafolioSeleccionado(e);
                                    setAgregarPortafolio(true);
                                  }}>
                                  <EditIcon />
                                </IconButton>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Paper elevation={3}>
                                <Grid container>
                                  <Grid item style={{ margin: "10px" }}>
                                    {e.descripcion}
                                  </Grid>
                                </Grid>
                              </Paper>

                              <Paper
                                elevation={3}
                                style={{ marginTop: "10px" }}>
                                {fechaActual === e.fecha && (
                                  <Grid container style={{ marginTop: "10px" }}>
                                    <Grid item xs={4} md={4}>
                                      <input
                                        className={classes.hidden}
                                        id="contained-button-file"
                                        onChange={handleFileInput}
                                        type="file"
                                      />

                                      <label htmlFor={"contained-button-file"}>
                                        <Button
                                          fullWidth
                                          component="span"
                                          m={1}
                                          variant="contained">
                                          Seleccionar Archivo
                                        </Button>
                                      </label>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                      {archivo && (
                                        <span>
                                          <span>
                                            <> {archivo.name}</>
                                          </span>

                                          <span>
                                            <IconButton
                                              onClick={handleDeleteFile}
                                              aria-label="delete">
                                              <DeleteIcon />
                                            </IconButton>
                                          </span>
                                        </span>
                                      )}
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                      <LoadingButton
                                        fullWidth
                                        loading={loading}
                                        onClick={uploadFile}
                                        variant="contained">
                                        Subir
                                      </LoadingButton>
                                    </Grid>
                                  </Grid>
                                )}

                                {archivos &&
                                  archivos.length >= 1 &&
                                  archivos.map((archivo) => {
                                    return (
                                      <Grid container>
                                        <Grid item xs={12} md={12}>
                                          <TableContainer component={Paper}>
                                            <Table>
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell>
                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        xs={
                                                          fechaActual ===
                                                          e.fecha
                                                            ? 10
                                                            : 12
                                                        }
                                                        md={
                                                          fechaActual ===
                                                          e.fecha
                                                            ? 10
                                                            : 12
                                                        }>
                                                        <Button
                                                          onClick={() => {
                                                            window.open(
                                                              archivo.urlArchivo,
                                                              "_blank"
                                                            );
                                                          }}>
                                                          {
                                                            archivo.nombreArchivo
                                                          }
                                                        </Button>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={
                                                          fechaActual ===
                                                            e.fecha && 2
                                                        }
                                                        md={
                                                          fechaActual ===
                                                            e.fecha && 2
                                                        }>
                                                        <LoadingButton
                                                          loading={
                                                            loadingEliminar
                                                          }
                                                          onClick={() => {
                                                            deleteFile(archivo);
                                                          }}>
                                                          <DeleteIcon />
                                                        </LoadingButton>
                                                      </Grid>
                                                    </Grid>
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Paper>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      )}
      {agregarPortafolio && (
        <AgregarPortafolio
          open={agregarPortafolio}
          setOpen={setAgregarPortafolio}
          cliente={cliente}
          portafolio={portafolioSeleccionado}
          setPortafolios={setPortafolios}
        />
      )}
    </div>
  );
}
