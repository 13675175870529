import React, { useState } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  Grid,
  Tooltip,
} from "@material-ui/core";

import DownloadIcon from "@material-ui/icons/Download";

import { filter } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../../Scrollbar";
import { TablaHead, TablaToolbar } from "../../_dashboard/tablas";
import moment from "moment";

const TABLE_HEAD = [
  { id: "nombreArchivo", label: "Nombre", alignRight: false },
  { id: "fecha", label: "Fecha", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.nombreArchivo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const styles = makeStyles(() => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
  icon: {
    fontSize: "28px",
  },
}));

export default function TablaContratoSeleccionado(props) {
  const {
    contratos,
  } = props;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombreArchivo");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");
  const classes = styles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contratos?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contratos?.length) : 0;

  const filteredUsers = applySortFilter(
    contratos,
    getComparator(order, orderBy),
    filterName
  );

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <div>
      <Card>
        <Typography variant="h5" component="h2" className={classes.title}>
          Contratos
        </Typography>
        <Grid
          container
          style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs md>
            <TablaToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TablaHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={contratos?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { nombreArchivo, fecha, urlArchivo } = row;
                    const isItemSelected =
                      selected.indexOf(nombreArchivo) !== -1;

                    return (
                      <TableRow
                        hover
                        key={nombreArchivo + fecha + urlArchivo}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography variant="subtitle2" noWrap>
                            {nombreArchivo}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {
                            fecha === "" ? (fecha) :
                              moment(fecha, "YYYY/MM/DD").format("DD/MM/YYYY")
                          }
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Descargar">
                            <Button
                              color="primary"
                              onClick={() => {
                                window.open(urlArchivo, "_blank");
                              }}
                            >
                              <DownloadIcon /> Descargar
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No se han agregado elementos
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={contratos?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
}
