import {
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from "@material-ui/core";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";

const renderSkeletons = () => (
  <>
    <Grid container justifyContent="center" alignItems="center">
      <Skeleton variant="text" width={200} height={32} />
    </Grid>
    <Grid
      container
      spacing={2}
      marginTop={2}
      justifyContent="center"
      alignItems="center"
    >
      {[1, 2, 3, 4].map((index) => (
        <Grid item xs={3} key={index}>
          <Grid container justifyContent="center" alignItems="center">
            <Skeleton variant="rect" width="80%" height={46} />{" "}
          </Grid>
        </Grid>
      ))}
    </Grid>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "20px" }}
    >
      <Skeleton
        variant="rect"
        style={{ borderRadius: 50 }}
        width={650}
        height={300}
      />
    </Grid>
  </>
);

const GraficoComisionesEmpleado = memo(
  ({
    comisionTaxtic,
    handleChangeComisionTaxtic,
    comisionVendedor,
    vendidoTaxtic,
    valorTarea,
    handleChangeComisionVendedor,
    comisionEmpleado,
    handleChangeComisionEmpleado,
    comisionSupervisor,
    handleChangeComisionSupervisor,
    tipoMonedaTarea,
    chart,
    cargando = false,
    datosNuevos = true,
    finalizado = false,
  }) => {
    return (
      <Grid container spacing={2} marginTop={2}>
        {cargando ? (
          renderSkeletons()
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                {!datosNuevos
                  ? "Participaciones (necesario actualizar)"
                  : "Participaciones"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Participación Taxtic"
                    value={comisionTaxtic}
                    disabled={true}
                    onChange={handleChangeComisionTaxtic}
                    type="text"
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        pattern: "^[0-9]*",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Participación Vendedor"
                    value={comisionVendedor}
                    disabled={vendidoTaxtic || valorTarea === 0 || finalizado}
                    onChange={handleChangeComisionVendedor}
                    type="text"
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        pattern: "^[0-9]*",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Participación Empleado"
                    value={comisionEmpleado}
                    disabled={valorTarea === 0 || finalizado}
                    onChange={handleChangeComisionEmpleado}
                    type="text"
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        pattern: "^[0-9]*",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Participación Supervisor"
                    value={comisionSupervisor}
                    disabled={valorTarea === 0 || finalizado}
                    onChange={handleChangeComisionSupervisor}
                    type="text"
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        pattern: "^[0-9]*",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                Detalle Participaciones
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {"Valor Tarea: " + valorTarea + " " + tipoMonedaTarea}
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <ReactApexChart
                key={Math.random()}
                width={650}
                options={chart.options}
                series={chart.series}
                type="polarArea"
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);

export default GraficoComisionesEmpleado;
