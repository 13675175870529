import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog({
  open,
  setOpen,
  value,
  title,
  content,
  label,
  type,
  setUpdate,
  onSubmit,
  error = false,
  helperText = "",
  ...rest
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setUpdate(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <TextField
          onChange={onChange}
          defaultValue={value}
          autoFocus
          margin="dense"
          id="id"
          label={label}
          type={type}
          fullWidth
          variant="standard"
          error={error}
          helperText={error && helperText}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
