import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tooltip,
  Typography,
  createTheme,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import CustomTablePagination from "src/components/CustomTablePagination";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import * as locales from "@material-ui/core/locale";
import { fNumber, formatAsCLP } from "src/utils/formatNumber";
import { fToNow } from "src/utils/formatTime";
import { calcularDiasEntreFechas } from "src/utils/converter";
import { useNavigate } from "react-router-dom";
import { Leaderboard, Visibility } from "@material-ui/icons";
import ModalDetalleFacturaTareas from "../modalDetalleFacturaTareas";
import { informesApi } from "src/services/informesApi";

const ModalTablaHistoricoTareas = ({
  isOpen,
  setOpen,
  titulo,
  loading,
  rows,
}) => {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const [nombreTarea, setNombreTarea] = useState("");
  const [abrirModalEstadisticasTarea, setAbrirModalEstadisticasTarea] =
    useState(false);

  const [
    obtenerDetalleComisiones,
    {
      isFetching: cargandoDatosComisionesFactura,
      data: datosDetalleComisiones = {},
      error: errorDetalleComisiones,
    },
  ] = informesApi.useLazyGetDetalleComisionesTareaQuery();
  const [
    obtenerDetalleFacturasTarea,
    {
      isFetching: cargandoDetalleFacturasTarea,
      data: datosDetalleFacturas = {},
    },
  ] = informesApi.useLazyGetDetalleFacturasTareaQuery();

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(rowsFiltradas.length / newRowsPerPage);
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const rowsFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["nombre", "tipoTarea"];
    return applySortFilter(
      rows,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [rows, statesTable.filterName, statesTable.order, statesTable.orderBy]);

  const seEncontroElemento = rowsFiltradas.length === 0;

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            rows.length
        )
      : 0;

  const handleClickWatchTask = (id) => {
    navigate("/dashboard/tareas/" + id);
  };

  const manejarModalEstadisticasTarea = useCallback(
    (idTarea, nombreTarea) => {
      setNombreTarea(nombreTarea);
      obtenerDetalleComisiones(idTarea);
      obtenerDetalleFacturasTarea(idTarea);
      setAbrirModalEstadisticasTarea(true);
    },
    [obtenerDetalleComisiones, obtenerDetalleFacturasTarea]
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-tabla-historico-tareas"
        id="dialog-tabla-historico-tareas"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          style={styles.title}
          htmlFor="dialog-tabla-historico-tareas"
        >
          {titulo ? titulo : "Detalle tareas"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}></Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <TablaToolbar
                filterName={statesTable.filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer component={Paper}>
              {!loading ? (
                <Table>
                  <TablaHead
                    order={statesTable.order}
                    orderBy={statesTable.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={rows.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rowsFiltradas
                      .slice(
                        statesTable.currentPage *
                          statesTable.rowsPerPageOptions,
                        statesTable.currentPage *
                          statesTable.rowsPerPageOptions +
                          statesTable.rowsPerPageOptions
                      )
                      .map((row, index) => (
                        <TableRow hover key={row.id}>
                          <TableCell>
                            {statesTable.currentPage *
                              statesTable.rowsPerPageOptions +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {capitalizarPrimeraLetra(row.nombre)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {capitalizarPrimeraLetra(row.estado)}
                          </TableCell>
                          <TableCell>
                            {capitalizarPrimeraLetra(row.tipoTarea)}
                          </TableCell>
                          <TableCell>
                            {row.valorTarea !== 0
                              ? (() => {
                                  const { valorTarea, tipoMonedaTarea } = row;
                                  if (tipoMonedaTarea === "PESOS") {
                                    return formatAsCLP(valorTarea);
                                  } else if (tipoMonedaTarea === "DOLAR") {
                                    const textoDolar =
                                      valorTarea < 10 ? "dólar" : "dólares";
                                    return `${fNumber(
                                      valorTarea
                                    )} ${textoDolar}`;
                                  } else {
                                    return `${fNumber(
                                      valorTarea
                                    )}${tipoMonedaTarea}`;
                                  }
                                })()
                              : row.valorTarea}
                          </TableCell>
                          <TableCell>{row.fechaInicio}</TableCell>
                          <TableCell>{fToNow(row.fechaInicio)}</TableCell>
                          <TableCell>
                            {calcularDiasEntreFechas(
                              row.fechaInicio,
                              row.fechaTermino
                            ) !== null
                              ? `${calcularDiasEntreFechas(
                                  row.fechaInicio,
                                  row.fechaTermino
                                )} días`
                              : ""}
                          </TableCell>
                          <TableCell>
                            {capitalizarPalabras(row.cliente.nombre)}
                          </TableCell>
                          <TableCell>
                            <Grid
                              container
                              justifyContent="right"
                              spacing={2}
                              paddingRight={1}
                            >
                              <Grid
                                item
                                xs={12}
                                md={row.tipoTarea === "ESPECIAL" ? 6 : 12}
                                lg={row.tipoTarea === "ESPECIAL" ? 6 : 12}
                              >
                                {row.tipoTarea === "ESPECIAL" &&
                                  row.estado !== "CANCELADO O RECHAZADO" && (
                                    <Tooltip title="Estadísticas tarea especial">
                                      <IconButton
                                        color="secondary"
                                        onClick={() =>
                                          manejarModalEstadisticasTarea(
                                            row.id,
                                            row.nombre
                                          )
                                        }
                                        aria-label="watch-statistics"
                                        size="small"
                                      >
                                        <Leaderboard />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={row.tipoTarea === "ESPECIAL" ? 6 : 12}
                                lg={row.tipoTarea === "ESPECIAL" ? 6 : 12}
                              >
                                <Tooltip title="Ver tarea">
                                  <IconButton
                                    color="primary"
                                    onClick={() => handleClickWatchTask(row.id)}
                                    aria-label="watch-task"
                                    size="small"
                                  >
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}

                    {filasVacias > 0 && (
                      <TableRow style={{ height: 53 * filasVacias }}>
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                  {seEncontroElemento && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound
                            searchQuery={statesTable.filterName}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow>
                      <ThemeProvider
                        theme={(outerTheme) =>
                          createTheme(outerTheme, locales[statesTable.locale])
                        }
                      >
                        <CustomTablePagination
                          count={rowsFiltradas.length}
                          rowsPerPage={statesTable.rowsPerPageOptions}
                          page={statesTable.currentPage}
                          colSpan={10}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </ThemeProvider>
                    </TableRow>
                  </TableFooter>
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {abrirModalEstadisticasTarea && (
        <ModalDetalleFacturaTareas
          isOpen={abrirModalEstadisticasTarea}
          setOpen={setAbrirModalEstadisticasTarea}
          dataDivisiones={datosDetalleComisiones}
          cargandoDivisiones={cargandoDatosComisionesFactura}
          errorDivisiones={errorDetalleComisiones}
          nombreTarea={nombreTarea}
          cargandoFacturado={cargandoDetalleFacturasTarea}
          dataFacturado={datosDetalleFacturas}
        />
      )}
    </>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "desc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "nombre", label: "Nombre tarea", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "tipoTarea", label: "Tipo", alignRight: false },
  { id: "valorTarea", label: "Valor", alignRight: false },
  { id: "fechaInicio", label: "Fecha inicio", alignRight: false },
  { id: "creada", label: "Creada", alignRight: false },
  { id: "finalizado", label: "Finalizada en", alignRight: false },
  { id: "nombreCliente", label: "Cliente", alignRight: false },
  { id: "" },
];

ModalTablaHistoricoTareas.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  titulo: PropTypes.string,
  loading: PropTypes.bool,
};

export default ModalTablaHistoricoTareas;
