import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardHeader,
  Box,
  Skeleton,
} from "@material-ui/core";

const FileListSkeleton = ({ numberOfItems = 3 }) => {
  return (
    <Card sx={{ marginTop: 2 }}>
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={20}
            width="40%"
            style={{ marginBottom: 6 }}
          />
        }
      />
      <List>
        {[...Array(numberOfItems)].map((_, idx) => (
          <ListItem key={idx}>
            <ListItemIcon>
              <Skeleton
                animation="wave"
                variant="circular"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary={<Skeleton animation="wave" height={20} width="60%" />}
            />
            <ListItemSecondaryAction>
              <Box display="flex" gap={1}>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={32}
                  height={32}
                />
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={32}
                  height={32}
                />
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default FileListSkeleton;
