import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import Scrollbar from "../Scrollbar";
import {
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  createTheme,
} from "@material-ui/core";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import SearchNotFound from "../SearchNotFound";
import { ThemeProvider } from "@material-ui/styles";
import * as locales from "@material-ui/core/locale";
import CustomTablePagination from "../CustomTablePagination";

const TablaDinamica = ({
  rows,
  loadingRows,
  tableHead,
  initialStateTable,
  propertiesToFilter,
  renderTableCellContent,
}) => {
  const [statesTable, setStateTable] = useState(initialStateTable);

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );

    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };
  const rowsFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = propertiesToFilter;
    return applySortFilter(
      rows,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [
    propertiesToFilter,
    rows,
    statesTable.filterName,
    statesTable.order,
    statesTable.orderBy,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(rowsFiltradas.length / newRowsPerPage);
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const seEncontroElemento = rowsFiltradas.length === 0;

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            rows.length
        )
      : 0;

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <TablaToolbar
            filterName={statesTable.filterName}
            onFilterName={handleFilterByName}
          />
        </Grid>
      </Grid>
      <Scrollbar>
        <TableContainer component={Paper}>
          {!loadingRows ? (
            <Table size="small">
              <TablaHead
                order={statesTable.order}
                orderBy={statesTable.orderBy}
                headLabel={tableHead}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rowsFiltradas
                  .slice(
                    statesTable.currentPage * statesTable.rowsPerPageOptions,
                    statesTable.currentPage * statesTable.rowsPerPageOptions +
                      statesTable.rowsPerPageOptions
                  )
                  .map((row, index) => (
                    <TableRow hover key={index}>
                      {tableHead.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.alignRight ? "right" : "left"}
                        >
                          {renderTableCellContent(
                            column,
                            row,
                            index,
                            statesTable
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                {filasVacias > 0 && (
                  <TableRow style={{ height: 53 * filasVacias }}>
                    <TableCell colSpan={tableHead.length} />
                  </TableRow>
                )}
              </TableBody>
              {seEncontroElemento && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={tableHead.length}
                      sx={{ py: 3 }}
                    >
                      <SearchNotFound searchQuery={statesTable.filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <ThemeProvider
                    theme={(outerTheme) =>
                      createTheme(outerTheme, locales[statesTable.locale])
                    }
                  >
                    <CustomTablePagination
                      count={rowsFiltradas.length}
                      rowsPerPage={statesTable.rowsPerPageOptions ? statesTable.rowsPerPageOptions : 0}
                      page={
                        !rowsFiltradas.length || rowsFiltradas.length <= 0
                          ? 0
                          : statesTable.currentPage
                      }
                      colSpan={tableHead.length}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </ThemeProvider>
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </TableContainer>
      </Scrollbar>
    </>
  );
};

TablaDinamica.propTypes = {
  rows: PropTypes.array,
  loadingRows: PropTypes.bool,
  initialStateTable: PropTypes.object,
  tableHead: PropTypes.array,
  propertiesToFilter: PropTypes.array,
  renderTableCellContent: PropTypes.func,
};

export default TablaDinamica;
