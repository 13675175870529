import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";

import Page from "../components/Page";
import { Titulo } from "../components/Titulo";
import Laboral from "../components/gestionEmpleado/gestionLaboral/Laboral";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

export default function AreaLaboral() {
  const { empleado: user } = useSelector((state) => state.auth.user);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [user.rut]);

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Área Laboral
          </Typography>
        </Titulo>
        <Laboral value={6} empleado={user} vista={"EMPLEADO"} />
      </Container>
    </Page>
  );
}
