import React, { useEffect, useState } from "react";
import { Card, Container, Stack, styled } from "@material-ui/core";

import { tareasApi } from "src/services/tareasApi";
import GraficoComisionesEmpleado from "../graficos/GraficoComisionesEmpleado";
import { LoadingButton } from "@material-ui/lab";
import { AlertaToast } from "../AlertaSwall";

const calcularParticipacion = (valor, porcentaje) => {
  return valor * (porcentaje / 100);
};

const validarString = (valor) => {
  return valor === null || valor === undefined || valor === "";
};

// Función para convertir la cadena a formato deseado
const formatearNombre = (nombre) => {
  // Dividir la cadena en palabras
  const palabras = nombre.split(" ");

  // Convertir la primera letra de cada palabra a mayúscula y el resto a minúscula
  const palabrasFormateadas = palabras.map(
    (palabra) =>
      palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
  );

  // Unir las palabras formateadas en una nueva cadena
  const nombreFormateado = palabrasFormateadas.join(" ");

  return nombreFormateado;
};

const encontrarComisionPorTipoCargo = (comisiones, tipoCargo) => {
  const comision = comisiones.find(
    (comision) => comision.tipoCargo === tipoCargo
  );
  return comision;
};

export default function Comisiones({
  tarea,
  setShowAlert,
  setColor,
  setMessage,
}) {
  const [
    obtenerComisiones,
    { data: comisiones = [], isLoading: cargandoComisiones },
  ] = tareasApi.useLazyObtenerComisionesTareaQuery();
  const [editarComisionesTarea] = tareasApi.useEditarComisionesTareaMutation();

  const [comisionVendedor, setComisionVendedor] = useState(0);
  const [comisionTaxtic, setComisionTaxtic] = useState(0);
  const [comisionEmpleado, setComisionEmpleado] = useState(0);
  const [comisionSupervisor, setComisionSupervisor] = useState(0);
  const [porcentajeUtilidad, setPorcentajeUtilidad] = useState(0);

  const [participacionTaxtic, setParticipacionTaxtic] = useState(0);
  const [participacionVendedor, setParticipacionVendedor] = useState(0);
  const [participacionEmpleado, setParticipacionEmpleado] = useState(0);
  const [participacionSupervisor, setParticipacionSupervisor] = useState(0);
  const [utilidad, setUtilidad] = useState(0);

  const [nombreVendedor, setNombreVendedor] = useState("");
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  const [nombreSupervisor, setNombreSupervisor] = useState("");

  const [idComisionTaxtic, setIdComisionTaxtic] = useState(0);
  const [idComisionVendedor, setIdComisionVendedor] = useState(0);
  const [idComisionEmpleado, setIdComisionEmpleado] = useState(0);
  const [idComisionSupervisor, setIdComisionSupervisor] = useState(0);
  const [idUtilidad, setIdUtilidad] = useState(0);

  // Creado para corregir datos antiguos guardados en base de datos.
  const [datosNuevos, setDatosNuevos] = useState(true);

  const [participacionesInformacion, setParticipacionesInformacion] = useState(
    []
  );
  const [labelParticipaciones, setLabelParticipaciones] = useState([]);
  const [acumuladoPorcentaje, setAcumuladoPorcentaje] =
    useState(comisionTaxtic);

  const [guardandoDatos, setGuardandoDatos] = useState(false);
  const [hayErrores, setHayErrores] = useState(false);

  const finalizado =
    tarea.estado === "PENDIENTE DE FACTURACION" ||
    tarea.estado === "TERMINADA" ||
    tarea.estado === "FACTURADO" ||
    tarea.valorTarea === 0;

  useEffect(() => {
    obtenerComisiones(tarea.id);
    // Coloca por defecto los valores que vienen de base de datos
    if (comisiones.length === 5) {
      setDatosNuevos(true);
      const datosComisionesVendedor = encontrarComisionPorTipoCargo(
        comisiones,
        "VENDEDOR"
      );
      const datosComisionesEmpleado = encontrarComisionPorTipoCargo(
        comisiones,
        "EMPLEADO"
      );
      const datosComisionesSupervisor = encontrarComisionPorTipoCargo(
        comisiones,
        "SUPERVISOR"
      );
      const datosComisionesUtilidad = encontrarComisionPorTipoCargo(
        comisiones,
        "UTILIDAD"
      );
      const datosComisionesTaxtic = encontrarComisionPorTipoCargo(
        comisiones,
        "TAXTIC"
      );

      setComisionVendedor(datosComisionesVendedor.comision);
      setComisionEmpleado(datosComisionesEmpleado.comision);
      setComisionSupervisor(datosComisionesSupervisor.comision);
      setPorcentajeUtilidad(datosComisionesUtilidad.comision);
      setComisionTaxtic(datosComisionesTaxtic.comision);

      setIdComisionTaxtic(datosComisionesTaxtic.id);
      setIdComisionVendedor(datosComisionesVendedor.id);
      setIdComisionEmpleado(datosComisionesEmpleado.id);
      setIdComisionSupervisor(datosComisionesSupervisor.id);
      setIdUtilidad(datosComisionesUtilidad.id);

      setNombreVendedor(
        datosComisionesVendedor.empleado
          ? `${datosComisionesVendedor.empleado.nombre} ${datosComisionesVendedor.empleado.apellidos}`
          : "Vendedor"
      );
      setNombreEmpleado(
        `${datosComisionesEmpleado.empleado.nombre} ${datosComisionesEmpleado.empleado.apellidos}`
      );
      setNombreSupervisor(
        `${datosComisionesSupervisor.empleado.nombre} ${datosComisionesSupervisor.empleado.apellidos}`
      );
    } else {
      setDatosNuevos(false);
      setComisionTaxtic(15);
      setNombreVendedor("Vendedor");
      setNombreEmpleado("Empleado");
      setNombreSupervisor("Supervisor");
    }
  }, [comisiones, obtenerComisiones, tarea.id]);

  const handleChangeComisionVendedor = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionVendedor(event.target.value);
    } else {
      setComisionVendedor(0);
    }
  };

  const handleChangeComisionTaxtic = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionTaxtic(event.target.value);
    } else {
      setComisionTaxtic(0);
    }
  };
  const handleChangeComisionEmpleado = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionEmpleado(event.target.value);
    } else {
      setComisionEmpleado(0);
    }
  };
  const handleChangeComisionSupervisor = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setComisionSupervisor(event.target.value);
    } else {
      setComisionSupervisor(0);
    }
  };

  useEffect(() => {
    if (tarea.valorTarea === 0) {
      const labels = [
        "Taxtic (15%)",
        "Vendedor (0%)",
        "Empleado (0%)",
        "Supervisor (0%)",
        "Utilidad (0%)",
      ];
      setComisionTaxtic(15);
      setParticipacionesInformacion([]);
      setLabelParticipaciones(labels);
    } else {
      let acumulado = 0;
      acumulado =
        Number(comisionTaxtic) +
        Number(comisionEmpleado) +
        Number(comisionSupervisor) +
        Number(comisionVendedor);
      setAcumuladoPorcentaje(acumulado);
      if (acumuladoPorcentaje > 100 || comisionVendedor > 15) {
        const mensajeTexto =
          acumuladoPorcentaje > 100
            ? "El porcentaje total de las participaciones, no debe ser mayor a 100"
            : "La participación del vendedor no puede ser mayor a 15";
        setMessage(mensajeTexto);
        setColor("error");
        setShowAlert(true);
        setHayErrores(true);
      } else {
        setHayErrores(false);
        const utilidadPorcentaje = 100 - acumuladoPorcentaje;
        setPorcentajeUtilidad(utilidadPorcentaje);
        const labelUtilidad = `Utilidad (${porcentajeUtilidad}%)`;
        const labelVendedor = `${formatearNombre(nombreVendedor)} (${
          tarea.vendidoTaxtic ? "0" : comisionVendedor
        }%)`;
        const labelEmpleado = `${formatearNombre(
          nombreEmpleado
        )} (${comisionEmpleado}%)`;
        const labelSupervisor = `${formatearNombre(
          nombreSupervisor
        )} (${comisionSupervisor}%)`;

        const labels = [
          "Taxtic (15%)",
          labelVendedor,
          labelEmpleado,
          labelSupervisor,
          labelUtilidad,
        ];
        setLabelParticipaciones(labels);
        setParticipacionTaxtic(
          calcularParticipacion(tarea.valorTarea, comisionTaxtic)
        );
        setParticipacionEmpleado(
          calcularParticipacion(tarea.valorTarea, comisionEmpleado)
        );
        setParticipacionSupervisor(
          calcularParticipacion(tarea.valorTarea, comisionSupervisor)
        );
        if (!tarea.vendidoTaxtic) {
          setParticipacionVendedor(
            calcularParticipacion(tarea.valorTarea, comisionVendedor)
          );
        }
        setUtilidad(
          calcularParticipacion(tarea.valorTarea, porcentajeUtilidad)
        );
        const arrayParticipaciones = [
          participacionTaxtic.toFixed(2),
          participacionVendedor.toFixed(2),
          participacionEmpleado.toFixed(2),
          participacionSupervisor.toFixed(2),
          utilidad.toFixed(2),
        ];
        setParticipacionesInformacion(arrayParticipaciones);
      }
    }
  }, [
    acumuladoPorcentaje,
    comisionEmpleado,
    comisionSupervisor,
    comisionTaxtic,
    comisionVendedor,
    nombreEmpleado,
    nombreSupervisor,
    nombreVendedor,
    participacionEmpleado,
    participacionSupervisor,
    participacionTaxtic,
    participacionVendedor,
    porcentajeUtilidad,
    setColor,
    setMessage,
    setShowAlert,
    tarea.valorTarea,
    tarea.vendidoTaxtic,
    utilidad,
  ]);

  const chart = {
    series: participacionesInformacion,
    options: {
      chart: {
        type: "polarArea",
      },
      labels: labelParticipaciones,
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      noData: {
        text: "No hay datos disponibles",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontWeight: 700,
          fontFamily: undefined,
        },
      },
    },
  };

  const onSubmitComisiones = () => {
    setGuardandoDatos(true);

    if (validarString(tarea.supervisor)) {
      const mensajeTexto =
        "Revise el detalle de la tarea, es necesario tener un supervisor para guardar comisiones";
      setMessage(mensajeTexto);
      setColor("error");
      setShowAlert(true);
      setGuardandoDatos(false);
      return;
    }

    const body = {
      idTarea: tarea.id,
      idComisionTaxtic: idComisionTaxtic,
      idComisionVendedor: idComisionVendedor,
      idComisionEmpleado: idComisionEmpleado,
      idComisionSupervisor: idComisionSupervisor,
      idUtilidad: idUtilidad,
      comisionVendedor: comisionVendedor,
      comisionEmpleado: comisionEmpleado,
      comisionSupervisor: comisionSupervisor,
      comisionTaxtic: comisionTaxtic,
      porcentajeUtilidad: porcentajeUtilidad,
      participacionTaxtic: participacionTaxtic,
      participacionVendedor: participacionVendedor,
      participacionEmpleado: participacionEmpleado,
      participacionSupervisor: participacionSupervisor,
      utilidad: utilidad,
      refEmpleado: tarea.refEmpleado,
      refSupervisor: tarea.supervisor,
      tipoMonedaTarea: tarea.tipoMonedaTarea,
      datosNuevos: datosNuevos,
    };

    editarComisionesTarea(body)
      .then(() => {
        AlertaToast({
          element: "Comisiones",
          action: "actualizadas",
        }).fire();
      })
      .catch((error) => {
        console.error("Error en onSubmitComisiones", error);
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      })
      .finally(() => {
        setGuardandoDatos(false);
        setDatosNuevos(true);
      });
  };

  return (
    <Container>
      <RootStyle>
        <Card style={{ padding: "1rem" }}>
          <GraficoComisionesEmpleado
            comisionTaxtic={comisionTaxtic}
            comisionEmpleado={comisionEmpleado}
            comisionSupervisor={comisionSupervisor}
            comisionVendedor={comisionVendedor}
            handleChangeComisionTaxtic={handleChangeComisionTaxtic}
            handleChangeComisionVendedor={handleChangeComisionVendedor}
            handleChangeComisionEmpleado={handleChangeComisionEmpleado}
            handleChangeComisionSupervisor={handleChangeComisionSupervisor}
            valorTarea={tarea.valorTarea}
            tipoMonedaTarea={tarea.tipoMonedaTarea}
            chart={chart}
            vendidoTaxtic={tarea.vendidoTaxtic === 1 ? true : false}
            cargando={cargandoComisiones || guardandoDatos}
            datosNuevos={datosNuevos}
            finalizado={finalizado}
          />
        </Card>
        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <LoadingButton
            loading={guardandoDatos}
            onClick={onSubmitComisiones}
            variant="contained"
            disabled={hayErrores || finalizado}
          >
            Guardar Comisiones
          </LoadingButton>
        </Stack>
      </RootStyle>
    </Container>
  );
}

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));
