import React, { useEffect, useState } from "react";
import { Grid, Button, Card, Stack, Typography } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import ColumnaDia from "./ColumnaDia";
import { useSelector } from "react-redux";
import moment from "moment";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { calendarioApi } from "src/services/calendarioApi";

const obtenerDiaMes = (indice) => {
  if (indice === "01") return "ENERO";
  if (indice === "02") return "FEBRERO";
  if (indice === "03") return "MARZO";
  if (indice === "04") return "ABRIL";
  if (indice === "05") return "MAYO";
  if (indice === "06") return "JUNIO";
  if (indice === "07") return "JULIO";
  if (indice === "08") return "AGOSTO";
  if (indice === "09") return "SEPTIEMBRE";
  if (indice === "10") return "OCTUBRE";
  if (indice === "11") return "NOVIEMBRE";
  if (indice === "12") return "DICIEMBRE";
};
export default function TableroCompartido(props) {
  const { value } = props;
  const {
    sucursal: sucursalObtenida,
  } = useSelector((state) => state.auth.user);

  const [
    getCalendario,
    {
      data: calendarioData
    },
  ] = calendarioApi.useLazyGetCalendarioQuery();

  const [fechaCalendario, setFechaCalendario] = useState([]);
  const [mesActual, setMesActual] = useState(0);
  const [textoMes, setTextoMes] = useState("");

  useEffect(() => {
    getCalendario({
      sucursal: sucursalObtenida.refSucursal,
      mesActual: mesActual,
      usuario: "NULL"
    })
  }, [getCalendario, mesActual, sucursalObtenida.refSucursal])

  useEffect(() => {
    setFechaCalendario(calendarioData);
    if (calendarioData && calendarioData.length >= 1) {
      const textoMesAux = `${obtenerDiaMes(moment(calendarioData[0].fecha, "DD/MM/YYYY").format('MM'))} ${moment(calendarioData[0].fecha, "DD/MM/YYYY").format('YYYY')}`;
      setTextoMes(textoMesAux);

    }
  }, [calendarioData])

  const onDragEnd = (result) => {
    return;
  };

  return (
    <>
      {value === 2 && (
        <div>
          <Grid
            container
            marginTop="10px"
            marginBottom="10px"
          >
            <Grid item xs={4} md={4}>
              <Button
                variant="contained"
                onClick={() => {
                  setMesActual(mesActual - 1);
                }}
              >
                <ArrowBack />
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setMesActual(0);
                }}
              >
                HOY
              </Button>

            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                variant="h3"
                align="center"
              >
                {
                  textoMes

                }
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Stack
                direction="row-reverse"
              >
                <Button
                  onClick={() => {
                    setMesActual(mesActual + 1);
                  }}
                  variant="contained"
                >
                  <ArrowForward />
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <DragDropContext onDragEnd={onDragEnd}>
            <Card>
              {fechaCalendario && (
                <Grid container spacing={1} minHeight="300px">
                  {
                    (fechaCalendario && fechaCalendario.length >= 1) && (
                      fechaCalendario.map((item) => {
                        return <ColumnaDia
                          key={item.fecha}
                          dia={item.fecha}
                          actividades={item.actividades}
                          estado={item.estado}
                          compartido={true}
                        />
                      })

                    )
                  }

                </Grid>
              )}
            </Card>
          </DragDropContext>
        </div>
      )}
    </>
  );
}
