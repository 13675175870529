import React, { useRef, useState, useEffect, useCallback } from "react";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as locales from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
// components
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";
import { TareasListHead, TareasListToolbar } from "../_dashboard/tareas";
import { Alerta } from "../Alerta";
import { API_SERVER } from "../../utils/urlBack_End";
import { calcularDiasTranscurridos, formatDate } from "../../utils/converter";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";
import moment from "moment";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_el) => {
      return (
        _el.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        removeAccents(_el.nombre.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_el.tipoTarea.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_el.nombreCliente.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _el.fechaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _el.fechaTermino.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
  pos: {
    marginBottom: 15,
  },
}));

export default function TareasEmpleado(props) {
  const { value, empleado, tareas, setTareas } = props;
  const navigate = useNavigate();
  const classes = styles();
  const isMounted = useRef(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("fechaTermino");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const tableHead = useState(defineTableHeader())[0];
  const [showAlert, setShowAlert] = useState(false);

  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const { data: empleadoData = [], isLoading: loadingTable } =
    tareasApi.useGetTareasAllQuery({
      refEmpleado: empleado.rut,
      id: sucursalObtenida.refSucursal,
    });

  useEffect(() => {
    if (!loadingTable) {
      setTareas(empleadoData);
    }
  }, [loadingTable]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function defineTableHeader() {
    const tableHeadAux = [
      // { id: 'id', label: 'ID', alignRight: false },
      { id: "nombreCliente", label: "Nombre Cliente", alignRight: false },
      { id: "nombre", label: "Tarea", alignRight: false },
      { id: "tipoTarea", label: "Tipo Tarea", alignRight: false },
      { id: "fechaInicio", label: "Fecha Inicio", alignRight: false },
      { id: "fechaTermino", label: "Fecha Término", alignRight: false },
      {
        id: "diasTranscurridos",
        label: "Días Transcurridos",
        alignRight: false,
      },
      { id: "editar", label: "", alignRight: false },
      { id: "eliminar", label: "", alignRight: false },
    ];

    return tableHeadAux;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tareas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tareas.length) : 0;

  const filteredItems = applySortFilter(
    tareas,
    getComparator(order, orderBy),
    filterName
  );

  const isElementNotFound = filteredItems.length === 0;

  const renderTableBody = () => {
    return filteredItems
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const {
          id,
          nombre,
          tipoTarea,
          nombreCliente,
          fechaInicio,
          fechaTermino,
          diasTranscurridos,
        } = row;
        const isItemSelected = selected.indexOf(id) !== -1;

        return (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            <TableCell
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
              align="left"
            >
              {nombreCliente}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {nombre}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {tipoTarea}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {fechaInicio
                ? moment(fechaInicio).format("DD/MM/YYYY")
                : fechaInicio}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {fechaTermino
                ? moment(fechaTermino).format("DD/MM/YYYY")
                : fechaTermino}
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
              style={{ cursor: "pointer" }}
            >
              {diasTranscurridos === 1
                ? `${diasTranscurridos} dia`
                : `${diasTranscurridos} dias`}
            </TableCell>
          </TableRow>
        );
      });
  };

  return (
    <div className={classes.pos}>
      {value === 2 && (
        <Card className={classes.root} variant="outlined">
          <Typography variant="h5" component="h2" className={classes.title}>
            Tareas del Empleado
          </Typography>
          <CardContent>
            <Grid container>
              <Grid item xs md={3}>
                <TareasListToolbar
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Grid>
            </Grid>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {!loadingTable ? (
                  <Table>
                    <TareasListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={tableHead}
                      rowCount={tareas.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {renderTableBody()}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isElementNotFound && tareas.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {tareas.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            No hay tareas que mostrar.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                ) : (
                  <Skeleton variant="rectangular" width="100%" height="500px" />
                )}
              </TableContainer>
            </Scrollbar>
            <ThemeProvider
              theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={tareas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          </CardContent>

          <Alerta
            showAlert={showError}
            setShowAlert={setShowError}
            message={error}
            color="error"
          />

          {showAlert && (
            <Alerta
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              message={error}
              color={"success"}
            />
          )}
        </Card>
      )}
    </div>
  );
}
