import PropTypes from "prop-types";
// material
import { visuallyHidden } from "@material-ui/utils";
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";

// ----------------------------------------------------------------------

TablaHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func,
  select: PropTypes.bool,
  rowCount: PropTypes.number,
  selectedRows: PropTypes.array,
  handleSelectAllClick: PropTypes.func,
};

export default function TablaHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  select = false,
  rowCount,
  selectedRows,
  handleSelectAllClick,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {select && (
          <TableCell padding="checkbox">
            <Tooltip title="Seleccionar todas">
              <Checkbox
                indeterminate={rowCount > 0 && rowCount < selectedRows.length}
                checked={rowCount > 0 && selectedRows.length === rowCount}
                onChange={handleSelectAllClick}
              />
            </Tooltip>
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
