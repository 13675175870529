import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@material-ui/core";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import Scrollbar from "../Scrollbar";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import EditIcon from "@material-ui/icons/Edit";
import SearchNotFound from "../SearchNotFound";
import * as locales from "@material-ui/core/locale";
import { Alerta } from "../Alerta";
import ModalProrrateo from "../Modal/modalProrrateo/ModalProrrateo";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

const TablaProrrateo = ({
  tabIndex,
  data: informacionProrrateo,
  loading: isLoadingInformacionProrrateo,
}) => {
  const [state, setState] = useState(INITIAL_STATE_TABLE);
  const [statesModal, setStateModal] = useState(INITIAL_STATE_MODAL);
  const [showAlert, setShowAlert] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  // const {
  //   data: informacionProrrateo,
  //   isLoading: isLoadingInformacionProrrateo,
  // } = prorrateosApi.useObtenerInformacionProrrateoQuery();

  const sucursalesFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "nombre",
      "gerente",
      "rutRazonSocial",
      "comuna",
    ];
    return applySortFilter(
      informacionProrrateo,
      getComparator(state.order, state.orderBy),
      state.filterName,
      propiedadesParaFiltrar
    );
  }, [state.filterName, state.order, state.orderBy, informacionProrrateo]);

  const handleClickAdd = useCallback(() => {
    setRowSelected({});
    setStateModal({ ...statesModal, abrirModal: true, seEdita: false });
  }, [statesModal]);

  const handleClickEdit = useCallback(
    (row) => {
      setRowSelected(row);
      setStateModal({ ...statesModal, abrirModal: true, seEdita: true });
    },
    [statesModal]
  );

  if (tabIndex !== 0) {
    return null;
  }

  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  const handleFilterByName = (event) => {
    setState({ ...state, filterName: event.target.value });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const esSucursalNoEncontrada = sucursalesFiltradas.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const emptyRows =
    state.currentPage > 0
      ? Math.max(
          0,
          (1 + state.currentPage) * state.rowsPerPageOptions -
            informacionProrrateo.length
        )
      : 0;

  return (
    <>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs="auto">
            <TablaToolbar
              filterName={state.filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" align="center">
              Prorrateos
            </Typography>
          </Grid>
          <Grid item xs md textAlign="right" marginRight={2}>
            <Button
              onClick={handleClickAdd.bind(null)}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Agregar prorrateo
            </Button>
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer>
            {!isLoadingInformacionProrrateo ? (
              <Table>
                <TablaHead
                  order={state.order}
                  orderBy={state.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={informacionProrrateo.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sucursalesFiltradas
                    .slice(
                      state.currentPage * state.rowsPerPageOptions,
                      state.currentPage * state.rowsPerPageOptions +
                        state.rowsPerPageOptions
                    )
                    .map((item) => (
                      <TableRow hover key={item.sucursal.rutRazonSocial}>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {item.sucursal.nombre}
                          </Typography>
                        </TableCell>
                        <TableCell>{item.sucursal.gerente}</TableCell>
                        <TableCell>
                          {formatRut(
                            item.sucursal.rutRazonSocial,
                            RutFormat.DASHS
                          )}
                        </TableCell>
                        <TableCell>{item.sucursal.comuna}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Tooltip title="Editar prorrateo">
                              <Button
                                color="primary"
                                onClick={handleClickEdit.bind(null, item)}
                              >
                                <EditIcon />
                              </Button>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
                {esSucursalNoEncontrada && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={state.filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
          <ThemeProvider
            theme={(outerTheme) =>
              createTheme(outerTheme, locales[state.locale])
            }
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sucursalesFiltradas.length}
              rowsPerPage={state.rowsPerPageOptions}
              page={state.currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Scrollbar>
      </Card>
      <Alerta
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        color="success"
        message="Prorrateo configurado correctamente"
      />
      {statesModal.abrirModal && (
        <ModalProrrateo
          open={statesModal.abrirModal}
          setStateModal={setStateModal}
          data={rowSelected}
          editar={statesModal.seEdita}
          statesModal={statesModal}
        />
      )}
    </>
  );
};

const TABLE_HEAD = [
  { id: "nombre", label: "Nombre", alignRight: false },
  { id: "gerente", label: "Gerente", alignRight: false },
  { id: "rutRazonSocial", label: "RUT", alignRight: false },
  { id: "comuna", label: "Ciudad", alignRight: false },
  { id: "" },
];

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

const INITIAL_STATE_MODAL = {
  abrirModal: false,
  seEdita: false,
};

export default TablaProrrateo;
