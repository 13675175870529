import React from "react";
import PropTypes from "prop-types";
// material
import { visuallyHidden } from "@material-ui/utils";
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@material-ui/core";

// ----------------------------------------------------------------------

TareasListHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func,
};

export default function TareasListHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  setCabecera,
  setFiltrar,
}) {
  const createSortHandler = (property) => (event) => {
    event.preventDefault();
    onRequestSort(event, property);
  };

  const filtro = (headCell) => {
    if (setCabecera && setFiltrar && headCell.id !== "diasTranscurridos") {
      setCabecera(headCell);
      setFiltrar(true);
    }
  };
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => filtro(headCell)}
              onContextMenu={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
