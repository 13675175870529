import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Container,
  Button,
  CardMedia,
  CardActions,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Titulo } from "../Titulo";
import { API_KEY_OPEN_FACTURA_DEV, API_KEY_OPEN_FACTURA_PROD, API_OPEN_FACTURA_DEV, API_OPEN_FACTURA_PROD, API_SERVER, URL_ORIGEN } from "../../utils/urlBack_End";
import { saveAs } from "file-saver";
import DownloadIcon from "@material-ui/icons/Download";
import { LoadingButton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import moment from "moment";

export default function PagosWebPay() {
  const { id } = useParams();
  const navigate = useNavigate()
  const {
    cliente: user,
  } = useSelector((state) => state.auth.user);
  const [factura, setFactura] = useState({});
  const [respuestaCrearTransaccion, setRespuestaCrearTransaccion] = useState(null);
  const [respuestaCommitTransaccion, setRespuestaCommitTransaccion] = useState(null);
  const [commitResponseWebpay, setCommitResponseWebpay] = useState(null);
  const [loadingDescargar, setLoadingDescargar] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/facturas/${id.split("_")[0]}/${id.split("_")[1]}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        const obtenerToken = String(window.location.href).split("?");
        const facturaObtenida = response.data
        setFactura(facturaObtenida);

        if (!respuestaCommitTransaccion
          && obtenerToken
          && obtenerToken.length >= 2
          && facturaObtenida.estado !== "PAGADO"
          && facturaObtenida.estado !== "ANULADO"
        ) {

          const paramsGET = String(obtenerToken[1]).split("&")

          let parametrosCommit = {
            refCliente: user.rut
          }
          paramsGET.forEach((element) => {
            const nombreParametro = String(element).split("=")[0];
            const valorParametro = String(element).split("=")[1]

            parametrosCommit[nombreParametro] = valorParametro;

          })
          axios
            .post(`${API_SERVER}/webpay/commit`, parametrosCommit,
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("token")
                }
              }).then(({ data }) => {
                if (data && data.viewData && data.viewData.id === 0) {
                  const tokenWebPay = data.viewData.token;
                  const datosWebPay = data.viewData.commitResponse;

                  if (Number(id.split("_")[1]) === 0) {
                    axios.post(`${API_SERVER}/accesoClientes/pagos/webpay/plan`, {
                      totalFactura: Number.parseFloat(datosWebPay.amount),
                      idFactura: facturaObtenida.id,
                      empleadoRecibePago: '',
                      estado: "TOTAL",
                      sucursal: facturaObtenida.sucursal,
                      metodoDePago: "WEBPAY",
                      deudaTotal: 0,
                      folio: facturaObtenida.folio,
                      tokenWebPay: tokenWebPay,
                      datosWebPay: datosWebPay
                    },
                      {
                        headers: {
                          Authorization: 'Bearer ' + localStorage.getItem("token")
                        }
                      }
                    )
                      .then(function (response3) {
                        if (response3.data === -2) window.location.reload();
                      }).catch((err) => {

                      }).finally(() => {
                        setRespuestaCommitTransaccion(data)
                        setCommitResponseWebpay(datosWebPay);

                      });
                  }
                  else {

                    axios.post(`${API_SERVER}/accesoClientes/pagos/webpay/tarea`, {
                      idTarea: facturaObtenida.refTarea,
                      totalFactura: Number.parseFloat(datosWebPay.amount),
                      idFactura: facturaObtenida.id,
                      empleadoRecibePago: '',
                      estado: "TOTAL",
                      sucursal: facturaObtenida.sucursal,
                      metodoDePago: "WEBPAY",
                      tokenWebPay: tokenWebPay,
                      datosWebPay: datosWebPay
                    },
                      {
                        headers: {
                          Authorization: 'Bearer ' + localStorage.getItem("token")
                        }
                      }
                    )
                      .then(function (response3) {
                        if (response3.data === -2) window.location.reload();
                      }).catch((err) => {

                      })
                      .finally(() => {
                        setRespuestaCommitTransaccion(data);
                        setCommitResponseWebpay(datosWebPay)

                      });
                  }
                }
                else {
                  setRespuestaCommitTransaccion(data);
                }
              })

        }
        else {
          if (facturaObtenida && (facturaObtenida.estado === "PAGADO" || facturaObtenida.estado === "ANULADO")) {
            window.close();
          }

          let montoTotalFactura = 0;
          if (facturaObtenida.valorPesos) {
            montoTotalFactura = facturaObtenida.valorPesos;
          }
          else {
            montoTotalFactura = facturaObtenida.total;

          }

          axios
            .post(`${API_SERVER}/webpay/create`, {
              amount: Number.parseFloat(montoTotalFactura),
              returnUrl: window.location.href,
            },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("token")
                }
              }).then(({ data }) => {
                setRespuestaCrearTransaccion(data)

              })



        }

      });
  }, [id]);

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;

    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  return (
    <>
      {!factura ? (


        <Container>
          <Titulo>
            <Typography
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "1rem",
                paddingLeft: "1rem",
              }}
              variant="h5"
            >
              Error al solicitar la información.
            </Typography>
            <Button
              onClick={() => {
                window.close();
              }}
            >
              Volver

            </Button>
          </Titulo>


        </Container>
      ) : (
        <Container>
          <Card>
            <CardHeader
              title={`Folio: ${factura.folio}`}
              subheader={`Fecha: ${moment(factura.fechaFacturacion).format("DD/MM/YYYY")}`}
            />
            <CardMedia
              component="img"
              sx={{ width: "100%", height: "200px", marginTop: "10px" }}
              image="/static/webpay.jpeg"
              alt="WebPay"
            />
            <CardContent>

              <Grid container>

                <Grid item xs={4} md={4}>
                  <LoadingButton
                    color="primary"
                    loading={loadingDescargar}
                    onClick={() => {
                      setLoadingDescargar(true);
                      axios
                        .get(
                          URL_ORIGEN.includes("http://localhost:3000") ?
                            `${API_OPEN_FACTURA_DEV}/v2/dte/document/${factura.token}/pdf` :
                            `${API_OPEN_FACTURA_PROD}/v2/dte/document/${factura.token}/pdf`,
                          {
                            headers: {
                              apikey: URL_ORIGEN.includes("http://localhost:3000") ? API_KEY_OPEN_FACTURA_DEV : API_KEY_OPEN_FACTURA_PROD,
                            },
                          }
                        )
                        .then((response) => {
                          saveAs(
                            "data:application/pdf;base64," +
                            response.data.pdf,
                            factura.folio + ".pdf"
                          );
                          setLoadingDescargar(false);
                        })
                        .catch(() => {
                          setLoadingDescargar(false);
                        });
                    }}
                    variant="contained"
                  >
                    <DownloadIcon
                      style={{ marginRight: "0.5rem" }}
                    />{ }
                    Descargar Factura
                  </LoadingButton>

                </Grid>
                <Grid item xs={4} md={4}>


                </Grid>
                <Grid item xs={4} md={4}>
                  {
                    respuestaCrearTransaccion && (
                      <form method="POST" action={respuestaCrearTransaccion.url}>
                        <input type="hidden" name="token_ws" value={respuestaCrearTransaccion.token} />
                        <Button
                          type="submit"
                          variant="contained"
                          color="info"
                        >
                          Pagar
                        </Button>
                      </form>
                    )
                  }
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </Container>
      )}
      {
        commitResponseWebpay && (
          <Dialog
            open={commitResponseWebpay}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title">
              {"Comprobante de pago"}
            </DialogTitle>
            <DialogContent>
              <List sx={{ width: '100%' }}>
                <ListItem>
                  <ListItemText primary="Estado" secondary={commitResponseWebpay.status} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Nº Orden" secondary={commitResponseWebpay.buy_order} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Fecha y hora" secondary={moment(commitResponseWebpay.transaction_date).format("DD/MM/YYYY HH:mm")} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Monto" secondary={formatNumber(commitResponseWebpay.amount)} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Medio de pago" secondary="WEBPAY" />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  navigate("/dashboard/facturas")
                }}
                autoFocus
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
}
