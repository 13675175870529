import React, { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { API_SERVER } from "../../utils/urlBack_End";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
  TableRow,
  Table,
  TableHead,
} from "@material-ui/core";
import axios from "axios";
export default function EditarElementoBaseCliente(props) {
  const { setColumnaCliente, openEditar, setOpenEditar, elemento } = props;

  const [componentesAsociados, setComponentesAsociados] = useState([]);

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState(
    "Error al guardar los datos"
  );

  const [openAgregarElemento, setOpenAgregarElemento] = useState(false);
  const [elementoSelector, setElementoSelector] = useState("");
  const elementoSeleccionado = elemento[0];

  useEffect(() => {
    axios
      .get(
        `${API_SERVER}/ajustes/relacionSelectorElemento/${elementoSeleccionado.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setComponentesAsociados(response.data);
      });
  }, [elementoSeleccionado.id]);

  const handleClickOpenAgregar = () => {
    setOpenAgregarElemento(true);
  };

  const handleChangeNombreElementoSelector = (event) => {
    setElementoSelector(event.target.value);
  };

  const handleCloseAgregar = () => {
    setOpenAgregarElemento(false);
    setElementoSelector("");
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const agregarElemento = () => {
    let elementosAux = componentesAsociados;
    let elemento = elementosAux.filter(
      (element) =>
        element.nombreElemento.toUpperCase() === elementoSelector.toUpperCase()
    )[0];

    if (elemento) {
      setColorNotificacion("error");
      setMensajeNotificacion("Elemento ya existe.");
      setOpenNotificacion(true);
    } else {
      elementosAux.push({
        nombreElemento: elementoSelector.toUpperCase(),
      });
      setComponentesAsociados(elementosAux);
    }

    setElementoSelector("");
    setOpenAgregarElemento(false);
  };

  const guardarPlan = () => {
    if (elementoSeleccionado.componente === "select") {
      axios
        .delete(
          `${API_SERVER}/ajustes/relacionSelectorElemento/${elementoSeleccionado.id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(async function (response) {
          if (response.data === -2) window.location.reload();
          let promesaComponentes = new Promise((resolve, reject) => {
            componentesAsociados.forEach((element) => {
              let selectorElemento = {
                refComponente: elementoSeleccionado.id,
                nombreElemento: element.nombreElemento,
              };
              axios
                .post(
                  `${API_SERVER}/ajustes/relacionSelectorElemento`,
                  {
                    ...selectorElemento,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then(function (response) {
                  if (response.data === -2) window.location.reload();
                })
                .catch(function (error) {
                  reject({ error: true });
                });
            });
            resolve({ error: false });
          });
          promesaComponentes.then(() => {
            axios
              .get(`${API_SERVER}/clientes/tablaCliente`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then(function (response) {
                if (response.data === -2) window.location.reload();
                setColumnaCliente(response.data);
                setOpenEditar(false);
              });
          });
        });
    }
  };

  const cancelar = () => {
    setOpenEditar(false);
  };
  return (
    <>
      <Dialog open={openEditar} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar Selector</DialogTitle>
        <DialogContent>
          {elementoSeleccionado.componente === "select" ? (
            <div>
              <TextField
                autoFocus
                margin="dense"
                label="Nombre Columna"
                type="text"
                value={elementoSeleccionado.nombreColumna}
                fullWidth
                disabled
              />
              <Button
                color="primary"
                startIcon={<Icon icon={plusFill} />}
                style={{ marginTop: "10px" }}
                onClick={handleClickOpenAgregar}>
                Agregar Elemento Selector
              </Button>

              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre Elemento Selector</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {componentesAsociados.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.nombreElemento}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              let componentes = componentesAsociados.filter(
                                (dato) =>
                                  dato.nombreElemento !== row.nombreElemento
                              );
                              setComponentesAsociados(componentes);
                            }}
                            color="error">
                            Eliminar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div></div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} color="error">
            Cancelar
          </Button>
          <Button onClick={guardarPlan} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAgregarElemento} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Nombre Elemento Selector
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Elemento Selector"
            type="text"
            value={elementoSelector}
            onChange={handleChangeNombreElementoSelector}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAgregar} color="error">
            Cancelar
          </Button>
          <Button onClick={agregarElemento} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}>
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}>
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
    </>
  );
}
