import { filter } from "lodash";
import { removeAccents } from "src/utils/converter";

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const applySortFilter = (array = [], comparator, query, properties) => {
  const mappedArray = array.map((el, index) => [el, index]);
  mappedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  if (query) {
    const normalizedQuery = removeAccents(query.toLowerCase());
    return filter(array, (_data) =>
      properties.some((prop) =>
        removeAccents(String(_data[prop]))
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  }
  return mappedArray.map(([el]) => el);
};

export { applySortFilter, getComparator };
