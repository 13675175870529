import { AppBar, Container, Tab, Tabs, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import TablaClientesPorGrupo from "src/components/clientes/grupos/tablaClientesPorGrupo";
import TablaClientesSinGrupo from "src/components/clientes/grupos/tablaClientesSinGrupo";
import Page from "src/components/Page";
import { Titulo } from "src/components/Titulo";
import { clientesApi } from "src/services/clientesApi";

export default function GrupoClientes() {
  const [tabIndex, setTabIndex] = useState(0);
  const [
    obtenerClientesPorGrupo,
    { data: clientesPorGrupo, isLoading: cargandoClientesPorGrupo },
  ] = clientesApi.useLazyObtenerClientesPorGrupoQuery();
  const [
    obtenerClientesSinGrupo,
    { data: clientesSinGrupo, isLoading: cargandoClientesSinGrupo },
  ] = clientesApi.useLazyObtenerClientesSinGrupoQuery();

  const verificarIndiceTab = useCallback(() => {
    if (tabIndex === 0) {
      obtenerClientesPorGrupo();
    } else if (tabIndex === 1) {
      obtenerClientesSinGrupo();
    }
  }, [obtenerClientesPorGrupo, obtenerClientesSinGrupo, tabIndex]);

  useEffect(() => {
    verificarIndiceTab();
  }, [verificarIndiceTab]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Administrar grupos clientes
          </Typography>
        </Titulo>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="tabs-configuracion-liquidaciones"
          >
            <Tab label="Clientes con grupos" />
            <Tab label="Clientes sin grupo" />
          </Tabs>
        </AppBar>
        <TablaClientesPorGrupo
          rows={
            !cargandoClientesPorGrupo && clientesPorGrupo
              ? clientesPorGrupo
              : []
          }
          loading={cargandoClientesPorGrupo}
          tabIndex={tabIndex}
          title="Clientes por grupo"
          subheader="Se muestran los clientes según su grupo"
        />
        <TablaClientesSinGrupo
          rows={
            !cargandoClientesSinGrupo && clientesSinGrupo
              ? clientesSinGrupo
              : []
          }
          loading={cargandoClientesSinGrupo}
          tabIndex={tabIndex}
          title="Clientes sin grupo"
          subheader="Se muestran los clientes activos sin grupo asociado"
        />
      </Container>
    </Page>
  );
}
