import React, { useEffect, useState, useRef } from "react";
import {
    Paper,
    Typography,
    Stack,
    Grid,
    FormControl,
    TextField,
    Autocomplete,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    IconButton
} from "@material-ui/core";
import axios from "axios";
import { lowerCase, startCase } from "lodash";
import { API_SERVER } from "src/utils/urlBack_End";
import DeleteIcon from "@material-ui/icons/Delete"
import { useSelector } from "react-redux";


export default function EmpleadosAdicionales(props) {
    const {
        valor,
        empleadosAdicionales,
        setEmpleadosAdicionales
    } = props;

    const {
        sucursal: sucursalObtenida,
    } = useSelector((state) => state.auth.user);

    const sucursal = sucursalObtenida.refSucursal;

    const [empleados, setEmpleados] = useState([]);
    const [empleadoOptions, setEmpleadoOptions] = useState([]);

    const [empleadoAdicional, setEmpleadoAdicional] = useState(null);
    const [empleadoAdicionalSeleccionado, setEmpleadoAdicionalSeleccionado] = useState("");

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    useEffect(() => {
        axios.get(
            `${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token")
                }
            }
        )
            .then(function (response) {
                if (response.data === -2) window.location.reload();
                if (isMounted.current) {
                    setEmpleados(response.data);
                }

            });

    }, [sucursal])

    useEffect(() => {
        if (isMounted.current) {
            setEmpleadoOptions(
                empleados.map((item) => {
                    return {
                        label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
                        value: item.rut,
                    };
                })
            );

        }

    }, [empleados]);
    return (
        <>
            {valor === 1 && (
                <Paper style={{ height: (window.screen.height - 350), margin: "10px", overflow: "auto" }} >
                    <Typography align="center" variant="h3">Adicionales</Typography>
                    <Stack spacing={2}>

                        <Grid container>
                            <Grid item xs={10} md={10}>
                                <FormControl fullWidth style={{ marginTop: "30px" }}>
                                    <Autocomplete
                                        value={empleadoAdicional}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                setEmpleadoAdicionalSeleccionado(
                                                    newValue.label.toUpperCase()
                                                );
                                                setEmpleadoAdicional(newValue);
                                            } else {
                                                setEmpleadoAdicionalSeleccionado("");
                                                setEmpleadoAdicional(null);
                                            }
                                        }}
                                        fullWidth
                                        id="combo-box-empleado-adicional"
                                        options={empleadoOptions}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label="Seleccionar Empleado Adicional"
                                                value={empleadoAdicionalSeleccionado}
                                            />
                                        )}
                                    />
                                </FormControl>


                            </Grid>



                            <Grid item xs={2} md={2}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    style={{ marginTop: "30px" }}
                                    onClick={() => {
                                        let empleadoObtenido = empleadosAdicionales.filter(e => e.value === empleadoAdicional.value)[0];
                                        if (empleadoObtenido) {

                                        }
                                        else {
                                            setEmpleadosAdicionales([...empleadosAdicionales, empleadoAdicional])
                                            setEmpleadoAdicional(null);

                                        }
                                    }}
                                >
                                    Agregar

                                </Button>


                            </Grid>

                        </Grid>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre Empleado</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {empleadosAdicionales && empleadosAdicionales.map((row, idx) => (
                                        <TableRow
                                            key={"empleadosAdicionales" + idx}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        let empleadoObtenido = empleadosAdicionales.filter(e => e.value !== row.value);
                                                        setEmpleadosAdicionales(empleadoObtenido)

                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>





                    </Stack>

                </Paper>

            )}
        </>

    );
}