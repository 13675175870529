import React, { useState, useEffect } from "react";
import { API_SERVER, dbx } from "../../../utils/urlBack_End";
import axios from "axios";
import TablaContratoSeleccionado from "./TablaContratoSeleccionado";
import { useSelector } from "react-redux";

export default function ContratosClienteSeleccionado(props) {
  const { value, cliente } = props;
  const {
    cliente: user,
  } = useSelector((state) => state.auth.user);

  const [contratos, setContratos] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/contrato/${user.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setContratos(response.data);
      });
  }, [user.rut]);

  useEffect(() => {
    if (cliente.rut !== undefined) {
      dbx
        .filesListFolder({ path: `/Clientes/${cliente.rut}/Contratos` })
        .then(() => { })
        .catch(() => {
          dbx
            .filesCreateFolderV2({
              path: `/Clientes/${cliente.rut}/Contratos`,
            })
            .then((response) => { })
            .catch((error) => { });
        })
        .finally(() => { });
    }
  }, [cliente.rut]);
  return (
    <>
      {value === 3 && (
        <>
          <TablaContratoSeleccionado
            contratos={contratos}
          />
        </>
      )}
    </>
  );
}
