import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  TableHead,
  IconButton,
  Snackbar,
  Alert,
} from "@material-ui/core/";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@material-ui/lab";
import { AlertaMensajeEliminacion } from "./AlertaMensajeEliminacion";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { clientesApi } from "src/services/clientesApi";
import { ajustesApi } from "src/services/ajustesApi";
import { AlertaModal, AlertaToast } from "./AlertaSwall";

export default function AsociarEmpresas(props) {
  const { open, setOpen } = props;
  const [grupoSelect, setGrupoSelect] = useState(null);

  const [clienteSelect, setClienteSelect] = useState(null);
  const [cargando, setCargando] = useState(null);

  const [asociaciones, setAsociasiones] = useState([]);
  const [mostrarMensajeEliminacion, setMostrarMensajeEliminacion] =
    useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [pasa, setPasa] = useState(false);

  const [openAgregarElemento, setOpenAgregarElemento] = useState(false);

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const colorNotificacion = "success";
  const mensajeNotificacion = "Error al guardar los datos";

  const [elementoSelector, setElementoSelector] = useState("");

  const { data: clientes = [] } = clientesApi.useGetClientesQuery({
    estado: "todos",
  });

  const [asociarGrupo] = clientesApi.useAddAsociarGruposMutation();

  const { data: grupos = [] } =
    ajustesApi.useGetSelectorElementoQuery("1297451858");

  const [agregarRelacionSelectorElemento] =
    ajustesApi.useAddRelacionSelectorElementoMutation();

  const asociar = () => {
    AlertaModal({
      title: "asociar clientes",
      confirmButtonText: "Sí, asociar",
      target: document.getElementById("dialogAsociaciones"),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          setCargando(true);
          asociarGrupo({
            asociaciones: asociaciones,
          })
            .unwrap()
            .then((response) => {
              if (Array.isArray(response) && Array(response).length > 1) {
                let arregloData = response;
                setMensajeAlerta(
                  "Los siguientes datos no se pudieron copiar \n" +
                    arregloData.toString()
                );
                setPasa(false);
                setMostrarMensajeEliminacion(true);
              } else {
                AlertaToast({
                  element: "clientes",
                  action: "asociados",
                }).fire();
                setOpen(false);
              }
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogAsociaciones"),
              }).fire();
            })
            .finally(() => {
              setCargando(false);
            });
        }
      });
  };

  const handleChangeNombreElementoSelector = (event) => {
    setElementoSelector(event.target.value);
  };

  const handleCloseAgregar = () => {
    setOpenAgregarElemento(false);
    setElementoSelector("");
  };

  const agregarElemento = () => {
    let elementos = grupos.filter(
      (e) => e.nombreElemento === elementoSelector.toUpperCase()
    )[0];

    if (elementos) {
      AlertaToast({
        action: "Elemento ya existe.",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogNombreGrupo"),
      }).fire();
    } else {
      let selectorElemento = {
        refComponente: "1297451858",
        nombreElemento: elementoSelector.toUpperCase(),
      };
      agregarRelacionSelectorElemento(selectorElemento)
        .unwrap()
        .then((response) => {
          AlertaToast({
            element: "grupo",
            action: "agregado",
            target: document.getElementById("dialogAsociaciones"),
          }).fire();
          setElementoSelector("");
          setOpenAgregarElemento(false);
        })
        .catch(() => {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogNombreGrupo"),
          }).fire();
        });
    }
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md" id="dialogAsociaciones">
        <DialogTitle>Asociar grupo a empresas</DialogTitle>
        <DialogContent style={{ height: "500px" }}>
          <Grid container marginTop={2}>
            <Grid item xs={12} md={5} style={{ marginTop: "30px" }}>
              <Button
                style={{ marginLeft: "15px", marginTop: "10px" }}
                onClick={() => {
                  setOpenAgregarElemento(true);
                }}
                fullWidth
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
              >
                Agregar Nuevo Grupo
              </Button>
            </Grid>
          </Grid>
          <Grid container marginTop={1} marginBottom={2}>
            <Grid item xs={12} md={5} style={{ marginTop: "30px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={grupos}
                  isOptionEqualToValue={(option) => option.nombreElemento}
                  getOptionLabel={(option) => option.nombreElemento}
                  value={grupoSelect}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setGrupoSelect(newValue);
                    } else {
                      setGrupoSelect(null);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField fullWidth {...params} label="Grupo" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5} style={{ marginTop: "30px" }}>
              <FormControl
                style={{ marginLeft: "10px", marginRight: "10px" }}
                fullWidth
              >
                <Autocomplete
                  disablePortal
                  options={clientes}
                  isOptionEqualToValue={(option) => option.rut}
                  getOptionLabel={(option) => option.nombre}
                  value={clienteSelect}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setClienteSelect(newValue);
                    } else {
                      setClienteSelect(null);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField fullWidth {...params} label="Empresa" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "30px" }}>
              <Button
                style={{ marginLeft: "15px", marginTop: "10px" }}
                onClick={() => {
                  if (
                    clienteSelect &&
                    grupoSelect &&
                    asociaciones.filter(
                      (asociacion) =>
                        asociacion.rutEmpresa === clienteSelect.rut
                    ).length === 0
                  ) {
                    setAsociasiones([
                      ...asociaciones,
                      {
                        nombreEmpresa: clienteSelect.nombre,
                        rutEmpresa: clienteSelect.rut,
                        grupoEmpresa: grupoSelect.nombreElemento,
                      },
                    ]);
                  }
                }}
                fullWidth
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Grupo</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asociaciones.map((row, idx) => (
                  <TableRow key={"tablaEmpresa" + idx}>
                    <TableCell component="th" scope="row">
                      {row.grupoEmpresa}
                    </TableCell>
                    <TableCell>{row.nombreEmpresa}</TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setAsociasiones(
                            asociaciones.filter(
                              (e) => e.rutEmpresa !== row.rutEmpresa
                            )
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cerrar
          </Button>
          <LoadingButton
            loading={cargando}
            autoFocus
            variant="contained"
            onClick={asociar}
          >
            Asociar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        id="dialogNombreGrupo"
        open={openAgregarElemento}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nombre Grupo</DialogTitle>
        <Snackbar
          open={openNotificacion}
          autoHideDuration={6000}
          onClose={handleCloseNotificacion}
        >
          <Alert
            onClose={handleCloseNotificacion}
            severity={colorNotificacion}
            sx={{ width: "100%" }}
          >
            {mensajeNotificacion}
          </Alert>
        </Snackbar>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Grupo"
            type="text"
            value={elementoSelector}
            onChange={handleChangeNombreElementoSelector}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAgregar} color="error">
            Cancelar
          </Button>
          <Button onClick={agregarElemento} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      {mostrarMensajeEliminacion && (
        <AlertaMensajeEliminacion
          mensaje={mensajeAlerta}
          open={mostrarMensajeEliminacion}
          setOpen={setMostrarMensajeEliminacion}
          aceptar={() => {
            if (pasa) {
              window.location.reload();
            }
          }}
          mensajeBotonAceptar={"Cerrar"}
          ocultar={"Ocultar"}
        />
      )}
    </>
  );
}
