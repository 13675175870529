import { deconstructRut, formatRut } from "@fdograph/rut-utilities";
import axios from "axios";
import { upperCase } from "lodash";
import { API_SERVER } from "../../utils/urlBack_End";

export const obtenerEmpleadosSucursal = async (sucursal) => {
  const empleados = [];
  await axios
    .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      empleados.push(...response.data);
    });

  return empleados;
};

export const obtenerClientesSucursal = async () => {
  const clientes = [];
  await axios
    .get(`${API_SERVER}/clientes/`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      clientes.push(...response.data);
    });

  return clientes;
};


export const formatearDatosPorpuestaPlan = (empresa) => {
  const refCliente =
    deconstructRut(empresa.rut).digits +
    "" +
    deconstructRut(empresa.rut).verifier;

  const descuento =
    empresa.plan.descuentoFinal > 0 ? empresa.plan.descuentoFinal : 0;

  const valorDesc = descuento > 0 ? empresa.plan.precioFinal : 0;
  const isFijo = precioFijo(
    empresa.plan.tipoMoneda,
    empresa.plan.tipoMonedaFinal,
    empresa.plan.valor,
    empresa.plan.precioFijoFinal
  );
  const valor = isFijo
    ? {
      valor: empresa.plan.precioFijoFinal,
      tipoMoneda: empresa.plan.tipoMonedaFinal,
    }
    : {
      valor: empresa.plan.valor,
      tipoMoneda: empresa.plan.tipoMoneda,
    };

  const fechaTerminoDesc =
    descuento > 0
      ? empresa.plan.frecuenciaDesc +
      " " +
      (empresa.plan.tipoFrecuenciaDesc === ""
        ? "MES"
        : empresa.plan.tipoFrecuenciaDesc)
      : "";

  const nombreCliente = upperCase(empresa.nombre);
  const direccion = upperCase(empresa.direccion);
  const comuna = upperCase(empresa.comuna);

  return {
    refPropuesta: "",
    refTipoPlan: empresa.plan.nombre,
    refCliente: refCliente,
    descuento: descuento,
    valorDesc: valorDesc,
    fechaTerminoDesc: fechaTerminoDesc,
    tipoMoneda: valor.tipoMoneda,
    valor: valor.valor,
    nombreCliente: nombreCliente,
    direccion: direccion,
    comuna: comuna,
    telefono: empresa.telefono,
    correo: empresa.email,
    recurrente: "SI",
    frecuencia: empresa.plan.frecuencia,
    tipoFrecuencia: empresa.plan.tipoFrecuencia,
    mesCargaTareas: empresa.plan.mesCargaTareas,
    valorFijo: isFijo ? "SI" : "NO",
  };
};

const precioFijo = (moneda, monedaFija, total, totalFijo) => {
  return moneda !== monedaFija || total !== totalFijo;
};

export const crearRelacionPropuestaPlan = (datos) => {
  let error = false;
  axios
    .post(
      `${API_SERVER}/propuestas/relacionPlan`,
      {
        ...datos,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      error = response.data;
      return error;
    })
    .catch(() => {
      return error;
    });
};

export const obtenerRelacionPropuestaPlan = async (idPropuesta) => {
  const planes = [];
  await axios
    .get(`${API_SERVER}/propuestas/relacionPlan/${idPropuesta}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async function (response) {
      if (response.data === -2) window.location.reload();
      await Promise.all(
        response.data.map(async (plan) => {
          const cliente = await obtenerClienteEspecifico(plan.refCliente);
          const infoPlan = await obtenerTipoPlanEspecifico(plan.refTipoPlan);

          planes.push({
            ...cliente,
            rut: formatRut(cliente.rut),
            email: cliente.correo,
            plan: {
              ...infoPlan,
              tipoMonedaFinal: plan.tipoMoneda,
              precioFijoFinal: plan.valor,
              descuentoFinal: plan.descuento,
              precioFinal: plan.descuento > 0 ? plan.valorDesc : plan.valor,
              frecuencia: plan.frecuencia,
              tipoFrecuencia: plan.tipoFrecuencia,
              frecuenciaDesc:
                plan.descuento <= 0 ? 1 : plan.fechaTerminoDesc.split(" ")[0],
              tipoFrecuenciaDesc:
                plan.descuento <= 0
                  ? "MES"
                  : plan.fechaTerminoDesc.split(" ")[1],
            },
          });
        })
      ).then(() => { });
    });

  return planes;
};

export const obtenerClienteEspecifico = async (rutCliente) => {
  const result = await axios.get(`${API_SERVER}/clientes/${rutCliente}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });

  return result.data ? result.data : null;
};

export const obtenerTipoPlanEspecifico = async (nombre) => {
  const result = await axios.get(`${API_SERVER}/tipos/tipoPlanes/${nombre}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  return result.data ? result.data : null;
};

export const eliminarRelacionPropuestaPlan = async (
  refPropuesta,
  refTipoPlan,
  refCliente
) => {
  let error = false;

  await axios
    .delete(
      `${API_SERVER}/propuestas/relacionPlan/${refPropuesta}/${refTipoPlan}/${refCliente}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      error = response.data;
    });

  return error;
};
