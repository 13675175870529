import {
  Card,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import SeleccionFechaSucursalFactura from "./SeleccionFechaSucursalFactura";
import DetalleFacturas from "./DetalleFacturas";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import es from "dayjs/locale/es.js";
import { validarFormatoFecha } from "src/utils/formatTime";
import { formatRut, validateRut } from "@fdograph/rut-utilities";
import DetalleInformacionCliente from "./DetalleInformacionCliente";
import FormProvider from "src/components/hook-form/FormProvider";
import { dteApi } from "src/services/dteApi";
import { facturasApi } from "src/services/facturasApi";
import handleError from "src/utils/manejo-errores";
import icAdd from "@iconify/icons-ant-design/plus-outlined";
import {
  limitarDecimalesSinRelleno,
  limpiarString,
} from "src/utils/sharedFunctions";
import { saveAs } from "file-saver";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import { ajustesApi } from "src/services/ajustesApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import ModalBorradorFactura from "src/components/Modal/modalBorradorFactura";
import Iconify from "src/components/iconify";
import FabButtonAnimate from "src/components/animate/FabButtonAnimate";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(es);
dayjs.tz.setDefault("America/Santiago");

const FacturarManual = ({
  sucursalObtenida,
  rutEmpleado,
  nombreEmpleado,
  tabIndex,
  sucursales,
  cargandoSucursales,
  monedas,
  obteniendoMonedas,
}) => {
  const [
    obtenerInfoContribuyente,
    { isFetching: obteniendoInfoContribuyente },
  ] = dteApi.useLazyObtenerInfoContribuyenteQuery();
  const { data: comunas = [] } = ajustesApi.useGetComunasQuery();
  const [generarFactura] = facturasApi.useFacturarManualMutation();
  const [abrirModal, setAbrirModal] = useState(false);
  const [mostrarBotonAgregarSeccion, setMostrarBotonAgregarSeccion] =
    useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const detalleFacturasRef = useRef(null);

  const initialValues = {
    rutCliente: "",
    tipo: "PLAN",
    sucursal: sucursalObtenida,
    fechaEmision: dayjs(),
    RznSocRecep: "",
    GiroRecep: "",
    DirRecep: "",
    CmnaRecep: "",
    EmailRecep: "",
    items: [
      {
        glosa: "",
        descripcion: "",
        moneda: "PESOS",
        cantidad: 1,
        precio: "",
        total: 0,
      },
    ],
    mostrarSeccionReferencia: false,
    referencias: [
      {
        TpoDocRef: "801",
        FolioRef: "",
        FchRef: dayjs(),
        RazonRef: "",
      },
    ],
    precioTotal: 0,
  };

  const facturasSchema = Yup.object().shape({
    rutCliente: Yup.string()
      .required("El rut del cliente es requerido")
      .test(
        "no-special-characters",
        "No ingrese puntos ni guiones",
        (value) => {
          if (value) {
            return !/[^0-9kK]/.test(value);
          }
        }
      )
      .test("valid-rut", "Ingrese un rut válido", (value) => {
        if (value) {
          return validateRut(value.replace(/[^0-9kK]/g, ""));
        }
      }),
    tipo: Yup.string().nullable().required("La sucursal es requerida"),
    RznSocRecep: Yup.string()
      .nullable()
      .required("La razón social es requerida"),
    GiroRecep: Yup.string().nullable().required("El giro es requerido"),
    DirRecep: Yup.string().nullable().required("La dirección es requerida"),
    CmnaRecep: Yup.string().nullable().required("La comuna es requerida"),
    mostrarSeccionReferencia: Yup.boolean(),
    EmailRecep: Yup.string()
      .nullable()
      .required("El correo es requerido")
      .trim()
      .email("Ingrese un correo electrónico válido"),
    sucursal: Yup.string().nullable().required("La sucursal es requerida"),
    fechaEmision: Yup.date()
      .required("La fecha de emisión es requerida")
      .test(
        "formatoFecha",
        "Formato de fecha inválido. Use DD/MM/YYYY",
        (value) => validarFormatoFecha(value, "DD/MM/YYYY")
      )
      .transform((originalValue, originalObject) =>
        validarFormatoFecha(originalValue, "DD/MM/YYYY")
          ? dayjs(originalValue).toDate()
          : originalValue
      )
      .typeError("Formato de fecha inválido. Use DD/MM/YYYY"),
    items: Yup.array().of(
      Yup.object().shape({
        glosa: Yup.string()
          .required("La glosa es requerida")
          .max(90, "La observación no puede tener más de 80 caracteres"),
        descripcion: Yup.string().max(
          1000,
          "La descripción no puede tener más de 1000 caracteres"
        ),
        moneda: Yup.string().required("La moneda es requerida"),
        cantidad: Yup.number()
          .min(1, "La cantidad debe ser mayor o igual a 1")
          .required("La cantidad es requerida")
          .typeError("La cantidad debe ser un número"),
        precio: Yup.string()
          .transform((value, originalValue) => {
            const transformedValue = String(originalValue).replace(",", ".");
            return transformedValue;
          })
          .matches(/^\d*\.?\d*$/, "El precio debe ser un número válido")
          .min(0, "El precio debe ser mayor o igual a 0")
          .required("El precio es requerido")
          .typeError("El precio debe ser un número"),
        total: Yup.number().nullable().required("El total es requerido"),
      })
    ),
    referencias: Yup.array().of(
      Yup.object().shape({
        TpoDocRef: Yup.string(),
        FolioRef: Yup.string(),
        FchRef: Yup.date()
          .test(
            "formatoFechaReferencia",
            "Formato de fecha inválido aa. Use DD/MM/YYYY",
            (value) => validarFormatoFecha(value, "DD/MM/YYYY")
          )
          .transform((originalValue, originalObject) =>
            validarFormatoFecha(originalValue, "DD/MM/YYYY")
              ? dayjs(originalValue).toDate()
              : originalValue
          )
          .typeError("Formato de fecha inválido. Use DD/MM/YYYY"),
        RazonRef: Yup.string().max(
          90,
          "La observación no puede tener más de 90 caracteres"
        ),
      })
    ),
    precioTotal: Yup.number()
      .nullable()
      .required("El precio total de los ítems es requerido")
      .typeError("El precio total debe ser un número"),
  });

  const validateForm = (values) => {
    const errors = {};

    if (values.mostrarSeccionReferencia) {
      values.referencias.forEach((referencia, index) => {
        if (!referencia.TpoDocRef) {
          if (!errors.referencias) errors.referencias = [];
          errors.referencias[index] = {
            TpoDocRef: "El tipo de documento es requerido",
          };
        }
        if (!referencia.FolioRef) {
          if (!errors.referencias) errors.referencias = [];
          errors.referencias[index] = {
            ...errors.referencias[index],
            FolioRef: "El folio de referencia es requerido",
          };
        }
        if (!referencia.FchRef) {
          if (!errors.referencias) errors.referencias = [];
          errors.referencias[index] = {
            ...errors.referencias[index],
            FchRef: "La fecha de la referencia es requerida",
          };
        }
      });
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: facturasSchema,
    validate: validateForm,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      emitirFactura(setSubmitting, resetForm);
    },
  });

  const {
    values,
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClicAddSection = useCallback(() => {
    setFieldValue("mostrarSeccionReferencia", true);
    // Scroll to DetalleFacturas
    if (detalleFacturasRef.current) {
      detalleFacturasRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setAnchorEl(null);
    setMostrarBotonAgregarSeccion(false);
  }, [setFieldValue]);

  const emitirFactura = useCallback(
    async (setSubmitting, resetForm) => {
      const result = await AlertaModal({
        title: "emitir la factura",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).fire();

      if (result.isConfirmed) {
        try {
          const {
            fechaEmision,
            sucursal,
            tipo,
            rutCliente,
            precioTotal,
            items,
            RznSocRecep,
            GiroRecep,
            DirRecep,
            CmnaRecep,
            EmailRecep,
            referencias,
            mostrarSeccionReferencia,
          } = values;
          const rutFormateado = formatRut(rutCliente);
          const fechaFormateada = fechaEmision.format("YYYY-MM-DD");

          const detalle = items.map(
            (
              { glosa, descripcion, moneda, cantidad, precio, total },
              index
            ) => {
              let precioItem;
              if (moneda !== "PESOS") {
                const { valor } = monedas.find(
                  (tipoMoneda) => tipoMoneda?.moneda === moneda
                );
                precioItem = limitarDecimalesSinRelleno(valor * Number(precio));
              } else {
                precioItem = limitarDecimalesSinRelleno(Number(precio));
              }

              const itemDetalle = {
                NroLinDet: index + 1,
                NmbItem: limpiarString(glosa),
                QtyItem: cantidad,
                PrcItem: precioItem,
                MontoItem: Number(total),
                IndExe: 1,
              };

              if (descripcion) {
                itemDetalle.DscItem = descripcion;
              }

              return itemDetalle;
            }
          );

          const Referencia = referencias.map((referencia, index) => ({
            ...referencia,
            NroLinRef: index + 1,
            FchRef: referencia.FchRef.format("YYYY-MM-DD"),
          }));

          const body = {
            Taxtic: {
              sucursal,
              tipo,
              rutEmpleado,
              nombreEmpleado,
              correoContribuyente: EmailRecep.trim(),
            },
            IdDoc: {
              TipoDTE: 34,
              Folio: 0,
              FchEmis: fechaFormateada,
              FmaPago: 2,
            },
            Receptor: {
              RUTRecep: rutFormateado,
              RznSocRecep,
              GiroRecep: GiroRecep.slice(0, 40),
              DirRecep,
              CmnaRecep,
            },
            Totales: {
              MntExe: precioTotal,
              MntTotal: precioTotal,
              MontoPeriodo: precioTotal,
              VlrPagar: precioTotal,
            },
            Detalle: detalle,
            Referencia,
            mostrarSeccionReferencia,
          };

          const response = await generarFactura(body);
          const { data, error } = response;

          if (error) {
            const { data: dataError } = error;
            throw dataError;
          }
          if (!data) {
            throw new Error(
              "Ocurrió un error desconocido al emitir la Factura"
            );
          }

          const { folio, pdf } = data;
          saveAs(`data:application/pdf;base64,${pdf}`, `${folio}.pdf`);

          resetForm();
          AlertaToast({
            action: "Factura emitida correctamente",
            fullText: true,
          }).fire();
        } catch (error) {
          AlertaToast({
            action: "Error al emitir la factura, reintente nuevamente",
            type: "error",
            fullText: true,
          }).fire();
          handleError("emitirFactura", error);
        } finally {
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    },
    [generarFactura, monedas, nombreEmpleado, rutEmpleado, values]
  );

  useEffect(() => {
    if (tabIndex !== 2) {
      resetForm();
      return;
    }
  }, [tabIndex, resetForm]);

  const manejarPeticionInfoCliente = useCallback(
    async (rut) => {
      const { data, error } = await obtenerInfoContribuyente(rut);

      if (error) {
        if (error.data && error.data.code === "ENOTFOUND") {
          AlertaToast({
            action:
              "Problemas de conexión. Por favor, verifica tu conexión a internet y vuelve a intentarlo.",
            type: "error",
            fullText: true,
          }).fire();
        } else if (error.status === "FETCH_ERROR") {
          AlertaToast({
            action:
              "Error de red. No se pudo establecer conexión con el servidor. Comprueba tu conexión a internet y vuelve a intentarlo más tarde.",
            type: "error",
            fullText: true,
          }).fire();
        } else {
          AlertaToast({
            action:
              "Se produjo un error inesperado. Inténtalo nuevamente más tarde.",
            type: "error",
            fullText: true,
          }).fire();
        }

        console.error(error);
        return;
      }
      const { RznSocRecep, GiroRecep, DirRecep, CmnaRecep, EmailRecep } = data;
      setFieldValue(
        "RznSocRecep",
        RznSocRecep ? capitalizarPalabras(RznSocRecep) : ""
      );
      setFieldValue(
        "GiroRecep",
        GiroRecep ? capitalizarPalabras(GiroRecep) : ""
      );
      setFieldValue(
        "DirRecep",
        DirRecep ? capitalizarPrimeraLetra(DirRecep) : ""
      );
      setFieldValue(
        "CmnaRecep",
        CmnaRecep ? limpiarString(CmnaRecep.toUpperCase()) : ""
      );
      setFieldValue("EmailRecep", EmailRecep || "");
    },
    [obtenerInfoContribuyente, setFieldValue]
  );

  useEffect(() => {
    let timerId;
    const rutPattern = /^\d{7,8}[0-9kK]$/;

    if (
      values.rutCliente !== "" &&
      rutPattern.test(values.rutCliente) &&
      validateRut(values.rutCliente)
    ) {
      timerId = setTimeout(() => {
        manejarPeticionInfoCliente(values.rutCliente);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [manejarPeticionInfoCliente, values.rutCliente]);

  const generarBorradorFactura = useCallback(() => {
    setAbrirModal(true);
  }, []);

  if (tabIndex !== 2) return null;

  return (
    <>
      <FormProvider onSubmit={handleSubmit} formik={formik}>
        <Card sx={{ pt: 3, px: 3 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={8} lg={8} xs={8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="h3" align="center">
                  Emisión de Facturas
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4} lg={4} xs={4}>
              {mostrarBotonAgregarSeccion && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Tooltip title="Agregar sección">
                    <FabButtonAnimate
                      variant="soft"
                      size="medium"
                      color="secondary"
                      onClick={handleClick}
                    >
                      <Iconify icon={icAdd} width={24} />
                    </FabButtonAnimate>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      sx: {
                        backgroundColor: "#212121",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <MenuItem onClick={handleClicAddSection}>
                      <Iconify icon={icAdd} width={20} sx={{ mr: 1 }} />
                      <Typography variant="body2">Referencias</Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Grid>
          <SeleccionFechaSucursalFactura
            sucursales={sucursales}
            loading={cargandoSucursales || isSubmitting}
            loadingSearch={obteniendoInfoContribuyente}
          />
          <Divider />
          <DetalleInformacionCliente
            comunas={comunas}
            loading={cargandoSucursales || isSubmitting}
            loadingSearch={obteniendoInfoContribuyente}
          />
          <Divider />
          <DetalleFacturas
            seccionReferencia={detalleFacturasRef}
            setMostrarBotonAgregarSeccion={setMostrarBotonAgregarSeccion}
            monedas={monedas}
            loading={cargandoSucursales || isSubmitting}
            obteniendoMonedas={obteniendoMonedas}
          />
        </Card>
        <Stack
          justifyContent="flex-end"
          direction="row"
          spacing={2}
          sx={{ mt: 3 }}
        >
          <LoadingButton
            color="inherit"
            size="large"
            disabled={!isValid || isSubmitting}
            variant="contained"
            onClick={() => generarBorradorFactura()}
          >
            Ver borrador
          </LoadingButton>
          <LoadingButton
            size="large"
            variant="contained"
            loading={isSubmitting}
            type="submit"
          >
            Emitir factura
          </LoadingButton>
        </Stack>
      </FormProvider>
      {abrirModal && isValid && (
        <ModalBorradorFactura
          isOpen={abrirModal}
          setOpen={setAbrirModal}
          data={values}
        />
      )}
    </>
  );
};
export default React.memo(FacturarManual);
