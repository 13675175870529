import { Icon } from "@iconify/react";
import { filter } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Titulo } from "../components/Titulo";
import { removeAccents } from "src/utils/converter";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { TablaHead, TablaToolbar } from "../components/_dashboard/tablas";
import * as locales from "@material-ui/core/locale";
import Page from "../components/Page";
import AgregarSucursal from "../components/AdministracionSucursal/AgregarSucursal";
import { Alerta } from "../components/Alerta";
import { API_SERVER } from "../utils/urlBack_End";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { sucursalesApi } from "src/services/sucursalesApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { empleadosApi } from "src/services/empleadosApi";

const TABLE_HEAD = [
  { id: "nombre", label: "Nombre Sucursal", alignRight: false },
  { id: "direccion", label: "Dirección", alignRight: false },
  { id: "telefono", label: "Teléfono", alignRight: false },
  { id: "gerente", label: "Nombre Gerente", alignRight: false },
  { id: "razonSocial", label: "Razón Social", alignRight: false },
  { id: "rutRazonSocial", label: "Rut Razón Social", alignRight: false },
  { id: "representanteLegal", label: "Representante", alignRight: false },
  { id: "rutRepresentante", label: "Rut Representante", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        removeAccents(_user.nombre.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _user.direccion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.telefono.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        removeAccents(_user.gerente.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_user.razonSocial.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _user.rutRazonSocial.toLowerCase().indexOf(query.toLowerCase()) !==
        -1 ||
        removeAccents(_user.representanteLegal.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _user.rutRepresentante.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Sucursales() {
  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [elementoSeleccionado, setElementoSeleccionado] = useState(null);
  const [locale] = useState("esES");

  const {
    data: sucursales = [],
    isLoading: loadingTable
  } = sucursalesApi.useGetSucursalesQuery();

  const [eliminarSucursalDB] = sucursalesApi.useDeleteSucursalMutation();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sucursales.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sucursales.length) : 0;

  const filteredUsers = applySortFilter(
    sucursales,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const eliminarSucursal = (nombre) => {
    AlertaModal({
      title: `eliminar la sucursal de ${nombre}`,
      confirmButtonText: 'Sí, eliminar',
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarSucursalDB(nombre)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: 'Sucursal',
                action: 'eliminado',
              }).fire()
            }).catch(() => {
              AlertaToast({
                action: 'Error en el servidor',
                fullText: true,
                type: 'error',
              }).fire()
            })

        }
      })
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs={6}>
              <Typography variant="h4" gutterBottom>
                Administración de Sucursales
              </Typography>
            </Grid>
            <Grid item xs>
              {(rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN") && (
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}>
                  Agregar Sucursal
                </Button>
              )}
            </Grid>
          </Grid>
        </Titulo>

        <Card>
          <TablaToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {!loadingTable ? (
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={sucursales.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          nombre,
                          direccion,
                          telefono,
                          gerente,
                          razonSocial,
                          rutRazonSocial,
                          representanteLegal,
                          rutRepresentante,
                        } = row;
                        const isItemSelected = selected.indexOf(nombre) !== -1;

                        return (
                          <TableRow
                            hover
                            key={nombre}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              padding="none">
                              <Typography variant="subtitle2" noWrap>
                                {nombre}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{direccion}</TableCell>
                            <TableCell align="left">{telefono}</TableCell>
                            <TableCell align="left">{gerente}</TableCell>
                            <TableCell align="left">{razonSocial}</TableCell>
                            <TableCell align="left">
                              {formatRut(rutRazonSocial, RutFormat.DASHS)}
                            </TableCell>
                            <TableCell align="left">
                              {representanteLegal}
                            </TableCell>
                            <TableCell align="left">
                              {formatRut(rutRepresentante, RutFormat.DASH)}
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                mb={5}>
                                <Tooltip title="Editar">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      setElementoSeleccionado(row);
                                      setOpenEditar(true);
                                    }}>
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                                {rolObtenido === "SUPER_ADMIN" && (
                                  <Tooltip title="Eliminar">
                                    <Button
                                      color="error"
                                      onClick={() => {
                                        eliminarSucursal(row.nombre)
                                      }}>
                                      <DeleteIcon />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={sucursales.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      </Container>
      <Alerta
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        color="success"
        message="Datos de la sucursal guardados"
      />

      {open && (
        <AgregarSucursal
          open={open}
          setOpen={setOpen}
          nombre=""
          direccion=""
          comuna={null}
          telefono=""
          gerente=""
          razonSocial=""
          rutRazonSocial=""
          representanteLegal=""
          rutRepresentante=""
          editar={false}
        />
      )}
      {openEditar && (
        <AgregarSucursal
          open={openEditar}
          setOpen={setOpenEditar}
          nombre={elementoSeleccionado.nombre}
          direccion={elementoSeleccionado.direccion}
          comuna={elementoSeleccionado.comuna}
          telefono={elementoSeleccionado.telefono}
          gerente={elementoSeleccionado.gerente}
          razonSocial={elementoSeleccionado.razonSocial}
          rutRazonSocial={formatRut(
            elementoSeleccionado.rutRazonSocial.toLowerCase(),
            RutFormat.DASH
          )}
          representanteLegal={elementoSeleccionado.representanteLegal}
          rutRepresentante={formatRut(
            elementoSeleccionado.rutRepresentante.toLowerCase(),
            RutFormat.DASH
          )}
          editar={true}
        />
      )}
    </Page>
  );
}
