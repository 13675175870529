import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  Box,
  Container,
  Grid,
} from "@material-ui/core";
import { API_SERVER } from "src/utils/urlBack_End";
import axios from "axios";
import TablaPagosFacturas from "src/components/gestionEmpleado/TablaPagosFacturas";

export default function PagosFacturaTareasCliente(props) {
  const { idFactura, openPagos, setOpenPagos } = props;
  const [pagos, setPagos] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/pagos/tareas/${idFactura}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setPagos(response.data);
        }
      });
  }, [idFactura]);

  return (
    <div>
      <Dialog
        open={openPagos}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <TablaPagosFacturas pagos={pagos} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={7}></Grid>
              <Grid item xs={6} sm={2} justifyContent="flex-end"></Grid>
              <Grid item xs={6} sm={2}>
                <Button
                  fullWidth
                  onClick={() => setOpenPagos(false)}
                  color="primary"
                >
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </DialogActions>
      </Dialog>
    </div>
  );
}
