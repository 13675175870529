import React, { useState } from "react";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabsComponente(props) {
  const { encabezados, tabs } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "10px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {encabezados &&
            Array.isArray(encabezados) &&
            encabezados.map((encabezado, idx) => {
              return <Tab key={idx} label={encabezado} {...a11yProps(idx)} />;
            })}
        </Tabs>
      </Box>
      {tabs &&
        Array.isArray(tabs) &&
        tabs.map((tab, idx) => {
          return (
            <TabPanel key={idx} value={value} index={idx}>
              {tab}
            </TabPanel>
          );
        })}
    </Box>
  );
}
