// material
import {
  TextField,
  InputAdornment,
  Grid,
  Box,
  MenuItem,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { round } from "lodash";
import { Descuento } from "./Descuento";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

// ----------------------------------------------------------------------

export default function FormDefinePrice(props) {
  const { formik } = props;

  const { errors, touched, setFieldValue, getFieldProps, values } = formik;

  return (
    <RootStyle>
      <Grid container spacing={3}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={6}>
              <Box sx={{ minWidth: 120, maxWidth: "90%" }}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  fullWidth
                  required
                  // autoComplete="username"
                  type="tipoMonedaFinal"
                  label="Tipo Moneda"
                  value={values.tipoMonedaFinal}
                  onChange={formik.handleChange}
                  {...getFieldProps("tipoMonedaFinal")}
                  error={Boolean(
                    touched.tipoMonedaFinal && errors.tipoMonedaFinal
                  )}
                  helperText={touched.tipoMonedaFinal && errors.tipoMonedaFinal}
                >
                  <MenuItem value="PESOS">Pesos</MenuItem>
                  <MenuItem value="UTM">UTM</MenuItem>
                  <MenuItem value="UF">UF</MenuItem>
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                // autoComplete="username"
                type="precioFijoFinal"
                label="Precio Fijo"
                required
                InputProps={
                  values.tipoMonedaFinal === "PESOS"
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }
                    : values.tipoMonedaFinal === "UTM"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">UTM</InputAdornment>
                        ),
                      }
                    : values.tipoMonedaFinal === "UF"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">uf</InputAdornment>
                        ),
                      }
                    : {
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }
                }
                onChangeCapture={(e) => {
                  const { descuentoFinal } = values;
                  const precioFijoFinal = e.target.value;
                  if (precioFijoFinal >= 0 && descuentoFinal >= 0) {
                    setFieldValue(
                      "precioFinal",
                      round(
                        precioFijoFinal -
                          precioFijoFinal * (descuentoFinal / 100),
                        2
                      )
                    );
                  }
                }}
                {...getFieldProps("precioFijoFinal")}
                error={Boolean(
                  touched.precioFijoFinal && errors.precioFijoFinal
                )}
                helperText={touched.precioFijoFinal && errors.precioFijoFinal}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              {" "}
              <TextField
                fullWidth
                // autoComplete="username"
                type="descuentoFinal"
                label="Descuento"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChangeCapture={(e) => {
                  const { precioFijoFinal } = values;
                  const descuentoFinal = e.target.value;
                  if (precioFijoFinal >= 0 && descuentoFinal >= 0) {
                    setFieldValue(
                      "precioFinal",
                      round(
                        precioFijoFinal -
                          precioFijoFinal * (descuentoFinal / 100),
                        2
                      )
                    );
                  }

                  if (descuentoFinal <= 0) {
                    setFieldValue("frecuenciaDesc", 1);
                    setFieldValue("tipoFrecuenciaDesc", "MES");
                  }
                }}
                {...getFieldProps("descuentoFinal")}
                error={Boolean(touched.descuentoFinal && errors.descuentoFinal)}
                helperText={touched.descuentoFinal && errors.descuentoFinal}
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <TextField
                // autoComplete="username"
                disabled={true}
                type="precioFinal"
                color="success"
                focused
                label="Precio Final"
                value={new Intl.NumberFormat("cl-ES").format(
                  values.precioFinal
                )}
                InputProps={
                  values.tipoMonedaFinal === "PESOS"
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }
                    : values.tipoMonedaFinal === "UTM"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">UTM</InputAdornment>
                        ),
                      }
                    : values.tipoMonedaFinal === "UF"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">uf</InputAdornment>
                        ),
                      }
                    : {
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }
                }
                required
              />
            </Grid>
          </Grid>
        </Grid>
        {values.descuentoFinal > 0 && (
          <Grid item>
            {" "}
            <Descuento formik={formik} />
          </Grid>
        )}
      </Grid>
    </RootStyle>
  );
}
