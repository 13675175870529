import { useState } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
  TableRow,
  Table,
  TableHead,
} from "@material-ui/core";
import axios from "axios";
export default function AgregarElementoBaseCliente(props) {
  const { setColumnaCliente, open, setOpen, columnasCliente } = props;
  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState(
    "Error al guardar los datos"
  );
  const [nombreColumna, setNombreColumna] = useState("");
  const [tipoDato, setTipoDato] = useState("");
  const [labelComponente, setLabelComponente] = useState("");
  const [tipoComponente, setTipoComponente] = useState("");

  const [componentesAsociados, setComponentesAsociados] = useState([]);
  const [openAgregarElemento, setOpenAgregarElemento] = useState(false);
  const [elementoSelector, setElementoSelector] = useState("");

  const handleChangeNombreColumna = (event) => {
    setNombreColumna(event.target.value);
  };

  const handleChangeLabelComponente = (event) => {
    setLabelComponente(event.target.value);
  };

  const handleChangeTipoComponente = (event) => {
    setTipoComponente(event.target.value);
  };

  const handleChangeTipoDato = (event) => {
    setTipoDato(event.target.value);
  };

  const handleClickOpenAgregar = () => {
    setOpenAgregarElemento(true);
  };

  const handleChangeNombreElementoSelector = (event) => {
    setElementoSelector(event.target.value);
  };

  const handleCloseAgregar = () => {
    setOpenAgregarElemento(false);
    setElementoSelector("");
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const agregarElemento = () => {
    let elementosAux = componentesAsociados;
    let elemento = elementosAux.filter(
      (element) =>
        element.nombreElemento.toUpperCase() === elementoSelector.toUpperCase()
    )[0];

    if (elemento) {
      setColorNotificacion("error");
      setMensajeNotificacion("Elemento ya existe.");
      setOpenNotificacion(true);
    } else {
      elementosAux.push({
        nombreElemento: elementoSelector.toUpperCase(),
      });
      setComponentesAsociados(elementosAux);
    }

    setElementoSelector("");
    setOpenAgregarElemento(false);
  };

  const guardarPlan = () => {
    if (
      nombreColumna === "" ||
      nombreColumna === " " ||
      tipoDato === "" ||
      tipoComponente === "" ||
      labelComponente === " " ||
      labelComponente === ""
    ) {
      setMensajeNotificacion("Todos los campos son obligatorios.");
      setOpenNotificacion(true);
    } else {
      let nombreColumnaAux =
        nombreColumna[0].toLowerCase() + nombreColumna.slice(1);

      nombreColumnaAux = nombreColumnaAux.replace(/ /g, "");

      let elemento = columnasCliente.filter(
        (element) =>
          element.Field.toLowerCase() === nombreColumnaAux.toLowerCase()
      )[0];
      if (elemento) {
        setMensajeNotificacion(
          nombreColumnaAux + " ya existe en los registros"
        );
        setOpenNotificacion(true);
      } else {
        let columna = {
          nombreColumna: nombreColumnaAux,
          tipoDato: tipoDato,
          labelComponente: labelComponente,
          tipoComponente: tipoComponente,
        };
        axios
          .post(
            `${API_SERVER}/ajustes/relacionColumnaCliente`,
            {
              ...columna,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            if (tipoComponente === "select") {
              componentesAsociados.forEach((element) => {
                let selectorElemento = {
                  refComponente: response.data,
                  nombreElemento: element.nombreElemento,
                };
                axios
                  .post(
                    `${API_SERVER}/ajustes/relacionSelectorElemento`,
                    {
                      ...selectorElemento,
                    },
                    {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    }
                  )
                  .then(function (response) {
                    if (response.data === -2) window.location.reload();
                  });
              });
            }

            axios
              .get(`${API_SERVER}/clientes/tablaCliente`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then(function (response) {
                if (response.data === -2) window.location.reload();
                setColumnaCliente(response.data);
                setOpen(false);
              });
          });
      }
    }
  };

  const cancelar = () => {
    setNombreColumna("");
    setTipoDato("");
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nueva Columna</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Columna"
            type="text"
            value={nombreColumna}
            onChange={handleChangeNombreColumna}
            fullWidth
            required
          />

          <FormControl style={{ marginTop: "10px" }} fullWidth>
            <InputLabel>Tipo Dato (*)</InputLabel>
            <Select
              style={{ marginTop: "10px" }}
              value={tipoDato}
              onChange={handleChangeTipoDato}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              <MenuItem value="text">TEXTO</MenuItem>
              <MenuItem value="int">ENTEROS</MenuItem>
              <MenuItem value="double">DECIMALES</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ marginTop: "10px" }} fullWidth>
            <InputLabel>Tipo Componente (*)</InputLabel>
            <Select
              style={{ marginTop: "10px" }}
              value={tipoComponente}
              onChange={handleChangeTipoComponente}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              <MenuItem value="textfield">TEXTO</MenuItem>
              <MenuItem value="select">SELECTOR</MenuItem>
            </Select>
          </FormControl>

          <TextField
            style={{ marginTop: "10px" }}
            margin="dense"
            label="Nombre del Campo"
            type="text"
            required
            value={labelComponente}
            onChange={handleChangeLabelComponente}
            fullWidth
          />

          {tipoComponente === "select" && (
            <div>
              <Button
                color="primary"
                startIcon={<Icon icon={plusFill} />}
                style={{ marginTop: "10px" }}
                onClick={handleClickOpenAgregar}>
                Agregar Elemento Selector
              </Button>

              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre Elemento Selector</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {componentesAsociados.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.nombreElemento}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              let componentes = componentesAsociados.filter(
                                (dato) =>
                                  dato.nombreElemento !== row.nombreElemento
                              );
                              setComponentesAsociados(componentes);
                            }}
                            color="error">
                            Eliminar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} color="error">
            Cancelar
          </Button>
          <Button onClick={guardarPlan} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAgregarElemento} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Nombre Elemento Selector
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Elemento Selector"
            type="text"
            value={elementoSelector}
            onChange={handleChangeNombreElementoSelector}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAgregar} color="error">
            Cancelar
          </Button>
          <Button onClick={agregarElemento} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}>
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}>
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
      ;
    </>
  );
}
