import React, { useEffect, useState, useRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import {
  Stack,
  TextField,
  Grid,
  Autocomplete,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { LoadingButton } from "@material-ui/lab";
import { cleanRut, formatRut, validateRut } from "@fdograph/rut-utilities";
import { obtenerClientesSucursal } from "./Consultas";
import FormPlan from "./FormPlan";
import { upperCase } from "lodash";
import { ajustesApi } from "src/services/ajustesApi";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

// ----------------------------------------------------------------------

export const FormNewEmpresa = (props) => {
  const { updateDataEmpresa, dataEmpresa } = props;

  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .email("El correo electrónico debe ser válido")
      .required("Correo requerido"),
    nombre: Yup.string().required("Nombre requerido"),
    rut: Yup.mixed()
      .test("rut test", "RUT no válido", (value) => validateRut(value))
      .test(
        "rut test1",
        "El RUT ya fue agregado con el plan seleccionado.",
        (value) => !isBusyRut(value)
      )
      .required("Rut requerido"),
    comuna: Yup.string().required("Comuna requerida"),
    direccion: Yup.string().required("Dirección requerida"),
    precioFijoFinal: Yup.number()
      .min(0, "El precio no puede ser menor a $0")
      .required("Precio mayor o igual a 0 "),
    precioFinal: Yup.number()
      .min(0, "El precio no puede ser menor a $0")
      .required("Precio mayor o igual a 0 "),
    descuentoFinal: Yup.number()
      .min(0, "El descuento no puede ser menor a 0")
      .max(100, "El descuento no puede ser mayor a 100")
      .required("Descuento mayor o igual a 0"),
    tipoMonedaFinal: Yup.string().required("Tipo de moneda requerida"),
    frecuencia: Yup.number()
      .min(1, "La frecuencia no puede ser menor a 1")
      .max(10000, "La frecuencia no puede ser mayor a 10000")
      .required("Frecuencia debe ser mayor o igual a 1"),
    tipoFrecuencia: Yup.string().required("Tipo de frecuencia requerida"),
    frecuenciaDesc: Yup.number()
      .min(1, "La frecuencia no puede ser menor a 1")
      .max(10000, "La frecuencia no puede ser mayor a 10000"),
    tipoFrecuenciaDesc: Yup.string(),
    plan: Yup.string()
      .test(
        "Plan test",
        "El Plan ya fue agregado con el RUT ingresado.",
        (value) => !isBusyPlan(value)
      )
      .required("Debe seleccionar un plan"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      nombre: "",
      rut: "",
      telefono: "",
      direccion: "",
      comuna: "",
      contacto: "",
      precioFijoFinal: 0,
      descuentoFinal: 0,
      precioFinal: 0,
      tipoMonedaFinal: "",
      frecuencia: 1,
      tipoFrecuencia: "MES",
      frecuenciaDesc: 1,
      tipoFrecuenciaDesc: "MES",
      plan: "",
      mesCargaTareas: "",
      remember: false,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      updateDataEmpresa([
        ...dataEmpresa,
        {
          email: values.email,
          nombre: upperCase(values.nombre),
          rut: formatRut(values.rut),
          telefono: values.telefono,
          direccion: upperCase(values.direccion),
          comuna: values.comuna,
          contacto: values.contacto,
          plan: {
            ...dataPlan,
            tipoMonedaFinal: values.tipoMonedaFinal,
            precioFijoFinal: values.precioFijoFinal,
            descuentoFinal: values.descuentoFinal,
            precioFinal: values.precioFinal,
            frecuencia: values.frecuencia,
            tipoFrecuencia: values.tipoFrecuencia,
            frecuenciaDesc: values.frecuenciaDesc,
            tipoFrecuenciaDesc: values.tipoFrecuenciaDesc,
            mesCargaTareas: values.mesCargaTareas,
          },
          encargados: {
            rutEmpleado: values.rutEmpleado,
            rutSupervisor: values.rutSupervisor,
          },
        },
      ]);
      updateDataPlan({});
    },
  });

  const [dataPlan, setDataPlan] = useState({});
  const [labelChecked, setLabelChecked] = useState("Cliente Existente");
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [comunaSelect, setComunaSelect] = useState(null);
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const [checked, setChecked] = useState(null);
  const [clientes, setClientes] = useState([]);

  const { data: comunas = [] } = ajustesApi.useGetComunasQuery();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateDataPlan = (data) => {
    setDataPlan({ ...data });
  };

  const isBusyRut = (value) => {
    return (
      dataEmpresa.find(
        (item) =>
          item.rut === formatRut(value) &&
          item.plan.nombre === (dataPlan?.nombre ? dataPlan?.nombre : "")
      ) !== undefined
    );
  };

  const isBusyPlan = (value) => {
    return (
      dataEmpresa.find(
        (item) =>
          item.plan.nombre === value &&
          item.rut === formatRut(clienteSeleccionado?.rut)
      ) !== undefined
    );
  };

  useEffect(() => {
    let clientesAUX = [];
    async function obtenerClientes() {
      clientesAUX = await obtenerClientesSucursal();
      if (isMounted.current) {
        setClientes(clientesAUX);
      }
    }
    obtenerClientes();
  }, []);

  useEffect(() => {
    if (dataEmpresa.length > 0) {
      const empresa = dataEmpresa[0];
      const clienteSelected = clientes.find(
        (cliente) => cliente.rut === cleanRut(empresa.rut)
      );
      if (clienteSelected) {
        setChecked(true);
        setClienteSeleccionado(clienteSelected);
        setFieldValue("rut", clienteSelected.rut);
        setFieldValue("nombre", clienteSelected.nombre);
        setFieldValue("email", clienteSelected.correo);
        setFieldValue("telefono", clienteSelected.telefono);
        setFieldValue("comuna", clienteSelected.comuna);
        setComunaSelect({
          nombre: clienteSelected.comuna,
          value: clienteSelected.comuna,
        });
        setFieldValue("direccion", clienteSelected.direccion);
        setFieldValue("contacto", clienteSelected.contacto);
      } else {
        setChecked(false);
        setClienteSeleccionado(null);
        setFieldValue("rut", empresa.rut);
        setFieldValue("nombre", empresa.nombre);
        setFieldValue("email", empresa.correo);
        setFieldValue("telefono", empresa.telefono);
        setFieldValue("comuna", empresa.comuna);
      }
    }
  }, [clientes, dataEmpresa, setFieldValue]);

  const handleChange = (event) => {
    if (checked) {
      setClienteSeleccionado({ rut: "" });
      setComunaSelect(null);
      setLabelChecked("Cliente Nuevo");
    } else {
      setLabelChecked("Cliente Existente");
    }
    setFieldValue("rut", "");
    setFieldValue("nombre", "");
    setFieldValue("email", "");
    setFieldValue("telefono", "");
    setFieldValue("comuna", "");
    setFieldValue("direccion", "");
    setFieldValue("contacto", "");
    setComunaSelect(null);

    setChecked(event.target.checked);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Empresa</Typography>
        <RootStyle>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange} />
                        }
                        label={labelChecked}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                      {checked ? (
                        <Autocomplete
                          value={clienteSeleccionado || null}
                          disablePortal
                          options={clientes}
                          type="rut"
                          name="rut"
                          isOptionEqualToValue={(option) => option.rut}
                          getOptionLabel={(option) => option.rut}
                          onChange={(event, newValue) => {
                            if (
                              newValue !== null &&
                              newValue.rut !== clienteSeleccionado?.rut
                            ) {
                              setClienteSeleccionado(newValue);
                              setFieldValue("rut", newValue.rut);
                              setFieldValue("nombre", newValue.nombre);
                              setFieldValue("email", newValue.correo);
                              setFieldValue("telefono", newValue.telefono);
                              setFieldValue("comuna", newValue.comuna);
                              setComunaSelect({
                                nombre: newValue.comuna,
                                value: newValue.comuna,
                              });
                              setFieldValue("direccion", newValue.direccion);
                              setFieldValue("contacto", newValue.contacto);
                            } else {
                              setFieldValue("rut", "");
                              setFieldValue("nombre", "");
                              setFieldValue("email", "");
                              setFieldValue("telefono", "");
                              setFieldValue("comuna", "");
                              setComunaSelect(null);
                              setFieldValue("direccion", "");
                              setFieldValue("contacto", "");
                              setClienteSeleccionado({ rut: "" });
                            }
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              type="rut"
                              name="rut"
                              label="Rut Empresa"
                              required
                              onChange={formik.handleChange}
                              {...getFieldProps("rut")}
                              error={Boolean(touched.rut && errors.rut)}
                              helperText={touched.rut && errors.rut}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          required
                          fullWidth
                          // autoComplete="username"
                          type="rut"
                          name="rut"
                          label="Rut Empresa"
                          {...getFieldProps("rut")}
                          error={Boolean(touched.rut && errors.rut)}
                          helperText={touched.rut && errors.rut}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                      <TextField
                        required
                        fullWidth
                        // autoComplete="username"
                        type="nombre"
                        label="Nombre Empresa"
                        onChange={formik.handleChange}
                        {...getFieldProps("nombre")}
                        error={Boolean(touched.nombre && errors.nombre)}
                        helperText={touched.nombre && errors.nombre}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={5}>
                  <TextField
                    required
                    fullWidth
                    // autoComplete="username"
                    type="email"
                    label="Correo"
                    onChange={formik.handleChange}
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={8} sm={6}>
                  {" "}
                  <TextField
                    type="telefono"
                    label="Telefono"
                    onChange={formik.handleChange}
                    {...getFieldProps("telefono")}
                    error={Boolean(touched.telefono && errors.telefono)}
                    helperText={touched.telefono && errors.telefono}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={10} sm={5} md={3}>
                      <Autocomplete
                        disablePortal
                        value={comunaSelect}
                        isOptionEqualToValue={(option) => option.nombre}
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setFieldValue("comuna", newValue.nombre);
                            setComunaSelect(newValue);
                          } else {
                            setFieldValue("comuna", "");
                            setComunaSelect(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-Comunas"
                        options={comunas}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            {...params}
                            type="comuna"
                            label="Comuna"
                            {...getFieldProps("comuna")}
                            error={Boolean(touched.comuna && errors.comuna)}
                            helperText={touched.comuna && errors.comuna}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7} md={6}>
                      <TextField
                        required
                        fullWidth
                        // autoComplete="username"
                        type="direccion"
                        label="Dirección"
                        onChange={(event) => formik.handleChange}
                        {...getFieldProps("direccion")}
                        error={Boolean(touched.direccion && errors.direccion)}
                        helperText={touched.direccion && errors.direccion}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <h3>Seleccionar Plan</h3>
                </Grid>
                <FormPlan
                  actualizarDataPlan={updateDataPlan}
                  dataPlan={dataPlan}
                  formik={formik}
                />

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingButton
                      size="large"
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      AGREGAR
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </RootStyle>
      </CardContent>
    </Card>
  );
};
