import React, { useEffect, useState } from "react";
import { formatRut } from "@fdograph/rut-utilities";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Grid,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import * as locales from "@material-ui/core/locale";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import Scrollbar from "../Scrollbar";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import AgregarSocio from "./AgregarSocio";
import DeleteIcon from "@material-ui/icons/Delete";
import MyConfirmationDialog from "../MyConfirmationDialog";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function Plan(props) {
  const { value, setValue, cliente, setShowMessage, setColor, setMessage } =
    props;
  const classes = styles();
  const [locale] = useState("esES");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [openAgregarSocio, setOpenAgregarSocio] = useState(false);
  const [socios, setSocios] = useState([]);
  const [elementoSeleccionado, setElementoSeleccionado] = useState({});
  const [deleteMessage, setDeleteMessage] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/socios/${cliente.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setSocios(response.data);
      });
  }, [cliente.rut]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/clientes/socios/${cliente.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setSocios(response.data);
      });
  };

  const eliminar = (datosSocio) => {
    axios
      .delete(`${API_SERVER}/clientes/socios/${cliente.rut}/${datosSocio.rutCliente}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        actualizar();
        setDeleteMessage(false);
      });
  };

  const redireccionar = (rutCliente) => {
    setValue(0);
    navigate("/dashboard/clientes/" + rutCliente);
  };
  return (
    <div className={classes.pos}>
      {value === 2 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                >
                  Socios
                </Typography>
              </Grid>
              <Grid item xs={12} align="end">
                <Button
                  onClick={() => {
                    setOpenAgregarSocio(true);
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Agregar Socio
                </Button>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Rut</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {socios &&
                      socios
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((element, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                onClick={() =>
                                  redireccionar(element.rutCliente)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {element.nombreCliente}
                              </TableCell>
                              <TableCell
                                align="center"
                                onClick={() =>
                                  redireccionar(element.rutCliente)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {formatRut(element.rutCliente)}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  color="error"
                                  onClick={() => {
                                    setElementoSeleccionado(element);
                                    setDeleteMessage(true);
                                  }}
                                >
                                  <DeleteIcon /> Eliminar
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </CardActions>
          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={socios.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      )}

      {openAgregarSocio && (
        <AgregarSocio
          open={openAgregarSocio}
          setOpen={setOpenAgregarSocio}
          setSocios={setSocios}
          refCliente={cliente.rut}
          setShowMessage={setShowMessage}
          setColor={setColor}
          setMessage={setMessage}
        />
      )}
      {deleteMessage && (
        <MyConfirmationDialog
          open={deleteMessage}
          onSubmit={() => eliminar(elementoSeleccionado)}
          onClose={() => setDeleteMessage(false)}
          title="Eliminar Socio"
          content={`¿Estás seguro de eliminar el socio ${elementoSeleccionado.nombreCliente} de rut ${elementoSeleccionado.rutCliente}?`}
        />
      )}
    </div>
  );
}
