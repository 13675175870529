import React, { useEffect, useState, useRef } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { Container, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import arrowBack from "@iconify/icons-carbon/arrow-left";
import { empleadosApi } from "src/services/empleadosApi";

const styles = makeStyles(() => ({
  container: {
    marginTop: 100,
  },
  button: {
    marginTop: 40,
  },
}));

export default function GraficoTareasEmpleado() {
  const classes = styles();
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const { id } = useParams();
  const [tareas, setTareas] = useState([]);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: id,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [id]);

  const [grafico, setGrafico] = useState({
    series: [
      {
        name: "Tareas Pendientes",
        data: tareas,
      },
    ],
    options: {
      annotations: {
        points: [{}],
      },
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "10%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },

      grid: {
        row: {
          colors: ["#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        title: {
          text: "Tipos De Tareas",
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        categories: ["INTERNA", "ESPECIAL", "MES"],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Cantidad Tareas Pendientes",
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },

      title: {
        text: "Tareas Pendientes",
        align: "center",
        margin: 10,
        style: {
          fontSize: "32px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
    },
  });

  const obtenerCategorias = (tareas) => {
    const t = tareas.map((t) => t.tipoTarea);
    return [...new Set(t)];
  };

  const obtenerDatos = (tareas) => {
    const counts = {};

    const contarTareas = tareas.map((t) => {
      if (t.fechaTermino === "") {
        return t.tipoTarea;
      } else {
        return "TERMINADA";
      }
    });

    for (const num of contarTareas) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    const t = tareas.map((t) => t.tipoTarea);
    const tipos = [...new Set(t)];
    let resultado = [];
    tipos.forEach((tipo) => {
      if (counts[tipo] !== undefined) {
        resultado.push(counts[tipo]);
      } else {
        resultado.push(0);
      }
    });
    return resultado;
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tareas/empleadoSinSucursal/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setTareas(response.data);

          setGrafico((grafico) => ({
            ...grafico,
            series: [
              {
                ...grafico.series,
                data: obtenerDatos(response.data),
              },
            ],
          }));

          setGrafico((grafico) => ({
            ...grafico,
            options: {
              ...grafico.options,
              xaxis: {
                ...grafico.options.xaxis,
                categories: obtenerCategorias(response.data),
              },
            },
          }));
        }
      });
  }, [id, grafico.xaxis]);

  return (
    <Container className={classes.container}>
      <ReactApexChart
        options={grafico.options}
        series={grafico.series}
        type="bar"
        height={500}
      />
      <Button
        size="large"
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<Icon icon={arrowBack} />}
        onClick={() => navigate(`/dashboard/graficoTareas/`)}>
        Volver
      </Button>
    </Container>
  );
}
