import React, { useEffect, useState, useRef } from "react";
import { lowerCase, startCase } from "lodash";
import {
  Card,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  CardContent,
  FormControl,
  Autocomplete,
  TextField,
  Button,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";

import { API_SERVER } from "../../utils/urlBack_End";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hidden: {
    display: "none",
  },
});

// ----------------------------------------------------------------------

export default function EmpleadosAsociados({ tarea }) {
  const classes = useStyles();
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);

  const
    {
      data: empleadosAsociados = [],
    } = empleadosApi.useGetEmpleadosTareaQuery(tarea.id);

  const [agregarEmpleadoTarea] = empleadosApi.useAddEmpleadosTareaMutation();
  const [eliminarEmpleadosTarea] = empleadosApi.useDeleteEmpleadosTareaMutation();
  const [rutEmpleadoAdicional, setRutEmpleadoAdicional] = useState("");

  const [empleados, setEmpleados] = useState([]);
  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);
  const [empleadoAdicionalSelect, setEmpleadoAdicionalSelect] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios.get(
      `${API_SERVER}/empleados/empleadoSucursal/${sucursalObtenida.refSucursal}/ALL`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setEmpleados(response.data);
        }
      });
  }, [sucursalObtenida.refSucursal]);

  useEffect(() => {
    if (isMounted.current) {
      setEmpleadoOptions(
        empleados.map((item) => {
          return {
            label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
            value: item.rut,
          };
        })
      );
    }
  }, [empleados]);

  return (
    <Container>
      <RootStyle>
        {(rolObtenido === "ADMIN" ||
          rolObtenido === "SUPER_ADMIN" ||
          tarea.supervisor === user.rut) && (
            <Card>
              {tarea.estado &&
                (tarea.estado === "TERMINADA" || tarea.estado === "FACTURADO") ? (
                <></>
              ) : (
                <CardContent>
                  <Grid container>
                    <Grid item xs={10} md={10}>
                      <FormControl fullWidth style={{ marginTop: "30px" }}>
                        <Autocomplete
                          disabled={!autorizado}
                          value={empleadoAdicionalSelect}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setRutEmpleadoAdicional(
                                empleados.find(
                                  (empleado) => empleado.rut === newValue.value
                                )
                              );
                              setEmpleadoAdicionalSelect(newValue);
                            } else {
                              setRutEmpleadoAdicional("");
                              setEmpleadoAdicionalSelect(null);
                            }
                          }}
                          fullWidth
                          id="combo-box-empleados"
                          options={empleadoOptions}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              type="rut"
                              label="Empleados Adicionales"
                              required
                              value={rutEmpleadoAdicional}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} md={2} style={{ marginTop: "30px" }}>
                      <Button
                        fullWidth
                        style={{ height: "100%" }}
                        variant="outlined"
                        onClick={() => {
                          if (tarea.supervisor === rutEmpleadoAdicional.rut) {
                            AlertaToast({
                              type: 'error',
                              action: 'El empleado a agregar es supervisor',
                              fullText: true,
                            }).fire()
                          } else if (
                            tarea.refEmpleado === rutEmpleadoAdicional.rut
                          ) {
                            AlertaToast({
                              type: 'error',
                              action: 'El empleado a agregar es el encargado de la tarea',
                              fullText: true,
                            }).fire()
                          } else {
                            let existe = empleadosAsociados.filter(
                              (e) => e.rut === rutEmpleadoAdicional.rut
                            )[0];
                            if (existe) {
                              AlertaToast({
                                type: 'error',
                                action: 'El empleado a agregar ya se encuentra.',
                                fullText: true,
                              }).fire()
                            } else {
                              agregarEmpleadoTarea({
                                idTarea: tarea.id,
                                empleadoObtenido: rutEmpleadoAdicional.rut,
                              })
                                .unwrap()
                                .then(() => {
                                  setEmpleadoAdicionalSelect(null)
                                  AlertaToast({
                                    element: 'Empleado',
                                    action: 'agregado',
                                  }).fire()
                                }).catch(() => {
                                  AlertaToast({
                                    type: 'error',
                                    action: 'Error en el servidor',
                                    fullText: true,
                                  }).fire()
                                })
                            }
                          }
                        }}
                      >
                        <AddIcon /> Agregar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          )}
        <Card style={{ padding: "1rem" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre Empleado</TableCell>
                  {(rolObtenido === "ADMIN" ||
                    rolObtenido === "SUPER_ADMIN") && <TableCell></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {empleadosAsociados &&
                  empleadosAsociados.map((row, idx) => (
                    <TableRow key={"empleadosAsociados_" + idx}>
                      <TableCell component="th" scope="row">
                        {row.nombreEmpleado + " " + row.apellidosEmpleado}
                      </TableCell>
                      {(rolObtenido === "ADMIN" ||
                        rolObtenido === "SUPER_ADMIN") && (
                          <TableCell>
                            <Button
                              disabled={tarea.estado && (tarea.estado === "TERMINADA" || tarea.estado === "FACTURADO")}
                              color="error"
                              onClick={() => {
                                AlertaModal({
                                  title: 'eliminar el empleado',
                                  confirmButtonText: 'Sí, eliminar',
                                })
                                  .fire()
                                  .then((result) => {

                                    if (result.isConfirmed) {
                                      eliminarEmpleadosTarea(row.id)
                                        .then(() => {
                                          AlertaToast({
                                            element: 'Empleado',
                                            action: 'eliminado',
                                          }).fire()
                                        }).catch(() => {
                                          AlertaToast({
                                            action: 'Error en el servidor',
                                            fullText: true,
                                          }).fire()
                                        })
                                    }
                                  })
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </TableCell>
                        )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </RootStyle>
    </Container>
  );
}
