import { useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import useChart from "../charts/use-chart";
import { fCurrency, fPercent, fShortenNumber } from "src/utils/formatNumber";
import Iconify from "../iconify";
import { Box, Card, Skeleton, Stack, Typography } from "@material-ui/core";
import Chart from "../charts/chart";

AppWidgetFacturacion.propTypes = {
  sx: PropTypes.object,
  chart: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  percent: PropTypes.number,
  loading: PropTypes.bool,
  cursor: PropTypes.string,
  mostrarProyeccion: PropTypes.bool,
  mostrarTrendingInfo: PropTypes.bool,
  mostrarDetalleFacturacion: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  iconTrending: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

export default function AppWidgetFacturacion({
  title,
  total,
  icon,
  iconTrending,
  percent,
  color = "primary",
  chart,
  sx,
  loading,
  cursor = "default",
  mostrarDetalleFacturacion = null,
  mostrarProyeccion = false,
  mostrarTrendingInfo = false,
  ...other
}) {
  const theme = useTheme();

  const { series, label, options } = chart;

  const chartOptions = useChart({
    colors: [theme.palette[color].main],
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      categories: label,
      labels: { show: true },
    },
    yaxis: {
      labels: { show: false },
    },
    stroke: {
      width: 4,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (value) => fCurrency(value),
        title: {
          formatter: (value, { seriesIndex, dataPointIndex, w }) =>
            `${label[dataPointIndex]}:`,
        },
      },
    },
    annotations: {
      xaxis: [
        mostrarProyeccion
          ? {
              x: label.slice(-3)[0],
              x2: label.slice(-1)[0],
              fillColor: "#B3F7CA",
              borderColor: "#00AB55",
              strokeDashArray: 8,
              label: {
                text: "Proyección",
              },
            }
          : {},
      ],
    },
    fill: {
      gradient: {
        opacityFrom: 0.56,
        opacityTo: 0.56,
      },
    },
    ...options,
  });

  if (loading) {
    return (
      <Card
        sx={{
          width: 1,
          boxShadow: 0,
          color: theme.palette[color].darker,
          bgcolor: theme.palette[color].lighter,
          ...sx,
        }}
        {...other}
      >
        <Skeleton variant="rectangular" height={70} />

        <Stack spacing={1} sx={{ p: 3 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Stack>

        <Skeleton variant="rectangular" height={70} />
      </Card>
    );
  }

  // Determina si se debe mostrar el TrendingInfo
  const trendingInfoComponent = mostrarTrendingInfo ? (
    <TrendingInfo percent={percent} icon={iconTrending} />
  ) : null;

  return (
    <Card
      onClick={mostrarDetalleFacturacion}
      sx={{
        width: 1,
        height: 1,
        boxShadow: 0,
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        cursor: cursor,
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          p: 1.5,
          top: 24,
          right: 24,
          width: 48,
          height: 48,
          borderRadius: "50%",
          position: "absolute",
          color: theme.palette[color].lighter,
          bgcolor: theme.palette[color].dark,
        }}
      />

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="h3">{`$${fShortenNumber(total)}`}</Typography>

        {/* Contenedor para el TrendingInfo con altura fija */}
        <div style={{ height: mostrarTrendingInfo ? "auto" : "22px" }}>
          {trendingInfoComponent}
        </div>
      </Stack>

      <Chart
        type="area"
        series={[{ data: series }]}
        options={chartOptions}
        height={120}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

TrendingInfo.propTypes = {
  percent: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

function TrendingInfo({ percent, icon }) {
  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={0.5}>
      <Iconify icon={icon} />

      <Typography variant="subtitle2" component="span">
        {percent > 0 && "+"}

        {fPercent(percent)}

        <Box component="span" sx={{ opacity: 0.72, typography: "body2" }}>
          {" comparado con el mes anterior"}
        </Box>
      </Typography>
    </Stack>
  );
}
