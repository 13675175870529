import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const clientesApi = createApi({
  reducerPath: "clientesApi",
  baseQuery: baseQuery(`${API_SERVER}/clientes`),
  tagTypes: ["Clientes", "clientesPorGrupo", "clientesSinGrupo"],
  endpoints: (builder) => ({
    getClientes: builder.query({
      query: ({ estado = "activo" } = {}) => ({
        url: "/",
        method: "GET",
        params: { estado },
      }),
      providesTags: ["Clientes"],
    }),
    getCliente: builder.query({
      query: ({ rut }) => ({
        url: `/${rut}`,
        method: "GET",
      }),
    }),
    getNuevosClientesIngresadosPorMes: builder.query({
      query: ({ fecha, tipo }) => ({
        url: `/nuevosClientesIngresadosPorMes/${tipo}`,
        method: "GET",
        params: { fecha },
      }),
    }),
    cantidadClientesActivos: builder.query({
      query: () => `/cantidadClientesActivos`,
    }),
    obtenerDetalleDivisionClientes: builder.query({
      query: (tipo) => `/detalleDivisionClientes/${tipo}`,
    }),
    obtenerDistribucionDeClientes: builder.query({
      query: () => `/distribucionDeClientes`,
    }),
    obtenerClientesPorDivision: builder.query({
      query: () => `/obtenerClientesPorDivision`,
    }),
    ultimosClientesGestor: builder.query({
      query: () => `/ultimosClientesGestor`,
    }),
    obtenerClientesPorGrupo: builder.query({
      query: () => `/clientesPorGrupo`,
      providesTags: ["clientesPorGrupo"],
    }),
    obtenerClientesSinGrupo: builder.query({
      query: () => `/clientesSinGrupo`,
      providesTags: ["clientesSinGrupo"],
    }),
    getClientesEmpleado: builder.query({
      query: ({ rut, sucursal }) => `/empleado/${rut}/${sucursal}`,
      providesTags: ["Clientes"],
    }),
    addCliente: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Clientes"],
    }),
    editarCliente: builder.mutation({
      query: ({ rut, datos }) => ({
        url: `/${rut}`,
        method: "PUT",
        body: { datos },
      }),
      invalidatesTags: ["Clientes"],
    }),
    addAsociarGrupos: builder.mutation({
      query: (body) => ({
        url: `/asociarGrupos`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["clientesSinGrupo"],
    }),
    actualizarOrganigramaGrupo: builder.mutation({
      query: (body) => ({
        url: `/clientesPorGrupo/actualizarOrganigramaGrupo`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["clientesPorGrupo"],
    }),
    asociarOrganigramaGrupo: builder.mutation({
      query: (body) => ({
        url: `/clientesPorGrupo/asociarOrganigramaGrupo`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["clientesPorGrupo"],
    }),
  }),
});

export const {
  useGetClientesEmpleadoQuery,
  useCantidadClientesActivosQuery,
  useObtenerClientesPorDivisionQuery,
  useObtenerDetalleDivisionClientesQuery,
  useGetNuevosClientesIngresadosPorMesQuery,
  useObtenerClientesPorGrupoQuery,
  useUltimosClientesGestorQuery,
  useObtenerDistribucionDeClientesQuery,
  useObtenerClientesSinGrupoQuery,
  useLazyObtenerClientesSinGrupoQuery,
  useLazyObtenerClientesPorGrupoQuery,
  useLazyGetNuevosClientesIngresadosPorMesQuery,
  useLazyObtenerDetalleDivisionClientesQuery,
  useLazyObtenerDistribucionDeClientesQuery,
  useLazyObtenerClientesPorDivisionQuery,
  useLazyUltimosClientesGestorQuery,
  useLazyCantidadClientesActivosQuery,
  useGetClientesQuery,
  useGetClienteQuery,
  useLazyGetClienteQuery,
  useLazyGetClientesEmpleadoQuery,
  useLazyGetClientesQuery,
  useAddClienteMutation,
  useEditarClienteMutation,
  useAsociarOrganigramaGrupoMutation,
  useActualizarOrganigramaGrupoMutation,
  useAddAsociarGruposMutation,
} = clientesApi;
