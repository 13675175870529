import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { lowerCase, startCase } from "lodash";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Step,
  StepLabel,
  Stepper,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  FormLabel,
  InputAdornment,
  Stack,
  Container,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { round } from "lodash";
import { useSelector } from "react-redux";

const styles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
    marginTop: 60,
  },
  boton: {
    maxHeight: "3.2rem",
    minWidth: "100%",
    marginTop: 17,
  },
  selectMenu: {
    minWidth: "100%",
    marginBottom: 12,
  },
  clientes: {
    minWidth: "100%",
  },
}));

export default function ModalTareaMasiva(props) {
  const {
    agregarTarea,
    setAgregarTarea,
    setTareaMasiva,
    datosTareaMasiva,
    setDatosTareaMasiva,
    empleados,
    monedas,
    setColor,
    setMessage,
    setAlerta,
  } = props;

  const [expandir, setExpandir] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [tipoTareas, setTipoTareas] = useState([]);
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  const [nombreCliente, setNombreCliente] = useState("");
  const [vendedor, setVendedor] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [clientesEmpleado, setClientesEmpleado] = useState([]);
  const steps = ["Vendedor", "Cliente", "Tarea", "Nota", "Descuento"];
  const [activeStep, setActiveStep] = useState(0);

  const [nombreTareaSeleccionada, setNombreTareaSeleccionada] = useState(null);
  const [nombreTarea, setNombreTarea] = useState("");
  const [valorTarea, setValorTarea] = useState("");
  const [tipoMonedaTarea, setTipoMonedaTarea] = useState("");
  const [descripcionTarea, setDescripcionTarea] = useState("");
  const [rutEmpleado, setRutEmpleado] = useState("");
  const [rutSupervisor, setRutSupervisor] = useState("");

  const [comisionVendedor, setComisionVendedor] = useState(0);
  const [comisionSupervisor, setComisionSupervisor] = useState(0);
  const [comisionEmpleado, setComisionEmpleado] = useState(0);

  const [labelChecked2, setLabelChecked2] = useState("Tarea Existente");
  const [checked2, setChecked2] = useState(true);
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [descuentoAdicional, setDescuentoAdicional] = useState(0);
  const classes = styles();
  const {
    empleado: usuario,
    sucursal: sucursalObtenida,
    rol: rolObtenido
  } = useSelector((state) => state.auth.user);

  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);
  const [supervisorSelect, setSupervisorSelect] = useState(null);
  const [empleadoPrincipalSelect, setEmpleadoPrincipalSelect] = useState(null);

  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClientes(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tipos/tipoTareas/ESPECIAL`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setTipoTareas(response.data);
      });
  }, []);

  useEffect(() => {
    if (rolObtenido === "EMPLEADO") {
      axios
        .get(`${API_SERVER}/planes/empleado/${sucursalObtenida.refSucursal}/${usuario.rut}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
          }
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          let grupo = {
            nombre: "Clientes de: " + usuario.nombre + " " + usuario.apellidos,
            rut: usuario.rut,
            info: response.data,
          };
          setClientesEmpleado((clientesEmpleado) => [
            ...clientesEmpleado,
            grupo,
          ]);
        });
    } else {
      if (
        empleados.length > 0 &&
        clientesEmpleado.length === 0 &&
        clientes.length > 0
      ) {
        let grupo = {
          nombre: "Todos los clientes",
          rut: usuario.rut,
          info: clientes,
        };
        setClientesEmpleado((clientesEmpleado) => [...clientesEmpleado, grupo]);
        Promise.all(
          empleados.map((empleado) => {
            axios
              .get(`${API_SERVER}/planes/empleado/${sucursalObtenida.refSucursal}/${empleado.rut}`, {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("token")
                }
              })
              .then(function (response) {
                if (response.data === -2) window.location.reload();
                let grupo = {
                  nombre:
                    "Clientes de: " +
                    empleado.nombre +
                    " " +
                    empleado.apellidos,
                  rut: empleado.rut,
                  info: response.data,
                };
                setClientesEmpleado((clientesEmpleado) => [
                  ...clientesEmpleado,
                  grupo,
                ]);
              });
            return false;
          })
        );
      }
    }
  }, [
    clientesEmpleado,
    empleados,
    usuario.apellidos,
    usuario.rut,
    usuario.nombre,
    rolObtenido,
    sucursalObtenida.refSucursal,
    clientes,
  ]);

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    const buscarEmpleado = () => {
      let empleado = null;
      let supervisor = null;
      if (rutEmpleado !== "" && empleadoOptions.length > 0) {
        empleado = empleadoOptions.find((item) => item.value === rutEmpleado);

        setEmpleadoPrincipalSelect(empleado !== undefined ? empleado : null);

        const supervisores =
          empleado !== undefined
            ? empleadoOptions.filter((item) => item.value !== empleado.value)
            : [];

        setSupervisorOptions([...supervisores]);
      }

      if (rutSupervisor !== "" && empleadoOptions.length > 0) {
        supervisor = empleadoOptions.find(
          (item) => item.value === rutSupervisor
        );
        setSupervisorSelect(supervisor !== undefined ? supervisor : null);
      }
    };
    if (supervisorSelect === null && empleadoPrincipalSelect === null)
      buscarEmpleado();
  }, [
    empleadoOptions,
    empleadoPrincipalSelect,
    supervisorSelect,
    rutEmpleado,
    rutSupervisor,
  ]);

  const handleChangeNombreTarea = (event) => {
    setNombreTarea(event.target.value);
  };
  const agregarDatos = () => {
    let tarea = [];
    let datosTarea = [];
    if (cliente.info === undefined) {
      tarea = {
        refCliente: cliente.rut,
        refEmpleado: rutEmpleado,
        nombreCliente: nombreCliente.toUpperCase(),
        nombre: nombreTarea.toUpperCase(),
        sucursal: sucursalObtenida.refSucursal.toUpperCase(),
        descripcionTarea: descripcionTarea.toUpperCase(),
        vendedor: vendedor.nombre + " " + vendedor.apellidos,
        descuento: descuentoAdicional,
        valorTarea: valorTarea,
        tipoMonedaTarea: tipoMonedaTarea.toUpperCase(),
        supervisor: rutSupervisor,
        total:
          parseFloat(valorTarea) *
          ((100 - parseFloat(descuentoAdicional)) / 100),
      };

      datosTarea = {
        refCliente: cliente.rut,
        refEmpleado: rutEmpleado,
        nombre: nombreTarea.toUpperCase(),
        sucursal: sucursalObtenida.refSucursal.toUpperCase(),
        descripcionTarea: descripcionTarea.toUpperCase(),
        vendedor: vendedor.nombre + " " + vendedor.apellidos,
        descuento: descuentoAdicional,
        valorTarea: valorTarea,
        tipoMonedaTarea: tipoMonedaTarea.toUpperCase(),
        supervisor: rutSupervisor,
        comisionEmpleado: comisionEmpleado,
        comisionVendedor: comisionVendedor,
        comisionSupervisor: comisionSupervisor,
      };
      setTareaMasiva((tareaMasiva) => [...tareaMasiva, tarea]);
      setDatosTareaMasiva([...datosTareaMasiva, datosTarea]);
    } else {
      cliente.info.map((infoCliente) => {
        tarea = [
          ...tarea,
          {
            refCliente: infoCliente.refCliente
              ? infoCliente.refCliente
              : infoCliente.rut,
            nombreCliente: infoCliente.nombre
              ? infoCliente.nombre
              : infoCliente.nombreCliente,
            refEmpleado: rutEmpleado,
            nombre: nombreTarea.toUpperCase(),
            sucursal: sucursalObtenida.refSucursal.toUpperCase(),
            descripcionTarea: descripcionTarea.toUpperCase(),
            vendedor: vendedor.nombre + " " + vendedor.apellidos,
            descuento: descuentoAdicional,
            valorTarea: valorTarea,
            tipoMonedaTarea: tipoMonedaTarea.toUpperCase(),
            supervisor: rutSupervisor,
            total:
              parseFloat(valorTarea) *
              ((100 - parseFloat(descuentoAdicional)) / 100),
          },
        ];
        datosTarea = [
          ...datosTarea,
          {
            refCliente: infoCliente.refCliente
              ? infoCliente.refCliente
              : infoCliente.rut,
            refEmpleado: rutEmpleado,
            nombre: nombreTarea.toUpperCase(),
            sucursal: sucursalObtenida.refSucursal.toUpperCase(),
            descripcionTarea: descripcionTarea.toUpperCase(),
            vendedor: vendedor.nombre + " " + vendedor.apellidos,
            descuento: descuentoAdicional,
            valorTarea: valorTarea,
            tipoMonedaTarea: tipoMonedaTarea.toUpperCase(),
            supervisor: rutSupervisor,
            comisionEmpleado: comisionEmpleado,
            comisionVendedor: comisionVendedor,
            comisionSupervisor: comisionSupervisor,
          },
        ];
        return false;
      });

      setTareaMasiva((tareaMasiva) => [...tareaMasiva, ...tarea]);
      setDatosTareaMasiva((datosTareaMasiva) => [
        ...datosTareaMasiva,
        ...datosTarea,
      ]);
    }
    setVendedor(null);
    setCliente(null);
    setRutEmpleado("");
    setNombreTarea("");
    setDescripcionTarea("");
    setValorTarea("");
    setNombreEmpleado("");
    setNombreCliente("");
    setTipoMonedaTarea("");
    setRutSupervisor("");
    setDescuentoAdicional(0);
    setTotalAPagar(0);
    setNombreTareaSeleccionada(null);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (vendedor !== null) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setColor("info");
        setMessage("Debe seleccionar a un vendedor");
        setAlerta(true);
      }
    }
    if (activeStep === 1) {
      if (cliente !== null) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setColor("info");
        setMessage("Debe seleccionar a un cliente");
        setAlerta(true);
      }
    }
    if (activeStep === 2) {
      if (
        nombreTarea !== "" &&
        valorTarea !== "" &&
        tipoMonedaTarea !== "" &&
        rutEmpleado !== "" &&
        rutSupervisor !== ""
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setColor("info");
        setMessage("Debe completar los campos faltanes");
        setAlerta(true);
      }
    }
    if (activeStep === 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 4) {
      agregarDatos();
      setActiveStep(0);
      setAgregarTarea(false);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setVendedor(null);
      setCliente(null);
      setRutEmpleado("");
      setNombreTarea("");
      setDescripcionTarea("");
      setValorTarea("");
      setNombreEmpleado("");
      setNombreCliente("");
      setTipoMonedaTarea("");
      setRutSupervisor("");
      setDescuentoAdicional(0);
      setTotalAPagar(0);
      setNombreTareaSeleccionada(null);
      setAgregarTarea(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function formatNumber(n) {
    n = String(n).replace(/\D/g, "");
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  function verificarNumero(n) {
    n = String(n);
    let expreg = /^[0-9]+\.[0-9]*$|^[0-9]+$/;
    return expreg.test(n);
  }

  const handleChangValorTarea = (event) => {
    if (verificarNumero(event.target.value)) {
      setValorTarea(event.target.value);
      setTotalAPagar(event.target.value);
    } else {
      setValorTarea("");
      setTotalAPagar(0);
    }
  };

  const handleChangeTipoMoneda = (event) => {
    if (event.target.value === "PESOS" && String(valorTarea).includes(".")) {
      setValorTarea(String(valorTarea).replaceAll(".", ""));
    }
    setTipoMonedaTarea(event.target.value);
  };

  const handleChangeComisionVendedor = (event) => {
    if (
      event.target.value >= 0 &&
      event.target.value <= 100 &&
      parseFloat(event.target.value) +
      parseFloat(comisionEmpleado) +
      parseFloat(comisionSupervisor) <=
      100
    ) {
      setComisionVendedor(event.target.value);
    } else {
      if (isNaN(parseFloat(event.target.value))) {
        setComisionVendedor(event.target.value);
      } else {
        setColor("error");
        setMessage("La suma de las comisiones no puede ser mayor a 100");
        setAlerta(true);
        setComisionVendedor(0);
      }
    }
  };
  const handleChangeComisionEmpleado = (event) => {
    if (
      event.target.value >= 0 &&
      event.target.value <= 100 &&
      parseFloat(event.target.value) +
      parseFloat(comisionVendedor) +
      parseFloat(comisionSupervisor) <=
      100
    ) {
      setComisionEmpleado(event.target.value);
    } else {
      if (isNaN(parseFloat(event.target.value))) {
        setComisionEmpleado(event.target.value);
      } else {
        setColor("error");
        setMessage("La suma de las comisiones no puede ser mayor a 100");
        setAlerta(true);
        setComisionEmpleado(0);
      }
    }
  };
  const handleChangeComisionSupervisor = (event) => {
    if (
      event.target.value >= 0 &&
      event.target.value <= 100 &&
      parseFloat(event.target.value) +
      parseFloat(comisionVendedor) +
      parseFloat(comisionEmpleado) <=
      100
    ) {
      setComisionSupervisor(event.target.value);
    } else {
      if (isNaN(parseFloat(event.target.value))) {
        setComisionSupervisor(event.target.value);
      } else {
        setColor("error");
        setMessage("La suma de las comisiones no puede ser mayor a 100");
        setAlerta(true);
        setComisionSupervisor(0);
      }
    }
  };

  const handleChange2 = (event) => {
    if (checked2) {
      setNombreTareaSeleccionada(null);
      setLabelChecked2("Tarea Nueva");
    } else {
      setLabelChecked2("Tarea Existente");
    }
    setNombreTarea("");
    setValorTarea("");
    setTotalAPagar(0);
    setTipoMonedaTarea("");
    setDescripcionTarea("");
    setChecked2(event.target.checked);
  };

  const handleChangeDescripcionTarea = (event) => {
    setDescripcionTarea(event.target.value);
  };

  const handleChangeDescuentoAdicional = (event) => {
    let valor = valorTarea - valorTarea * event.target.value * 0.01;
    if (tipoMonedaTarea === "PESOS") {
    } else {
      valor = round(valor, 2);
    }
    setTotalAPagar(valor);
    setDescuentoAdicional(event.target.value);
  };

  const handleChangeSupervisores = (value) => {
    const supervisores = empleadoOptions.filter(
      (item) => item.value !== value.value
    );

    setSupervisorOptions([...supervisores]);
  };

  return (
    <>
      <Dialog
        open={agregarTarea}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">Nueva Tarea Remunerada</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", minHeight: expandir }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 ? (
              <React.Fragment>
                <Autocomplete
                  className={classes.formControl}
                  value={vendedor}
                  options={empleados}
                  isOptionEqualToValue={(option, value) =>
                    option.rut === value.rut
                  }
                  getOptionLabel={(option) =>
                    option.nombre + " " + option.apellidos
                  }
                  onChange={(event, newValue) => {
                    setVendedor(newValue);
                  }}
                  inputValue={nombreEmpleado}
                  onInputChange={(event, newInputValue) => {
                    setNombreEmpleado(newInputValue);
                  }}
                  fullWidth
                  ListboxProps={{
                    style: { maxHeight: "15rem" },
                  }}
                  onOpen={(e) => setExpandir(400)}
                  onClose={(e) => setExpandir(0)}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendedor Asignado" />
                  )}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 1 && (
                  <Stack className={classes.clientes}>
                    <Autocomplete
                      className={classes.formControl}
                      value={cliente}
                      disablePortal
                      options={clientesEmpleado
                        .filter((grupo) => grupo.info.length > 0)
                        .concat(clientes)}
                      isOptionEqualToValue={(option, value) =>
                        option.rut === value.rut &&
                        option.nombre === value.nombre
                      }
                      getOptionLabel={(option) => option.nombre}
                      onChange={(event, newValue) => {
                        setCliente(newValue);
                      }}
                      inputValue={nombreCliente}
                      onInputChange={(event, newInputValue) => {
                        setNombreCliente(newInputValue);
                      }}
                      fullWidth
                      onOpen={(e) => setExpandir(400)}
                      onClose={(e) => setExpandir(0)}
                      ListboxProps={{ style: { maxHeight: "15rem" } }}
                      renderInput={(params) => (
                        <TextField {...params} label="Seleccione Cliente" />
                      )}
                    />
                  </Stack>
                )}

                {activeStep === 2 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Tarea
                    </Typography>

                    <Stack>
                      <FormControlLabel
                        style={{ marginTop: "30px" }}
                        control={
                          <Switch checked={checked2} onChange={handleChange2} />
                        }
                        label={labelChecked2}
                      />
                    </Stack>

                    {checked2 ? (
                      <Autocomplete
                        style={{ marginTop: "30px" }}
                        value={nombreTareaSeleccionada}
                        disablePortal
                        options={tipoTareas}
                        isOptionEqualToValue={(option) => option.nombre}
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setNombreTareaSeleccionada(newValue);
                            setNombreTarea(newValue.nombre);
                            setValorTarea(newValue.valor);
                            setTotalAPagar(newValue.valor);
                            setTipoMonedaTarea(newValue.tipoMoneda);
                            setDescripcionTarea(newValue.descripcion);
                          } else {
                            setNombreTareaSeleccionada(null);
                            setNombreTarea("");
                            setValorTarea("");
                            setTotalAPagar(0);
                            setTipoMonedaTarea("");
                            setDescripcionTarea("");
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setNombreTarea(newInputValue);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Nombre Tarea" />
                        )}
                      />
                    ) : (
                      <TextField
                        style={{ marginTop: "30px" }}
                        fullWidth
                        required
                        label="Nombre Tarea"
                        value={nombreTarea}
                        onChange={handleChangeNombreTarea}
                      />
                    )}
                    <FormControl style={{ marginTop: "10px", width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Tipo Moneda (*)
                      </InputLabel>
                      <Select
                        style={{ marginTop: "10px" }}
                        value={tipoMonedaTarea}
                        disabled={checked2}
                        onChange={handleChangeTipoMoneda}
                      >
                        <MenuItem value="">
                          <em>Ninguno</em>
                        </MenuItem>
                        {monedas.map((element, index) => {
                          return (
                            <MenuItem key={index} value={element.nombre}>
                              {element.nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <TextField
                      margin="dense"
                      label="Valor Tarea"
                      value={valorTarea}
                      disabled={checked2}
                      onChange={handleChangValorTarea}
                      type="number"
                      fullWidth
                      required
                    />

                    <FormControl fullWidth style={{ marginTop: "30px" }}>
                      <Autocomplete
                        disabled={!autorizado}
                        disablePortal
                        value={empleadoPrincipalSelect}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setRutEmpleado(newValue.value);
                            setEmpleadoPrincipalSelect(newValue);
                            setSupervisorSelect(null);
                            handleChangeSupervisores(newValue);
                            setRutSupervisor("");
                          } else {
                            setRutEmpleado();
                            setEmpleadoPrincipalSelect(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-empleados"
                        options={empleadoOptions}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            type="rut"
                            label="Empleado Principal"
                            required
                            value={rutEmpleado}
                          />
                        )}
                      />
                    </FormControl>

                    {rolObtenido !== "EMPLEADO" && (
                      <FormControl fullWidth style={{ marginTop: "30px" }}>
                        <Autocomplete
                          disabled={
                            empleadoPrincipalSelect === null || !autorizado
                          }
                          disablePortal
                          value={supervisorSelect}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          getOptionLabel={(option) => option.label}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setRutSupervisor(newValue.value);
                              setSupervisorSelect(newValue);
                            } else {
                              setRutSupervisor("");
                              setSupervisorSelect(null);
                            }
                          }}
                          fullWidth
                          id="combo-box-Supervisor"
                          options={supervisorOptions}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              required
                              type="rut"
                              label="Supervisor Asignado"
                              value={rutSupervisor}
                            />
                          )}
                        />
                      </FormControl>
                    )}

                    <Stack direction="row" style={{ marginTop: "10px" }}>
                      <TextField
                        margin="dense"
                        label="Comisión Vendedor"
                        value={comisionVendedor}
                        disabled={
                          rolObtenido === "ADMIN" ||
                            rolObtenido === "SUPER_ADMIN"
                            ? false
                            : true
                        }
                        onChange={handleChangeComisionVendedor}
                        type="number"
                        fullWidth
                        required
                      />

                      <TextField
                        margin="dense"
                        label="Comisión Empleado"
                        value={comisionEmpleado}
                        disabled={
                          rolObtenido === "ADMIN" ||
                            rolObtenido === "SUPER_ADMIN"
                            ? false
                            : true
                        }
                        onChange={handleChangeComisionEmpleado}
                        type="number"
                        fullWidth
                        required
                      />

                      <TextField
                        margin="dense"
                        label="Comisión Supervisor"
                        value={comisionSupervisor}
                        disabled={
                          rolObtenido === "ADMIN" ||
                            rolObtenido === "SUPER_ADMIN"
                            ? false
                            : true
                        }
                        onChange={handleChangeComisionSupervisor}
                        type="number"
                        fullWidth
                        required
                      />
                    </Stack>
                  </div>
                )}

                {activeStep === 3 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Nota o Descripción Tarea
                    </Typography>

                    <FormLabel component="legend">No Obligatorio</FormLabel>

                    <TextField
                      style={{ marginTop: "30px" }}
                      label="Descripción Tarea"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={descripcionTarea}
                      fullWidth
                      onChange={handleChangeDescripcionTarea}
                    />
                  </div>
                )}

                {activeStep === 4 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Total a Pagar
                    </Typography>

                    <TextField
                      style={{ marginTop: "30px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Descuento adicional"
                      variant="outlined"
                      value={descuentoAdicional}
                      fullWidth
                      type="number"
                      onChange={handleChangeDescuentoAdicional}
                    />

                    <TextField
                      style={{ marginTop: "30px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      label="Total a Pagar"
                      variant="outlined"
                      value={
                        tipoMonedaTarea === "PESOS"
                          ? formatNumber(totalAPagar)
                          : totalAPagar
                      }
                      fullWidth
                      disabled
                      type="text"
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Container style={{ margin: 20 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Volver
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
              </Button>
            </Box>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
}
