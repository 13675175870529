import React, { useEffect, useRef, useState } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { makeStyles } from "@material-ui/styles";
import { experimentalStyled as styled } from "@material-ui/core/styles";

// material
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";

const styles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
}));

export default function FacturaTareasEmpleado(props) {
  const { value, empleado, tareas } = props;
  const classes = styles();
  const [comisiones, setComisiones] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        `${API_SERVER}/empleados/comisiones/${empleado.rut}/${empleado.nombre} ${empleado.apellidos}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setComisiones(response.data);
        }
      });
  }, [empleado.rut, empleado.nombre, empleado.apellidos, tareas]);

  const RootStyle = styled("div")(({ theme }) => ({
    marginTop: "1rem",
  }));

  return (
    <>
      {value === 5 && (
        <Card>
          <Typography variant="h5" component="h2" className={classes.title}>
            Comisiones del Empleado
          </Typography>
          <RootStyle>
            <Card style={{ padding: "1rem" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cargo</TableCell>
                      <TableCell>Comisión</TableCell>
                      <TableCell>Nombre Tarea</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comisiones &&
                      comisiones.map((row, idx) => {
                        return (
                          <TableRow key={"facturasAsociadas_" + idx}>
                            <TableCell component="th" scope="row">
                              {row.tipoCargo}
                            </TableCell>
                            <TableCell>{row.comision} %</TableCell>
                            <TableCell>{row.nombreTarea}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </RootStyle>
        </Card>
      )}
    </>
  );
}
