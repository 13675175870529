import { Card, Grid } from "@material-ui/core";
import { Download } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import Upload from "src/components/upload/upload";
import { tareasApi } from "src/services/tareasApi";
import { subirArchivoADropbox } from "src/utils/dropboxUtils";

const MAX_FILES_UPLOAD = 10;
const MAX_SIZE_FILE = 50 * 1024 * 1024; //50 MB

const CustomUploadComponent = ({
  generateDocument,
  estadoTarea,
  tipoTarea,
  idTarea,
  loadingGenerateDocument,
  cantidadArchivosCargados,
  ...other
}) => {
  const [files, setFiles] = useState([]);
  const [fileStatuses, setFileStatuses] = useState({});
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [subirArchivoATarea] = tareasApi.useSubirArchivoATareaMutation();

  const handleDropMultiFile = useCallback((acceptedFiles) => {
    // Crear nuevos archivos con preview
    const newFiles = acceptedFiles.map((newFile) =>
      Object.assign(newFile, {
        preview: URL.createObjectURL(newFile),
      })
    );

    // Actualizar los archivos
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    // Inicializar el estado para cada nuevo archivo
    const newFileStatuses = newFiles.reduce(
      (acc, file) => ({
        ...acc,
        [file.preview]: {
          status: "pending",
          message: "Listo para subir",
        },
      }),
      {}
    );

    // Actualizar los estados de los archivos
    setFileStatuses((prevStatuses) => ({
      ...prevStatuses,
      ...newFileStatuses,
    }));
  }, []);

  // Se actualiza handleRemoveFile para limpiar el estado
  const handleRemoveFile = (inputFile) => {
    const filesFiltered = files.filter(
      (fileFiltered) => fileFiltered !== inputFile
    );
    setFiles(filesFiltered);

    // Limpiar el estado del archivo eliminado
    const newFileStatuses = { ...fileStatuses };
    delete newFileStatuses[inputFile.preview];
    setFileStatuses(newFileStatuses);
  };

  // Se actualiza handleRemoveAllFiles para limpiar todos los estados
  const handleRemoveAllFiles = () => {
    setFiles([]);
    setFileStatuses({});
  };

  const onUpload = async () => {
    setLoadingUpload(true);

    for (const file of files) {
      const { name, type } = file;
      const fileKey = file.preview;

      // Establecer estado 'uploading' para este archivo
      setFileStatuses((prev) => ({
        ...prev,
        [fileKey]: { status: "uploading", message: "Subiendo archivo..." },
      }));

      const body = {
        idTarea,
        nombreArchivo: name,
        tipoArchivo: type,
      };

      try {
        const bodyConLinkDropbox = await subirArchivoADropbox(
          file,
          `Tareas/${idTarea}`,
          body,
          null,
          "link",
          false,
          name,
          true
        );

        if (bodyConLinkDropbox?.error) {
          const { error } = bodyConLinkDropbox.error;
          const tag = error[".tag"];
          const {
            shared_link_already_exists: {
              metadata: { name },
            },
          } = error;

          if (tag === "shared_link_already_exists") {
            throw new Error(`El archivo ${name} ya existe en Dropbox`);
          } else {
            throw new Error(
              `Ocurrió un error desconocido al subir el archivo ${name} a Dropbox`
            );
          }
        }

        await subirArchivoATarea(bodyConLinkDropbox);

        // Actualizar estado a 'success' para este archivo
        setFileStatuses((prev) => ({
          ...prev,
          [fileKey]: {
            status: "success",
            message: "Archivo subido correctamente",
          },
        }));
      } catch (error) {
        // Actualizar estado a 'error' para este archivo
        setFileStatuses((prev) => ({
          ...prev,
          [fileKey]: {
            status: "error",
            message: error.message || "Error al subir el archivo",
          },
        }));
        console.error(`Error al subir ${file.name}: ${error.message}`);
      }
    }

    setLoadingUpload(false);
  };

  if (
    estadoTarea &&
    (estadoTarea === "TERMINADA" ||
      estadoTarea === "FACTURADO" ||
      estadoTarea === "PENDIENTE DE FACTURACION")
  ) {
    return null;
  }

  return (
    <>
      <Card style={{ padding: "1rem", marginTop: "1rem" }}>
        {tipoTarea !== "AÑO" &&
          tipoTarea !== "MES" &&
          tipoTarea !== "INTERNA" && (
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Grid item xl={3} md={4} sm={12} xs={12}>
                <LoadingButton
                  onClick={generateDocument}
                  variant="contained"
                  loadingPosition="start"
                  startIcon={<Download />}
                  loading={loadingGenerateDocument}
                  fullWidth
                  style={{
                    backgroundColor: "#3f51b5",
                    color: "#fff",
                    padding: "12px 0",
                  }}
                >
                  Descargar Prototipo Propuesta
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        {(tipoTarea === "ESPECIAL" ||
          tipoTarea === "AÑO" ||
          tipoTarea === "MES" ||
          tipoTarea === "INTERNA") && (
          <Upload
            multiple
            disabled={loadingUpload}
            thumbnail={false}
            files={files}
            loadingUpload={loadingUpload}
            onDrop={handleDropMultiFile}
            onRemove={handleRemoveFile}
            onRemoveAll={handleRemoveAllFiles}
            fileStatuses={fileStatuses}
            onUpload={onUpload}
            accept={{
              "image/*": [".png", ".jpg", ".jpeg", ".gif"],
              "application/pdf": [".pdf"],
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
              "application/vnd.ms-excel": [".xls"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
            maxFiles={MAX_FILES_UPLOAD}
            helperText={`(Máximo ${MAX_FILES_UPLOAD} archivos simultáneamente)`}
            maxSize={MAX_SIZE_FILE}
          />
        )}
      </Card>
    </>
  );
};

CustomUploadComponent.propTypes = {
  generateDocument: PropTypes.func.isRequired,
  loadingGenerateDocument: PropTypes.bool.isRequired,
  estadoTarea: PropTypes.string.isRequired,
  idTarea: PropTypes.number.isRequired,
  tipoTarea: PropTypes.string.isRequired,
  cantidadArchivosCargados: PropTypes.number.isRequired,
};

export default React.memo(CustomUploadComponent);
