import { Form, FormikProvider } from "formik";
import PropTypes from "prop-types";
import React from "react";

const FormProvider = ({ children, onSubmit, formik, disableEnterSubmit }) => {
  const handleKeyDown = (event) => {
    if (disableEnterSubmit && event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={onSubmit} onKeyDown={handleKeyDown}>
        {children}
      </Form>
    </FormikProvider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  formik: PropTypes.object,
  disableEnterSubmit: PropTypes.bool,
};

FormProvider.defaultProps = {
  disableEnterSubmit: false,
};

export default React.memo(FormProvider);
