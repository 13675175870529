import React, { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { API_SERVER } from "../../utils/urlBack_End";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
  TableRow,
  Table,
  TableHead,
} from "@material-ui/core";
import axios from "axios";
import { tiposApi } from "src/services/tiposApi";
import { AlertaToast } from "../AlertaSwall";
export default function EditarTarea(props) {
  const {
    openEditar,
    setOpenEditar,
    monedas,
    nombreTareaDato,
    valorTareaDato,
    tipoMonedaDato,
    descripcionDato,
    tipoTareaDato,
  } = props;
  const [nombreSubTarea, setNombreSubTarea] = useState("");

  const [nombreTarea, setNombreTarea] = useState(nombreTareaDato);
  const [valorTarea, setValorTarea] = useState(valorTareaDato);
  const [tipoMoneda, setTipoMoneda] = useState(tipoMonedaDato);
  const [descripcion, setDescripcion] = useState(descripcionDato);
  const [tipoTarea, setTipoTarea] = useState(tipoTareaDato);
  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");

  const [tareasAsociadas, setTareasAsociadas] = useState([]);

  const [editarTipoTarea] = tiposApi.useUpdateTipoTareasMutation();

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tareas/tipoTarea/${nombreTarea}/${tipoTarea}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setTareasAsociadas(response.data);
      });
  }, [nombreTarea, tipoTarea]);

  const [openAgregarTarea, setOpenAgregarTarea] = useState(false);

  const handleClickOpenAgregar = () => {
    setOpenAgregarTarea(true);
  };

  const handleCloseAgregar = () => {
    setOpenAgregarTarea(false);
    setNombreTarea("");
  };

  const handleChangeTipoMoneda = (event) => {
    setTipoMoneda(event.target.value);
  };

  const handleChangeNombreSubTarea = (event) => {
    setNombreSubTarea(event.target.value);
  };

  const handleChangValorTarea = (event) => {
    setValorTarea(event.target.value);
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const handleChangeDescripcionPlan = (event) => {
    setDescripcion(event.target.value);
  };
  const agregarTarea = () => {
    let tareas = tareasAsociadas;

    let elemento = tareas.filter(
      (element) => element.nombre.toUpperCase() === nombreSubTarea.toUpperCase()
    )[0];

    if (elemento) {
      setColorNotificacion("error");
      setMensajeNotificacion("Elemento ya existe.");
      setOpenNotificacion(true);
    } else {
      axios
        .post(
          `${API_SERVER}/tipos/relacionTipoTareaSubTarea`,
          {
            nombreTarea: nombreTarea.toUpperCase(),
            tipoTarea: tipoTarea.toUpperCase(),
            nombre: nombreSubTarea.toUpperCase(),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          axios
            .get(`${API_SERVER}/tareas/tipoTarea/${nombreTarea}/${tipoTarea}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(function (response) {
              setTareasAsociadas(response.data);
            });
        });

      setTareasAsociadas(tareas);
    }
    setNombreSubTarea("");
    setOpenAgregarTarea(false);
  };

  const guardarPlan = () => {
    if (nombreTarea === "" || valorTarea === "" || tipoMoneda === "") {
      AlertaToast({
        action: "Todos los campos deben estar completos",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogEditar"),
      }).fire();
    } else {
      let valorFinal = valorTarea;
      if (String(valorFinal).includes(",")) {
        valorFinal = String(valorFinal).replaceAll(",", ".");
        valorFinal = parseInt(valorFinal);
      }
      let tarea = {
        nombre: nombreTarea.toUpperCase(),
        valor: valorFinal,
        tipoMoneda: tipoMoneda.toUpperCase(),
        descripcion: descripcion,
        tipoTarea: tipoTarea.toUpperCase(),
      };

      editarTipoTarea(tarea)
        .unwrap()
        .then(() => {
          AlertaToast({
            element: "Tarea",
            action: "actualizado",
          }).fire();
          setOpenEditar(false);
        })
        .catch((error) => {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogEditar"),
          }).fire();
        });
    }
  };

  const cancelar = () => {
    setNombreTarea("");
    setValorTarea(0);
    setTipoMoneda("");
    setDescripcion("");
    setTipoTarea("");
    setNombreSubTarea("");
    setOpenEditar(false);
  };
  return (
    <>
      <Dialog
        open={openEditar}
        aria-labelledby="form-dialog-title"
        id="dialogEditar">
        <DialogTitle id="form-dialog-title">Editar Tipo Tarea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Tarea"
            type="text"
            value={nombreTarea}
            fullWidth
            disabled
          />

          <TextField
            style={{ marginTop: "10px" }}
            value={tipoTarea}
            margin="dense"
            label="Tipo Tarea"
            type="text"
            fullWidth
            disabled
          />

          <TextField
            margin="dense"
            label="Valor Tarea"
            value={valorTarea}
            onChange={handleChangValorTarea}
            type="number"
            fullWidth
            required
          />

          <FormControl style={{ marginTop: "10px", width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Tipo Moneda (*)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ marginTop: "10px" }}
              value={tipoMoneda}
              onChange={handleChangeTipoMoneda}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {monedas.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.nombre}>
                    {element.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            style={{ marginTop: "10px", width: "100%" }}
            id="outlined-multiline-static"
            label="Descripción Tarea"
            multiline
            rows={4}
            variant="outlined"
            value={descripcion}
            onChange={handleChangeDescripcionPlan}
          />

          <Button
            color="primary"
            startIcon={<Icon icon={plusFill} />}
            style={{ marginTop: "10px" }}
            onClick={handleClickOpenAgregar}>
            Agregar Tarea
          </Button>

          <TableContainer component={Paper} style={{ marginTop: "10px" }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre Subtarea</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tareasAsociadas.map((row) => (
                  <TableRow key={row.nombre}>
                    <TableCell component="th" scope="row">
                      {row.nombre}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          axios
                            .delete(
                              `${API_SERVER}/subtareas/tipoTarea/${row.nombre}/${row.refTipoTarea}/${row.tipoTarea}`,
                              {
                                headers: {
                                  Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                                },
                              }
                            )
                            .then(function () {
                              axios
                                .get(
                                  `${API_SERVER}/tareas/tipoTarea/${nombreTarea}/${tipoTarea}`,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem("token"),
                                    },
                                  }
                                )
                                .then(function (response) {
                                  setTareasAsociadas(response.data);
                                });
                            });
                        }}
                        color="error">
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} color="error">
            Cancelar
          </Button>
          <Button onClick={guardarPlan} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAgregarTarea} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nombre Tarea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Tarea"
            type="text"
            value={nombreSubTarea}
            onChange={handleChangeNombreSubTarea}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAgregar} color="error">
            Cancelar
          </Button>
          <Button onClick={agregarTarea} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}>
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}>
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
      ;
    </>
  );
}
