import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { API_SERVER } from '../utils/urlBack_End';

export const calendarioApi = createApi({
    reducerPath: 'calendarioApi',
    baseQuery: baseQuery(`${API_SERVER}/calendarios`),
    tagTypes: ["Calendario"],
    endpoints: (builder) => ({
        getCalendario: builder.query({
            query: (params) => `/${params.sucursal}/${params.mesActual}/${params.usuario}`,
            providesTags: ["Calendario"],
        }),
        addCalendario: builder.mutation({
            query: (body) => ({
                url: `/`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Calendario"],
        }),
        updateCalendario: builder.mutation({
            query: ({ id, body }) => ({
                url: `/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["Calendario"],
        }),
        deleteCalendario: builder.mutation({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Calendario"],
        }),
        acceptInvitationCalendario: builder.mutation({
            query: (body) => ({
                url: `/aceptarInvitacion`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Calendario"],
        }),
    }),
});

export const {
    useGetCalendarioQuery,
    useLazyGetCalendarioQuery,
    useUpdateCalendarioMutation,
    useDeleteCalendarioMutation,
    useAddCalendarioMutation,
    useAcceptInvitationCalendarioMutation,
} = calendarioApi;