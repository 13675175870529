import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const tiposApi = createApi({
  reducerPath: "tiposApi",
  baseQuery: baseQuery(`${API_SERVER}/tipos`),
  tagTypes: ["TipoPlanes", "TipoTareas"],
  endpoints: (builder) => ({
    getTipoMoneda: builder.query({
      query: () => `/tipoMoneda`,
    }),
    getIndicadores: builder.query({
      query: () => `/indicadores`,
    }),
    getTipoPlanes: builder.query({
      query: () => `/tipoPlanes`,
      providesTags: ["TipoPlanes"],
    }),
    getTipoPlanesID: builder.query({
      query: (id) => `/tipoPlanes/${id}`,
      providesTags: ["TipoPlanes"],
    }),
    getTipoTareas: builder.query({
      query: () => `/tipoTareas`,
      providesTags: ["TipoTareas"],
    }),
    getTipoTareasNombre: builder.query({
      query: ({ tipoTarea, nombre }) => `/tipoTareas/${tipoTarea}/${nombre}`,
      providesTags: ["TipoTareas"],
    }),
    getTipoTareasSegunTipo: builder.query({
      query: (tipoTarea) => `/tipoTareas/${tipoTarea}`,
      providesTags: ["TipoTareas"],
    }),
    deleteTipoTarea: builder.mutation({
      query: ({ nombre, tipoTarea }) => ({
        url: `/tipoTareas/${nombre}/${tipoTarea}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TipoTareas"],
    }),
    deleteTipoPlanes: builder.mutation({
      query: (id) => ({
        url: `/tipoPlanes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TipoPlanes"],
    }),
    deleteRelacionTipoPlanTarea: builder.mutation({
      query: (body) => ({
        url: `/relacionTipoPlanTarea`,
        body: body,
        method: "DELETE",
      }),
      invalidatesTags: ["TipoPlanes"],
    }),
    addTipoTareas: builder.mutation({
      query: (body) => ({
        url: `/tipoTareas`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TipoTareas"],
    }),
    addRelacionTipoTareaSubTarea: builder.mutation({
      query: (body) => ({
        url: `/relacionTipoTareaSubTarea`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TipoTareas"],
    }),
    addRelacionTipoPlanTarea: builder.mutation({
      query: (body) => ({
        url: `/relacionTipoPlanTarea`,
        method: "POST",
        body,
      }),
    }),
    updateTipoTareas: builder.mutation({
      query: (body) => ({
        url: `/tipoTareas`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["TipoTareas"],
    }),
    updateTipoPlan: builder.mutation({
      query: (body) => ({
        url: `/tipoPlan`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["TipoPlanes"],
    }),
    addTipoPlan: builder.mutation({
      query: (body) => ({
        url: `/tipoPlan`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TipoPlanes"],
    }),
  }),
});

export const {
  useGetTipoMonedaQuery,
  useGetTipoPlanesIDQuery,
  useGetTipoPlanesQuery,
  useGetTipoTareasNombreQuery,
  useGetTipoTareasQuery,
  useGetTipoTareasSegunTipoQuery,
  useGetIndicadoresQuery,
  useLazyGetIndicadoresQuery,
  useLazyGetTipoMonedaQuery,
  useLazyGetTipoPlanesIDQuery,
  useLazyGetTipoPlanesQuery,
  useLazyGetTipoTareasNombreQuery,
  useLazyGetTipoTareasQuery,
  useLazyGetTipoTareasSegunTipoQuery,
  useAddRelacionTipoPlanTareaMutation,
  useAddRelacionTipoTareaSubTareaMutation,
  useAddTipoTareasMutation,
  useUpdateTipoTareasMutation,
  useUpdateTipoPlanMutation,
  useDeleteRelacionTipoPlanTareaMutation,
  useDeleteTipoPlanesMutation,
  useDeleteTipoTareaMutation,
  useAddTipoPlanMutation,
} = tiposApi;
