import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  Card,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Container,
  Stack,
  Checkbox,
  Alert,
  CardContent,
  Typography,
  CardActions,
} from "@material-ui/core";
import { motion } from "framer-motion";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "@material-ui/lab/LoadingButton";
import Label from "../Label";

import { dbx } from "../../utils/urlBack_End";
import IconButton from "@material-ui/core/IconButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sentenceCase } from "change-case";

import ModifyTaskStateModal from "../_dashboard/tareas/ModifyTaskStateModal";

import RemoveIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import AgregarSubTareaDialog from "./AgregarSubTareaDialog";
import ArchivoSubtareaDialog from "./ArchivoSubtareaDialog";
import EditarSubTareaDialog from "./EditarSubTareaDialog";
import HistorialTiempoSubTareaDialog from "./HistorialTiempoSubTareaDialog";
import { AlertaMensajeEliminacion } from "../AlertaMensajeEliminacion";
import moment from "moment";
import { useStopwatch } from "react-timer-hook";
import { MotionContainer, varBounceIn } from "../animate";
import { subtareasApi } from "src/services/subtareasApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hidden: {
    display: "none",
  },
});

// ----------------------------------------------------------------------

export default function Subtareas({ tarea }) {
  const { data: subtareas = [], isLoading: isLoadingSubtareas } =
    subtareasApi.useGetSubtareasTareaQuery(tarea.id);

  const [crearNuevoTiempo] = subtareasApi.useAddCrearNuevoTiempoMutation();
  const [actualizarEstadoSubtarea] =
    subtareasApi.useUpdateEstadoSubtareaMutation();
  const [eliminarArchivo] = subtareasApi.useDeleteArchivoMutation();
  const [actualizarMostrarArchivo] =
    subtareasApi.useUpdateMostrarArchivoMutation();
  const [eliminarSubtarea] = subtareasApi.useDeleteSubtareaMutation();

  const [subTareasSeleccionadas, setSubTareasSeleccionadas] = useState([]);
  const classes = useStyles();
  const [showModifyTaskState, setShowModifyTaskState] = useState(false);
  const [selectedTask, setSelectedTask] = useState();

  const [loadingEliminar, setLoadingEliminar] = useState(false);
  const [subTareaSeleccionada, setSubTareaSeleccionada] = useState(null);

  const [mostrarDialogArchivo, setMostrarDialogArchivo] = useState(false);

  const [mostrarAgregar, setMostrarAgregar] = useState(false);
  const [mostrarHistorialTiempo, setMostrarHistorialTiempo] = useState(false);
  const [mostrarEliminarArchivoSubTarea, setMostrarEliminarArchivoSubTarea] =
    useState(false);

  const [selectedSubTaskEdit, setSelectedSubTaskEdit] = useState(null);
  const [selectSubTaskFileDelete, setSelectSubTaskFileDelete] = useState(null);
  const [selectSubTaskCronometro, setSelectSubTaskCronometro] = useState(null);
  const [mostrarEditar, setMostrarEditar] = useState(false);
  const [eliminarLista, setEliminarLista] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChangeTaskState = (id) => {
    const selectedTaskAux = subtareas.find((t) => t.id === id);
    setSelectedTask(selectedTaskAux);
    setShowModifyTaskState(true);
  };

  const doChangeTaskState = (newState) => {
    if (selectedTask.estado === newState) return setShowModifyTaskState(false);
    actualizarEstadoSubtarea({
      id: selectedTask.id,
      body: {
        estado: newState,
      },
    })
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Subtarea",
          action: "actualizada",
        }).fire();
      })
      .catch(() => {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      });
    setShowModifyTaskState(false);
  };

  const handleDelete = (id) => {
    AlertaModal({
      title: "eliminar la subtarea",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarSubtarea(id)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Subtarea",
                action: "eliminada",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  const handleDeleteLista = (subTareasSeleccionadas) => {
    AlertaModal({
      title: "eliminar las subtareas",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          subTareasSeleccionadas.map((subtarea) =>
            eliminarSubtarea(subtarea.id)
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: "subtareas",
                  action: "eliminadas",
                }).fire();
              })
              .catch(() => {
                AlertaToast({
                  action: "Error en el servidor",
                  fullText: true,
                  type: "error",
                }).fire();
              })
          );
          setSubTareasSeleccionadas([]);
        }
      });
  };

  const removeFile = async (subTarea) => {
    setLoadingEliminar(true);
    if (
      subTarea &&
      subTarea.id &&
      subTarea.nombreArchivo &&
      subTarea.refTarea
    ) {
      try {
        await deleteFileFromDropbox(subTarea.nombreArchivo, subTarea.refTarea);
        deleteFileFromDB(subTarea.id);
      } catch (ex) {
      } finally {
        setLoadingEliminar(false);
      }
    } else {
      setLoadingEliminar(false);
    }
  };

  const deleteFileFromDB = (idSubTarea) => {
    eliminarArchivo(idSubTarea)
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Archivo",
          action: "eliminado",
        }).fire();
      })
      .catch(() => {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      });
  };

  const deleteFileFromDropbox = (nombre, refTarea) => {
    return dbx.filesDeleteV2({
      path: `/Tareas/${refTarea}/SubTareas/${nombre}`,
    });
  };

  function secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    hour = hour < 10 ? "0" + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    var second = seconds % 60;
    second = second < 10 ? "0" + second : second;
    return hour + ":" + minute + ":" + Math.round(second);
  }

  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  return (
    <Container>
      <RootStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Button
            disabled={
              tarea.estado &&
              (tarea.estado === "TERMINADA" ||
                tarea.estado === "FACTURADO" ||
                tarea.estado === "PENDIENTE DE FACTURACION")
            }
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              setMostrarAgregar(true);
            }}
          >
            Agregar SubTarea
          </Button>
        </Stack>

        {selectSubTaskCronometro && (
          <MotionContainer initial="initial" open>
            <Card>
              <CardContent
                style={{
                  textAlign: "center",
                }}
              >
                <Stack>
                  <motion.div variants={varBounceIn}>
                    <Typography variant="h5" color="primary">
                      {`En proceso: ${selectSubTaskCronometro.nombre}`}
                    </Typography>
                  </motion.div>
                  <motion.div variants={varBounceIn}>
                    <Typography variant="h2" color="primary">
                      {`${hours}:${minutes}:${seconds}`}
                    </Typography>
                  </motion.div>
                </Stack>
              </CardContent>
              <CardActions>
                {isRunning === false ? (
                  <Button
                    style={{ marginTop: "20px" }}
                    size="large"
                    variant="contained"
                    onClick={start}
                  >
                    Iniciar
                  </Button>
                ) : (
                  <>
                    <Button
                      style={{ marginTop: "20px" }}
                      size="large"
                      color="error"
                      variant="contained"
                      onClick={pause}
                    >
                      Detener
                    </Button>
                    <Button
                      style={{ marginTop: "20px" }}
                      size="large"
                      color="info"
                      variant="contained"
                      onClick={() => {
                        pause();
                        let segundosFinal = 0;
                        if (hours > 0) {
                          segundosFinal = Math.round(
                            Number(`${minutes}.${seconds}`) * 3600
                          );
                        } else if (minutes > 0) {
                          segundosFinal = Math.round(
                            Number(`${minutes}.${seconds}`) * 60
                          );
                        } else {
                          segundosFinal = seconds;
                        }
                        crearNuevoTiempo({
                          refIdSubTarea: selectSubTaskCronometro.id,
                          segundos: segundosFinal,
                        })
                          .unwrap()
                          .then(() => {
                            reset(null, false);
                            AlertaToast({
                              element: "Nuevo tiempo",
                              action: "agregado",
                            }).fire();
                          })
                          .catch(() => {
                            AlertaToast({
                              action: "Error en el servidor",
                              fullText: true,
                              type: "error",
                            }).fire();
                          })
                          .finally(() => {
                            setSelectSubTaskCronometro(null);
                          });
                      }}
                    >
                      Finalizar
                    </Button>
                  </>
                )}
              </CardActions>
            </Card>
          </MotionContainer>
        )}

        <Card style={{ padding: "1rem" }}>
          {subTareasSeleccionadas && subTareasSeleccionadas.length >= 1 && (
            <Grid container>
              <Grid item xs={10} md={10}>
                <Alert>
                  {subTareasSeleccionadas.length} subtareas seleccionadas
                </Alert>
              </Grid>
              <Grid item xs={2} md={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleDeleteLista(subTareasSeleccionadas);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
          )}

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha Inicio</TableCell>
                  <TableCell>Fecha Término</TableCell>
                  <TableCell>Archivo</TableCell>
                  <TableCell>Mostrar Archivo al Cliente</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoadingSubtareas &&
                  subtareas.map((row, idx) => {
                    let totalTiempo = 0;
                    try {
                      row.horasDedicadas.map((item) => {
                        totalTiempo = totalTiempo + item.segundos;
                      });
                    } catch (error) {}
                    return (
                      <TableRow key={idx}>
                        {tarea.estado &&
                        (tarea.estado === "TERMINADA" ||
                          tarea.estado === "FACTURADO" ||
                          tarea.estado === "PENDIENTE DE FACTURACION") ? (
                          <TableCell></TableCell>
                        ) : (
                          <TableCell>
                            <Checkbox
                              checked={
                                subTareasSeleccionadas.filter(
                                  (e) => e.id === row.id
                                )[0]
                                  ? true
                                  : false
                              }
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setSubTareasSeleccionadas([
                                    ...subTareasSeleccionadas,
                                    row,
                                  ]);
                                } else {
                                  let subTareasAUX =
                                    subTareasSeleccionadas.filter(
                                      (e) => e.id !== row.id
                                    );
                                  setSubTareasSeleccionadas(subTareasAUX);
                                }
                              }}
                            />
                          </TableCell>
                        )}

                        <TableCell component="th" scope="row">
                          {row.nombre}
                        </TableCell>
                        <TableCell>
                          {row.fechaInicio === ""
                            ? row.fechaInicio
                            : moment(row.fechaInicio, "YYYY/MM/DD").format(
                                "DD/MM/YYYY"
                              )}
                        </TableCell>
                        <TableCell>
                          {row.fechaTermino === ""
                            ? row.fechaTermino
                            : moment(row.fechaTermino, "YYYY/MM/DD").format(
                                "DD/MM/YYYY"
                              )}
                        </TableCell>
                        <TableCell>
                          <Grid container>
                            {row.urlArchivo ? (
                              <div>
                                <Grid item xs={10} md={10}>
                                  <a
                                    href={row.urlArchivo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Visualizar
                                  </a>
                                </Grid>
                                {tarea.estado &&
                                (tarea.estado === "TERMINADA" ||
                                  tarea.estado === "FACTURADO" ||
                                  tarea.estado ===
                                    "PENDIENTE DE FACTURACION") ? (
                                  <></>
                                ) : (
                                  <Grid item xs={2} md={2}>
                                    <LoadingButton
                                      loading={loadingEliminar}
                                      color="error"
                                      onClick={() => {
                                        setSelectSubTaskFileDelete(row);
                                        setMostrarEliminarArchivoSubTarea(true);
                                      }}
                                    >
                                      <RemoveIcon />
                                    </LoadingButton>
                                  </Grid>
                                )}
                              </div>
                            ) : (
                              <Button
                                disabled={
                                  tarea.estado &&
                                  (tarea.estado === "TERMINADA" ||
                                    tarea.estado === "FACTURADO" ||
                                    tarea.estado === "PENDIENTE DE FACTURACION")
                                }
                                variant="contained"
                                onClick={() => {
                                  setSubTareaSeleccionada(row);
                                  setMostrarDialogArchivo(true);
                                }}
                              >
                                Subir Archivo
                              </Button>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.mostrarArchivo === "NO" ? false : true}
                            onChange={(event) => {
                              let mostrarArchivo = "NO";
                              if (event.target.checked) {
                                mostrarArchivo = "SI";
                              }
                              actualizarMostrarArchivo({
                                mostrarArchivo: mostrarArchivo,
                                idSubtarea: row.id,
                              })
                                .unwrap()
                                .then(() => {
                                  AlertaToast({
                                    element: "Mostrar archivo",
                                    action: "actualizado",
                                  }).fire();
                                })
                                .catch(() => {
                                  AlertaToast({
                                    action: "Error en el servidor",
                                    fullText: true,
                                    type: "error",
                                  }).fire();
                                });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell>
                          {tarea.estado &&
                          (tarea.estado === "TERMINADA" ||
                            tarea.estado === "FACTURADO" ||
                            tarea.estado === "PENDIENTE DE FACTURACION") ? (
                            <Label
                              variant="ghost"
                              color={
                                (row.estado === "PENDIENTE" && "error") ||
                                (row.estado === "EN PROCESO" && "warning") ||
                                (row.estado === "TERMINADA" && "success") ||
                                "info"
                              }
                            >
                              {sentenceCase(row.estado)}
                            </Label>
                          ) : (
                            <Label
                              onClick={() => handleChangeTaskState(row.id)}
                              style={{ cursor: "pointer" }}
                              variant="ghost"
                              color={
                                (row.estado === "PENDIENTE" && "error") ||
                                (row.estado === "EN PROCESO" && "warning") ||
                                (row.estado === "TERMINADA" && "success") ||
                                "info"
                              }
                            >
                              {sentenceCase(row.estado)}
                            </Label>
                          )}
                        </TableCell>
                        <TableCell>
                          <Stack>
                            {tarea.estado &&
                            (tarea.estado === "TERMINADA" ||
                              tarea.estado === "FACTURADO" ||
                              tarea.estado === "PENDIENTE DE FACTURACION") ? (
                              <></>
                            ) : row.estado === "TERMINADA" ? (
                              <></>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => {
                                  setSelectSubTaskCronometro(row);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlay} />
                              </Button>
                            )}

                            <Button
                              onClick={() => {
                                setSubTareaSeleccionada(row);
                                setMostrarHistorialTiempo(true);
                              }}
                            >
                              Tiempo Total: {secondsToString(totalTiempo)}
                            </Button>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {tarea.estado &&
                          (tarea.estado === "TERMINADA" ||
                            tarea.estado === "FACTURADO" ||
                            tarea.estado === "PENDIENTE DE FACTURACION") ? (
                            <></>
                          ) : row.estado === "TERMINADA" ? (
                            <></>
                          ) : (
                            <React.Fragment>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setSelectedSubTaskEdit(row);
                                  setMostrarEditar(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  handleDelete(row.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            </React.Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            {selectedTask && (
              <ModifyTaskStateModal
                task={selectedTask}
                onSubmit={doChangeTaskState}
                onClose={() => setShowModifyTaskState(false)}
                states={[
                  "PENDIENTE",
                  "EN PROCESO",
                  "OBSERVADA SII",
                  "TERMINADA",
                ]}
                open={showModifyTaskState}
                cargandoDatos={false}
              />
            )}
          </TableContainer>
        </Card>
        {mostrarHistorialTiempo && (
          <HistorialTiempoSubTareaDialog
            open={mostrarHistorialTiempo}
            setOpen={setMostrarHistorialTiempo}
            subtarea={subTareaSeleccionada}
          />
        )}
        {mostrarAgregar && (
          <AgregarSubTareaDialog
            open={mostrarAgregar}
            setOpen={setMostrarAgregar}
            tarea={tarea}
          />
        )}

        {mostrarDialogArchivo && (
          <ArchivoSubtareaDialog
            open={mostrarDialogArchivo}
            setOpen={setMostrarDialogArchivo}
            subTarea={subTareaSeleccionada}
          />
        )}
        {mostrarEditar && (
          <EditarSubTareaDialog
            open={mostrarEditar}
            setOpen={setMostrarEditar}
            subTarea={selectedSubTaskEdit}
            tarea={tarea}
          />
        )}

        {mostrarEliminarArchivoSubTarea &&
          selectSubTaskFileDelete &&
          selectSubTaskFileDelete.id && (
            <AlertaMensajeEliminacion
              mensaje={"¿Seguro que deseas eliminar el archivo?"}
              open={mostrarEliminarArchivoSubTarea}
              setOpen={setMostrarEliminarArchivoSubTarea}
              aceptar={() => {
                removeFile(selectSubTaskFileDelete);
              }}
              mensajeBotonAceptar={"Eliminar"}
            />
          )}

        {eliminarLista && (
          <AlertaMensajeEliminacion
            mensaje={"¿Seguro que desea eliminar estas subtareas?"}
            open={eliminarLista}
            setOpen={setEliminarLista}
            aceptar={() => {
              subTareasSeleccionadas.map((subTarea) => removeFile(subTarea));
            }}
            mensajeBotonAceptar={"Eliminar"}
          />
        )}
      </RootStyle>
    </Container>
  );
}
