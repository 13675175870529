import { useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import useChart from "../charts/use-chart";
import { fNumber } from "src/utils/formatNumber";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
} from "@material-ui/core";
import CustomSmallSelect from "../custom-input/CustomSmallSelect";
import Chart from "../charts/chart";
import _ from "lodash";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";
import { BarChart } from "@material-ui/icons";

AppHistorialTareas.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  loading: PropTypes.bool,
  abrirModal: PropTypes.func,
  manejarClickGrafico: PropTypes.func,
};

export default function AppHistorialTareas({
  title,
  subheader,
  chart,
  loading,
  abrirModal,
  manejarClickGrafico = null,
  sucursales,
  ...other
}) {
  const { data, series, options } = chart;
  const theme = useTheme();

  const [seriesData, setSeriesData] = useState("Especial");

  const chartOptions = useChart({
    chart: {
      stacked: true,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (manejarClickGrafico) {
            const { dataPointIndex, seriesIndex } = config;
            const sucursal =
              chartContext.w.config.xaxis.categories[dataPointIndex];
            const estadoTarea = config.w.config.series[seriesIndex].name;

            manejarClickGrafico({
              sucursal,
              estadoTarea,
              tipoTarea: seriesData,
            });
          }
        },
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = manejarClickGrafico
            ? "pointer"
            : "default";
        },
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.text.disabled,
    ],
    stroke: {
      width: 0,
    },
    xaxis: {
      categories:
        (seriesData === "Especial" &&
          !_.isEmpty(data) &&
          Array.from(
            new Set(data.tareasEspeciales.map((item) => item.sucursal))
          )) ||
        (seriesData === "Planes" &&
          !_.isEmpty(data) &&
          Array.from(
            new Set(data.tareasDePlan.map((item) => item.sucursal))
          )) ||
        [],
    },
    tooltip: {
      y: {
        formatter: (value) => fNumber(value),
      },
    },
    plotOptions: {
      bar: {
        borderRadius:
          ((seriesData === "Especial" || seriesData === "Planes") && 8) || 10,
        columnWidth: "20%",
      },
    },
    noData: {
      text: "No hay datos disponibles",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: undefined,
      },
    },

    ...options,
  });

  if (loading) {
    return (
      <Card>
        <CardHeader
          title={<Skeleton animation="wave" height={30} width="80%" />}
          subheader={<Skeleton animation="wave" height={20} width="40%" />}
        />
        <Box sx={{ mt: 3, mx: 3 }}>
          <Skeleton variant="rect" height={364} />
        </Box>
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <Stack direction={{ xs: "column", md: "row", lg: "row" }} spacing={1}>
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              {sucursales.map((sucursal, index) => (
                <Tooltip
                  key={index}
                  title={`Estadísticas generales sucursal ${capitalizarPrimeraLetra(
                    sucursal.refSucursal
                  )}`}
                >
                  <Button
                    key={index}
                    onClick={() => abrirModal(sucursal.refSucursal)}
                    variant="outlined"
                    color="inherit"
                    startIcon={<BarChart />}
                    size="small"
                  >
                    {capitalizarPrimeraLetra(sucursal.refSucursal)}
                  </Button>
                </Tooltip>
              ))}
            </Stack>
            <CustomSmallSelect
              value={seriesData}
              onChange={(event) => setSeriesData(event.target.value)}
            >
              {series.map((option) => (
                <option key={option.type} value={option.type}>
                  {option.type}
                </option>
              ))}
            </CustomSmallSelect>
          </Stack>
        }
      />

      {series.map((item) => (
        <Box key={item.type} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.type === seriesData && (
            <Chart
              type="bar"
              series={item.data}
              options={chartOptions}
              height={364}
            />
          )}
        </Box>
      ))}
    </Card>
  );
}
