import {
  Avatar,
  Card,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import useChart from "../charts/use-chart";
import { fNumber } from "src/utils/formatNumber";
import Chart from "../charts/chart";

// ----------------------------------------------------------------------

AppDetalleTareas.propTypes = {
  data: PropTypes.array,
  chart: PropTypes.object,
  total: PropTypes.number,
  title: PropTypes.string,
  subheader: PropTypes.string,
  loading: PropTypes.bool,
  manejarClickStack: PropTypes.func,
  cursor: PropTypes.string,
};

export default function AppDetalleTareas({
  data,
  total,
  chart,
  title,
  subheader,
  loading,
  manejarClickStack,
  cursor = "default",
  ...other
}) {
  const theme = useTheme();

  const { colors, series, options } = chart;

  const chartColors = colors || [
    theme.palette.primary.light,
    theme.palette.primary.main,
  ];

  const chartOptions = useChart({
    chart: {
      offsetY: -16,
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: 24,
        bottom: 24,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "56%",
        },
        dataLabels: {
          name: {
            offsetY: 8,
          },
          value: {
            offsetY: -40,
          },
          total: {
            label: `Finalizadas de ${fNumber(total)}`,
            color: theme.palette.text.disabled,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [chartColors].map((colr) => [
          { offset: 0, color: colr[0] },
          { offset: 100, color: colr[1] },
        ]),
      },
    },
    ...options,
  });

  if (loading) {
    return (
      <Card
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        {...other}
      >
        <div style={{ width: "100%", margin: 0, padding: 0 }}>
          <CardHeader
            title={
              <Skeleton
                animation="wave"
                height={10}
                width="40%"
                style={{ marginBottom: 6 }}
              />
            }
            subheader={<Skeleton animation="wave" height={10} width="20%" />}
          />
          <Skeleton
            animation="wave"
            variant="circular"
            width={360}
            height={360}
            style={{ margin: "0 auto" }}
          />
          <Stack spacing={2} sx={{ p: 3, marginTop: 4 }}>
            {[...Array(data.length)].map((_, index) => (
              <Stack
                key={index}
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={48}
                  height={48}
                />
                <Stack spacing={0.5} flexGrow={1}>
                  <Skeleton animation="wave" height={10} width="30%" />
                  <Skeleton animation="wave" height={10} width="20%" />
                </Stack>
                <Skeleton animation="wave" height={10} width="20%" />
              </Stack>
            ))}
          </Stack>
        </div>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...other}
    >
      <div style={{ width: "100%", margin: 0, padding: 0 }}>
        <CardHeader title={title} subheader={subheader} />

        <Chart
          type="radialBar"
          series={[series]}
          options={chartOptions}
          height={360}
        />

        <Stack spacing={3} sx={{ p: 3, marginTop: 10 }}>
          {data.map((category) => (
            <Stack
              key={category.name}
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <Avatar
                variant="rounded"
                sx={{
                  bgcolor: "background.neutral",
                  width: 48,
                  height: 48,
                  borderRadius: 1.5,
                }}
              >
                {category.icon}
              </Avatar>

              <Stack spacing={0.5} flexGrow={1}>
                <Typography
                  variant="subtitle2"
                  sx={{ cursor: cursor }}
                  onClick={() =>
                    manejarClickStack && manejarClickStack(category.name)
                  }
                >
                  {category.name}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {category.percentTask}% del total
                </Typography>
              </Stack>

              <Typography variant="subtitle2">
                {fNumber(category.quantityTask)} tareas
              </Typography>
            </Stack>
          ))}
        </Stack>
      </div>
    </Card>
  );
}
