import {
  Card,
  CardHeader,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import CalendarField from "../modalGav/Fields/CalendarField";
import { memo, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import Scrollbar from "src/components/Scrollbar";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import SearchNotFound from "src/components/SearchNotFound";
import { ThemeProvider } from "@material-ui/styles";
import CustomTablePagination from "src/components/CustomTablePagination";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import * as locales from "@material-ui/core/locale";

ModalDetalleResumenPropuestas.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  filtroFecha: PropTypes.string,
  setFiltroFecha: PropTypes.func,
  propuestas: PropTypes.array,
  cargandoPropuestas: PropTypes.bool,
};

export default function ModalDetalleResumenPropuestas({
  isOpen,
  setOpen,
  filtroFecha,
  setFiltroFecha,
  propuestas,
  cargandoPropuestas,
  title,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFechaFacturacion = useCallback(
    (newValue) => setFiltroFecha(dayjs(newValue).format("YYYY/MM")),
    [setFiltroFecha]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-modal-resumen-propuestas"
      id="dialog-modal-resumen-propuestas"
      fullWidth
      maxWidth="xl"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "grid",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <DialogTitle htmlFor="dialog-modal-resumen-propuestas">
            {title}
          </DialogTitle>
        </Grid>
        <Grid item xs={12} md={4} lg={4} marginTop={2}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item marginRight={3}>
              <CalendarField
                id="fecha-filtro"
                label="Filtrar por fecha"
                value={filtroFecha}
                onChange={handleChangeFechaFacturacion}
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                tamanio={12}
                maxDate={dayjs().format("YYYY/MM/DD")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogContent>
        <TablaPropuestas
          propuestas={propuestas}
          cargandoPropuestas={cargandoPropuestas}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "desc",
  orderBy: "fechaCreacion",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "rutSolicitante", label: "RUT solicitante", alignRight: false },
  { id: "nombreSolicitante", label: "Nombre solicitante", alignRight: false },
  {
    id: "telefonoSolicitante",
    label: "Celular solicitante",
    alignRight: false,
  },
  { id: "empleado", label: "Empleado", alignRight: false },
  { id: "fechaCreacion", label: "Fecha creación", alignRight: false },
  { id: "sucursal", label: "Sucursal", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "comentarios", label: "Comentarios", alignRight: false },
  { id: "" },
];

const TablaPropuestas = memo(function TablaPropuestas({
  propuestas,
  cargandoPropuestas,
  title,
  subheader,
}) {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);

  const propuestasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "nombreEmpleado",
      "apellidoEmpleado",
      "fecha",
      "nombreSolicitante",
      "rutSolicitante",
    ];
    return applySortFilter(
      propuestas,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [
    propuestas,
    statesTable.filterName,
    statesTable.order,
    statesTable.orderBy,
  ]);

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            propuestas.length
        )
      : 0;

  const seEncontroElemento = propuestasFiltradas.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      propuestasFiltradas.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(
      propuestasFiltradas.length / newRowsPerPage
    );
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      propuestasFiltradas.length / statesTable.rowsPerPageOptions
    );
    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  return (
    <Card>
      <Grid container>
        <Grid item xs={12} md={8} lg={8}>
          <CardHeader title={title} subheader={subheader} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "grid",
            justifyContent: { xs: "center", md: "right", lg: "right" },
            alignItems: "flex-end",
          }}
        >
          <TablaToolbar
            filterName={statesTable.filterName}
            onFilterName={handleFilterByName}
          />
        </Grid>
      </Grid>
      <Scrollbar>
        <TableContainer component={Paper}>
          {!cargandoPropuestas ? (
            <Table>
              <TablaHead
                order={statesTable.order}
                orderBy={statesTable.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={propuestas.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {propuestasFiltradas
                  .slice(
                    statesTable.currentPage * statesTable.rowsPerPageOptions,
                    statesTable.currentPage * statesTable.rowsPerPageOptions +
                      statesTable.rowsPerPageOptions
                  )
                  .map((row, index) => {
                    const {
                      id,
                      rutSolicitante,
                      nombreSolicitante,
                      telefonoSolicitante,
                      nombreEmpleado,
                      apellidoEmpleado,
                      fechaCreacion,
                      sucursal,
                      estado,
                      comentarios,
                    } = row;

                    return (
                      <TableRow hover key={id}>
                        <TableCell>
                          {statesTable.currentPage *
                            statesTable.rowsPerPageOptions +
                            index +
                            1}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {formatRut(rutSolicitante, RutFormat.DOTS_DASH)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {capitalizarPalabras(nombreSolicitante)}
                        </TableCell>
                        <TableCell>{telefonoSolicitante}</TableCell>
                        <TableCell>
                          {capitalizarPalabras(
                            `${nombreEmpleado} ${apellidoEmpleado}`
                          )}
                        </TableCell>
                        <TableCell>{fechaCreacion}</TableCell>
                        <TableCell>
                          {capitalizarPrimeraLetra(sucursal)}
                        </TableCell>
                        <TableCell>{capitalizarPrimeraLetra(estado)}</TableCell>
                        <TableCell>{comentarios}</TableCell>
                      </TableRow>
                    );
                  })}

                {filasVacias > 0 && (
                  <TableRow style={{ height: 53 * filasVacias }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
              </TableBody>
              {seEncontroElemento && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={statesTable.filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <ThemeProvider
                    theme={(outerTheme) =>
                      createTheme(outerTheme, locales[statesTable.locale])
                    }
                  >
                    <CustomTablePagination
                      count={propuestasFiltradas.length}
                      rowsPerPage={statesTable.rowsPerPageOptions}
                      page={statesTable.currentPage}
                      colSpan={10}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </ThemeProvider>
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </TableContainer>
      </Scrollbar>
    </Card>
  );
});

TablaPropuestas.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  propuestas: PropTypes.array,
  cargandoPropuestas: PropTypes.bool,
};
