import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Autocomplete,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { TareasListToolbar } from "./_dashboard/tareas";

export default function ModalFiltro(props) {
  const { cabecera, open, setOpen, info, onSubmit } = props;

  const [filterName, setFilterName] = useState("defecto");
  const unique = (arr) => [...new Set(arr.map((data) => data[cabecera.id]))];
  const [checked, setChecked] = useState(unique(info));
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setFilterName(event.target.value);
  };

  const [filtroAplicado, setFiltroAplicado] = useState({
    label: "Es Igual A... ",
    value: 0,
  });

  const [valorFiltro, setValorFiltro] = useState("");
  const [buscador, setBuscador] = useState("");

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const aplicarFiltroDefecto = () => {
    const data = info.filter(
      (datos) =>
        removeAccents(String(datos[cabecera.id]))
          .toLowerCase()
          .includes(removeAccents(buscador).toLowerCase()) &&
        checked.indexOf(datos[cabecera.id]) !== -1
    );

    onSubmit(data, "defecto");
  };

  const aplicarFiltroLetras = () => {
    if (valorFiltro !== "" && valorFiltro !== " ") {
      let datos;
      if (filtroAplicado.value === 0) {
        datos = info.filter(
          (data) =>
            removeAccents(String(data[cabecera.id])).toLowerCase() ===
            removeAccents(valorFiltro).toLowerCase()
        );
      }
      if (filtroAplicado.value === 1) {
        datos = info.filter(
          (data) =>
            removeAccents(String(data[cabecera.id])).toLowerCase() !==
            removeAccents(valorFiltro).toLowerCase()
        );
      }
      if (filtroAplicado.value === 2) {
        datos = info.filter((data) =>
          removeAccents(String(data[cabecera.id]))
            .toLowerCase()
            .startsWith(removeAccents(valorFiltro).toLowerCase())
        );
      }
      if (filtroAplicado.value === 3) {
        datos = info.filter((data) =>
          removeAccents(String(data[cabecera.id]))
            .toLowerCase()
            .endsWith(
              removeAccents(valorFiltro).toLowerCase(),
              valorFiltro.length
            )
        );
      }
      if (filtroAplicado.value === 4) {
        datos = info.filter((data) =>
          removeAccents(String(data[cabecera.id]))
            .toLowerCase()
            .includes(removeAccents(valorFiltro).toLowerCase())
        );
      }
      if (filtroAplicado.value === 5) {
        datos = info.filter(
          (data) =>
            !removeAccents(String(data[cabecera.id]))
              .toLowerCase()
              .includes(removeAccents(valorFiltro).toLowerCase())
        );
      }
      onSubmit(datos, "letras");
    }
  };

  const aplicarFiltro = () => {
    if (filterName === "defecto") {
      aplicarFiltroDefecto();
    }
    if (filterName === "letra") {
      aplicarFiltroLetras();
    }
    if (filterName === "quitar") {
      onSubmit(info, "quitar");
    }
    handleClose();
  };

  const tabla = () => {
    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };
    const handleFilterByName = (event) => {
      setBuscador(event.target.value);
    };

    return (
      <>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={7}>
            <TareasListToolbar
              filterName={buscador}
              onFilterName={handleFilterByName}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              size="large"
              onClick={() => setChecked([])}>
              Desmarcar Todo
            </Button>
          </Grid>
        </Grid>
        <List style={{ marginLeft: 20 }}>
          {unique(info)
            .sort((a, b) => {
              if (cabecera.id === "diasTranscurridos") {
                return parseInt(a) - parseInt(b);
              } else {
                return a - b;
              }
            })
            .filter((data) =>
              removeAccents(String(data))
                .toLowerCase()
                .includes(removeAccents(buscador).toLowerCase())
            )
            .map((value, index) => {
              const labelId = `checkbox-list-label-${value[cabecera.id]}`;
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments"></IconButton>
                  }
                  disablePadding>
                  <ListItemButton onClick={handleToggle(value)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value} />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </>
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm">
        <DialogTitle id="alert-dialog-title">{"Aplicar Filtro"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <FormLabel id="demo-radio-buttons-group-label">
              Filtros disponibles
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={filterName}
              onChange={handleChange}
              name="radio-buttons-group">
              <FormControlLabel
                value="quitar"
                control={<Radio />}
                label="Quitar Filtros"
              />
              <FormControlLabel
                value="defecto"
                control={<Radio />}
                label="Filtrar Por Filas"
              />
              {filterName === "defecto" && tabla()}
              <FormControlLabel
                value="letra"
                control={<Radio />}
                label="Filtrar por Frase"
              />
              {filterName === "letra" && (
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: 1, marginBottom: 12 }}>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="combo-b"
                      options={filtroLetraOptions}
                      value={filtroAplicado}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setFiltroAplicado(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Filtro a Aplicar"
                          value={filtroAplicado.label}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Valor"
                      value={valorFiltro}
                      onChange={(e) => setValorFiltro(e.target.value)}
                    />
                  </Grid>
                </Grid>
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => aplicarFiltro()} autoFocus>
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const filtroLetraOptions = [
  { label: "Es Igual A... ", value: 0 },
  { label: "No Es Igual A... ", value: 1 },
  { label: "Empieza Por...", value: 2 },
  { label: "Termina Por...", value: 3 },
  { label: "Contiene...", value: 4 },
  { label: "No Contiene", value: 5 },
];
