import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import Scrollbar from "../../Scrollbar";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function PlanesClienteSeleccionado(props) {
  const { value, cliente } = props;
  const classes = styles();
  const [locale] = useState("esES");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [planes, setPlanes] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/planes/${cliente.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setPlanes(response.data);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.pos}>
      {value === 2 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                >
                  Planes Asociados
                </Typography>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Sucursal</TableCell>
                      <TableCell align="center">Empleado</TableCell>
                      <TableCell align="center">Nombre Plan</TableCell>
                      <TableCell align="center">Valor Plan</TableCell>
                      <TableCell align="center">Descuento</TableCell>
                      <TableCell align="center">Valor Descuento</TableCell>
                      <TableCell align="center">Tipo Plan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planes &&
                      planes
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((element, index) => {
                          return (
                            <TableRow key={"listadoPlan_" + index}>
                              <TableCell
                                align="center"
                              >
                                {element.sucursal}
                              </TableCell>
                              <TableCell
                                align="center"
                              >
                                {element.nombreEmpleado + " " + element.apellidosEmpleado}
                              </TableCell>
                              <TableCell
                                align="center"
                              >
                                {element.refTipoPlan}
                              </TableCell>
                              <TableCell
                                align="center"
                              >
                                {element.valor + " " + element.tipoMoneda}
                              </TableCell>
                              <TableCell
                                align="center"
                              >
                                {element.descuento + "%"}
                              </TableCell>
                              <TableCell
                                align="center"
                              >
                                {element.valorDesc + " " + element.tipoMoneda}
                              </TableCell>
                              <TableCell
                                align="center"
                              >
                                {element.tipoFrecuencia}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </CardActions>
          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={planes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      )}
    </div>
  );
}
