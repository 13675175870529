import React, { useEffect, useRef, useState } from "react";
import Skeleton from "@material-ui/core/Skeleton";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import IconButton from "@material-ui/core/IconButton";
import * as locales from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  TareasListHead,
  TareasListToolbar,
} from "../components/_dashboard/tareas";
import ModifyTaskStateModal from "../components/_dashboard/tareas/ModifyTaskStateModal";
import { Alerta } from "../components/Alerta";
import SimpleModal from "../layouts/SimpleModal";
import ArchivosAdjuntosDialog from "../components/tareas/ArchivosAdjuntosDialog";
import { formatDate } from "../utils/converter";
import SubTareasDialog from "src/components/tareas/SubTareasDialog";
import ModalFiltro from "src/components/ModalFiltro";
import { useSelector } from "react-redux";
import moment from "moment";
import { tareasApi } from "../services/tareasApi";
import { AlertaToast } from "src/components/AlertaSwall";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_el) => {
      return (
        removeAccents(_el.nombre.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_el.tipoTarea.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_el.nombreCliente.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _el.fechaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        removeAccents(_el.estado.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function InicioTabla(props) {
  const navigate = useNavigate();
  const { value } = props;
  const isMounted = useRef(true);
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const [page, setPage] = useState(
    localStorage.getItem("pageHomeTask")
      ? parseInt(localStorage.getItem("pageHomeTask"), 10)
      : 0
  );
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("tipoTarea");
  const [filterName, setFilterName] = useState(
    localStorage.getItem("searchHomeTask")
      ? localStorage.getItem("searchHomeTask")
      : ""
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowHomePerPageTask")
      ? parseInt(localStorage.getItem("rowHomePerPageTask"), 10)
      : 10
  );
  const [locale] = useState("esES");
  const [tareas, setTareas] = useState([]);
  const [tareasOriginal, setTareasOriginal] = useState([]);
  const [showModifyTaskState, setShowModifyTaskState] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [specificTask, setSpecificTask] = useState(null);
  const tableHead = useState(defineTableHeader())[0];
  const [loadingTable, setLoadingTable] = useState(true);
  const [mostrarSubTareas, setMostrarSubTareas] = useState(false);
  const [idSubTarea, setIdSubtarea] = useState(null);

  const [filtrar, setFiltrar] = useState(false);
  const [cabecera, setCabecera] = useState({});

  const color = "success";
  const [showAlert, setShowAlert] = useState(false);

  const [
    getTablero,
    {
      data: tableroData,
      isSuccess: isSuccessTableroData,
      isFetching: isFetchingTableroData,
    },
  ] = tareasApi.useLazyGetTableroQuery();

  const [actualizarEstadoTarea] = tareasApi.useUpdateCambiaEstadoMutation();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function defineTableHeader() {
    const tableHeadAux = [
      { id: "nombreCliente", label: "Nombre Cliente", alignRight: false },
      { id: "nombre", label: "Tarea", alignRight: false },
      { id: "tipoTarea", label: "Tipo Tarea", alignRight: false },
      { id: "fechaInicio", label: "Fecha Inicio", alignRight: false },
      {
        id: "diasTranscurridos",
        label: "Días Transcurridos",
        alignRight: false,
      },
      { id: "estado", label: "Estado", alignRight: false },
      { id: "subtareas", label: "", alignRight: false },
    ];
    return tableHeadAux;
  }

  useEffect(() => {
    getTablero({
      refEmpleado: user.rut,
      sucursal: sucursalObtenida.refSucursal,
    });
  }, [user, sucursalObtenida, getTablero]);

  useEffect(() => {
    setLoadingTable(true);

    if (isSuccessTableroData) {
      setTareasOriginal(
        tableroData.filter(
          (el) =>
            el.estado !== "TERMINADA" &&
            el.estado !== "FACTURADO" &&
            el.estado !== "CANCELADO O RECHAZADO"
        )
      );
      setTareas(
        tableroData.filter(
          (el) =>
            el.estado !== "TERMINADA" &&
            el.estado !== "FACTURADO" &&
            el.estado !== "CANCELADO O RECHAZADO"
        )
      );
      setLoadingTable(false);
    }
  }, [isFetchingTableroData, isSuccessTableroData, tableroData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tareas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("pageHomeTask", parseInt(newPage, 10));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem(
      "rowHomePerPageTask",
      parseInt(event.target.value, 10)
    );
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    localStorage.setItem("searchHomeTask", event.target.value);
    setFilterName(event.target.value);
  };

  const handleChangeTaskState = (id) => {
    const selectedTaskAux = tareas.find((t) => t.id === id);
    setSelectedTask(selectedTaskAux);
    setShowModifyTaskState(true);
  };

  const doChangeTaskState = (newState) => {
    if (selectedTask.estado === newState) return setShowModifyTaskState(false);
    let modificacionEstado = {
      ...selectedTask,
      estado: newState,
    };

    if (newState === "TERMINADA") {
      if (selectedTask.tipoTarea === "ESPECIAL") {
        modificacionEstado.estado = "PENDIENTE DE FACTURACION";
      }
      modificacionEstado.fechaTermino = getToday();
    } else {
      modificacionEstado.fechaTermino = "";
    }
    setShowModifyTaskState(false);
    updateTaskInDB(modificacionEstado);
  };

  const getToday = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getUTCFullYear();
    return formatDate(`${year}/${month}/${day}`);
  };

  const updateTaskInDB = (modificacionEstado) => {
    actualizarEstadoTarea({
      id: modificacionEstado.id,
      body: {
        estado: modificacionEstado.estado,
        fechaTermino: modificacionEstado.fechaTermino,
      },
    })
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Tarea",
          action: "actualizada",
        }).fire();
        getTablero({
          refEmpleado: user.rut,
          sucursal: sucursalObtenida.refSucursal,
        }).refetch();
      })
      .catch(() => {
        AlertaToast({
          action: "No se pudo actualizar la tarea en la base de datos.",
          fullText: true,
          type: "error",
        }).fire();
        setSelectedTask(null);
      });
  };

  const handleAttachment = async (id) => {
    setSpecificTask(id);
    setShowAttachments(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tareas.length) : 0;

  const filteredItems = applySortFilter(
    tareas,
    getComparator(order, orderBy),
    filterName
  );

  const isElementNotFound = filteredItems.length === 0;

  const renderTableBody = () => {
    return filteredItems
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const {
          id,
          nombre,
          tipoTarea,
          nombreCliente,
          fechaInicio,
          estado,
          diasTranscurridos,
        } = row;
        const isItemSelected = selected.indexOf(id) !== -1;
        let estiloFila = { backgroundColor: "#FFFFFF" };
        if (diasTranscurridos >= 14) {
          estiloFila = { backgroundColor: "#FFF9C4" };
        }

        return (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
            style={estiloFila}
          >
            <TableCell
              style={{ cursor: "pointer" }}
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
            >
              {nombreCliente}
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
            >
              {nombre}
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
            >
              {tipoTarea}
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
            >
              {moment(fechaInicio, "YYYY/MM/DD").format("DD/MM/YYYY")}
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              align="left"
              onClick={() => {
                navigate("/dashboard/tareas/" + id);
              }}
            >
              {diasTranscurridos === 1
                ? diasTranscurridos + " Día"
                : diasTranscurridos + " Días"}
            </TableCell>
            <TableCell align="left">
              <Label
                onClick={() => handleChangeTaskState(id)}
                style={{ cursor: "pointer" }}
                variant="ghost"
                color={
                  (estado === "PENDIENTE" && "error") ||
                  (estado === "EN PROCESO" && "warning") ||
                  (estado === "TERMINADA" && "success") ||
                  "info"
                }
              >
                {sentenceCase(estado)}
              </Label>
              {estado !== "TERMINADA" &&
                estado !== "PENDIENTE DE FACTURACION" &&
                estado !== "FACTURADO" && (
                  <Tooltip title="Adjuntar">
                    <IconButton onClick={() => handleAttachment(row)}>
                      <AttachmentIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </TableCell>
            <TableCell align="left">
              <Label
                onClick={() => {
                  setIdSubtarea(row);
                  setMostrarSubTareas(true);
                }}
                style={{ cursor: "pointer" }}
                variant="ghost"
                color={"info"}
              >
                {sentenceCase("Subtareas")}
              </Label>
            </TableCell>
          </TableRow>
        );
      });
  };

  if (value !== 1) return <div />;

  return (
    <Page title="TAXTIC">
      <Card style={{ marginTop: 5 }}>
        <TareasListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {!loadingTable ? (
              <Table>
                <TareasListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={tareas.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  setCabecera={setCabecera}
                  setFiltrar={setFiltrar}
                />
                <TableBody>
                  {renderTableBody()}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isElementNotFound && tareas.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {tareas.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No hay tareas que mostrar.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={tareas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>

      {selectedTask && (
        <ModifyTaskStateModal
          task={selectedTask}
          onSubmit={doChangeTaskState}
          onClose={() => setShowModifyTaskState(false)}
          states={[
            "PENDIENTE",
            "EN PROCESO",
            "OBSERVADA SII",
            "TERMINADA",
            "FACTURADO",
          ]}
          open={showModifyTaskState}
          cargandoDatos={false}
        />
      )}

      <Alerta
        showAlert={showError}
        setShowAlert={setShowError}
        message={error}
        color="error"
      />

      {showAlert && (
        <Alerta
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          message={error}
          color={color}
        />
      )}

      {specificTask && (
        <SimpleModal
          maxWidth="md"
          open={showAttachments}
          title="Archivo Adjunto"
          showCancelButton={false}
          showAcceptButton={false}
        >
          <ArchivosAdjuntosDialog
            tarea={specificTask}
            setTarea={setSpecificTask}
            setShowError={setShowError}
            setError={(msg) => {
              setError(msg);
              setShowError(true);
            }}
            onClose={() => setShowAttachments(false)}
          />
        </SimpleModal>
      )}
      {mostrarSubTareas && (
        <SubTareasDialog
          open={mostrarSubTareas}
          setOpen={setMostrarSubTareas}
          tarea={idSubTarea}
        />
      )}

      {filtrar && (
        <ModalFiltro
          cabecera={cabecera}
          open={filtrar}
          setOpen={setFiltrar}
          info={tareasOriginal}
          setInfoAux={setTareas}
        />
      )}
    </Page>
  );
}
