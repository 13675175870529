import { Box, Card, Skeleton, Stack, Typography } from "@material-ui/core";
import { fNumber, formatAsCLP } from "src/utils/formatNumber";
import PropTypes from "prop-types";

AppWidgetSummary.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  type: PropTypes.string,
  icon: PropTypes.object,
  color: PropTypes.string,
  sx: PropTypes.object,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
  cursor: PropTypes.string,
};

export default function AppWidgetSummary({
  title,
  total,
  type = "normal",
  icon,
  color = "primary",
  sx,
  loading = false,
  handleClick = null,
  cursor = "default",
  ...other
}) {
  if (loading) {
    return (
      <Card
        component={Stack}
        spacing={3}
        direction="row"
        sx={{
          px: 3,
          py: 4.6,
          borderRadius: 2,
          ...sx,
        }}
        {...other}
      >
        <Box
          sx={{
            width: 64,
            height: 64,
            borderRadius: 1,
            backgroundColor: "grey.300",
          }}
        />

        <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" width="20%" height={20} />
          <Skeleton variant="text" width="60%" height={16} />
        </Stack>
      </Card>
    );
  }
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      onClick={handleClick}
      sx={{
        px: 3,
        py: 4.6,
        borderRadius: 2,
        cursor: cursor,
        ...sx,
      }}
      {...other}
    >
      {icon && (
        <Box
          sx={{
            width: 64,
            height: 64,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
      )}

      <Stack spacing={0.5}>
        <Typography variant="h4">
          {/* {type === "normal" ? fNumber(total) : "$" + fShortenNumber(total)} */}
          {type === "normal" ? fNumber(total) : formatAsCLP(total)}
        </Typography>

        <Typography
          variant="subtitle2"
          display="block"
          sx={{ color: "text.disabled" }}
        >
          {title}
        </Typography>
      </Stack>
    </Card>
  );
}
