import { memo, useCallback } from "react";
import {
  Chip,
  CircularProgress,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";

const SucursalesField = memo(
  ({
    sucursales,
    selectedSucursales,
    setSelectedSucursales,
    loading,
    colorChip,
    error,
    helperText,
  }) => {
    const handleChipClick = useCallback(
      (sucursal) => {
        let updatedChips;

        const elementoEncontrado = selectedSucursales.find(
          (seleccionada) => seleccionada.nombre === sucursal.nombre
        );
        if (elementoEncontrado) {
          updatedChips = selectedSucursales.filter(
            (sucursalSeleccionada) =>
              sucursalSeleccionada.nombre !== sucursal.nombre
          );
        } else {
          updatedChips = [...selectedSucursales, sucursal];
        }
        setSelectedSucursales("selectedSucursales", updatedChips);
      },
      [selectedSucursales, setSelectedSucursales]
    );

    if (loading) {
      return (
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
            marginTop={1}
            marginBottom={1}
          >
            <CircularProgress color="primary" size={20} />
          </Grid>
        </Grid>
      );
    }
    if (sucursales.length === 0) {
      return (
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
            marginTop={1}
            marginBottom={1}
          >
            <Typography variant="overline" display="block" gutterBottom>
              Sin prorrateos configurados
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
          marginBottom={1}
        >
          {sucursales.map((sucursal) => (
            <Grid item key={sucursal.nombre}>
              <Chip
                label={capitalizarPrimeraLetra(sucursal.nombre)}
                icon={
                  selectedSucursales.find(
                    (seleccionada) => seleccionada.nombre === sucursal.nombre
                  ) ? (
                    <Done />
                  ) : null
                }
                variant="outlined"
                color={
                  selectedSucursales.find(
                    (seleccionada) => seleccionada.nombre === sucursal.nombre
                  )
                    ? colorChip
                    : "default"
                }
                clickable
                onClick={() => handleChipClick(sucursal)}
              />
            </Grid>
          ))}
        </Grid>
        {error && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <FormHelperText error>{helperText}</FormHelperText>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default SucursalesField;
