import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { API_SERVER, dbx } from "src/utils/urlBack_End";
import axios from "axios";
import saveFill from "@iconify/icons-eva/save-outline";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  Grid,
  Alert,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";

const styles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 7,
    marginTop: 12,
  },
  gridContainer: {},
  Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  textField: {
    width: "100%",
    marginBottom: 18,
    marginTop: 15,
  },
  textFieldComentario: {
    marginBottom: 18,
    marginTop: 20,
  },
  hidden: {
    display: "none",
  },
}));

export default function AdjuntarPoderCliente(props) {
  const {
    open,
    setOpen,
    setLoading,
    cliente,
    setCliente,
    archivoObtenido,
    setArchivoObtenido,
    setShowAlert,
    setMessage,
  } = props;
  const classes = styles();
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const guardarDatos = async () => {
    setIsSubmitting(true);
    if (file !== null) {
      await uploadFile("Tareas");
    } else {
      setIsSubmitting(false);
      setShowAlert(true);
      setMessage("Debe subir un archivo");
    }
  };

  const cerrar = () => {
    handleClose();
  };

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    setFile(fileAux);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/clientes/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setShowAlert(true);
        setCliente(response.data);
      });
    axios
      .get(`${API_SERVER}/clientes/poder/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setArchivoObtenido(response.data[0]);
        setIsSubmitting(false);
        setOpen(false);
      });
  };

  async function uploadFile() {
    setLoading(true);
    if (!file) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      return;
    }

    try {
      await dbx.filesUpload({
        path: `/Clientes/${cliente.rut}/Poder_` + cliente.rut + ".pdf",
        contents: file,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: `/Clientes/${cliente.rut}/Poder_` + cliente.rut + ".pdf",
        });
        let url = shareResponse.result.url;
        axios
          .post(
            `${API_SERVER}/clientes/poder`,
            {
              refCliente: cliente.rut,
              urlPoder: url,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            actualizar();
          });
      } catch (ex) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;
        if (archivoObtenido) {
          axios
            .put(API_SERVER + "/clientes/poder", {
              id: archivoObtenido.id,
              urlPoder: url,
            },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("token")
                }
              })
            .then(function (response) {
              actualizar();
            });
        }
      } finally {
        setLoading(false);
      }
    } catch (ex) {
    } finally {
      setFile(null);
      setLoading(false);
    }
  }
  return (
    <>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Subir Archivo</DialogTitle>
          <DialogContent>
            <Card style={{ padding: "1rem" }}>
              <Alert severity="info">
                Haga click en el botón 'Seleccionar Archivo' para subir un
                documento.
              </Alert>

              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={4} md={4}>
                  <input
                    className={classes.hidden}
                    id="contained-button-file"
                    onChange={handleFileInput}
                    type="file"
                  />

                  <label htmlFor={"contained-button-file"}>
                    <Button
                      fullWidth
                      component="span"
                      m={1}
                      variant="contained">
                      Seleccionar Archivo
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={6} md={6}>
                  {file && (
                    <span>
                      <span>
                        <> {file.name}</>
                      </span>

                      <span>
                        <IconButton
                          onClick={handleDeleteFile}
                          aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </span>
                  )}
                </Grid>
              </Grid>
            </Card>

            <DialogActions>
              <LoadingButton onClick={() => cerrar()} color="error">
                Cancelar
              </LoadingButton>
              <LoadingButton
                type="submit"
                color="primary"
                startIcon={<Icon icon={saveFill} />}
                loading={isSubmitting}
                onClick={guardarDatos}>
                Guardar Cambios
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
