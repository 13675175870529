import React, { useState } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
// material
import {
  TextField,
  Card,
  Stack,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { validateRut } from "@fdograph/rut-utilities";

export default function VerPropuestaDatosSolicitante(props) {
  const { setPropuesta, propuesta } = props;

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [mostrarCircular, setMostrarCircular] = useState(false);

  const [nombreSolicitante, setNombreSolicitante] = useState(
    propuesta.nombreSolicitante
  );
  const [rutSolicitante, setRutSolicitante] = useState(
    propuesta.rutSolicitante
  );
  const [telefonoSolicitante, setTelefonoSolicitante] = useState(
    propuesta.telefonoSolicitante
  );
  const [correoSolicitante, setCorreoSolicitante] = useState(
    propuesta.correoSolicitante
  );
  const [comentarios, setComentarios] = useState(propuesta.comentarios);

  const handleChangeNombreSolicitante = (event) => {
    setNombreSolicitante(event.target.value);
  };

  const handleChangeRutSolicitante = (event) => {
    setRutSolicitante(event.target.value);
  };

  const handleChangeTelefonoSolicitante = (event) => {
    setTelefonoSolicitante(event.target.value);
  };

  const handleChangeCorreoSolicitante = (event) => {
    setCorreoSolicitante(event.target.value);
  };

  const handleChangeComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const guardarCambios = () => {
    setMostrarCircular(true);
    if (
      rutSolicitante === "" ||
      rutSolicitante === " " ||
      nombreSolicitante === "" ||
      nombreSolicitante === " " ||
      telefonoSolicitante === "" ||
      telefonoSolicitante === " " ||
      correoSolicitante === "" ||
      correoSolicitante === " "
    ) {
      setColorNotificacion("info");
      setMensajeNotificacion("Completar los datos requeridos.");
      setMostrarCircular(false);
      setOpenNotificacion(true);
    } else {
      if (!validateRut(rutSolicitante)) {
        setColorNotificacion("error");
        setMensajeNotificacion("El rut es invalido");
        setMostrarCircular(false);
        setOpenNotificacion(true);
      } else {
        let datosSolicitante = {
          id: propuesta.id,
          rutSolicitante: rutSolicitante,
          nombreSolicitante: nombreSolicitante.toUpperCase(),
          telefonoSolicitante: telefonoSolicitante,
          correoSolicitante: correoSolicitante,
          comentarios: comentarios.toUpperCase(),
        };
        axios
          .put(API_SERVER + "/propuestas/", datosSolicitante, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            setRutSolicitante(rutSolicitante);
            setNombreSolicitante(nombreSolicitante.toUpperCase());
            setTelefonoSolicitante(telefonoSolicitante);
            setCorreoSolicitante(correoSolicitante);
            setComentarios(comentarios.toUpperCase());
            setColorNotificacion("success");
            setMensajeNotificacion("Datos cambiados correctamente");
            setMostrarCircular(false);
            setOpenNotificacion(true);
            axios
              .get(`${API_SERVER}/propuestas/${propuesta.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then(function (response) {
                if (response.data === -2) window.location.reload();
                setPropuesta(response.data);
              })
              .catch(function (error) {});
          })
          .catch(function (error) {});
      }
    }
  };

  return (
    <>
      <Card>
        <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
          Datos Solicitante
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px" }}
          mb={5}>
          <TextField
            margin="dense"
            label="Rut Solicitante"
            type="text"
            value={rutSolicitante}
            onChange={handleChangeRutSolicitante}
            fullWidth
            required
            disabled={propuesta.estado === "ACEPTADA" && true}
          />

          <TextField
            margin="dense"
            label="Nombre Solicitante"
            type="text"
            value={nombreSolicitante}
            onChange={handleChangeNombreSolicitante}
            fullWidth
            required
            disabled={propuesta.estado === "ACEPTADA" && true}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px" }}>
          <TextField
            margin="dense"
            label="Teléfono Solicitante"
            type="text"
            value={telefonoSolicitante}
            onChange={handleChangeTelefonoSolicitante}
            fullWidth
            required
            disabled={propuesta.estado === "ACEPTADA" && true}
          />

          <TextField
            margin="dense"
            label="Correo Solicitante"
            type="email"
            value={correoSolicitante}
            onChange={handleChangeCorreoSolicitante}
            fullWidth
            required
            disabled={propuesta.estado === "ACEPTADA" && true}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px" }}>
          <TextField
            margin="dense"
            label="Comentarios"
            type="text"
            rows={4}
            value={comentarios}
            onChange={handleChangeComentarios}
            fullWidth
            multiline
            disabled={propuesta.estado === "ACEPTADA" && true}
          />
        </Stack>

        <div
          style={{
            textAlign: "right",
            marginRight: "25px",
            marginBottom: "25px",
          }}>
          {mostrarCircular ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={guardarCambios}
              disabled={propuesta.estado === "ACEPTADA" && true}>
              Guardar Cambios
            </Button>
          )}
        </div>
      </Card>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}>
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}>
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
    </>
  );
}
