import React, { useRef, useState, useEffect } from "react";
import Label from "../Label";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";
import { Titulo } from "../Titulo";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import { filter } from "lodash";
import {
  Paper,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Skeleton,
  Typography,
  Grid,
  TablePagination,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons//KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons//KeyboardArrowUp";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import { formatRut } from "@fdograph/rut-utilities";
import { useSelector } from "react-redux";

const TABLE_HEAD = [
  { id: "a", label: "", alignRight: false },
  { id: "nombre", label: "Nombre", alignRight: false },
];

const TABLE_HEAD_CLIENTES = [
  { id: "nombreCliente", label: "Nombre Cliente", alignRight: false },
  { id: "rutCliente", label: "Rut Cliente", alignRight: false },
  { id: "fechaCreacion", label: "Fecha Creación", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "contratoFirmado", label: "Contrato Firmado", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return _user.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterClientes(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.nombreCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaCreacion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.contratoFirmado.toLowerCase().indexOf(query.toLowerCase()) !==
        -1 ||
        _user.rutCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function Row(props) {
  const { plan, indice } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [locale] = useState("esES");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("nombre");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = plan.clientes.map((n) => n.nombre);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilterClientes(
    plan.clientes,
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - plan.clientes.length) : 0;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{plan.nombre}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Titulo style={{ marginBottom: "1rem" }}>
                <Typography variant="h4" gutterBottom>
                  Clientes
                </Typography>
              </Titulo>
              <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={12} md={3}>
                  <TablaToolbar
                    numSelected={selected?.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  />
                </Grid>
              </Grid>
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="purchases">
                    <TablaHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD_CLIENTES}
                      rowCount={plan.clientes.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((cliente) => (
                          <TableRow key={cliente.rutCliente + plan.nombre}>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/dashboard/planes/" + cliente.id)
                              }
                            >
                              {cliente.nombreCliente}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/dashboard/planes/" + cliente.id)
                              }
                            >
                              {formatRut(cliente.rutCliente)}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/dashboard/planes/" + cliente.id)
                              }
                            >
                              {cliente.fechaCreacion}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/dashboard/planes/" + cliente.id)
                              }
                            >
                              <Label
                                variant="ghost"
                                color={
                                  (cliente.estado === "INACTIVO" && "error") ||
                                  "success"
                                }
                              >
                                {sentenceCase(cliente.estado)}
                              </Label>
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/dashboard/planes/" + cliente.id)
                              }
                            >
                              <Label
                                variant="ghost"
                                color={
                                  (cliente.contratoFirmado === "NO" &&
                                    "error") ||
                                  "success"
                                }
                              >
                                {sentenceCase(cliente.contratoFirmado)}
                              </Label>
                            </TableCell>
                          </TableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow
                          key={plan.nombre + "000" + indice}
                          style={{ height: 53 * emptyRows }}
                        >
                          <TableCell align="center" colSpan={12} sx={{ py: 7 }}>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ThemeProvider
                theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={plan.clientes.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </ThemeProvider>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TablaPlanExtendido(props) {
  const { value } = props;
  const [planes, setPlanes] = useState([]);
  const {
    empleado: user,
    sucursal: sucursalObtenida,
  } = useSelector((state) => state.auth.user);
  const isMounted = useRef(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [locale] = useState("esES");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("nombre");

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tipos/tipoPlanes`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        let planesAux = response.data;
        Promise.all(
          response.data.map((data, index) =>
            axios
              .get(`${API_SERVER}/planes/tipoPlan/${sucursalObtenida.refSucursal}/${data.nombre}`, {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("token")
                }
              })
              .then(function (response) {
                if (response.data === -2) window.location.reload();
                planesAux[index]["clientes"] = response.data;
              })
          )
        )
          .then(function () {
            setPlanes(planesAux);
          })
          .finally(() => {
            if (isMounted.current) {
              setLoadingTable(false);
            }
          });
      });
  }, [user.rut, sucursalObtenida.refSucursal]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = planes.map((n) => n.nombre);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    planes,
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - planes.length) : 0;

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <div>
      {value === 1 && (
        <>
          {" "}
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} md={3}>
              <TablaToolbar
                numSelected={selected?.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer component={Paper}>
              {!loadingTable ? (
                <Table aria-label="collapsible table">
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={planes.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((plan, index) => {
                        return (
                          <Row key={plan.nombre + "_" + index} plan={plan} />
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell align="center" colSpan={6}>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>
          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={planes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </>
      )}
    </div>
  );
}
