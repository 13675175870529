import { useState } from "react";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { tiposApi } from "src/services/tiposApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
export default function AgregarTarea(props) {
  const { open, setOpen, monedas } = props;
  const [nombreTarea, setNombreTarea] = useState("");
  const [valorTarea, setValorTarea] = useState(0);
  const [tipoMoneda, setTipoMoneda] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [tipoTarea, setTipoTarea] = useState("");

  const [agregarTipoTarea] = tiposApi.useAddTipoTareasMutation();

  const handleChangeTipoMoneda = (event) => {
    setTipoMoneda(event.target.value);
  };

  const handleChangeTipoTarea = (event) => {
    setTipoTarea(event.target.value);
  };

  const handleChangeNombreTarea = (event) => {
    setNombreTarea(event.target.value);
  };

  const handleChangValorTarea = (event) => {
    setValorTarea(event.target.value);
  };

  const handleChangeDescripcionPlan = (event) => {
    setDescripcion(event.target.value);
  };

  const guardarPlan = () => {
    if (
      nombreTarea === "" ||
      valorTarea === "" ||
      tipoMoneda === "" ||
      tipoTarea === ""
    ) {
      AlertaToast({
        action: 'Todos los campos deben estar completos',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialogNuevaTipoTarea'),
      }).fire()
    } else {
      let valorFinal = valorTarea;
      if (String(valorFinal).includes(",")) {
        valorFinal = String(valorFinal).replaceAll(",", ".");
        valorFinal = parseInt(valorFinal);
      }
      let tarea = {
        nombre: nombreTarea.toUpperCase(),
        valor: valorFinal,
        tipoMoneda: tipoMoneda.toUpperCase(),
        descripcion: descripcion,
        tipoTarea: tipoTarea.toUpperCase(),
      };

      AlertaModal({
        title: 'Agregar la tarea',
        confirmButtonText: 'Sí, agregar',
        target: document.getElementById('dialogNuevaTipoTarea'),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            agregarTipoTarea(tarea)
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: 'Tarea',
                  action: 'agregada'
                }).fire()
                setOpen(false);
              }).catch(() => {
                AlertaToast({
                  action: 'Error en el servidor',
                  fullText: true,
                  type: 'error',
                  target: document.getElementById('dialogNuevaTipoTarea'),
                }).fire()
              })

          }
        })
    }
  };

  const cancelar = () => {
    setNombreTarea("");
    setValorTarea(0);
    setTipoMoneda("");
    setDescripcion("");
    setTipoTarea("");
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        id="dialogNuevaTipoTarea"
      >
        <DialogTitle id="form-dialog-title">Nueva Tarea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Tarea"
            type="text"
            value={nombreTarea}
            onChange={handleChangeNombreTarea}
            fullWidth
            required
          />

          <TextField
            margin="dense"
            label="Valor Tarea"
            value={valorTarea}
            onChange={handleChangValorTarea}
            type="number"
            fullWidth
            required
          />

          <TextField
            style={{ marginTop: "10px", width: "100%" }}
            id="outlined-multiline-static"
            label="Descripción Tarea"
            multiline
            rows={4}
            variant="outlined"
            value={descripcion}
            onChange={handleChangeDescripcionPlan}
          />

          <FormControl style={{ marginTop: "10px", width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Tipo Moneda (*)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ marginTop: "10px" }}
              value={tipoMoneda}
              onChange={handleChangeTipoMoneda}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {monedas.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.nombre}>
                    {element.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ marginTop: "10px", width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Tipo Tarea (*)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              style={{ marginTop: "10px" }}
              value={tipoTarea}
              onChange={handleChangeTipoTarea}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              <MenuItem value={"ESPECIAL"}>ESPECIAL</MenuItem>
              <MenuItem value={"NORMAL"}>NORMAL</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} color="error">
            Cancelar
          </Button>
          <Button onClick={guardarPlan} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
}
