import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  alpha,
  Card,
  CardHeader,
  Checkbox,
  createTheme,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import Scrollbar from "src/components/Scrollbar";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import SearchNotFound from "src/components/SearchNotFound";
import { ThemeProvider } from "@material-ui/styles";
import CustomTablePagination from "src/components/CustomTablePagination";
import * as locales from "@material-ui/core/locale";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { fDateLong } from "src/utils/formatTime";
import { LinkOutlined } from "@material-ui/icons";
import ModalEnlaceGrupoCliente from "src/components/Modal/modalEnlaceGrupoCliente";

const EnhancedTableToolbar = React.memo(
  ({
    numSelected,
    title,
    subheader,
    statesTable,
    handleFilterByName,
    abrirModal,
  }) => {
    const toolbarStyles = {
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
      bgcolor:
        numSelected > 0
          ? (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              )
          : undefined,
    };
    return (
      <Toolbar sx={toolbarStyles}>
        <Grid container>
          <Grid item xs={12} md={8} lg={8}>
            <CardHeader
              title={title}
              subheader={
                numSelected > 0
                  ? `${numSelected} seleccionado${numSelected > 1 ? "s" : ""}`
                  : subheader
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{
              display: "grid",
              justifyContent: { xs: "center", md: "right", lg: "right" },
              alignItems: "flex-end",
            }}
          >
            <TablaToolbar
              filterName={statesTable.filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
        </Grid>
        {numSelected > 0 && (
          <Tooltip title="Enlazar a grupo">
            <IconButton
              onClick={abrirModal}
              color="primary"
              sx={{ marginRight: 2 }}
            >
              <LinkOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  statesTable: PropTypes.object.isRequired,
  handleFilterByName: PropTypes.func.isRequired,
  abrirModal: PropTypes.func.isRequired,
};

const TablaClientesSinGrupo = ({
  rows,
  title,
  subheader,
  loading = false,
  tabIndex,
}) => {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [abrirModalEnlace, setAbrirModalEnlace] = useState(false);

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }
    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  const rowsFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["rut", "nombre"];
    return applySortFilter(
      rows,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [rows, statesTable.filterName, statesTable.order, statesTable.orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            rows.length
        )
      : 0;

  const seEncontroElemento = rowsFiltradas.length === 0;

  const handleChangePage = useCallback(
    (event, newPage) => {
      const totalPages = Math.ceil(
        rowsFiltradas.length / statesTable.rowsPerPageOptions
      );
      const currentPage = Math.min(newPage, totalPages - 1);
      setStateTable({ ...statesTable, currentPage });
    },
    [rowsFiltradas.length, statesTable]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const newRowsPerPage = Number(event.target.value);
      const newTotalPages = Math.ceil(rowsFiltradas.length / newRowsPerPage);
      const currentPage =
        statesTable.currentPage < newTotalPages
          ? statesTable.currentPage
          : newTotalPages - 1;
      setStateTable({
        ...statesTable,
        rowsPerPageOptions: newRowsPerPage,
        currentPage,
      });
    },
    [rowsFiltradas.length, statesTable]
  );

  const handleSelectAllClick = useCallback(() => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      setSelectedRows(rowsFiltradas);
      setSelectAll(true);
    }
  }, [rowsFiltradas, selectAll]);

  const handleRowSelection = useCallback(
    (row) => {
      const selectedIndex = selectedRows.indexOf(row);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      setSelectedRows(newSelected);
      setSelectAll(newSelected.length === rowsFiltradas.length);
    },
    [rowsFiltradas.length, selectedRows]
  );

  const manejarModalEnlaceGrupo = useCallback(() => {
    setAbrirModalEnlace(true);
  }, []);

  if (tabIndex !== 1) {
    return null;
  }

  return (
    <>
      <Card>
        <EnhancedTableToolbar
          numSelected={selectedRows.length}
          title={title}
          subheader={subheader}
          statesTable={statesTable}
          handleFilterByName={handleFilterByName}
          abrirModal={manejarModalEnlaceGrupo}
        />
        <Scrollbar>
          <TableContainer component={Paper}>
            {!loading ? (
              <Table>
                <TablaHead
                  order={statesTable.order}
                  orderBy={statesTable.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  onRequestSort={handleRequestSort}
                  select={true}
                  selectedRows={selectedRows}
                  handleSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {rowsFiltradas
                    .slice(
                      statesTable.currentPage * statesTable.rowsPerPageOptions,
                      statesTable.currentPage * statesTable.rowsPerPageOptions +
                        statesTable.rowsPerPageOptions
                    )
                    .map((row, index) => (
                      <TableRow
                        hover
                        key={row.rut}
                        selected={selectedRows.includes(row)}
                      >
                        <TableCell padding="checkbox">
                          <Tooltip title="Seleccionar cliente">
                            <Checkbox
                              checked={selectedRows.includes(row)}
                              onChange={() => handleRowSelection(row)}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {statesTable.currentPage *
                            statesTable.rowsPerPageOptions +
                            index +
                            1}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {formatRut(row.rut, RutFormat.DOTS_DASH)}
                          </Typography>
                        </TableCell>
                        <TableCell>{capitalizarPalabras(row.nombre)}</TableCell>
                        <TableCell>{fDateLong(row.fechaIngreso)}</TableCell>
                        <TableCell>{row.correo.toLowerCase()}</TableCell>
                        <TableCell>{row.telefono}</TableCell>
                      </TableRow>
                    ))}

                  {filasVacias > 0 && (
                    <TableRow style={{ height: 53 * filasVacias }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
                {seEncontroElemento && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={statesTable.filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <ThemeProvider
                      theme={(outerTheme) =>
                        createTheme(outerTheme, locales[statesTable.locale])
                      }
                    >
                      <CustomTablePagination
                        count={rowsFiltradas.length}
                        rowsPerPage={statesTable.rowsPerPageOptions}
                        page={statesTable.currentPage}
                        colSpan={7}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </ThemeProvider>
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
      </Card>
      {abrirModalEnlace && (
        <ModalEnlaceGrupoCliente
          isOpen={abrirModalEnlace}
          setOpen={setAbrirModalEnlace}
          title="Enlazar clientes a grupo"
          clientes={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
    </>
  );
};

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "fechaIngreso", label: "Fecha ingreso", alignRight: false },
  { id: "correo", label: "Correo", alignRight: false },
  { id: "telefono", label: "Celular", alignRight: false },
  // { id: "" },
];

TablaClientesSinGrupo.propTypes = {
  rows: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
  loading: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default React.memo(TablaClientesSinGrupo);
