import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@material-ui/core";
import { AddLink } from "@material-ui/icons";
import { memo, useCallback, useMemo, useState } from "react";
import CustomTablePagination from "src/components/CustomTablePagination";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import { formatAsCLP } from "src/utils/formatNumber";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import dayjs from "dayjs";
import * as locales from "@material-ui/core/locale";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";
import ModalEnlaceSucursal from "src/components/Modal/modalEnlaceSucursal";
import { AlertaModal } from "src/components/AlertaSwall";

const FacturasSinSucursal = memo(({ tabIndex, data, isLoadingData }) => {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const [statesModal, setStateModal] = useState(INITIAL_STATE_MODAL);
  const [rowSelected, setRowSelected] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [cargandoEnlaceFacturas, setCargandoEnlaceFacturas] = useState(false);

  // Función para descargar la factura sin sucursal. Revisar si dejarla añadida o no.
  // const [indiceCargandoVisualizar, setIndiceCargandoVisualizar] =
  //   useState(null);
  const [indiceCargandoEnlaceSucursal, setIndiceCargandoEnlaceSucursal] =
    useState(null);

  // const [obtenerFacturaPorToken] =
  //   facturasApi.useLazyObtenerDocumentoPorTokenQuery();
  // const [obtenerDocumentoReferencia] =
  //   facturasApi.useLazyObtenerDocumentoReferenciaQuery();

  const handleRowSelection = (factura) => {
    const selectedIndex = selectedRows.indexOf(factura);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, factura);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
    setSelectAll(newSelected.length === facturasFiltradas.length);
  };

  const handleSelectAllClick = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      setSelectedRows(facturasFiltradas);
      setSelectAll(true);
    }
  };

  const facturasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "folio",
      "nombreCliente",
      "rutCliente",
      "valorPesos",
      "estadoFactura",
      "detalleFactura",
    ];
    return applySortFilter(
      data,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [data, statesTable.filterName, statesTable.order, statesTable.orderBy]);

  const manejarAbrirModalEnlaceSucursal = useCallback(
    (factura, index) => {
      setIndiceCargandoEnlaceSucursal(index);
      setRowSelected(factura);
      setStateModal({ ...statesModal, abrirModal: true });
    },
    [statesModal]
  );

  const enlazarFacturasMasivas = () => {
    AlertaModal({
      title:
        "¡Atención! La función de enlace masivo solo está disponible para la vinculación de facturas asociadas a planes",
      fullText: true,
      confirmButtonText: "Continuar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          setStateModal({ ...statesModal, abrirModal: true });
          setCargandoEnlaceFacturas(true);
        }
      });
  };

  if (tabIndex !== 1) return null;

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.max(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            data.length
        )
      : 0;

  const seEncontroFactura = facturasFiltradas.length === 0;

  const handleChangePage = (event, newPage) => {
    setStateTable({ ...statesTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const handleFilterByName = (event) => {
    setStateTable({ ...statesTable, filterName: event.target.value });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  // const manejarDescargaDeFactura = (
  //   token,
  //   folio,
  //   folioRef,
  //   estadoFactura,
  //   index
  // ) => {
  //   setIndiceCargandoVisualizar(index);
  //   // Si la factura fue anulada y no posee folioRef, entonces es la factura original. Se debe buscar la referencia.
  //   if (estadoFactura === "ANULADO" && !folioRef) {
  //     obtenerDocumentoReferencia(folio)
  //       .then((response) => {
  //         console.log(response.data);
  //         const { data } = response;
  //         if (data) {
  //           saveAs(
  //             "data:application/pdf;base64," + data.pdf,
  //             data.folio + ".pdf"
  //           );
  //         } else {
  //           console.log("Error");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "Error en handleClickDownloadReceipt (obtenerDocumentoReferencia)",
  //           error
  //         );
  //       })
  //       .finally(() => {
  //         setIndiceCargandoVisualizar(null);
  //       });
  //   } else {
  //     obtenerFacturaPorToken(token)
  //       .then((response) => {
  //         if (response.data?.pdf) {
  //           saveAs(
  //             "data:application/pdf;base64," + response.data.pdf,
  //             folio + ".pdf"
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "Error en handleClickDownloadReceipt (obtenerPorToken)",
  //           error
  //         );
  //       })
  //       .finally(() => {
  //         setIndiceCargandoVisualizar(null);
  //       });
  //   }
  // };

  return (
    <>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={3}>
            <TablaToolbar
              filterName={statesTable.filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" align="center">
              Facturas Sin Sucursal
            </Typography>
          </Grid>
          {selectedRows.length > 0 ? (
            <Grid item sx={{ display: "grid" }} xs={3}>
              <Button
                sx={{ marginRight: "1rem", maxWidth: "150px" }}
                variant="outlined"
                size="small"
                disabled={cargandoEnlaceFacturas}
                onClick={() => enlazarFacturasMasivas()}
                startIcon={
                  !cargandoEnlaceFacturas ? (
                    <AddLink />
                  ) : (
                    <CircularProgress size={15} />
                  )
                }
              >
                Enlazar facturas
              </Button>
            </Grid>
          ) : (
            <Grid item xs={3}></Grid>
          )}
        </Grid>
        <Scrollbar>
          <TableContainer>
            {!isLoadingData ? (
              <Table>
                <TablaHead
                  order={statesTable.order}
                  orderBy={statesTable.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  onRequestSort={handleRequestSort}
                  select={true}
                  selectedRows={selectedRows}
                  handleSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {facturasFiltradas
                    .slice(
                      statesTable.currentPage * statesTable.rowsPerPageOptions,
                      statesTable.currentPage * statesTable.rowsPerPageOptions +
                        statesTable.rowsPerPageOptions
                    )
                    .map((factura, index) => (
                      <TableRow
                        hover
                        key={factura.id}
                        selected={selectedRows.includes(factura)}
                      >
                        <TableCell padding="checkbox">
                          <Tooltip title="Seleccionar factura">
                            <Checkbox
                              checked={selectedRows.includes(factura)}
                              onChange={() => handleRowSelection(factura)}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {statesTable.currentPage *
                            statesTable.rowsPerPageOptions +
                            index +
                            1}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {factura.folio}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {formatRut(factura.rutCliente, RutFormat.DOTS_DASH)}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {capitalizarPrimeraLetra(factura.nombreCliente)}
                          </Typography>
                        </TableCell>
                        <TableCell>{factura.detalleFactura}</TableCell>
                        <TableCell>
                          {capitalizarPrimeraLetra(factura.estadoFactura)}
                        </TableCell>
                        <TableCell>
                          {dayjs(factura.fechaFacturacion).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{formatAsCLP(factura.valorPesos)}</TableCell>
                        <TableCell>
                          <Grid
                            item
                            xs={12}
                            md
                            container
                            justifyContent="flex-end"
                            sx={{ flexWrap: "nowrap" }}
                          >
                            {selectedRows.length === 0 && (
                              <Tooltip title="Enlazar factura sucursal">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    manejarAbrirModalEnlaceSucursal(
                                      factura,
                                      index
                                    )
                                  }
                                >
                                  {indiceCargandoEnlaceSucursal === index ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <AddLink />
                                  )}
                                </Button>
                              </Tooltip>
                            )}

                            {/* <Tooltip title="Visualizar factura">
                              <Button
                                color="secondary"
                                onClick={() =>
                                  manejarDescargaDeFactura(
                                    factura.token,
                                    factura.folio,
                                    factura.folioRef,
                                    factura.estadoFactura,
                                    index
                                  )
                                }
                              >
                                {indiceCargandoVisualizar === index ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <Visibility />
                                )}
                              </Button>
                            </Tooltip> */}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}

                  {filasVacias > 0 && (
                    <TableRow style={{ height: 53 * filasVacias }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
                {seEncontroFactura && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={statesTable.filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <ThemeProvider
                      theme={(outerTheme) =>
                        createTheme(outerTheme, locales[statesTable.locale])
                      }
                    >
                      <CustomTablePagination
                        count={facturasFiltradas.length}
                        rowsPerPage={statesTable.rowsPerPageOptions}
                        page={statesTable.currentPage}
                        colSpan={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </ThemeProvider>
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
      </Card>
      {statesModal.abrirModal && (
        <ModalEnlaceSucursal
          isOpen={statesModal.abrirModal}
          setOpen={setStateModal}
          data={selectedRows.length > 0 ? selectedRows : rowSelected}
          statesModal={statesModal}
          setSelectedRows={setSelectedRows}
          setIndiceCargandoEnlaceSucursal={
            selectedRows.length > 0
              ? setCargandoEnlaceFacturas
              : setIndiceCargandoEnlaceSucursal
          }
          enlaceMasivo={selectedRows.length > 0 ? true : false}
        />
      )}
    </>
  );
});

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "fechaFacturacion",
  locale: "esES",
  filterName: "",
};

const INITIAL_STATE_MODAL = {
  abrirModal: false,
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "folio", label: "Folio", alignRight: false },
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombreCliente", label: "Cliente", alignRight: false },
  { id: "detalleFactura", label: "Detalle factura", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha facturación", alignRight: false },
  { id: "montoTotal", label: "Monto total", alignRight: false },
  { id: "" },
];

export default FacturasSinSucursal;
