import React, { useEffect, useState, useRef } from "react";
import {
    Paper,
    Typography,
    Stack,
    FormControl,
    TextField,
    Autocomplete,
} from "@material-ui/core";
import axios from "axios";
import { lowerCase, startCase } from "lodash";
import { API_SERVER } from "src/utils/urlBack_End";
import { useSelector } from "react-redux";


export default function DatosIniciales(props) {
    const {
        valor,
        vendedor,
        empleado,
        supervisor,
        setVendedor,
        setEmpleado,
        setSupervisor,
    } = props;

    const {
        sucursal: sucursalObtenida,
    } = useSelector((state) => state.auth.user);

    const sucursal = sucursalObtenida.refSucursal;

    const [empleados, setEmpleados] = useState([]);
    const [empleadoOptions, setEmpleadoOptions] = useState([]);

    const [vendedorSeleccionado, setVendedorSeleccionado] = useState("");
    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState("");
    const [supervisorSeleccionado, setSupervisorSeleccionado] = useState("");

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    useEffect(() => {
        axios.get(
            `${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token")
                }
            }
        )
            .then(function (response) {
                if (response.data === -2) window.location.reload();
                if (isMounted.current) {
                    setEmpleados(response.data);
                }

            });

    }, [sucursal])

    useEffect(() => {
        if (isMounted.current) {
            setEmpleadoOptions(
                empleados.map((item) => {
                    return {
                        label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
                        value: item.rut,
                    };
                })
            );

        }

    }, [empleados]);
    return (
        <>
            {valor === 0 && (
                <Paper style={{ height: (window.screen.height - 350), margin: "10px", overflow: "auto" }} >
                    <Typography align="center" variant="h3">Encargados</Typography>
                    <Stack spacing={2}>
                        <FormControl fullWidth style={{ marginTop: "30px" }}>
                            <Autocomplete
                                value={vendedor}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        setVendedorSeleccionado(
                                            newValue.label.toUpperCase()
                                        );
                                        setVendedor(newValue);
                                    } else {
                                        setVendedorSeleccionado("");
                                        setVendedor(null);
                                    }
                                }}
                                fullWidth
                                id="combo-box-vendedor"
                                options={empleadoOptions}
                                renderInput={(params) => (
                                    <TextField
                                        error={vendedor === null}
                                        fullWidth
                                        {...params}
                                        label="Vendedor"
                                        required
                                        value={vendedorSeleccionado}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: "30px" }}>
                            <Autocomplete
                                value={empleado}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        setEmpleadoSeleccionado(
                                            newValue.label.toUpperCase()
                                        );
                                        setEmpleado(newValue);
                                    } else {
                                        setEmpleadoSeleccionado("");
                                        setEmpleado(null);
                                    }
                                }}
                                fullWidth
                                id="combo-box-empleados"
                                options={empleadoOptions}
                                renderInput={(params) => (
                                    <TextField
                                        error={empleado === null}
                                        fullWidth
                                        {...params}
                                        label="Empleado"
                                        required
                                        value={empleadoSeleccionado}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl fullWidth style={{ marginTop: "30px" }}>
                            <Autocomplete
                                value={supervisor}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        setSupervisorSeleccionado(
                                            newValue.label.toUpperCase()
                                        );
                                        setSupervisor(newValue);
                                    } else {
                                        setSupervisorSeleccionado("");
                                        setSupervisor(null);
                                    }
                                }}
                                fullWidth
                                id="combo-box-supervisor"
                                options={empleadoOptions}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        label="Supervisor"
                                        value={supervisorSeleccionado}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>

                </Paper>

            )}
        </>

    );
}