import React, { useState } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER, dbx } from "../../utils/urlBack_End";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Cancel";

export default function ArchivoSubtareaDialog(props) {
  const { open, setOpen, subTarea, setSubTareas } = props;
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [file, setFile] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const agregar = async () => {
    setLoadingUpload(true);
    if (!file) return setLoadingUpload(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      return;
    }

    try {
      await dbx.filesUpload({
        path: "/Tareas/" + subTarea.refTarea + "/SubTareas/" + file.name,
        contents: file,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: "/Tareas/" + subTarea.refTarea + "/SubTareas/" + file.name,
        });
        let url = shareResponse.result.url;

        axios
          .put(
            `${API_SERVER}/subtareas/archivo`,
            {
              id: subTarea.id,
              nombreArchivo: file.name,
              link: url,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            if (data.data === -2) window.location.reload();
            axios
              .get(`${API_SERVER}/subtareas/tarea/${subTarea.refTarea}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((data2) => {
                if (data2.data === -2) window.location.reload();
                setSubTareas(data2.data);
              })
              .catch(() => {});
          })
          .catch(() => {});
      } catch (ex) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;

        axios
          .put(
            `${API_SERVER}/subtareas/archivo`,
            {
              id: subTarea.id,
              nombreArchivo: file.name,
              link: url,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            if (data.data === -2) window.location.reload();
            axios
              .get(`${API_SERVER}/subtareas/tarea/${subTarea.refTarea}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((data2) => {
                if (data2.data === -2) window.location.reload();
                setSubTareas(data2.data);
              })
              .catch(() => {});
          })
          .catch(() => {});
      } finally {
        setLoadingUpload(false);
      }
    } catch (ex) {
    } finally {
      setFile(null);
      setLoadingUpload(false);
      handleClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Archivo Subtarea {subTarea.nombre}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={file ? 4 : 12} md={file ? 4 : 12}>
            <input
              style={{
                display: "none",
              }}
              id="contained-button-file"
              onChange={(e) => {
                const fileAux = e.target.files[0];
                setFile(fileAux);
              }}
              type="file"
            />

            <label htmlFor={"contained-button-file"}>
              <Button component="span" variant="outlined">
                Seleccionar Archivo
              </Button>
            </label>
          </Grid>
          {file && (
            <>
              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2" noWrap>
                  {file.name}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <IconButton color="error" onClick={handleDeleteFile}>
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} loading={loadingUpload}>
          Cerrar
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={agregar}
          autoFocus
          loading={loadingUpload}>
          Subir
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
