import React, { useEffect, useState } from "react";
import { Typography, AppBar, Tabs, Tab, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { Alerta } from "../Alerta";
import { Titulo } from "../Titulo";
import { API_SERVER, dbx } from "../../utils/urlBack_End";
import EditarClavesClienteSeleccionado from "./clientes/EditarClavesClienteSeleccionado";
import SociosClienteSeleccionado from "./clientes/SociosClienteSeleccionado";
import PlanesClienteSeleccionado from "./clientes/PlanesClienteSeleccionado";
import ContratosClienteSeleccionado from "./clientes/ContratosClienteSeleccionado";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
  },
  margen: {
    marginBottom: 12,
  },
}));

export default function DatosClientes() {
  const {
    cliente: user,
  } = useSelector((state) => state.auth.user);
  const [cliente, setCliente] = useState({});
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/${user.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setCliente(response.data);
      });
  }, [user.rut]);

  useEffect(() => {
    dbx
      .filesListFolder({ path: `/Clientes/${user.rut}` })
      .then(() => { })
      .catch(() => {
        dbx
          .filesCreateFolderV2({ path: `/Clientes/${user.rut}` })
          .then((response) => { })
          .catch((error) => { });
      })
      .finally(() => { });
  }, [user.rut]);

  return (
    <>
      {cliente.estado && (
        <>
          <Container>
            <Titulo>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingRight: "1rem",
                  paddingLeft: "1rem",
                }}
                variant="h5"
              >
                Mis Datos
              </Typography>
            </Titulo>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Claves Portales" />
                  <Tab label="Socios" />
                  <Tab label="Plan" />
                  <Tab label="Contratos" />
                </Tabs>
              </AppBar>
            </div>

            <EditarClavesClienteSeleccionado
              value={value}
              cliente={cliente}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
            <SociosClienteSeleccionado
              value={value}
              cliente={cliente}
            />

            <PlanesClienteSeleccionado
              value={value}
              cliente={cliente}
            />
            <ContratosClienteSeleccionado
              value={value}
              cliente={cliente}
            />

            {/*<Plan value={value} cliente={cliente} setCliente={setCliente} />
            <TareasAsociadasCliente
              value={value}
              cliente={cliente}
              setCliente={setCliente}
            />
            <FacturasAsociadasCliente value={value} cliente={cliente} />

            <ContratosCliente
              value={value}
              cliente={cliente}
              setShowMessage={setShowMessage}
              setColor={setColor}
              setMessage={setMessage}
            />*/}

            <Alerta
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              color="success"
              message="Datos del cliente guardados"
            />
            <Alerta
              showAlert={showErrorMessage}
              setShowAlert={setShowErrorMessage}
              color="error"
              message={message}
            />

            {showMessage && (
              <Alerta
                showAlert={showMessage}
                setShowAlert={setShowMessage}
                color={color}
                message={message}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
}
