import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CalendarField from "../modalGav/Fields/CalendarField";
import { memo, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import * as locales from "@material-ui/core/locale";
import { LoadingButton } from "@material-ui/lab";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import Scrollbar from "src/components/Scrollbar";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import SearchNotFound from "src/components/SearchNotFound";
import { ThemeProvider } from "@material-ui/styles";
import CustomTablePagination from "src/components/CustomTablePagination";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { formatearFecha } from "src/utils/formatTime";
import { Visibility } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

ModalInformacionNuevosClientes.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  nuevosClientes: PropTypes.array,
  cargandoNuevosClientes: PropTypes.bool,
};

export default function ModalInformacionNuevosClientes({
  isOpen,
  setOpen,
  title,
  nuevosClientes,
  cargandoNuevosClientes,
  filtroFecha,
  setFiltroFecha,
}) {
  const handleClose = () => {
    setOpen(false);
  };

//   const handleChangeFechaFacturacion = useCallback(
//     (newValue) => setFiltroFecha(dayjs(newValue).format("YYYY/MM")),
//     [setFiltroFecha]
//   );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-modal-informacion-nuevos-clientes"
      id="dialog-modal-informacion-nuevos-clientes"
      fullWidth
      maxWidth="xl"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            display: "grid",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <DialogTitle htmlFor="dialog-modal-informacion-nuevos-clientes">
            {title}
          </DialogTitle>
        </Grid>
        <Grid item xs={12} md={4} lg={4} marginTop={2}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item marginRight={3}>
              {/* <CalendarField
                id="fecha-filtro"
                label="Filtrar por fecha"
                value={filtroFecha}
                onChange={handleChangeFechaFacturacion}
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                tamanio={12}
                maxDate={dayjs().format("YYYY/MM/DD")}
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogContent>
        <TablaNuevosClientes
          clientes={nuevosClientes}
          cargandoClientes={cargandoNuevosClientes}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "desc",
  orderBy: "fechaIngreso",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombre", label: "Nombre", alignRight: false },
  {
    id: "fechaIngreso",
    label: "Fecha ingreso",
    alignRight: false,
  },
  { id: "direccion", label: "Dirección", alignRight: false },
  { id: "comuna", label: "Comuna", alignRight: false },
  { id: "correo", label: "Correo", alignRight: false },
  { id: "correoFacturacion", label: "Correo facturación", alignRight: false },
  { id: "telefono", label: "Teléfono", alignRight: false },
  { id: "" },
];

const TablaNuevosClientes = memo(function TablaNuevosClientes({
  clientes,
  cargandoClientes,
}) {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const navigate = useNavigate();

  const clientesFiltrados = useMemo(() => {
    const propiedadesParaFiltrar = ["rut", "nombre", "comuna"];
    return applySortFilter(
      clientes,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [
    clientes,
    statesTable.filterName,
    statesTable.order,
    statesTable.orderBy,
  ]);

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            clientes.length
        )
      : 0;

  const seEncontroElemento = clientesFiltrados.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      clientesFiltrados.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(clientesFiltrados.length / newRowsPerPage);
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      clientesFiltrados.length / statesTable.rowsPerPageOptions
    );
    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  const verCliente = (id) => {
    navigate("/dashboard/clientes/" + id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <TablaToolbar
            filterName={statesTable.filterName}
            onFilterName={handleFilterByName}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Scrollbar>
          <TableContainer component={Paper}>
            {!cargandoClientes ? (
              <Table>
                <TablaHead
                  order={statesTable.order}
                  orderBy={statesTable.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={clientes.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {clientesFiltrados
                    .slice(
                      statesTable.currentPage * statesTable.rowsPerPageOptions,
                      statesTable.currentPage * statesTable.rowsPerPageOptions +
                        statesTable.rowsPerPageOptions
                    )
                    .map((row, index) => {
                      const {
                        rut,
                        nombre,
                        fechaIngreso,
                        direccion,
                        comuna,
                        correo,
                        correoFacturacion,
                        telefono,
                      } = row;

                      return (
                        <TableRow hover key={rut}>
                          <TableCell>
                            {statesTable.currentPage *
                              statesTable.rowsPerPageOptions +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {formatRut(rut, RutFormat.DOTS_DASH)}
                            </Typography>
                          </TableCell>
                          <TableCell>{capitalizarPalabras(nombre)}</TableCell>
                          <TableCell>
                            {formatearFecha(fechaIngreso, "DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            {capitalizarPrimeraLetra(
                              direccion ? direccion : ""
                            )}
                          </TableCell>
                          <TableCell>
                            {capitalizarPalabras(comuna ? comuna : "")}
                          </TableCell>
                          <TableCell>
                            {correo ? correo.toLowerCase() : ""}
                          </TableCell>
                          <TableCell>
                            {correoFacturacion
                              ? correoFacturacion.toLowerCase()
                              : ""}
                          </TableCell>
                          <TableCell>{telefono}</TableCell>
                          <TableCell>
                            <Tooltip title="Ver cliente">
                              <IconButton
                                color="primary"
                                onClick={() => verCliente(row.rut)}
                                aria-label="watch-task"
                                size="small"
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {filasVacias > 0 && (
                    <TableRow style={{ height: 53 * filasVacias }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
                {seEncontroElemento && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={statesTable.filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <ThemeProvider
                      theme={(outerTheme) =>
                        createTheme(outerTheme, locales[statesTable.locale])
                      }
                    >
                      <CustomTablePagination
                        count={clientesFiltrados.length}
                        rowsPerPage={statesTable.rowsPerPageOptions}
                        page={statesTable.currentPage}
                        colSpan={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </ThemeProvider>
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
      </Grid>
    </Grid>
  );
});

TablaNuevosClientes.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  clientes: PropTypes.array,
  cargandoClientes: PropTypes.bool,
};
