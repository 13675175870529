import { experimentalStyled as styled } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

export const Titulo = styled(Card)(({ theme }) => ({
  boxShadow: "initial",
  marginBottom: "-1rem",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[100],
}));
