import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormProvider from "src/components/hook-form/FormProvider";
import { ajustesApi } from "src/services/ajustesApi";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import * as Yup from "yup";
import { clientesApi } from "src/services/clientesApi";
import { AlertaToast } from "src/components/AlertaSwall";
import AutoCompleteField from "../sharedFields/AutocompleteField";
import ModalGrupoCliente from "../modalGrupoCliente";

const mostrarAlertaError = (mensaje) => {
  AlertaToast({
    action: mensaje,
    fullText: true,
    type: "error",
    target: document.getElementById("dialog-enlace-grupo-cliente"),
  }).fire();
};

const mostrarAlertaExito = (accion) => {
  AlertaToast({
    element: "Enlace clientes",
    action: accion,
  }).fire();
};

const ModalEnlaceGrupoCliente = ({
  isOpen,
  setOpen,
  title,
  clientes,
  setSelectedRows,
}) => {
  const { data: gruposApi = [], isLoading: cargandoGrupos } =
    ajustesApi.useGetSelectorElementoQuery("1297451858");
  const [asociarClientes] = clientesApi.useAddAsociarGruposMutation();

  const [abirModalCreacionGrupo, setAbrirModalCreacionGrupo] = useState(false);

  const grupos = useMemo(() => {
    if (!cargandoGrupos && gruposApi) {
      return gruposApi
        .map((grupo) => ({
          value: grupo.nombreElemento,
          label: capitalizarPalabras(grupo.nombreElemento),
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
    }
    return [];
  }, [cargandoGrupos, gruposApi]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const facturasSchema = Yup.object().shape({
    grupo: Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required("El grupo es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      grupo: null,
    },
    validationSchema: facturasSchema,
    onSubmit: () => {
      manejarGuardarEnlaceGrupo();
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    handleSubmit,
    setSubmitting,
  } = formik;

  useEffect(() => {
    if (grupos.length > 0 && !formik.values.grupo) {
      setFieldValue("grupo", grupos[0]);
    }
  }, [formik.values.grupo, grupos, setFieldValue]);

  const handleSucursalSelection = useCallback(
    (event, newValue) => {
      setFieldValue("grupo", newValue);
    },
    [setFieldValue]
  );

  const handleOptionLabelDivision = useCallback(
    (option) => capitalizarPalabras(option.label || ""),
    []
  );

  const handleIsOptionEqualToValueDivision = useCallback(
    (option, value) => option.value === value.value,
    []
  );

  const manejarModalCreacionGrupo = () => {
    setAbrirModalCreacionGrupo(true);
  };

  const manejarGuardarEnlaceGrupo = useCallback(async () => {
    try {
      const {
        grupo: { value },
      } = values;

      const asociaciones = clientes.map((cliente) => ({
        rutEmpresa: cliente.rut,
        grupoEmpresa: value,
      }));

      const body = {
        asociaciones,
      };

      const { error } = await asociarClientes(body);

      if (error) {
        console.error(error);
        mostrarAlertaError(`Error al enlazar clientes al grupo ${value}`);
        setSubmitting(false);
        return;
      }

      mostrarAlertaExito("agregados");
      setSubmitting(false);
      setSelectedRows([]);
      handleClose();
    } catch (error) {
      console.error(error);
      mostrarAlertaError("Ocurrió un error desconocido");
    }
  }, [
    asociarClientes,
    clientes,
    handleClose,
    setSelectedRows,
    setSubmitting,
    values,
  ]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-enlace-grupo-cliente"
        id="dialog-enlace-grupo-cliente"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={styles.title} htmlFor="dialog-enlace-grupo-cliente">
          {title}
        </DialogTitle>
        <FormProvider
          onSubmit={handleSubmit}
          formik={formik}
          disableEnterSubmit={true}
        >
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogContentText marginBottom={1}>
                Elija el grupo al que desea vincular los clientes.
              </DialogContentText>
              <Button
                onClick={manejarModalCreacionGrupo}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                sx={{ marginBottom: 1 }}
              >
                Agregar grupo
              </Button>
            </Grid>

            <Divider />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginTop={2}
            >
              <AutoCompleteField
                disableClearable={true}
                id="autocomplete-grupo"
                label="Seleccione grupo"
                value={values.grupo}
                handleSucursalSelection={handleSucursalSelection}
                options={grupos}
                touched={touched.grupo}
                error={errors.grupo}
                getOptionLabel={handleOptionLabelDivision}
                isOptionEqualToValue={handleIsOptionEqualToValueDivision}
                typeRenderInput="loading"
                isLoading={cargandoGrupos}
                disabled={cargandoGrupos}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton onClick={handleClose} color="error">
              Cerrar
            </LoadingButton>
            <LoadingButton
              type="submit"
              disabled={isSubmitting || !isValid}
              startIcon={<Icon icon={saveFill} />}
              color="primary"
              loading={isSubmitting}
            >
              Enlazar
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
      {abirModalCreacionGrupo && (
        <ModalGrupoCliente
          isOpen={abirModalCreacionGrupo}
          setOpen={setAbrirModalCreacionGrupo}
          title="Agregar nuevo grupo"
        />
      )}
    </>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalEnlaceGrupoCliente.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  clientes: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
};

export default React.memo(ModalEnlaceGrupoCliente);
