import {
  Card,
  CardHeader,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { fNumber, fPercent } from "src/utils/formatNumber";
import CustomSmallSelect from "../custom-input/CustomSmallSelect";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

const PROPUESTA_ESTADOS = {
  ENVIADA: "enviada",
  RECHAZADA: "rechazada",
  ACEPTADA: "aceptada",
  CREADA: "creada",
};

AppDistribucionPropuestas.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  sucursalEmpleado: PropTypes.string,
  data: PropTypes.object,
  cargandoData: PropTypes.bool,
  sucursales: PropTypes.array,
  cargandoSucursales: PropTypes.bool,
  loadingComponent: PropTypes.bool,
  onClick: PropTypes.func,
};

export default function AppDistribucionPropuestas({
  title,
  subheader,
  sucursalEmpleado,
  data,
  cargandoData,
  sucursales = [],
  cargandoSucursales,
  loadingComponent,
  onClick,
  ...other
}) {
  const [seriesData, setSeriesData] = useState("");

  useEffect(() => {
    if (!cargandoSucursales && sucursales && sucursales.length > 0) {
      setSeriesData(
        sucursales.find(
          (sucursal) =>
            sucursal.refSucursal.toLowerCase() ===
            sucursalEmpleado.toLowerCase()
        ).refSucursal
      );
    }
  }, [cargandoSucursales, sucursalEmpleado, sucursales]);

  const dataGraph = useMemo(() => {
    if (!cargandoData && seriesData && data[seriesData]) {
      return data[seriesData].items || [];
    }
    return [];
  }, [cargandoData, data, seriesData]);

  const handleSeriesChange = useCallback((event) => {
    setSeriesData(event.target.value);
  }, []);

  if (loadingComponent) {
    return (
      <Card {...other}>
        <CardHeader
          title={<Skeleton width="60%" />}
          subheader={<Skeleton width="40%" />}
          action={<Skeleton width={100} height={40} />}
        />
        <Stack spacing={4} sx={{ p: 3 }}>
          {[1, 2, 3, 4].map((item) => (
            <Stack key={item} spacing={1}>
              <Stack direction="row" alignItems="center">
                <Skeleton width="60%" height={24} />
                <Skeleton width="20%" height={24} />
                <Skeleton width="20%" height={24} />
              </Stack>
              <Skeleton variant="rectangular" height={10} />
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <CustomSmallSelect
            value={seriesData}
            onChange={(event) => handleSeriesChange(event)}
          >
            {sucursales.map((sucursal) => (
              <option key={sucursal.refSucursal} value={sucursal.refSucursal}>
                {capitalizarPrimeraLetra(sucursal.refSucursal)}
              </option>
            ))}
          </CustomSmallSelect>
        }
      />

      <Stack spacing={4} sx={{ p: 3 }}>
        {dataGraph.map(({ estado, cantidad, porcentaje }) => (
          <ProgressItem
            seriesData={seriesData}
            onClick={onClick}
            key={estado}
            estado={estado}
            cantidad={cantidad}
            porcentaje={porcentaje}
          />
        ))}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

const ProgressItem = memo(function ProgressItem({
  seriesData,
  onClick,
  estado,
  cantidad,
  porcentaje,
}) {
  return (
    <Stack
      spacing={1}
      sx={{ cursor: onClick ? "pointer" : "default" }}
      onClick={() => onClick(estado, seriesData)}
    >
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {capitalizarPrimeraLetra(estado)}
        </Typography>
        <Typography variant="subtitle2">{fNumber(cantidad)}</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          &nbsp;({fPercent(porcentaje)})
        </Typography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={porcentaje}
        color={
          (estado.toLowerCase() === PROPUESTA_ESTADOS.ENVIADA && "warning") ||
          (estado.toLowerCase() === PROPUESTA_ESTADOS.RECHAZADA && "error") ||
          (estado.toLowerCase() === PROPUESTA_ESTADOS.ACEPTADA && "success") ||
          "primary"
        }
      />
    </Stack>
  );
});

ProgressItem.propTypes = {
  seriesData: PropTypes.string,
  onClick: PropTypes.func,
  estado: PropTypes.string,
  cantidad: PropTypes.number,
  porcentaje: PropTypes.number,
};
