import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { memo } from "react";
import FormEnlaceSucursal from "./FormEnlaceSucursal";
import FormEnlaceMasivoSucursal from "./FormEnlaceMasivoSucursal";

const ModalEnlaceSucursal = memo(
  ({
    isOpen,
    setOpen,
    data,
    statesModal,
    setIndiceCargandoEnlaceSucursal,
    setSelectedRows,
    enlaceMasivo = false,
  }) => {
    return (
      <Dialog
        open={isOpen}
        aria-labelledby="dialog-enlace-sucursal"
        id="dialog-enlace-sucursal"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={styles.title} htmlFor="dialog-gav">
          {enlaceMasivo ? "Enlace masivo de facturas a sucursal" : "Enlazar factura a sucursal"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={1}>
            {enlaceMasivo ? "Complete los datos para enlazar las facturas a una sucursal" : "Complete los datos para enlazar la factura a una sucursal"}
          </DialogContentText>
          <Divider />
          {enlaceMasivo ? (
            <FormEnlaceMasivoSucursal
              data={data}
              setOpen={setOpen}
              statesModal={statesModal}
              setIndiceCargandoEnlaceSucursal={setIndiceCargandoEnlaceSucursal}
              setSelectedRows={setSelectedRows}
            />
          ) : (
            <FormEnlaceSucursal
              data={data}
              setOpen={setOpen}
              statesModal={statesModal}
              setIndiceCargandoEnlaceSucursal={setIndiceCargandoEnlaceSucursal}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
);

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default ModalEnlaceSucursal;
