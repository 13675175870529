import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const baseQuery = (baseUrl = '/') => (fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');

        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return headers;
    },
}));

export default baseQuery;