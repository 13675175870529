import React, { useState } from "react";
import * as XLSX from "xlsx";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";
export default function ExportToExcelTareas(props) {
  const {
    data,
    filename,
    sheetName,
    setShowAlert,
    setColor,
    setMessage,
  } = props;

  const [loadingExport, setLoadingExport] = useState(false);

  const exportar = () => {
    setLoadingExport(true);
    if (data?.length > 0) {
      let tareasCompletas = [];

      if (Array.isArray(data)) {
        let tareas = data;

        tareas.forEach((tarea) => {

          let tareaArray = [
            tarea.fechaLimite,
            tarea.descuento,
            tarea.valorTarea,
            tarea.tipoMonedaTarea,
            tarea.fecha,
            tarea.refCliente,
            tarea.nombre,
            tarea.tipoTarea,
            tarea.fechaInicio,
            tarea.etiqueta,
            tarea.fechaTermino,
            tarea.estado,
            tarea.nombreCliente,
            tarea.nombreEmpleado,
            tarea.apellidoEmpleado,
            tarea.diasTranscurridos,
            tarea.diasLimites
          ];

          tareasCompletas.push(tareaArray);

        });



        let worksheet = XLSX.utils.aoa_to_sheet([["Fecha Limite", "Descuento", "Valor Tarea", "Tipo Moneda Tarea", "Fecha", "Ref Cliente", "Nombre", "Tipo Tarea", "Fecha Inicio", "Etiqueta", "Fecha Termino", "Estado", "Nombre Cliente", "Nombre Empleado", "Apellido Empleado", "Dias Transcurridos", "Dias Limites"], ...tareasCompletas]);
        let new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName);
        XLSX.writeFile(new_workbook, filename + ".xlsx");
        setLoadingExport(false);
      }
      else {
        setLoadingExport(false);
        setColor("error");
        setMessage("Error al exportar");
        setShowAlert(true);

      }
    } else {
      setLoadingExport(false);
      if (setShowAlert !== undefined) {
        setColor("error");
        setMessage("No hay datos que exportar");
        setShowAlert(true);
      }
    }
  };

  return (
    <LoadingButton
      variant="contained"
      onClick={exportar}
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px" }}
      loading={loadingExport}
    >
      Exportar A Excel
    </LoadingButton>
  );
}
