import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import AppTablaTareasEmpleado from "src/components/estadisticas/app-tabla-tareas-empleado";

const ModalDetalleTareasEmpleado = ({
  isOpen,
  setOpen,
  titulo,
  data,
  loadingData,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-detalle-tareas-empleado"
      id="dialog-detalle-tareas-empleado"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle style={styles.title} htmlFor="dialog-detalle-tareas">
        {titulo ? titulo : "Título modal"}
      </DialogTitle>
      <DialogContent>
        <AppTablaTareasEmpleado
          title="Detalle tareas"
          data={!loadingData && data ? data : []}
          loadingData={loadingData}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalDetalleTareasEmpleado.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  titulo: PropTypes.string,
  data: PropTypes.array,
  loadingData: PropTypes.bool,
};

export default ModalDetalleTareasEmpleado;
