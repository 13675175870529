import { memo, useMemo } from "react";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";

const AutoCompleteField = memo(
  ({
    id,
    label,
    value,
    xs = 12,
    md = 12,
    lg = 12,
    handleSucursalSelection,
    options,
    touched,
    error,
    getOptionLabel,
    isOptionEqualToValue,
    disableClearable = false,
    multiple = false,
    typeRenderInput,
    isLoading = false,
    disabled = false,
    renderTags = () => {},
    ...sx
  }) => {
    const renderInput = useMemo(() => {
      if (typeRenderInput === "normal") {
        return (params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            error={touched && Boolean(error)}
            helperText={touched && error}
          />
        );
      } else if (typeRenderInput === "loading") {
        return (params) => (
          <TextField
            {...params}
            label={label}
            placeholder="Seleccione un ítem"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={touched && Boolean(error)}
            helperText={touched && error}
          />
        );
      }
    }, [error, isLoading, label, touched, typeRenderInput]);

    const autoComplete = useMemo(
      () => (
        <Grid item xs={xs} md={md} lg={lg} {...sx}>
          <Autocomplete
            multiple={multiple}
            disableClearable={disableClearable}
            value={value}
            onChange={handleSucursalSelection}
            id={id}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderTags={renderTags}
            renderInput={renderInput}
            disabled={disabled}
          />
        </Grid>
      ),
      [
        disableClearable,
        disabled,
        getOptionLabel,
        handleSucursalSelection,
        id,
        isOptionEqualToValue,
        lg,
        md,
        multiple,
        options,
        renderInput,
        renderTags,
        sx,
        value,
        xs,
      ]
    );
    return autoComplete;
  }
);

export default AutoCompleteField;
