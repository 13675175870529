import {
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import { memo } from "react";

const ShowFileComponent = memo(
  ({ value, label, tamanio, onDeleteFile, loading }) => {
    return (
      <Grid container item xs={tamanio} alignItems="center">
        <TextField
          fullWidth
          variant="outlined"
          label={label}
          disabled={loading}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Eliminar archivo" arrow>
                <IconButton onClick={onDeleteFile} edge="end">
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CancelOutlined />
                  )}
                </IconButton>
              </Tooltip>
            ),
            startAdornment: (
              <span
                onClick={() => window.open(value, "_blank")}
                style={{
                  width: "100%",
                  cursor: "pointer",
                  color: "#ff802e",
                  textDecoration: "underline",
                }}
              >
                {label}
              </span>
            ),
          }}
        />
      </Grid>
    );
  }
);

export default ShowFileComponent;
