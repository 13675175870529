// material
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Tab,
  Tabs,
} from "@material-ui/core";
import { Titulo } from "src/components/Titulo";
// components
import Page from "../components/Page";
import { API_SERVER } from "../utils/urlBack_End";
import axios from "axios";
import TablaInicioCliente from "src/components/pagesCliente/TablaInicioCliente";
import TareasTerminadas from "src/components/pagesCliente/TareasTerminadas";
import FacturasPendientesPagoDialog from "src/components/pagesCliente/FacturasPendientesPagoDialog";
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function DashboardCliente() {
  const {
    cliente: usuario,
  } = useSelector((state) => state.auth.user);

  const [value, setValue] = useState(0);

  const [facturasPendientes, setFacturasPendientes] = useState([]);
  const [mostrarFacturas, setMostrarFacturas] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const isMounted = useRef(true);
  const [tareasCliente, setTareasCliente] = useState([]);


  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/tareas/${usuario.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setTareasCliente(response.data);
      });
  }, [usuario.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/facturas/pendientePago/${usuario.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setFacturasPendientes(response.data)

        if (localStorage.getItem("lookBills")) {
          if (localStorage.getItem("lookBills") === "true") {
            setMostrarFacturas(true)
          }
          else {
            setMostrarFacturas(false)
          }
        }
        else {
          localStorage.setItem("lookBills", JSON.stringify(true));
          setMostrarFacturas(true)

        }
      });
  }, [usuario.rut]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("lookBills") === "true") {
      setMostrarFacturas(true)

    }
    else {
      setMostrarFacturas(false)

    }

  }, [])
  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4">Bienvenido(a) {usuario?.nombre}</Typography>
        </Titulo>
        <Box sx={{ width: '100%', margin: "10px" }}>
          <Box sx={{ margin: "10px", borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Tareas" {...a11yProps(0)} />
              <Tab label="Tareas Terminadas" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TablaInicioCliente tareasCliente={tareasCliente && tareasCliente.filter(e => e.estado !== "TERMINADA")} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TareasTerminadas tareasCliente={tareasCliente && tareasCliente.filter(e => e.estado === "TERMINADA")} />
          </TabPanel>
        </Box>

      </Container>

      {
        mostrarFacturas && facturasPendientes.length >= 1 && (
          <FacturasPendientesPagoDialog
            open={mostrarFacturas}
            setOpen={setMostrarFacturas}
            facturasPendientes={facturasPendientes}
          />

        )
      }







    </Page>
  );
}
