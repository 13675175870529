import { Button, Divider, IconButton, Stack, Tooltip } from "@material-ui/core";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import FormikTextField from "src/components/hook-form/FormikTextField";
import FormikSelect from "src/components/hook-form/FormikSelect";
import { FieldArray } from "formik";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import plusFill from "@iconify/icons-eva/plus-fill";
import Iconify from "src/components/iconify";
import FormikDatePicker from "src/components/hook-form/FormikDatePicker";
import icDel from "@iconify/icons-carbon/edit";
import { fechaActual } from "src/utils/formatTime";

const TIPOS_REFERENCIAS = [
  {
    label: "801 - Orden de compra",
    value: "801",
  },
  {
    label: "802 - Nota de pedido",
    value: "802",
  },
  {
    label: "803 - Contrato",
    value: "803",
  },
  {
    label: "804 - Resolución",
    value: "804",
  },
  {
    label: "813 - Pasaporte",
    value: "813",
  },
];

const ReferenciaItem = React.memo(
  ({ item, index, handleRemove, showDescripcion, handleToggleDescripcion }) => {
    const caracteresMaximoFolioRef = 18;
    const caracteresMaximoRazonRef = 90;
    const onRemove = useCallback(() => {
      handleRemove(index);
    }, [handleRemove, index]);
    const onToggleObservation = useCallback(() => {
      handleToggleDescripcion(index);
    }, [handleToggleDescripcion, index]);

    return (
      <Stack key={index} alignItems="flex-end" spacing={1.5}>
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          spacing={2}
          sx={{ width: 1 }}
        >
          <FormikSelect
            name={`referencias.${index}.TpoDocRef`}
            label="Tipo documento*"
            shrink={true}
            options={TIPOS_REFERENCIAS}
            size="small"
          />
          <FormikTextField
            name={`referencias.${index}.FolioRef`}
            size="small"
            label="Folio*"
            InputLabelProps={{ shrink: true }}
            helperText={`${item.FolioRef.length}/${caracteresMaximoFolioRef} caracteres`}
            FormHelperTextProps={{
              style: { textAlign: "right" },
            }}
          />
          <FormikDatePicker
            name={`referencias.${index}.FchRef`}
            label="Fecha Emisión*"
            size="small"
            maxDate="2050/12/31"
            minDate="2002/08/01"
          />
          {showDescripcion[index] && (
            <FormikTextField
              name={`referencias.${index}.RazonRef`}
              size="small"
              label="Observaciones"
              InputLabelProps={{ shrink: true }}
              helperText={`${item.RazonRef.length}/${caracteresMaximoRazonRef} caracteres`}
              FormHelperTextProps={{
                style: { textAlign: "right" },
              }}
              maxLength={90}
            />
          )}
          <div style={{ marginBottom: "20px" }}>
            <Tooltip title="Agregar observación">
              <IconButton onClick={onToggleObservation} color="primary">
                <Iconify icon={icDel} />
              </IconButton>
            </Tooltip>
          </div>
        </Stack>
        <Button
          size="small"
          color="error"
          startIcon={<Iconify icon={trash2Outline} />}
          onClick={onRemove}
        >
          Eliminar
        </Button>
      </Stack>
    );
  }
);

ReferenciaItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    TpoDocRef: PropTypes.string,
    FolioRef: PropTypes.string,
    FchRef: PropTypes.object,
    RazonRef: PropTypes.string,
  }).isRequired,
  handleRemove: PropTypes.func.isRequired,
  showDescripcion: PropTypes.array,
  handleToggleDescripcion: PropTypes.func,
};

const ReferenciaFactura = ({
  referencias,
  setFieldValue,
  setShowObservation,
  showObservation,
}) => {
  const handleAddItem = useCallback(() => {
    setFieldValue("referencias", [
      ...referencias,
      {
        TpoDocRef: "801",
        FolioRef: "",
        FchRef: fechaActual(),
        RazonRef: "",
      },
    ]);
  }, [referencias, setFieldValue]);

  const handleRemove = useCallback(
    (index) => {
      setFieldValue(
        "referencias",
        referencias.filter((_, i) => i !== index)
      );
    },
    [referencias, setFieldValue]
  );

  const handleToggleDescripcion = useCallback(
    (index) => {
      setShowObservation((prevState) => {
        const newState = [...prevState];
        newState[index] = !newState[index];
        return newState;
      });
      setFieldValue(`referencias[${index}].descripcion`, "");
    },
    [setFieldValue, setShowObservation]
  );

  return (
    <FieldArray name="referencias">
      {() => (
        <>
          <Stack
            divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
            spacing={3}
          >
            {referencias.map((item, index) => (
              <ReferenciaItem
                key={index}
                index={index}
                item={item}
                handleRemove={handleRemove}
                showDescripcion={showObservation}
                handleToggleDescripcion={handleToggleDescripcion}
              />
            ))}
            <Stack
              spacing={2}
              direction={{ xs: "column-reverse", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              <Button
                size="small"
                startIcon={<Iconify icon={plusFill} />}
                onClick={handleAddItem}
                sx={{ flexShrink: 0 }}
              >
                Agregar Referencia
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </FieldArray>
  );
};

ReferenciaFactura.propTypes = {
  referencias: PropTypes.arrayOf(
    PropTypes.shape({
      TpoDocRef: PropTypes.string,
      FolioRef: PropTypes.string,
      FchRef: PropTypes.object,
      RazonRef: PropTypes.string,
    }).isRequired
  ),
  setFieldValue: PropTypes.func.isRequired,
};

export default React.memo(ReferenciaFactura);
