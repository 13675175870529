import { Box, Card, Skeleton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { fNumber } from "src/utils/formatNumber";

AppWidgetTareas.propTypes = {
  icon: PropTypes.node,
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
  loading: PropTypes.bool,
  cursor: PropTypes.string,
  onClickCard: PropTypes.func,
};

export default function AppWidgetTareas({
  title,
  total,
  icon,
  sx,
  loading,
  cursor = "default",
  onClickCard = null,
  ...other
}) {
  if (loading) {
    return (
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          pl: 3,
          ...sx,
        }}
        {...other}
      >
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width={80} />
          <Skeleton variant="text" width={120} />
        </Box>
        <Box
          sx={{
            width: 120,
            height: 120,
            borderRadius: "50%",
            bgcolor: "background.neutral",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="circle"
            width={80}
            height={80}
            sx={{ borderRadius: "20px" }}
          />
        </Box>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        pl: 3,
        cursor: cursor,
        ...sx,
      }}
      onClick={onClickCard}
      {...other}
    >
      <div>
        <Typography variant="h3">{fNumber(total)}</Typography>
        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
          {title}
        </Typography>
      </div>

      <Box
        sx={{
          width: 120,
          height: 120,
          lineHeight: 0,
          borderRadius: "50%",
          bgcolor: "background.neutral",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
    </Card>
  );
}
