import { Card, Skeleton, Stack, Typography } from "@material-ui/core";
import { sumBy } from "lodash";
import PropTypes from "prop-types";
import { fCurrency, fNumber, formatAsCLP } from "src/utils/formatNumber";

AppDistribucionSaldos.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  errorDetalles: PropTypes.object,
};

export default function AppDistribucionSaldos({
  title,
  data,
  loading,
  errorDetalles,
  sx,
  ...other
}) {
  if (loading) {
    return (
      <Card sx={{ p: 4.6, ...sx }} {...other}>
        <Typography variant="subtitle2" gutterBottom>
          <Skeleton variant="text" width={100} />
        </Typography>

        <Stack spacing={1}>
          <Typography variant="h3">
            <Skeleton variant="text" width={150} />
          </Typography>

          {[1, 2, 3, 4, 5].map((index) => (
            <Stack direction="row" key={index} justifyContent="space-between">
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                <Skeleton variant="text" width={80} />
              </Typography>
              <Typography variant="caption">
                <Skeleton variant="text" width={50} />
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  if (errorDetalles && !loading) {
    return (
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          p: 4.6,
          ...sx,
          opacity: 0.5,
          pointerEvents: "none",
        }}
        {...other}
      >
        <Typography variant="subtitle2" gutterBottom>
          Se deben configurar las comisiones para ver el componente
        </Typography>
      </Card>
    );
  }

  const {
    divisionSaldos: divisiones,
    valorMonedaTarea,
    tipoMonedaTarea,
    precioTipoMoneda,
  } = data;

  const total = sumBy(divisiones, "quantity");

  return (
    <Card
      sx={{
        p: 4.6,
        height: "100%",
        ...sx,
      }}
      {...other}
    >
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>

      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3">{fCurrency(total)}</Typography>
          {tipoMonedaTarea !== "PESOS" && (
            <Typography variant="overline">{`${fNumber(
              valorMonedaTarea
            )}${tipoMonedaTarea} (${formatAsCLP(
              precioTipoMoneda
            )})`}</Typography>
          )}
        </Stack>

        {divisiones &&
          divisiones.map((division) => (
            <Stack
              direction="row"
              key={division.label}
              justifyContent="space-between"
            >
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {division.label}
              </Typography>
              <Typography variant="caption">
                + {fCurrency(division.quantity)}
              </Typography>
            </Stack>
          ))}
      </Stack>
    </Card>
  );
}
