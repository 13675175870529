import React, { useState } from "react";
import { AppBar, Tabs, Tab, Container, Typography } from "@material-ui/core";
import { Titulo } from "../Titulo";
import FacturaPlanesCliente from "./facturas/FacturaPlanesCliente";
import FacturaTareasCliente from "./facturas/FacturaTareasCliente";

export default function FacturasCliente() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Titulo style={{ marginBottom: "-3rem" }}>
        <Typography variant="h4" gutterBottom>
          Mis Facturas
        </Typography>
      </Titulo>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
        >
          <Tab label="Facturas Por Planes" />
          <Tab label="Facturas Por Tareas" />
        </Tabs>
        <FacturaPlanesCliente value={value} />
        <FacturaTareasCliente value={value} />
      </AppBar>
    </Container>
  );
}
