import React, { useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Titulo } from "src/components/Titulo";
import Page from "src/components/Page";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import TablaGav from "src/components/liquidacion/TablaGav";
import { gavsApi } from "src/services/gavsApi";
import TablaRecaudacionMensual from "src/components/liquidacion/TablaRecaudacionMensual";
import TablaRecaudacionMensualGav from "src/components/liquidacion/TablaRecaudacionMensualGav";
import { informesApi } from "src/services/informesApi";
import dayjs from "dayjs";

const Liquidacion = () => {
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [tabIndex, setTabIndex] = useState(0);
  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();
  const [obtenerGavs, { data: gavs = [], isLoading: isLoadingGavs }] =
    gavsApi.useLazyObtenerGavsQuery();
  const [
    obtenerRecaudaciones,
    { data: recaudaciones = [], isLoading: isLoadingRecaudaciones },
  ] = informesApi.useLazyGetRecaudacionesMensualesSucursalesQuery();

  const [
    obtenerRecaudacionesGav,
    { data: recaudacionesGav = [], isLoading: isLoadingRecaudacionesGav },
  ] = informesApi.useLazyGetRecaudacionesMensualesGavQuery();

  const [obtenerInformeLiquidacion] =
    informesApi.useLazyGetInformeLiquidacionQuery();

  const verificarIndiceTab = useCallback(() => {
    if (tabIndex === 0) {
      obtenerGavs();
    } else if (tabIndex === 1) {
      // Para setear mes específico utilizar: .set("month", 0)
      const fecInicio = dayjs().startOf("month").format("YYYY/MM/DD");
      const fecTermino = dayjs().endOf("month").format("YYYY/MM/DD");
      const body = {
        fechaInicio: fecInicio,
        fechaTermino: fecTermino,
      };
      obtenerRecaudaciones(body);
    } else if (tabIndex === 2) {
      const fechaFacturacion = dayjs().startOf("month").format("YYYY/MM");
      const body = {
        fechaFacturacion: fechaFacturacion,
      };
      obtenerRecaudacionesGav(body);
    }
  }, [obtenerGavs, obtenerRecaudaciones, obtenerRecaudacionesGav, tabIndex]);

  useEffect(() => {
    verificarIndiceTab();
  }, [verificarIndiceTab]);
  
  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4" gutterBottom>
            Administración de Liquidaciones
          </Typography>
        </Titulo>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="tabs-configuracion-liquidaciones"
          >
            <Tab label="Gavs" />
            <Tab label="Recaudación mensual sucursales" />
            <Tab label="Recaudación mensual GAVS" />
          </Tabs>
        </AppBar>

        <TablaGav
          data={gavs}
          loading={isLoadingGavs}
          tabIndex={tabIndex}
          obtenerInformeLiquidacion={obtenerInformeLiquidacion}
        />
        <TablaRecaudacionMensual
          data={recaudaciones}
          loading={isLoadingRecaudaciones}
          tabIndex={tabIndex}
        />
        <TablaRecaudacionMensualGav
          data={recaudacionesGav}
          loading={isLoadingRecaudacionesGav}
          tabIndex={tabIndex}
        />
      </Container>
    </Page>
  );
};

export default Liquidacion;
