import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  blur: {
    filter: "blur(4px)",
    pointerEvents: "none",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: 1,
  },
  card: {
    maxWidth: 300,
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const ComingSoonCard = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.blur}>{children}</Box>
      <Box className={classes.overlay}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h4" component="h2" className={classes.title}>
              Próximamente
            </Typography>
            <Typography variant="body1">
              Esta función estará disponible en breve.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ComingSoonCard;
