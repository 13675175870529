import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Container,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";

import { LoadingButton } from "@material-ui/lab";
import { API_SERVER } from "src/utils/urlBack_End";
import axios from "axios";
import Tabla from "./Tabla";
import ModalTareaMasiva from "./ModalTareaMasiva";
import { useSelector } from "react-redux";

export default function AgregarTareaMasiva(props) {
  const {
    openTareaMasiva,
    setOpenTareaMasiva,
    setAlerta,
    setColor,
    setMessage,
  } = props;
  const [empleados, setEmpleados] = useState([]);

  const [monedas, setMonedas] = useState([]);
  const [tareaMasiva, setTareaMasiva] = useState([]);
  const [datosTareaMasiva, setDatosTareaMasiva] = useState([]);
  const [guardando, setGuardando] = useState(false);

  const [agregarTarea, setAgregarTarea] = useState(false);
  const {
    sucursal: sucursalObtenida,
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    axios.get(
      `${API_SERVER}/empleados/empleadoSucursal/${sucursalObtenida.refSucursal}/ALL`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      }
    )
    axios.get(
      `${API_SERVER}/empleados/empleadoSucursal/${sucursalObtenida.refSucursal}/ALL`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      }
    )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
  }, [sucursalObtenida.refSucursal]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}tipos/tipoMoneda`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setMonedas(response.data);
      });
  }, []);

  const guardarDatos = () => {
    if (datosTareaMasiva.length > 0) {
      setGuardando(true);
      Promise.all(
        datosTareaMasiva.map((tarea, i) =>
          axios
            .post(`${API_SERVER}/tareas/especialMasivo`, {
              ...tarea,
            },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("token")
                }
              }
            )
            .then(function (response) {
              setColor("success");
              setMessage(
                tareaMasiva[i].nombre +
                " asignada al vendedor: " +
                tareaMasiva[i].vendedor +
                " para el cliente: " +
                tareaMasiva[i].refCliente +
                " guardado correctamente."
              );
              setAlerta(true);
            })
            .catch(() => {
              setColor("error");
              setMessage("Ha ocurrido un error al guardar los datos");
              setAlerta(true);

            })
        )
      ).then(function (response) {
        setColor("success");
        setMessage("El guardado de los datos ha terminado");
        setAlerta(true);
        setOpenTareaMasiva(false);
        setGuardando(false);
      });
    }
  };

  return (
    <div>
      <Dialog
        open={openTareaMasiva}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">Nueva Tarea Masiva</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            {!agregarTarea && (
              <Container style={{ marginTop: 20, marginBottom: 40 }}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setAgregarTarea(true)}
                >
                  {" "}
                  Agregar Nueva Tarea
                </Button>
              </Container>
            )}

            <Tabla
              empleados={empleados}
              tarea={tareaMasiva}
              setTarea={setTareaMasiva}
              datosTarea={datosTareaMasiva}
              setDatosTarea={setDatosTareaMasiva}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={7}>
                {guardando && (
                  <Typography
                    style={{ color: "#FF0000" }}
                    variant="body3"
                    gutterBottom
                  >
                    El guardado de los datos podría durar varios minutos, por
                    favor espere...
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={2} justifyContent="flex-end">
                <Button
                  fullWidth
                  disabled={guardando}
                  onClick={() => setOpenTareaMasiva(false)}
                  style={{ marginRight: 5 }}
                  color="error"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6} sm={2}>
                <LoadingButton
                  fullWidth
                  onClick={() => guardarDatos()}
                  color="primary"
                  loading={guardando}
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Container>
        </DialogActions>
      </Dialog>
      {agregarTarea && (
        <ModalTareaMasiva
          agregarTarea={agregarTarea}
          setAgregarTarea={setAgregarTarea}
          setTareaMasiva={setTareaMasiva}
          datosTareaMasiva={datosTareaMasiva}
          setDatosTareaMasiva={setDatosTareaMasiva}
          empleados={empleados}
          monedas={monedas}
          setColor={setColor}
          setMessage={setMessage}
          setAlerta={setAlerta}
        />
      )}
    </div>
  );
}
