import React, { useState } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  Stack,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import { dbx } from "../../utils/urlBack_End";
import { propuestasApi } from "src/services/propuestasApi";
import { AlertaToast } from "../AlertaSwall";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  hidden: {
    display: "none",
  },
}));
// ----------------------------------------------------------------------

export default function ArchivoAdjuntoDialogPropuesta(props) {
  const {
    setShowError,
    setError,
    propuesta,
    openModalArchivo,
    setOpenModalArchivo,
  } = props;
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [showFile, setShowFile] = useState(!!propuesta.urlPropuesta);
  const [loading, setLoading] = useState(false);

  const [actualizarPropuesta] = propuestasApi.useUpdatePropuestaMutation();

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    if (fileAux.type !== "application/pdf") {
      return;
    }
    setFile(fileAux);
    setShowFile(true);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setShowFile(false);
  };

  const actualizarBaseDatos = async (task) => {
    const update = {
      refEmpleado: task.refEmpleado,
      nombreSolicitante: task.nombreSolicitante,
      rutSolicitante: task.rutSolicitante,
      telefonoSolicitante: task.telefonoSolicitante,
      correoSolicitante: task.correoSolicitante,
      estado: task.estado,
      fechaEnvioCorreo: task.fechaEnvioCorreo,
      comentarios: task.comentarios,
      supervisor: task.supervisor,
      urlPropuesta: task.urlPropuesta,
    };

    actualizarPropuesta({
      id: task.id,
      body: update,
    })
      .then(() => {})
      .catch(() => {
        setError("No se pudo actualizar la tarea en la base de datos.");
        setShowError(true);
      });
  };

  const deleteFileFromDropbox = () => {
    return dbx.filesDeleteV2({ path: `/Propuestas/${propuesta.id}.pdf` });
  };

  const deleteFileFromDB = async () => {
    const update = {
      refEmpleado: propuesta.refEmpleado,
      nombreSolicitante: propuesta.nombreSolicitante,
      rutSolicitante: propuesta.rutSolicitante,
      telefonoSolicitante: propuesta.telefonoSolicitante,
      correoSolicitante: propuesta.correoSolicitante,
      estado: propuesta.estado,
      fechaEnvioCorreo: propuesta.fechaEnvioCorreo,
      comentarios: propuesta.comentarios,
      supervisor: propuesta.supervisor,
      urlPropuesta: "",
    };
    actualizarPropuesta({
      id: propuesta.id,
      body: update,
    })
      .then(() => {})
      .catch(() => {
        setError("No se pudo actualizar el archivo en la base de datos.");
        setShowError(true);
      });
  };

  const removeFile = async () => {
    const propuestaAux = { ...propuesta };
    propuestaAux.urlArchivo = "";
    try {
      await deleteFileFromDropbox();
      deleteFileFromDB();
    } catch (ex) {
      setError("No se pudo eliminar el archivo adjunto.");
      setShowError(true);

      setOpenModalArchivo(false);
    } finally {
      setLoading(false);
    }
  };

  async function uploadFile() {
    setLoading(true);
    if (!file && propuesta.urlPropuesta) return removeFile();
    if (!file) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      setError("El tamaño máximo de un archivo es de 150 MB.");
      setShowError(true);
      setOpenModalArchivo(false);
      return;
    }

    try {
      await dbx.filesUpload({
        path: "/Propuestas/" + propuesta.id + ".pdf",
        contents: file,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: "/Propuestas/" + propuesta.id + ".pdf",
        });
        let url = shareResponse.result.url;
        let propuestaAux = { ...propuesta };
        propuestaAux.urlPropuesta = url;
        actualizarBaseDatos(propuestaAux);
      } catch (ex) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;
        let propuestaAux = { ...propuesta };
        propuestaAux.urlPropuesta = url;
        actualizarBaseDatos(propuestaAux);
      } finally {
        setLoading(false);
        AlertaToast({
          element: "Archivo",
          action: "añadido",
        }).fire();
      }
    } catch (ex) {
      setError("Ha ocurrido un error que impidió guardar los cambios.");
    } finally {
      setLoading(false);
    }
    setOpenModalArchivo(false);
  }

  return (
    <Dialog open={openModalArchivo}>
      <DialogTitle>Archivo Propuesta</DialogTitle>
      <DialogContent>
        <Container>
          <RootStyle>
            <Card style={{ padding: "1rem" }}>
              <Alert severity="info">
                Haga click en el botón 'Seleccionar Archivo' para subir un
                documento en formato PDF asociado a esta propuesta.
              </Alert>

              <Alert severity="warning">
                Precaución: Solo se puede adjuntar un archivo por propuesta.
              </Alert>

              <div style={{ marginTop: "10px" }}>
                <input
                  accept="application/pdf"
                  className={classes.hidden}
                  id="contained-button-file"
                  onChange={handleFileInput}
                  type="file"
                />

                <label htmlFor={"contained-button-file"}>
                  <Button component="span" m={1} color="primary">
                    Seleccionar Archivo
                  </Button>
                </label>

                {showFile && (propuesta.urlPropuesta || file) && (
                  <span>
                    <span
                      style={
                        propuesta.urlPropuesta ? { cursor: "pointer" } : {}
                      }
                    >
                      {propuesta.urlPropuesta ? (
                        <a
                          href={propuesta.urlPropuesta}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {propuesta.id}
                          {".pdf"}
                        </a>
                      ) : (
                        <>
                          {" "}
                          {propuesta.id}
                          {".pdf"}
                        </>
                      )}
                    </span>

                    <span>
                      <IconButton
                        onClick={handleDeleteFile}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </span>
                )}
              </div>
            </Card>

            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <LoadingButton
                style={{ marginRight: 10 }}
                disabled={loading}
                onClick={() => {
                  setOpenModalArchivo(false);
                }}
                color="error"
              >
                Cancelar
              </LoadingButton>

              <LoadingButton
                loading={loading}
                onClick={uploadFile}
                color="primary"
              >
                Guardar
              </LoadingButton>
            </Stack>
          </RootStyle>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
