import React, { useState, useEffect } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { lowerCase, startCase } from "lodash";
// material
import {
  TextField,
  Card,
  Typography,
  FormControl,
  Stack,
  Snackbar,
  Alert,
  Autocomplete,
} from "@material-ui/core";
import axios from "axios";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { useSelector } from "react-redux";

export default function VerPropuestaPlanes(props) {
  const { sucursal: sucursalObtenida, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const { setPropuesta, propuesta } = props;

  const [estado, setEstado] = useState(propuesta.estado);
  const [fechaEnvioCorreo, setFechaEnvioCorreo] = useState(
    propuesta.fechaEnvioCorreo
  );
  const vendedor = propuesta.vendedor;
  const [supervisor, setSupervisor] = useState(propuesta.supervisor);
  const [refEmpleado, setRefEmpleado] = useState(propuesta.refEmpleado);
  const [empleados, setEmpleados] = useState([]);

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [mostrarCircular, setMostrarCircular] = useState(false);

  const autorizado = rolObtenido !== "EMPLEADO";

  const [empleadoOptions, setEmpleadoOptions] = useState([]);

  const [empleadoPrincipalSelect, setEmpleadoPrincipalSelect] = useState(null);
  const [supervisorSelect, setSupervisorSelect] = useState(null);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ALL`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
  }, [sucursal]);

  useEffect(() => {
    setEmpleadoOptions(
      empleados.map((item) => {
        return {
          label: startCase(lowerCase(item.nombre + " " + item.apellidos)),
          value: item.rut,
        };
      })
    );
  }, [empleados]);

  useEffect(() => {
    if (refEmpleado !== "" && empleadoOptions.length > 0) {
      setSupervisorOptions(
        empleadoOptions.filter((option) => option.value !== refEmpleado)
      );
    }
  }, [refEmpleado, empleadoOptions]);

  useEffect(() => {
    const buscarEmpleado = () => {
      let empleado = null;
      let refSupervisor = null;
      if (refEmpleado !== "" && empleadoOptions.length > 0) {
        empleado = empleadoOptions.find((item) => item.value === refEmpleado);

        setEmpleadoPrincipalSelect(empleado !== undefined ? empleado : null);

        const supervisores =
          empleado !== undefined
            ? empleadoOptions.filter((item) => item.value !== empleado.value)
            : [];

        setSupervisorOptions([...supervisores]);
      }

      if (supervisor !== "" && empleadoOptions.length > 0) {
        refSupervisor = empleadoOptions.find(
          (item) => item.value === supervisor
        );
        setSupervisorSelect(refSupervisor !== undefined ? refSupervisor : null);
      }
    };
    if (supervisorSelect === null && empleadoPrincipalSelect === null)
      buscarEmpleado();
  }, [
    empleadoOptions,
    empleadoPrincipalSelect,
    supervisorSelect,
    refEmpleado,
    supervisor,
  ]);

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const guardarCambios = () => {
    setMostrarCircular(true);
    if (
      (estado === "ENVIADA" ||
        estado === "ACEPTADA" ||
        estado === "RECHAZADA") &&
      (fechaEnvioCorreo === "" || fechaEnvioCorreo === " ")
    ) {
      setColorNotificacion("info");
      setMensajeNotificacion("Se debe ingresar la fecha del envio del correo.");
      setMostrarCircular(false);
      setOpenNotificacion(true);
    } else if (supervisor === refEmpleado) {
      setColorNotificacion("error");
      setMensajeNotificacion("El supervisor no puede ser igual al empleado.");
      setMostrarCircular(false);
      setOpenNotificacion(true);
    }
    else if (estado.toUpperCase() === "ACEPTADA") {
      setColorNotificacion("error");
      setMensajeNotificacion("Para cambiar el estado a aceptada, se debe realizar desde la tabla.");
      setMostrarCircular(false);
      setOpenNotificacion(true);
    }
    else {
      let datosSolicitante = {
        estado: estado.toUpperCase(),
        fechaEnvioCorreo: fechaEnvioCorreo,
        refEmpleado: refEmpleado,
        supervisor: supervisor,
      };

      axios
        .put(
          `${API_SERVER}/propuestas/datosPropuesta/${propuesta.id}`,
          datosSolicitante,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          setEstado(estado.toUpperCase());
          setFechaEnvioCorreo(fechaEnvioCorreo);

          setRefEmpleado(refEmpleado);
          setSupervisor(supervisor);
          setColorNotificacion("success");
          setMensajeNotificacion("Datos cambiados correctamente");
          setMostrarCircular(false);
          setOpenNotificacion(true);
          axios
            .get(`${API_SERVER}/propuestas/${propuesta.id}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              setPropuesta(response.data);
            });
        })
        .catch(function (error) {
          setColorNotificacion("error");
          setMensajeNotificacion("Ha ocurrido un error con el servidor");
          setMostrarCircular(false);
          setOpenNotificacion(true);
        });
    }
  };

  const handleChangeSupervisores = (value) => {
    const supervisores = empleadoOptions.filter(
      (item) => item.value !== value.value
    );

    setSupervisorOptions([...supervisores]);
  };

  return (
    <>
      <Card>
        <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
          Datos Propuesta
        </Typography>

        <Alert severity="info">
          Información: El estado se puede cambiar en la tabla de propuestas.
        </Alert>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            marginTop: "30px",
          }}>
          <TextField
            margin="dense"
            label="Estado"
            type="text"
            value={propuesta.estado}
            fullWidth
            disabled
          />
          <TextField
            margin="dense"
            label="Fecha de Envio"
            type="text"
            value={propuesta.fechaEnvioCorreo}
            fullWidth
            disabled
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            marginTop: "30px",
          }}>
          <TextField
            margin="dense"
            label="Vendedor"
            type="text"
            value={vendedor}
            fullWidth
            disabled
          />
        </Stack>

        {(rolObtenido === "SUPER_ADMIN" ||
          rolObtenido === "ADMIN" ||
          rolObtenido === "SUPERVISOR") && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              spacing={3}
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "30px",
              }}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!autorizado}
                  disablePortal
                  value={empleadoPrincipalSelect}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setRefEmpleado(newValue.value);
                      setEmpleadoPrincipalSelect(newValue);
                      handleChangeSupervisores(newValue);
                    } else {
                      setRefEmpleado("");
                      setEmpleadoPrincipalSelect(null);
                    }
                  }}
                  fullWidth
                  id="combo-box-empleados"
                  options={empleadoOptions}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      type="rut"
                      label="Empleado Principal"
                      required
                      value={refEmpleado}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}

        {(rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={3}
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              marginTop: "30px",
            }}>
            <FormControl fullWidth>
              <Autocomplete
                disabled={!autorizado}
                disablePortal
                value={supervisorSelect}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setSupervisor(newValue.value);
                    setSupervisorSelect(newValue);
                  } else {
                    setSupervisor("");
                    setSupervisorSelect(null);
                  }
                }}
                fullWidth
                id="combo-box-Supervisor"
                options={supervisorOptions}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    type="rut"
                    label="Supervisor Asignado"
                    value={supervisor}
                  />
                )}
              />
            </FormControl>
          </Stack>
        )}
        <div
          style={{
            textAlign: "right",
            marginRight: "25px",
            marginBottom: "25px",
          }}>
          <LoadingButton
            loading={mostrarCircular}
            variant="contained"
            color="primary"
            onClick={guardarCambios}
            disabled={propuesta.estado === "ACEPTADA" && true}>
            Guardar Cambios
          </LoadingButton>
        </div>
      </Card>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}>
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}>
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
    </>
  );
}
