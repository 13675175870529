import React, { useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Titulo } from "src/components/Titulo";
import Page from "src/components/Page";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import TablaProrrateo from "src/components/prorrateosConfiguraciones/TablaProrrateo";
import TablaCategoria from "src/components/prorrateosConfiguraciones/TablaCategoria";
import { categoriasApi } from "src/services/categoriasApi";
import { prorrateosApi } from "src/services/prorrateosApi";

const ProrrateoVista = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();
  const [
    obtenerCategoriasYsubcategorias,
    {
      data: categoriasYsubcategorias = [],
      isLoading: isLoadingCategoriasYsubcategorias,
    },
  ] = categoriasApi.useLazyObtenerCategoriasYsubcategoriasQuery();

  const [
    obtenerInfoProrrateo,
    {
      data: informacionProrrateo = [],
      isLoading: isLoadingInformacionProrrateo,
    },
  ] = prorrateosApi.useLazyObtenerInformacionProrrateoQuery();

  const verificarIndiceTab = useCallback(() => {
    if (tabIndex === 0) {
      obtenerInfoProrrateo();
    } else if (tabIndex === 1) {
      obtenerCategoriasYsubcategorias();
    }
  }, [obtenerCategoriasYsubcategorias, obtenerInfoProrrateo, tabIndex]);

  useEffect(() => {
    verificarIndiceTab();
  }, [verificarIndiceTab]);

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs={6}>
              <Typography variant="h4" gutterBottom>
                Administración de Prorrateo
              </Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Titulo>
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="tabs-configuracion-prorrateo"
            >
              <Tab label="Prorrateo" />
              <Tab label="Categorías y subcategorías" />
            </Tabs>
          </AppBar>

          <TablaProrrateo
            tabIndex={tabIndex}
            data={informacionProrrateo}
            loading={isLoadingInformacionProrrateo}
          />
          <TablaCategoria
            tabIndex={tabIndex}
            data={categoriasYsubcategorias}
            loading={isLoadingCategoriasYsubcategorias}
          />
        </>
      </Container>
    </Page>
  );
};

export default ProrrateoVista;
