import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Label from "../Label";
import IconButton from "@material-ui/core/IconButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sentenceCase } from "change-case";
import ModifyTaskStateModal from "../_dashboard/tareas/ModifyTaskStateModal";
import AgregarSubTareaDialog from "./AgregarSubTareaDialog";
import EditarSubTareaDialog from "./EditarSubTareaDialog";
import { useSelector } from "react-redux";
import moment from "moment";
import { subtareasApi } from "src/services/subtareasApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { tareasApi } from "src/services/tareasApi";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hidden: {
    display: "none",
  },
});

export default function SubTareasDialog(props) {
  const { open, setOpen, tarea } = props;
  const { empleado: user } = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const [showModifyTaskState, setShowModifyTaskState] = useState(false);
  const [selectedTask, setSelectedTask] = useState();

  const { data: subtareas = [] } = subtareasApi.useGetSubtareasTareaQuery(
    tarea.id
  );

  const [actualizarEstadoSubtarea] =
    subtareasApi.useUpdateEstadoSubtareaMutation();
  const [eliminarSubtarea] = subtareasApi.useDeleteSubtareaMutation();
  const [actualizarEstadoTarea] = tareasApi.useUpdateCambiaEstadoMutation();

  const [mostrarAgregar, setMostrarAgregar] = useState(false);

  const [selectedSubTaskEdit, setSelectedSubTaskEdit] = useState(null);
  const [mostrarEditar, setMostrarEditar] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTaskState = (id) => {
    const selectedTaskAux = subtareas.find((t) => t.id === id);
    setSelectedTask(selectedTaskAux);
    setShowModifyTaskState(true);
  };

  const doChangeTaskState = (newState) => {
    if (selectedTask.estado === newState) return setShowModifyTaskState(false);
    actualizarEstadoSubtarea({
      id: selectedTask.id,
      body: {
        estado: newState,
      },
    })
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Subtarea",
          action: "actualizada",
          target: document.getElementById("dialogSubtarea"),
        }).fire();
      })
      .catch(() => {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
          target: document.getElementById("dialogSubtarea"),
        }).fire();
      });
    setShowModifyTaskState(false);
  };

  const handleDelete = (id) => {
    AlertaModal({
      title: "eliminar la subtarea",
      confirmButtonText: "Sí, eliminar",
      target: document.getElementById("dialogSubtarea"),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarSubtarea(id)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Subtarea",
                action: "eliminada",
                target: document.getElementById("dialogSubtarea"),
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogSubtarea"),
              }).fire();
            });
        }
      });
  };

  useEffect(() => {
    if (tarea.estado === "PENDIENTE" && tarea.refEmpleado === user.rut) {
      actualizarEstadoTarea({
        id: tarea.id,
        body: {
          estado: "EN PROCESO",
          fechaTermino: "",
        },
      }).unwrap();
    }
  }, [tarea.estado, tarea.refEmpleado, tarea.id, user.rut]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" id="dialogSubtarea">
      <DialogTitle>Subtareas</DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              setMostrarAgregar(true);
            }}
          >
            Agregar SubTarea
          </Button>
        </Stack>

        <Card style={{ padding: "1rem" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha Inicio</TableCell>
                  <TableCell>Fecha Término</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subtareas &&
                  subtareas.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {row.nombre}
                      </TableCell>
                      <TableCell>
                        {row.fechaInicio === ""
                          ? row.fechaInicio
                          : moment(row.fechaInicio, "YYYY/MM/DD").format(
                              "DD/MM/YYYY"
                            )}
                      </TableCell>
                      <TableCell>
                        {row.fechaTermino === ""
                          ? row.fechaTermino
                          : moment(row.fechaTermino, "YYYY/MM/DD").format(
                              "DD/MM/YYYY"
                            )}
                      </TableCell>
                      <TableCell onClick={() => handleChangeTaskState(row.id)}>
                        <Label
                          onClick={() => handleChangeTaskState(row.id)}
                          style={{ cursor: "pointer" }}
                          variant="ghost"
                          color={
                            (row.estado === "PENDIENTE" && "error") ||
                            (row.estado === "EN PROCESO" && "warning") ||
                            (row.estado === "TERMINADA" && "success") ||
                            "info"
                          }
                        >
                          {sentenceCase(row.estado)}
                        </Label>
                      </TableCell>
                      <TableCell>
                        {row.estado === "TERMINADA" ? (
                          <></>
                        ) : (
                          <React.Fragment>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setSelectedSubTaskEdit(row);
                                setMostrarEditar(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </React.Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            {selectedTask && (
              <ModifyTaskStateModal
                task={selectedTask}
                onSubmit={doChangeTaskState}
                onClose={() => setShowModifyTaskState(false)}
                states={[
                  "PENDIENTE",
                  "EN PROCESO",
                  "OBSERVADA SII",
                  "TERMINADA",
                ]}
                open={showModifyTaskState}
                cargandoDatos={false}
              />
            )}
          </TableContainer>
        </Card>

        {mostrarAgregar && (
          <AgregarSubTareaDialog
            open={mostrarAgregar}
            setOpen={setMostrarAgregar}
            tarea={tarea}
          />
        )}
        {mostrarEditar && (
          <EditarSubTareaDialog
            open={mostrarEditar}
            setOpen={setMostrarEditar}
            subTarea={selectedSubTaskEdit}
            tarea={tarea}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
