import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { API_SERVER } from '../utils/urlBack_End';

export const sucursalesApi = createApi({
    reducerPath: 'sucursalesApi',
    baseQuery: baseQuery(`${API_SERVER}/sucursales`),
    tagTypes: [
        "SucursalesEmpleados",
        "Sucursales"
    ],
    endpoints: (builder) => ({
        getSucursales: builder.query({
            query: () => `/`,
            providesTags: ["Sucursales"],
        }),
        getSucursalID: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["Sucursales"],
        }),
        getSucursalesEmpleados: builder.query({
            query: (id) => `/sucursalesEmpleado/${id}`,
            providesTags: ["SucursalesEmpleados"],
        }),
        deleteSucursal: builder.mutation({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Sucursales"],
        }),
        addSucursal: builder.mutation({
            query: (body) => ({
                url: `/`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Sucursales"],
        }),
        updateSucursal: builder.mutation({
            query: ({ id, body }) => ({
                url: `/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["Sucursales"],
        }),
    }),
});

export const {
    useGetSucursalIDQuery,
    useGetSucursalesEmpleadosQuery,
    useGetSucursalesQuery,
    useLazyGetSucursalIDQuery,
    useLazyGetSucursalesEmpleadosQuery,
    useLazyGetSucursalesQuery,
    useAddSucursalMutation,
    useDeleteSucursalMutation,
    useUpdateSucursalMutation
} = sucursalesApi;