import React, { useState, useEffect } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Typography,
    Skeleton,
    TablePagination,
    Grid,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Alert
} from "@material-ui/core";
import Scrollbar from "../Scrollbar";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import SearchNotFound from "../SearchNotFound";
import * as locales from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { filter } from "lodash";
import { LoadingButton } from '@material-ui/lab';
import MyConfirmationDialog from '../MyConfirmationDialog';
import { useSelector } from 'react-redux';

const TABLE_HEAD = [
    { id: "nombre", label: "Cliente", alignRight: false },
    { id: "grupo", label: "Grupo", alignRight: false },
    { id: "empleado", label: "Empleado", alignRight: false },
    { id: "", label: "", alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => {
            return (
                _user.nombreCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                _user.nombreEmpleado.toLowerCase().indexOf(query.toLowerCase()) !==
                -1 ||
                _user.apellidoEmpleado.toLowerCase().indexOf(query.toLowerCase()) !== -1 || (
                    _user.grupoCliente && _user.grupoCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1
                )

            );
        });
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function MigrarPlanesDialog(props) {
    const { open, setOpen, planesEntrada } = props;
    const {
        empleado: user,
        sucursal: sucursalObtenida,
    } = useSelector((state) => state.auth.user);
    const sucursal = sucursalObtenida.refSucursal;
    const [locale] = useState("esES");
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState("asc");
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState("nombre");
    const [filterName, setFilterName] = useState("");
    const [loadingTable, setLoadingTable] = useState(true);

    const [planes, setPlanes] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    const [sucursales, setSucursales] = useState([]);

    const [cargandoMigracion, setCargandoMigracion] = useState(false);

    const [sucursalSeleccionada, setSucursalSeleccionada] = useState(sucursal);
    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState("");
    const [planesSeleccionados, setPlanesSeleccionados] = useState([]);
    const [conservarEmpleadosAdicionales, setConservarEmpleadosAdiciones] = useState("NO");

    const [mensajeAlerta, setMensajeAlerta] = useState("");
    const [pasa, setPasa] = useState(false);
    const [mostrarMensajeEliminacion, setMostrarMensajeEliminacion] = useState(false);


    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (planesEntrada) {
            setPlanes(planesEntrada.filter(e => e.estado === "ACTIVO" && e.contratoFirmado === "SI"))
            setLoadingTable(false)
        };
    }, [planesEntrada]);

    useEffect(() => {
        axios
            .get(`${API_SERVER}/sucursales/`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(function (response) {
                if (response.data === -2) window.location.reload();
                setSucursales(response.data);
            });
    }, [])

    useEffect(() => {
        axios
            .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursalSeleccionada}/TODOS`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(function (response) {
                if (response.data === -2) window.location.reload();
                setEmpleados(response.data);
            });
    }, [user.rut, sucursalSeleccionada]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = planes.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - planes.length) : 0;

    const filteredUsers = applySortFilter(
        planes,
        getComparator(order, orderBy),
        filterName
    );

    const isUserNotFound = filteredUsers.length === 0;





    const handleCloseDialog = () => {
        setOpen(false);
    };



    const migrando = () => {
        setCargandoMigracion(true);
        axios
            .post(API_SERVER + "/planes/migrar", {
                sucursalSeleccionada: sucursalSeleccionada,
                empleadoSeleccionado: empleadoSeleccionado,
                conservarEmpleadosAdicionales: conservarEmpleadosAdicionales,
                planesSeleccionados: planesSeleccionados,
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(function (response) {
                if (response.data === -2) window.location.reload();
                if (Array.isArray(response.data) && Array(response.data).length > 1) {
                    let arregloData = response.data;
                    setMensajeAlerta("Los siguientes datos no se pudieron copiar \n" + arregloData.toString());
                    setPasa(false);
                    setMostrarMensajeEliminacion(true);
                    setCargandoMigracion(false)

                }
                else {
                    setMensajeAlerta("Datos actualizados con éxito");
                    setPasa(true);
                    setMostrarMensajeEliminacion(true);
                    setCargandoMigracion(false)

                }
            }).catch(() => {
                setCargandoMigracion(false)
            });


    };



    return (
        <>
            <Dialog
                open={open}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Migracion de Planes</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <TablaToolbar
                                numSelected={selected.length}
                                filterName={filterName}
                                onFilterName={handleFilterByName}

                            />

                        </Grid>

                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Alert>Empresas Seleccionadas: {planesSeleccionados.length}</Alert>

                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                fullWidth
                                style={{ marginTop: "20px" }}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Migrar a
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"


                                    label="Migrar a:"
                                    value={sucursalSeleccionada}
                                    onChange={(e) => {
                                        setSucursalSeleccionada(e.target.value)
                                        axios
                                            .get(`${API_SERVER}/empleados/empleadoSucursal/${e.target.value}/TODOS`, {
                                                headers: {
                                                    Authorization: 'Bearer ' + localStorage.getItem("token")
                                                }
                                            })
                                            .then(function (response) {
                                                if (response.data === -2) window.location.reload();
                                                setEmpleados(response.data);
                                            });
                                    }}

                                >
                                    {sucursales.map((sucursalSelect, index) => {
                                        return (
                                            <MenuItem key={"sucursales_" + index} value={sucursalSelect.nombre}>
                                                {sucursalSelect.nombre}
                                            </MenuItem>
                                        );


                                    })}
                                </Select>
                            </FormControl>


                        </Grid>


                    </Grid>
                    <Grid container>

                        <Grid item xs={12} md={12}>
                            <FormControl
                                fullWidth
                                style={{ marginTop: "20px" }}
                            >
                                <InputLabel id="demo-simple-select-label2">
                                    Nuevo Empleado
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    label="Nuevo Empleado"
                                    value={empleadoSeleccionado}
                                    onChange={(e) => {
                                        setEmpleadoSeleccionado(e.target.value)


                                    }}

                                >
                                    {empleados.map((empleadoSelect, index) => {
                                        return (
                                            <MenuItem key={"empleados_" + index} value={empleadoSelect.rut}>
                                                {empleadoSelect.nombre + " " + empleadoSelect.apellidos}
                                            </MenuItem>
                                        );



                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "10px" }}
                        >
                            <Grid container>
                                <Grid item xs={8} md={8}>
                                    <Alert severity="info">Conservar Empleados Adicionales:</Alert>

                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <InputLabel id="demo-simple-select-label3">
                                            Conservar Empleados Adicionales
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label3"
                                            label="Conservar Empleados Adicionales"
                                            value={conservarEmpleadosAdicionales}
                                            onChange={(e) => {
                                                setConservarEmpleadosAdiciones(e.target.value)


                                            }}

                                        >
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                            <MenuItem value={"SI"}>SI</MenuItem>

                                        </Select>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Scrollbar>
                        <TableContainer>
                            {!loadingTable ? (
                                <Table>
                                    <TablaHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={planes.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row) => {
                                                const {
                                                    id,
                                                    nombreCliente,
                                                    nombreEmpleado,
                                                    apellidoEmpleado,
                                                    grupoCliente
                                                } = row;
                                                const isItemSelected =
                                                    selected.indexOf(nombreCliente) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                    >

                                                        <TableCell
                                                            component="th"
                                                            scope="row"

                                                        >
                                                            <Typography variant="subtitle2" display="inline">
                                                                {nombreCliente}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {grupoCliente}
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {nombreEmpleado + " " + apellidoEmpleado}
                                                        </TableCell>
                                                        {empleadoSeleccionado !== "" && (
                                                            <TableCell
                                                            >
                                                                <Checkbox
                                                                    checked={planesSeleccionados.filter(e => e.id === row.id)[0] ? true : false}
                                                                    onChange={(event) => {
                                                                        if (event.target.checked) {
                                                                            setPlanesSeleccionados([...planesSeleccionados, row])

                                                                        }
                                                                        else {
                                                                            let planesSeleccionadosAux = planesSeleccionados.filter(e => e.id !== row.id)
                                                                            setPlanesSeleccionados(planesSeleccionadosAux)

                                                                        }


                                                                    }}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />

                                                            </TableCell>

                                                        )}

                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6}>
                                                    No hay Planes que mostrar.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            ) : (
                                <Skeleton variant="rectangular" width="100%" height="500px" />
                            )}
                        </TableContainer>
                    </Scrollbar>

                    <ThemeProvider
                        theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
                    >
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={planes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </ThemeProvider>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                    >
                        Cerrar
                    </Button>
                    <LoadingButton
                        loading={cargandoMigracion}
                        variant="contained"
                        onClick={migrando}
                    >
                        Migrar Planes
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {mostrarMensajeEliminacion && (
                <MyConfirmationDialog
                    onSubmit={() => {
                        if (pasa) {
                            window.location.reload();

                        }
                        else {
                            setMostrarMensajeEliminacion(false)

                        }

                    }}
                    onClose={() => {
                        if (pasa) {
                            window.location.reload();

                        }
                        else {
                            setMostrarMensajeEliminacion(false)

                        }


                    }}
                    title="Resultado Migración"
                    content={mensajeAlerta}
                    open={mostrarMensajeEliminacion}
                />

            )}


        </>

    );
}