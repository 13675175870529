import React from "react";
import { sentenceCase } from "change-case";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  Button,
  CardContent,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  Grid
} from "@material-ui/core";
import Label from "../Label";

export default function SubTareasDialogCliente(props) {
  const { open, setOpen, subTareas } = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Progreso</DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subTareas.map((row, id) => (
                    <TableRow
                      key={"subtareas_" + id}
                    >
                      <TableCell component="th" scope="row">
                        {row.nombreSubTarea}
                      </TableCell>
                      <TableCell>
                        <Label
                          variant="ghost"
                          color={
                            (row.estadoSubTarea === "PENDIENTE" && "error") ||
                            (row.estadoSubTarea === "EN PROCESO" && "warning") ||
                            (row.estadoSubTarea === "TERMINADA" && "success") ||
                            "info"
                          }
                        >
                          {sentenceCase(row.estadoSubTarea)}
                        </Label>
                        { }
                      </TableCell>
                      {row.mostrarArchivo === "SI" && row.urlArchivo && (
                        <TableCell>
                          <Grid item xs={10} md={10}>
                            <a
                              href={row.urlArchivo}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar
                            </a>
                          </Grid>
                        </TableCell>

                      )}

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </CardContent>
        </Card>

        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
