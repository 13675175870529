import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";

export const AlertaMensajeEliminacion = (props) => {
  const { mensaje, open, setOpen, aceptar, mensajeBotonAceptar, ocultar } = props;

  const [loading, setLoading] = useState(false);



  const handleClose = () => {
    setOpen(false);
  };
  const handleAceptar = () => {
    setLoading(true);
    aceptar();
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
    >
      <DialogContent>
        <DialogContentText>
          {mensaje}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {ocultar && ocultar === "Ocultar" ? (
          <></>

        ) : (
          <Button onClick={handleClose}>Cancelar</Button>

        )}

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleAceptar}
          autoFocus
        >
          {mensajeBotonAceptar}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

