import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowFill from "@iconify/icons-eva/arrow-back-fill";

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import * as locales from "@material-ui/core/locale";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { TablaHead, TablaToolbar } from "../components/_dashboard/tablas";
//
import AgregarPlan from "../components/tipoplanes/AgregarPlan";
import EditarPlan from "src/components/tipoplanes/EditarPlan";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { tiposApi } from "src/services/tiposApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { empleadosApi } from "src/services/empleadosApi";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "nombre", label: "Nombre Plan", alignRight: false },
  { id: "valorPlan", label: "Valor Plan", alignRight: false },
  { id: "tipoMoneda", label: "Tipo Moneda", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TipoPlanes() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [elementoSeleccionado, setElementoSeleccionado] = useState(null);
  const [locale] = useState("esES");
  const { empleado: user } = useSelector((state) => state.auth.user);

  const { data: tipoPlanes = [] } = tiposApi.useGetTipoPlanesQuery();

  const { data: monedas = [] } = tiposApi.useGetTipoMonedaQuery();

  const [eliminarTipoPlanDB] = tiposApi.useDeleteTipoPlanesMutation();

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [user.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tipoPlanes.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tipoPlanes.length) : 0;

  const filteredUsers = applySortFilter(
    tipoPlanes,
    getComparator(order, orderBy),
    filterName
  );

  const eliminarPlan = (nombre) => {
    AlertaModal({
      title: `eliminar el plan ${nombre}`,
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarTipoPlanDB(nombre)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Plan",
                action: "eliminado",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="TAXTIC">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Button
            variant="contained"
            color="info"
            startIcon={<Icon icon={arrowFill} />}
            onClick={() => {
              navigate("/dashboard/configuraciones");
            }}
          >
            Volver
          </Button>
          <Typography variant="h4" gutterBottom>
            Tipo Planes
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleClickOpen}
          >
            Agregar Plan
          </Button>
        </Stack>

        <Card>
          <TablaToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TablaHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tipoPlanes.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { nombre, valor, tipoMoneda } = row;
                      const isItemSelected = selected.indexOf(nombre) !== -1;

                      return (
                        <TableRow
                          hover
                          key={nombre}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            align="left"
                            onClick={() => {
                              setElementoSeleccionado(row);
                              setOpenEditar(true);
                            }}
                          >
                            <Typography variant="subtitle2">
                              {nombre}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() => {
                              setElementoSeleccionado(row);
                              setOpenEditar(true);
                            }}
                          >
                            {valor}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() => {
                              setElementoSeleccionado(row);
                              setOpenEditar(true);
                            }}
                          >
                            {tipoMoneda}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Eliminar">
                              <Button
                                color="error"
                                onClick={() => {
                                  eliminarPlan(row.nombre);
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={tipoPlanes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      </Container>
      {open && <AgregarPlan open={open} setOpen={setOpen} monedas={monedas} />}
      {openEditar && (
        <EditarPlan
          openEditar={openEditar}
          setOpenEditar={setOpenEditar}
          monedas={monedas}
          nombrePlanDato={elementoSeleccionado.nombre}
          valorPlanDato={elementoSeleccionado.valor}
          tipoMonedaDato={elementoSeleccionado.tipoMoneda}
          descripcionDato={elementoSeleccionado.descripcion}
        />
      )}
    </Page>
  );
}
