import React, { useRef, useState, useEffect } from "react";
import {
  API_KEY_OPEN_FACTURA_DEV,
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_DEV,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
  URL_ORIGEN,
} from "../../../utils/urlBack_End";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { saveAs } from "file-saver";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
// material
import {
  Button,
  Card,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Stack,
} from "@material-ui/core";
import axios from "axios";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { es } from "date-fns/locale";
import DownloadIcon from "@material-ui/icons/Download";
import { LoadingButton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import moment from "moment";

const TABLE_HEAD = [
  { id: "folio", label: "Folio", alignRight: false },
  { id: "valorPesos", label: "Total ($)", alignRight: false },
  { id: "fecha", label: "Fecha", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha Facturación", alignRight: false },
  { id: "fechaCorreo", label: "Fecha Envio Correo", alignRight: false },
  {
    id: "empleadoEnviaCorreo",
    label: "Empleado Envia Correo",
    alignRight: false,
  },
  { id: "estadoCorreo", label: "Estado Correo", alignRight: false },
  { id: "estadoFactura", label: "Estado Facturación", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filters) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.folio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaFacturacion.toLowerCase().indexOf(query.toLowerCase()) !==
        -1 ||
        _user.fechaCorreo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.empleadoEnviaCorreo.toLowerCase().indexOf(query.toLowerCase()) !==
        -1 ||
        _user.estadoCorreo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estadoFactura.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FacturaPlanesCliente(props) {
  const { value } = props;
  const { cliente: user } = useSelector((state) => state.auth.user);
  const [locale] = useState("esES");

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [facturas, setFacturas] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const [loadingDescargar, setLoadingDescargar] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/accesoClientes/facturas/planes/${user.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setFacturas(response.data);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingTable(false);
        }
      });
  }, [user.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = facturas.map((n) => n.nombre);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;

    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - facturas.length) : 0;

  const filteredUsers = applySortFilter(
    facturas,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  function getAbsolutePath() {
    var loc = window.location;
    var pathName = loc.pathname.substring(0, loc.pathname.lastIndexOf("/") + 1);
    return loc.href.substring(
      0,
      loc.href.length -
      ((loc.pathname + loc.search + loc.hash).length - pathName.length)
    );
  }

  return (
    <>
      {value === 0 && (
        <Card>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <Grid
              container
              style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}>
              <Grid item xs={12} md={3}>
                <TablaToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <Scrollbar>
            <TableContainer>
              {!loadingTable ? (
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={facturas.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          folio,
                          valorPesos,
                          fecha,
                          fechaFacturacion,
                          fechaCorreo,
                          empleadoEnviaCorreo,
                          estadoCorreo,
                          estadoFactura,
                        } = row;
                        const isItemSelected = selected.indexOf(fecha) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <TableCell align="left">
                              <Typography variant="subtitle2">
                                {folio ? folio : 0}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {formatNumber(valorPesos)}
                            </TableCell>
                            <TableCell align="left">
                              {fecha === ""
                                ? fecha
                                : moment(fecha, "YYYY/MM").format("DD/MM")}
                            </TableCell>
                            <TableCell align="left">
                              {fechaFacturacion === ""
                                ? fechaFacturacion
                                : moment(fechaFacturacion, "YYYY/MM/DD").format(
                                  "DD/MM/YYYY"
                                )}
                            </TableCell>
                            <TableCell align="left">
                              {fechaCorreo === ""
                                ? fechaCorreo
                                : moment(fechaCorreo, "YYYY/MM/DD").format(
                                  "DD/MM/YYYY"
                                )}
                            </TableCell>
                            <TableCell align="left">
                              {empleadoEnviaCorreo}
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (estadoCorreo === "ENVIADO" && "success") ||
                                  "error"
                                }>
                                {sentenceCase(estadoCorreo)}
                              </Label>
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  estadoFactura === "FACTURADO"
                                    ? "success"
                                    : estadoFactura === "PAGADO"
                                      ? "info"
                                      : "error"
                                }>
                                {sentenceCase(estadoFactura)}
                              </Label>
                            </TableCell>
                            <TableCell align="right">
                              <Stack>
                                {estadoFactura === "PAGADO" ||
                                  estadoFactura === "ANULADO" ? (
                                  <></>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      // window.open(getAbsolutePath() + "pago/" + id + "_0");
                                      window.location.href =
                                        getAbsolutePath() + "pago/" + id + "_0";
                                    }}>
                                    Pagar
                                  </Button>
                                )}

                                <LoadingButton
                                  color="primary"
                                  loading={loadingDescargar}
                                  onClick={() => {
                                    setLoadingDescargar(true);
                                    axios
                                      .get(
                                        URL_ORIGEN.includes(
                                          "http://localhost:3000"
                                        )
                                          ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${row.token}/pdf`
                                          : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${row.token}/pdf`,
                                        {
                                          headers: {
                                            apikey: URL_ORIGEN.includes(
                                              "http://localhost:3000"
                                            )
                                              ? API_KEY_OPEN_FACTURA_DEV
                                              : API_KEY_OPEN_FACTURA_PROD,
                                          },
                                        }
                                      )
                                      .then((response) => {
                                        saveAs(
                                          "data:application/pdf;base64," +
                                          response.data.pdf,
                                          row.folio + ".pdf"
                                        );
                                        setLoadingDescargar(false);
                                      })
                                      .catch(() => {
                                        setLoadingDescargar(false);
                                      });
                                  }}>
                                  <DownloadIcon
                                    style={{ marginRight: "0.5rem" }}
                                  />
                                </LoadingButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound
                            searchFilter={true}
                            searchQuery={filterName}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={facturas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      )}
    </>
  );
}
