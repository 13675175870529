import React, { useState, useEffect, useRef } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { API_SERVER } from "../utils/urlBack_End";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import syncFill from "@iconify/icons-eva/sync-outline";
import clipboardFill from "@iconify/icons-eva/clipboard-fill";

// material
import {
  Button,
  Card,
  Container,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import ModalEstado from "src/components/planes/ModalEstado";
import ModalContrato from "src/components/planes/ModalContrato";

import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import axios from "axios";
import ExportToExcel from "../components/ExportToExcel";
import PlanesAsociadosCliente from "src/components/verplan/PlanesAsociadosCliente";
import ModalPlanMasivo from "src/components/planes/ModalPlanMasivo";
import SeleccionAgregarPlanMasivo from "src/components/planes/SeleccionAgregarPlanMasivo";
import PlanMasivoConExcel from "src/components/planes/PlanMasivoConExcel";
import { Alerta } from "src/components/Alerta";
import ProcesoFacturacionDialog from "src/components/planes/ProcesoFacturacionDialog";
import MigrarPlanesDialog from "src/components/planes/MigrarPlanesDialog";
import { useSelector } from "react-redux";
import moment from "moment";
import { facturasApi } from "src/services/facturasApi";
import { AlertaModal } from "src/components/AlertaSwall";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "empleado", label: "Empleado", alignRight: false },
  { id: "fechaCreacion", label: "Fecha Creación", alignRight: false },
  { id: "contratoFirmado", label: "Contrato Firmado", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  { id: "planes", label: "Planes Asociados", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaCreacion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.contratoFirmado.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.nombreCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.nombreEmpleado.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.apellidoEmpleado.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Planes(props) {
  const { value } = props;
  const navigate = useNavigate();
  const [openCambiarEstado, setOpenCambiarEstado] = useState(false);
  const [openCambiarEstadoContrato, setOpenCambiarEstadoContrato] =
    useState(false);
  const [openPlanesAsociadosCliente, setOpenPlanesAsociadosCliente] =
    useState(false);
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);
  const sucursal = sucursalObtenida.refSucursal;
  const [locale] = useState("esES");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [loadingTable, setLoadingTable] = useState(true);

  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [openPlanes, setOpenPlanes] = useState(false);
  const [openPlanMasivo, setOpenPlanMasivo] = useState(false);
  const [openMigrarPlanes, setOpenMigrarPlanes] = useState(false);
  const [openSubirExcel, setOpenSubirExcel] = useState(false);
  const [alerta, setAlerta] = useState(false);
  const [mostrarFacturar, setMostrarFacturar] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");
  const [empleados, setEmpleados] = useState([]);

  const [listadoFacturacionFinal, setListadoFacturacionFinal] = useState([]);
  const [mostrarListadoFacturacionFinal, setMostrarListadoFacturacionFinal] =
    useState(false);

  const { data: periodoFacturacion, isLoading: cargandoPeriodoFactura } =
    facturasApi.useGetPeriodoFacturacionQuery(sucursal);

  const [estadoSeleccionado, setEstadoSeleccionado] = useState("");
  const [idPlanSeleccionado, setIdPlanSeleccionado] = useState(0);
  const [estadoContratoSeleccionado, setEstadoContratoSeleccionado] =
    useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [planes, setPlanes] = useState([]);

  const [dataExcel, setDataExcel] = useState([]);
  const isMounted = useRef(true);
  const fechaActual = new Date().getDate();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/empleadoSucursal/${sucursal}/ACTIVOS`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setEmpleados(response.data);
      });
  }, [sucursal]);

  useEffect(() => {
    if (rolObtenido === "SUPER_ADMIN" || rolObtenido === "ADMIN") {
      axios
        .get(`${API_SERVER}/planes/sucursal/${sucursal}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          let resultado = response.data;
          if (isMounted.current) {
            setPlanes(resultado);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setLoadingTable(false);
          }
        });
    } else {
      axios
        .get(`${API_SERVER}/planes/empleado/${sucursal}/${user.rut}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          let resultado = response.data;
          axios
            .get(
              `${API_SERVER}/empleados/empleadoPlan/${user.rut}/${sucursal}`,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              if (res.data === -2) window.location.reload();

              if (res.data && Array.isArray(res.data)) {
                res.data.forEach((t) => {
                  resultado.push(t);
                });
              }
              if (isMounted.current) {
                setPlanes(resultado);
              }
            })
            .catch(() => {
              if (isMounted.current) {
                setPlanes(resultado);
              }
            });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoadingTable(false);
          }
        });
    }
  }, [sucursal, rolObtenido, user.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/planes/excel`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data.length > 0) {
          if (isMounted.current) {
            if (rolObtenido === "EMPLEADO" || rolObtenido === "SUPERVISOR") {
              setDataExcel([
                response.data.filter(
                  (data) =>
                    data.refEmpleado === user.rut && data.sucursal === sucursal
                ),
              ]);
            } else {
              setDataExcel([
                response.data.filter((data) => data.sucursal === sucursal),
              ]);
            }
          }
        }
      });
  }, [rolObtenido, user.rut, sucursal]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = planes.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - planes.length) : 0;

  const filteredUsers = applySortFilter(
    planes,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleClickOpenCambiarEstado = (nuevoEstado, idPlan) => {
    setEstadoSeleccionado(nuevoEstado);
    setIdPlanSeleccionado(idPlan);
    setOpenCambiarEstado(true);
  };

  const handleClickOpenCambiarEstadoContrato = (nuevoEstado, idPlan) => {
    setEstadoContratoSeleccionado(nuevoEstado);
    setIdPlanSeleccionado(idPlan);
    setOpenCambiarEstadoContrato(true);
  };

  return (
    <Page title="TAXTIC" style={{ marginTop: 30 }}>
      {value === 0 && (
        <Container>
          <Card>
            <Grid container>
              <Grid item xs={8} md={8}>
                <TablaToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <Grid
                  container
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    marginTop: 30,
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <ExportToExcel
                      data={dataExcel}
                      filename={"Planes"}
                      sheetName="Planes"
                      type="array"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="center"
              style={{ marginBottom: "10px" }}
            >
              <Grid item xs={4} md={4}>
                {(rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN") && (
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenPlanMasivo(true);
                      }}
                      variant="contained"
                      startIcon={<Icon icon={plusFill} />}
                    >
                      Agregar Plan Masivo
                    </Button>
                  </Container>
                )}
              </Grid>
              <Grid item xs={4} md={4}>
                {(rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN") && (
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenMigrarPlanes(true);
                      }}
                      variant="contained"
                      startIcon={<Icon icon={syncFill} />}
                    >
                      Migrar Planes
                    </Button>
                  </Container>
                )}
              </Grid>

              <Grid item xs={4} md={4}>
                {(rolObtenido === "ADMIN" || rolObtenido === "SUPER_ADMIN") && (
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      disabled={
                        Number(fechaActual) > 12
                          ? true
                          : !cargandoPeriodoFactura
                          ? periodoFacturacion
                            ? periodoFacturacion.estado !== "FINALIZACION"
                              ? false
                              : true
                            : false
                          : false
                      }
                      onClick={() => {
                        AlertaModal({
                          title:
                            "¡ATENCIÓN! Esta acción es irreversible. Solo se facturarán los planes activos con un valor mayor a 0. ¿Desea continuar?",
                          fullText: true,
                          confirmButtonText: "Sí, continuar ->",
                        })
                          .fire()
                          .then((result) => {
                            if (result.isConfirmed) {
                              setMostrarFacturar(true);
                            }
                          });
                      }}
                      variant="contained"
                      startIcon={<Icon icon={clipboardFill} />}
                    >
                      Facturar
                    </Button>
                  </Container>
                )}
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {!loadingTable ? (
                  <Table>
                    <TablaHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={planes.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const {
                            id,
                            estado,
                            fechaCreacion,
                            contratoFirmado,
                            nombreCliente,
                            nombreEmpleado,
                            apellidoEmpleado,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(nombreCliente) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/dashboard/planes/" + row.id);
                                }}
                              >
                                <Typography variant="subtitle2">
                                  {nombreCliente}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/dashboard/planes/" + row.id);
                                }}
                                align="left"
                              >
                                {nombreEmpleado + " " + apellidoEmpleado}
                              </TableCell>
                              <TableCell
                                style={{
                                  cursor: "pointer",
                                }}
                                align="left"
                                onClick={() => {
                                  navigate("/dashboard/planes/" + row.id);
                                }}
                              >
                                {moment(fechaCreacion, "YYYY/MM/DD").format(
                                  "DD/MM/YYYY"
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  cursor: "pointer",
                                }}
                                align="left"
                                onClick={() => {
                                  navigate("/dashboard/planes/" + row.id);
                                }}
                              >
                                <Label
                                  onClick={() =>
                                    handleClickOpenCambiarEstadoContrato(
                                      contratoFirmado,
                                      row.id
                                    )
                                  }
                                  variant="ghost"
                                  color={
                                    (contratoFirmado === "NO" && "error") ||
                                    "success"
                                  }
                                >
                                  {sentenceCase(contratoFirmado)}
                                </Label>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/dashboard/planes/" + row.id);
                                }}
                              >
                                <Label
                                  onClick={() =>
                                    handleClickOpenCambiarEstado(estado, row.id)
                                  }
                                  variant="ghost"
                                  color={
                                    (estado === "INACTIVO" && "error") ||
                                    "success"
                                  }
                                >
                                  {sentenceCase(estado)}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  onClick={() => {
                                    setPlanSeleccionado(row.id);
                                    setOpenPlanesAsociadosCliente(true);
                                  }}
                                >
                                  Planes
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6}>
                            No hay Planes que mostrar.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                ) : (
                  <Skeleton variant="rectangular" width="100%" height="500px" />
                )}
              </TableContainer>
            </Scrollbar>

            <ThemeProvider
              theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={planes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          </Card>
        </Container>
      )}
      {openCambiarEstado && (
        <ModalEstado
          openDialog={openCambiarEstado}
          setOpenDialog={setOpenCambiarEstado}
          estadoSeleccionado={estadoSeleccionado}
          setPlanes={setPlanes}
          idPlanSeleccionado={idPlanSeleccionado}
        />
      )}

      {openCambiarEstadoContrato && (
        <ModalContrato
          openDialog={openCambiarEstadoContrato}
          setOpenDialog={setOpenCambiarEstadoContrato}
          estadoContratoSeleccionado={estadoContratoSeleccionado}
          setPlanes={setPlanes}
          idPlanSeleccionado={idPlanSeleccionado}
        />
      )}

      {openPlanesAsociadosCliente && (
        <PlanesAsociadosCliente
          open={openPlanesAsociadosCliente}
          setOpen={setOpenPlanesAsociadosCliente}
          id={planSeleccionado}
        />
      )}

      {openPlanMasivo && (
        <SeleccionAgregarPlanMasivo
          openPlanMasivo={openPlanMasivo}
          setOpenPlanMasivo={setOpenPlanMasivo}
          setOpenPlanes={setOpenPlanes}
          setOpenSubirExcel={setOpenSubirExcel}
          setColor={setColor}
          setMessage={setMessage}
          setAlerta={setAlerta}
        />
      )}
      {openSubirExcel && (
        <PlanMasivoConExcel
          openSubirExcel={openSubirExcel}
          setOpenSubirExcel={setOpenSubirExcel}
          setColor={setColor}
          setMessage={setMessage}
          setAlerta={setAlerta}
          setOpenPlanMasivo={setOpenPlanMasivo}
        />
      )}
      {openPlanes && (
        <ModalPlanMasivo
          agregarPlan={openPlanes}
          setAgregarPlan={setOpenPlanes}
          setOpenPlanMasivo={setOpenPlanMasivo}
          setPlanesOriginal={setPlanes}
          empleados={empleados}
          setColor={setColor}
          setMessage={setMessage}
          setAlerta={setAlerta}
        />
      )}
      {alerta && (
        <Alerta
          showAlert={alerta}
          setShowAlert={setAlerta}
          color={color}
          message={message}
        />
      )}

      {mostrarFacturar && (
        <ProcesoFacturacionDialog
          open={mostrarFacturar}
          setOpen={setMostrarFacturar}
          listadoFacturacionFinal={listadoFacturacionFinal}
          setListadoFacturacionFinal={setListadoFacturacionFinal}
          mostrarListadoFacturacionFinal={mostrarListadoFacturacionFinal}
          setMostrarListadoFacturacionFinal={setMostrarListadoFacturacionFinal}
        />
      )}
      {openMigrarPlanes && (
        <MigrarPlanesDialog
          open={openMigrarPlanes}
          setOpen={setOpenMigrarPlanes}
          planesEntrada={planes}
        />
      )}
    </Page>
  );
}
