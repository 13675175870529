import React, { useState } from "react";
import { AppBar, Tabs, Tab, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Vacaciones from "./Vacaciones";
import Permisos from "./Permisos";
import Contratos from "./Contratos";
import { Alerta } from "../../Alerta";
import Bitacora from "./Bitacora";

const styles = makeStyles((theme) => ({
  pos: {
    marginBottom: 15,
  },
}));

export default function Laboral(props) {
  const { value, empleado, vista } = props;
  const [showMessagePermiso, setShowMessagePermiso] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");
  const [valueLaboral, setValueLaboral] = useState(0);

  const classes = styles();

  const handleChange = (event, newValue) => {
    setValueLaboral(newValue);
  };

  return (
    <div className={classes.pos}>
      {value === 6 && (
        <Container>
          <div>
            <AppBar position="static" color="default">
              <Tabs
                value={valueLaboral}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
              >
                <Tab label="Vacaciones" />
                <Tab label="Contrato" />
                <Tab label="Bitácora" />
                <Tab label="Permisos" />
              </Tabs>

              <Vacaciones
                valueLaboral={valueLaboral}
                empleado={empleado}
                setShowMessagePermiso={setShowMessagePermiso}
                setColor={setColor}
                setMessage={setMessage}
                vista={vista}
              />

              <Contratos
                valueLaboral={valueLaboral}
                empleado={empleado}
                setShowMessagePermiso={setShowMessagePermiso}
                setColor={setColor}
                setMessage={setMessage}
                vista={vista}
              />

              <Bitacora
                valueLaboral={valueLaboral}
                empleado={empleado}
                setShowMessagePermiso={setShowMessagePermiso}
                setColor={setColor}
                setMessage={setMessage}
                vista={vista}
              />

              <Permisos
                valueLaboral={valueLaboral}
                empleado={empleado}
                setShowMessagePermiso={setShowMessagePermiso}
                setColor={setColor}
                setMessage={setMessage}
                vista={vista}
              />
            </AppBar>
          </div>

          {showMessagePermiso && (
            <Alerta
              showAlert={showMessagePermiso}
              setShowAlert={setShowMessagePermiso}
              color={color}
              message={message}
            />
          )}
        </Container>
      )}
    </div>
  );
}
