import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "src/utils/urlBack_End";

export const categoriasApi = createApi({
  reducerPath: "categoriasApi",
  baseQuery: baseQuery(`${API_SERVER}/categorias`),
  tagTypes: ["categysubcateg"],
  endpoints: (builder) => ({
    obtenerCategoriasYsubcategorias: builder.query({
      query: () => "/categysubcateg",
      providesTags: ["categysubcateg"],
    }),
    crearCategoria: builder.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["categysubcateg"],
    }),
    editarCategoria: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["categysubcateg"],
    }),
    eliminarCategoria: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categysubcateg"],
    }),
    crearSubcategoria: builder.mutation({
      query: (body) => ({
        url: "/subcategorias/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["categysubcateg"],
    }),
    editarSubcategoria: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/subcategorias/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["categysubcateg"],
    }),
    eliminarSubcategoria: builder.mutation({
      query: (id) => ({
        url: `/subcategorias/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categysubcateg"],
    }),
  }),
});

export const {
  useObtenerCategoriasYsubcategoriasQuery,
  useLazyObtenerCategoriasYsubcategoriasQuery,
  useCrearCategoriaMutation,
  useEditarCategoriaMutation,
  useCrearSubcategoriaMutation,
  useEditarSubcategoriaMutation,
  useEliminarCategoriaMutation,
  useEliminarSubcategoriaMutation,
} = categoriasApi;
