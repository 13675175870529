import { Box, Card, Divider, Grid, Skeleton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  exportButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  detailsSection: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
}));

const TarjetaContratoSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box className={classes.exportButton}>
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton
          variant="text"
          width={120}
          height={24}
          style={{ marginTop: 8 }}
        />
      </Box>
      <Divider />
      <Box className={classes.detailsSection}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={150} height={20} />
            <Skeleton variant="text" width={180} height={20} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={150} height={20} />
            <Skeleton variant="text" width={180} height={20} />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default TarjetaContratoSkeleton;
