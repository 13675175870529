import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowFill from "@iconify/icons-eva/arrow-back-fill";

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  Tooltip,
} from "@material-ui/core";

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from "../utils/urlBack_End";
import AgregarElementoBaseCliente from "src/components/basedatocliente/AgregarElementoBaseCliente";
import EditarElementoBaseCliente from "src/components/basedatocliente/EditarElementoBaseCliente";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

export default function BaseDatosCliente() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [elementoSeleccionado, setElementoSeleccionado] = useState(null);

  const [openEliminar, setOpenEliminar] = useState(false);

  const [columnasCliente, setColumnaCliente] = useState([]);
  const { empleado: user } = useSelector((state) => state.auth.user);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/tablaCliente`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setColumnaCliente(response.data);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseEliminar = () => {
    setOpenEliminar(false);
  };

  const eliminarColumna = () => {
    axios
      .delete(
        `${API_SERVER}/ajustes/relacionColumnaCliente/${elementoSeleccionado[0].nombreColumna}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        axios
          .get(`${API_SERVER}/clientes/tablaCliente`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            setElementoSeleccionado(null);
            setColumnaCliente(response.data);
            setOpenEliminar(false);
          });
      });
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}>
          <Button
            variant="contained"
            color="info"
            startIcon={<Icon icon={arrowFill} />}
            onClick={() => {
              navigate("/dashboard/configuraciones");
            }}>
            Volver
          </Button>
          <Typography variant="h4" gutterBottom>
            Base de Datos Cliente
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleClickOpen}>
            Agregar Columna
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre Columna</TableCell>
                    <TableCell align="right">Tipo Dato</TableCell>
                    <TableCell align="right">Tipo Componente</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {columnasCliente.map((row, index) => {
                    if (index < 12) {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.Field}
                          </TableCell>
                          <TableCell align="right">{row.Type}</TableCell>
                          <TableCell align="right"></TableCell>

                          <TableCell align="right"></TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.Field}
                          </TableCell>
                          <TableCell align="right">{row.Type}</TableCell>
                          <TableCell align="right"></TableCell>

                          <TableCell align="right">
                            <Tooltip title="Editar">
                              <Button
                                color="primary"
                                onClick={() => {
                                  axios
                                    .get(
                                      `${API_SERVER}/ajustes/relacionColumnaCliente/${row.Field}`,
                                      {
                                        headers: {
                                          Authorization:
                                            "Bearer " +
                                            localStorage.getItem("token"),
                                        },
                                      }
                                    )
                                    .then(function (response) {
                                      if (response.data === -2)
                                        window.location.reload();
                                      setElementoSeleccionado(response.data);
                                      setOpenEditar(true);
                                    });
                                }}>
                                <EditIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip title="Eliminar">
                              <Button
                                color="error"
                                onClick={() => {
                                  axios
                                    .get(
                                      `${API_SERVER}/ajustes/relacionColumnaCliente/${row.Field}`,
                                      {
                                        headers: {
                                          Authorization:
                                            "Bearer " +
                                            localStorage.getItem("token"),
                                        },
                                      }
                                    )
                                    .then(function (response) {
                                      if (response.data === -2)
                                        window.location.reload();
                                      setElementoSeleccionado(response.data);
                                      setOpenEliminar(true);
                                    });
                                }}>
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>

      {open && (
        <AgregarElementoBaseCliente
          setColumnaCliente={setColumnaCliente}
          open={open}
          setOpen={setOpen}
          columnasCliente={columnasCliente}
        />
      )}
      {openEditar && (
        <EditarElementoBaseCliente
          setColumnaCliente={setColumnaCliente}
          openEditar={openEditar}
          setOpenEditar={setOpenEditar}
          elemento={elementoSeleccionado}
        />
      )}

      <Dialog
        open={openEliminar}
        onClose={handleCloseEliminar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Alert severity="info">
            La columna que vas a eliminar es:{" "}
            <h3>
              {elementoSeleccionado !== null &&
                elementoSeleccionado[0].nombreColumna}
            </h3>
          </Alert>
          <Alert severity="error">
            ¿Seguro que quieres eliminar la columna de la base de datos?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCloseEliminar}>
            Cancelar
          </Button>
          <Button onClick={eliminarColumna} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
