import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import { LoadingButton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { uiCargandoSimpleModal } from "src/reducers/settingsReducers";
import { useDispatch } from "react-redux";

export default function SimpleModal(props) {
  const {
    children,
    maxWidth = "xs",
    onClose,
    onSubmit,
    open,
    showCancelButton = true,
    showAcceptButton = true,
    disabledAcceptButton = false,
    disabledCancelButton = false,
    cargandoDatos = false,
    title,
  } = props;

  const Submitting = () => {
    onSubmit();
  };

  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <Box mt={1} style={{ overflowY: "auto" }}>
        <DialogContent>{children}</DialogContent>
      </Box>

      <DialogActions>
        {showCancelButton && (
          <LoadingButton
            disabled={disabledCancelButton}
            onClick={() => onClose()}
            color="error"
            loading={cargandoDatos}
          >
            Cancelar
          </LoadingButton>
        )}
        {showAcceptButton && (
          <LoadingButton
            disabled={disabledAcceptButton}
            autoFocus
            color="primary"
            onClick={() => Submitting()}
            loading={cargandoDatos}
          >
            Aceptar
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
