import React, { useState, useEffect, useRef } from "react";
import { Card, Stack, TextField, Grid } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { AlertaToast } from "../AlertaSwall";
import { formatDate } from "../../utils/converter";
import axios from "axios";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@material-ui/core";

import SelectMenu from "../SelectMenu";
import { API_SERVER } from "../../utils/urlBack_End";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

// ----------------------------------------------------------------------

export default function DetallesTarea({
  tarea,
  detallesTarea,
  ESTADOS_TAREA,
  empleados,
}) {
  const [loading, setLoading] = useState(false);
  const { rol: rolObtenido } = useSelector((state) => state.auth.user);
  const [valorTarea, setValorTarea] = useState(tarea?.valorTarea);
  const [tipoMoneda, setTipoMoneda] = useState("");
  const [monedas, setMonedas] = useState([]);
  const [estadoTarea, setEstadoTarea] = useState("");
  const [errorValorTarea, setErrorValorTarea] = useState("");
  const [mostrarAlerta, setMostrarAlerta] = useState(null);
  const isMounted = useRef(true);

  const [actualizarTarea] = tareasApi.useUpdateTareaMutation();
  const [actualizarEstadoTarea] = tareasApi.useUpdateCambiaEstadoMutation();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const saveToDB = () => {
    setLoading(true);
    if (Number.isNaN(parseFloat(valorTarea)) !== true) {
      const update = {
        id: tarea.id,
        refEmpleado: detallesTarea.refEmpleado,
        estado: detallesTarea.estadoTarea,
        fechaTermino: detallesTarea.fechaTermino,
        comentario: detallesTarea.comentario,
        supervisor: detallesTarea.supervisor,
        duracionTarea: detallesTarea.duracionTarea,
        tipoDuracion: detallesTarea.tipoDuracionTarea,
        urlArchivo: detallesTarea.urlArchivo,
        valorTarea: valorTarea,
        tipoMoneda: tipoMoneda,
        tipoTarea: detallesTarea.tipoTarea,
      };

      if (update.estado === "TERMINADA") {
        const request = {
          id: update.id,
          body: {
            estado: "TERMINADA",
            fechaTermino: getToday(),
          },
        };
        actualizarEstadoTarea(request);
        setEstadoTarea("TERMINADA");
        //navigate("/dashboard/tareas", { replace: true });
      }

      actualizarTarea(update)
        .unwrap()
        .then(() => {
          AlertaToast({
            element: "Tarea",
            action: "actualizada",
          }).fire();
          setLoading(false);
        })
        .catch(() => {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
          }).fire();
          setLoading(false);
          setEstadoTarea(tarea.estado);
        });
    } else {
      setMostrarAlerta("Ha ocurrido un error");
      setErrorValorTarea("El valor debe ser un numero");
      setLoading(false);
    }
  };

  const getToday = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getUTCFullYear();
    return formatDate(`${year}/${month}/${day}`);
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tipos/tipoMoneda`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setMonedas(response.data);
          setValorTarea(tarea?.valorTarea);
          setTipoMoneda(tarea?.tipoMonedaTarea);
          if (estadoTarea === undefined || estadoTarea === "") {
            setEstadoTarea(detallesTarea?.estadoTarea);
          }
        }
      });
  }, [
    tarea?.tipoMonedaTarea,
    tarea?.valorTarea,
    detallesTarea?.estadoTarea,
    estadoTarea,
  ]);

  const handleChangValorTarea = (event) => {
    setValorTarea(event.target.value);
  };

  const handleChangeTipoMoneda = (event) => {
    setTipoMoneda(event.target.value);
  };

  return (
    <Container id="idContainerDetallesTarea">
      <RootStyle>
        <Card style={{ padding: "1rem" }}>
          <Grid container spacing={1}>
            <Grid mt={2} item mb={2} md={4} sm={6} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Nombre Cliente"
                value={`${detallesTarea.nombreCliente}`}
              />
            </Grid>

            <Grid item mt={2} md={4} sm={6} xs={12}>
              {detallesTarea.nombreEmpleado && (
                <Autocomplete
                  value={detallesTarea.empleado}
                  disablePortal
                  disabled={
                    estadoTarea === "TERMINADA" ||
                    estadoTarea === "PENDIENTE DE FACTURACION" ||
                    estadoTarea === "FACTURADO"
                      ? true
                      : rolObtenido === "EMPLEADO"
                      ? true
                      : false
                  }
                  options={empleados}
                  isOptionEqualToValue={(option) => option.refEmpleado}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      detallesTarea.setNombreEmpleado(newValue.nombre);
                      detallesTarea.setRefEmpleado(newValue.refEmpleado);
                      detallesTarea.setEmpleado(newValue);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Empleado" />
                  )}
                />
              )}
            </Grid>

            <Grid mt={2} item mb={2} md={4} sm={6} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Vendedor"
                value={`${detallesTarea.vendedor}` || "SIN VENDEDOR"}
              />
            </Grid>

            <Grid mt={2} item mb={2} md={4} sm={6} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Fecha Inicio"
                value={`${detallesTarea.fechaInicio}`}
              />
            </Grid>

            <Grid mt={2} item mb={2} md={4} sm={6} xs={12}>
              <Grid container spacing={2} direccion="row">
                <Grid item xs={tarea.tipoTarea === "ESPECIAL" ? 6 : 12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Fecha límite"
                    value={`${
                      detallesTarea.fechaLimite !== null
                        ? detallesTarea.fechaLimite
                        : ""
                    }`}
                  />
                </Grid>
                {tarea.tipoTarea === "ESPECIAL" && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      label="Fecha facturación"
                      value={`${
                        detallesTarea.fechaTermino !== null
                          ? detallesTarea.fechaTermino
                          : ""
                      }`}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid mt={2} item mb={2} md={4} sm={6} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Tipo Tarea"
                value={`${detallesTarea.tipoTarea}`}
              />
            </Grid>

            <Grid mt={2} item mb={2} md={4} sm={6} xs={12}>
              {estadoTarea === "TERMINADA" ||
              estadoTarea === "FACTURADO" ||
              estadoTarea === "PENDIENTE DE FACTURACION" ||
              detallesTarea.tipoTarea !== "ESPECIAL" ? (
                <TextField
                  disabled
                  fullWidth
                  label="Valor Tarea"
                  value={`${detallesTarea.valorTarea} ${detallesTarea.tipoMonedaTarea}`}
                />
              ) : (
                <Grid container spacing={2} direccion="row">
                  {monedas.length > 0 && (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label="Valor Tarea"
                          value={valorTarea}
                          onChange={handleChangValorTarea}
                          type="number"
                          error={Boolean(errorValorTarea)}
                          helperText={errorValorTarea}
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Tipo Moneda
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tipoMoneda}
                            onChange={handleChangeTipoMoneda}
                          >
                            <MenuItem value="">
                              <em>Ninguno</em>
                            </MenuItem>
                            {monedas?.map((element, index) => {
                              return (
                                <MenuItem key={index} value={element.nombre}>
                                  {element.nombre}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid item mb={2} md={4} sm={6} xs={12}>
              {estadoTarea && (
                <SelectMenu
                  label="Estado"
                  disabled={
                    estadoTarea === "TERMINADA" ||
                    estadoTarea === "PENDIENTE DE FACTURACION" ||
                    estadoTarea === "FACTURADO"
                      ? true
                      : false
                  }
                  options={ESTADOS_TAREA}
                  value={detallesTarea.estadoTarea}
                  onChange={(v) => {
                    detallesTarea.setEstadoTarea(v);
                    if (v === "TERMINADA") {
                      return detallesTarea.setFechaTermino(getToday());
                    }
                    return detallesTarea.setFechaTermino("");
                  }}
                />
              )}
            </Grid>

            <Grid item mt={2} md={4} sm={6} xs={12}>
              <Autocomplete
                value={
                  detallesTarea.supervisor
                    ? empleados.find(
                        (em) => em.refEmpleado === detallesTarea.supervisor
                      )
                    : { refEmpleado: "", nombre: "" }
                }
                disablePortal
                disabled={
                  estadoTarea === "TERMINADA" ||
                  estadoTarea === "PENDIENTE DE FACTURACION" ||
                  estadoTarea === "FACTURADO"
                    ? true
                    : rolObtenido !== "ADMIN" && rolObtenido !== "SUPER_ADMIN"
                }
                options={empleados}
                isOptionEqualToValue={(option) => option.refEmpleado}
                getOptionLabel={(option) => option.nombre}
                onChange={(event, newValue) => {
                  if (newValue) {
                    detallesTarea.setSupervisor(newValue.refEmpleado);
                  } else {
                    detallesTarea.setSupervisor("");
                  }
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Supervisor" />
                )}
              />
            </Grid>
          </Grid>

          {/*
            <Grid container spacing={3}>
            <Grid mt={2} item mb={2} sm={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                label="Duración"
                disabled={
                  detallesTarea.estadoTarea === "TERMINADA" ? true : false
                }
                value={`${detallesTarea.duracionTarea}`}
                onChange={(e) => detallesTarea.setDuracionTarea(e.target.value)}
              />
            </Grid>

            <Grid item mb={2} sm={6} xs={12}>
              <SelectMenu
                disabled={
                  detallesTarea.estadoTarea === "TERMINADA" ? true : false
                }
                label="Medida del Tiempo"
                options={detallesTarea.TIPOS_DURACION}
                value={detallesTarea.tipoDuracionTarea || "HORAS"}
                onChange={(v) => detallesTarea.setTipoDuracionTarea(v)}
              />
            </Grid>
          </Grid>
          */}

          <Grid container>
            <Grid item mt={2} xs={12}>
              <TextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                label="Comentario"
                value={`${detallesTarea.comentario}`}
                onChange={(e) => detallesTarea.setComentario(e.target.value)}
              />
            </Grid>
          </Grid>
        </Card>

        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <LoadingButton
            loading={loading}
            onClick={saveToDB}
            variant="contained"
          >
            Guardar Detalles
          </LoadingButton>
        </Stack>

        {mostrarAlerta && (
          <Snackbar
            onClose={() => {
              setMostrarAlerta(false);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={mostrarAlerta}
            autoHideDuration={6000}
          >
            <Alert
              onClose={() => {
                setMostrarAlerta(false);
              }}
              severity="info"
              sx={{ width: "100%" }}
            >
              {mostrarAlerta}
            </Alert>
          </Snackbar>
        )}
      </RootStyle>
    </Container>
  );
}
