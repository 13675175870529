import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { empleadosApi } from "src/services/empleadosApi";
import { AlertaToast } from "../AlertaSwall";
import { sucursalesApi } from "src/services/sucursalesApi";
import { ajustesApi } from "src/services/ajustesApi";

export default function SucursalesEmpleado(props) {
  const { open, setOpen, empleadoDato } = props;
  const empleado = empleadoDato;
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");
  const [rolSeleccionado, setRolSeleccionado] = useState("");

  const [agregarRelacionSucursalEmpleado] = empleadosApi.useAddRelacionEmpleadoSucursalMutation();
  const [editarRelacionSucursalEmpleado] = empleadosApi.useUpdateRelacionEmpleadoSucursalMutation();
  const [eliminarRelacionSucursalEmpleado] = empleadosApi.useDeleteRelacionEmpleadoSucursalMutation();

  const {
    data: sucursalesEmpleados = [],
    isLoading: isLoadingSucursalesEmpleados,
    refetch: refetchSucursalesEmpleados
  } = sucursalesApi.useGetSucursalesEmpleadosQuery(empleado.rut)

  const {
    data: roles = []
  } = ajustesApi.useGetRolesQuery();

  const {
    data: sucursales = []
  } = sucursalesApi.useGetSucursalesQuery()

  /**
   * Se encarga de cerrar el modal de agregar empleado
   */
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSucursalSeleccionada = (event) => {
    setSucursalSeleccionada(event.target.value);
  };

  const handleChangeRolSeleccionado = (event) => {
    setRolSeleccionado(event.target.value);
  };

  const guardarDatos = () => {
    let existe = false;

    sucursalesEmpleados.forEach((element) => {
      if (element.refSucursal === sucursalSeleccionada) {
        existe = true;
      }
    });

    if (existe) {
      editarRelacionSucursalEmpleado({
        refEmpleado: empleado.rut,
        refSucursal: sucursalSeleccionada,
        refRol: rolSeleccionado
      })
        .unwrap()
        .then(() => {
          refetchSucursalesEmpleados();
          setSucursalSeleccionada("");
          setRolSeleccionado("");
          AlertaToast({
            element: 'Relación',
            action: 'actualizada',
            target: document.getElementById('dialogRelacionEmpleadoSucursal'),
          }).fire()
        }).catch(() => {
          AlertaToast({
            action: 'El empleado debe tener rol y estado',
            fullText: true,
            type: 'error',
            target: document.getElementById('dialogRelacionEmpleadoSucursal'),
          }).fire()
        })
    } else {
      agregarRelacionSucursalEmpleado({
        refEmpleado: empleado.rut,
        refSucursal: sucursalSeleccionada,
        refRol: rolSeleccionado,
      })
        .unwrap()
        .then(() => {
          refetchSucursalesEmpleados();
          setSucursalSeleccionada("");
          setRolSeleccionado("");
          AlertaToast({
            element: 'Relación',
            action: 'agregada',
            target: document.getElementById('dialogRelacionEmpleadoSucursal'),
          }).fire()
        }).catch(() => {
          AlertaToast({
            action: 'El empleado debe tener rol y estado',
            fullText: true,
            type: 'error',
            target: document.getElementById('dialogRelacionEmpleadoSucursal'),
          }).fire()
        })
    }
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
          id="dialogRelacionEmpleadoSucursal"
        >
          <DialogTitle id="form-dialog-title">
            Modificar Sucursales Empleado
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={5} md={5}>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Seleccionar Sucursal</InputLabel>
                  <Select
                    value={sucursalSeleccionada}
                    label="Seleccionar Sucursal"
                    onChange={handleChangeSucursalSeleccionada}>
                    {sucursales.map((element) => {
                      return (
                        <MenuItem value={element.nombre} key={element.nombre}>
                          {element.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={5}>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Seleccionar Rol</InputLabel>
                  <Select
                    value={rolSeleccionado}
                    label="Seleccionar Rol"
                    onChange={handleChangeRolSeleccionado}>
                    {roles.filter(e => e.nombre !== "CLIENTE").map((element) => {
                      return (
                        <MenuItem value={element.nombre} key={element.nombre}>
                          {element.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={guardarDatos}
                  style={{ marginLeft: "10px", marginTop: "20%" }}>
                  Agregar
                </Button>
              </Grid>
            </Grid>
            <Stack justifyContent="space-between"></Stack>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sucursal</TableCell>
                    <TableCell align="center">Rol</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoadingSucursalesEmpleados &&
                    sucursalesEmpleados.map((element, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {element.refSucursal}
                          </TableCell>
                          <TableCell align="center">{element.refRol}</TableCell>
                          <TableCell align="center">
                            <Button
                              color="error"
                              disabled={
                                sucursalesEmpleados &&
                                sucursalesEmpleados.length <= 1 &&
                                true
                              }
                              onClick={() => {

                                eliminarRelacionSucursalEmpleado({
                                  rut: empleado.rut,
                                  sucursal: element.refSucursal,
                                  rol: element.refRol,
                                })
                                  .unwrap()
                                  .then(() => {
                                    refetchSucursalesEmpleados();
                                    setSucursalSeleccionada("");
                                    setRolSeleccionado("");
                                    AlertaToast({
                                      element: 'Relación',
                                      action: 'eliminada',
                                      target: document.getElementById('dialogRelacionEmpleadoSucursal'),
                                    }).fire()
                                  }).catch(() => {
                                    AlertaToast({
                                      action: 'Error al eliminar la relación',
                                      fullText: true,
                                      type: 'error',
                                      target: document.getElementById('dialogRelacionEmpleadoSucursal'),
                                    }).fire()
                                  })
                              }}>
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <LoadingButton onClick={handleClose} color="primary">
              Cerrar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
