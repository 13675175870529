import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  createTheme,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import * as locales from "@material-ui/core/locale";
import CustomTablePagination from "src/components/CustomTablePagination";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { useMemo, useState } from "react";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import dayjs from "dayjs";
import "dayjs/locale/es.js";
import { formatAsCLP } from "src/utils/formatNumber";

const ModalDetalleFacturas = ({
  isOpen,
  setOpen,
  title,
  rows,
  cargandoDatos,
  tipo,
}) => {
  const [statesTable, setStateTable] = useState(INITIAL_STATE_TABLE);
  const tableHead = TABLE_HEADS[tipo];

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterByName = (event) => {
    const filterName = event.target.value;
    const newTotalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );

    let newCurrentPage = statesTable.currentPage;
    if (newTotalPages < statesTable.currentPage) {
      newCurrentPage = newTotalPages - 1;
    }

    setStateTable({
      ...statesTable,
      filterName,
      currentPage: newCurrentPage,
    });
  };

  const rowsFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "rut",
      "folio",
      "nombre",
      "sucursal",
      "estadoFactura",
    ];
    return applySortFilter(
      rows,
      getComparator(statesTable.order, statesTable.orderBy),
      statesTable.filterName,
      propiedadesParaFiltrar
    );
  }, [rows, statesTable.filterName, statesTable.order, statesTable.orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      statesTable.orderBy === property && statesTable.order === "asc";
    setStateTable({
      ...statesTable,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(
      rowsFiltradas.length / statesTable.rowsPerPageOptions
    );
    const currentPage = Math.min(newPage, totalPages - 1);
    setStateTable({ ...statesTable, currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = Number(event.target.value);
    const newTotalPages = Math.ceil(rowsFiltradas.length / newRowsPerPage);
    const currentPage =
      statesTable.currentPage < newTotalPages
        ? statesTable.currentPage
        : newTotalPages - 1;
    setStateTable({
      ...statesTable,
      rowsPerPageOptions: newRowsPerPage,
      currentPage,
    });
  };

  const seEncontroElemento = rowsFiltradas.length === 0;

  const filasVacias =
    statesTable.currentPage > 0
      ? Math.min(
          0,
          (1 + statesTable.currentPage) * statesTable.rowsPerPageOptions -
            rows.length
        )
      : 0;

  const renderTableCellContent = (column, row, index) => {
    switch (column.id) {
      case "position":
        return (
          statesTable.currentPage * statesTable.rowsPerPageOptions + index + 1
        );
      //   case "actions":
      //     return (
      //       <Grid item xs={12} md={6} lg={6}>
      //         <Tooltip title="Ver tarea">
      //           <IconButton
      //             color="primary"
      //             // onClick={() => handleClickWatchTask(row.id)}
      //             aria-label="watch-task"
      //             size="small"
      //           >
      //             <Visibility />
      //           </IconButton>
      //         </Tooltip>
      //       </Grid>
      //     );
      case "estadoFactura":
      case "sucursal":
      case "nombre":
        return row[column.id] && capitalizarPalabras(row[column.id]);
      case "rut":
        return formatRut(row[column.id], RutFormat.DOTS_DASH);
      case "fechaFacturacion":
        return dayjs(row[column.id]).format("DD/MM/YYYY");
      case "precioFactura":
      case "montoRestante":
      case "montoPagado":
        return formatAsCLP(row[column.id]);
      default:
        return row[column.id];
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-detalle-estadisticas-facturacion"
      id="dialog-detalle-estadisticas-facturacion"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle style={styles.title} htmlFor="dialog-gav">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}></Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <TablaToolbar
              filterName={statesTable.filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer component={Paper}>
            {!cargandoDatos ? (
              <Table>
                <TablaHead
                  order={statesTable.order}
                  orderBy={statesTable.orderBy}
                  headLabel={tableHead}
                  rowCount={rows.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rowsFiltradas
                    .slice(
                      statesTable.currentPage * statesTable.rowsPerPageOptions,
                      statesTable.currentPage * statesTable.rowsPerPageOptions +
                        statesTable.rowsPerPageOptions
                    )
                    .map((row, index) => (
                      <TableRow hover key={row.folio}>
                        {tableHead.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.alignRight ? "right" : "left"}
                          >
                            {renderTableCellContent(column, row, index)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}

                  {filasVacias > 0 && (
                    <TableRow style={{ height: 53 * filasVacias }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
                {seEncontroElemento && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={statesTable.filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <ThemeProvider
                      theme={(outerTheme) =>
                        createTheme(outerTheme, locales[statesTable.locale])
                      }
                    >
                      <CustomTablePagination
                        count={rowsFiltradas.length}
                        rowsPerPage={statesTable.rowsPerPageOptions}
                        // page={statesTable.currentPage}
                        page={
                          !rowsFiltradas.length || rowsFiltradas.length <= 0
                            ? 0
                            : statesTable.currentPage
                        }
                        colSpan={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </ThemeProvider>
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "fechaFacturacion",
  locale: "esES",
  filterName: "",
};

const COLUMNAS_EN_COMUN = [
  { id: "position", label: "N°", alignRight: false },
  { id: "folio", label: "Folio", alignRight: false },
  { id: "sucursal", label: "Sucursal", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha facturación", alignRight: false },
  { id: "estadoFactura", label: "Estado", alignRight: false },
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "precioFactura", label: "Precio factura", alignRight: false },
];

const COLUMNAS_RECAUDACIONES = [
  ...COLUMNAS_EN_COMUN,
  { id: "montoPagado", label: "Monto pagado", alignRight: false },
  { id: "montoRestante", label: "Monto Restante", alignRight: false },
];

const COLUMNAS_RECAUDACIONES_TOTAL = [
  ...COLUMNAS_RECAUDACIONES,
  { id: "tipo", label: "Tipo factura", alignRight: false },
];

const COLUMNAS_PENDIENTES = [...COLUMNAS_EN_COMUN];

const TABLE_HEADS = {
  recaudado: COLUMNAS_RECAUDACIONES,
  recaudadoFull: COLUMNAS_RECAUDACIONES_TOTAL,
  pendiente: COLUMNAS_PENDIENTES,
};

ModalDetalleFacturas.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  rows: PropTypes.array,
  cargandoDatos: PropTypes.bool,
  tipo: PropTypes.string,
};

export default ModalDetalleFacturas;
