import React from "react";
import { memo, useMemo } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import {
  FileUploadOutlined,
  DescriptionOutlined,
  CommentOutlined,
} from "@material-ui/icons";

const TextFieldComponent = memo(
  ({
    campo,
    label,
    touched = false,
    errors = "",
    getFieldProps = {},
    inputAdornment,
    fileInputProps,
    value,
  }) => {
    const inputProps = useMemo(() => {
      if (inputAdornment === "description") {
        return {
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionOutlined />
            </InputAdornment>
          ),
        };
      } else if (inputAdornment === "dollar") {
        return {
          startAdornment: (
            <InputAdornment position="start">{"$"}</InputAdornment>
          ),
        };
      } else if (inputAdornment === "file") {
        return {
          endAdornment: (
            <IconButton component="label" {...fileInputProps}>
              <FileUploadOutlined />
              <input
                style={{ display: "none" }}
                type="file"
                accept=".pdf, image/*, application/msword"
                hidden
                onChange={fileInputProps.onChange}
                name={fileInputProps.name}
              />
            </IconButton>
          ),
        };
      } else if (inputAdornment === "comment") {
        return {
          startAdornment: (
            <InputAdornment position="start">
              <CommentOutlined />
            </InputAdornment>
          ),
        };
      } else if (inputAdornment === "percent") {
        return {
          startAdornment: (
            <InputAdornment position="start">{"%"}</InputAdornment>
          ),
        };
      }
      return {};
    }, [inputAdornment, fileInputProps]);

    const textField = useMemo(
      () => (
        <TextField
          fullWidth
          id={campo}
          label={label}
          value={
            inputAdornment === "file"
              ? value.name
                ? value.name
                : value
              : value || ""
          }
          variant="outlined"
          {...getFieldProps}
          helperText={touched && errors}
          error={touched && Boolean(errors)}
          InputProps={inputProps}
        />
      ),
      [campo, errors, getFieldProps, inputAdornment, inputProps, label, touched, value]
    );
    return textField;
  }
);

export default TextFieldComponent;
