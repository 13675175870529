import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Visibility,
  Leaderboard,
} from "@material-ui/icons";
import { useCallback, useMemo, useState } from "react";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import SearchNotFound from "../SearchNotFound";
import * as locales from "@material-ui/core/locale";
import { fToNow } from "src/utils/formatTime";
import { WarningAmber, AccessTime, DoneAll } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../Scrollbar";
import CustomTablePagination from "../CustomTablePagination";
import ModalDetalleFacturaTareas from "../Modal/modalDetalleFacturaTareas";
import { informesApi } from "src/services/informesApi";

export default function RowAppEmployee({ row }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(INITIAL_STATE_TABLE);
  const [abrirModalEstadisticasTarea, setAbrirModalEstadisticasTarea] =
    useState(false);
  const [
    obtenerDetalleComisiones,
    {
      isFetching: cargandoDatosComisionesFactura,
      data: datosDetalleComisiones = {},
      error: errorDetalleComisiones,
    },
  ] = informesApi.useLazyGetDetalleComisionesTareaQuery();
  const [
    obtenerDetalleFacturasTarea,
    {
      isFetching: cargandoDetalleFacturasTarea,
      data: datosDetalleFacturas = {},
    },
  ] = informesApi.useLazyGetDetalleFacturasTareaQuery();

  const [nombreTarea, setNombreTarea] = useState("");
  const navigate = useNavigate();

  const handleFilterByName = (event) => {
    setState({ ...state, filterName: event.target.value });
  };

  const filasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = [
      "task",
      "nameClient",
      "branch",
      "taskType",
      "state",
    ];
    return applySortFilter(
      row.history,
      getComparator(state.order, state.orderBy),
      state.filterName,
      propiedadesParaFiltrar
    );
  }, [row.history, state.filterName, state.order, state.orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const emptyRows =
    state.currentPage > 0
      ? Math.max(
          0,
          (1 + state.currentPage) * state.rowsPerPageOptions -
            row.history.length
        )
      : 0;

  const esSucursalNoEncontrada = filasFiltradas.length === 0;

  const handleClickWatchTask = (id) => {
    navigate("/dashboard/tareas/" + id);
  };

  const manejarModalEstadisticasTarea = useCallback(
    (idTarea, nombreTarea) => {
      setNombreTarea(nombreTarea);
      obtenerDetalleComisiones(idTarea);
      obtenerDetalleFacturasTarea(idTarea);
      setAbrirModalEstadisticasTarea(true);
    },
    [obtenerDetalleComisiones, obtenerDetalleFacturasTarea]
  );

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nameEmployee}
        </TableCell>
        <TableCell>{row.task}</TableCell>
        <TableCell>{row.nameClient}</TableCell>
        <TableCell>{row.branch}</TableCell>
        <TableCell align="right">{row.taskType}</TableCell>
        <TableCell align="right">
          {row.state === "En Proceso" ? (
            <Chip
              color="secondary"
              label={row.state}
              size="small"
              icon={<AccessTime />}
            />
          ) : row.state === "Terminada" || row.state === "Facturado" ? (
            <Chip
              color="success"
              label={row.state}
              size="small"
              icon={<DoneAll />}
            />
          ) : (
            <Chip
              color="warning"
              label={row.state}
              size="small"
              icon={<WarningAmber />}
            />
          )}
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" noWrap>
            {fToNow(row.createdAt)}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container justifyContent="right" spacing={2} paddingRight={1}>
            <Grid
              item
              xs={12}
              md={row.taskType === "Especial" ? 6 : 12}
              lg={row.taskType === "Especial" ? 6 : 12}
            >
              {row.taskType === "Especial" && (
                <Tooltip title="Estadísticas tarea especial">
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      manejarModalEstadisticasTarea(row.id, row.task)
                    }
                    aria-label="watch-statistics"
                    size="small"
                  >
                    <Leaderboard />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={row.taskType === "Especial" ? 6 : 12}
              lg={row.taskType === "Especial" ? 6 : 12}
            >
              <Tooltip title="Ver tarea">
                <IconButton
                  color="primary"
                  onClick={() => handleClickWatchTask(row.id)}
                  aria-label="watch-task"
                  size="small"
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: "100%" }}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom component="div">
                    Historial tarea pendientes
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <TablaToolbar
                    filterName={state.filterName}
                    onFilterName={handleFilterByName}
                  />
                </Grid>
              </Grid>
              <Scrollbar>
                <TableContainer>
                  <Table size="small" aria-label="purchases">
                    <TablaHead
                      order={state.order}
                      orderBy={state.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={row.history?.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filasFiltradas
                        .slice(
                          state.currentPage * state.rowsPerPageOptions,
                          state.currentPage * state.rowsPerPageOptions +
                            state.rowsPerPageOptions
                        )
                        .map((historyRow, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {historyRow.task}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {historyRow.nameClient}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.branch}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.taskType}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.state === "En Proceso" ? (
                                <Chip
                                  color="secondary"
                                  label={historyRow.state}
                                  size="small"
                                  icon={<AccessTime />}
                                />
                              ) : historyRow.state === "Terminada" ||
                                historyRow.state === "Facturado" ? (
                                <Chip
                                  color="success"
                                  label={historyRow.state}
                                  size="small"
                                  icon={<DoneAll />}
                                />
                              ) : (
                                <Chip
                                  color="warning"
                                  label={historyRow.state}
                                  size="small"
                                  icon={<WarningAmber />}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="body2" noWrap>
                                {fToNow(historyRow.createdAt)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Grid
                                container
                                justifyContent="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={
                                    historyRow.taskType === "Especial" ? 6 : 12
                                  }
                                  lg={
                                    historyRow.taskType === "Especial" ? 6 : 12
                                  }
                                >
                                  {historyRow.taskType === "Especial" && (
                                    <Tooltip title="Estadísticas tarea especial">
                                      <IconButton
                                        color="secondary"
                                        onClick={() =>
                                          manejarModalEstadisticasTarea(
                                            historyRow.id,
                                            historyRow.task
                                          )
                                        }
                                        aria-label="watch-statistics"
                                        size="small"
                                      >
                                        <Leaderboard />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={
                                    historyRow.taskType === "Especial" ? 6 : 12
                                  }
                                  lg={
                                    historyRow.taskType === "Especial" ? 6 : 12
                                  }
                                >
                                  <Tooltip title="Ver tarea">
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        handleClickWatchTask(historyRow.id)
                                      }
                                      aria-label="watch-task"
                                      size="small"
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {esSucursalNoEncontrada && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={state.filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableFooter>
                      <TableRow>
                        <ThemeProvider
                          theme={(outerTheme) =>
                            createTheme(outerTheme, locales[state.locale])
                          }
                        >
                          <CustomTablePagination
                            count={filasFiltradas.length}
                            rowsPerPage={state.rowsPerPageOptions}
                            page={state.currentPage}
                            colSpan={9}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </ThemeProvider>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {abrirModalEstadisticasTarea && (
        <ModalDetalleFacturaTareas
          isOpen={abrirModalEstadisticasTarea}
          setOpen={setAbrirModalEstadisticasTarea}
          dataDivisiones={datosDetalleComisiones}
          cargandoDivisiones={cargandoDatosComisionesFactura}
          errorDivisiones={errorDetalleComisiones}
          nombreTarea={nombreTarea}
          cargandoFacturado={cargandoDetalleFacturasTarea}
          dataFacturado={datosDetalleFacturas}
        />
      )}
    </>
  );
}

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "tarea", label: "Tarea", alignRight: false },
  { id: "cliente", label: "Cliente", alignRight: false },
  { id: "sucursal", label: "Sucursal", alignRight: true },
  { id: "tipo-tarea", label: "Tipo tarea", alignRight: true },
  { id: "estado", label: "Estado", alignRight: true },
  { id: "creada", label: "Creada", alignRight: true },
  { id: "ver-tarea", label: "", alignRight: true },
];
