import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  DialogActions,
  Button,
} from "@material-ui/core";
import { API_SERVER } from "../../utils/urlBack_End";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { formatearFecha } from "src/utils/formatTime";
moment.locale("es");
const ModalVerFactura = (props) => {
  const { open, setOpen, factura } = props;

  const formatosFecha = ["DD/MM/YYYY", "YYYY-MM-DD"];

  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;

    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const [detalleFactura, setDetalleFactura] = useState([]);

  const [mostrarDatosCheque, setMostrarDatosCheque] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (rolObtenido !== "CLIENTE") {
      if (factura.tipo === "PLAN") {
        axios
          .get(`${API_SERVER}/pagos/facturaPlanes/${factura.folio}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            setDetalleFactura(response.data);
            const existeCheque = response.data.find(
              (pago) => pago.metodoPago === "CHEQUE"
            );
            setMostrarDatosCheque(!!existeCheque);
          });
      } else if (factura.tipo === "TAREA") {
        axios
          .get(`${API_SERVER}/pagos/facturaTareas/${factura.id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            setDetalleFactura(response.data);
            const existeCheque = response.data.find(
              (pago) => pago.metodoPago === "CHEQUE"
            );
            setMostrarDatosCheque(!!existeCheque);
          });
      } else if (factura.tipo === "AFECTA") {
        axios
          .get(`${API_SERVER}/pagos/facturaAfecta/${factura.folio}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            setDetalleFactura(response.data);
            const existeCheque = response.data.find(
              (pago) => pago.metodoPago === "CHEQUE"
            );
            setMostrarDatosCheque(!!existeCheque);
          });
      }
    } else {
      axios
        .get(`${API_SERVER}/accesoClientes/pagos/planes/${factura.folio}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setDetalleFactura(response.data);
        });
    }
  }, [factura.folio, rolObtenido, user.rut, factura.id, factura.tipo]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle>{"Factura  N° " + factura.folio} </DialogTitle>
        <DialogContent>
          <DialogTitle>Detalle Factura</DialogTitle>{" "}
          <Grid container>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha de Registro</TableCell>
                    <TableCell>Fecha de Pago</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Método Pago</TableCell>
                    {mostrarDatosCheque && (
                      <>
                        <TableCell>Banco</TableCell>
                        <TableCell>Número Cheque</TableCell>
                      </>
                    )}
                    {rolObtenido !== "CLIENTE" && (
                      <TableCell>Empleado Recibe Pago</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detalleFactura &&
                    detalleFactura.map((row, index) => (
                      <TableRow key={"tablaDetallePlan_" + index}>
                        <TableCell>
                          {formatearFecha(row.fechaCreacion, "DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {formatearFecha(row.fechaPago, "DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{formatNumber(row.valorPesos)}</TableCell>
                        <TableCell>{row.metodoPago}</TableCell>
                        {mostrarDatosCheque && (
                          <>
                            <TableCell>
                              {row?.banco ? row.banco : "-"}
                            </TableCell>
                            <TableCell>
                              {row?.numeroCheque ? row.numeroCheque : "-"}
                            </TableCell>
                          </>
                        )}
                        {rolObtenido !== "CLIENTE" && (
                          <TableCell>{row.empleadoRecibePago}</TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalVerFactura.propTypes = {};

export default ModalVerFactura;
