import React, { useState, useEffect } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { API_SERVER, dbx } from "../../utils/urlBack_End";
import { toPascalCase } from "../../utils/converter";
import SelectMenu from "../SelectMenu";
import axios from "axios";
import * as Yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import AdjuntarPoderCliente from "./AdjuntarPoderCliente";
import Fab from "@material-ui/core/Fab";
import UnirPDF from "../UnirPDF";
import GenerarPoderDialog from "./GenerarPoderDialog";
import { ajustesApi } from "src/services/ajustesApi";
import handleError from "src/utils/manejo-errores";
import { clientesApi } from "src/services/clientesApi";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    minWidth: "100%",
    marginTop: 16,
  },
  grid: {
    xs: 6,
    spacing: 1,
    alignItems: "center",
    justify: "center",
    marginBottom: "1rem",
  },
  SelectMenu: {
    marginTop: "1rem",
  },
  hidden: {
    display: "none",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
  gridBorders: {
    marginBottom: 15,
    marginTop: 12,
  },
}));

export default function EditarCamposCliente(props) {
  const {
    value,
    cliente,
    setCliente,
    setShowAlert,
    setShowErrorMessage,
    setMessage,
  } = props;
  const [correoValido, setCorreoValido] = useState(false);
  const [correoFacturacionValido, setCorreoFacturacionValido] = useState(false);
  const [guardandoDatos, setGuardandoDatos] = useState(false);
  const [infoComponente, setInfoComponente] = useState([]);
  const [selectorComponente, setSelectorComponente] = useState([]);
  const [valido, setValido] = useState(false);
  const classes = styles();

  const [archivoObtenido, setArchivoObtenido] = useState(null);

  const [loading, setLoading] = useState(false);
  const [adjuntar, setAdjuntar] = useState(false);
  const [unirPDF, setUnirPDF] = useState(false);
  const [generarPoder, setGenerarPoder] = useState(false);

  const { data: comunas = [] } = ajustesApi.useGetComunasQuery();
  const [editarCliente] = clientesApi.useEditarClienteMutation();
  const { data: complejidades = [], isSuccess: complejidadesCargadas } =
    ajustesApi.useGetComplejidadesClienteQuery();

  useEffect(() => {
    if (Object.entries(cliente).length > 14 && infoComponente.length === 0) {
      async function fetchData() {
        let infoComp = [];
        for (let index = 14; index < Object.entries(cliente).length; index++) {
          const propiedad = Object.entries(cliente)[index];
          await axios
            .get(
              `${API_SERVER}/ajustes/relacionColumnaCliente/${propiedad[0]}`,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              infoComp.push(response.data[0]);
            });
        }
        setInfoComponente(infoComp);
      }
      fetchData();
    }
  }, [cliente, infoComponente, selectorComponente]);

  useEffect(() => {
    if (infoComponente.length > 0 && !valido) {
      async function fetchData() {
        setValido(true);
        let selectComp = [];
        for (let index = 0; index < infoComponente.length; index++) {
          const info = infoComponente[index];
          info.componente === "select" &&
            (await axios
              .get(
                `${API_SERVER}/ajustes/relacionSelectorElemento/${info.id}`,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then(function (response) {
                if (response.data === -2) window.location.reload();
                selectComp.push(response.data);
              }));
        }

        const ordenarGrupos = selectComp[0];

        ordenarGrupos.sort((a, b) => {
          const nombreA = a.nombreElemento.toUpperCase();
          const nombreB = b.nombreElemento.toUpperCase();

          if (nombreA < nombreB) {
            return -1;
          }
          if (nombreA > nombreB) {
            return 1;
          }
          return 0;
        });

        setSelectorComponente(selectComp);
      }
      fetchData();
    }
  }, [infoComponente, selectorComponente, valido]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/poder/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setArchivoObtenido(response.data[0]);
      });
  }, [cliente.rut]);

  const handleChangeEstado = (e) => {
    setCliente({ ...cliente, estado: e.target.value });
  };

  const handleChangeComplejidad = (value) => {
    if (complejidadesCargadas) {
      setCliente({ ...cliente, refComplejidad: value.id });
    }
  };

  let schema = Yup.object().shape({
    email: Yup.string().email(),
  });

  const validar = (propiedad, valor) => {
    if (propiedad === "correo") {
      schema.isValid({ email: valor.trim() }).then(function (valid) {
        setCorreoValido(valid);
      });
      return !correoValido;
    } else if (propiedad === "correoFacturacion") {
      schema.isValid({ email: valor.trim() }).then(function (valid) {
        setCorreoFacturacionValido(valid);
      });
      return !correoFacturacionValido;
    }
    return false;
  };

  const mostrarTexto = (propiedad, validacion) => {
    if (propiedad === "telefono" && validacion) {
      return "El teléfono debe tener 9 dígitos";
    }
    if (propiedad === "correo" && validacion) {
      return "El correo ingresado no es válido";
    }
    if (propiedad === "correoFacturacion" && validacion) {
      return "El correo de facturacion no es válido";
    }
  };

  const formatearDatosCliente = () => {
    let datos = {};
    Object.entries(cliente).forEach(([clave, valor]) => {
      if (clave !== "rut" && clave !== "refComplejidad") {
        datos[clave] = valor === null ? null : valor.trim().toUpperCase();
      } else if (clave !== "rut") {
        datos[clave] = valor;
      }
    });
    return datos;
  };

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/clientes/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();

        setCliente(response.data);
      });
    axios
      .get(`${API_SERVER}/clientes/poder/${cliente.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setArchivoObtenido(response.data);
      });
  };

  const guardarDatos = async () => {
    try {
      let esValido = Object.entries(cliente).filter(([clave, valor]) =>
        validar(clave, valor)
      );
      if (esValido.length === 0) {
        setGuardandoDatos(true);
        let datosCliente = formatearDatosCliente();

        const body = {
          rut: cliente.rut,
          datos: datosCliente,
        };

        const { error } = await editarCliente(body);

        if (error) {
          setMessage("Ha ocurrido un error al actualizar el cliente");
          setShowErrorMessage(true);
          setGuardandoDatos(false);
          handleError("guardarDatos (EditarCamposCliente)", error?.data);
        }
        setShowAlert(true);
      } else {
        setMessage(
          `Error: El campo ${esValido[0][0]} con el valor: ${esValido[0][1]} no es válido`
        );
        setShowErrorMessage(true);
      }
    } catch (error) {
      handleError("guardarDatos", error);
    } finally {
      setGuardandoDatos(false);
    }
  };

  const mostrarLabel = (propiedad) => {
    if (propiedad === "rut") return "Rut";
    if (propiedad === "nombre") return "Nombre";
    if (propiedad === "direccion") return "Dirección";
    if (propiedad === "telefono") return "Teléfono";
    if (propiedad === "comentarios") return "Notas sobre el cliente";
    if (propiedad === "correo") return "Correo Personal";
    if (propiedad === "correoFacturacion") return "Correo Facturación";
    if (propiedad === "contacto") return "Contacto";
    if (propiedad === "fechaIngreso") return "Fecha Ingreso";
    if (propiedad === "fechaTermino") return "Fecha Término";
  };

  async function deleteFile() {
    try {
      await dbx.filesDeleteV2({
        path: `/Clientes/${cliente.rut}/Poder_` + cliente.rut + ".pdf",
      });
      if (archivoObtenido) {
        axios
          .delete(`${API_SERVER}/clientes/poder/${archivoObtenido.id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            actualizar();
          });
      }
    } catch {}
  }

  return (
    <div className={classes.pos}>
      {value === 0 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" className={classes.title}>
              Datos del Cliente
            </Typography>
            {archivoObtenido ? (
              <Grid
                container
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid item xs={12} md={10}>
                  <Fab
                    style={{ width: "90%" }}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      window.open(archivoObtenido.urlPoder, "_blank");
                    }}
                  >
                    DESCARGAR PODER
                  </Fab>
                </Grid>
                <Grid item xs={12} md={2}>
                  <LoadingButton
                    fullWidth
                    loading={loading}
                    onClick={deleteFile}
                    size="large"
                    color="error"
                  >
                    <DeleteIcon /> Eliminar
                  </LoadingButton>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.gridBorders}
              >
                <Grid item xs={10} md={10} className={classes.gridItem}>
                  <Fab
                    style={{ width: "90%" }}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    onClick={() => setAdjuntar(true)}
                  >
                    Subir Poder Al Cliente
                  </Fab>
                </Grid>
                <Grid item xs={2} md={2} className={classes.gridItem}>
                  <Fab
                    style={{ width: "90%" }}
                    variant="extended"
                    size="medium"
                    color="default"
                    aria-label="add"
                    onClick={() => setUnirPDF(true)}
                  >
                    Unir PDF
                  </Fab>
                </Grid>
              </Grid>
            )}
            <Grid container style={{ margin: "20px" }}>
              <Grid item xs={12} md={12}>
                <Fab
                  style={{ width: "90%" }}
                  variant="extended"
                  size="medium"
                  color="info"
                  aria-label="add"
                  onClick={() => {
                    setGenerarPoder(true);
                  }}
                >
                  GENERAR PODER
                </Fab>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Seleccione Estado
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Seleccione Estado"
                    id="demo-simple-select"
                    value={cliente.estado}
                    onChange={(e) => handleChangeEstado(e)}
                  >
                    <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                    <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <SelectMenu
                  options={complejidadesCargadas ? complejidades : []}
                  value={
                    complejidadesCargadas && cliente
                      ? complejidades.find(
                          (complejidad) =>
                            complejidad.id === cliente.refComplejidad
                        )
                      : null
                  }
                  onChange={(value) => handleChangeComplejidad(value)}
                  label="Nivel de Complejidad"
                  disableClearable={true}
                />
              </Grid>
            </Grid>

            <FormControl autoComplete="off" noValidate>
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={2}>
                  {Object.entries(cliente)
                    .filter((propiedad) => {
                      return (
                        propiedad[0] !== "estado" &&
                        propiedad[0] !== "contrasena" &&
                        propiedad[0] !== "refComplejidad"
                      );
                    })
                    .map((propiedad, index) => {
                      let disabled = false;
                      if (
                        propiedad[0] === "fechaIngreso" ||
                        propiedad[0] === "fechaTermino" ||
                        propiedad[0] === "rut"
                      ) {
                        disabled = true;
                      }
                      if (index < 11) {
                        return (
                          <React.Fragment key={propiedad[0]}>
                            <Grid item xs={12} md={6}>
                              {propiedad[0] !== "comuna" ? (
                                <TextField
                                  className={classes.SelectMenu}
                                  id={`${propiedad[0]}`}
                                  value={propiedad[1] ? propiedad[1] : ""}
                                  label={mostrarLabel(propiedad[0])}
                                  fullWidth
                                  disabled={disabled}
                                  onChange={(e) =>
                                    setCliente({
                                      ...cliente,
                                      [propiedad[0]]: e.target.value,
                                    })
                                  }
                                  error={validar(propiedad[0], propiedad[1])}
                                  helperText={mostrarTexto(
                                    propiedad[0],
                                    validar(propiedad[0], propiedad[1])
                                  )}
                                />
                              ) : (
                                <SelectMenu
                                  options={comunas.map((comuna) => {
                                    return comuna.nombre;
                                  })}
                                  label={
                                    "Seleccione " + toPascalCase(propiedad[0])
                                  }
                                  value={propiedad[1]}
                                  onChange={(e) =>
                                    setCliente({
                                      ...cliente,
                                      [propiedad[0]]: e,
                                    })
                                  }
                                />
                              )}
                            </Grid>
                          </React.Fragment>
                        );
                      } else {
                        if (infoComponente.length > 0) {
                          return (
                            <React.Fragment key={propiedad[0]}>
                              <Grid item xs={12} md={6} mt={2}>
                                {infoComponente[index - 11]?.componente !==
                                "select" ? (
                                  <TextField
                                    id={propiedad[0]}
                                    value={propiedad[1] ? propiedad[1] : ""}
                                    label={infoComponente[index - 11]?.label}
                                    fullWidth
                                    disabled={disabled}
                                    onChange={(e) =>
                                      setCliente({
                                        ...cliente,
                                        [propiedad[0]]: e.target.value,
                                      })
                                    }
                                    error={validar(propiedad[0], propiedad[1])}
                                    helperText={mostrarTexto(
                                      propiedad[0],
                                      validar(propiedad[0], propiedad[1])
                                    )}
                                  />
                                ) : (
                                  selectorComponente.length > 0 && (
                                    <FormControl fullWidth>
                                      <InputLabel
                                        id={
                                          "demo-simple-select-label" +
                                          infoComponente[index - 11]?.label
                                        }
                                      >
                                        Seleccione{" "}
                                        {infoComponente[index - 11]?.label}
                                      </InputLabel>
                                      <Select
                                        labelId={"demo-simple-select-label"}
                                        id={"demo-simple-select"}
                                        label={`Seleccione ${
                                          infoComponente[index - 11]?.label
                                        }`}
                                        value={propiedad[1] || ""}
                                        onChange={(e) =>
                                          setCliente({
                                            ...cliente,
                                            [propiedad[0]]: e.target.value,
                                          })
                                        }
                                      >
                                        <MenuItem value="">
                                          <em>Ninguno</em>
                                        </MenuItem>
                                        {propiedad[1] !== "" && (
                                          <MenuItem value={propiedad[1]}>
                                            {propiedad[1]}
                                          </MenuItem>
                                        )}
                                        {selectorComponente?.map(
                                          (seleccion) => {
                                            return seleccion
                                              .filter((selector) => {
                                                return (
                                                  selector.refComponente ===
                                                  infoComponente[index - 11]?.id
                                                );
                                              })
                                              .map((selector, index) =>
                                                selector.nombreElemento !==
                                                propiedad[1] ? (
                                                  <MenuItem
                                                    key={index}
                                                    value={
                                                      selector.nombreElemento
                                                    }
                                                  >
                                                    {selector.nombreElemento}
                                                  </MenuItem>
                                                ) : (
                                                  false
                                                )
                                              );
                                          }
                                        )}
                                      </Select>
                                    </FormControl>
                                  )
                                )}
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      }
                      return false;
                    })}
                </Grid>
              </Grid>
            </FormControl>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              disabled={guardandoDatos}
              loading={guardandoDatos}
              onClick={() => guardarDatos()}
            >
              Guardar
            </LoadingButton>
          </CardActions>
        </Card>
      )}
      {adjuntar && (
        <AdjuntarPoderCliente
          open={adjuntar}
          setOpen={setAdjuntar}
          setLoading={setLoading}
          cliente={cliente}
          setCliente={setCliente}
          archivoObtenido={archivoObtenido}
          setArchivoObtenido={setArchivoObtenido}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
        />
      )}
      {unirPDF && (
        <UnirPDF cliente={cliente} open={unirPDF} setOpen={setUnirPDF} />
      )}

      {generarPoder && (
        <GenerarPoderDialog
          open={generarPoder}
          setOpen={setGenerarPoder}
          cliente={cliente}
        />
      )}
    </div>
  );
}
