import React, { useState, useEffect } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../../utils/urlBack_End";
import { Box } from "@material-ui/system";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { es } from "date-fns/locale";
import moment from "moment";

export default function PagosFacturaPlanesDialog(props) {
  const { open, setOpen, factura, recargarFacturas } = props;
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [cargandoAgregar, setCargandoAgregar] = useState(false);
  const [mensajeError, setMensajeError] = useState(false);

  const [tipoPago, setTipoPago] = useState("TOTAL");
  const valorAPagar = factura.valorPesos;
  const [montoAbonado, setMontoAbonado] = useState(0);
  const [montoAPagar, setMontoAPagar] = useState(0);
  const [numeroCheque, setNumeroCheque] = useState("");
  const [banco, setBanco] = useState("");
  const [metodoDePago, setMetodoDePago] = useState("");
  const [totalAPagar, setTotalAPagar] = useState();
  const [fechaIngreso, setFechaIngreso] = useState(new Date());

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const handleChangeTipoPago = (event) => {
    if (event.target.value === "TOTAL" && montoAbonado > 0) {
      setTotalAPagar(factura.valorPesos - montoAbonado);
    } else if (event.target.value === "TOTAL" && montoAbonado <= 0) {
      setTotalAPagar(factura.valorPesos);
    }
    setTipoPago(event.target.value);
  };

  const handleChangeMontoAPagar = (event) => {
    if (montoAbonado <= 0) {
      setTotalAPagar(factura.valorPesos - event.target.value);
    } else {
      setTotalAPagar(montoAbonado - event.target.value);
    }
    setMontoAPagar(event.target.value);
  };

  const handleChangeMetodoDePago = (event) => {
    setMetodoDePago(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeNumeroCheque = (event) => {
    setNumeroCheque(event.target.value);
  };

  const handleChangeBanco = (event) => {
    setBanco(event.target.value);
  };

  const pagar = () => {
    setCargandoAgregar(true);
    setMensajeError(false);
    let state = true;

    if (
      tipoPago !== "INCOBRABLE" &&
      (metodoDePago === "" || metodoDePago === " ")
    ) {
      setMensajeError("Debe seleccionar metodo de pago.");
      setCargandoAgregar(false);
    } else if (
      tipoPago !== "INCOBRABLE" &&
      factura.valorPesos <= montoAbonado
    ) {
      setMensajeError("Monto Abonado no puede ser mayor al de la factura.");
      setCargandoAgregar(false);
    } else if (tipoPago !== "INCOBRABLE" && factura.valorPesos < montoAPagar) {
      setMensajeError("Monto a pagar no puede ser mayor al de la factura.");
      setCargandoAgregar(false);
    } else if (
      montoAPagar <= 0 &&
      tipoPago !== "TOTAL" &&
      tipoPago !== "INCOBRABLE"
    ) {
      setMensajeError("Monto abonado no puede ser menor o igual a 0");
      setCargandoAgregar(false);
    } else if (
      tipoPago === "ABONO" &&
      Number(montoAPagar) + montoAbonado > factura.valorPesos
    ) {
      setMensajeError(
        "El monto a pagar es igual o supera el total facturado, por favor, seleccione la opción TOTAL"
      );
      setCargandoAgregar(false);
    } else if (
      (metodoDePago === "CHEQUE" && !numeroCheque.trim()) ||
      (metodoDePago === "CHEQUE" && !banco.trim())
    ) {
      setMensajeError("Ingrese el número de cheque y el banco");
      setCargandoAgregar(false);
    } else {
      let valorTotal = 0;
      let valorPesosGuardar = 0.0;
      if (tipoPago === "TOTAL") {
        if (montoAbonado > 0) {
          valorTotal = 0.0;
          valorPesosGuardar = factura.valorPesos - montoAbonado;
        } else {
          valorTotal = 0.0;
          valorPesosGuardar = valorAPagar;
        }
      } else {
        if (montoAbonado <= 0) {
          valorTotal = factura.valorPesos - Number(montoAPagar);
          valorPesosGuardar = Number(montoAPagar);
        } else {
          valorTotal = montoAbonado + Number(montoAPagar);
          valorPesosGuardar = montoAPagar;
        }
      }

      if (
        factura.valorPesos === Number(montoAPagar) ||
        Number(montoAPagar) + montoAbonado === factura.valorPesos
      ) {
        state = false;
        if (Number(montoAPagar) + montoAbonado === factura.valorPesos) {
          valorTotal = 0;
        }
      }

      const { nombre, correoFacturacion } = factura;

      const { detalleFactura, fechaFacturacion } = factura;
      if (factura.tipo === "PLAN") {
        axios
          .post(
            `${API_SERVER}/pagos/facturaPlanes`,
            {
              totalFactura: valorPesosGuardar,
              idFactura: factura.id,
              empleadoRecibePago: user.nombre + " " + user.apellidos,
              estado: state ? tipoPago : "TOTAL",
              sucursal: factura.sucursal,
              metodoDePago: metodoDePago,
              deudaTotal: valorTotal,
              folio: factura.folio,
              numeroCheque: metodoDePago === "CHEQUE" ? numeroCheque : null,
              banco: metodoDePago === "CHEQUE" ? banco : null,
              fechaPago: moment(fechaIngreso).format("DD/MM/YYYY"),
              nombreCliente: nombre,
              correoCliente: correoFacturacion,
              detalleFactura,
              fechaFacturacion,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (response) {
            recargarFacturas();
            setOpen(false);
          })
          .catch(() => {})
          .finally(() => {
            setCargandoAgregar(false);
          });
      } else if (factura.tipo === "TAREA") {
        axios
          .post(
            `${API_SERVER}/pagos/facturaTareas`,
            {
              idTarea: factura.refTarea,
              totalFactura: Number(valorPesosGuardar),
              idFactura: factura.id,
              empleadoRecibePago: user.nombre + " " + user.apellidos,
              estado: state ? tipoPago : "TOTAL",
              sucursal: factura.sucursal,
              metodoDePago: metodoDePago,
              folio: factura.folio,
              numeroCheque: metodoDePago === "CHEQUE" ? numeroCheque : null,
              banco: metodoDePago === "CHEQUE" ? banco : null,
              fechaPago: moment(fechaIngreso).format("DD/MM/YYYY"),
              deudaTotal: valorTotal,
              nombreCliente: nombre,
              correoCliente: correoFacturacion,
              detalleFactura,
              fechaFacturacion,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (response) {
            recargarFacturas();
            setOpen(false);
          })
          .catch(() => {})
          .finally(() => {
            setCargandoAgregar(false);
          });
      } else if (factura.tipo === "AFECTA") {
        axios
          .post(
            `${API_SERVER}/pagos/facturaAfecta`,
            {
              folio: factura.folio,
              totalFactura: valorPesosGuardar,
              idFactura: factura.id,
              empleadoRecibePago: user.nombre + " " + user.apellidos,
              estado: state ? tipoPago : "TOTAL",
              sucursal: factura.sucursal,
              metodoDePago: metodoDePago,
              refCliente: factura.refCliente,
              deudaTotal: valorTotal,
              numeroCheque: metodoDePago === "CHEQUE" ? numeroCheque : null,
              banco: metodoDePago === "CHEQUE" ? banco : null,
              fechaPago: moment(fechaIngreso).format("DD/MM/YYYY"),
              nombreCliente: nombre,
              correoCliente: correoFacturacion,
              detalleFactura,
              fechaFacturacion,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (response) {
            recargarFacturas();
            setOpen(false);
          })
          .catch(() => {})
          .finally(() => {
            setCargandoAgregar(false);
          });
      }
    }
  };

  useEffect(() => {
    if (factura.tipo === "PLAN") {
      axios
        .get(`${API_SERVER}/pagos/abonosFacturasPlanes/${factura.folio}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();

          if (Array.isArray(response.data)) {
            let valor = 0.0;
            response.data.forEach((e) => {
              valor = valor + Number.parseFloat(e.valorPesos);
            });
            if (valor > 0) {
              setTotalAPagar(factura.valorPesos - valor);
            } else if (valor <= 0) {
              setTotalAPagar(factura.valorPesos);
            }
            setMontoAbonado(valor);
          }
        })
        .catch(() => {});
    } else if (factura.tipo === "TAREA") {
      axios
        .get(`${API_SERVER}/pagos/abonosFacturasTareas/${factura.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          if (Array.isArray(response.data)) {
            let valor = 0.0;
            response.data.forEach((e) => {
              valor = valor + Number.parseFloat(e.valorPesos);
            });
            if (valor > 0) {
              setTotalAPagar(factura.valorPesos - valor);
            } else if (valor <= 0) {
              setTotalAPagar(factura.valorPesos);
            }
            setMontoAbonado(valor);
          }
        })
        .catch(() => {});
    } else if (factura.tipo === "AFECTA") {
      axios
        .get(`${API_SERVER}/pagos/abonosFacturasAfecta/${factura.folio}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          if (Array.isArray(response.data)) {
            let valor = 0.0;
            response.data.forEach((e) => {
              valor = valor + Number.parseFloat(e.valorPesos);
            });
            if (valor > 0) {
              setTotalAPagar(factura.valorPesos - valor);
            } else if (valor <= 0) {
              setTotalAPagar(factura.valorPesos);
            }
            setMontoAbonado(valor);
          }
        })
        .catch(() => {});
    }
  }, [factura.folio, factura.valorPesos, user.rut, factura.tipo, factura.id]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Pagos Factura {factura.folio}</DialogTitle>
      <DialogContent>
        <Stack>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo Pago</FormLabel>
            <RadioGroup row value={tipoPago} onChange={handleChangeTipoPago}>
              <FormControlLabel
                value="ABONO"
                control={<Radio />}
                label="ABONO"
              />
              <FormControlLabel
                value="TOTAL"
                control={<Radio />}
                label="TOTAL"
              />
              <FormControlLabel
                value="INCOBRABLE"
                control={<Radio />}
                label="INCOBRABLE"
              />
            </RadioGroup>
          </FormControl>

          {tipoPago === "INCOBRABLE" ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <DatePicker
                  label="Fecha incobrable"
                  views={["year", "month", "day"]}
                  value={fechaIngreso}
                  onChange={(newValue) => {
                    setFechaIngreso(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={params?.inputProps?.placeholder}
                      fullWidth
                      style={{ marginTop: "10px" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </>
          ) : (
            <>
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount1">
                  Total Facturado
                </InputLabel>
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  style={{ marginTop: "10px" }}
                  label="Valor A Pagar"
                  value={formatNumber(valorAPagar)}
                  disabled={true}
                  variant="outlined"
                  type="text"
                />
              </FormControl>

              <FormControl fullWidth style={{ marginTop: "10px" }}>
                <InputLabel htmlFor="outlined-adornment-amount1">
                  Total Abonado
                </InputLabel>
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  style={{ marginTop: "10px" }}
                  label="Total Abonado"
                  value={montoAbonado}
                  disabled
                  variant="outlined"
                  type="number"
                />
              </FormControl>

              {tipoPago !== "TOTAL" && (
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="outlined-adornment-amount1">
                    Monto a Pagar
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    style={{ marginTop: "10px" }}
                    label="Monto a Pagar"
                    value={montoAPagar}
                    onChange={handleChangeMontoAPagar}
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              )}

              {tipoPago === "TOTAL" && (
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="outlined-adornment-amount1">
                    Total a Pagar
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    style={{ marginTop: "10px" }}
                    label="Total a Pagar"
                    value={totalAPagar}
                    disabled
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              )}

              <FormControl fullWidth style={{ marginTop: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  MÉTODO DE PAGO
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={metodoDePago}
                  label="MÉTODO DE PAGO"
                  onChange={handleChangeMetodoDePago}
                >
                  <MenuItem value="">SELECCIONAR MÉTODO DE PAGO</MenuItem>
                  <MenuItem value="EFECTIVO">EFECTIVO</MenuItem>
                  <MenuItem value="TRANSFERENCIA">TRANSFERENCIA</MenuItem>
                  <MenuItem value="TARJETA">TARJETA</MenuItem>
                  <MenuItem value="WEBPAY">WEBPAY</MenuItem>
                  <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                </Select>
              </FormControl>

              {metodoDePago === "CHEQUE" && (
                <>
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel htmlFor="outlined-adornment-amount1">
                      Número de Cheque
                    </InputLabel>
                    <OutlinedInput
                      style={{ marginTop: "10px" }}
                      label="Número Cheque"
                      value={numeroCheque}
                      onChange={handleChangeNumeroCheque}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel htmlFor="outlined-adornment-amount1">
                      Ingrese Banco
                    </InputLabel>
                    <OutlinedInput
                      style={{ marginTop: "10px" }}
                      label="Banco"
                      value={banco}
                      onChange={handleChangeBanco}
                      variant="outlined"
                    />
                  </FormControl>
                </>
              )}

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <DatePicker
                  label="Fecha Pago"
                  views={["year", "month", "day"]}
                  value={fechaIngreso}
                  onChange={(newValue) => {
                    setFechaIngreso(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={params?.inputProps?.placeholder}
                      fullWidth
                      style={{ marginTop: "10px" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </>
          )}
        </Stack>
      </DialogContent>
      {mensajeError && (
        <Box width="100%">
          <Alert severity="error">{mensajeError}</Alert>
        </Box>
      )}
      <DialogActions>
        <LoadingButton onClick={handleClose} loading={cargandoAgregar}>
          Cerrar
        </LoadingButton>
        <LoadingButton
          onClick={pagar}
          loading={cargandoAgregar}
          variant="contained"
        >
          Pagar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
