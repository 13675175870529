import React, { useState, useEffect, useCallback } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { API_SERVER } from "../../utils/urlBack_End";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
  TableRow,
  Table,
  TableHead,
} from "@material-ui/core";
import axios from "axios";
import { tiposApi } from "src/services/tiposApi";
import { AlertaToast } from "../AlertaSwall";
import { tareasApi } from "src/services/tareasApi";
export default function EditarPlan(props) {
  const {
    openEditar,
    setOpenEditar,
    monedas,
    nombrePlanDato,
    valorPlanDato,
    tipoMonedaDato,
    descripcionDato,
  } = props;
  const [nombrePlan, setNombrePlan] = useState(nombrePlanDato);
  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [valorPlan, setValorPlan] = useState(valorPlanDato);
  const [tipoMoneda, setTipoMoneda] = useState(tipoMonedaDato);
  const [descripcion, setDescripcion] = useState(descripcionDato);
  const [nombreTarea, setNombreTarea] = useState("");
  const [nombreSubTarea, setNombreSubTarea] = useState("");

  const [mostrarModalSubTareas, setMostrarModalSubTarea] = useState(false);
  const [mostrarModalAgregarSubTarea, setMostrarModalAgregarSubTarea] =
    useState(false);
  const [subTareas, setSubtareas] = useState(null);
  const [subTareasMostrar, setSubtareasMostrar] = useState(null);

  const [tareaSeleccionada, setTareaSeleccionada] = useState(null);

  const { data: tareasAsociadas = [], refetch: recargarTareasPlan } =
    tareasApi.useGetTipoPlanQuery(nombrePlanDato);

  const [agregarTareaAPlan] = tiposApi.useAddRelacionTipoPlanTareaMutation();
  const [actualizarPlan] = tiposApi.useUpdateTipoPlanMutation();
  const [eliminarTareaAsociadaPlan] =
    tiposApi.useDeleteRelacionTipoPlanTareaMutation();

  useEffect(() => {
    axios
      .get(`${API_SERVER}/subtareas/planTarea`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setSubtareas(response.data);
      });
  }, []);

  const [openAgregarTarea, setOpenAgregarTarea] = useState(false);

  const handleClickOpenAgregar = () => {
    setOpenAgregarTarea(true);
  };

  const handleCloseAgregar = () => {
    setOpenAgregarTarea(false);
    setNombreTarea("");
  };

  const handleChangeTipoMoneda = (event) => {
    setTipoMoneda(event.target.value);
  };

  const handleChangeNombreTarea = (event) => {
    setNombreTarea(event.target.value);
  };

  const handleChangeNombreSubTarea = (event) => {
    setNombreSubTarea(event.target.value);
  };

  const handleChangeNombrePlan = (event) => {
    setNombrePlan(event.target.value);
  };
  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const handleChangValorPlan = (event) => {
    setValorPlan(event.target.value);
  };

  const handleChangeDescripcionPlan = (event) => {
    setDescripcion(event.target.value);
  };

  const agregarTareaPlan = useCallback(async () => {
    const body = {
      nombrePlan: nombrePlanDato,
      nombreTarea: nombreTarea.toUpperCase(),
    };
    await agregarTareaAPlan(body);
    setOpenAgregarTarea(false);
    recargarTareasPlan();

  }, [agregarTareaAPlan, nombrePlanDato, nombreTarea, recargarTareasPlan]);

  const guardarPlan = () => {
    if (
      nombrePlan === "" ||
      valorPlan === "" ||
      tipoMoneda === "" ||
      descripcion === ""
    ) {
      AlertaToast({
        action: "Todos los campos deben estar completos",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogEditar"),
      }).fire();
    } else {
      let valorFinal = valorPlan;
      if (String(valorFinal).includes(",")) {
        valorFinal = String(valorFinal).replaceAll(",", ".");
        valorFinal = parseInt(valorFinal);
      }
      let plan = {
        nombrePlan: nombrePlan.toUpperCase(),
        valorPlan: valorFinal,
        tipoMoneda: tipoMoneda.toUpperCase(),
        descripcion: descripcion,
      };
      actualizarPlan(plan)
        .unwrap()
        .then(() => {
          AlertaToast({
            element: "Plan",
            action: "actualizado",
          }).fire();
          setOpenEditar(false);
        })
        .catch(() => {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogEditar"),
          }).fire();
        });
    }
  };

  const manejarEliminacionTareaAsociada = useCallback(
    async (tarea) => {
      const body = {
        nombrePlan: tarea.refPlanTipo,
        nombre: tarea.nombre,
      };
      const response = await eliminarTareaAsociadaPlan(body);

      console.log(response);
      recargarTareasPlan();
    },
    [eliminarTareaAsociadaPlan, recargarTareasPlan]
  );

  const cancelar = () => {
    setNombrePlan("");
    setValorPlan(0);
    setTipoMoneda("");
    setDescripcion("");
    setOpenEditar(false);
  };
  return (
    <>
      <Dialog
        open={openEditar}
        aria-labelledby="form-dialog-title"
        id="dialogEditar"
      >
        <DialogTitle id="form-dialog-title">Editar Plan</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Plan"
            type="text"
            value={nombrePlan}
            onChange={handleChangeNombrePlan}
            fullWidth
            disabled
          />

          <TextField
            margin="dense"
            label="Valor Plan"
            defaultValue={valorPlan}
            value={valorPlan}
            onChange={handleChangValorPlan}
            type="number"
            fullWidth
            required
          />

          <TextField
            style={{ marginTop: "10px", width: "100%" }}
            id="outlined-multiline-static"
            label="Descripción Plan"
            multiline
            rows={4}
            variant="outlined"
            required
            value={descripcion}
            onChange={handleChangeDescripcionPlan}
          />

          <FormControl style={{ marginTop: "10px", width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Tipo Moneda (*)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ marginTop: "10px" }}
              value={tipoMoneda}
              onChange={handleChangeTipoMoneda}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {monedas.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.nombre}>
                    {element.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            color="primary"
            startIcon={<Icon icon={plusFill} />}
            style={{ marginTop: "10px" }}
            onClick={handleClickOpenAgregar}
          >
            Agregar Tarea
          </Button>

          <TableContainer component={Paper} style={{ marginTop: "10px" }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre Tarea</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tareasAsociadas.map((row) => (
                  <TableRow key={row.nombre}>
                    <TableCell component="th" scope="row">
                      {row.nombre}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => manejarEliminacionTareaAsociada(row)}
                        color="error"
                      >
                        Eliminar
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          let subTareasMostrar = subTareas.filter(
                            (dato) =>
                              dato.refPlanTipo === row.refPlanTipo &&
                              dato.nombreRelacionTareaTipoPlan === row.nombre
                          );
                          setTareaSeleccionada(row);
                          setSubtareasMostrar(subTareasMostrar);
                          setMostrarModalSubTarea(true);
                        }}
                      >
                        Sub-Tareas
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} color="error">
            Cancelar
          </Button>
          <Button onClick={guardarPlan} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAgregarTarea} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nombre Tarea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Tarea"
            type="text"
            value={nombreTarea}
            onChange={handleChangeNombreTarea}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAgregar} color="error">
            Cancelar
          </Button>
          <Button onClick={() => agregarTareaPlan()} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mostrarModalAgregarSubTarea}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nombre Subtarea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Subtarea"
            type="text"
            value={nombreSubTarea}
            onChange={handleChangeNombreSubTarea}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNombreSubTarea("");
              setMostrarModalAgregarSubTarea(false);
            }}
            color="error"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (
                tareaSeleccionada &&
                nombreSubTarea &&
                nombreSubTarea !== "" &&
                nombreSubTarea !== " "
              ) {
                axios
                  .post(
                    `${API_SERVER}/subtareas/planTarea`,
                    {
                      refPlanTipo: tareaSeleccionada.refPlanTipo,
                      nombreRelacionTareaTipoPlan: tareaSeleccionada.nombre,
                      nombre: nombreSubTarea.toUpperCase(),
                    },
                    {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    }
                  )
                  .then(function (response) {
                    if (response.data === -2) {
                      window.location.reload();
                    } else if (response.data === -1) {
                      return;
                    } else {
                      axios
                        .get(`${API_SERVER}/subtareas/planTarea`, {
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                        .then(function (response2) {
                          if (response2.data === -2) window.location.reload();
                          let elementos = response2.data;
                          if (Array.isArray(elementos)) {
                            setSubtareas(response2.data);
                            let subTareasMostrar = elementos.filter(
                              (dato) =>
                                dato.refPlanTipo ===
                                  tareaSeleccionada.refPlanTipo &&
                                dato.nombreRelacionTareaTipoPlan ===
                                  tareaSeleccionada.nombre
                            );
                            setSubtareasMostrar(subTareasMostrar);
                            setMostrarModalAgregarSubTarea(false);
                          }
                        });
                    }
                    setNombreSubTarea("");
                  })
                  .catch(() => {
                    setColorNotificacion("error");
                    setMensajeNotificacion("La Subtarea ya existe");
                    setOpenNotificacion(true);
                  });
              }
            }}
            color="primary"
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mostrarModalSubTareas}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          Subtarea: {tareaSeleccionada ? tareaSeleccionada.nombre : ""}
        </DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            onClick={() => {
              setMostrarModalAgregarSubTarea(true);
            }}
          >
            Agregar SubTarea
          </Button>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subTareasMostrar &&
                  subTareasMostrar.map((e, idx) => {
                    return (
                      <TableRow key={"subTareaTabla_" + idx}>
                        <TableCell>{e.nombre}</TableCell>
                        <TableCell>
                          <Button
                            color="error"
                            onClick={() => {
                              axios
                                .delete(
                                  `${API_SERVER}/subtareas/planTarea/${tareaSeleccionada.refPlanTipo}/${tareaSeleccionada.nombre}/${e.nombre}`,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem("token"),
                                    },
                                  }
                                )
                                .then(function (response) {
                                  if (response.data === -2) {
                                    window.location.reload();
                                  } else if (response.data === -1) {
                                    return;
                                  } else {
                                    axios
                                      .get(
                                        `${API_SERVER}/subtareas/planTarea`,
                                        {
                                          headers: {
                                            Authorization:
                                              "Bearer " +
                                              localStorage.getItem("token"),
                                          },
                                        }
                                      )
                                      .then(function (response2) {
                                        if (response2.data === -2)
                                          window.location.reload();
                                        let elementos = response2.data;
                                        if (Array.isArray(elementos)) {
                                          setSubtareas(response2.data);
                                          let subTareasMostrar =
                                            elementos.filter(
                                              (dato) =>
                                                dato.refPlanTipo ===
                                                  tareaSeleccionada.refPlanTipo &&
                                                dato.nombreRelacionTareaTipoPlan ===
                                                  tareaSeleccionada.nombre
                                            );
                                          setSubtareasMostrar(subTareasMostrar);
                                          setMostrarModalAgregarSubTarea(false);
                                        }
                                      });
                                  }
                                });
                            }}
                          >
                            Eliminar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setMostrarModalSubTarea(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}
      >
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}
        >
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
      ;
    </>
  );
}
