import React, { useEffect, useState, useRef } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  TextField,
} from "@material-ui/core";
import { AlertaToast } from "../AlertaSwall";
import dayjs from "dayjs";

export default function CantidadRemuneracionesDialog(props) {
  const {
    open,
    setOpen,
    tarea,
    updateTaskInDBRemuneraciones,
    guardandoDatos,
    setGuardandoDatos,
  } = props;
  const [cantidadRemuneraciones, setCantidadRemuneraciones] = useState(0);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const editar = () => {
    setGuardandoDatos(true);
    try {
      const convertirANumero = Number(cantidadRemuneraciones);

      if (convertirANumero < 0) {
        AlertaToast({
          action: "La cantidad debe un numero entero",
          fullText: true,
          type: "error",
          target: document.getElementById("dialogCantidadRemuneraciones"),
        }).fire();
        setGuardandoDatos(false);
        return;
      }

      const fechaTermino = dayjs();

      const fechaTerminoFormateada = fechaTermino.format("YYYY/MM/DD");
      const tareaFinal = {
        ...tarea,
        cantidadFactura: convertirANumero,
        fechaTermino: fechaTerminoFormateada,
      };

      updateTaskInDBRemuneraciones(tareaFinal);
    } catch {
      AlertaToast({
        action: "La cantidad de trabajadores debe ser un numero",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCantidadRemuneraciones"),
      }).fire();
    }
  };

  useEffect(() => {
    if (tarea.estado !== "TERMINADA") setOpen(false);
  }, [setOpen, tarea]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      id="dialogCantidadRemuneraciones"
    >
      <DialogTitle>Cantidad de Remuneraciones</DialogTitle>
      <DialogContent>
        <Stack spacing={3} marginTop="10px">
          <Typography color="primary">Nombre Tarea {tarea.nombre}</Typography>
          <Typography color="primary">
            Nombre Cliente: {tarea.cliente.nombre}
          </Typography>

          <TextField
            value={cantidadRemuneraciones}
            onChange={(event) => {
              setCantidadRemuneraciones(event.target.value);
            }}
            type="number"
            label="Cantidad de remuneraciones realizadas"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} disabled={guardandoDatos}>
          Cerrar
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={editar}
          autoFocus
          loading={guardandoDatos}
        >
          Guardar Cambios
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
