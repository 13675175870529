import * as Dropbox from "dropbox";
const ACCESS_TOKEN = `${process.env.REACT_APP_ACCESS_TOKEN_DROPBOX}`;
const dbx = new Dropbox.Dropbox({ accessToken: ACCESS_TOKEN });
const URL_ORIGEN = window.location.href;

const API_SERVER = `${process.env.REACT_APP_API_SERVER}`;
const API_OPEN_FACTURA_DEV = `${process.env.REACT_APP_API_OPEN_FACTURA_DEV}`;
const API_OPEN_FACTURA_PROD = `${process.env.REACT_APP_API_OPEN_FACTURA_PROD}`;
const API_KEY_OPEN_FACTURA_DEV = `${process.env.REACT_APP_API_KEY_OPEN_FACTURA_DEV}`;
const API_KEY_OPEN_FACTURA_PROD = `${process.env.REACT_APP_API_KEY_OPEN_FACTURA_PROD}`;
const RUT_EMISOR = `${process.env.REACT_APP_RUT_EMISOR}`;

export {
  API_SERVER,
  dbx,
  ACCESS_TOKEN,
  API_OPEN_FACTURA_DEV,
  API_OPEN_FACTURA_PROD,
  API_KEY_OPEN_FACTURA_DEV,
  API_KEY_OPEN_FACTURA_PROD,
  URL_ORIGEN,
  RUT_EMISOR,
};
