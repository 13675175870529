import { experimentalStyled as styled } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

export const CardStyle = styled(Card)(({ theme }) => ({
  marginBottom: "-1rem",
  textAlign: "center",
  padding: theme.spacing(1, 5),
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.info.main,
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > div:first-of-type": {
    alignSelf: "flex-start",
    paddingRight: theme.spacing(2),
  },
}));

export const Title = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1rem",
});

export const Amount = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
});

export const CardAColor = ({ valor, titulo }) => {
  return (
    <CardStyle>
      <div>
        <Title>{titulo}</Title>
      </div>
      <div>
        <Amount>{valor}</Amount>
      </div>
    </CardStyle>
  );
};
