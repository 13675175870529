import { useState } from "react";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Card,
  Stack,
  Container,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [isClient, setIsClient] = useState(false);
  return (
    <RootStyle title="TAXTIC">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hola, bienvenido(a)
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Inicio de Sesión
            </Typography>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography sx={{ color: "text.secondary" }}>
                  {isClient ? "Clientes" : "Empleados"}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setIsClient((isClient) => !isClient)}
                >
                  Inicio Sesión {!isClient ? "Cliente" : "Empleado"}
                </Button>
              </Grid>
            </Grid>
          </Stack>

          <LoginForm isClient={isClient} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
