import { saveAs } from "file-saver";
import handleError from "./manejo-errores.js";

const colocarEncabezado = (worksheet, textoABuscar, dato) => {
  try {
    const regex = new RegExp(textoABuscar, "gi");
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value && typeof cell.value === "string") {
          const updatedValue = cell.value.replace(regex, dato);
          cell.value = updatedValue;
        }
      });
    });
  } catch (error) {
    handleError("colocarEncabezado", error);
  }
};

const activarCalculoAutomatico = (workbook) => {
  try {
    workbook.calcProperties.fullCalcOnLoad = true;
    workbook.calcProperties.calcMode = "auto";
    workbook.calcProperties.calcOnSave = true;
  } catch (error) {
    handleError("activarCalculoAutomatico", error);
  }
};

const aplicarBordeUltimaFila = (worksheet, inicio, fin) => {
  try {
    const ultimaHoja = worksheet.lastRow;
    const numeroSiguienteFila = ultimaHoja.number + 1;
    const siguienteFila = worksheet.getRow(numeroSiguienteFila);

    // Verificar si la fila siguiente existe, si no, crearla
    if (!siguienteFila) {
      worksheet.addRow();
    }

    const columnas = worksheet.columns.slice(inicio, fin); // Obtener las columnas por posiciones
    columnas.forEach((column) => {
      const cell = siguienteFila.getCell(column._number);
      cell.border = {
        bottom: { style: "double", color: { argb: "000000" } },
      };
    });
  } catch (error) {
    handleError("aplicarBordeUltimaFila", error);
  }
};

const guardarArchivo = async (workbook, fileName) => {
  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, fileName);
  } catch (error) {
    handleError("guardarArchivo", error);
  }
};

export {
  colocarEncabezado,
  activarCalculoAutomatico,
  aplicarBordeUltimaFila,
  guardarArchivo,
};
