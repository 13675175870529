import React from "react";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";
import { LoadingButton } from "@material-ui/lab";
export default function ExportToExcelPromedioPagosClientes(props) {
  const { title, exportarDatos, isLoading, sx } = props;

  return (
    <LoadingButton
      variant="contained"
      loading={isLoading}
      onClick={exportarDatos}
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px" }}
      disabled={isLoading}
      type="submit"
      sx={sx}
    >
      {title}
    </LoadingButton>
  );
}
