import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  CardActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { API_SERVER } from "../../utils/urlBack_End";
import axios from "axios";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";
import { AlertaToast } from "../AlertaSwall";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: 20,
  },
  pos: {
    marginBottom: 15,
  },
  formControl: {
    minWidth: "100%",
    marginBottom: 30,
    marginTop: 12,
  },
  formSelect: {
    minWidth: "100%",
  },
  grid: {
    xs: 6,
    spacing: 1,
    alignItems: "center",
    justify: "center",
    marginBottom: "1rem",
  },
  SelectMenu: {
    marginTop: "1rem",
  },
}));

export default function EditarCamposEmpleado(props) {
  const { value, empleado, setEmpleado, setShowErrorMessage, setMessage } =
    props;
  const classes = styles();
  const [fechaIngreso, setFechaIngreso] = useState(empleado.fechaIngreso);

  const validarTelefono = (value) => {
    if (value?.length === 9) return true;
    else return false;
  };
  const guardarDatos = () => {
    if (errors.correo === undefined && errors.telefono === undefined) {
      let emp = {
        apellidos: getFieldProps("apellidos").value.toUpperCase(),
        correo: getFieldProps("correo").value,
        estado: empleado.estado,
        nombre: getFieldProps("nombre").value.toUpperCase(),
        telefono: getFieldProps("telefono").value.toString().toUpperCase(),
      };

      setEmpleado({
        ...empleado,
        emp,
      });

      axios
        .put(
          `${API_SERVER}/empleados/${
            deconstructRut(getFieldProps("rut").value.toUpperCase()).digits
          }${
            deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier
          }`,
          {
            ...emp,
            fechaIngreso:
              new Date(fechaIngreso).getFullYear() +
              "/" +
              (new Date(fechaIngreso).getMonth() + 1) +
              "/" +
              new Date(fechaIngreso).getDate(),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          const { data, status } = response;

          if (status === 200 && data) {
            AlertaToast({
              action: "Empleado actualizado correctamente",
              fullText: true,
            }).fire();
          } else {
            console.error("Error al guardar datos empleado: ", response);
            AlertaToast({
              action: "Ocurrió un error desconocido al actualizar el empleado",
              fullText: true,
              type: "error",
            }).fire();
          }
        });
    } else {
      setShowErrorMessage(true);
      setMessage("Error, Correo o teléfono no válidos");
    }
  };

  const LoginSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre del empleado requerido"),
    apellidos: Yup.string().required("Apellidos del empleado requeridos"),
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    telefono: Yup.string().test(
      "telefono test",
      "El teléfono debe tener 9 digitos",
      (value) => validarTelefono(value)
    ),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: empleado.nombre,
      apellidos: empleado.apellidos,
      rut: empleado.rut,
      telefono: empleado.telefono,
      correo: empleado.correo,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div className={classes.pos}>
      {value === 0 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" className={classes.title}>
              Datos del Empleado
            </Typography>

            <FormControl autoComplete="off" noValidate>
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={2}>
                  {empleado !== undefined && (
                    <FormikProvider value={formik}>
                      <Form
                        autoComplete="off"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <TextField
                          className={classes.pos}
                          required
                          autoFocus
                          margin="dense"
                          id="nombre"
                          label="Nombre"
                          fullWidth
                          {...getFieldProps("nombre")}
                          error={Boolean(touched.nombre && errors.nombre)}
                          helperText={touched.nombre && errors.nombre}
                        />

                        <TextField
                          className={classes.pos}
                          required
                          margin="dense"
                          id="apellidos"
                          label="Apellidos"
                          fullWidth
                          {...getFieldProps("apellidos")}
                          error={Boolean(touched.apellidos && errors.apellidos)}
                          helperText={touched.apellidos && errors.apellidos}
                        />
                        <TextField
                          className={classes.pos}
                          required
                          margin="dense"
                          id="rut"
                          label="Rut"
                          fullWidth
                          disabled={true}
                          {...getFieldProps("rut")}
                          error={Boolean(touched.rut && errors.rut)}
                          helperText={touched.rut && errors.rut}
                        />
                        <TextField
                          className={classes.pos}
                          required
                          margin="dense"
                          id="telefono"
                          label="Teléfono"
                          type="number"
                          fullWidth
                          {...getFieldProps("telefono")}
                          error={Boolean(touched.telefono && errors.telefono)}
                          helperText={touched.telefono && errors.telefono}
                        />
                        <TextField
                          className={classes.pos}
                          required
                          margin="dense"
                          id="correo"
                          label="Correo"
                          type="email"
                          fullWidth
                          {...getFieldProps("correo")}
                          error={Boolean(touched.correo && errors.correo)}
                          helperText={touched.correo && errors.correo}
                        />
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Seleccione Estado
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            label="Seleccione Estado"
                            id="demo-simple-select"
                            value={empleado.estado}
                            onChange={(e) =>
                              setEmpleado({
                                ...empleado,
                                estado: e.target.value,
                              })
                            }
                          >
                            <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                            <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                          </Select>
                        </FormControl>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={es}
                        >
                          <DatePicker
                            label="Fecha Ingreso Contrato"
                            views={["year", "month", "day"]}
                            value={fechaIngreso}
                            onChange={(newValue) => {
                              setFechaIngreso(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={params?.inputProps?.placeholder}
                                fullWidth
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Form>
                    </FormikProvider>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => guardarDatos()}
            >
              Guardar
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
