import React, { useEffect, useState } from "react";
import { Grid, Button, Card, Stack, Typography } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import ColumnaDia from "./ColumnaDia";
import { Alerta } from "../Alerta";
import { useSelector } from "react-redux";
import moment from "moment";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { calendarioApi } from "src/services/calendarioApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

const obtenerDiaMes = (indice) => {
  if (indice === "01") return "ENERO";
  if (indice === "02") return "FEBRERO";
  if (indice === "03") return "MARZO";
  if (indice === "04") return "ABRIL";
  if (indice === "05") return "MAYO";
  if (indice === "06") return "JUNIO";
  if (indice === "07") return "JULIO";
  if (indice === "08") return "AGOSTO";
  if (indice === "09") return "SEPTIEMBRE";
  if (indice === "10") return "OCTUBRE";
  if (indice === "11") return "NOVIEMBRE";
  if (indice === "12") return "DICIEMBRE";
};

export default function Tablero(props) {
  const { value } = props;
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const [showAlert, setShowAlert] = useState(false);

  const [fechaCalendario, setFechaCalendario] = useState([]);
  const [mesActual, setMesActual] = useState(0);
  const [textoMes, setTextoMes] = useState("");

  const [getCalendario, { data: calendarioData }] =
    calendarioApi.useLazyGetCalendarioQuery();

  const [eliminarCalendario] = calendarioApi.useDeleteCalendarioMutation();
  const [actualizarCalendario] = calendarioApi.useUpdateCalendarioMutation();
  const [aceptarInvitacionCalendario] =
    calendarioApi.useAcceptInvitationCalendarioMutation();

  useEffect(() => {
    getCalendario({
      sucursal: sucursalObtenida.refSucursal,
      mesActual: mesActual,
      usuario: user.rut,
    });
  }, [getCalendario, mesActual, sucursalObtenida.refSucursal, user.rut]);

  useEffect(() => {
    setFechaCalendario(calendarioData);
    if (calendarioData && calendarioData.length >= 1) {
      const textoMesAux = `${obtenerDiaMes(
        moment(calendarioData[0].fecha, "DD/MM/YYYY").format("MM")
      )} ${moment(calendarioData[0].fecha, "DD/MM/YYYY").format("YYYY")}`;
      setTextoMes(textoMesAux);
    }
  }, [calendarioData]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      return;
    } else if (moment(destination.droppableId, "DD/MM/YYYY").day() === 0) {
      return;
    } else {
      const elementoObtenido = fechaCalendario.filter(
        (e) => e.fecha === source.droppableId
      )[0];

      if (elementoObtenido) {
        const elementoActualizar = elementoObtenido.actividades[source.index];

        if (user.rut === elementoActualizar.refEmpleado) {
          actualizarCalendario({
            id: elementoActualizar.id,
            body: {
              nombreCita: elementoActualizar.nombreCita,
              link: elementoActualizar.link,
              urlArchivo: elementoActualizar.urlArchivo,
              fecha: destination.droppableId,
              privado: elementoActualizar.privado,
              estado: elementoActualizar.estado,
              horaInicio: elementoActualizar.horaInicio,
              horaTermino: elementoActualizar.horaTermino,
            },
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Evento",
                action: "actualizado",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        } else {
          return;
        }
      } else {
        return;
      }
    }
  };

  const eliminarDatos = (id) => {
    AlertaModal({
      title: "eliminar el evento",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          eliminarCalendario(id)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Evento",
                action: "eliminado",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  const aceptarInvitacion = (id) => {
    AlertaModal({
      title: "aceptar la invitación el evento",
      confirmButtonText: "Sí, aceptar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          aceptarInvitacionCalendario({
            refEmpleado: user.rut,
            refCalendario: id,
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Invitación",
                action: "aceptada",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  return (
    <>
      {value === 0 && (
        <div>
          <Grid container marginTop="10px" marginBottom="10px">
            <Grid item xs={4} md={4}>
              <Button
                variant="contained"
                onClick={() => {
                  setMesActual(mesActual - 1);
                }}
              >
                <ArrowBack />
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setMesActual(0);
                }}
              >
                HOY
              </Button>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography variant="h3" align="center">
                {textoMes}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Stack direction="row-reverse">
                <Button
                  onClick={() => {
                    setMesActual(mesActual + 1);
                  }}
                  variant="contained"
                >
                  <ArrowForward />
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <DragDropContext onDragEnd={onDragEnd}>
            <Card>
              {fechaCalendario && (
                <Grid container spacing={1} minHeight="300px">
                  {fechaCalendario &&
                    fechaCalendario.length >= 1 &&
                    fechaCalendario.map((item) => {
                      return (
                        <ColumnaDia
                          key={item.fecha}
                          dia={item.fecha}
                          actividades={item.actividades}
                          estado={item.estado}
                          compartido={false}
                          eliminarDatos={eliminarDatos}
                          aceptarInvitacion={aceptarInvitacion}
                        />
                      );
                    })}
                </Grid>
              )}
            </Card>
          </DragDropContext>

          {showAlert && (
            <Alerta
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              color={showAlert.color}
              message={showAlert.mensaje}
            />
          )}
        </div>
      )}
    </>
  );
}
