import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  Grid,
  CircularProgress,
  TablePagination,
} from "@material-ui/core";
import Scrollbar from "../Scrollbar";
import { useSelector } from "react-redux";
import { LoadingButton } from "@material-ui/lab";
import { AlertaToast } from "../AlertaSwall";
import { dteApi } from "src/services/dteApi";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import { facturasApi } from "src/services/facturasApi";
import { fPercent } from "src/utils/formatNumber";

export default function ProcesoFacturacionPlanesMensuales(props) {
  const {
    planesMensuales,
    periodoFacturacion,
    recargarPeriodo,
    setDesactivado,
  } = props;
  const [valorTotalFacturas, setValorTotalFacturas] = useState(0);
  const [locale] = useState("esES");
  const rowsPerPage = 30;
  const [page, setPage] = useState(0);
  const [facturado, setFacturado] = useState(false);
  const [facturando, setFacturando] = useState(false);

  const [paginaActualFacturar, setPaginaActualFacturar] = useState(0);
  const paginasTotalFacturar = Math.ceil(planesMensuales.length / 30);
  const [generarDtes] = dteApi.useGenerarDTEMasivoExentoMutation();
  const [facturarPorPartes] = facturasApi.useAddFacturarPorPartesMutation();

  useEffect(() => {
    if (periodoFacturacion?.paginaActualMes) {
      setPaginaActualFacturar(periodoFacturacion.paginaActualMes);
    }
  }, [periodoFacturacion]);

  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const sucursal = sucursalObtenida.refSucursal;

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;

    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  useEffect(() => {
    let suma = 0;
    planesMensuales.forEach((e) => {
      suma = suma + e.valorTotal;
    });
    setValorTotalFacturas(suma);
  }, [planesMensuales]);

  useEffect(() => {
    const planesPorFacturar = planesMensuales.filter(
      (plan) => Number(plan.folio) === 0
    );
    if (planesPorFacturar.length > 0) {
      setFacturado(false);
    } else {
      if (planesMensuales[0]?.folio) {
        setFacturado(true);
      } else {
        setFacturado(false);
      }
    }
  }, [planesMensuales]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const facturar = async () => {
    setFacturando(true);
    setDesactivado(true);
    try {
      await facturarPorPartes({
        sucursal: sucursal,
        body: {
          tipoFactura: "MES",
          facturas: planesMensuales.slice(
            paginaActualFacturar * rowsPerPage,
            paginaActualFacturar * rowsPerPage + rowsPerPage
          ),
          esUltimoTramoFacturacion:
            paginaActualFacturar === paginasTotalFacturar - 1,
          totalPaginasAFacturar: paginasTotalFacturar,
        },
      });
      await recargarPeriodo();
      setFacturando(false);
    } catch {
      setFacturando(false);
      setFacturado(false);
      AlertaToast({
        action: "Error al facturar",
        fullText: true,
        type: "error",
      }).fire();
    }
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>
          <Typography variant="h3">
            Valor total a facturar: ${formatNumber(valorTotalFacturas)}
          </Typography>
        </Grid>
        <Grid item xs={2} md={2}>
          {/* {valorTotalFacturas > 0 && (
            <LoadingButton
              disabled={
                periodoFacturacion.estado === "FACTURANDO"
                  ? true
                  : periodoFacturacion.cantidadErrorMes
                  ? periodoFacturacion.cantidadErrorMes <= 0
                    ? true
                    : false
                  : false
              }
              loading={cargandoFolio}
              fullWidth
              onClick={() => {
                AlertaModal({
                  title: "obtener folios",
                  confirmButtonText: "Sí, obtener",
                  target: document.getElementById("dialogProcesoFacturacion"),
                })
                  .fire()
                  .then((result) => {
                    if (result.isConfirmed) {
                      setCargandoFolio(true);
                      generarDtes({
                        sucursal: sucursal,
                        id: sucursal,
                        tipoFactura: "MES",
                      })
                        .unwrap()
                        .then((res) => {
                          recargarPeriodo();
                          AlertaModal2({
                            title:
                              "Los folios se estan procesando, debe esperar unos segundos.",
                            fullText: true,
                            target: document.getElementById(
                              "dialogProcesoFacturacion"
                            ),
                          })
                            .fire()
                            .then((result) => {});
                        })
                        .catch(() => {
                          AlertaToast({
                            action: "Error en el servidor",
                            fullText: true,
                            type: "error",
                            target: document.getElementById(
                              "dialogProcesoFacturacion"
                            ),
                          }).fire();
                        })
                        .finally(() => {
                          setCargandoFolio(false);
                        });
                    }
                  });
              }}
              variant="contained">
              Obtener Folios
            </LoadingButton>
          )} */}
        </Grid>
        <Grid item xs={2} md={2}>
          {valorTotalFacturas > 0 && (
            <LoadingButton
              variant="contained"
              loading={
                facturando || periodoFacturacion?.estado === "FACTURANDO"
              }
              disabled={
                facturado || periodoFacturacion?.estado === "FINALIZADO"
                  ? true
                  : periodoFacturacion?.estado === "FACTURANDO"
                  ? true
                  : periodoFacturacion?.cantidadErrorMes
                  ? periodoFacturacion?.cantidadErrorMes <= 0
                    ? true
                    : false
                  : false
              }
              onClick={() => facturar()}
            >
              {facturado
                ? "Facturado"
                : `Facturar ${
                    paginaActualFacturar + 1
                  }/${paginasTotalFacturar} páginas`}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Folio</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Tipo Moneda</TableCell>
                <TableCell>Descuento</TableCell>
                <TableCell>Fecha Término Descuento</TableCell>
                <TableCell>Valor Con Descuento</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Valor ($)</TableCell>
                <TableCell>Valor Fijo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {planesMensuales
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((historyRow) => {
                  return (
                    <TableRow
                      key={historyRow.id}
                      style={
                        historyRow.facturado === 1
                          ? { backgroundColor: "#C8FACD" }
                          : historyRow.facturado === -1
                          ? { backgroundColor: "#FFE7D9" }
                          : {}
                      }
                    >
                      <TableCell align="left">
                        <Typography variant="caption">
                          {periodoFacturacion && periodoFacturacion.estado ? (
                            periodoFacturacion.estado === "INICIADO" ? (
                              "ESPERANDO INICIO FACTURACIÓN"
                            ) : periodoFacturacion.estado === "FINALIZACION" ||
                              Number(historyRow.folio) !== 0 ? (
                              historyRow.folio
                            ) : periodoFacturacion.estado === "FACTURANDO" ? (
                              <CircularProgress />
                            ) : historyRow.error ? (
                              "ERROR AL GENERAR, VOLVER A INTENTAR..."
                            ) : (
                              <CircularProgress />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle2">
                          {historyRow.nombreCliente}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {historyRow.nombrePlan}
                      </TableCell>
                      <TableCell align="left">
                        {historyRow.tipoMoneda === "PESOS"
                          ? formatNumber(historyRow.valor)
                          : historyRow.valor}
                      </TableCell>
                      <TableCell align="left">
                        {historyRow.tipoMoneda}
                      </TableCell>
                      <TableCell align="left">
                        {fPercent(historyRow.descuento)}
                      </TableCell>
                      <TableCell align="left">
                        {historyRow.fechaTerminoDesc}
                      </TableCell>
                      <TableCell align="left">
                        {historyRow.tipoMoneda === "PESOS"
                          ? formatNumber(historyRow.valorDesc)
                          : historyRow.valorDesc}
                      </TableCell>
                      <TableCell align="left">{historyRow.cantidad}</TableCell>
                      <TableCell align="left">
                        {historyRow.valorTotal &&
                          formatNumber(historyRow.valorTotal)}
                      </TableCell>
                      <TableCell align="left">{historyRow.valorFijo}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[30]}
            component="div"
            count={planesMensuales.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Scrollbar>
    </React.Fragment>
  );
}
