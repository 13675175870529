import React, { useState, useEffect } from "react";

import TablaVacaciones from "./TablaVacaciones";
import { API_SERVER } from "../../../utils/urlBack_End";
import axios from "axios";
import {
  comingSoonStyles,
  ComingSoonWrapper,
} from "src/components/coming-soon-screen/coming-soon-wrapper";

export default function Vacaciones(props) {
  const {
    valueLaboral,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage,
    vista,
  } = props;
  const [vacaciones, setVacaciones] = useState([]);
  const [vacacionesSolicitadas, setVacacionesSolicitadas] = useState(0);
  const [vacacionesDisponibles, setVacacionesDisponibles] = useState(0);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/vacaciones/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        const respuesta = response.data;
        setVacaciones(respuesta.vacaciones);
        setVacacionesSolicitadas(respuesta.vacacionesSolicitadas);
        setVacacionesDisponibles(respuesta.vacacionesDisponibles);
      });
  }, [empleado.rut]);

  return (
    <>
      {valueLaboral === 0 && (
        <ComingSoonWrapper style={comingSoonStyles.CARD}>
          <TablaVacaciones
            vacaciones={vacaciones}
            setVacaciones={setVacaciones}
            vacacionesSolicitadas={vacacionesSolicitadas}
            setVacacionesSolicitadas={setVacacionesSolicitadas}
            vacacionesDisponibles={vacacionesDisponibles}
            setVacacionesDisponibles={setVacacionesDisponibles}
            empleado={empleado}
            setShowMessagePermiso={setShowMessagePermiso}
            setColor={setColor}
            setMessage={setMessage}
            vista={vista}
          />
        </ComingSoonWrapper>
      )}
    </>
  );
}
