import React, { useState, useEffect, useCallback } from "react";
import Page from "../../components/Page";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Container from "@material-ui/core/Container";
import Stack from "@material-ui/core/Stack";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Alert from "@material-ui/core/Alert";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import Circle from "@uiw/react-color-circle";

import SimpleModal from "../../layouts/SimpleModal";
import MyConfirmationDialog from "../MyConfirmationDialog";
import { Alerta } from "../Alerta";
import { API_SERVER } from "../../utils/urlBack_End";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

const colors = [
  "#4db0ec",
  "#FBFA93",
  "#8C92AC",
  "#F0B365",
  "#AB9F91",
  "#C6f68d",
  "#E66360",
];

export default function Etiqueta() {
  const [etiquetas, setEtiquetas] = useState([]);
  const [showCreateTag, setShowCreateTag] = useState(false);
  const [newName, setNewName] = useState("");
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#E66360");
  const [showAlert, setShowAlert] = useState(false);
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [addHistorialAcciones, user.rut]);

  const fetchData = useCallback(async function () {
    try {
      const { data } = await axios.get(`${API_SERVER}/ajustes/etiquetas`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (data === -2) window.location.reload();
      setEtiquetas([...data]);
    } catch (ex) {
      setError("Ha ocurrido un error al comunicarse con la base de datos.");
      setShowError(true);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteItem = (id) => {
    setShowRemovalConfirmation(true);
    setSelectedId(id);
  };

  const doDeleteItem = () => {
    setLoading(true);
    const backup = [...etiquetas];
    const filtered = etiquetas.filter((e) => e.nombreEtiqueta !== selectedId);
    setEtiquetas(filtered);
    setShowRemovalConfirmation(false);
    deleteTagFromDB(backup);
  };

  const handleSubmit = () => {
    if (!newName) return setShowAlert(true);
    if (
      etiquetas.find((e) => e.nombreEtiqueta === newName.trim().toUpperCase())
    ) {
      setError("Ya existe una etiqueta con este nombre.");
      setShowError(true);
      return;
    }
    const backup = [...etiquetas];
    addItem(newName, color);
    setNewName("");
    setColor("#000000");
    setShowCreateTag(false);
    createTagInDB(backup);
  };

  const createTagInDB = async (backup) => {
    try {
      const response = await axios.post(
        `${API_SERVER}/ajustes/etiquetas`,
        {
          nombreEtiqueta: newName.toUpperCase(),
          color,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data === -2) window.location.reload();
    } catch (ex) {
      setError("No se pudo guardar la nueva etiqueta en la base de datos.");
      setShowError(true);
      setEtiquetas(backup);
    }
  };

  const deleteTagFromDB = async (backup) => {
    try {
      const response = await axios.delete(
        `${API_SERVER}/ajustes/etiquetas/${selectedId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data === -2) window.location.reload();
    } catch (ex) {
      setError("No se pudo eliminar la etiqueta de la base de datos.");
      setShowError(true);
      setEtiquetas(backup);
    } finally {
      setLoading(false);
    }
  };

  const addItem = (nombre, color) => {
    setEtiquetas((prev) => [
      ...prev,
      { nombreEtiqueta: nombre.toUpperCase(), color },
    ]);
  };

  return (
    <Page title="TAXTIC">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}>
          <Typography variant="h4" gutterBottom>
            Etiquetas
          </Typography>
        </Stack>

        <Card>
          <Box display="flex" flexDirection="row-reverse" m={2}>
            <Button onClick={() => setShowCreateTag(true)} variant="contained">
              Nueva Etiqueta
            </Button>
          </Box>

          <Box display="flex" flexDirection="row-reverse" m={2}>
            <Alert color="info" severity="info">
              Agregue etiquetas para organizar sus tareas. De esta manera, podrá
              utilizar el buscador asociado a las tablas para desplegar aquellas
              tareas que coincidan con la etiqueta buscada.
            </Alert>
          </Box>

          <nav aria-label="secondary mailbox folders">
            {etiquetas && etiquetas.length > 0 && (
              <List>
                {etiquetas.map((e, idx) => (
                  <ListItem key={idx}>
                    <div
                      style={{
                        backgroundColor: e.color,
                        width: 15,
                        height: 15,
                        marginRight: 15,
                        borderRadius: 7,
                        border: "1px solid",
                      }}
                    />
                    <ListItemText primary={e.nombreEtiqueta} />
                    <ListItemIcon style={{ cursor: "pointer" }}>
                      <Tooltip title="Eliminar">
                        <DeleteIcon
                          disabled={loading}
                          color="error"
                          onClick={() => handleDeleteItem(e.nombreEtiqueta)}
                        />
                      </Tooltip>
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            )}
          </nav>
        </Card>

        <MyConfirmationDialog
          open={showRemovalConfirmation}
          onSubmit={() => doDeleteItem()}
          onClose={() => setShowRemovalConfirmation(false)}
          title="Eliminar Etiqueta"
          content="¿Está seguro de eliminar esta etiqueta?"
        />

        <Alerta
          showAlert={showError}
          setShowAlert={setShowError}
          message={error}
          color="error"
        />

        <SimpleModal
          onClose={() => setShowCreateTag(false)}
          onSubmit={handleSubmit}
          open={showCreateTag}
          title="Nueva Etiqueta">
          {showAlert && (
            <Alert severity="error">Este campo es obligatorio</Alert>
          )}
          <TextField
            label="Nombre"
            variant="outlined"
            fullWidth
            value={newName}
            onChange={({ target: t }) => {
              if (showAlert) setShowAlert(false);
              setNewName(t.value);
            }}
          />

          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <Box mb={2}>
              <FormLabel>Color</FormLabel>
            </Box>

            <Box>
              <Circle
                colors={colors}
                color={color}
                onChange={(color) => {
                  setColor(color.hex);
                }}
              />
            </Box>
          </Box>
        </SimpleModal>
      </Container>
    </Page>
  );
}
