import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";
import { LoadingButton } from "@material-ui/lab";
import { formatoFecha } from "../../utils/formatNumber";
import { useSelector } from "react-redux";

export default function AgregarPortafolio(props) {
  const { open, setOpen, portafolio, cliente, setPortafolios } = props;
  const { empleado: user } = useSelector((state) => state.auth.user);

  let fechaActual = formatoFecha();

  const [loadinGuardar, setLoadingGuardar] = useState(false);

  const [descripcion, setDescripcion] = useState(
    portafolio ? portafolio.descripcion : ""
  );

  const handleChangeDescripcion = (e) => {
    setDescripcion(e.target.value);
  };

  const handleClose = () => {
    window.location.reload();
    setOpen(false);
  };

  const guardar = () => {
    setLoadingGuardar(true);
    if (portafolio) {
      axios
        .put(`${API_SERVER}/clientes/portafolio`, {
          id: portafolio.id,
          descripcion: descripcion,
          fechaEntrada: fechaActual,
          empleadoIngresaPortafolio: user.nombre + " " + user.apellidos
        },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem("token")
            }
          })
        .then((res) => {

          axios
            .get(`${API_SERVER}/clientes/portafolio/${cliente.rut}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              if (res.data === -2) window.location.reload();

              if (Array.isArray(res.data)) {
                setPortafolios(res.data);
              }
              setLoadingGuardar(false);
            })
            .catch(() => {
              setLoadingGuardar(false);
            });
        })
        .catch(() => {
          setLoadingGuardar(false);
        });
    } else {
      axios
        .post(
          `${API_SERVER}/clientes/portafolio`,
          {
            refCliente: cliente.rut,
            descripcion: descripcion,
            empleadoIngresaPortafolio: user.nombre + " " + user.apellidos,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data === -2) window.location.reload();

          axios
            .get(`${API_SERVER}/clientes/portafolio/${cliente.rut}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              if (res.data === -2) window.location.reload();

              if (Array.isArray(res.data)) {
                setPortafolios(res.data);
              }
              setLoadingGuardar(false);
            })
            .catch(() => {
              setLoadingGuardar(false);
            });
        })
        .catch(() => {
          setLoadingGuardar(false);
        });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Nuevo Portafolio</DialogTitle>
      <DialogContent>
        <TextField
          style={{ marginTop: "10px" }}
          label="Descripción"
          fullWidth
          multiline
          rows={4}
          value={descripcion}
          onChange={handleChangeDescripcion}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="error">
          Cerrar
        </Button>
        <LoadingButton loading={loadinGuardar} onClick={guardar}>
          Crear
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
