import React, { useState } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { saveAs } from "file-saver";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Alert,
} from "@material-ui/core";
import axios from "axios";
import {
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
} from "../../utils/urlBack_End";
import DownloadIcon from "@material-ui/icons/Download";
import { useSelector } from "react-redux";
import { obtenerValorMoneda } from "src/utils/sharedFunctions";

export default function ObtenerFacturaDialog(props) {
  const {
    open,
    setOpen,
    tarea,
    setFacturas,
    setValorTareaPesos,
    monedas,
    setTotalFacturadoEntrada,
  } = props;
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  let fechaActual = new Date();
  const [cargandoAgregar, setCargandoAgregar] = useState(false);
  const [fechaIngreso, setFechaIngreso] = useState(fechaActual);
  const [folio, setFolio] = useState("");
  const [intentos, setIntentos] = useState(5);
  const [mensajeAlerta, setMensajeAlerta] = useState(false);
  const [facturaObtenida, setFacturaObtenida] = useState(false);
  const [loadingDescargar, setLoadingDescargar] = useState(false);

  const handleChangeFolio = (event) => {
    setFolio(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const guardar = () => {
    setCargandoAgregar(true);
    axios
      .post(
        `${API_SERVER}/facturas/tareas`,
        {
          idTarea: tarea.id,
          folio: facturaObtenida.Folio,
          tokenFactura: facturaObtenida.Token,
          refCliente: tarea.refCliente,
          refEmpleado: tarea.refEmpleado,
          sucursal: tarea.sucursal,
          vendedor: tarea.vendedor,
          descuento: tarea.descuento,
          valorTarea: tarea.valorTarea,
          tipoMonedaTarea: tarea.tipoMonedaTarea,
          supervisor: tarea.supervisor,
          totalFactura: facturaObtenida.MntTotal,
          montoPagado: "NO",
          metodoDePago: "TRANSFERENCIA",
          empleadoRecibePago: user.nombre + " " + user.apellidos,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((respFactura) => {
        if (respFactura.data === -2) window.location.reload();
        axios
          .get(
            `${API_SERVER}/facturas/tareas/${sucursalObtenida.refSucursal}/${tarea.id}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(function (responseFactura) {
            if (responseFactura.data === -2) window.location.reload();
            let arregloFactura = responseFactura.data;
            let totalFacutadoTareas = 0;

            arregloFactura.forEach((element) => {
              if (element.total) {
                totalFacutadoTareas =
                  totalFacutadoTareas + parseFloat(element.total);
              }
            });

            const { tipoMonedaTarea, valorTarea } = tarea;
            const valorTareaEnPesos =
              tipoMonedaTarea === "PESOS"
                ? valorTarea
                : obtenerValorMoneda(monedas, tipoMonedaTarea) * valorTarea;

            if (valorTareaEnPesos === null || valorTareaEnPesos === undefined) {
              console.error("Error al obtener las monedas");
              throw Error("Error al obtener las monedas");
            }

            setValorTareaPesos(valorTareaEnPesos);
            setTotalFacturadoEntrada(totalFacutadoTareas);
            setFacturas(arregloFactura);
          })
          .finally(() => {
            setCargandoAgregar(false);
            setOpen(false);
          });
      })
      .catch(() => {
        setMensajeAlerta("Error al crear la factura.");
        setCargandoAgregar(false);
        setOpen(false);
      });
  };

  const agregar = () => {
    setMensajeAlerta(false);
    if (folio === "" || folio === " ") {
      setMensajeAlerta("Ingresar Folio");
    } else if (intentos === 0) {
      setMensajeAlerta(
        "No se ha encontrado el folio ingresado para la fecha indicada."
      );
    } else {
      setCargandoAgregar(true);
      let fechaActual = new Date(fechaIngreso);
      let diaActual = "";
      let mesActual = fechaActual.getMonth() + 1;
      if (fechaActual.getDate() === 1) {
        diaActual = "01";
      } else if (fechaActual.getDate() === 2) {
        diaActual = "02";
      } else if (fechaActual.getDate() === 3) {
        diaActual = "03";
      } else if (fechaActual.getDate() === 4) {
        diaActual = "04";
      } else if (fechaActual.getDate() === 5) {
        diaActual = "05";
      } else if (fechaActual.getDate() === 6) {
        diaActual = "06";
      } else if (fechaActual.getDate() === 7) {
        diaActual = "07";
      } else if (fechaActual.getDate() === 8) {
        diaActual = "08";
      } else if (fechaActual.getDate() === 9) {
        diaActual = "09";
      } else {
        diaActual = fechaActual.getDate();
      }

      if (mesActual === 1) {
        mesActual = "01";
      } else if (mesActual === 2) {
        mesActual = "02";
      } else if (mesActual === 3) {
        mesActual = "03";
      } else if (mesActual === 4) {
        mesActual = "04";
      } else if (mesActual === 5) {
        mesActual = "05";
      } else if (mesActual === 6) {
        mesActual = "06";
      } else if (mesActual === 7) {
        mesActual = "07";
      } else if (mesActual === 8) {
        mesActual = "08";
      } else if (mesActual === 9) {
        mesActual = "09";
      }
      let fechaFinal =
        fechaActual.getFullYear() + "-" + mesActual + "-" + diaActual;
      axios
        .get(
          `${API_SERVER}/facturas/facturaOpenFactura/${String(
            fechaFinal
          )}/${folio}/34/${sucursalObtenida.refSucursal}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (isObjectEmpty(response.data)) {
            let intentosRestantes = intentos - 1;
            setIntentos(intentosRestantes);
            setMensajeAlerta(
              "Folio no encontrado. \n Intentos disponibles: " +
                intentosRestantes
            );
          } else {
            setFacturaObtenida(response.data);
          }
        })
        .catch(() => {
          let intentosRestantes = intentos - 1;
          setIntentos(intentosRestantes);
          setMensajeAlerta(
            "Folio no encontrado. \n Intentos disponibles: " + intentosRestantes
          );
        })
        .finally(() => {
          setCargandoAgregar(false);
        });
    }
  };

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Obtener Factura Segun Folio</DialogTitle>
      <DialogContent>
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DatePicker
              label="Fecha Emisión Documento"
              views={["year", "month", "day"]}
              maxDate={new Date()}
              value={fechaIngreso}
              onChange={(newValue) => {
                setFechaIngreso(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={params?.inputProps?.placeholder}
                  fullWidth
                  style={{ marginTop: "10px" }}
                />
              )}
            />
          </LocalizationProvider>

          <TextField
            label="Número de Folio"
            variant="outlined"
            style={{ marginTop: "10px" }}
            onChange={handleChangeFolio}
            value={folio}
          />

          {facturaObtenida && facturaObtenida.Token && (
            <LoadingButton
              style={{ marginTop: "10px" }}
              color="primary"
              loading={loadingDescargar}
              onClick={() => {
                setLoadingDescargar(true);
                axios
                  .get(
                    `${API_OPEN_FACTURA_PROD}/v2/dte/document/${facturaObtenida.Token}/pdf`,
                    {
                      headers: {
                        apikey: API_KEY_OPEN_FACTURA_PROD,
                      },
                    }
                  )
                  .then((response) => {
                    saveAs(
                      "data:application/pdf;base64," + response.data.pdf,
                      facturaObtenida.Folio + ".pdf"
                    );
                    setLoadingDescargar(false);
                  })
                  .catch(() => {
                    setLoadingDescargar(false);
                  });
              }}
              variant="contained"
            >
              <DownloadIcon style={{ marginRight: "0.5rem" }} />
              {}
              Descargar Factura
            </LoadingButton>
          )}
        </Stack>
      </DialogContent>
      {mensajeAlerta && <Alert severity="error">{mensajeAlerta}</Alert>}

      <DialogActions>
        <LoadingButton onClick={handleClose} loading={cargandoAgregar}>
          Cancelar
        </LoadingButton>
        {facturaObtenida && facturaObtenida.Token ? (
          <LoadingButton
            onClick={guardar}
            autoFocus
            loading={cargandoAgregar}
            variant="contained"
          >
            Guardar Factura
          </LoadingButton>
        ) : (
          <LoadingButton
            onClick={agregar}
            autoFocus
            loading={cargandoAgregar}
            variant="contained"
          >
            Obtener Factura
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
