import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const subtareasApi = createApi({
  reducerPath: "subtareasApi",
  baseQuery: baseQuery(`${API_SERVER}/subtareas`),
  tagTypes: ["Subtareas"],
  endpoints: (builder) => ({
    getSubtareasTarea: builder.query({
      query: (id) => `/tarea/${id}`,
      providesTags: ["Subtareas"],
    }),
    addCrearNuevoTiempo: builder.mutation({
      query: (body) => ({
        url: `/historialTiempo`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Subtareas"],
    }),
    addSubtarea: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Subtareas"],
    }),
    updateEstadoSubtarea: builder.mutation({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Subtareas"],
    }),
    updateMostrarArchivo: builder.mutation({
      query: (body) => ({
        url: `/mostrarArchivo`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Subtareas"],
    }),
    updateSubtarea: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Subtareas"],
    }),
    deleteArchivo: builder.mutation({
      query: (id) => ({
        url: `/archivo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subtareas"],
    }),
    deleteSubtarea: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subtareas"],
    }),
  }),
});

export const {
  useGetSubtareasTareaQuery,
  useLazyGetSubtareasTareaQuery,
  useAddCrearNuevoTiempoMutation,
  useUpdateEstadoSubtareaMutation,
  useDeleteArchivoMutation,
  useUpdateMostrarArchivoMutation,
  useDeleteSubtareaMutation,
  useAddSubtareaMutation,
  useUpdateSubtareaMutation,
} = subtareasApi;
