import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { formatAsCLP } from "src/utils/formatNumber";

const TablaRecaudacionMensual = ({
  tabIndex,
  data: recaudaciones,
  loading: isLoadingRecaudaciones,
}) => {
  if (tabIndex !== 1) return null;

  const chart = {
    series: [
      {
        name: "Recaudado",
        data: recaudaciones.map((recaudacion) => ({
          x: recaudacion.x, // Corresponde al nombre de la sucursal
          y: recaudacion.y, // Corresponde a lo que se ha recaudado
          goals: [
            {
              name: "Total facturado",
              value: recaudacion.goals, // Corresponde al total de lo facturado
              strokeHeight: 5,
              strokeColor: "#1DA389",
            },
          ],
        })),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      title: {
        text: "Total",
        align: "left",
      },
      colors: ["#ff802e"],
      dataLabels: {
        enabled: true,
        position: "top",
        offsetY: -25,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          return formatAsCLP(value);
        },
        style: {
          fontSize: "13px",
          fontWeight: 700,
          colors: ["black"],
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Recaudado", "Total facturado"],
        markers: {
          fillColors: ["#ff802e", "#1DA389"],
        },
      },
      annotations: {
        points: recaudaciones.map((recaudacion) => ({
          x: recaudacion.x,
          y: recaudacion.goals,
          marker: {
            size: 5,
            fillColor: "#1DA389",
            strokeWidth: 0,
            strokeColor: "#fff",
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
          },
          label: {
            borderColor: "#1DA389",
            offsetY: -5,
            style: {
              color: "#fff",
              background: "#1DA389",
              fontSize: "12px",
            },
            text: `${recaudacion.x}: ${formatAsCLP(recaudacion.goals)}`,
          },
        })),
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (value) => {
            if (typeof value !== "undefined") {
              return `${formatAsCLP(value.toFixed(0))}`;
            }
            return value;
          },
        },
      },
      noData: {
        text: "No hay datos disponibles",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontWeight: 700,
          fontFamily: undefined,
        },
      },
    },
  };

  return (
    <Card>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop={1}
        spacing={2}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Typography variant="h3" align="center">
            Recaudación sucursales
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      {isLoadingRecaudaciones ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box dir="ltr" margin={2}>
          <ReactApexChart
            height={400}
            options={chart.options}
            series={chart.series}
            type="bar"
          />
        </Box>
      )}
    </Card>
  );
};

export default TablaRecaudacionMensual;
