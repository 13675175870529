// material
import React, { useState, useEffect } from "react";
import { Container, Typography, AppBar, Tabs, Tab } from "@material-ui/core";
import { Titulo } from "src/components/Titulo";
// components
import Page from "../components/Page";
import Planes from "./Planes";
import TablaPlanExtendido from "src/components/planes/TablaPlanExtendido";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
// ----------------------------------------------------------------------

export default function TabsPlanes() {
  const [value, setValue] = useState(0);
  const { empleado: user } = useSelector((state) => state.auth.user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo style={{ marginBottom: "-3rem" }}>
          <Typography variant="h4" gutterBottom>
            Planes
          </Typography>
        </Titulo>

        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="full width tabs example">
              <Tab label="Contribuyentes" />
              <Tab label="Contribuyentes según planes" />
            </Tabs>
          </AppBar>

          <Planes value={value} />

          <TablaPlanExtendido value={value} />
        </div>
      </Container>
    </Page>
  );
}
