import React, { useState, useEffect } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CardActionArea,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import DownloadIcon from "@material-ui/icons/Download";
import axios from "axios";
import { dbx } from "../../utils/urlBack_End";
import { API_SERVER } from "../../utils/urlBack_End";
import { AlertaMensajeEliminacion } from "../AlertaMensajeEliminacion";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  hidden: {
    display: "none",
  },
}));
// ----------------------------------------------------------------------

export default function ArchivosAdjuntosModal(props) {
  const { setShowError, setError, tarea, onClose } = props;
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState(null);

  const [mostrarAlertaEliminacion, setMostrarAlertaEliminacion] =
    useState(false);
  const [elementoEliminar, setElementoEliminar] = useState(null);

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }
    setFile(fileAux);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const removeFile = async (nombre, idArchivo) => {
    setLoading(true);
    try {
      dbx
        .filesDeleteV2({ path: `/Tareas/${tarea.id}/${nombre}` })
        .then(() => {
          const query = [
            `${API_SERVER}/tareas/archivos/${idArchivo}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            },
          ];

          axios.delete(query[0], query[1]).then((response) => {
            if (response.data === -2) window.location.reload();
            axios
              .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((data) => {
                if (data.data === -2) window.location.reload();
                setArchivos(data.data);
              })
              .catch((error) => {});
          });
        })
        .catch(() => {
          setError("No se pudo eliminar el archivo adjunto.");
          setShowError(true);
        });
    } catch (ex) {
      setError("No se pudo eliminar el archivo adjunto.");
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const updateTaskInDB = async (task) => {
    const update = {
      nombreArchivo: task.nombreArchivo,
      tipoArchivo: task.tipoArchivo,
      link: task.link,
      idTarea: task.idTarea,
    };

    const query = [
      `${API_SERVER}/tareas/archivos`,
      {
        ...update,
      },
    ];
    try {
      const response = await axios.post(query[0], query[1], {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data === -2) window.location.reload();
      axios
        .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((data) => {
          if (data.data === -2) window.location.reload();
          setArchivos(data.data);
        })
        .catch((error) => {});
    } catch (ex) {
      setError("No se pudo actualizar la tarea en la base de datos.");
      setShowError(true);
    }
  };

  async function uploadFile() {
    setLoading(true);
    if (!file) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      setError("El tamaño máximo de un archivo es de 150 MB.");
      setShowError(true);
      return;
    }

    try {
      await dbx.filesUpload({
        path: "/Tareas/" + tarea.id + "/" + file.name,
        contents: file,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: "/Tareas/" + tarea.id + "/" + file.name,
        });
        let url = shareResponse.result.url;

        let tareaAux = {
          nombreArchivo: file.name,
          tipoArchivo: file.type,
          link: url,
          idTarea: tarea.id,
        };
        updateTaskInDB(tareaAux);
      } catch (ex) {
        setError("El archivo ya existe en el sistema.");
      } finally {
        setLoading(false);
      }
    } catch (ex) {
      setError("Ha ocurrido un error que impidió guardar los cambios.");
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  useEffect(() => {
    dbx
      .filesListFolder({ path: `/Tareas/${tarea.id}` })
      .then((respuesta) => {
        if (respuesta.result.entries) {
          axios
            .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((data) => {
              if (data.data === -2) window.location.reload();
              setArchivos(data.data);
            })
            .catch((error) => {});
        }
      })
      .catch((e) => {
        dbx
          .filesCreateFolderV2({ path: `/Tareas/${tarea.id}` })
          .then((response) => {})
          .catch((error) => {});
      });
  }, [tarea.id]);

  return (
    <Container>
      <RootStyle>
        <Card style={{ padding: "1rem" }}>
          <Alert severity="info">
            Haga click en el botón 'Seleccionar Archivo' para subir un documento
            asociado a esta tarea.
          </Alert>

          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={4} md={4}>
              <input
                className={classes.hidden}
                id="contained-button-file"
                onChange={handleFileInput}
                type="file"
              />

              <label htmlFor={"contained-button-file"}>
                <Button fullWidth component="span" m={1} variant="contained">
                  Seleccionar Archivo
                </Button>
              </label>
            </Grid>
            <Grid item xs={6} md={6}>
              {file && (
                <span>
                  <span>
                    <> {file.name}</>
                  </span>

                  <span>
                    <IconButton onClick={handleDeleteFile} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </span>
              )}
            </Grid>
            <Grid item xs={2} md={2}>
              <LoadingButton
                fullWidth
                loading={loading}
                onClick={uploadFile}
                variant="contained">
                Subir
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>

        <Card>
          <List className={classes.root}>
            {archivos &&
              archivos.map((value, idx) => {
                return (
                  <ListItem key={"archivos_" + idx}>
                    <ListItemText primary={value.nombreArchivo} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => {
                          window.open(value.link, "_blank");
                        }}>
                        <DownloadIcon />
                      </IconButton>
                      <LoadingButton
                        loading={loading}
                        onClick={() => {
                          setElementoEliminar(value);
                          setMostrarAlertaEliminacion(true);
                        }}>
                        <DeleteIcon />
                      </LoadingButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </Card>
        <CardActionArea>
          <Button
            fullWidth
            onClick={() => {
              onClose();
            }}
            variant="contained"
            color="inherit">
            Cerrar
          </Button>
        </CardActionArea>
        {mostrarAlertaEliminacion && elementoEliminar && (
          <AlertaMensajeEliminacion
            mensaje="¿Seguro que deseas eliminar el archivo?"
            open={mostrarAlertaEliminacion}
            setOpen={setMostrarAlertaEliminacion}
            aceptar={() => {
              removeFile(elementoEliminar.nombreArchivo, elementoEliminar.id);
            }}
            mensajeBotonAceptar={"Eliminar"}
          />
        )}
      </RootStyle>
    </Container>
  );
}
