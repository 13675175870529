import React from "react";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useField } from "formik";

const FormikSelect = ({
  label,
  helperText,
  options,
  shrink,
  loading = false,
  acceptNewElementsSelect = false,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        label={label}
        error={meta.touched && !!meta.error}
        disabled={loading}
        endAdornment={loading && <CircularProgress size={24} />}
        IconComponent={loading ? () => null : undefined}
      >
        {!loading &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        {!loading && acceptNewElementsSelect && (
          <MenuItem key={field.value} value={field.value}>
            {field.value}
          </MenuItem>
        )}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(FormikSelect);
