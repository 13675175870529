import React, { useState } from "react";
// import * as XLSX from "xlsx";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import moment from "moment/moment";
import { calcularDiasRetraso } from "src/utils/formatTime";
export default function ExportToExcelFacturasClientes(props) {
  const { data, filename, nombreBoton, cliente } = props;

  const [loadingExport, setLoadingExport] = useState(false);

  // function formatNumber(n) {
  //   n = n > 0 ? String(n).replace(/\D/g, "") : n;
  //   return n === "" ? n : Number(n).toLocaleString("es-CL");
  // }
  // const exportar = async () => {
  //   setLoadingExport(true);
  //   if (data?.length > 0) {
  //     let clientesCompletos = [];

  //     data.forEach((elementoObtenido) => {
  //       let valorPesos = 0.0;

  //       if (elementoObtenido.total) {
  //         valorPesos = elementoObtenido.total;
  //       } else {
  //         valorPesos = elementoObtenido.valorPesos;
  //       }
  //       let credenciales = [
  //         cliente.nombre,
  //         elementoObtenido.sucursal,
  //         elementoObtenido.folio,
  //         formatNumber(valorPesos),
  //         elementoObtenido.estadoFactura,
  //         elementoObtenido.fechaFacturacion,
  //         elementoObtenido.fechaPago,
  //       ];

  //       clientesCompletos.push(credenciales);
  //     });
  //     let worksheet = XLSX.utils.aoa_to_sheet([
  //       [
  //         "Cliente",
  //         "Sucursal",
  //         "Folio",
  //         "Total Facturado",
  //         "Estado",
  //         "Fecha Facturación",
  //         "Fecha Pago",
  //       ],
  //       ...clientesCompletos,
  //     ]);
  //     let new_workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName);
  //     XLSX.writeFile(new_workbook, filename + ".xlsx");
  //     setLoadingExport(false);
  //   } else {
  //     setLoadingExport(false);
  //   }
  // };

  const enableAutoCalculation = (workbook) => {
    workbook.calcProperties.fullCalcOnLoad = true;
    workbook.calcProperties.calcMode = "auto";
    workbook.calcProperties.calcOnSave = true;
  };
  const fillTemplate = async (templatePath, formatData) => {
    try {
      const workbook = new ExcelJS.Workbook();

      const templateBuffer = await fetch(templatePath).then((response) =>
        response.arrayBuffer()
      );
      await workbook.xlsx.load(templateBuffer);
      workbook.eachSheet((sheet) => {
        if (sheet.name === "NOMBRE DEL CLIENTE") {
          sheet.name = cliente.nombre;
        }
      });
      enableAutoCalculation(workbook);

      const regex = new RegExp("NOMBRE DEL CLIENTE", "gi");

      const worksheet = workbook.worksheets[0];

      worksheet.eachRow((row, _rowNumber) => {
        row.eachCell((cell) => {
          if (cell.value && typeof cell.value === "string") {
            const updatedValue = cell.value.replace(regex, cliente.nombre);
            cell.value = updatedValue;
          }
        });
      });

      const startRow = 6;
      formatData.forEach((row, index) => {
        const rowIndex = startRow + index;
        worksheet.insertRow(rowIndex, row, startRow);
      });

      const columnasFormulas = ["E", "F", "G"];

      columnasFormulas.forEach((columna, index) => {
        // const startCellRef = `${columna}${startRow}`;
        // const endCellRef = `${columna}${startRow + data.length - 1}`;
        // // const formula = `SUMA(${startCellRef}:${endCellRef})`;
        let total = 0;
        if (index === 0) {
          total = data.reduce((acumulador, factura) => {
            if (
              factura.estadoFactura === "PAGADO" ||
              factura.estadoFactura === "ABONO" ||
              factura.estadoFactura === "FACTURADO"
            ) {
              return acumulador + factura.valorPesos;
            } else {
              return acumulador + 0;
            }
          }, 0);
        } else if (index === 1) {
          total = data.reduce(
            (acumulador, factura) => acumulador + factura.abono,
            0
          );
        } else {
          total = data.reduce(
            (acumulador, factura) => acumulador + factura.deuda,
            0
          );
        }

        const nextRowIndex = startRow + data.length;
        const cell = worksheet.getCell(`${columna}${nextRowIndex}`);
        cell.value = total;
      });

      const columnasFormato = [5, 6, 7];

      columnasFormato.forEach((columna) => {
        const formatoCelda = '_-$* #.##0_-;-$* #.##0_-;_-$* "-"??_-;_-@_-';
        const range = worksheet.getColumn(columna);
        range.eachCell((cell) => {
          cell.numFmt = formatoCelda;
        });
      });

      return workbook;
    } catch (error) {
      console.error("Error al cargar el archivo Excel", error);
      throw error;
    }
  };

  const saveFile = async (workbook, fileName) => {
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, fileName);
  };

  const handleGenerateFile = async () => {
    const templatePath = "/static/plantillas/MODELO_ESTADO_DE_CUENTA.xlsx";

    function obtenerFecha(fecha) {
      if (fecha) {
        const formatosFecha = ["DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"];
        return moment(fecha, formatosFecha).format("DD-MM-YYYY");
      }
      return "-";
    }

    let arreglo = [...data];

    const formatData = arreglo
      .sort((a, b) => {
        let fechaA = new Date(a.fechaFacturacion);
        let fechaB = new Date(b.fechaFacturacion);
        return fechaA - fechaB;
      })
      .map((factura) => {
        const diasRetraso =
          factura.estadoFactura.toLowerCase() !== "pagado" &&
          factura.estadoFactura.toLowerCase() !== "anulado" &&
          factura.estadoFactura.toLowerCase() !== "incobrable"
            ? calcularDiasRetraso(factura.fechaFacturacion)
            : 0;

        const arr = [
          factura.tipoDte,
          Number(factura.folio),
          obtenerFecha(factura.fechaFacturacion),
          factura.estadoFactura,
          factura.valorPesos,
          factura.abono,
          factura.deuda,
          obtenerFecha(factura.fechaPago),
          factura.detalleFactura,
          diasRetraso,
        ];

        return arr;
      });
    setLoadingExport(true);
    try {
      const workbook = await fillTemplate(templatePath, formatData);
      saveFile(workbook, filename + ".xlsx");
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      console.error("Error al generar el archivo", error);
    }
  };
  return (
    <LoadingButton
      variant="contained"
      onClick={handleGenerateFile}
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px" }}
      loading={loadingExport}
    >
      Exportar {nombreBoton}
    </LoadingButton>
  );
}
