import {
  Box,
  Collapse,
  createTheme,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AttachmentOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Visibility,
  Edit,
} from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { fDateLong } from "src/utils/formatTime";
import {
  capitalizarPalabras,
  capitalizarPrimeraLetra,
} from "src/utils/sharedFunctions";
import * as locales from "@material-ui/core/locale";
import CustomTablePagination from "src/components/CustomTablePagination";
import { useCallback, useMemo, useState } from "react";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import ModalOrganigramaGrupo from "src/components/Modal/grupoCliente/modalOrganigramaGrupo";

RowAppGroup.propTypes = {
  row: PropTypes.object,
};

export default function RowAppGroup({ row }) {
  const [open, setOpen] = useState(false);
  const [editarOrganigrama, setEditarOrganigrama] = useState(false);
  const [abrirModalOrganigrama, setAbrirModalOrganigrama] = useState(false);
  const [state, setState] = useState(INITIAL_STATE_TABLE);
  const [grupo, setGrupo] = useState(null);

  const handleFilterByName = (event) => {
    setState({ ...state, filterName: event.target.value });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const filasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["nombre", "tipo", "sucursal"];
    return applySortFilter(
      row.history,
      getComparator(state.order, state.orderBy),
      state.filterName,
      propiedadesParaFiltrar
    );
  }, [row.history, state.filterName, state.order, state.orderBy]);

  const esSucursalNoEncontrada = filasFiltradas.length === 0;

  const emptyRows =
    state.currentPage > 0
      ? Math.max(
          0,
          (1 + state.currentPage) * state.rowsPerPageOptions -
            row.history.length
        )
      : 0;

  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const manejarClicArchivoGrupo = useCallback((editar, grupo) => {
    setEditarOrganigrama(editar);
    setGrupo(grupo);
    setAbrirModalOrganigrama(true);
  }, []);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {capitalizarPrimeraLetra(row.grupo)}
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {row.urlArchivo ? (
              <>
                <Tooltip title="Editar organigrama">
                  <IconButton
                    aria-label="edit-attachment"
                    size="small"
                    color="secondary"
                    onClick={() => manejarClicArchivoGrupo(true, row)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ver organigrama">
                  <IconButton
                    aria-label="watch-attachment"
                    size="small"
                    color="warning"
                    onClick={() => window.open(row.urlArchivo, "_blank")}
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Agregar organigrama">
                <IconButton
                  aria-label="add-attachment"
                  size="small"
                  color="success"
                  onClick={() => manejarClicArchivoGrupo(false, row)}
                >
                  <AttachmentOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: "100%" }}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom component="div">
                    {`Clientes grupo ${capitalizarPalabras(row.grupo)}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <TablaToolbar
                    filterName={state.filterName}
                    onFilterName={handleFilterByName}
                  />
                </Grid>
              </Grid>
              <Scrollbar>
                <TableContainer>
                  <Table size="small" aria-label="purchases">
                    <TablaHead
                      order={state.order}
                      orderBy={state.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={row.history?.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filasFiltradas
                        .slice(
                          state.currentPage * state.rowsPerPageOptions,
                          state.currentPage * state.rowsPerPageOptions +
                            state.rowsPerPageOptions
                        )
                        .map((historyRow, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <Typography variant="body2" noWrap>
                                {formatRut(historyRow.rut, RutFormat.DOTS_DASH)}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {capitalizarPalabras(historyRow.nombre)}
                            </TableCell>
                            <TableCell align="right">
                              {fDateLong(historyRow.fechaIngreso)}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.correo}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.telefono}
                            </TableCell>
                          </TableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {esSucursalNoEncontrada && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={state.filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableFooter>
                      <TableRow>
                        <ThemeProvider
                          theme={(outerTheme) =>
                            createTheme(outerTheme, locales[state.locale])
                          }
                        >
                          <CustomTablePagination
                            count={filasFiltradas.length}
                            rowsPerPage={state.rowsPerPageOptions}
                            page={state.currentPage}
                            colSpan={9}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </ThemeProvider>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {abrirModalOrganigrama && (
        <ModalOrganigramaGrupo
          isOpen={abrirModalOrganigrama}
          setOpen={setAbrirModalOrganigrama}
          title={
            !editarOrganigrama ? "Agregar organigrama" : "Editar organigrama"
          }
          grupo={grupo}
          editar={editarOrganigrama}
        />
      )}
    </>
  );
}

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

const TABLE_HEAD = [
  { id: "rut", label: "RUT", alignRight: false },
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "fechaIngreso", label: "Fecha de ingreso", alignRight: true },
  { id: "correo", label: "Correo", alignRight: true },
  { id: "telefono", label: "Telefono", alignRight: true },
];
