import React, { useEffect, useState, useRef } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  List,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

export default function SolicitarCambioEstadoDialog(props) {
  const { open, setOpen, tarea } = props;
  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);

  const [empleadosAdministradores, setEmpleadosAdministradores] = useState([]);

  const [cargandoEditar, setCargandoEditar] = useState(false);

  const [solicitudCambioEstado] =
    tareasApi.useAddSolicitudCambioEstadoMutation();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/administradores/${sucursal}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((elementos) => {
        if (elementos.data === -2) window.location.reload();
        if (isMounted.current) {
          let empleadosAUX = [];
          elementos.data.forEach((element) => {
            let elemento = element;
            elemento.seleccionado = false;
            empleadosAUX.push(elemento);
          });

          setEmpleadosAdministradores(empleadosAUX);
        }
      })
      .catch(() => {});
  }, [sucursal]);

  const editar = () => {
    AlertaModal({
      title: "solicitar el cambio de estado",
      confirmButtonText: "Sí, solicitar",
      target: document.getElementById("dialogSolicitudCambiada"),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          setCargandoEditar(true);

          let dominio = new URL(window.location.href);

          solicitudCambioEstado({
            refEmpleado: empleadoSeleccionado,
            idTarea: tarea.id,
            dominio: dominio.origin,
          })
            .unwrap()
            .then(() => {
              setCargandoEditar(false);
              setOpen(false);
              AlertaToast({
                element: "Solicitud",
                action: "enviada",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "ERROR, Solicitud no enviada",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogSolicitudCambiada"),
              }).fire();
              setCargandoEditar(false);
            });
        }
      });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" id="dialogSolicitudCambiada">
      <DialogTitle>Solicitar Cambio Estado</DialogTitle>
      <DialogContent>
        <Stack spacing={3} marginTop="10px">
          <Typography color="primary">Seleccionar Administrador</Typography>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {empleadosAdministradores.map((value, idx) => {
              return (
                <ListItem key={idx} disablePadding>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={value.seleccionado}
                      tabIndex={-1}
                      disableRipple
                      onChange={(event) => {
                        let empleadosAdminAUX2 = empleadosAdministradores;
                        let arregloAUX = [];
                        empleadosAdminAUX2.forEach((e) => {
                          let elemento = e;
                          if (e.rut === value.rut) {
                            setEmpleadoSeleccionado(e.rut);
                            elemento.seleccionado = true;
                          } else {
                            elemento.seleccionado = false;
                          }
                          arregloAUX.push(elemento);
                        });
                        setEmpleadosAdministradores(arregloAUX);
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={value.nombre + " " + value.apellidos}
                  />
                </ListItem>
              );
            })}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} loading={cargandoEditar}>
          Cerrar
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={editar}
          autoFocus
          loading={cargandoEditar}
        >
          Solicitar Cambio Estado
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
