import React from "react";
import { Icon } from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Autocomplete,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { sucursalesApi } from "src/services/sucursalesApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { ajustesApi } from "src/services/ajustesApi";

export default function FormDialog(props) {
  const {
    open,
    setOpen,
    nombre,
    direccion,
    comuna,
    telefono,
    gerente,
    razonSocial,
    rutRazonSocial,
    representanteLegal,
    rutRepresentante,
    editar,
  } = props;

  const [agregarSucursal] = sucursalesApi.useAddSucursalMutation();
  const [editarSucursal] = sucursalesApi.useUpdateSucursalMutation();
  const {
    data: comunas = []
  } = ajustesApi.useGetComunasQuery();

  /**
   * Se encarga de cerrar el modal de agregar sucursal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Se encarga de hacer las verificaciones para los distinso campos del
   * formulario de agregar sucursales.
   */
  const LoginSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre de la Sucursal requerido"),
    direccion: Yup.string().required("Dirección requerida"),
    comuna: Yup.string().required("Comuna requerida").nullable(),
    telefono: Yup.string().required("Teléfono requerido"),
    gerente: Yup.string().required("Nombre del gerente requerido"),
    razonSocial: Yup.string().required("Razón social requerida"),
    rutRazonSocial: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    representanteLegal: Yup.string().required("Nombre Requerido"),
    rutRepresentante: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
  });

  /**
   * Se encargar de guardar los datos ingresados por el usuario, ya validados
   * a la variable datos, que es la que se guardará en la bd.
   */
  const guardarDatos = () => {
    const sucursal = {
      nombre: getFieldProps("nombre").value.toUpperCase(),
      direccion: getFieldProps("direccion").value.toUpperCase(),
      comuna: getFieldProps("comuna").value.toUpperCase(),
      telefono: getFieldProps("telefono").value.toString().toUpperCase(),
      gerente: getFieldProps("gerente").value.toUpperCase(),
      razonSocial: getFieldProps("razonSocial").value.toUpperCase(),
      rutRazonSocial:
        deconstructRut(getFieldProps("rutRazonSocial").value.toUpperCase())
          .digits +
        deconstructRut(getFieldProps("rutRazonSocial").value.toUpperCase())
          .verifier,
      representanteLegal:
        getFieldProps("representanteLegal").value.toUpperCase(),
      rutRepresentante:
        deconstructRut(getFieldProps("rutRepresentante").value.toUpperCase())
          .digits +
        deconstructRut(getFieldProps("rutRepresentante").value.toUpperCase())
          .verifier,
    };
    if (editar) {
      editarSucursal({
        id: getFieldProps("nombre").value.toUpperCase(),
        body: {
          ...sucursal
        },
      })
        .unwrap()
        .then(() => {
          AlertaToast({
            element: 'Sucursal',
            action: 'actualizada',
            target: document.getElementById('dialogSucursal'),
          }).fire()
        }).catch(() => {
          AlertaToast({
            action: 'Error en el servidor',
            fullText: true,
            type: 'error',
            target: document.getElementById('dialogSucursal'),
          }).fire()
        })
    } else {
      AlertaModal({
        title: `agregar la sucursal`,
        confirmButtonText: 'Sí, agregar',
        target: document.getElementById('dialogSucursal'),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            agregarSucursal({
              ...sucursal,
              nombre: getFieldProps("nombre").value.toUpperCase(),
            })
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: 'Sucursal',
                  action: 'agregada',
                }).fire()
                handleClose();
              }).catch(() => {
                AlertaToast({
                  action: 'Error en el servidor',
                  fullText: true,
                  type: 'error',
                  target: document.getElementById('dialogSucursal'),
                }).fire()
              })

          }
        })
    }
  };

  /**
   * Objeto encargado de validar los valores ingresados al formulario, según las
   * validaciones entregadas por LoginSchema.
   */
  const formik = useFormik({
    initialValues: {
      nombre: nombre,
      direccion: direccion,
      comuna: comuna,
      telefono: telefono,
      gerente: gerente,
      razonSocial: razonSocial,
      rutRazonSocial: rutRazonSocial,
      representanteLegal: representanteLegal,
      rutRepresentante: rutRepresentante,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogSucursal"
        >
          <DialogTitle id="form-dialog-title">Sucursal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información de la sucursal
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="nombre"
                  label="Nombre Sucursal"
                  fullWidth
                  disabled={editar}
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
                <TextField
                  required
                  margin="dense"
                  id="direccion"
                  label="Dirección"
                  fullWidth
                  {...getFieldProps("direccion")}
                  error={Boolean(touched.direccion && errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />

                <Autocomplete
                  style={{ marginTop: "10px" }}
                  {...getFieldProps("comuna")}
                  disablePortal
                  options={comunas}
                  isOptionEqualToValue={(option, value) => {
                    return option.nombre === value;
                  }}
                  getOptionLabel={(option) =>
                    option.nombre ? option.nombre : option
                  }
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setFieldValue("comuna", newValue.nombre, true);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.comuna && errors.comuna)}
                      fullWidth
                      helperText={touched.comuna && errors.comuna}
                      label="Seleccione Comuna"
                      name="comuna"
                      variant="outlined"
                    />
                  )}
                />

                <TextField
                  required
                  margin="dense"
                  id="telefono"
                  label="Teléfono"
                  type="number"
                  fullWidth
                  {...getFieldProps("telefono")}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />
                <TextField
                  required
                  margin="dense"
                  id="gerente"
                  label="Nombre del Gerente"
                  fullWidth
                  {...getFieldProps("gerente")}
                  error={Boolean(touched.gerente && errors.gerente)}
                  helperText={touched.gerente && errors.gerente}
                />
                <TextField
                  required
                  margin="dense"
                  id="razonSocial"
                  label="Razón Social de la Sucursal"
                  fullWidth
                  {...getFieldProps("razonSocial")}
                  error={Boolean(touched.razonSocial && errors.razonSocial)}
                  helperText={touched.razonSocial && errors.razonSocial}
                />
                <TextField
                  required
                  margin="dense"
                  id="rutRazonSocial"
                  label="Rut de la Razón Social"
                  fullWidth
                  {...getFieldProps("rutRazonSocial")}
                  error={Boolean(
                    touched.rutRazonSocial && errors.rutRazonSocial
                  )}
                  helperText={touched.rutRazonSocial && errors.rutRazonSocial}
                />
                <TextField
                  required
                  margin="dense"
                  id="representanteLegal"
                  label="Nombre del Representante Legal de la Sucursal"
                  fullWidth
                  {...getFieldProps("representanteLegal")}
                  error={Boolean(
                    touched.representanteLegal && errors.representanteLegal
                  )}
                  helperText={
                    touched.representanteLegal && errors.representanteLegal
                  }
                />
                <TextField
                  required
                  margin="dense"
                  id="rutRepresentanteLegal"
                  label="Rut del Representante legal de la Sucursal"
                  fullWidth
                  {...getFieldProps("rutRepresentante")}
                  error={Boolean(
                    touched.rutRepresentante && errors.rutRepresentante
                  )}
                  helperText={
                    touched.rutRepresentante && errors.rutRepresentante
                  }
                />

                <DialogActions>
                  <LoadingButton onClick={handleClose} color="error">
                    Cancelar
                  </LoadingButton>
                  <Button
                    type="submit"
                    startIcon={<Icon icon={saveFill} />}
                    color="primary">
                    Guardar Sucursal
                  </Button>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
