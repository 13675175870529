import { Container, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import AppNewCustomer from "src/components/estadisticas/app-new-customer";
import AppTaskFinished from "src/components/estadisticas/app-task-finished";
import AppWidgetFacturacion from "src/components/estadisticas/app-widget-facturacion";
import { informesApi } from "src/services/informesApi";
import { empleadosApi } from "src/services/empleadosApi";
import { clientesApi } from "src/services/clientesApi";
import { tareasApi } from "src/services/tareasApi";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";
import dayjs from "dayjs";
import arrowLeftDown from "@iconify/icons-eva/diagonal-arrow-left-down-fill";
import arrowRightUp from "@iconify/icons-eva/diagonal-arrow-right-up-fill";
import facturacion from "@iconify/icons-ic/outline-receipt-long";
import recaudacion from "@iconify/icons-ant-design/dollar-circle-outlined";
import AppDetalleClientes from "src/components/estadisticas/app-detalle-clientes";
import AppWidgetIcon from "src/components/estadisticas/app-widgets-icon";
import AppHistorialTareas from "src/components/estadisticas/app-historial-tareas";
import { useCallback, useEffect, useState } from "react";
import ModalEstadisticasFacturacionMensual from "src/components/Modal/modalEstadisticasFacturacion";
import AppTablaEmpleadosActivos from "src/components/estadisticas/app-tabla-empleados-activos";
import ModalDetalleTareaSucursal from "src/components/Modal/modalDetalleTareasSucursal";
import ModalDetalleTareaPorEstado from "src/components/Modal/modalDetalleTareaPorEstado";
import CalendarField from "src/components/Modal/modalGav/Fields/CalendarField";
import { sucursalesApi } from "src/services/sucursalesApi";
import ModalDistribucionClientes from "src/components/Modal/modalDistribucionCliente";
import { planesApi } from "src/services/planesApi";
import { facturasApi } from "src/services/facturasApi";
import AppDistribucionPropuestas from "src/components/estadisticas/app-distribucion-propuestas";
import { propuestasApi } from "src/services/propuestasApi";
import ModalDetalleResumenPropuestas from "src/components/Modal/modalDetalleResumenPropuestas";

const Estadisticas = () => {
  const {
    empleado: usuario,
    sucursal: { refSucursal: sucursalEmpleado },
  } = useSelector((state) => state.auth.user);
  const { data: cantidadEmpleados, isFetching: relizandoPeticionEmpleados } =
    empleadosApi.useCantidadEmpleadosQuery(undefined, {
      pollingInterval: 1800000, // Cada 30 minutos hace la petición.
    });
  const [sucursal, setSucursal] = useState("");
  const [modalEstadisticas, setModalEstadisticas] = useState(false);
  const [modalDetalleTareaSucursal, setModalDetalleTareaSucursal] =
    useState(false);
  const [modalDetallePropuestas, setModalDetallePropuestas] = useState(false);
  const [abrirModalDetalleTareaPorEstado, setAbrirModalDetalleTareaPorEstado] =
    useState(false);
  const [filtroFechaEstadisticas, setFechaFiltroEstadisticas] = useState(
    dayjs().format("YYYY/MM")
  );

  const [
    tituloModalDetalleTareasPorEstado,
    setTituloModalDetalleTareasPorEstado,
  ] = useState("");
  const [tituloDetalleResumenPropuestas, setTituloDetalleResumenPropuestas] =
    useState("");
  const [abrirModalDetalleClientes, setAbrirModalDetalleClientes] =
    useState(false);

  const { data: empleadosActivos = [], isLoading: cargandoEmpleadosActivos } =
    empleadosApi.useObtenerEmpleadosActivosSucursalQuery(usuario.rut);

  const {
    data: historialTareas,
    isFetching: realizandoPeticionHistorialTareas,
  } = tareasApi.useHistorialUltimasTareasFinalizadasQuery(usuario.rut, {
    pollingInterval: 900000, // Cada 15 minutos hace la petición.
  });

  const { data: sucursalesEmpleado, isFetching: obteniendoSucursalesEmpleado } =
    sucursalesApi.useGetSucursalesEmpleadosQuery(usuario.rut);

  const {
    data: propuestasPorEstadoYSucursal,
    isFetching: obteniendoPropuestasPorEstadoYSucursal,
  } = propuestasApi.useGetResumenPorEstadoYSucursalQuery({
    rut: usuario.rut,
    fecha: filtroFechaEstadisticas,
  });

  const [
    obtenerDetalleCantidadTareas,
    { data: detalleCantidadTareas, isFetching: cargandoDetalleCantidadTareas },
  ] = tareasApi.useLazyGetCantidadTareasMensualesYTotalesQuery();

  const [
    obtenerTareasAnualesYMensualesAgrupadas,
    {
      data: tareasMensualesYAnualesAgrupadas,
      isFetching: cargandoTareasMensualesYAnualesAgrupadas,
    },
  ] = tareasApi.useLazyGetTareasAnualesYMensualesAgrupadasQuery();

  const [
    obtenerInformacionTareasInternas,
    {
      data: informacionTareasInternas,
      isFetching: cargandoInformacionTareasInternas,
    },
  ] = tareasApi.useLazyGetTareasInformacionQuery();

  // const {
  //   data: tareasMasAntiguas,
  //   isFetching: realizandoPeticionTareasMasAntiguas,
  // } = tareasApi.useTareasPendientesMasAntiguasQuery(undefined, {
  //   pollingInterval: 900000, // Cada 15 minutos hace la petición.
  // });

  const {
    data: ultimosClientesGestor,
    isFetching: realizandoPeticionUltimosClientes,
  } = clientesApi.useUltimosClientesGestorQuery(undefined, {
    pollingInterval: 1800000, // Cada 30 minutos hace la petición.
  });

  const [
    obtenerEstadisticasTareas,
    {
      data: estadisticasTareasEmpleado,
      isFetching: obteniendoEstadisticasTareasEmpleado,
    },
  ] = tareasApi.useLazyObtenerTareasTotalesEmpleadosSucursalQuery();

  const {
    data: cantidadClientesActivos,
    isFetching: realizandoPeticionClientes,
  } = clientesApi.useCantidadClientesActivosQuery();

  const {
    data: clienterPorDivision,
    isFetching: realizandoPeticionClientesPorDivision,
  } = clientesApi.useObtenerClientesPorDivisionQuery();

  const { data: infoRecuentoTareas, isFetching: obteniendoRecuentoTareas } =
    informesApi.useGetInformacionRecuentoTareasQuery({
      fechaActual: filtroFechaEstadisticas,
      rut: usuario.rut,
    });

  const { data: facturacionDatos, isFetching: realizandoPeticionFacturacion } =
    informesApi.useObtenerRecaudacionesYFacturasTotalesQuery(
      { rut: usuario.rut, fechaTermino: filtroFechaEstadisticas },
      {
        pollingInterval: 900000, // Cada 15 minutos hace la petición.
      }
    );
  const {
    data: tareasEspecialesRecientes,
    isFetching: realizandoPeticionTareasEspecialesRecientes,
  } = tareasApi.useHistorialUltimasTareasEspecialesIngresadasQuery(
    usuario.rut,
    {
      pollingInterval: 900000,
    }
  );

  const [
    obtenerTareasTareasEmpleados,
    { data: tareasEmpleado, isFetching: obteniendoTareasEmpleado },
  ] = tareasApi.useLazyGetTareasTotalesPorSucursalTipoTareaYFechaQuery();

  const [
    obtenerDispersionEmpleados,
    {
      data: dispersionTareasEmpleado,
      isFetching: obteniendoDispersionTareasEmpleado,
    },
  ] = tareasApi.useLazyGetCantidadTareasEmpleadosAgrupadasQuery();

  const [
    obtenerCantidadPromedioPlanes,
    {
      data: cantidadPlanesPromedioClientes,
      isFetching: obteniendoCantidadPlanesPromedioClientes,
    },
  ] = planesApi.useLazyObtenerCantidadPromedioPlanesClienteQuery();

  const [
    obtenerPromedioFacturasClientes,
    {
      data: promedioFacturasClientes,
      isFetching: obteniendoPromedioFacturasCliente,
    },
  ] = facturasApi.useLazyObtenerPromedioFacturasEmitidasPorClienteQuery();

  const [
    obtenerClientesPorDivision,
    { data: clientesPorDivision, isFetching: obteniendoclientesPorDivision },
  ] = clientesApi.useLazyObtenerDistribucionDeClientesQuery();

  const [
    obtenerPlanesMasCotizados,
    { data: planesMasCotizados, isFetching: obteniendoPlanesMasCotizados },
  ] = planesApi.useLazyObtenerPlanesMasCotizadosQuery();

  const [
    obtenerContribuyentesPlanes,
    { data: contribuyentesPlanes, isFetching: obteniendocontribuyentesPlanes },
  ] = planesApi.useLazyObtenerContribuyentesPlanesQuery();

  const [
    obtenerPropuestasModal,
    { data: propuestasModal, isFetching: obteniendoPropuestasModal },
  ] = propuestasApi.useLazyGetPropuestasPorSucursalYEstadoQuery();

  const handleMostrarDetalleFacturacion = useCallback(() => {
    setModalEstadisticas(true);
  }, [setModalEstadisticas]);

  const modalDetalleTareasSucursales = useCallback(
    (sucursal) => {
      setModalDetalleTareaSucursal(true);
      setSucursal(capitalizarPrimeraLetra(sucursal));
      setFechaFiltroEstadisticas(filtroFechaEstadisticas);
    },
    [filtroFechaEstadisticas]
  );

  const manejarDatosModalDetalleTareasSucursal = useCallback(() => {
    if (filtroFechaEstadisticas && sucursal) {
      const body = {
        sucursal: sucursal,
        fecha: filtroFechaEstadisticas,
        tipoTarea: "INTERNA",
      };
      obtenerEstadisticasTareas(body);
      obtenerTareasAnualesYMensualesAgrupadas(body);
      obtenerDetalleCantidadTareas(body);
      obtenerInformacionTareasInternas(body);
    }
  }, [
    filtroFechaEstadisticas,
    obtenerDetalleCantidadTareas,
    obtenerEstadisticasTareas,
    obtenerInformacionTareasInternas,
    obtenerTareasAnualesYMensualesAgrupadas,
    sucursal,
  ]);

  useEffect(() => {
    manejarDatosModalDetalleTareasSucursal();
  }, [manejarDatosModalDetalleTareasSucursal]);

  const handleChangeFechaFacturacion = useCallback(
    (newValue) => setFechaFiltroEstadisticas(dayjs(newValue).format("YYYY/MM")),
    []
  );

  const abrirModalEstadisticasTareasEmpleado = useCallback(
    ({ sucursal, estadoTarea, tipoTarea }) => {
      // Determinar el título de la tarea en base al tipo
      const tituloTipoTarea =
        tipoTarea.toLowerCase() === "especial" ? "especiales" : "de planes";
      const titulo = `Tareas ${tituloTipoTarea} ${estadoTarea.toLowerCase()} sucursal ${sucursal}`;

      // Determinar los tipos de tarea en base al tipo de tarea
      const tiposTarea =
        tipoTarea.toLowerCase() === "planes"
          ? ["MES", "AÑO", "INTERNA"]
          : ["ESPECIAL"];

      // Crear el objeto `body` con los datos necesarios
      const body = {
        sucursal,
        fecha: filtroFechaEstadisticas,
        estadoTarea:
          estadoTarea.toUpperCase() === "PENDIENTES"
            ? "PENDIENTE"
            : estadoTarea.toUpperCase(),
        tipoTarea: tiposTarea,
      };

      // Llamadas a las funciones de obtención de datos
      obtenerTareasTareasEmpleados(body);
      obtenerDispersionEmpleados(body);

      // Actualizar el estado del componente
      setTituloModalDetalleTareasPorEstado(titulo);
      setAbrirModalDetalleTareaPorEstado(true);
    },
    [
      filtroFechaEstadisticas,
      obtenerDispersionEmpleados,
      obtenerTareasTareasEmpleados,
    ]
  );

  const manejarClicTarjeta = useCallback(
    (tipoTarjeta) => {
      if (tipoTarjeta.toLowerCase() === "clientes") {
        obtenerCantidadPromedioPlanes();
        obtenerPromedioFacturasClientes();
        obtenerClientesPorDivision();
        obtenerPlanesMasCotizados();
        obtenerContribuyentesPlanes();
        setAbrirModalDetalleClientes(true);
      } else if (tipoTarjeta.toLowerCase() === "empleados") {
        // En caso de querer añadir funcionalidad a la tarjeta de empleados, implementar acá
      } else {
        throw new Error("Opción de tarjeta no válida");
      }
    },
    [
      obtenerCantidadPromedioPlanes,
      obtenerPromedioFacturasClientes,
      obtenerClientesPorDivision,
      obtenerPlanesMasCotizados,
      obtenerContribuyentesPlanes,
    ]
  );

  const manejarClicPropuestasSucursal = useCallback(
    (estado, sucursal) => {
      const titulo = `Detalle propuestas ${estado.toLowerCase()} - ${sucursal}`;
      const body = {
        sucursal,
        estado,
        fecha: filtroFechaEstadisticas,
      };
      obtenerPropuestasModal(body);
      setTituloDetalleResumenPropuestas(titulo);
      setModalDetallePropuestas(true);
    },
    [filtroFechaEstadisticas, obtenerPropuestasModal]
  );

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={8} md={8} lg={8}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              ¡Hola! {capitalizarPrimeraLetra(usuario.nombre)}, Bienvenido/a 👋
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            lg={4}
            sx={{
              display: "grid",
              justifyContent: { xs: "center", md: "right", lg: "right" },
              alignItems: "flex-end",
            }}
          >
            <CalendarField
              id="fecha-filtro"
              label="Filtrar por fecha"
              value={filtroFechaEstadisticas}
              onChange={handleChangeFechaFacturacion}
              inputFormat="MM/yyyy"
              views={["month", "year"]}
              tamanio={12}
              maxDate={dayjs().format("YYYY/MM/DD")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <AppWidgetFacturacion
                  title="Facturación mensual"
                  color="green"
                  icon={facturacion}
                  mostrarDetalleFacturacion={handleMostrarDetalleFacturacion}
                  mostrarProyeccion={true}
                  cursor="pointer"
                  iconTrending={
                    (!realizandoPeticionFacturacion && facturacionDatos
                      ? facturacionDatos.porcentajeFacturas
                      : 0) < 0
                      ? arrowLeftDown
                      : arrowRightUp
                  }
                  percent={
                    !realizandoPeticionFacturacion && facturacionDatos
                      ? facturacionDatos.porcentajeFacturas
                      : 0
                  }
                  mostrarTrendingInfo={true}
                  loading={realizandoPeticionFacturacion}
                  total={
                    !realizandoPeticionFacturacion && facturacionDatos
                      ? facturacionDatos.facturaTotalMes
                      : 0
                  }
                  chart={{
                    series:
                      !realizandoPeticionFacturacion && facturacionDatos
                        ? facturacionDatos.facturasGrafico.map(
                            (factura) => factura.total
                          )
                        : [],
                    label:
                      !realizandoPeticionFacturacion && facturacionDatos
                        ? facturacionDatos.facturasGrafico.map(
                            (factura) => factura.fecha
                          )
                        : [],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AppWidgetFacturacion
                  title="Ingresos mensuales"
                  color="warning"
                  icon={recaudacion}
                  iconTrending={-0.5 < 0 ? arrowLeftDown : arrowRightUp}
                  percent={-0.5}
                  loading={realizandoPeticionFacturacion}
                  total={
                    !realizandoPeticionFacturacion && facturacionDatos
                      ? facturacionDatos.recaudadoTotalMes
                      : 0
                  }
                  chart={{
                    series:
                      !realizandoPeticionFacturacion && facturacionDatos
                        ? facturacionDatos.recaudadoGrafico.map(
                            (factura) => factura.total
                          )
                        : [],
                    label:
                      !realizandoPeticionFacturacion && facturacionDatos
                        ? facturacionDatos.recaudadoGrafico.map(
                            (factura) => factura.fecha
                          )
                        : [],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AppWidgetIcon
                  loading={
                    realizandoPeticionClientes || relizandoPeticionEmpleados
                  }
                  onClick={manejarClicTarjeta}
                  series={
                    !relizandoPeticionEmpleados &&
                    cantidadEmpleados &&
                    !realizandoPeticionClientes &&
                    cantidadClientesActivos
                      ? [
                          {
                            label: "Empleados",
                            total: cantidadEmpleados,
                            clic: false,
                            imagen: "/assets/icons/glass/empleados_icono.svg",
                          },
                          {
                            label: "Clientes",
                            total: cantidadClientesActivos,
                            clic: true,
                            imagen: "/assets/icons/glass/clientes_icono.svg",
                          },
                        ]
                      : [
                          {
                            label: "Empleados",
                            total: 0,
                            clic: false,
                            imagen: "/assets/icons/glass/empleados_icono.svg",
                          },
                          {
                            label: "Clientes",
                            total: 0,
                            clic: false,
                            imagen: "/assets/icons/glass/clientes_icono.svg",
                          },
                        ]
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <AppDetalleClientes
              title="Detalle clientes"
              subheader={
                "Se muestra el detalle de cómo se dividen las tareas y planes entre los clientes"
              }
              loading={realizandoPeticionClientesPorDivision}
              total={
                !realizandoPeticionClientesPorDivision && clienterPorDivision
                  ? clienterPorDivision.totalClientes
                  : 0
              }
              chart={{
                series:
                  !realizandoPeticionClientesPorDivision && clienterPorDivision
                    ? clienterPorDivision.series
                    : [],
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppDistribucionPropuestas
              title="Propuestas por sucursal"
              subheader="Cantidad de propuestas por estado"
              sucursalEmpleado={sucursalEmpleado}
              data={propuestasPorEstadoYSucursal}
              cargandoData={obteniendoPropuestasPorEstadoYSucursal}
              sucursales={
                !obteniendoSucursalesEmpleado && sucursalesEmpleado
                  ? sucursalesEmpleado
                  : []
              }
              cargandoSucursales={obteniendoSucursalesEmpleado}
              loadingComponent={
                obteniendoSucursalesEmpleado ||
                obteniendoPropuestasPorEstadoYSucursal
              }
              onClick={manejarClicPropuestasSucursal}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppHistorialTareas
              title="Recuento tareas"
              subheader="Se muestran las tareas pendientes y en proceso de las sucursales"
              loading={obteniendoRecuentoTareas || obteniendoSucursalesEmpleado}
              abrirModal={modalDetalleTareasSucursales}
              manejarClickGrafico={abrirModalEstadisticasTareasEmpleado}
              sucursales={
                !obteniendoSucursalesEmpleado && sucursalesEmpleado
                  ? sucursalesEmpleado
                  : []
              }
              chart={{
                data:
                  !obteniendoRecuentoTareas && infoRecuentoTareas
                    ? infoRecuentoTareas
                    : {},
                series: [
                  {
                    type: "Especial",
                    data: [
                      {
                        name: "Pendientes",
                        data:
                          !obteniendoRecuentoTareas && infoRecuentoTareas
                            ? infoRecuentoTareas.tareasEspeciales
                                .filter((tarea) => tarea.estado === "PENDIENTE")
                                .map((tarea) => tarea.cantidad)
                            : [],
                      },
                      {
                        name: "En proceso",
                        data:
                          !obteniendoRecuentoTareas && infoRecuentoTareas
                            ? infoRecuentoTareas.tareasEspeciales
                                .filter(
                                  (tarea) => tarea.estado === "EN PROCESO"
                                )
                                .map((tarea) => tarea.cantidad)
                            : [],
                      },
                    ],
                  },
                  {
                    type: "Planes",
                    data: [
                      {
                        name: "Pendientes",
                        data:
                          !obteniendoRecuentoTareas && infoRecuentoTareas
                            ? infoRecuentoTareas.tareasDePlan
                                .filter((tarea) => tarea.estado === "PENDIENTE")
                                .map((tarea) => tarea.cantidad)
                            : [],
                      },
                      {
                        name: "En proceso",
                        data:
                          !obteniendoRecuentoTareas && infoRecuentoTareas
                            ? infoRecuentoTareas.tareasDePlan
                                .filter(
                                  (tarea) => tarea.estado === "EN PROCESO"
                                )
                                .map((tarea) => tarea.cantidad)
                            : [],
                      },
                    ],
                  },
                ],
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppTablaEmpleadosActivos
              title="Estadísticas empleados"
              subheader="Se pueden revisar las estadísticas de tareas especiales de los empleados"
              rows={!cargandoEmpleadosActivos ? empleadosActivos : []}
              loading={cargandoEmpleadosActivos}
            />
          </Grid>

          {/* <Grid item xs={12} md={12} lg={12}>
            <AppEmployeesTask
              title="Tareas pendientes empleado"
              subheader="Ordenadas por antigüedad"
              rows={
                !realizandoPeticionTareasMasAntiguas && tareasMasAntiguas
                  ? tareasMasAntiguas
                  : []
              }
              loading={realizandoPeticionTareasMasAntiguas}
            />
          </Grid> */}

          <Grid item xs={12} md={12} lg={6}>
            <AppNewCustomer
              title="Últimas tareas especiales incorporadas"
              subheader="Historial de las últimas 5 tareas especiales ingresadas"
              loading={realizandoPeticionTareasEspecialesRecientes}
              list={
                !realizandoPeticionTareasEspecialesRecientes &&
                tareasEspecialesRecientes
                  ? tareasEspecialesRecientes
                  : []
              }
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <AppNewCustomer
              title="Últimos clientes incorporados"
              loading={realizandoPeticionUltimosClientes}
              subheader="Expansión de nuestra familia de clientes"
              list={
                !realizandoPeticionUltimosClientes && ultimosClientesGestor
                  ? [
                      ...ultimosClientesGestor.map((cliente, index) => {
                        return {
                          ...cliente,
                          image: `/static/mock-images/avatars/avatar_${
                            20 + index
                          }.jpg`,
                        };
                      }),
                    ]
                  : []
              }
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <AppTaskFinished
              title="Últimas tareas finalizadas"
              subheader="Especiales"
              list={
                !realizandoPeticionHistorialTareas && historialTareas
                  ? historialTareas.tareasEspeciales
                  : []
              }
              loading={realizandoPeticionHistorialTareas}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <AppTaskFinished
              title="Últimas tareas finalizadas"
              subheader="Planes"
              list={
                !realizandoPeticionHistorialTareas && historialTareas
                  ? historialTareas.tareasPlan
                  : []
              }
              loading={realizandoPeticionHistorialTareas}
            />
          </Grid>

          {/* <Grid item xs={12} md={12} lg={12}>
            <AppHighlyValuedTask
              title="Tareas más cotizados"
              subheader="Cantidad de clientes por Tarea"
              loading={realizandoPeticionTareasMasCotizados}
              chart={
                !realizandoPeticionTareasMasCotizados && tareasMasCotizadas
                  ? tareasMasCotizadas
                  : { series: [] }
              }
            />
          </Grid> */}
        </Grid>
      </Container>
      {modalEstadisticas && (
        <ModalEstadisticasFacturacionMensual
          isOpen={modalEstadisticas}
          setOpen={setModalEstadisticas}
          fechaActual={filtroFechaEstadisticas}
          rut={usuario.rut}
        />
      )}
      {modalDetalleTareaSucursal && (
        <ModalDetalleTareaSucursal
          isOpen={modalDetalleTareaSucursal}
          setOpen={setModalDetalleTareaSucursal}
          dataTarjetaTotalTareas={detalleCantidadTareas}
          dataDistribucionTareas={tareasMensualesYAnualesAgrupadas}
          dataInformacionTareasInternas={informacionTareasInternas}
          dataGraficoEstadisticasTareas={estadisticasTareasEmpleado}
          cargandoDataGraficoEstadisticasTareas={
            obteniendoEstadisticasTareasEmpleado
          }
          cargandoInformacionTareasInternas={cargandoInformacionTareasInternas}
          cargandoDataDistribucionTareas={
            cargandoTareasMensualesYAnualesAgrupadas
          }
          cargandoDataTarjetaTotalTareas={cargandoDetalleCantidadTareas}
          sucursal={sucursal}
          filtroFecha={filtroFechaEstadisticas}
          setFiltroFecha={setFechaFiltroEstadisticas}
        />
      )}
      {abrirModalDetalleTareaPorEstado && (
        <ModalDetalleTareaPorEstado
          isOpen={abrirModalDetalleTareaPorEstado}
          setOpen={setAbrirModalDetalleTareaPorEstado}
          titulo={tituloModalDetalleTareasPorEstado}
          dataTable={tareasEmpleado}
          datosGraficoDispersion={dispersionTareasEmpleado}
          cargandoDatosGraficoDispersion={obteniendoDispersionTareasEmpleado}
          loadingDataTable={obteniendoTareasEmpleado}
        />
      )}
      {abrirModalDetalleClientes && (
        <ModalDistribucionClientes
          isOpen={abrirModalDetalleClientes}
          setOpen={setAbrirModalDetalleClientes}
          title="Detalle distribución clientes"
          cantidadPromedioPlanes={cantidadPlanesPromedioClientes}
          promedioFacturaClientes={promedioFacturasClientes}
          divisionClientes={clientesPorDivision}
          planesMasCotizados={planesMasCotizados}
          cargandoPlanesMasCotizados={obteniendoPlanesMasCotizados}
          cargandoDivisionClientes={obteniendoclientesPorDivision}
          cargandoPromedioFacturaClientes={obteniendoPromedioFacturasCliente}
          cargandoCantidadPromedioPlanes={
            obteniendoCantidadPlanesPromedioClientes
          }
          contribuyentesPlanes={contribuyentesPlanes}
          cargandoContribuyentesPlanes={obteniendocontribuyentesPlanes}
        />
      )}
      {modalDetallePropuestas && (
        <ModalDetalleResumenPropuestas
          isOpen={modalDetallePropuestas}
          setOpen={setModalDetallePropuestas}
          title={tituloDetalleResumenPropuestas}
          propuestas={propuestasModal}
          cargandoPropuestas={obteniendoPropuestasModal}
          filtroFecha={filtroFechaEstadisticas}
          setFiltroFecha={setFechaFiltroEstadisticas}
        />
      )}
    </>
  );
};

export default Estadisticas;
