import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useNavigate } from "react-router-dom";
import SimpleModal from "../../../layouts/SimpleModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { login } from "src/reducers/authReducers";

export default function LoginOptionsModal({ sucursales, open, setOpen, user }) {
  const userInfo = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleLogin = () => {
    const sucursal = sucursales[value];
    if (sucursal) {
      const rol = sucursal.refRol;
      localStorage.setItem("rol", rol);
      localStorage.setItem("sucursal", sucursal.refSucursal);
      dispatch(login({ ...userInfo, sucursal: sucursal, rol: rol }));

      if (setOpen !== undefined) {
        window.location.reload();
        setOpen(false);
      } else {
        if (rol === "SUPER_ADMIN" || rol === "ADMIN") {
          navigate("/dashboard/estadisticas");
        } else {
          navigate("/dashboard/inicio");
        }
      }
    }
  };

  return (
    <SimpleModal
      title="Seleccionar Sucursal"
      onSubmit={handleLogin}
      showCancelButton={false}
      open={open}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">Sucursal</FormLabel>
        <RadioGroup
          aria-label="sucursales"
          name="sucursal"
          onChange={handleChange}
          value={value}
        >
          {sucursales.map((s, idx) => (
            <FormControlLabel
              control={<Radio />}
              key={idx}
              label={`${s.refSucursal}`}
              value={`${idx}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </SimpleModal>
  );
}
