import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { API_SERVER, dbx } from "../../../utils/urlBack_End";
import saveFill from "@iconify/icons-eva/save-outline";
import { LocalizationProvider, TimePicker } from "@material-ui/lab";
import * as Yup from "yup";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { es } from "date-fns/locale";

import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogTitle,
  Container,
  Grid,
  Button,
  Typography,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import DownloadIcon from "@material-ui/icons/Download";
import { useSelector } from "react-redux";
import Label from "src/components/Label";

const styles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 7,
    marginTop: 12,
  },
  gridContainer: {},
  Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  textField: {
    width: "100%",
    marginBottom: 18,
    marginTop: 15,
  },
  textFieldComentario: {
    marginBottom: 18,
    marginTop: 20,
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function EditarPermiso(props) {
  const {
    open,
    setOpen,
    editing,
    editingEmpleado,
    setEditingEmpleado,
    permiso,
    setPermiso,
    empleado,
    titulo,
    setShowMessagePermiso,
    setColor,
    setMessage,
    setPermisos,
    visualizaEmpleado,
    setVisualizaEmpleado,
    setPermisosSolicitadas,
    permisos,
  } = props;

  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [errorHInicio, setErrorHInicio] = useState(false);
  const [errorHTermino, setErrorHTermino] = useState(false);
  const [errorFecha, setErrorFecha] = useState(false);

  const [esTodoElDia, setEsTodoElDia] = useState(false);

  function calculateTimeDifference(horaInicio, horaTermino) {
    const time1 = new Date("2023-05-18T" + horaInicio + ":00");
    const time2 = new Date("2023-05-18T" + horaTermino + ":00");

    const diffMs = time2 - time1;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    const diffFormatted = `${diffHours
      .toString()
      .padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}`;

    return diffFormatted;
  }

  useEffect(() => {
    const diferenciaTiempo = calculateTimeDifference(
      permiso.horaInicio,
      permiso.horaTermino
    );

    const horas = Number(diferenciaTiempo.split(":", 2)[0]);

    setEsTodoElDia(horas >= 8);
  }, [editing, permiso.horaInicio, permiso.horaTermino]);

  const [archivo, setArchivo] = useState(null);

  const [newDate, setNewDate] = useState(new Date());

  const [startHour, setStartHour] = useState(
    permiso.horaInicio === ""
      ? new Date()
      : new Date(
          0,
          0,
          0,
          permiso.horaInicio.split(":", 2)[0],
          permiso.horaInicio.split(":", 2)[1]
        )
  );

  const [endHour, setEndHour] = useState(
    permiso.horaTermino === ""
      ? startHour !== null
        ? new Date(
            startHour.getFullYear(),
            startHour.getMonth(),
            startHour.getDate(),
            startHour.getHours() + 1,
            startHour.getMinutes()
          )
        : new Date()
      : new Date(
          0,
          0,
          0,
          permiso.horaTermino.split(":", 2)[0],
          permiso.horaTermino.split(":", 2)[1]
        )
  );
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const classes = styles();

  const handleClose = () => {
    setOpen(false);
  };

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/empleados/permisos/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();

        setPermisos(response.data.permisos);
        setPermisosSolicitadas(response.data.permisosSolicitadas);
      });
  };

  const LoginSchema = Yup.object().shape({
    motivo: Yup.string().required("Motivo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      motivo: permiso.motivo,
      fechaInicio: permiso.fechaInicio,
      horaInicio: permiso.horaInicio,
      fechaTermino: permiso.fechaTermino,
      horaTermino: permiso.horaTermino,
      estado: permiso.estado,
      comentario: permiso.comentario,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const handleFileInput = (e) => {
    if (e.target.files[0] === undefined) {
      return;
    }
    setArchivo(e.target.files[0]);
  };

  const handleDeleteFile = () => {
    setArchivo(null);
  };

  async function uploadFile() {
    if (!archivo) return false;
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (archivo.size >= UPLOAD_FILE_SIZE_LIMIT) {
      return false;
    }

    try {
      await dbx.filesUpload({
        path: `/Empleados/${empleado.rut}/Permisos/` + archivo.name,
        contents: archivo,
        mode: "overwrite",
      });
      // si es que se sube bien, no devuelve la URL
      try {
        const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
          path: `/Empleados/${empleado.rut}/Permisos/` + archivo.name,
        });
        let url = shareResponse.result.url;
        return url;
      } catch (ex) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;
        return url;
      } finally {
      }
    } catch (ex) {
      return false;
    }
  }

  const {
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    touched,
    errors,
    values,
  } = formik;

  const obtenerFecha = (fecha) => {
    if (fecha.getMonth() + 1 < 10) {
      if (fecha.getDate() < 10) {
        return (
          fecha.getFullYear() +
          "/0" +
          (fecha.getMonth() + 1) +
          "/0" +
          fecha.getDate()
        );
      } else {
        return (
          fecha.getFullYear() +
          "/0" +
          (fecha.getMonth() + 1) +
          "/" +
          fecha.getDate()
        );
      }
    } else {
      if (fecha.getDate() < 10) {
        return (
          fecha.getFullYear() +
          "/" +
          (fecha.getMonth() + 1) +
          "/0" +
          fecha.getDate()
        );
      } else {
        return (
          fecha.getFullYear() +
          "/" +
          (fecha.getMonth() + 1) +
          "/" +
          fecha.getDate()
        );
      }
    }
  };

  const obtenerHorasMinutos = (horaMinutos) => {
    if (parseInt(horaMinutos) < 10) {
      return "0" + horaMinutos;
    }
    return horaMinutos;
  };

  function puedeCrearPermiso(editingEmpleado) {
    let totalSum = 0;
    let difference;
    let hours, minutes;
    let timeInMinutes;
    const anioActual = new Date().getFullYear();

    let permisosAux;
    if (editingEmpleado) {
      permisosAux = permisos.filter(
        (permisoAux) =>
          new Date(permisoAux.fechaInicio).getFullYear() === anioActual &&
          (permisoAux.estado !== "RECHAZADO" ||
            permisoAux.estado === "PENDIENTE") &&
          permisoAux.id !== permiso.id
      );
    } else {
      permisosAux = permisos.filter(
        (permiso) =>
          new Date(permiso.fechaInicio).getFullYear() === anioActual &&
          (permiso.estado !== "RECHAZADO" || permiso.estado === "PENDIENTE")
      );
    }

    for (let i = 0; i < permisosAux.length; i++) {
      const { horaInicio, horaTermino } = permisosAux[i];
      difference = calculateTimeDifference(horaInicio, horaTermino);
      [hours, minutes] = difference.split(":");
      timeInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      totalSum += timeInMinutes;
    }

    const partialSumHours = Math.floor(totalSum / 60);
    const partialSumMinutes = totalSum % 60;

    difference = calculateTimeDifference(
      obtenerHorasMinutos(startHour.getHours()) +
        ":" +
        obtenerHorasMinutos(startHour.getMinutes()),
      obtenerHorasMinutos(endHour.getHours()) +
        ":" +
        obtenerHorasMinutos(endHour.getMinutes())
    );
    [hours, minutes] = difference.split(":");
    timeInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    totalSum += timeInMinutes;
    const totalSumHours = Math.floor(totalSum / 60);
    const totalSumMinutes = totalSum % 60;

    if (totalSumHours < 24 || (totalSumHours === 24 && totalSumMinutes === 0)) {
      return true;
    } else {
      let horasPorPedir = 24 - partialSumHours - 1;
      let minutosPorPedir = 60 - partialSumMinutes;

      if (minutosPorPedir === 60) {
        minutosPorPedir = 0;
        horasPorPedir++;
      }

      setShowMessagePermiso(true);
      setColor("error");
      setMessage(
        `Solo puede pedir un total de ${horasPorPedir} horas y ${minutosPorPedir} minutos`
      );
      return false;
    }
  }
  const guardarDatos = async () => {
    if (editing) {
      if (permiso.estado === "PENDIENTE") {
        const permission = {
          estado: values.estado,
          id: permiso.id,
          comentario: values.comentario,
          refEmpleadoSolicita: empleado.rut,
          refEmpleadoAcepta: user.nombre + " " + user.apellidos,
        };
        axios
          .put(`${API_SERVER}/empleados/permisos`, permission, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            if (response.data === -2) window.location.reload();
            actualizar();
            setShowMessagePermiso(true);
            setColor("success");
            setMessage("Permiso Actualizado Correctamente");
            setOpen(false);
          });
      } else {
        setOpen(false);
      }
    } else {
      if (
        !(errorHInicio || errorHTermino) &&
        !errorFecha &&
        getFieldProps("motivo").value !== ""
      ) {
        if (visualizaEmpleado) {
          setVisualizaEmpleado(false);
          setOpen(false);
          setPermiso({
            fechaInicio: "",
            fechaTermino: "",
            horaInicio: "",
            horaTermino: "",
            refEmpleado: empleado.rut,
            urlArchivo: "",
            motivo: "",
            comentario: "",
            empleadoAcepta: "",
          });
        } else {
          if (puedeCrearPermiso(editingEmpleado)) {
            if (editingEmpleado) {
              const permission = {
                id: permiso.id,
                fechaInicio: obtenerFecha(newDate),
                horaInicio:
                  obtenerHorasMinutos(startHour.getHours()) +
                  ":" +
                  obtenerHorasMinutos(startHour.getMinutes()),
                fechaTermino: obtenerFecha(newDate),
                horaTermino:
                  obtenerHorasMinutos(endHour.getHours()) +
                  ":" +
                  obtenerHorasMinutos(endHour.getMinutes()),
                urlArchivo: permiso.urlArchivo,
                motivo: getFieldProps("motivo").value,
              };
              axios
                .put(
                  `${API_SERVER}/empleados/permisosEmpleado`,
                  {
                    ...permission,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then(function (response) {
                  if (response.data === -2) window.location.reload();
                  actualizar();
                  setOpen(false);
                  setPermiso({
                    fechaInicio: "",
                    fechaTermino: "",
                    horaInicio: "",
                    horaTermino: "",
                    refEmpleado: empleado.rut,
                    urlArchivo: "",
                    motivo: "",
                    comentario: "",
                    empleadoAcepta: "",
                  });
                  setEditingEmpleado(false);
                  setShowMessagePermiso(true);
                  setColor("success");
                  setMessage("Permiso Actualizado Correctamente");
                });
            } else {
              const resultado = await uploadFile();

              let permission;
              if (!resultado) {
                permission = {
                  fechaInicio: obtenerFecha(newDate),
                  horaInicio:
                    obtenerHorasMinutos(startHour.getHours()) +
                    ":" +
                    obtenerHorasMinutos(startHour.getMinutes()),
                  fechaTermino: obtenerFecha(newDate),
                  horaTermino:
                    obtenerHorasMinutos(endHour.getHours()) +
                    ":" +
                    obtenerHorasMinutos(endHour.getMinutes()),
                  refEmpleado: empleado.rut,
                  urlArchivo: "",
                  motivo: getFieldProps("motivo").value,
                  comentario: "",
                  empleadoAcepta: "",
                  nombreArchivo: "",
                };
              } else {
                permission = {
                  fechaInicio: obtenerFecha(newDate),
                  horaInicio:
                    obtenerHorasMinutos(startHour.getHours()) +
                    ":" +
                    obtenerHorasMinutos(startHour.getMinutes()),
                  fechaTermino: obtenerFecha(newDate),
                  horaTermino:
                    obtenerHorasMinutos(endHour.getHours()) +
                    ":" +
                    obtenerHorasMinutos(endHour.getMinutes()),
                  refEmpleado: empleado.rut,
                  urlArchivo: resultado,
                  motivo: getFieldProps("motivo").value,
                  comentario: "",
                  empleadoAcepta: "",
                  nombreArchivo: archivo.name,
                };
              }
              if (esTodoElDia) {
                permission = {
                  ...permission,
                  horaInicio: "10:00",
                  horaTermino: "18:00",
                };
              }
              axios
                .post(
                  `${API_SERVER}/empleados/permisos`,
                  {
                    ...permission,
                    sucursal: sucursalObtenida.refSucursal,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then(function (response) {
                  if (response.data === -2) window.location.reload();
                  actualizar();
                  setArchivo(null);
                  setOpen(false);
                  setShowMessagePermiso(true);
                  setColor("success");
                  setMessage("Permiso Creado Correctamente");
                });
            }
          } else {
            setSubmitting(false);
          }
        }
      } else {
      }
    }
  };

  const cerrar = () => {
    setPermiso({
      fechaInicio: "",
      fechaTermino: "",
      horaInicio: "",
      horaTermino: "",
      refEmpleado: empleado.rut,
      urlArchivo: "",
      motivo: "",
      comentario: "",
      empleadoAcepta: "",
    });
    setVisualizaEmpleado(false);
    setEditingEmpleado(false);
    handleClose();
  };

  const handleChangeTodoElDia = (event) => {
    setEsTodoElDia(event.target.checked);
  };

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  return (
    <>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  disabled={editing || visualizaEmpleado}
                  id="motivo"
                  label="Motivo"
                  fullWidth
                  {...getFieldProps("motivo")}
                  error={Boolean(touched.motivo && errors.motivo)}
                  helperText={touched.motivo && errors.motivo}
                />

                {editing || visualizaEmpleado ? (
                  <>
                    <TextField
                      required
                      margin="dense"
                      disabled={editing || visualizaEmpleado}
                      id="fechaInicio"
                      label="Fecha de Inicio"
                      fullWidth
                      {...getFieldProps("fechaInicio")}
                    />

                    <TextField
                      required
                      margin="dense"
                      disabled={editing || visualizaEmpleado}
                      id="horaInicio"
                      label="Hora de Inicio"
                      fullWidth
                      {...getFieldProps("horaInicio")}
                    />

                    <TextField
                      margin="dense"
                      disabled={editing || visualizaEmpleado}
                      id="fechaTermino"
                      label="Fecha de Término"
                      fullWidth
                      {...getFieldProps("fechaTermino")}
                    />
                    <TextField
                      margin="dense"
                      disabled={editing || visualizaEmpleado}
                      id="horaTermino"
                      label="Hora de Término"
                      fullWidth
                      {...getFieldProps("horaTermino")}
                    />

                    {visualizaEmpleado ? (
                      <>
                        <TextField
                          margin="dense"
                          id="comentario"
                          label="Comentario"
                          disabled={visualizaEmpleado}
                          multiline
                          minRows={3}
                          maxRows={6}
                          fullWidth
                          {...getFieldProps("comentario")}
                        />

                        <TextField
                          margin="dense"
                          id="estado"
                          label="Estado"
                          disabled={visualizaEmpleado}
                          fullWidth
                          {...getFieldProps("estado")}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          margin="dense"
                          id="comentario"
                          label="Ingrese un Comentario"
                          disabled={
                            permiso.estado === "PENDIENTE" ? false : true
                          }
                          multiline
                          minRows={3}
                          maxRows={6}
                          fullWidth
                          {...getFieldProps("comentario")}
                        />

                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Seleccione Estado
                          </InputLabel>
                          <Select
                            disabled={
                              permiso.estado === "PENDIENTE" ? false : true
                            }
                            labelId="demo-simple-select-label"
                            label="Seleccione Estado"
                            id="demo-simple-select"
                            value={getFieldProps("estado").value}
                            onChange={(e) =>
                              setFieldValue("estado", e.target.value)
                            }>
                            <MenuItem value={"PENDIENTE"}>PENDIENTE</MenuItem>
                            <MenuItem value={"ACEPTADO"}>ACEPTADO</MenuItem>
                            <MenuItem value={"RECHAZADO"}>RECHAZADO</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {permiso.urlArchivo !== "" &&
                      permiso.urlArchivo !== " " &&
                      permiso.urlArchivo && (
                        <Grid container style={{ marginTop: "10px" }}>
                          <Grid item xs={10} md={10}>
                            <Typography>Archivo Adjunto</Typography>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <IconButton
                              onClick={() => {
                                window.open(permiso.urlArchivo, "_blank");
                              }}
                              aria-label="delete">
                              <DownloadIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                  </>
                ) : (
                  <>
                    {" "}
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={es}>
                      <DatePicker
                        open={isOpenDatePicker}
                        onClose={() => setIsOpenDatePicker(false)}
                        shouldDisableDate={disableWeekends}
                        disablePast
                        views={["day"]}
                        label="Seleccione Fecha"
                        value={newDate}
                        onChange={(newValue) => {
                          setNewDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={() => setIsOpenDatePicker(true)}
                            helperText={null}
                            className={classes.textField}
                          />
                        )}
                        onError={(e) =>
                          e === null
                            ? setErrorFecha(false)
                            : setErrorFecha(true)
                        }
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={es}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.gridContainer}>
                        <Grid item xs={5}>
                          <TimePicker
                            disabled={esTodoElDia}
                            renderInput={(params) => {
                              if (params.error === true) {
                                const hour = params.inputProps.value.split(
                                  ":",
                                  2
                                );
                                if (
                                  parseInt(hour[0]) < 8 ||
                                  parseInt(hour[0]) > 18
                                ) {
                                  params.helperText =
                                    "La hora debe estar entre las 08:00 y las 18:00. ";
                                } else {
                                  params.helperText =
                                    "La hora de inicio debe ser menor que la del término";
                                }
                              }
                              return <TextField {...params} />;
                            }}
                            onError={(e) =>
                              e === null
                                ? setErrorHInicio(false)
                                : setErrorHInicio(true)
                            }
                            value={startHour}
                            label="Hora de Inicio"
                            onChange={(newValue, keyboardInputValue) => {
                              setStartHour(newValue);
                            }}
                            minTime={new Date(0, 0, 0, 8)}
                            maxTime={new Date(0, 0, 0, 17, 59)}
                            shouldDisableTime={(timeValue, clockType) => {
                              if (
                                clockType === "hours" &&
                                timeValue > endHour?.getHours()
                              ) {
                                return true;
                              }
                              if (
                                clockType === "minutes" &&
                                startHour?.getHours() === endHour?.getHours() &&
                                timeValue > endHour.getMinutes()
                              ) {
                                return true;
                              }
                              return false;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Container className={classes.Container}>A</Container>
                        </Grid>
                        <Grid item xs={5}>
                          <TimePicker
                            disabled={esTodoElDia}
                            renderInput={(params) => {
                              if (params.error === true) {
                                const hour = params.inputProps.value.split(
                                  ":",
                                  2
                                );
                                if (
                                  parseInt(hour[0]) < 8 ||
                                  parseInt(hour[0]) > 18
                                ) {
                                  params.helperText =
                                    "La hora debe estar entre las 08:00 y las 18:00. ";
                                } else {
                                  params.helperText =
                                    "La hora de término debe ser mayor que la del inicio";
                                }
                              }
                              return <TextField {...params} />;
                            }}
                            label="Hora de Término"
                            value={endHour}
                            onChange={(newValue) => {
                              setEndHour(newValue);
                            }}
                            onError={(e) =>
                              e === null
                                ? setErrorHTermino(false)
                                : setErrorHTermino(true)
                            }
                            minTime={new Date(0, 0, 0, 8, 1)}
                            maxTime={new Date(0, 0, 0, 18)}
                            shouldDisableTime={(timeValue, clockType) => {
                              if (
                                clockType === "hours" &&
                                timeValue < startHour?.getHours()
                              ) {
                                return true;
                              }
                              if (
                                clockType === "minutes" &&
                                endHour?.getHours() === startHour?.getHours() &&
                                timeValue <= startHour.getMinutes()
                              ) {
                                return true;
                              }
                              return false;
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={esTodoElDia}
                                onChange={handleChangeTodoElDia}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Todo el día"
                          />
                        </FormGroup>
                      </Grid>
                    </LocalizationProvider>
                    {!editingEmpleado &&
                      (permiso.urlArchivo === "" ||
                      permiso.urlArchivo === " " ||
                      !permiso.urlArchivo ? (
                        <Grid container style={{ marginTop: "10px" }}>
                          <Grid item xs={3} md={3}>
                            <label htmlFor="contained-button-file">
                              <Input
                                accept="*"
                                id="contained-button-file"
                                type="file"
                                onChange={handleFileInput}
                              />
                              <Button variant="contained" component="span">
                                Seleccionar Archivo
                              </Button>
                            </label>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            {archivo && (
                              <Grid container>
                                <Grid item xs={10} md={10}>
                                  <Typography>{archivo.name}</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <IconButton
                                    onClick={handleDeleteFile}
                                    aria-label="delete">
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <Button variant="contained">Subir</Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container style={{ marginTop: "10px" }}>
                          <Grid item xs={10} md={10}>
                            <Typography>Archivo Adjunto</Typography>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <IconButton
                              onClick={() => {
                                window.open(permiso.urlArchivo, "_blank");
                              }}
                              aria-label="delete">
                              <DownloadIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    {editingEmpleado &&
                      permiso.urlArchivo !== "" &&
                      permiso.urlArchivo !== " " &&
                      permiso.urlArchivo !== null && (
                        <Grid container style={{ marginTop: "10px" }}>
                          <Grid item xs={10} md={10}>
                            <Typography>Archivo Adjunto</Typography>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <IconButton
                              onClick={() => {
                                window.open(permiso.urlArchivo, "_blank");
                              }}
                              aria-label="delete">
                              <DownloadIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                  </>
                )}

                <DialogActions>
                  <LoadingButton onClick={() => cerrar()} color="error">
                    Cancelar
                  </LoadingButton>
                  {editing ? (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      startIcon={
                        permiso.estado === "PENDIENTE" ? (
                          <Icon icon={saveFill} />
                        ) : (
                          false
                        )
                      }
                      loading={isSubmitting}>
                      {permiso.estado === "PENDIENTE"
                        ? "Guardar Cambios"
                        : "Aceptar"}
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      startIcon={
                        visualizaEmpleado ? false : <Icon icon={saveFill} />
                      }
                      loading={isSubmitting}>
                      {visualizaEmpleado ? "Aceptar" : "Guardar Cambios"}
                    </LoadingButton>
                  )}
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
