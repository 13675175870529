import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  Stack,
  Autocomplete,
  Switch,
  FormControlLabel,
  Grid,
  Button,
} from "@material-ui/core";

import { obtenerClientesSucursal } from "../propuesta/Consultas";
import { generateRut } from "@fdograph/rut-utilities";
import { ajustesApi } from "src/services/ajustesApi";

export default function DatosEmpresa(props) {
  const {
    valor,
    rutEmpresa,
    setRutEmpresa,
    nombreEmpresa,
    setNombreEmpresa,
    correoEmpresa,
    correoFacturacionCliente,
    setCorreoEmpresa,
    setCorreoFacturacionCliente,
    telefonoEmpresa,
    setTelefonoEmpresa,
    comunaEmpresa,
    setComunaEmpresa,
    direccionEmpresa,
    setDireccionEmpresa,
    sinRut,
    setSinRut,
    validatePhoneNumber,
    formatPhoneNumber,
  } = props;
  const [clientes, setClientes] = useState([]);
  const [comunaSelect, setComunaSelect] = useState(null);
  const [labelChecked, setLabelChecked] = useState("Cliente Existente");
  const [checked, setChecked] = useState(true);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { data: comunas = [] } = ajustesApi.useGetComunasQuery();

  useEffect(() => {
    let clientesAUX = [];
    async function obtenerClientes() {
      clientesAUX = await obtenerClientesSucursal();
      if (isMounted.current) {
        setClientes(clientesAUX);
      }
    }
    obtenerClientes();
  }, []);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Solo permitir números y algunos caracteres especiales al escribir
    if (/^[\d\s\-()+.]*$/.test(value)) {
      setTelefonoEmpresa(value);
    }
  };

  const handlePhoneBlur = (e) => {
    const value = e.target.value;
    if (validatePhoneNumber(value)) {
      // Formatear el número cuando el campo pierde el foco
      setTelefonoEmpresa(formatPhoneNumber(value));
    }
  };

  const handleChangeChecked = (event) => {
    if (checked) {
      setClienteSeleccionado({ rut: "" });
      setComunaSelect(null);
      setLabelChecked("Cliente Nuevo");
    } else {
      setLabelChecked("Cliente Existente");
    }
    setSinRut(false);
    setRutEmpresa("");
    setNombreEmpresa("");
    setCorreoEmpresa("");
    setCorreoFacturacionCliente("");
    setTelefonoEmpresa("");
    setComunaEmpresa("");
    setDireccionEmpresa("");
    setComunaSelect(null);

    setChecked(event.target.checked);
  };

  return (
    <>
      {valor === 3 && (
        <Paper
          style={{
            height: window.screen.height - 350,
            margin: "10px",
            overflow: "auto",
          }}
        >
          <Typography align="center" variant="h3">
            Empresa
          </Typography>

          <Stack spacing={2}>
            <FormControlLabel
              style={{ width: "200px" }}
              control={
                <Switch checked={checked} onChange={handleChangeChecked} />
              }
              label={labelChecked}
            />

            {checked ? (
              <FormControl fullWidth style={{ marginTop: "30px" }}>
                <Autocomplete
                  value={clienteSeleccionado}
                  disablePortal
                  options={clientes}
                  type="rut"
                  name="rut"
                  isOptionEqualToValue={(option) => option.rut}
                  getOptionLabel={(option) => option.rut}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setClienteSeleccionado(newValue);
                      setRutEmpresa(newValue.rut);
                      setNombreEmpresa(newValue.nombre);
                      setCorreoEmpresa(newValue.correo);
                      setCorreoFacturacionCliente(newValue.correoFacturacion);
                      setTelefonoEmpresa(newValue.telefono);
                      setComunaEmpresa(newValue.comuna);
                      setComunaSelect({
                        nombre: newValue.comuna,
                      });
                      setDireccionEmpresa(newValue.direccion);
                    } else {
                      setRutEmpresa("");
                      setNombreEmpresa("");
                      setCorreoEmpresa("");
                      setCorreoFacturacionCliente("");
                      setTelefonoEmpresa("");
                      setComunaEmpresa("");
                      setComunaSelect(null);
                      setClienteSeleccionado({ rut: "" });
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      error={rutEmpresa === ""}
                      required
                      {...params}
                      label="Rut Empresa"
                    />
                  )}
                />
              </FormControl>
            ) : (
              <Grid container>
                <Grid item xs={10} md={10}>
                  <FormControl fullWidth style={{ marginTop: "30px" }}>
                    <TextField
                      error={rutEmpresa === ""}
                      required
                      fullWidth
                      value={rutEmpresa}
                      label="Rut Empresa"
                      onChange={(e) => {
                        setClienteSeleccionado({
                          rut: e.target.value,
                        });
                        setRutEmpresa(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  {sinRut ? (
                    <FormControl fullWidth style={{ marginTop: "25%" }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSinRut(!sinRut);
                          setClienteSeleccionado({
                            rut: "",
                          });
                          setRutEmpresa("");
                        }}
                      >
                        Con Rut
                      </Button>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth style={{ marginTop: "25%" }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSinRut(!sinRut);
                          let rutGenerado = generateRut();
                          setClienteSeleccionado({
                            rut: rutGenerado,
                          });
                          setRutEmpresa(rutGenerado);
                        }}
                      >
                        Sin Rut
                      </Button>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            )}

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                error={nombreEmpresa === ""}
                label="Nombre Empresa"
                value={nombreEmpresa}
                onChange={(event) => {
                  setNombreEmpresa(event.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                error={
                  correoFacturacionCliente
                    ? correoFacturacionCliente.trim() === ""
                    : null
                }
                label="Correo facturación empresa"
                value={correoFacturacionCliente}
                onChange={(event) => {
                  setCorreoFacturacionCliente(event.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                error={correoEmpresa === ""}
                label="Correo Empresa"
                value={correoEmpresa}
                onChange={(event) => {
                  setCorreoEmpresa(event.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                label="Teléfono empresa"
                value={telefonoEmpresa}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
                placeholder="+56 9 XXXX XXXX"
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <Autocomplete
                disablePortal
                value={comunaSelect}
                isOptionEqualToValue={(option) => option.nombre}
                getOptionLabel={(option) => option.nombre}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setComunaEmpresa(newValue.nombre);
                    setComunaSelect(newValue);
                  } else {
                    setComunaEmpresa(null);
                    setComunaEmpresa(null);
                  }
                }}
                fullWidth
                id="combo-box-Comunas"
                options={comunas}
                renderInput={(params) => (
                  <TextField
                    label="Comuna Empresa"
                    error={comunaEmpresa === null}
                    required
                    fullWidth
                    {...params}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                error={direccionEmpresa === null}
                label="Dirección Empresa"
                value={direccionEmpresa}
                onChange={(event) => {
                  setDireccionEmpresa(event.target.value);
                }}
              />
            </FormControl>
          </Stack>
        </Paper>
      )}
    </>
  );
}
