import React, { useState } from "react";
import { Icon } from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { API_SERVER } from "../../utils/urlBack_End";

import axios from "axios";

export default function EditarClavesPortales(props) {
  const {
    open,
    setOpen,
    setClavesPortales,
    refCliente,
    setShowAlert,
    setColor,
    setMessage,
    elementoSeleccionado,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Se encarga de cerrar el modal de agregar empleado
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Se encarga de hacer las verificaciones para los distinso campos del
   * formulario de agregar empleados.
   */
  const LoginSchema = Yup.object().shape({
    clave: Yup.string().required("Se debe ingresar una clave"),
  });

  /**
   * Actualiza la variable clavePortales, para que se refrescar la pantalla
   * al momento de agregar o quitar información del cliente
   */
  const actualizar = () => {
    axios
      .get(`${API_SERVER}/clientes/clavesPortales/${refCliente}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClavesPortales(response.data);
      });
  };

  /**
   * Objeto encargado de validar los valores ingresados al formulario, según las
   * validaciones entregadas por LoginSchema.
   */
  const formik = useFormik({
    initialValues: {
      clave: elementoSeleccionado.clave,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  /**
   * Se encargar de guardar los datos ingresados por el usuario, ya validados
   * a la variable empleado, que es la que se guardará en la bd.
   */
  const guardarDatos = () => {
    const clavesCliente = {
      clave: getFieldProps("clave").value,
      tipoClave: elementoSeleccionado.tipoClave,
    };

    axios
      .put(`${API_SERVER}/clientes/clavesPortales/${refCliente}/${elementoSeleccionado.institucion}/${elementoSeleccionado.usuario}`, {
        ...clavesCliente,
      },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
          }
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        actualizar();
        setColor("success");
        setMessage("Datos del Cliente Guardados");
        setShowAlert(true);
        handleClose();
      });
  };

  const handleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Editar Claves Cliente</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingrese nueva contraseña del cliente.
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  disabled={true}
                  margin="dense"
                  label="Nombre de Usuario"
                  fullWidth
                  value={elementoSeleccionado.usuario}
                />



                <FormControl
                  margin="dense"
                  fullWidth
                  disabled={true}
                >
                  <InputLabel id="demo-simple-select-label">Tipo Cliente</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={elementoSeleccionado.tipoClave}
                    label="Tipo Cliente"
                  >
                    <MenuItem value={"SOCIO"}>SOCIO</MenuItem>
                    <MenuItem value={"REPRESENTANTE LEGAL"}>REPRESENTANTE LEGAL</MenuItem>
                    <MenuItem value={"EMPRESA"}>EMPRESA</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  disabled={true}
                  margin="dense"
                  label="Institucion"
                  fullWidth
                  value={elementoSeleccionado.institucion}
                />

                <TextField
                  margin="dense"
                  required
                  fullWidth
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  label="Clave del cliente"
                  {...getFieldProps("clave")}
                  style={{ marginBottom: 15 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.clave && errors.clave)}
                  helperText={touched.clave && errors.clave}
                />

                <DialogActions>
                  <LoadingButton onClick={handleClose} color="error">
                    Cancelar
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    startIcon={<Icon icon={saveFill} />}
                    loading={isSubmitting}
                  >
                    Guardar Claves Cliente
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
