import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import VerPlanPropuesta from "./VerPlanPropuesta";

export default function PlanesAsociadosCliente(props) {
  const { open, setOpen, id } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog id="modal-mostrar-planes" open={open} fullWidth maxWidth="md">
      <DialogContent>
        <VerPlanPropuesta id={id} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
