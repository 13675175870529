import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import React, { useCallback } from "react";
import FormProvider from "src/components/hook-form/FormProvider";
import FormikTextField from "src/components/hook-form/FormikTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ajustesApi } from "src/services/ajustesApi";
import { AlertaToast } from "src/components/AlertaSwall";

const mostrarAlertaError = (mensaje) => {
  AlertaToast({
    action: mensaje,
    fullText: true,
    type: "error",
    target: document.getElementById("dialogModalGrupoCliente"),
  }).fire();
};

const mostrarAlertaExito = (accion, grupo) => {
  AlertaToast({
    element: `Grupo ${grupo.toUpperCase()}`,
    action: accion,
    target: document.getElementById("dialog-enlace-grupo-cliente"),
  }).fire();
};

const ModalGrupoCliente = ({ isOpen, setOpen, title }) => {
  const [agregarGrupo] = ajustesApi.useAddRelacionSelectorElementoMutation();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const facturasSchema = Yup.object().shape({
    nombre: Yup.string().required("El nombre del grupo es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: "",
    },
    validationSchema: facturasSchema,
    onSubmit: () => {
      guardarGrupo();
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, setSubmitting } = formik;

  const guardarGrupo = useCallback(async () => {
    try {
      const { nombre } = values;
      const body = {
        refComponente: "1297451858",
        nombreElemento: nombre.trim().toUpperCase(),
      };

      const { error } = await agregarGrupo(body);

      if (error) {
        const { status } = error;
        if (status === 409) {
          mostrarAlertaError(`El grupo ${nombre} ya existe`);
        } else {
          mostrarAlertaError(`Ocurrió un error al crear el grupo ${nombre}`);
        }
        console.error(error);
        return;
      }

      mostrarAlertaExito("creado", body.nombreElemento);
      handleClose();
    } catch (error) {
      console.error(error);
      mostrarAlertaError("Ocurrió un error desconocido");
    } finally {
      setSubmitting(false);
    }
  }, [agregarGrupo, handleClose, setSubmitting, values]);

  return (
    <Dialog
      id="dialogModalGrupoCliente"
      open={isOpen}
      aria-labelledby="dialogModalGrupoCliente"
    >
      <DialogTitle id="dialogModalGrupoCliente">{title}</DialogTitle>
      <FormProvider onSubmit={handleSubmit} formik={formik}>
        <DialogContent>
          <FormikTextField
            name="nombre"
            size="small"
            label="Nombre grupo*"
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
          <LoadingButton
            type="submit"
            disabled={isSubmitting || !isValid}
            startIcon={<Icon icon={saveFill} />}
            color="primary"
            loading={isSubmitting}
          >
            Agregar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default React.memo(ModalGrupoCliente);
