import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  blur: {
    filter: "blur(4px)",
    pointerEvents: "none",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 1,
  },
  message: {
    textAlign: "center",
    color: "white",
    animation: "$fadeIn 1s ease-in-out",
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    fontSize: "3rem",
  },
  subtitle: {
    fontSize: "1.5rem",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0, transform: "translateY(20px)" },
    "100%": { opacity: 1, transform: "translateY(0)" },
  },
}));

const ComingSoonAnimated = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.blur}>{children}</Box>
      <Box className={classes.overlay}>
        <Box className={classes.message}>
          <Typography variant="h2" component="h2" className={classes.title}>
            Próximamente
          </Typography>
          <Typography variant="h5" className={classes.subtitle}>
            Esta función estará disponible en breve.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ComingSoonAnimated;
