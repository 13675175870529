import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";


export default function PagosFacturaDialog(props) {
  const { open, setOpen, factura } = props;
  const [pagosFactura, setPagosFactura] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/pagos/facturaTareas/${factura.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setPagosFactura(response.data);
      }).catch(() => { });

  }, [factura.id])





  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Pagos Factura {factura.folio}
      </DialogTitle>
      <DialogContent>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha Creación</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Fecha Pago</TableCell>
                <TableCell>Método Pago</TableCell>
                <TableCell>Empleado Recibe Pago</TableCell>
                <TableCell>Estado Pago</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {
                pagosFactura && pagosFactura.map((e, index) => {
                  return (
                    <TableRow key={"pagosFactura_" + index}>
                      <TableCell>{e.fechaCreacion}</TableCell>
                      <TableCell>{e.valorPesos}</TableCell>
                      <TableCell>{e.fecha}</TableCell>
                      <TableCell>{e.fechaPago}</TableCell>
                      <TableCell>{e.metodoPago}</TableCell>
                      <TableCell>{e.empleadoRecibePago}</TableCell>
                      <TableCell>{e.estado}</TableCell>
                    </TableRow>
                  );

                })
              }



            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog >
  );

}
