// material
import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Titulo } from "src/components/Titulo";
// components
import TableroKanban from "../components/tableroKanban/TableroKanban";
import Page from "../components/Page";
import InicioTabla from "./InicioTabla";
import Tablero from "../components/calendario/Tablero";
import { useSelector } from "react-redux";
import TableroCompartido from "src/components/calendario/TableroCompartido";
import DialogCambioContrasena from "src/components/authentication/login/DialogCambioContrasena";
import { empleadosApi } from "src/services/empleadosApi";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [value, setValue] = useState(0);

  const { empleado: usuario, cambioContrasena } = useSelector(
    (state) => state.auth.user
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [modalCambioContrasena, setModalContrasena] = useState(false);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: usuario.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, usuario.rut]);

  useEffect(() => {
    if (cambioContrasena) {
      setModalContrasena(true);
    }
  }, [cambioContrasena]);

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography variant="h4">
            Bienvenido(a) {usuario.nombre} {usuario.apellidos}{" "}
          </Typography>
        </Titulo>

        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="full width tabs example"
            >
              <Tab label="Calendario" />
              <Tab label="Tareas por Terminar" />
              <Tab label="Calendario Compartido" />
              <Tab label="Kanban" />
            </Tabs>
          </AppBar>

          <Tablero value={value} />

          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <InicioTabla value={value} />
            </Grid>
          </Grid>

          <TableroCompartido value={value} />

          <TableroKanban value={value} />
        </div>
      </Container>
      {modalCambioContrasena && (
        <DialogCambioContrasena
          open={modalCambioContrasena}
          setOpen={setModalContrasena}
        />
      )}
    </Page>
  );
}
