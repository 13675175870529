import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import {
  TareasAnualesIlustracion,
  TareasMensualesIlustracion,
} from "src/assets/illustrations";
import AppDetalleDistribucionTareas from "src/components/estadisticas/app-detalle-distribucion-tareas";
import AppDetalleTareas from "src/components/estadisticas/app-detalle-tareas";
import AppWidgetTareas from "src/components/estadisticas/app-widget-tareas";
import Iconify from "src/components/iconify";
import enProcesoIcono from "@iconify/icons-ant-design/sync-outlined";
import pendientesIcono from "@iconify/icons-ant-design/clock-circle-outlined";
import finalizadasIcono from "@iconify/icons-ant-design/check-circle-outlined";
import canceladasIcono from "@iconify/icons-ant-design/close-circle-outlined";
import observadas from "@iconify/icons-ant-design/warning-outlined";
import CalendarField from "../modalGav/Fields/CalendarField";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import ModalEstadisticasTareas from "../modalEstadisticasTareas";
import { tareasApi } from "src/services/tareasApi";
import ModalDetalleTareaPorEstado from "../modalDetalleTareaPorEstado";
import AppGraficoDeBarras from "src/components/informes/App-grafico-barras";
import { deconstructRut, validateRut } from "@fdograph/rut-utilities";
import ModalDetalleTareasEmpleado from "../modalDetalleTareasEmpleado";

const iconos = {
  Pendientes: (
    <Iconify
      icon={pendientesIcono}
      width={24}
      sx={{ color: "text.disabled" }}
    />
  ),
  "En proceso": (
    <Iconify icon={enProcesoIcono} width={24} sx={{ color: "text.disabled" }} />
  ),
  "Canceladas o rechazadas": (
    <Iconify
      icon={canceladasIcono}
      width={24}
      sx={{ color: "text.disabled" }}
    />
  ),
  Terminadas: (
    <Iconify
      icon={finalizadasIcono}
      width={24}
      sx={{ color: "text.disabled" }}
    />
  ),
  "Observadas SII": (
    <Iconify icon={observadas} width={24} sx={{ color: "text.disabled" }} />
  ),
};

const defaultDataTareas = [
  {
    name: "Pendientes",
    quantityTask: 0,
    percentTask: 0,
    icon: (
      <Iconify
        icon={pendientesIcono}
        width={24}
        sx={{ color: "text.disabled" }}
      />
    ),
  },
  {
    name: "En proceso",
    quantityTask: 0,
    percentTask: 0,
    icon: (
      <Iconify
        icon={enProcesoIcono}
        width={24}
        sx={{ color: "text.disabled" }}
      />
    ),
  },
  {
    name: "Cancelada o rechazada",
    quantityTask: 0,
    percentTask: 0,
    icon: (
      <Iconify
        icon={canceladasIcono}
        width={24}
        sx={{ color: "text.disabled" }}
      />
    ),
  },
  {
    name: "Terminadas",
    quantityTask: 0,
    percentTask: 0,
    icon: (
      <Iconify
        icon={finalizadasIcono}
        width={24}
        sx={{ color: "text.disabled" }}
      />
    ),
  },
];

const ModalDetalleTareaSucursal = ({
  isOpen,
  setOpen,
  sucursal,
  dataTarjetaTotalTareas,
  dataDistribucionTareas,
  dataInformacionTareasInternas,
  dataGraficoEstadisticasTareas,
  cargandoDataGraficoEstadisticasTareas,
  cargandoInformacionTareasInternas,
  cargandoDataTarjetaTotalTareas,
  cargandoDataDistribucionTareas,
  filtroFecha,
  setFiltroFecha,
}) => {
  const [abrirModalEstadisticasTareas, setAbrirModalEstadisticasTareas] =
    useState(false);
  const [modalDetalleTareasEmpleado, setModalDetalleTareasEmpleado] =
    useState(false);
  const [
    tituloModalDetalleTareasEmpleado,
    setTituloModalDetalleTareasEmpleado,
  ] = useState(false);

  const [
    obtenerInformacionTareasPorTipoTarea,
    {
      data: informacionTareasPorTipo,
      isFetching: obteniendoInformacionTareasPorTipo,
    },
  ] = tareasApi.useLazyGetTareasTotalesPorSucursalTipoTareaYFechaQuery();

  const [
    obtenerInformacionTareasMensuales,
    {
      data: informacionTareasMensuales,
      isFetching: cargandoInformacionTareasMensuales,
    },
  ] = tareasApi.useLazyGetTareasInformacionQuery();

  const [
    obtenerCantidadTareasPorEmpleado,
    {
      data: informacionCantidadTareasEmpleado,
      isFetching: obteniendoInformacionCantidadTareasEmpleado,
    },
  ] = tareasApi.useLazyGetCantidadTareasEmpleadosAgrupadasQuery();

  const [
    obtenerCantidadTareasAgrupadasPorNombre,
    {
      data: cantidadTareasAgrupadasPorNombre,
      isFetching: obteniendoCantidadTareasAgrupadasPorNombre,
    },
  ] = tareasApi.useLazyGetCantidadTareasAgrupadasPorNombreTareaQuery();

  const [
    obtenerTareasEmpleadoPorNombreTarea,
    {
      data: tareasEmpleadoPorNombre,
      isFetching: obteniendoTareasEmpleadoPorNombre,
    },
  ] = tareasApi.useLazyGetTareasEmpleadosPorNombreTareaQuery();

  const [
    obtenerCantidadTareasAgrupadasPorNombreTareaYEmpleado,
    {
      data: cantidadTareasAgrupadasPorNombreTareaYEmpleado,
      isFetching: obteniendoCantidadTareasAgrupadasPorNombreTareaYEmpleado,
    },
  ] = tareasApi.useLazyGetCantidadTareasAgrupadasPorNombreTareaYEmpleadoQuery();

  const [
    obtenerTareasEmpleados,
    { data: tareasEmpleado, isFetching: obteniendoTareasEmpleado },
  ] = tareasApi.useLazyGetTareasTotalesPorSucursalTipoTareaYFechaQuery();

  const [
    obtenerDispersionEmpleados,
    {
      data: dispersionTareasEmpleado,
      isFetching: obteniendoDispersionTareasEmpleado,
    },
  ] = tareasApi.useLazyGetCantidadTareasEmpleadosAgrupadasQuery();

  const [
    obtenerTareasEmpleadoGrafico,
    {
      data: tareasEmpleadoGrafico,
      isFetching: obteniendoTareasEmpleadoGrafico,
    },
  ] = tareasApi.useLazyObtenerTareasEmpleadoPorSucursalYEstadoTareaQuery();

  const [tipoModal, setTipoModal] = useState("");
  const [nombreTarea, setNombreTarea] = useState("");
  const [datosTarjetaMensual, setDatosTarjetaMensual] = useState({});
  const [cargandoDatosTarjetaMensual, setCargandoDatosTarjetaMensual] =
    useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [cargandoDataTable, setCargandoDataTable] = useState(false);
  const [datosGraficoDispersion, setDatosGraficoDispersion] = useState([]);
  const [cargandoDatosGraficoDispersion, setCargandoDatosGraficoDispersion] =
    useState(false);
  const [
    tituloModalDetalleTareasPorEstado,
    setTituloModalDetalleTareasPorEstado,
  ] = useState("");
  const [abrirModalDetalleTareaPorEstado, setAbrirModalDetalleTareaPorEstado] =
    useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFechaFacturacion = useCallback(
    (newValue) => setFiltroFecha(dayjs(newValue).format("YYYY/MM")),
    [setFiltroFecha]
  );

  const manejarEstadisticasTareaTarjeta = useCallback(
    (tipo) => {
      setTipoModal(tipo);
      const bodyInfoTareas = {
        sucursal,
        fecha: filtroFecha,
        tipoTarea: tipo.toUpperCase(),
      };

      obtenerInformacionTareasPorTipoTarea(bodyInfoTareas);
      obtenerInformacionTareasMensuales(bodyInfoTareas);
      obtenerCantidadTareasPorEmpleado(bodyInfoTareas);
      setAbrirModalEstadisticasTareas(true);
    },
    [
      filtroFecha,
      obtenerCantidadTareasPorEmpleado,
      obtenerInformacionTareasMensuales,
      obtenerInformacionTareasPorTipoTarea,
      sucursal,
    ]
  );

  const manejarLabelSeleccionadoGrafico = useCallback(
    (labelSeleccionado, tipo) => {
      setTipoModal("DETALLETAREA");
      setNombreTarea(labelSeleccionado);

      const body = {
        sucursal,
        fecha: filtroFecha,
        nombre: labelSeleccionado.toUpperCase(),
        tipo,
      };

      obtenerCantidadTareasAgrupadasPorNombre(body);
      obtenerTareasEmpleadoPorNombreTarea(body);
      obtenerCantidadTareasAgrupadasPorNombreTareaYEmpleado(body);
      setAbrirModalEstadisticasTareas(true);
    },
    [
      filtroFecha,
      obtenerCantidadTareasAgrupadasPorNombre,
      obtenerCantidadTareasAgrupadasPorNombreTareaYEmpleado,
      obtenerTareasEmpleadoPorNombreTarea,
      sucursal,
    ]
  );

  const obtenerDatosModalDetalleTareas = useCallback(() => {
    if (tipoModal === "DETALLETAREA") {
      setDatosTarjetaMensual(cantidadTareasAgrupadasPorNombre);
      setDataTable(tareasEmpleadoPorNombre);
      setDatosGraficoDispersion(cantidadTareasAgrupadasPorNombreTareaYEmpleado);
      setCargandoDatosGraficoDispersion(
        obteniendoCantidadTareasAgrupadasPorNombreTareaYEmpleado
      );
      setCargandoDataTable(obteniendoTareasEmpleadoPorNombre);
      setCargandoDatosTarjetaMensual(
        obteniendoCantidadTareasAgrupadasPorNombre
      );
    } else if (tipoModal === "MES" || tipoModal === "AÑO") {
      setDatosTarjetaMensual(informacionTareasMensuales);
      setCargandoDatosTarjetaMensual(cargandoInformacionTareasMensuales);
      setDataTable(informacionTareasPorTipo);
      setCargandoDataTable(obteniendoInformacionTareasPorTipo);
      setDatosGraficoDispersion(informacionCantidadTareasEmpleado);
      setCargandoDatosGraficoDispersion(
        obteniendoInformacionCantidadTareasEmpleado
      );
    }
  }, [
    cantidadTareasAgrupadasPorNombre,
    cantidadTareasAgrupadasPorNombreTareaYEmpleado,
    cargandoInformacionTareasMensuales,
    informacionCantidadTareasEmpleado,
    informacionTareasMensuales,
    informacionTareasPorTipo,
    obteniendoCantidadTareasAgrupadasPorNombre,
    obteniendoCantidadTareasAgrupadasPorNombreTareaYEmpleado,
    obteniendoInformacionCantidadTareasEmpleado,
    obteniendoInformacionTareasPorTipo,
    obteniendoTareasEmpleadoPorNombre,
    tareasEmpleadoPorNombre,
    tipoModal,
  ]);

  const manejarClickDetalleTareas = useCallback(
    (estadoTareaAux) => {
      const titulo = `Tareas internas ${estadoTareaAux.toLowerCase()} sucursal ${sucursal}`;

      let estadoTarea = "";
      if (estadoTareaAux.toLowerCase() === "pendientes") {
        estadoTarea = "PENDIENTE";
      } else if (estadoTareaAux.toLowerCase() === "canceladas o rechazadas") {
        estadoTarea = "CANCELADO O RECHAZADO";
      } else if (estadoTareaAux.toLowerCase() === "terminadas") {
        estadoTarea = ["FACTURADO", "TERMINADA"];
      } else {
        estadoTarea = estadoTareaAux.toUpperCase();
      }

      // Crear el objeto `body` con los datos necesarios
      const body = {
        sucursal,
        fecha: filtroFecha,
        estadoTarea,
        tipoTarea: "INTERNA",
      };

      // Llamadas a las funciones de obtención de datos
      obtenerTareasEmpleados(body);
      obtenerDispersionEmpleados(body);

      // Actualizar el estado del componente
      setTituloModalDetalleTareasPorEstado(titulo);
      setAbrirModalDetalleTareaPorEstado(true);
    },
    [filtroFecha, obtenerDispersionEmpleados, obtenerTareasEmpleados, sucursal]
  );

  useEffect(() => {
    if (isOpen) {
      obtenerDatosModalDetalleTareas();
    }
  }, [isOpen, obtenerDatosModalDetalleTareas]);

  const abrirModalDetalleTareasEmpleado = useCallback(
    (detalleEmpleado, estadoTarea) => {
      const [nombre, rut] = detalleEmpleado.match(/(.*) \((.*)\)/).slice(1, 3);

      if (!validateRut(rut)) {
        throw new Error("Error: rut del gráfico no válido");
      }

      const { digits, verifier } = deconstructRut(rut);

      const rutFormateado = `${digits}${verifier}`;

      const body = {
        sucursal,
        estado: estadoTarea.toUpperCase(),
        rut: rutFormateado,
        fecha: filtroFecha,
      };

      const titulo = `Tareas estado: ${estadoTarea} de ${nombre}`;

      setTituloModalDetalleTareasEmpleado(titulo);
      obtenerTareasEmpleadoGrafico(body);
      setModalDetalleTareasEmpleado(true);
    },
    [filtroFecha, obtenerTareasEmpleadoGrafico, sucursal]
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-detalle-tareas-sucursal"
        id="dialog-detalle-tareas-sucursal"
        fullWidth
        maxWidth="xl"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}></Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{
              display: "grid",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <DialogTitle
              // style={styles.title}
              htmlFor="dialog-detalle-tareas-sucursal"
            >
              {`Detalle tareas sucursal ${sucursal}`}
            </DialogTitle>
          </Grid>
          <Grid item xs={12} md={4} lg={4} marginTop={2}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item marginRight={3}>
                <CalendarField
                  id="fecha-filtro"
                  label="Filtrar por fecha"
                  value={filtroFecha}
                  onChange={handleChangeFechaFacturacion}
                  inputFormat="MM/yyyy"
                  views={["month", "year"]}
                  tamanio={12}
                  maxDate={dayjs().format("YYYY/MM/DD")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <AppWidgetTareas
                    title="Tareas mensuales totales"
                    loading={cargandoDataTarjetaTotalTareas}
                    onClickCard={() => manejarEstadisticasTareaTarjeta("MES")}
                    cursor="pointer"
                    total={
                      dataTarjetaTotalTareas &&
                      dataTarjetaTotalTareas.find(
                        (data) => data.tipoTarea === "MES"
                      ) &&
                      !cargandoDataTarjetaTotalTareas
                        ? dataTarjetaTotalTareas.find(
                            (data) => data.tipoTarea === "MES"
                          ).cantidad
                        : 0
                    }
                    icon={<TareasMensualesIlustracion />}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <AppWidgetTareas
                    title="Tareas anuales totales"
                    loading={cargandoDataTarjetaTotalTareas}
                    total={
                      dataTarjetaTotalTareas &&
                      dataTarjetaTotalTareas.find(
                        (data) => data.tipoTarea === "AÑO"
                      ) &&
                      !cargandoDataTarjetaTotalTareas
                        ? dataTarjetaTotalTareas.find(
                            (data) => data.tipoTarea === "AÑO"
                          ).cantidad
                        : 0
                    }
                    icon={<TareasAnualesIlustracion />}
                    onClickCard={() => manejarEstadisticasTareaTarjeta("AÑO")}
                    cursor="pointer"
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} ld={6}>
                      <AppDetalleDistribucionTareas
                        title="Distribucion de tareas mensuales"
                        loading={cargandoDataDistribucionTareas}
                        tipo="MES"
                        onClickLabelGrafico={manejarLabelSeleccionadoGrafico}
                        chart={{
                          series:
                            !cargandoDataDistribucionTareas &&
                            dataDistribucionTareas &&
                            dataDistribucionTareas?.tareasMensuales
                              ? dataDistribucionTareas.tareasMensuales
                              : [],
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} ld={6}>
                      <AppDetalleDistribucionTareas
                        title="Distribucion de tareas anuales"
                        onClickLabelGrafico={manejarLabelSeleccionadoGrafico}
                        loading={cargandoDataDistribucionTareas}
                        tipo="AÑO"
                        chart={{
                          series:
                            !cargandoDataDistribucionTareas &&
                            dataDistribucionTareas &&
                            dataDistribucionTareas?.tareasAnuales
                              ? dataDistribucionTareas.tareasAnuales
                              : [],
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <AppDetalleTareas
                loading={cargandoInformacionTareasInternas}
                total={
                  !cargandoInformacionTareasInternas &&
                  dataInformacionTareasInternas
                    ? dataInformacionTareasInternas.totalTareas
                    : 0
                }
                title="Tareas internas"
                subheader="Se muestra un detalle global de las tareas internas de la sucursal"
                chart={{
                  series:
                    !cargandoInformacionTareasInternas &&
                    dataInformacionTareasInternas
                      ? dataInformacionTareasInternas.porcentajeTerminadas
                      : 0,
                }}
                data={
                  !cargandoInformacionTareasInternas &&
                  dataInformacionTareasInternas &&
                  dataInformacionTareasInternas.distribucionTareas
                    ? dataInformacionTareasInternas.distribucionTareas.map(
                        (tarea) => ({ ...tarea, icon: iconos[tarea.name] })
                      )
                    : defaultDataTareas
                }
                cursor="pointer"
                manejarClickStack={manejarClickDetalleTareas}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AppGraficoDeBarras
                title="Estadísticas generales empleado"
                subheader="Se muestran las estadísticas de tareas de los empleados hasta la fecha"
                loading={cargandoDataGraficoEstadisticasTareas}
                showDataLabels={true}
                shouldShowSelect={true}
                dataTypes="numeros"
                manejarClickGrafico={abrirModalDetalleTareasEmpleado}
                usePagination={true}
                itemsPerPage={5}
                optionsSelect={[
                  {
                    type: "cantidad",
                    label: "Cantidad",
                  },
                  {
                    type: "complejidad",
                    label: "Complejidad",
                  },
                ]}
                chart={{
                  categories:
                    !cargandoDataGraficoEstadisticasTareas &&
                    dataGraficoEstadisticasTareas
                      ? dataGraficoEstadisticasTareas.labels
                      : [],
                  series: {
                    data:
                      !cargandoDataGraficoEstadisticasTareas &&
                      dataGraficoEstadisticasTareas
                        ? dataGraficoEstadisticasTareas.datasets
                        : [],
                    datasetsComplexity:
                      !cargandoDataGraficoEstadisticasTareas &&
                      dataGraficoEstadisticasTareas
                        ? dataGraficoEstadisticasTareas.datasetsComplexity
                        : [],
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {abrirModalEstadisticasTareas && (
        <ModalEstadisticasTareas
          isOpen={abrirModalEstadisticasTareas}
          setOpen={setAbrirModalEstadisticasTareas}
          dataTable={dataTable}
          datosGraficoDispersion={datosGraficoDispersion}
          cargandoDatosGraficoDispersion={cargandoDatosGraficoDispersion}
          loadingTable={cargandoDataTable}
          datosTarjetaMensual={datosTarjetaMensual}
          cargandoDatosTarjetaMensual={cargandoDatosTarjetaMensual}
          iconos={iconos}
          defaultDataTareas={defaultDataTareas}
          tipoModal={tipoModal}
          nombreTarea={nombreTarea}
          setDatosTarjetaMensual={setDatosTarjetaMensual}
          setDatosGraficoDispersion={setDatosGraficoDispersion}
          setDataTable={setDataTable}
          sucursal={sucursal}
          filtroFecha={filtroFecha}
        />
      )}
      {abrirModalDetalleTareaPorEstado && (
        <ModalDetalleTareaPorEstado
          isOpen={abrirModalDetalleTareaPorEstado}
          setOpen={setAbrirModalDetalleTareaPorEstado}
          titulo={tituloModalDetalleTareasPorEstado}
          dataTable={tareasEmpleado}
          datosGraficoDispersion={dispersionTareasEmpleado}
          cargandoDatosGraficoDispersion={obteniendoDispersionTareasEmpleado}
          loadingDataTable={obteniendoTareasEmpleado}
        />
      )}
      {modalDetalleTareasEmpleado && (
        <ModalDetalleTareasEmpleado
          isOpen={modalDetalleTareasEmpleado}
          setOpen={setModalDetalleTareasEmpleado}
          titulo={tituloModalDetalleTareasEmpleado}
          data={tareasEmpleadoGrafico}
          loadingData={obteniendoTareasEmpleadoGrafico}
        />
      )}
    </>
  );
};

ModalDetalleTareaSucursal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  dataTarjetaTotalTareas: PropTypes.array,
  dataDistribucionTareas: PropTypes.object,
  dataInformacionTareasInternas: PropTypes.object,
  dataGraficoEstadisticasTareas: PropTypes.object,
  cargandoDataGraficoEstadisticasTareas: PropTypes.bool,
  cargandoInformacionTareasInternas: PropTypes.bool,
  cargandoDataDistribucionTareas: PropTypes.bool,
  cargandoDataTarjetaTotalTareas: PropTypes.bool,
  sucursal: PropTypes.string,
  filtroFecha: PropTypes.string,
  setFiltroFecha: PropTypes.func,
};

export default ModalDetalleTareaSucursal;
