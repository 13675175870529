// material
import { Grid, Box } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { useState } from "react";
import SelectPlan from "./SelectPlan";
import CardPlan from "./CardPlan";
import FormDefinePrice from "./FormDefinePrice";
import { FormFrecuenciaPlan } from "./FormFrecuenciaPlan";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

// ----------------------------------------------------------------------

export default function FormPlan(props) {
  const { dataPlan, actualizarDataPlan, formik } = props;
  const [selectPlan, setSelectPlan] = useState(false);

  const updateSelectedPlan = (data) => {
    actualizarDataPlan({ ...data });
    formik.setFieldValue("precioFijoFinal", data?.valor ? data.valor : 0);
    formik.setFieldValue("precioFinal", data?.valor ? data.valor : 0);
    formik.setFieldValue("descuentoFinal", 0);
    formik.setFieldValue(
      "tipoMonedaFinal",
      data?.tipoMoneda ? data.tipoMoneda : ""
    );

    if (data?.valor) {
      setSelectPlan(true);
    } else {
      setSelectPlan(false);
    }
  };

  return (
    <RootStyle>
      <Box
        sx={{
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        {" "}
        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          <Grid item xs={11}>
            <SelectPlan
              updateDataPlan={updateSelectedPlan}
              error={selectPlan}
              formik={formik}
            />
          </Grid>

          <Grid item xs={11}>
            {" "}
            {dataPlan?.nombre ? (
              <Grid alignItems="center" container direction="row" spacing={3}>
                <Grid item xs={10} md={6}>
                  {" "}
                  <CardPlan plan={dataPlan} />
                </Grid>
                <Grid item xs={10} md={6}>
                  {" "}
                  <Grid item xs={12}>
                    <h4>Definir Precio </h4>
                  </Grid>
                  <FormDefinePrice formik={formik} />
                </Grid>
                <Grid item xs={12}>
                  <h4>Definir Frecuencia </h4>
                </Grid>
                <Grid item xs={10} md={6}>
                  <FormFrecuenciaPlan formik={formik} />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </RootStyle>
  );
}
