import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
} from "@material-ui/core";
import moment from "moment";
export default function FacturasPendientesPagoDialog(props) {
  const { open, setOpen, facturasPendientes } = props;

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }


  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Facturas Pendientes de Pago</DialogTitle>
      <DialogContent>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Folio</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Fecha Facturación</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facturasPendientes.map((row, id) => (
                <TableRow
                  key={"facturasPendientes_" + id}
                >
                  <TableCell component="th" scope="row">
                    {row.folio}
                  </TableCell>
                  <TableCell>
                    {formatNumber(row.total)}
                  </TableCell>
                  <TableCell>
                    {
                      row.fechaFacturacion === "" ? (row.fechaFacturacion) :
                        moment(row.fechaFacturacion, "YYYY/MM/DD").format("DD/MM/YYYY")
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <DialogActions>
          <Button
            onClick={() => {
              localStorage.setItem("lookBills", JSON.stringify(false));
              setOpen(false)
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
