import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const gavsApi = createApi({
  reducerPath: "gavsApi",
  baseQuery: baseQuery(`${API_SERVER}/gavs`),
  tagTypes: ["gavsInfo"],
  endpoints: (builder) => ({
    obtenerGavs: builder.query({
      query: () => "/",
      providesTags: ["gavsInfo"],
    }),
    crearNuevoGav: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["gavsInfo"],
    }),
    editarGav: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["gavsInfo"],
    }),
    eliminarGav: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["gavsInfo"],
    }),
    eliminarImagenGav: builder.mutation({
      query: ({ id }) => ({
        url: `/eliminarImagenGav/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["gavsInfo"],
    }),
  }),
});

export const {
  useCrearNuevoGavMutation,
  useObtenerGavsQuery,
  useLazyObtenerGavsQuery,
  useEditarGavMutation,
  useEliminarGavMutation,
  useEliminarImagenGavMutation,
} = gavsApi;
