import {
  alpha,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { varFade } from "src/components/animate";
import FileThumbnail from "src/components/file-thumbnail/FileThumbnail";
import { fileData } from "src/components/file-thumbnail/utils";
import Iconify from "src/components/iconify";
import closeFill from "@iconify/icons-eva/close-fill";
import downloadFill from "@iconify/icons-eva/download-fill";
import checkCircle from "@iconify/icons-eva/checkmark-circle-2-outline";
import cancel from "@iconify/icons-eva/close-circle-outline";
import { fData } from "src/utils/formatNumber";

// ----------------------------------------------------------------------

const FileStatusIcon = ({ status }) => {
  switch (status) {
    case "uploading":
      return <CircularProgress size={20} />;
    case "success":
      return (
        <IconButton
          edge="end"
          size="small"
          color="success"
          sx={{ pointerEvents: "none" }}
        >
          <Iconify icon={checkCircle} />
        </IconButton>
      );
    case "error":
      return (
        <IconButton
          edge="end"
          size="small"
          color="error"
          sx={{ pointerEvents: "none" }}
        >
          <Iconify icon={cancel} />
        </IconButton>
      );
    default:
      return null;
  }
};

MultiFilePreview.propTypes = {
  sx: PropTypes.object,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  thumbnail: PropTypes.bool,
  fileStatuses: PropTypes.object,
};

export default function MultiFilePreview({
  thumbnail,
  files,
  onRemove,
  onDownload,
  fileStatuses = {}, // { fileId: { status: 'uploading' | 'success' | 'error', message?: string } }
  sx,
}) {
  if (!files?.length) {
    return null;
  }

  return (
    <AnimatePresence initial={true}>
      {files.map((file) => {
        const { key, name = "", size = 0 } = fileData(file);
        const isNotFormatFile = typeof file === "string";
        const fileStatus = fileStatuses[key] || {};

        if (thumbnail) {
          return (
            <Stack
              key={key}
              component={motion.div}
              {...varFade().inUp}
              alignItems="center"
              display="inline-flex"
              justifyContent="center"
              sx={{
                m: 0.5,
                width: 80,
                height: 80,
                borderRadius: 1.25,
                overflow: "hidden",
                position: "relative",
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                ...sx,
              }}
            >
              <FileThumbnail
                tooltip
                imageView
                file={file}
                sx={{ position: "absolute" }}
                imgSx={{ position: "absolute" }}
              />

              {/* Status indicator */}
              <div className="absolute top-2 right-2">
                <FileStatusIcon status={fileStatus.status} />
              </div>

              {onRemove && fileStatus.status !== "uploading" && (
                <IconButton
                  size="small"
                  onClick={() => onRemove(file)}
                  sx={{
                    top: 4,
                    right: 4,
                    p: "1px",
                    position: "absolute",
                    color: (theme) => alpha(theme.palette.common.white, 0.72),
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                    "&:hover": {
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    },
                  }}
                >
                  <Iconify icon={closeFill} width={16} />
                </IconButton>
              )}
            </Stack>
          );
        }

        return (
          <Stack
            key={key}
            component={motion.div}
            {...varFade().inUp}
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              my: 1,
              px: 1,
              py: 0.75,
              borderRadius: 0.75,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
              ...(fileStatus.status === "error" && {
                borderColor: "error.light",
                bgcolor: "error.lighter",
              }),
              ...(fileStatus.status === "success" && {
                borderColor: "success.light",
                bgcolor: "success.lighter",
              }),
              ...sx,
            }}
          >
            <FileThumbnail file={file} />

            <Stack flexGrow={1} sx={{ minWidth: 0 }}>
              <Typography variant="subtitle2" noWrap>
                {isNotFormatFile ? file : name}
              </Typography>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {isNotFormatFile ? "" : fData(size)}
                </Typography>

                {fileStatus.message && (
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        fileStatus.status === "error"
                          ? "error.main"
                          : "text.secondary",
                    }}
                  >
                    {fileStatus.message}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <div className="flex items-center gap-2">
              <FileStatusIcon status={fileStatus.status} />
              {onRemove && fileStatus.status !== "uploading" && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => onRemove(file)}
                >
                  <Iconify icon={closeFill} />
                </IconButton>
              )}

              {onDownload && fileStatus.status === "success" && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => onDownload()}
                >
                  <Iconify icon={downloadFill} />
                </IconButton>
              )}
            </div>
          </Stack>
        );
      })}
    </AnimatePresence>
  );
}
