import React, { useState } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  Grid,
  Tooltip,
  Container,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import eyeFill from "@iconify/icons-eva/eye-fill";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { filter } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../../Scrollbar";
import { TablaHead, TablaToolbar } from "../../_dashboard/tablas";
import MyConfirmationDialog from "../../MyConfirmationDialog";
import { API_SERVER } from "../../../utils/urlBack_End";
import axios from "axios";
import NuevaBitacora from "./NuevaBitacora";
import EditarBitacora from "./EditarBitacora";
import VerMasBitacora from "./VerMasBitacora";

const TABLE_HEAD = [
  { id: "fecha", label: "Fecha", alignRight: false },
  { id: "descripcion", label: "Descripción", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.descripcion.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const styles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
  icon: {
    fontSize: "28px",
  },
}));

export default function TablaBitacora(props) {
  const {
    bitacoras,
    setBitacoras,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage,
    vista,
  } = props;
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const [mostrarNuevaBitacora, setMostrarNuevaBitacora] = useState(false);
  const [mostrarEditarBitacora, setMostrarEditarBitacora] = useState(false);
  const [mostrarVerMasBitacora, setMostrarVerMasBitacora] = useState(false);
  const [descripcionMostrar, setDescripcionMostrar] = useState("");
  const [selectedId, setSelectedId] = useState("");



  function obtenerFecha() {
    const fechaActualAUX = new Date();
    let anio = fechaActualAUX.getFullYear();
    let mes = fechaActualAUX.getMonth() + 1;
    let dia = fechaActualAUX.getDate();

    if (mes === 1 ||
      mes === 2 ||
      mes === 3 ||
      mes === 4 ||
      mes === 5 ||
      mes === 6 ||
      mes === 7 ||
      mes === 8 ||
      mes === 9) {
      mes = "0" + mes;
    }

    if (dia === 1 ||
      dia === 2 ||
      dia === 3 ||
      dia === 4 ||
      dia === 5 ||
      dia === 6 ||
      dia === 7 ||
      dia === 8 ||
      dia === 9) {
      dia = "0" + dia;
    }
    return anio + "/" + mes + "/" + dia;

  }
  const fechaActual = obtenerFecha();

  const [page, setPage] = useState(0);
  const [bitacoraSeleccionada, setBitacoraSeleccionada] = useState(null);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale] = useState("esES");
  const classes = styles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = bitacoras?.map((n) => n.fecha);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bitacoras?.length) : 0;

  const filteredUsers = applySortFilter(
    bitacoras,
    getComparator(order, orderBy),
    filterName
  );

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;


  const handleDeleteBitacora = (fecha) => {
    setSelectedId(fecha);
    setShowRemovalConfirmation(true);

  };

  const eliminarBitacora = () => {
    axios
      .delete(`${API_SERVER}/empleados/bitacora/${selectedId}/${empleado.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();

        axios
          .get(`${API_SERVER}/empleados/bitacora/${empleado.rut}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem("token")
            }
          })
          .then(function (response2) {
            if (response2.data === -2) window.location.reload();
            setBitacoras(response2.data);
            setColor("success");
            setMessage("Bitácora Eliminada Correctamente");
            setShowMessagePermiso(true);
            setShowRemovalConfirmation(false);
          }).catch(() => {
            setColor("success");
            setMessage("Bitácora Eliminada Correctamente");
            setShowMessagePermiso(true);
            setShowRemovalConfirmation(false);

          });

      });
  };


  return (
    <div>
      <Card>
        <Typography variant="h5" component="h2" className={classes.title}>
          {vista === "EMPLEADO"
            ? "Mi Bitácora de Permisos"
            : "Bitácora del Empleado"}
        </Typography>
        <Grid
          container
          style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs md>
            <TablaToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Grid>

          {vista !== "EMPLEADO" && (
            <Grid item xs md>
              <Grid container direction="row-reverse">
                <Grid item xs={12} md={6}>
                  <Container style={{ marginLeft: ".5rem" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setMostrarNuevaBitacora(true);
                      }}
                      startIcon={<Icon icon={plusFill} />}
                      style={{ minWidth: "170px" }}
                    >
                      Nueva Bitácora
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TablaHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={bitacoras?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => {
                    const {
                      fecha,
                      descripcion
                    } = row;
                    const isItemSelected = selected.indexOf(fecha) !== -1;
                    let descripcionFinal = descripcion;
                    if (descripcionFinal.length >= 50) {
                      descripcionFinal = descripcionFinal.slice(0, 50) + "..."

                    }

                    return (
                      <TableRow

                        key={"Bitacora" + idx}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography variant="subtitle2" noWrap>
                            {fecha}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {descripcionFinal}

                          {descripcionFinal.length >= 50 && (
                            <Button
                              onClick={() => {
                                setDescripcionMostrar(descripcion)
                                setMostrarVerMasBitacora(true);

                              }}
                            >
                              Ver más
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {vista !== "EMPLEADO" && fechaActual === row.fecha && (
                            <Tooltip title="Editar">
                              <Button
                                color="info"
                                onClick={() => {
                                  setBitacoraSeleccionada(row);
                                  setMostrarEditarBitacora(true)
                                }}
                              >
                                <EditIcon />
                              </Button>
                            </Tooltip>
                          )}

                          {vista !== "EMPLEADO" && (
                            <Tooltip title="Eliminar">
                              <Button
                                color="error"
                                onClick={() => handleDeleteBitacora(row.fecha)}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          )}


                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No se han agregado elementos
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={bitacoras?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>

      {mostrarNuevaBitacora && (
        <NuevaBitacora
          open={mostrarNuevaBitacora}
          setOpen={setMostrarNuevaBitacora}
          setBitacoras={setBitacoras}
          empleado={empleado}
          setShowMessagePermiso={setShowMessagePermiso}
          setColor={setColor}
          setMessage={setMessage}
        />
      )}

      {
        mostrarEditarBitacora && (
          <EditarBitacora
            open={mostrarEditarBitacora}
            setOpen={setMostrarEditarBitacora}
            bitacora={bitacoraSeleccionada}
            setBitacoras={setBitacoras}
            empleado={empleado}
            setShowMessagePermiso={setShowMessagePermiso}
            setColor={setColor}
            setMessage={setMessage}
          />
        )
      }

      {mostrarVerMasBitacora && (
        <VerMasBitacora
          open={mostrarVerMasBitacora}
          setOpen={setMostrarVerMasBitacora}
          descripcion={descripcionMostrar}
        />
      )}

      <MyConfirmationDialog
        open={showRemovalConfirmation}
        onSubmit={() => eliminarBitacora()}
        onClose={() => setShowRemovalConfirmation(false)}
        title="Eliminar bitácora"
        content="¿Está seguro de eliminar la bitácora?"
      />
    </div>
  );
}
