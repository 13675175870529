import { Container } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import TableEmpresa from "./TableEmpresa";

import { FormNewEmpresa } from "./FormNewEmpresa";

export default function FormEmpresa(props) {
  const { updateDataEmpresa, dataEmpresa } = props;

  return (
    <Container>
      <FormNewEmpresa
        updateDataEmpresa={updateDataEmpresa}
        dataEmpresa={dataEmpresa}
      />
      <TableEmpresa
        updateDataEmpresa={updateDataEmpresa}
        dataEmpresa={dataEmpresa}
      />
    </Container>
  );
}

FormEmpresa.propTypes = {
  updateDataEmpresa: PropTypes.func.isRequired,
  dataEmpresa: PropTypes.array.isRequired,
};
