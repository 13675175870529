import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";

export default function PagosFacturaAsociadoCliente(props) {
  const { open, setOpen, factura, tipoFactura } = props;

  const [pagos, setPagos] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      tipoFactura &&
      tipoFactura === "PLAN" &&
      factura.folio &&
      factura.folio !== ""
    ) {
      axios
        .get(`${API_SERVER}/pagos/facturaPlanes/${factura.folio}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
          }
        })
        .then(function (response) {
          if (response.data === 2) window.location.reload();

          setPagos(response.data);
        });
    } else if (tipoFactura && tipoFactura === "TAREA") {
      axios
        .get(`${API_SERVER}/pagos/facturaTareas/${factura.id}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
          }
        })
        .then(function (response) {
          if (response.data === 2) window.location.reload();

          setPagos(response.data);
        });
    }
  }, [factura.id, tipoFactura, factura.folio]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Pagos Factura {factura.folio}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Total ($)</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Fecha Pago</TableCell>
                <TableCell>Método de Pago</TableCell>
                <TableCell>Empleado Recibe Pago</TableCell>
                <TableCell>Estado del Pago</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagos.map((row, index) => (
                <TableRow key={"tablaPagos_" + index}>
                  <TableCell>{row.valorPesos}</TableCell>
                  <TableCell>{row.fecha}</TableCell>
                  <TableCell>{row.fechaPago}</TableCell>
                  <TableCell>{row.metodoPago}</TableCell>
                  <TableCell>{row.empleadoRecibePago}</TableCell>
                  <TableCell>{row.estado}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
