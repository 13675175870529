import React from "react";
import ComingSoonCard from "./coming-soon-card";
import ComingSoonAnimated from "./coming-soon-animated";
import ComingSoonMinimal from "./coming-soon-minimal";
import ComingSoon from "./coming-soon";

const styles = {
  MINIMAL: "minimal",
  CARD: "card",
  ANIMATED: "animated",
  DEFECT: "defect",
};

const ComingSoonWrapper = ({ children, style = styles.DEFECT }) => {
  switch (style) {
    case styles.CARD:
      return <ComingSoonCard>{children}</ComingSoonCard>;
    case styles.ANIMATED:
      return <ComingSoonAnimated>{children}</ComingSoonAnimated>;
    case styles.MINIMAL:
      return <ComingSoonMinimal>{children}</ComingSoonMinimal>;
    default:
      return <ComingSoon>{children}</ComingSoon>;
  }
};

export { ComingSoonWrapper, styles as comingSoonStyles };
