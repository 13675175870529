import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { tiposApi } from "src/services/tiposApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
export default function AgregarPlan(props) {
  const { open, setOpen, monedas } = props;
  const [nombrePlan, setNombrePlan] = useState("");
  const [valorPlan, setValorPlan] = useState(0);
  const [tipoMoneda, setTipoMoneda] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const [agregarPlan] = tiposApi.useAddTipoPlanMutation();

  const handleChangeTipoMoneda = (event) => {
    setTipoMoneda(event.target.value);
  };

  const handleChangeNombrePlan = (event) => {
    setNombrePlan(event.target.value);
  };

  const handleChangValorPlan = (event) => {
    setValorPlan(event.target.value);
  };

  const handleChangeDescripcionPlan = (event) => {
    setDescripcion(event.target.value);
  };

  const guardarPlan = () => {
    if (
      nombrePlan === "" ||
      valorPlan === "" ||
      tipoMoneda === "" ||
      descripcion === ""
    ) {
      AlertaToast({
        action: 'Todos los campos deben estar completos',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialogTipoPlanAgregar'),
      }).fire()
    } else {
      let valorFinal = valorPlan;
      if (String(valorFinal).includes(",")) {
        valorFinal = String(valorFinal).replaceAll(",", ".");
        valorFinal = parseInt(valorFinal);
      }
      let plan = {
        nombrePlan: nombrePlan.toUpperCase(),
        valorPlan: valorFinal,
        tipoMoneda: tipoMoneda.toUpperCase(),
        descripcion: descripcion,
      };

      AlertaModal({
        title: 'Agregar el plan',
        confirmButtonText: 'Sí, agregar',
        target: document.getElementById('dialogTipoPlanAgregar'),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            agregarPlan(plan)
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: 'Plan',
                  action: 'agregado'
                }).fire()
                setOpen(false);
              }).catch(() => {
                AlertaToast({
                  action: 'Error en el servidor',
                  fullText: true,
                  type: 'error',
                  target: document.getElementById('dialogTipoPlanAgregar'),
                }).fire()
              })

          }
        })
    }
  };

  const cancelar = () => {
    setNombrePlan("");
    setValorPlan(0);
    setTipoMoneda("");
    setDescripcion("");
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        id="dialogTipoPlanAgregar"
      >
        <DialogTitle id="form-dialog-title">Nuevo Plan</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Plan"
            type="text"
            value={nombrePlan}
            onChange={handleChangeNombrePlan}
            fullWidth
            required
          />

          <TextField
            margin="dense"
            label="Valor Plan"
            value={valorPlan}
            onChange={handleChangValorPlan}
            type="number"
            fullWidth
            required
          />

          <TextField
            style={{ marginTop: "10px", width: "100%" }}
            id="outlined-multiline-static"
            label="Descripción Plan"
            multiline
            rows={4}
            variant="outlined"
            required
            value={descripcion}
            onChange={handleChangeDescripcionPlan}
          />

          <FormControl style={{ marginTop: "10px", width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Tipo Moneda (*)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ marginTop: "10px" }}
              value={tipoMoneda}
              onChange={handleChangeTipoMoneda}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {monedas.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.nombre}>
                    {element.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} color="error">
            Cancelar
          </Button>

          <Button onClick={guardarPlan} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
}
