import React, { useEffect, useState, useRef } from "react";
// material
import { Grid, Button, LinearProgress, Fade, Typography } from "@material-ui/core";
import FormEmpresa from "../propuesta/FormEmpresa";
import {
  crearRelacionPropuestaPlan,
  eliminarRelacionPropuestaPlan,
  formatearDatosPorpuestaPlan,
  obtenerEmpleadosSucursal,
  obtenerRelacionPropuestaPlan,
} from "../propuesta/Consultas";
import { deconstructRut } from "@fdograph/rut-utilities";
import { Alerta } from "../Alerta";
import { useSelector } from "react-redux";

function comparerArray(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return (
          other.rut === current.rut && other.plan.nombre === current.plan.nombre
        );
      }).length === 0
    );
  };
}

export default function VerPropuestaPlanes(props) {
  const { propuesta } = props;
  const {
    sucursal: sucursalObtenida,
  } = useSelector((state) => state.auth.user);
  const sucursal = sucursalObtenida.refSucursal;
  const [planesEmpresa, setPlanesEmpresa] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alerta, setAlerta] = useState({ color: "error", mensaje: "" });

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let empleadosAUX = [];
    async function obtenerEmpleados() {
      empleadosAUX = await obtenerEmpleadosSucursal(sucursal);
      if (isMounted.current) {
        setEmpleados(empleadosAUX);
      }
    }

    obtenerEmpleados();
  }, [sucursal]);

  useEffect(() => {
    let planesEmpresaAUX = [];
    async function obtenerPlanes() {
      planesEmpresaAUX = await obtenerRelacionPropuestaPlan(propuesta.id);
      if (isMounted.current) {
        setPlanesEmpresa(planesEmpresaAUX);
      }
    }

    obtenerPlanes();
  }, [propuesta.id]);

  const isNew = (newPlanEmpresa) => {
    setLoading(true);
    let error = [];
    Promise.all(
      (error = newPlanEmpresa.map((plan) => {
        const datosPlan = formatearDatosPorpuestaPlan(plan);

        return crearRelacionPropuestaPlan({
          ...datosPlan,
          refPropuesta: propuesta.id,
        });
      }))
    ).then(() => {
      setPlanesEmpresa([...planesEmpresa, ...newPlanEmpresa]);

      setLoading(false);
      setAlerta({
        color: "success",
        mensaje: "Plan agregado correctamente.",
      });
      setShowAlert(true);
    }).catch(() => {
      setLoading(false);
      setAlerta({
        color: "error",
        mensaje: "Error al agregar plan.",
      });
      setShowAlert(true);

    });
  };

  const isDelete = async (planesEmpresaDelete) => {
    let error = [];
    setLoading(true);

    Promise.all(
      (error = planesEmpresaDelete.map(async (plan) => {
        return await eliminarRelacionPropuestaPlan(
          propuesta.id,
          plan.plan.nombre,
          deconstructRut(plan.rut).digits +
          "" +
          deconstructRut(plan.rut).verifier
        );
      }))
    ).then(() => {
      setLoading(false);
      // eslint-disable-next-line array-callback-return
      error.map((e) => {
        e.then((result) => {
          if (result !== true) {
            setAlerta({
              color: "error",
              mensaje: "Error al eliminar cliente con el plan",
            });
            setShowAlert(true);
          } else {
            setAlerta({
              color: "success",
              mensaje: "Cliente eliminado correctamente con el plan",
            });
            setShowAlert(true);
          }
        });
      });
    });
  };

  const updateEmpresas = (datos) => {
    const newPlanEmpresa = datos.filter(comparerArray(planesEmpresa));
    const planesEmpresaDelete = planesEmpresa.filter(comparerArray(datos));

    if (newPlanEmpresa.length > 0) isNew(newPlanEmpresa);
    if (planesEmpresaDelete.length > 0) isDelete(planesEmpresaDelete);
  };

  return (
    <Grid container style={{ marginTop: "2rem" }}>
      <Alerta
        color={alerta.color}
        message={alerta.mensaje}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      <Grid item>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>

        <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
          Planes
        </Typography>
        <Button style={{ display: "none" }}>

        </Button>
        <FormEmpresa
          dataEmpresa={planesEmpresa}
          updateDataEmpresa={updateEmpresas}
          empleados={empleados}
        />

      </Grid>

    </Grid>
  );
}
