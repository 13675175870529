import React, { useState } from "react";

// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Autocomplete,
  TextField,
  Container,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";

const styles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
    marginTop: 60,
  },
  boton: {
    maxHeight: "3.2rem",
    minWidth: "100%",
    marginTop: 17,
  },
  selectMenu: {
    minWidth: "100%",
    marginBottom: 12,
  },
  Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
}));

export default function ModalPlanMasivo(props) {
  const {
    openPlanMasivo,
    setOpenPlanMasivo,
    setOpenPlanes,
    setOpenSubirExcel,
    setColor,
    setMessage,
    setAlerta,
  } = props;

  const [forma, setForma] = useState({
    label: "De Forma Manual",
    value: "MANUAL",
  });

  const classes = styles();
  const options = [
    { label: "De Forma Manual", value: "MANUAL" },
    { label: "Subir Excel", value: "EXCEL" },
  ];

  const siguiente = () => {
    if (forma !== null) {
      if (forma.value === "MANUAL") {
        setOpenPlanes(true);
      } else {
        setOpenSubirExcel(true);
      }
    } else {
      setColor("error");
      setMessage("Debe seleccionar un método");
      setAlerta(true);
    }
  };

  return (
    <>
      <Dialog
        open={openPlanMasivo}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">
          Seleccione un método de creación del plan masivo
        </DialogTitle>
        <DialogContent>
          <Container className={classes.Container}>
            <Autocomplete
              disablePortal
              value={forma}
              onChange={(event, newValue) => {
                setForma(newValue);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              id="combo-box-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione un método" />
              )}
            />
          </Container>
        </DialogContent>

        <DialogActions>
          <Container style={{ margin: 20 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
              <Button
                color="inherit"
                onClick={() => setOpenPlanMasivo(false)}
                sx={{ mr: 1 }}
              >
                Cerrar
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <LoadingButton onClick={siguiente}>Siguiente</LoadingButton>
            </Box>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
}
