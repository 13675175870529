import React, { useState } from "react";
import Alert from "@material-ui/core/Alert";
import LoadingButton from "@material-ui/lab/LoadingButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { API_SERVER } from "../../../utils/urlBack_End";

import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

export default function ForgotPassword({ open, onClose, title }) {
  const [error, setError] = useState("");

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
  });

  /**
   * Objeto encargado de validar los valores ingresados al formulario, según las
   * validaciones entregadas por LoginSchema.
   */
  const formik = useFormik({
    initialValues: {
      rut: "",
      correo: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  /**
   * Se encarga de guardar los datos ingresados por el usuario, ya validados
   * a la variable empleado, que es la que se guardará en la bd.
   */
  const guardarDatos = async () => {
    setSubmitting(true);
    setError("");

    const empleado = {
      correo: getFieldProps("correo").value,
      rut:
        deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
        deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
    };

    try {
      const res = await axios.post(API_SERVER + "/recuperarContrasena", {
        ...empleado,
      });
      const msgAux = res.mensaje;
      onClose(msgAux);

    } catch (error) {
      const errorAux =
        "Ha ocurrido un error. Revise que el correo y rut sean correctos.";
      setError(errorAux);

    }


  };

  return (
    <Dialog open={open}>
      <DialogTitle>Recuperar Contraseña</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Rellene el formulario y le enviaremos un correo con instrucciones para
          restablecer su contraseña.
        </DialogContentText>

        {error && (
          <Alert severity="error" style={{ marginTop: 5, marginBottom: 5 }}>
            {error}
          </Alert>
        )}

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              required
              margin="dense"
              id="rut"
              label="Rut"
              fullWidth
              {...getFieldProps("rut")}
              error={Boolean(touched.rut && errors.rut)}
              helperText={touched.rut && errors.rut}
            />
            <TextField
              required
              margin="dense"
              id="correo"
              label="Correo"
              type="email"
              fullWidth
              {...getFieldProps("correo")}
              error={Boolean(touched.correo && errors.correo)}
              helperText={touched.correo && errors.correo}
            />

            <DialogActions style={{ marginTop: 15 }}>
              <LoadingButton
                onClick={() => onClose("")}
                color="error"
                disabled={isSubmitting}
              >
                Cancelar
              </LoadingButton>

              <LoadingButton
                type="submit"
                color="primary"
                loading={isSubmitting}
              >
                Aceptar
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
