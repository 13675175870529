import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { formatAsCLP } from "src/utils/formatNumber";

const TablaRecaudacionMensualGav = ({
  tabIndex,
  data: recaudacionesGav,
  loading: isLoadingRecaudacionesGav,
}) => {
  if (tabIndex !== 2) return null;

  const chart = {
    series: [
      {
        name: "Gasto mes",
        data:
          recaudacionesGav.montos?.length > 0 ? recaudacionesGav.montos : [],
      },
    ],
    options: {
      chart: {
        height: 400,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Total",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: recaudacionesGav.meses,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (value) => {
            if (typeof value !== "undefined") {
              return `${formatAsCLP(value.toFixed(0))}`;
            }
            return value;
          },
        },
      },
      noData: {
        text: "No hay datos disponibles",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontWeight: 700,
          fontFamily: undefined,
        },
      },
    },
  };

  return (
    <Card>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop={1}
        spacing={2}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Typography variant="h3" align="center">
            Gastos GAVs
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      {isLoadingRecaudacionesGav ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box dir="ltr" margin={2}>
          <ReactApexChart
            height={400}
            options={chart.options}
            series={chart.series}
            type="line"
          />
        </Box>
      )}
    </Card>
  );
};

export default TablaRecaudacionMensualGav;
