import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, AppBar, Tabs, Tab, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EditarCamposCliente from "../components/gestionCliente/EditarCamposCliente";
import EditarClavesCliente from "../components/gestionCliente/EditarClavesCliente";
import Plan from "../components/gestionCliente/Plan";
import TareasAsociadasCliente from "../components/gestionCliente/TareasAsociadasCliente";
import { Alerta } from "../components/Alerta";
import { Titulo } from "../components/Titulo";
import axios from "axios";
import { API_SERVER, dbx } from "../utils/urlBack_End";
import FacturasAsociadasCliente from "src/components/gestionCliente/FacturasAsociadasCliente";
import Portafolio from "src/components/gestionCliente/Portafolio";
import ContratosCliente from "src/components/gestionCliente/ContratosCliente";
import SociosCliente from "src/components/gestionCliente/SociosCliente";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
  },
  margen: {
    marginBottom: 12,
  },
}));

export default function VerClientes() {
  const { id } = useParams();
  const [cliente, setCliente] = useState({});
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");
  const { empleado: user } = useSelector((state) => state.auth.user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setCliente(response.data);
      });
  }, [id]);

  useEffect(() => {
    dbx
      .filesListFolder({ path: `/Clientes/${id}` })
      .then(() => {})
      .catch(() => {
        dbx
          .filesCreateFolderV2({ path: `/Clientes/${id}` })
          .then((response) => {})
          .catch((error) => {});
      })
      .finally(() => {});
  }, [id]);

  return (
    <>
      {cliente.estado && (
        <>
          <Container>
            <Titulo>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingRight: "1rem",
                  paddingLeft: "1rem",
                }}
                variant="h5"
              >
                Cliente: {cliente.nombre}
              </Typography>
            </Titulo>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Datos Cliente" />
                  <Tab label="Claves Portales" />
                  <Tab label="Socios" />
                  <Tab label="Bitácora" />
                  <Tab label="Plan" />
                  <Tab label="Tareas Asociadas al Cliente" />
                  <Tab label="Facturas" />
                  <Tab label="Contratos" />
                </Tabs>
              </AppBar>
            </div>

            <EditarCamposCliente
              value={value}
              cliente={cliente}
              setCliente={setCliente}
              setShowAlert={setShowAlert}
              setShowErrorMessage={setShowErrorMessage}
              setMessage={setMessage}
            />
            <EditarClavesCliente
              value={value}
              cliente={cliente}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
            <SociosCliente
              value={value}
              setValue={setValue}
              cliente={cliente}
              setShowMessage={setShowMessage}
              setColor={setColor}
              setMessage={setMessage}
            />
            <Portafolio value={value} cliente={cliente} />

            <Plan value={value} cliente={cliente} setCliente={setCliente} />
            <TareasAsociadasCliente
              value={value}
              cliente={cliente}
              setCliente={setCliente}
              title="Tareas cliente"
              subheader="Se muestran las tareas asociadas al cliente"
            />
            <FacturasAsociadasCliente value={value} cliente={cliente} />

            <ContratosCliente
              value={value}
              cliente={cliente}
              setShowMessage={setShowMessage}
              setColor={setColor}
              setMessage={setMessage}
            />

            <Alerta
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              color="success"
              message="Datos del cliente guardados"
            />
            <Alerta
              showAlert={showErrorMessage}
              setShowAlert={setShowErrorMessage}
              color="error"
              message={message}
            />

            {showMessage && (
              <Alerta
                showAlert={showMessage}
                setShowAlert={setShowMessage}
                color={color}
                message={message}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
}
