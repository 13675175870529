import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import Tareas from "./pages/Tareas";
import Facturas from "./pages/Facturacion";
import Pagos from "./pages/Pagos";
import Clientes from "./pages/Clientes";
import Empleados from "./pages/Empleados";
import Sucursales from "./pages/Sucursales";
import Configuraciones from "./pages/Configuraciones";
import Informes from "./pages/Informes";
import NuevaTarea from "./pages/NuevaTarea";
import NotFound from "./pages/Page404";
import VerClientes from "./pages/VerClientes";
import TipoPlanes from "./pages/TipoPlanes";
import TipoTareas from "./pages/TipoTareas";
import BaseDatosCliente from "./pages/BaseDatosCliente";
import Propuestas from "./pages/Propuestas";
import EditarTarea from "./pages/EditarTarea";
import Etiqueta from "./components/etiquetas/Etiquetas";
import GraficoTareas from "./pages/GraficoTareas";
import GraficoTareasEmpleado from "./components/graficoTareas/GraficoTareasEmpleado";
import AreaLaboral from "./pages/AreaLaboral";
import TabsPlanes from "./pages/TabsPlanes";
import DashboardCliente from "./pages/DashboardCliente";
import FacturasCliente from "./components/pagesCliente/FacturasCliente";
import VerEmpleado from "./pages/VerEmpleado";
import VerPropuesta from "./pages/VerPropuesta";
import VerPlan from "./pages/VerPlan";
import DatosClientes from "./components/pagesCliente/DatosClientes";
import PagosWebPay from "./components/pagesCliente/PagosWebPay";
import CambioEstadoTarea from "./pages/CambioEstadoTarea";
import { useSelector } from "react-redux";
import { decodeToken } from "react-jwt";
import { useDispatch } from "react-redux";
import { login } from "./reducers/authReducers";
import { useEffect } from "react";
import ProrrateoVista from "./pages/ProrrateoVista";
import Liquidacion from "./pages/Liquidacion";
import Estadisticas from "./pages/Estadisticas";
import GrupoClientes from "./pages/GrupoClientes";

// ----------------------------------------------------------------------

const getSuperAdminRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/estadisticas" replace /> },
    { path: "estadisticas", element: <Estadisticas /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "tareas", element: <Tareas /> },
    { path: "planes", element: <TabsPlanes /> },
    { path: "facturacion", element: <Facturas /> },
    { path: "pagos", element: <Pagos /> },
    { path: "clientes", element: <Clientes /> },
    { path: "grupos-clientes", element: <GrupoClientes /> },
    { path: "empleados", element: <Empleados /> },
    { path: "sucursales", element: <Sucursales /> },
    { path: "configuraciones", element: <Configuraciones /> },
    { path: "informes", element: <Informes /> },
    // { path: 'agenda', element: <Agenda /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    { path: "clientes/:id", element: <VerClientes /> },
    { path: "empleados/:id", element: <VerEmpleado /> },
    { path: "configuraciones/tipo-planes", element: <TipoPlanes /> },
    { path: "configuraciones/tipo-tareas", element: <TipoTareas /> },
    { path: "configuraciones/prorrateo", element: <ProrrateoVista /> },
    {
      path: "configuraciones/base-datos-clientes",
      element: <BaseDatosCliente />,
    },
    { path: "propuestas", element: <Propuestas /> },
    { path: "tareas/:id", element: <EditarTarea /> },
    { path: "propuestas/:id", element: <VerPropuesta /> },
    { path: "planes/:id", element: <VerPlan /> },
    { path: "configuraciones/etiquetas", element: <Etiqueta /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// El admin no ve sucursales
const getAdminRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/estadisticas" replace /> },
    { path: "estadisticas", element: <Estadisticas /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "tareas", element: <Tareas /> },
    { path: "planes", element: <TabsPlanes /> },
    { path: "facturacion", element: <Facturas /> },
    { path: "pagos", element: <Pagos /> },
    { path: "clientes", element: <Clientes /> },
    { path: "grupos-clientes", element: <GrupoClientes /> },
    { path: "empleados", element: <Empleados /> },
    { path: "informes", element: <Informes /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    { path: "clientes/:id", element: <VerClientes /> },
    { path: "propuestas", element: <Propuestas /> },
    { path: "tareas/:id", element: <EditarTarea /> },
    { path: "propuestas/:id", element: <VerPropuesta /> },
    { path: "planes/:id", element: <VerPlan /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
    { path: "cambiarEstadoTarea/:id", element: <CambioEstadoTarea /> },
  ];
};

// El admin no ve sucursales
const getAdminOPRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "tareas", element: <Tareas /> },
    { path: "clientes", element: <Clientes /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    { path: "clientes/:id", element: <VerClientes /> },
    { path: "tareas/:id", element: <EditarTarea /> },
    { path: "area-laboral", element: <AreaLaboral /> },
    { path: "propuestas", element: <Propuestas /> },
    { path: "propuestas/:id", element: <VerPropuesta /> },
  ];
};

// Supervisor no ve sucursales, facturación, pagos, configuraciones, ni
// empleados
const getSupervisorRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "tareas", element: <Tareas /> },
    { path: "planes", element: <TabsPlanes /> },
    { path: "clientes", element: <Clientes /> },
    { path: "grupos-clientes", element: <GrupoClientes /> },
    { path: "informes", element: <Informes /> },
    // { path: 'agenda', element: <Agenda /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    { path: "clientes/:id", element: <VerClientes /> },
    { path: "propuestas", element: <Propuestas /> },
    { path: "tareas/:id", element: <EditarTarea /> },
    { path: "propuestas/:id", element: <VerPropuesta /> },
    { path: "planes/:id", element: <VerPlan /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// Empleado no ve facturación, pagos, empleados, sucursales, configuraciones ni
// informes
const getEmpleadoRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "tareas", element: <Tareas /> },
    { path: "planes", element: <TabsPlanes /> },
    { path: "clientes", element: <Clientes /> },
    // { path: 'agenda', element: <Agenda /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    { path: "clientes/:id", element: <VerClientes /> },
    { path: "propuestas", element: <Propuestas /> },
    { path: "tareas/:id", element: <EditarTarea /> },
    { path: "propuestas/:id", element: <VerPropuesta /> },
    { path: "planes/:id", element: <VerPlan /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
  ];
};

// Gerente Sucursal sucursales, configuraciones ni
// informes
const getGerenteSucursalRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardApp /> },
    { path: "tareas", element: <Tareas /> },
    { path: "planes", element: <TabsPlanes /> },
    { path: "clientes", element: <Clientes /> },
    // { path: 'agenda', element: <Agenda /> },
    { path: "nueva-tarea", element: <NuevaTarea /> },
    { path: "clientes/:id", element: <VerClientes /> },
    { path: "propuestas", element: <Propuestas /> },
    { path: "tareas/:id", element: <EditarTarea /> },
    { path: "propuestas/:id", element: <VerPropuesta /> },
    { path: "planes/:id", element: <VerPlan /> },
    { path: "graficoTareas", element: <GraficoTareas /> },
    { path: "graficoTareas/:id", element: <GraficoTareasEmpleado /> },
    { path: "area-laboral", element: <AreaLaboral /> },
    { path: "facturacion", element: <Facturas /> },
    { path: "pagos", element: <Pagos /> },
    { path: "informes", element: <Informes /> },
    { path: "empleados/:id", element: <VerEmpleado /> },
  ];
};

const getClienteRoutes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard/inicio" replace /> },
    { path: "inicio", element: <DashboardCliente /> },
    { path: "facturación", element: <FacturasCliente /> },
    { path: "clientes", element: <DatosClientes /> },
    { path: "pago/:id", element: <PagosWebPay /> },
  ];
};

const getNotLoggedInRoutes = () => {
  return [{ path: "*", element: <Navigate to="/login" replace /> }];
};

export default function Router() {
  const userInfo = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const loggout = () => {
    const token = localStorage.getItem("token");
    const sucursalObtenida = localStorage.getItem("sucursal");
    const rolObtenido = localStorage.getItem("rol");
    let myDecodedToken;

    if (token) {
      try {
        myDecodedToken = decodeToken(token);
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.clear();
        return;
      }

      if (myDecodedToken && myDecodedToken.exp) {
        const fechaTerminoToken = new Date(Number(myDecodedToken.exp) * 1000);
        const fechaActual = new Date();

        if (fechaActual > fechaTerminoToken) {
          localStorage.clear();
          return;
        }
      } else {
        // Si no se puede decodificar el token o no tiene una fecha de expiración válida
        localStorage.clear();
        return;
      }
    }

    if (!userInfo && token && sucursalObtenida && rolObtenido) {
      let newUser = myDecodedToken;
      if (sucursalObtenida === "CL") {
        dispatch(login(newUser));
        return newUser;
      } else {
        const sucursal = myDecodedToken?.sucursales?.filter(
          (e) => e.refSucursal === sucursalObtenida && e.refRol === rolObtenido
        )[0];
        if (sucursal) {
          newUser.sucursal = sucursal;
          newUser.rol = sucursal.refRol;
          dispatch(login(newUser));
          return newUser;
        } else {
          localStorage.clear();
          return;
        }
      }
    } else {
      return userInfo;
    }
  };

  useEffect(() => {
    const expiracionToken = () => {
      if (token) {
        const myDecodedToken = decodeToken(token);
        const fechaTerminoToken = new Date(Number(myDecodedToken.exp) * 1000);
        const fechaActual = new Date();

        if (fechaActual > fechaTerminoToken) {
          localStorage.clear();
          return;
        }
      } else {
        localStorage.clear();
        return;
      }
    };
    expiracionToken();
  }, [token]);

  const selectChildren = () => {
    const userAUX = loggout();
    if (!userAUX) return getNotLoggedInRoutes();
    if (
      userAUX.rol === "SUPER_ADMIN" &&
      (userAUX.empleado.rut === "192613639" ||
        userAUX.empleado.rut === "194467354" ||
        userAUX.empleado.rut === "121695979" ||
        userAUX.empleado.rut === "154157131" ||
        userAUX.empleado.rut === "132012636" ||
        userAUX.empleado.rut === "193928595")
    )
      return [
        ...getSuperAdminRoutes(),
        ...[{ path: "liquidacion", element: <Liquidacion /> }],
      ];
    if (userAUX.rol === "SUPER_ADMIN") return getSuperAdminRoutes();
    if (userAUX.rol === "ADMIN") return getAdminRoutes();
    if (userAUX.rol === "ADMIN_OPERACIONES") return getAdminOPRoutes();
    if (userAUX.rol === "SUPERVISOR") return getSupervisorRoutes();
    if (userAUX.rol === "EMPLEADO") return getEmpleadoRoutes();
    if (userAUX.rol === "CLIENTE") return getClienteRoutes();
    if (userAUX.rol === "GERENTE_SUCURSAL") return getGerenteSucursalRoutes();
  };

  const selectAppRoutesChildren = () => {
    const userAUX = loggout();

    let children = [
      { path: "/login", element: <Login /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ];

    if (userAUX && userAUX.rol) {
      if (userAUX.rol === "SUPER_ADMIN" || userAUX.rol === "ADMIN") {
        children.unshift({
          path: "/",
          element: <Navigate to="/dashboard/estadisticas" />,
        });
      } else {
        children.unshift({
          path: "/",
          element: <Navigate to="/dashboard/inicio" />,
        });
      }
    } else {
      children.unshift({
        path: "/",
        element: <Navigate to="/login" />,
      });
    }
    return children;
  };

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: selectChildren(),
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: selectAppRoutesChildren(),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// ----------------------------------------------------------------------

export {
  getAdminRoutes,
  getEmpleadoRoutes,
  getSuperAdminRoutes,
  getSupervisorRoutes,
  getClienteRoutes,
  getAdminOPRoutes,
  getGerenteSucursalRoutes,
};
