import { Autocomplete, Box, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";

export const Descuento = (props) => {
  const { formik } = props;
  const [tipoFrecuencia, setTipoFrecuencia] = useState({
    label: "Mes",
    value: "MES",
  });

  const { touched, getFieldProps, errors, setFieldValue } = formik;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5} md={6}>
        <TextField
          fullWidth
          // autoComplete="username"
          type="Frecuencia"
          label="Tiempo Descuento"
          onChange={formik.handleChange}
          {...getFieldProps("frecuenciaDesc")}
          error={Boolean(touched.frecuenciaDesc && errors.frecuenciaDesc)}
          helperText={touched.frecuenciaDesc && errors.frecuenciaDesc}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={6}>
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={options}
          value={tipoFrecuencia}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setFieldValue("tipoFrecuenciaDesc", newValue.value);
              setTipoFrecuencia(newValue);
            } else {
              setFieldValue("tipoFrecuenciaDesc", "");
              setTipoFrecuencia(null);
            }
          }}
          renderInput={(params) => (
            <Box sx={{ minWidth: 230, maxWidth: "90%" }}>
              <TextField
                fullWidth
                {...params}
                label="Tipo tiempo descuento"
                {...getFieldProps("tipoFrecuenciaDesc")}
                error={Boolean(
                  touched.tipoFrecuenciaDesc && errors.tipoFrecuenciaDesc
                )}
                helperText={
                  "Si no selecciona una opción, se marca por defecto (Mes)"
                }
              />
            </Box>
          )}
        />
      </Grid>
    </Grid>
  );
};

const options = [
  { label: "Dia", value: "DIA" },
  { label: "Semana", value: "SEMANA" },
  { label: "Mes", value: "MES" },
  { label: "Año", value: "AÑO" },
];
