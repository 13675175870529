import React, { useState, useEffect } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Stack,
  Typography,
} from "@material-ui/core"
import {
  VisibilityOff,
  Visibility
} from "@material-ui/icons"
import { useSelector } from 'react-redux';
import { empleadosApi } from 'src/services/empleadosApi';
import { AlertaModal, AlertaToast } from 'src/components/AlertaSwall';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'src/reducers/authReducers';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function DialogCambioContrasena(props) {
  const { open, setOpen } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.auth.user);

  const [showPassword, setShowPassword] = useState(false);
  const [progress, setProgress] = useState(0);

  const [password, setPassword] = useState("");
  const [errorpassword, setErrorPassword] = useState(false);
  const [verifidPassword, setVerifidPassword] = useState("");
  const [cargando, setCargando] = useState(false);

  const [cambioContrasenaDB] = empleadosApi.useUpdateCambioContrasenaMutation()


  const cambioContrasena = () => {
    if (password === "" || verifidPassword === "" || password !== verifidPassword) {
      AlertaToast({
        action: 'Las contraseñas no coinciden.',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialogCambioContrasenaObligatorio'),
      }).fire();
    } else if (String(password).toLowerCase().includes(String(userInfo.empleado.nombre).toLowerCase())) {
      AlertaToast({
        action: 'La contraseña no puede tener su nombre',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialogCambioContrasenaObligatorio'),
      }).fire();
    } else if (String(password).toLowerCase().includes("123") || String(password).toLowerCase().includes("789")) {
      AlertaToast({
        action: 'La contraseña no puede tener 123 o 789',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialogCambioContrasenaObligatorio'),
      }).fire();
    }
    else if (progress < 100) {
      AlertaToast({
        action: 'Se debe completar un 100% del progreso',
        fullText: true,
        type: 'info',
        target: document.getElementById('dialogCambioContrasenaObligatorio'),
      }).fire();
    }
    else {
      setCargando(true);
      AlertaModal({
        title: 'cambiar contraseña',
        confirmButtonText: 'Sí, cambiar',
        target: document.getElementById('dialogCambioContrasenaObligatorio'),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            cambioContrasenaDB({
              nuevaContrasena: password,
            })
              .unwrap()
              .then(() => {
                AlertaToast({
                  element: 'contraseña',
                  action: 'cambiada',
                }).fire()
                dispatch(logout())
                localStorage.clear();
                navigate("/login", { replace: true });
                setCargando(false)
                setOpen(false);

              })
              .catch(() => {
                AlertaToast({
                  action: 'Error en el servidor',
                  fullText: true,
                  target: document.getElementById('dialogCambioContrasenaObligatorio'),
                }).fire()
                setCargando(false)
              })

          }
        })
    }




  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (password === "") {
      setErrorPassword(true);
    } else if (String(password).toLowerCase().includes(String(userInfo.empleado.nombre).toLowerCase())) {
      setErrorPassword(true);
    } else if (String(password).toLowerCase().includes("123") || String(password).toLowerCase().includes("789")) {
      setErrorPassword(true);
    }
    else {
      setErrorPassword(false);
    }

  }, [password, userInfo.empleado.nombre])

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      id='dialogCambioContrasenaObligatorio'
    >
      <DialogTitle id="alert-dialog-title">
        {"Cambio de contraseña obligatorio"}
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          marginTop={2}
        >
          <Alert severity="info">
            <Stack
              spacing={1}
            >
              <AlertTitle>Información sobre la contraseña</AlertTitle>
              <Typography variant='caption'>8 caracteres</Typography>
              <Typography variant='caption'>Una mayúscula </Typography>
              <Typography variant='caption'>Cuatro minúsculas </Typography>
              <Typography variant='caption'>Tres numeros </Typography>
              <Typography variant='caption'>NOTA: NO IMPORTA EL ORDEN </Typography>
            </Stack>
          </Alert>
          <FormControl
            variant="outlined"
            error={errorpassword}
          >
            <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
            <Input
              fullWidth
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              onChange={(event) => {
                const nuevaEntrada = event.target.value;

                if (String(nuevaEntrada).toLowerCase().includes(String(userInfo.empleado.nombre).toLowerCase())) {
                  setPassword(nuevaEntrada);
                } else if (String(nuevaEntrada).toLowerCase().includes("123") || String(nuevaEntrada).toLowerCase().includes("789")) {
                  setPassword(nuevaEntrada);
                }
                else {
                  let valorProgress = 0;
                  if (nuevaEntrada.match(/[A-Z]/g) && nuevaEntrada.match(/[A-Z]/g).length === 1) {
                    if ((valorProgress + 25) >= 100) {
                      valorProgress = 100;
                    }
                    else {
                      valorProgress = valorProgress + 25
                    }

                  }
                  if (nuevaEntrada.match(/[a-z]/g) && nuevaEntrada.match(/[a-z]/g).length === 4) {
                    if ((valorProgress + 25) >= 100) {
                      valorProgress = 100;
                    }
                    else {
                      valorProgress = valorProgress + 25
                    }
                  }
                  if (nuevaEntrada.match(/[0-9]/g) && nuevaEntrada.match(/[0-9]/g).length === 3) {
                    if ((valorProgress + 25) >= 100) {
                      valorProgress = 100;
                    }
                    else {
                      valorProgress = valorProgress + 25
                    }
                  }
                  if (nuevaEntrada.length === 8) {
                    if ((valorProgress + 25) >= 100) {
                      valorProgress = 100;
                    }
                    else {
                      valorProgress = valorProgress + 25
                    }
                  }
                  setProgress(valorProgress);
                  setPassword(nuevaEntrada);

                }




              }}
              value={password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            variant="outlined"
            error={password !== verifidPassword || verifidPassword === ""}
          >
            <InputLabel htmlFor="standard-adornment-password-2">Verificación Contraseña</InputLabel>
            <Input
              fullWidth
              id="standard-adornment-password-2"
              type={showPassword ? 'text' : 'password'}
              value={verifidPassword}
              onChange={(event) => {
                setVerifidPassword(event.target.value)


              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
          </Box>

        </Stack>

      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={cargando}
          onClick={cambioContrasena}
          autoFocus
        >
          Cambiar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
