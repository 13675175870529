import {
  Box,
  Card,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@material-ui/core";
import { useResponsive } from "src/hooks/use-responsive";
import { fNumber } from "src/utils/formatNumber";
import PropTypes from "prop-types";

AppWidgetIcon.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      clic: PropTypes.bool.isRequired,
      imagen: PropTypes.string.isRequired,
    })
  ),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

AppWidgetIcon.defaultProps = {
  series: [],
  onClick: () => {},
  loading: false,
};

export default function AppWidgetIcon({ series, onClick, loading, ...other }) {
  const isDesktop = useResponsive("up", "sm");

  if (loading) {
    return (
      <Card {...other}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          divider={
            <Divider
              orientation={isDesktop ? "vertical" : "horizontal"}
              flexItem
              sx={{ borderStyle: "dashed" }}
            />
          }
        >
          {Array.from({ length: series.length }).map((_, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={3}
              sx={{ width: 1, py: 5 }}
            >
              <Skeleton variant="circular" width={64} height={64} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={120} />
              </Box>
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  return (
    <Card sx={{ height: "100%" }} {...other}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ height: 1 }}
        divider={
          <Divider
            orientation={isDesktop ? "vertical" : "horizontal"}
            flexItem
            sx={{ borderStyle: "dashed" }}
          />
        }
      >
        {series.map((item, index) => (
          <Stack
            key={item.label}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            onClick={item.clic ? () => onClick(item.label) : undefined}
            sx={{ width: 1, py: 5, cursor: item.clic ? "pointer" : "default" }}
          >
            <Box sx={{ width: 64, height: 64 }}>
              <img alt="icon" src={item.imagen} />
            </Box>
            <div>
              <Typography variant="h4" sx={{ mb: 0.5 }}>
                {fNumber(item.total)}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ color: "text.disabled", maxWidth: "115px" }}
              >
                {item.label}
              </Typography>
            </div>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
