import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { API_SERVER } from "../../utils/urlBack_End";
import axios from "axios";

export default function FormDialog(props) {
  const {
    open,
    setOpen,
    setSocios,
    refCliente,
    setShowMessage,
    setColor,
    setMessage,
  } = props;

  const [clientes, setClientes] = useState([]);
  const [tipoSocio, setTipoSocio] = useState({
    label: "Existente",
    value: "AUTOMATICO",
  });
  const [cliente, setCliente] = useState(null);
  const [clienteNombre, setClienteNombre] = useState("");

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        const data = response.data.filter((data) => data.rut !== refCliente);
        setClientes(data);
      });
  }, [setClientes, refCliente]);

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    rutSocio: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombreSocio: Yup.string().required(
      "Se necesario ingresar el nombre del socio"
    ),
  });

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/clientes/socios/${refCliente}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setSocios(response.data);
      });
  };

  const formik = useFormik({
    initialValues: {
      tipoSocio: "",
      rutSocio: "",
      nombreSocio: "",
      remember: true,
    },

    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    values,
  } = formik;

  const guardarDatos = () => {
    setSubmitting(true);
    const datosSocio = {
      tipoSocio: tipoSocio.value,
      refCliente: refCliente,
      rutSocio:
        deconstructRut(values.rutSocio.toUpperCase()).digits +
        deconstructRut(values.rutSocio.toUpperCase()).verifier,
      nombreSocio: values.nombreSocio.toUpperCase(),
    };
    axios
      .post(
        `${API_SERVER}/clientes/socios`,
        {
          ...datosSocio,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        actualizar();
        setShowMessage(true);
        setColor("success");
        setMessage("Se han guardado los datos del socio.");
        setSubmitting(false);
        handleClose();
      })
      .catch((e) => {
        setShowMessage(true);
        setColor("error");
        setMessage("Ha ocurrido un error en el servidor o el socio ya existe.");
        setSubmitting(false);
      });
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm">
          <DialogTitle id="form-dialog-title">Agregar un socio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              complete la información del socio
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Autocomplete
                  id="combo-b"
                  options={tipoSociosOptions}
                  value={tipoSocio}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      values.tipoSocio = newValue.value;
                      values.nombreSocio = "";
                      values.rutSocio = "";
                      setTipoSocio(newValue);
                    }
                  }}
                  style={{ marginBottom: 15, marginTop: 15 }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Seleccione tipo de ingreso"
                      {...getFieldProps("tipoSocio")}
                      error={Boolean(touched.tipoSocio && errors.tipoSocio)}
                      helperText={touched.tipoSocio && errors.tipoSocio}
                    />
                  )}
                />
                {tipoSocio.value === "AUTOMATICO" && (
                  <Autocomplete
                    value={cliente}
                    options={clientes}
                    isOptionEqualToValue={(option, value) =>
                      option.rut === value.rut && option.nombre === value.nombre
                    }
                    getOptionLabel={(option) => option.nombre}
                    onChange={(event, newValue) => {
                      setCliente(newValue);
                      if (newValue === null) {
                        values.rutSocio = newValue;
                        values.nombreSocio = newValue;
                      } else {
                        values.rutSocio = newValue.rut;
                        values.nombreSocio = newValue.nombre;
                      }
                    }}
                    inputValue={clienteNombre}
                    onInputChange={(event, newInputValue) => {
                      setClienteNombre(newInputValue);
                    }}
                    fullWidth
                    ListboxProps={{ style: { maxHeight: "15rem" } }}
                    renderInput={(params) => (
                      <TextField {...params} label="Seleccione Cliente" />
                    )}
                  />
                )}

                {tipoSocio.value === "MANUAL" && (
                  <>
                    <TextField
                      required
                      fullWidth
                      autoComplete="Nombre"
                      type="text"
                      label="Nombre Socio"
                      {...getFieldProps("nombreSocio")}
                      style={{ marginBottom: 15 }}
                      error={Boolean(touched.nombreSocio && errors.nombreSocio)}
                      helperText={touched.nombreSocio && errors.nombreSocio}
                    />

                    <TextField
                      required
                      fullWidth
                      autoComplete="rut"
                      type="text"
                      label="Rut Socio"
                      {...getFieldProps("rutSocio")}
                      style={{ marginBottom: 15 }}
                      error={Boolean(touched.rutSocio && errors.rutSocio)}
                      helperText={touched.rutSocio && errors.rutSocio}
                    />
                  </>
                )}

                <DialogActions>
                  <LoadingButton onClick={handleClose} color="error">
                    Cancelar
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    startIcon={<Icon icon={saveFill} />}
                    loading={isSubmitting}>
                    Guardar Socio
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

const tipoSociosOptions = [
  { label: "Existente", value: "AUTOMATICO" },
  { label: "Manual", value: "MANUAL" },
];
