import {
  Card,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { useStyles, getBorderColor } from "src/theme/kanbanStyles";
import { useNavigate } from "react-router-dom";
import UpdateIcon from "@material-ui/icons/Update";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Label from "../Label";
import { useState } from "react";
import ModifyTaskStateModal from "../_dashboard/tareas/ModifyTaskStateModal";
import CantidadRemuneracionesDialog from "../tareas/CantidadRemuneracionesDialog";
import SolicitarCambioEstadoDialog from "../tareas/SolicitarCambioEstadoDialog";
import { formatDate } from "src/utils/converter";
import { tareasApi } from "src/services/tareasApi";
import { AlertaToast } from "../AlertaSwall";
import { useSelector } from "react-redux";

export default function TareasKanban(props) {
  const { tareas } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModifyTaskState, setShowModifyTaskState] = useState(false);
  const [showCantidadRemuneraciones, setShowCantidadRemuneraciones] =
    useState(false);
  const [actualizarEstadoTarea] = tareasApi.useUpdateCambiaEstadoMutation();
  const { rol: rolObtenido } = useSelector((state) => state.auth.user);
  const [mostrarSolicitarCambioEstado, setMostrarSolicitarCambioEstado] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [guardandoDatos, setGuardandoDatos] = useState(false);

  const handleChangeTaskState = (tarea, option = "admin") => {
    setSelectedTask(tarea);
    if (option === "admin") {
      setSelectedTaskId(tarea.id);
      setIsLoading(true);
      setShowModifyTaskState(true);
    } else {
      setMostrarSolicitarCambioEstado(true);
    }
  };

  const doChangeTaskState = (newState) => {
    if (selectedTask.estado === newState) return setShowModifyTaskState(false);
    let modificacionEstado = {
      ...selectedTask,
      estado: newState,
    };
    if (
      newState === "TERMINADA" &&
      (selectedTask.tipoTarea === "MES" || selectedTask.tipoTarea === "AÑO") &&
      String(selectedTask.nombre)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes("REMUNERACION")
    ) {
      setSelectedTask(modificacionEstado);
      setShowModifyTaskState(false);
      return setShowCantidadRemuneraciones(true);
    }
    if (newState === "TERMINADA") {
      if (selectedTask.tipoTarea === "ESPECIAL") {
        modificacionEstado.estado = "PENDIENTE DE FACTURACION";
      }
      modificacionEstado.fechaTermino = getToday();
    } else {
      modificacionEstado.fechaTermino = "";
    }
    setShowModifyTaskState(false);
    updateTaskInDB(modificacionEstado);
  };

  const updateTaskInDB = (modificacionEstado) => {
    actualizarEstadoTarea({
      id: modificacionEstado.id,
      body: {
        estado: modificacionEstado.estado,
        fechaTermino: modificacionEstado.fechaTermino,
      },
    })
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Tarea",
          action: "actualizada",
        }).fire();
      })
      .finally(() => {
        setSelectedTaskId(null);
        setIsLoading(false);
      })
      .catch(() => {
        AlertaToast({
          action: "No se pudo actualizar la tarea en la base de datos.",
          fullText: true,
          type: "error",
        }).fire();
        setSelectedTask(null);
        setSelectedTaskId(null);
        setIsLoading(false);
      });
  };

  const updateTaskInDBRemuneraciones = (modificacionEstado) => {
    actualizarEstadoTarea({
      id: modificacionEstado.id,
      body: {
        estado: modificacionEstado.estado,
        fechaTermino: modificacionEstado.fechaTermino,
        cantidadRemuneraciones: modificacionEstado.cantidadFactura,
      },
    })
      .unwrap()
      .then(() => {
        AlertaToast({
          element: "Tarea",
          action: "actualizada",
        }).fire();
        setSelectedTask(null);
      })
      .catch(() => {
        AlertaToast({
          action: "No se pudo actualizar la tarea en la base de datos.",
          fullText: true,
          type: "error",
        }).fire();
        setSelectedTask(null);
      })
      .finally(() => {
        setGuardandoDatos(false);
      });
  };

  const getToday = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getUTCFullYear();
    return formatDate(`${year}/${month}/${day}`);
  };

  return (
    <div>
      {tareas.length > 0 ? (
        tareas.map((tarea, index) => (
          <Card
            key={index}
            className={classes.card}
            style={{
              borderLeft: `2px solid ${getBorderColor(tarea.tipoTarea)}`,
              borderRight: `2px solid ${getBorderColor(tarea.tipoTarea)}`,
            }}
          >
            {tarea.estado === "TERMINADA" || tarea.estado === "FACTURADO" ? (
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    className={classes.columnTypography}
                    style={{ wordWrap: "break-word" }}
                    onClick={() => {
                      navigate("/dashboard/tareas/" + tarea.id);
                    }}
                  >
                    {tarea.nombre.charAt(0).toUpperCase() +
                      tarea.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtituloTypography}>
                    Cliente:{" "}
                    {tarea.cliente.nombre.charAt(0).toUpperCase() +
                      tarea.cliente.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={3} marginRight="10px">
                    <Label variant="ghost" color="warning">
                      {tarea.tipoTarea.charAt(0).toUpperCase() +
                        tarea.tipoTarea.slice(1).toLowerCase()}
                    </Label>
                  </Grid>
                  <Grid item xs={3}>
                    <Label variant="ghost" color="info">
                      {tarea.estado.charAt(0).toUpperCase() +
                        tarea.estado.slice(1).toLowerCase()}
                    </Label>
                  </Grid>
                  <Grid item xs={3} marginRight="9px"></Grid>{" "}
                  <Grid item xs={1} justifyItems="flex-end">
                    {" "}
                    {rolObtenido === "SUPER_ADMIN" ||
                    rolObtenido === "ADMIN" ? (
                      <Tooltip title="Cambiar estado">
                        <IconButton
                          onClick={() => handleChangeTaskState(tarea)}
                          aria-label="Cambiar"
                          disabled={isLoading && selectedTaskId === tarea.id}
                        >
                          {isLoading && selectedTaskId === tarea.id ? (
                            <CircularProgress size={24} />
                          ) : (
                            <UpdateIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Solicitar cambio de estado">
                        <IconButton
                          onClick={() =>
                            handleChangeTaskState(tarea, "employee ")
                          }
                          aria-label="Solicitar cambio estado"
                        >
                          <NotificationImportantIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (tarea.estado === "PENDIENTE DE FACTURACION" &&
                tarea.tipoTarea === "ESPECIAL") ||
              tarea.estado === "CANCELADO O RECHAZADO" ? (
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    className={classes.columnTypography}
                    style={{ wordWrap: "break-word" }}
                    onClick={() => {
                      navigate("/dashboard/tareas/" + tarea.id);
                    }}
                  >
                    {tarea.nombre.charAt(0).toUpperCase() +
                      tarea.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtituloTypography}>
                    Cliente:{" "}
                    {tarea.cliente.nombre.charAt(0).toUpperCase() +
                      tarea.cliente.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={3} marginRight="10px">
                    <Label variant="ghost" color="warning">
                      {tarea.tipoTarea.charAt(0).toUpperCase() +
                        tarea.tipoTarea.slice(1).toLowerCase()}
                    </Label>
                  </Grid>
                  <Grid item xs={3}>
                    {tarea.estado === "PENDIENTE DE FACTURACION" ? (
                      <Label variant="ghost" color="success">
                        Pendiente Fact.
                      </Label>
                    ) : (
                      <Label variant="ghost" color="info">
                        Cancelado
                      </Label>
                    )}
                  </Grid>
                  <Grid item xs={4} marginRight="9px"></Grid>{" "}
                </Grid>
              </Grid>
            ) : tarea.estado === "OBSERVADA SII" ? (
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    className={classes.columnTypography}
                    style={{ wordWrap: "break-word" }}
                    onClick={() => {
                      navigate("/dashboard/tareas/" + tarea.id);
                    }}
                  >
                    {tarea.nombre.charAt(0).toUpperCase() +
                      tarea.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtituloTypography}>
                    Cliente:{" "}
                    {tarea.cliente.nombre.charAt(0).toUpperCase() +
                      tarea.cliente.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={3} marginRight="10px">
                    <Label variant="ghost" color="warning">
                      {tarea.tipoTarea.charAt(0).toUpperCase() +
                        tarea.tipoTarea.slice(1).toLowerCase()}
                    </Label>
                  </Grid>
                  <Grid item xs={3}>
                    <Label variant="ghost" color="error">
                      Observada SII
                    </Label>
                  </Grid>
                  <Grid item xs={3} marginRight="9px"></Grid>{" "}
                  <Grid item xs={1} justifyItems="flex-end">
                    {" "}
                    <Tooltip title="Cambiar estado">
                      <IconButton
                        onClick={() => handleChangeTaskState(tarea)}
                        aria-label="Cambiar"
                        disabled={isLoading && selectedTaskId === tarea.id}
                      >
                        {isLoading && selectedTaskId === tarea.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <UpdateIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    className={classes.columnTypography}
                    style={{ wordWrap: "break-word" }}
                    onClick={() => {
                      navigate("/dashboard/tareas/" + tarea.id);
                    }}
                  >
                    {tarea.nombre.charAt(0).toUpperCase() +
                      tarea.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtituloTypography}>
                    Cliente:{" "}
                    {tarea.cliente.nombre.charAt(0).toUpperCase() +
                      tarea.cliente.nombre.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    {" "}
                    <Label variant="ghost" color="warning">
                      {tarea.tipoTarea.charAt(0).toUpperCase() +
                        tarea.tipoTarea.slice(1).toLowerCase()}
                    </Label>
                  </Grid>
                  <Grid item xs={4}></Grid>{" "}
                  <Grid item xs={2} justifyItems="flex-end">
                    {" "}
                    <Tooltip title="Cambiar estado">
                      <IconButton
                        onClick={() => handleChangeTaskState(tarea)}
                        aria-label="Cambiar"
                        disabled={isLoading && selectedTaskId === tarea.id}
                      >
                        {isLoading && selectedTaskId === tarea.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <UpdateIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Card>
        ))
      ) : (
        <Typography className={classes.noTasks}>No posee tareas</Typography>
      )}
      {selectedTask && (
        <ModifyTaskStateModal
          task={selectedTask}
          onSubmit={doChangeTaskState}
          onClose={() => {
            setShowModifyTaskState(false);
            setIsLoading(false);
          }}
          states={[
            "PENDIENTE",
            "EN PROCESO",
            "OBSERVADA SII",
            "TERMINADA",
            "FACTURADO",
            "CANCELADO O RECHAZADO",
          ]}
          open={showModifyTaskState}
          cargandoDatos={false}
        />
      )}
      {selectedTask && showCantidadRemuneraciones && (
        <CantidadRemuneracionesDialog
          open={showCantidadRemuneraciones}
          setOpen={setShowCantidadRemuneraciones}
          tarea={selectedTask}
          updateTaskInDBRemuneraciones={updateTaskInDBRemuneraciones}
          guardandoDatos={guardandoDatos}
          setGuardandoDatos={setGuardandoDatos}
        />
      )}
      {mostrarSolicitarCambioEstado && (
        <SolicitarCambioEstadoDialog
          open={mostrarSolicitarCambioEstado}
          setOpen={setMostrarSolicitarCambioEstado}
          tarea={selectedTask}
        />
      )}
    </div>
  );
}
