import React, { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import * as locales from "@material-ui/core/locale";
import Scrollbar from "../Scrollbar";
import AgregarClavesPortales from "./AgregarClavesPortales";
import { TablaHead } from "../_dashboard/tablas";
import { filter } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Alerta } from "../Alerta";
import MyConfirmationDialog from "../MyConfirmationDialog";
import { API_SERVER } from "../../utils/urlBack_End";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import axios from "axios";
import EditarClavesPortales from "./EditarClavesPortales";

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "1rem",
  },
  textfield: {
    width: "100%",
  },
  grid: {
    xs: 12,
    alignItems: "center",
    justify: "center",
    marginBottom: "1rem",
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    minWidth: "100%",
    marginBottom: 12,
    marginTop: 12,
  },
}));

const TABLE_HEAD = [
  { id: "usuario", label: "Nombre Usuario", alignRight: false },
  { id: "clave", label: "Contraseña", alignRight: false },
  { id: "institucion", label: "Institución", alignRight: false },
  { id: "tipoClave", label: "Tipo de Clave", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function EditarClavesCliente(props) {
  const { value, cliente, showAlert, setShowAlert } = props;
  const [clavesPortales, setClavesPortales] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [elementoSeleccionado, setElementoSeleccionado] = useState(null);
  const [locale] = useState("esES");
  const classes = styles();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/clavesPortales/${cliente.rut}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClavesPortales(response.data);
        let clave = response.data.find(
          (datos) => datos.institucion === "SITAX"
        );

        if (clave !== undefined) {
          axios
            .get(`${API_SERVER}/accesoClientes/claves/mostrarSitax/${clave.clave}`, {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
              }
            })
            .then(function (response) {
              if (response.data === -2) window.location.reload();
              setPassword(response.data);
            });
        }
      });
  }, [cliente.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clavesPortales.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - clavesPortales.length)
      : 0;

  const filteredUsers = applySortFilter(
    clavesPortales,
    getComparator(order, orderBy),
    ""
  );

  const isUserNotFound = filteredUsers.length === 0;

  /**
   * Actualiza la variable clavePortales, para que se refrescar la pantalla
   * al momento de agregar o quitar información del cliente
   */
  const actualizar = (refCliente) => {
    axios
      .get(`${API_SERVER}/clientes/clavesPortales/${refCliente}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setClavesPortales(response.data);
        setDeleteMessage(false);
        setShowAlert(true);
      });
  };

  const eliminar = (clavesCliente) => {
    const {
      refCliente,
      institucion,
      clave,
    } = clavesCliente;
    axios
      .delete(`${API_SERVER}/clientes/clavesPortales/${refCliente}/${institucion}/${clave}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("token")
        }
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        actualizar(clavesCliente.refCliente);
      });
  };

  const handleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handlePassword = (clave) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{showPassword ? password : "**************"}</Grid>
          <Grid item>
            <IconButton onClick={handleShowPassword} edge="end">
              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <div className={classes.pos}>
      {value === 1 && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                >
                  Claves Portales
                </Typography>
              </Grid>
              <Grid item xs={12} align="end">
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Agregar Claves
                </Button>
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={clavesPortales.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const { usuario, clave, institucion, tipoClave } = row;
                        const isItemSelected = selected.indexOf(usuario) !== -1;

                        return (
                          <TableRow
                            hover
                            key={usuario + clave + institucion}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{usuario}</TableCell>
                            <TableCell align="left">
                              {institucion === "SITAX"
                                ? handlePassword(clave)
                                : clave}
                            </TableCell>
                            <TableCell align="left">{institucion}</TableCell>
                            <TableCell align="left">
                              {tipoClave === undefined ? "---" : tipoClave}
                            </TableCell>
                            <TableCell align="left">
                              {institucion !== "SITAX" && (
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    setElementoSeleccionado(row);
                                    setOpenEditar(true)
                                  }}
                                >
                                  <EditIcon /> Editar
                                </Button>

                              )}

                              <Button
                                color="error"
                                onClick={() => {
                                  setElementoSeleccionado(row);
                                  setDeleteMessage(true);
                                }}
                              >
                                <DeleteIcon /> Eliminar
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          No hay claves que mostrar.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <ThemeProvider
              theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={clavesPortales.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ThemeProvider>

            <Alerta
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              color={color}
              message={message}
            />
            {open && (
              <AgregarClavesPortales
                open={open}
                setOpen={setOpen}
                setClavesPortales={setClavesPortales}
                refCliente={cliente.rut}
                setShowAlert={setShowAlert}
                setColor={setColor}
                setMessage={setMessage}
              />
            )}

            {openEditar && (
              <EditarClavesPortales
                elementoSeleccionado={elementoSeleccionado}
                open={openEditar}
                setOpen={setOpenEditar}
                setClavesPortales={setClavesPortales}
                refCliente={cliente.rut}
                setShowAlert={setShowAlert}
                setColor={setColor}
                setMessage={setMessage}
              />
            )}
          </CardContent>
        </Card>
      )}
      {deleteMessage && (
        <MyConfirmationDialog
          open={deleteMessage}
          onSubmit={() => eliminar(elementoSeleccionado)}
          onClose={() => setDeleteMessage(false)}
          title="Eliminación Claves del Cliente"
          content={`¿Estás seguro de eliminar el nombre de usuario: ${elementoSeleccionado.usuario} y contraseña: ${elementoSeleccionado.clave}?`}
        />
      )}
    </div>
  );
}
