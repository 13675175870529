import React from "react";
import PropTypes from "prop-types";
import { Snackbar, Alert } from "@material-ui/core";

export const Alerta = (props) => {
  const { showAlert, setShowAlert, color, message } = props;

  const handleCloseNotification = () => {
    setShowAlert(false);
  };

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={4000}
      onClose={() => handleCloseNotification()}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => handleCloseNotification()}
        severity={color}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

Alerta.propTypes = {
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
