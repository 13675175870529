import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import TablaDinamica from "src/components/tabla";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import dayjs from "dayjs";
import "dayjs/locale/es.js";
import { fNumber, formatAsCLP } from "src/utils/formatNumber";

const ModalFacturasTareasEmpleado = ({
  setOpen,
  isOpen,
  facturasDetareasEmpleado,
  tareasPendientesFacturacion,
  tareasConRecaudacionesYPagos,
  obteniendoFacturasDeTareasEmpleado,
  obteniendoTareasPendientesFacturacion,
  obteniendoTareasConRecaudacionesYPagos,
  title,
  tipoTabla,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const [tableHead, setTableHead] = useState([]);
  const [initialStateTable, setInitialStateTable] = useState({});
  const [propertiesToFilter, setPropertiesToFilter] = useState([]);

  useEffect(() => {
    if (tipoTabla === "emitidas") {
      const TABLE_HEAD_RECAUDACIONES_FACTURAS = [
        { id: "position", label: "N°", alignRight: false },
        { id: "cliente", label: "Cliente", alignRight: false },
        { id: "nombreTarea", label: "Tarea", alignRight: false },
        { id: "precioTarea", label: "Valor tarea", alignRight: false },
        { id: "estadoTarea", label: "Estado tarea", alignRight: false },
        { id: "fechaInicio", label: "Fecha tarea", alignRight: false },
        { id: "sucursal", label: "Sucursal", alignRight: false },
        { id: "empleado", label: "Empleado", alignRight: false },
        { id: "folio", label: "Folio", alignRight: false },
        { id: "estadoFactura", label: "Estado Factura", alignRight: false },
        { id: "fechaFacturacion", label: "Fecha factura", alignRight: false },
        { id: "total", label: "Precio factura", alignRight: false },
        // { id: "actions" },
      ];

      const INITIAL_STATE_TABLE = {
        currentPage: 0,
        rowsPerPageOptions: 5,
        order: "desc",
        orderBy: "fechaFacturacion",
        locale: "esES",
        filterName: "",
      };

      const PROPERTIES_TO_FILTER = [
        "nombre",
        "apellidos",
        "cliente",
        "estadoFactura",
        "estadoTarea",
        "nombreTarea",
        "valorTarea",
      ];

      setInitialStateTable(INITIAL_STATE_TABLE);
      setTableHead(TABLE_HEAD_RECAUDACIONES_FACTURAS);
      setPropertiesToFilter(PROPERTIES_TO_FILTER);
    } else if (tipoTabla === "pendientes") {
      const TABLE_HEAD_RECAUDACIONES_FACTURAS = [
        { id: "position", label: "N°", alignRight: false },
        { id: "rutCliente", label: "RUT cliente", alignRight: false },
        { id: "cliente", label: "Cliente", alignRight: false },
        { id: "nombreTarea", label: "Tarea", alignRight: false },
        { id: "precioTarea", label: "Valor tarea", alignRight: false },
        { id: "estadoTarea", label: "Estado tarea", alignRight: false },
        { id: "fechaInicio", label: "Fecha tarea", alignRight: false },
        { id: "sucursal", label: "Sucursal", alignRight: false },
        { id: "empleado", label: "Empleado", alignRight: false },
        { id: "valorTareaEnPesos", label: "Precio tarea", alignRight: false },
        { id: "totalEmitido", label: "Facturas emitidas", alignRight: false },
        {
          id: "pendientePorFacturar",
          label: "Pendiente por facturar",
          alignRight: false,
        },
        // { id: "actions" },
      ];

      const INITIAL_STATE_TABLE = {
        currentPage: 0,
        rowsPerPageOptions: 5,
        order: "desc",
        orderBy: "fechaInicio",
        locale: "esES",
        filterName: "",
      };

      const PROPERTIES_TO_FILTER = [
        "nombre",
        "apellidos",
        "cliente",
        "estadoTarea",
        "nombreTarea",
        "valorTarea",
      ];

      setInitialStateTable(INITIAL_STATE_TABLE);
      setTableHead(TABLE_HEAD_RECAUDACIONES_FACTURAS);
      setPropertiesToFilter(PROPERTIES_TO_FILTER);
    } else if (tipoTabla === "recaudacion") {
      const TABLE_HEAD_RECAUDACIONES_FACTURAS = [
        { id: "position", label: "N°", alignRight: false },
        { id: "rutCliente", label: "RUT cliente", alignRight: false },
        { id: "cliente", label: "Cliente", alignRight: false },
        { id: "nombreTarea", label: "Tarea", alignRight: false },
        { id: "precioTarea", label: "Valor tarea", alignRight: false },
        { id: "estadoTarea", label: "Estado tarea", alignRight: false },
        { id: "fechaInicio", label: "Fecha tarea", alignRight: false },
        { id: "sucursal", label: "Sucursal", alignRight: false },
        { id: "folio", label: "Folio", alignRight: false },
        { id: "estadoFactura", label: "Estado factura", alignRight: false },
        {
          id: "fechaFacturacion",
          label: "Fecha facturación",
          alignRight: false,
        },
        { id: "precioFactura", label: "Monto factura", alignRight: false },
        { id: "montoPagado", label: "Monto pagado", alignRight: false },
        {
          id: "montoRestante",
          label: "Monto pendiente",
          alignRight: false,
        },
        // { id: "actions" },
      ];

      const INITIAL_STATE_TABLE = {
        currentPage: 0,
        rowsPerPageOptions: 5,
        order: "desc",
        orderBy: "fechaFacturacion",
        locale: "esES",
        filterName: "",
      };

      const PROPERTIES_TO_FILTER = [
        "rutCliente",
        "cliente",
        "nombreTarea",
        "sucursal",
        "folio",
        "estadoFactura",
      ];

      setInitialStateTable(INITIAL_STATE_TABLE);
      setTableHead(TABLE_HEAD_RECAUDACIONES_FACTURAS);
      setPropertiesToFilter(PROPERTIES_TO_FILTER);
    }
  }, [tipoTabla]);

  const renderTableCellContent = (column, row, index, statesTable) => {
    if (tipoTabla === "emitidas") {
      switch (column.id) {
        case "position":
          return (
            statesTable.currentPage * statesTable.rowsPerPageOptions + index + 1
          );
        case "cliente":
        case "nombreTarea":
        case "sucursal":
        case "estadoFactura":
        case "estadoTarea":
          return capitalizarPalabras(row[column.id]);
        case "rutCliente":
          return formatRut(row[column.id], RutFormat.DOTS_DASH);
        case "fechaInicio":
        case "fechaFacturacion":
          return dayjs(row[column.id]).format("DD/MM/YYYY");
        case "total":
          return formatAsCLP(row[column.id]);
        case "empleado":
          return capitalizarPalabras(`${row["nombre"]} ${row["apellidos"]}`);
        case "precioTarea": {
          const { valorTarea, tipoMonedaTarea } = row;
          if (tipoMonedaTarea === "PESOS") {
            return formatAsCLP(valorTarea);
          } else if (tipoMonedaTarea === "DOLAR") {
            const textoDolar = valorTarea < 10 ? "dólar" : "dólares";
            return `${fNumber(valorTarea)} ${textoDolar}`;
          } else {
            return `${fNumber(valorTarea)}${tipoMonedaTarea}`;
          }
        }
        default:
          return row[column.id];
      }
    } else if (tipoTabla === "pendientes") {
      switch (column.id) {
        case "position":
          return (
            statesTable.currentPage * statesTable.rowsPerPageOptions + index + 1
          );
        case "cliente":
        case "nombreTarea":
        case "sucursal":
        case "estadoTarea":
          return capitalizarPalabras(row[column.id]);
        case "rutCliente":
          return formatRut(row[column.id], RutFormat.DOTS_DASH);
        case "fechaInicio":
          return dayjs(row[column.id]).format("DD/MM/YYYY");
        case "valorTareaEnPesos":
        case "totalEmitido":
        case "pendientePorFacturar":
          return formatAsCLP(row[column.id]);
        case "empleado":
          return capitalizarPalabras(`${row["nombre"]} ${row["apellidos"]}`);
        case "precioTarea": {
          const { valorTarea, tipoMonedaTarea } = row;
          if (tipoMonedaTarea === "PESOS") {
            return formatAsCLP(valorTarea);
          } else if (tipoMonedaTarea === "DOLAR") {
            const textoDolar = valorTarea < 10 ? "dólar" : "dólares";
            return `${fNumber(valorTarea)} ${textoDolar}`;
          } else {
            return `${fNumber(valorTarea)}${tipoMonedaTarea}`;
          }
        }
        default:
          return row[column.id];
      }
    } else if (tipoTabla === "recaudacion") {
      switch (column.id) {
        case "position":
          return (
            statesTable.currentPage * statesTable.rowsPerPageOptions + index + 1
          );
        case "cliente":
        case "nombreTarea":
        case "sucursal":
        case "estadoFactura":
        case "estadoTarea":
          return capitalizarPalabras(row[column.id]);
        case "rutCliente":
          return formatRut(row[column.id], RutFormat.DOTS_DASH);
        case "fechaInicio":
        case "fechaFacturacion":
          return dayjs(row[column.id]).format("DD/MM/YYYY");
        case "montoPagado":
        case "montoRestante":
        case "precioFactura":
          return formatAsCLP(row[column.id]);
        case "precioTarea": {
          const { valorTarea, tipoMonedaTarea } = row;
          if (tipoMonedaTarea === "PESOS") {
            return formatAsCLP(valorTarea);
          } else if (tipoMonedaTarea === "DOLAR") {
            const textoDolar = valorTarea < 10 ? "dólar" : "dólares";
            return `${fNumber(valorTarea)} ${textoDolar}`;
          } else {
            return `${fNumber(valorTarea)}${tipoMonedaTarea}`;
          }
        }
        default:
          return row[column.id];
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-facturas-tareas-empleado"
      id="dialog-facturas-tareas-empleado"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle
        style={styles.title}
        htmlFor="dialog-detalle-factura-empleados"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {tipoTabla === "emitidas" && (
          <TablaDinamica
            rows={facturasDetareasEmpleado}
            loadingRows={obteniendoFacturasDeTareasEmpleado}
            tableHead={tableHead}
            initialStateTable={initialStateTable}
            propertiesToFilter={propertiesToFilter}
            renderTableCellContent={renderTableCellContent}
          />
        )}
        {tipoTabla === "pendientes" && (
          <TablaDinamica
            rows={tareasPendientesFacturacion}
            loadingRows={obteniendoTareasPendientesFacturacion}
            tableHead={tableHead}
            initialStateTable={initialStateTable}
            propertiesToFilter={propertiesToFilter}
            renderTableCellContent={renderTableCellContent}
          />
        )}
        {tipoTabla === "recaudacion" && (
          <TablaDinamica
            rows={tareasConRecaudacionesYPagos}
            loadingRows={obteniendoTareasConRecaudacionesYPagos}
            tableHead={tableHead}
            initialStateTable={initialStateTable}
            propertiesToFilter={propertiesToFilter}
            renderTableCellContent={renderTableCellContent}
          />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ModalFacturasTareasEmpleado.propTypes = {
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  obteniendoFacturasDeTareasEmpleado: PropTypes.bool,
  obteniendoTareasPendientesFacturacion: PropTypes.bool,
  obteniendoTareasConRecaudacionesYPagos: PropTypes.bool,
  facturasDetareasEmpleado: PropTypes.array,
  tareasPendientesFacturacion: PropTypes.array,
  tareasConRecaudacionesYPagos: PropTypes.array,
  title: PropTypes.string,
  tipoTabla: PropTypes.string,
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default ModalFacturasTareasEmpleado;
