import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import ComisionesPredefinida from "src/components/ComisionesPredefinida";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    minWidth: "100px",
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
}));

export default function Configuraciones() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { empleado: user, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  const [mostrarComisionesPredefinida, setMostrarComisionesPredefinida] =
    useState(false);
  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo>
          <Typography
            style={{ marginBottom: "2rem" }}
            variant="h4"
            gutterBottom
          >
            Configuraciones
          </Typography>
        </Titulo>

        <Grid container justifyContent="space-around" spacing={3}>
          <Grid
            item
            xs={6}
            md={
              (user.rut === "192613639" ||
                user.rut === "194467354" ||
                user.rut === "121695979" ||
                user.rut === "132012636" ||
                user.rut === "154157131" ||
                user.rut === "193928595") &&
              rolObtenido === "SUPER_ADMIN"
                ? 2
                : 3
            }
          >
            <Card
              className={classes.root}
              onClick={() => {
                navigate("/dashboard/configuraciones/tipo-planes");
              }}
            >
              <CardActionArea>
                <CardMedia
                  sx={{ height: 140 }}
                  className={classes.media}
                  image="/static/icons/planes.svg"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Planes
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid
            item
            xs={6}
            md={
              (user.rut === "192613639" ||
                user.rut === "194467354" ||
                user.rut === "121695979" ||
                user.rut === "132012636" ||
                user.rut === "154157131" ||
                user.rut === "193928595") &&
              rolObtenido === "SUPER_ADMIN"
                ? 2
                : 3
            }
          >
            <Card
              onClick={() => {
                navigate("/dashboard/configuraciones/tipo-tareas");
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/static/icons/tareas.svg"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Tareas
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid
            item
            xs={6}
            md={
              (user.rut === "192613639" ||
                user.rut === "194467354" ||
                user.rut === "121695979" ||
                user.rut === "132012636" ||
                user.rut === "154157131" ||
                user.rut === "193928595") &&
              rolObtenido === "SUPER_ADMIN"
                ? 2
                : 3
            }
          >
            <Card
              onClick={() => {
                navigate("/dashboard/configuraciones/base-datos-clientes");
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/static/icons/cliente.svg"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Base de Datos Clientes
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {/*<Grid item xs={6} md={3}>
            <Card
              onClick={() => {
                navigate("/dashboard/configuraciones/etiquetas");
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/static/icons/tag.svg"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Etiquetas
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>*/}

          <Grid
            item
            xs={6}
            md={
              (user.rut === "192613639" ||
                user.rut === "194467354" ||
                user.rut === "121695979" ||
                user.rut === "132012636" ||
                user.rut === "154157131" ||
                user.rut === "193928595") &&
              rolObtenido === "SUPER_ADMIN"
                ? 2
                : 3
            }
          >
            <Card
              onClick={() => {
                setMostrarComisionesPredefinida(true);
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/static/icons/comision.png"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Comisión
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {rolObtenido === "SUPER_ADMIN" &&
            (user.rut === "192613639" ||
              user.rut === "194467354" ||
              user.rut === "121695979" ||
              user.rut === "132012636" ||
              user.rut === "154157131" ||
              user.rut === "193928595") && (
              <Grid item xs={6} md={2}>
                <Card
                  onClick={() => {
                    navigate("/dashboard/configuraciones/prorrateo");
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image="/static/icons/prorrateo.svg"
                    />
                    <CardContent>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        component="p"
                        style={{ textAlign: "center" }}
                      >
                        Prorrateo
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
        </Grid>
        {mostrarComisionesPredefinida && (
          <ComisionesPredefinida
            open={mostrarComisionesPredefinida}
            setOpen={setMostrarComisionesPredefinida}
          />
        )}
      </Container>
    </Page>
  );
}
