import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import AppDispersionTareasEmpleado from "src/components/estadisticas/app-dispersion-tareas-empleados";
import AppEmployeesTask from "src/components/estadisticas/app-employees-task";

const ModalDetalleTareaPorEstado = ({
  isOpen,
  setOpen,
  titulo,
  dataTable,
  datosGraficoDispersion,
  cargandoDatosGraficoDispersion,
  loadingDataTable,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-detalle-tareas"
      id="dialog-detalle-tareas"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle style={styles.title} htmlFor="dialog-detalle-tareas">
        {titulo ? titulo : "Detalle tareas"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <AppEmployeesTask
              loading={loadingDataTable}
              rows={!loadingDataTable ? dataTable : []}
              title="Tareas empleados"
              subheader="Se muestran las tareas agrupadas por empleado"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppDispersionTareasEmpleado
              title="Dispersión tareas por empleado"
              subheader="Se muestra la distribución de tareas por empleado"
              chartData={
                !cargandoDatosGraficoDispersion && datosGraficoDispersion
                  ? datosGraficoDispersion
                  : []
              }
              loadingChartData={cargandoDatosGraficoDispersion}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalDetalleTareaPorEstado.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  titulo: PropTypes.string,
  dataTable: PropTypes.array,
  datosGraficoDispersion: PropTypes.array,
  cargandoDatosGraficoDispersion: PropTypes.bool,
  loadingDataTable: PropTypes.bool,
};

export default ModalDetalleTareaPorEstado;
