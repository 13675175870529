import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { Box, Drawer, Typography, Avatar } from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";

// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import LoginOptionsModal from "src/components/authentication/login/LoginOptionsModal";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const {
    empleado: user,
    rol: rolUsuario,
    cliente: clienteObtenido,
    sucursal: { refSucursal },
  } = useSelector((state) => state.auth.user);
  const [sucursales, setSucursales] = useState([]);
  const [showLoginOptions, setShowLoginOptions] = useState(false);

  const sucursalesOptions = async () => {
    let rut;
    if (rolUsuario === "CLIENTE") {
      rut = clienteObtenido.rut;
    } else {
      rut = user.rut;
    }
    await axios
      .get(`${API_SERVER}/sucursales/sucursalesEmpleado/${rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data === -2) window.location.reload();
        setSucursales(response.data);
        setShowLoginOptions(true);
      });
  };
  const account =
    rolUsuario === "CLIENTE"
      ? {
          displayName: clienteObtenido?.nombre,
          email: clienteObtenido?.correo,
          photoURL: "/static/mock-images/avatars/avatar_default.jpg",
        }
      : {
          displayName: user?.nombre,
          email: user?.correo,
          photoURL: "/static/mock-images/avatars/avatar_default.jpg",
        };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        {rolUsuario !== "CLIENTE" ? (
          <AccountStyle
            onClick={() => sucursalesOptions()}
            style={{ cursor: "pointer" }}
          >
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {account.displayName}
              </Typography>
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {refSucursal ? `Sucursal: ${refSucursal}` : ""}
              </Typography>
            </Box>
          </AccountStyle>
        ) : (
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {account.displayName}
              </Typography>
            </Box>
          </AccountStyle>
        )}
      </Box>

      <NavSection navConfig={sidebarConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      {sucursales.length > 1 && (
        <LoginOptionsModal
          sucursales={sucursales}
          open={showLoginOptions}
          setOpen={setShowLoginOptions}
          user={user}
        />
      )}
    </RootStyle>
  );
}
