import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "@material-ui/lab/LoadingButton";
import EditIcon from "@material-ui/icons/Edit";
import EditarComisionesPredefinida from "./EditarComisionesPredefinida";
import { ajustesApi } from "src/services/ajustesApi";

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hidden: {
    display: "none",
  },
});

// ----------------------------------------------------------------------

export default function ComisionesPredefinida({ open, setOpen }) {
  const classes = useStyles();
  const [mostrarComision, setMostrarComision] = useState(false);

  const [comisionSeleccionada, setComisionSeleccionada] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    data: comisiones = [],
    isLoading: isLoadingComisiones,
  } = ajustesApi.useGetComisionesPredefinidasQuery()

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      id="dialogComisionesPredefinidas"
    >
      <DialogTitle>Comisiones Predefinidas</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>TipoTarea</TableCell>
                <TableCell>Comisión</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(!isLoadingComisiones && comisiones) &&
                comisiones.map((row, idx) => {
                  return (
                    <TableRow key={"facturasAsociadas_" + idx}>
                      <TableCell component="th" scope="row">
                        {row.tipoTarea}
                      </TableCell>
                      <TableCell>{row.comision}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setComisionSeleccionada(row);
                            setMostrarComision(true);
                          }}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {mostrarComision && comisionSeleccionada && (
          <EditarComisionesPredefinida
            open={mostrarComision}
            setOpen={setMostrarComision}
            comision={comisionSeleccionada}
          />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose}>Cerrar</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
