import { filter } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Stack,
  IconButton
} from "@material-ui/core";
import {
  removeAccents,
} from "../../utils/converter";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import { TablaHead, TablaToolbar } from "../../components/_dashboard/tablas";
import * as locales from "@material-ui/core/locale";
import Page from "../../components/Page";
import { Alerta } from "../../components/Alerta";
import AdjuntarDocumento from "./AdjuntarDocumento";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import SubTareasDialogCliente from "./SubTareasDialogCliente";
import { AlertaMensajeEliminacion } from "../AlertaMensajeEliminacion";
import Search from '@material-ui/icons/Search';
import { useSelector } from "react-redux";
import moment from "moment";

const TABLE_HEAD = [
  { id: "nombre", label: "Tarea", alignRight: false },
  { id: "sucursal", label: "Sucursal", alignRight: false },
  { id: "tipoTarea", label: "Tipo Tarea", alignRight: false },
  { id: "fechaInicio", label: "Fecha Inicio", alignRight: false },
  { id: "fechaTermino", label: "Fecha Termino", alignRight: false },
  { id: "progreso", label: "Progreso", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        removeAccents(_user.nombre.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        removeAccents(_user.tipoTarea.toLowerCase()).indexOf(
          removeAccents(query.toLowerCase())
        ) !== -1 ||
        _user.fechaInicio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaTermino.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TareasTerminadas(props) {
  const { tareasCliente } = props;
  const {
    cliente: user,
  } = useSelector((state) => state.auth.user);
  //const navigate = useNavigate();

  const [adjuntar, setAdjuntar] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [elementoSeleccionado, setElementoSeleccionado] = useState(null);
  const [porcentajeSubTareas, setPorcentajeSubTareas] = useState([]);
  const [subTareas, setSubTareas] = useState([]);
  const [subTareaSeleccionada, setSubTareaSeleccionada] = useState(null);
  const [mostrarSubTareas, setMostrarSubTarea] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [locale] = useState("esES");
  const [loadingTable, setLoadingTable] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tareasCliente.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tareasCliente.length) : 0;

  const filteredUsers = applySortFilter(
    tareasCliente,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  useEffect(() => {
    axios.get(`${API_SERVER}/accesoClientes/subtareas/${user.rut}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("token")
      }
    }).then((response) => {
      if (response.data === -2) window.location.reload();
      if (isMounted.current) {
        let arregloSubTarea = response.data;

        if (Array.isArray(arregloSubTarea)) {
          let arregloPorcentaje = []
          tareasCliente.forEach((element) => {
            let tareasTerminadas = arregloSubTarea.filter((e) => element.id === e.idTarea && e.estadoSubTarea === "TERMINADA").length;
            let tareasTotal = arregloSubTarea.filter((e) => element.id === e.idTarea).length;
            if (tareasTotal === 0) {
              arregloPorcentaje.push({ idTarea: element.id, porcentaje: 0 })

            }
            else {
              let porcentaje = (tareasTerminadas / tareasTotal) * 100
              arregloPorcentaje.push({ idTarea: element.id, porcentaje: porcentaje })

            }

          })
          setSubTareas(arregloSubTarea)
          setPorcentajeSubTareas(arregloPorcentaje)
          setLoadingTable(false);
        }
        else {
          setLoadingTable(false);

        }

      }


    }).catch(() => {

    })
  }, [tareasCliente, user.rut])


  return (
    <Page title="TAXTIC">
      <Container>
        <Card>
          <Grid
            container
            style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs md>
              <TablaToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {!loadingTable ? (
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tareasCliente.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .filter(e => e.estado === "TERMINADA")
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          nombre,
                          tipoTarea,
                          fechaInicio,
                          estado,
                          fechaTermino,
                          sucursal,
                        } = row;
                        const isItemSelected = selected.indexOf(nombre) !== -1;
                        let porcentajeSubtareasAux = 0
                        if (porcentajeSubTareas && porcentajeSubTareas.length >= 1) {
                          porcentajeSubtareasAux = porcentajeSubTareas.filter((e) => e.idTarea === id)[0];
                          if (porcentajeSubtareasAux.porcentaje) {
                            porcentajeSubtareasAux = porcentajeSubtareasAux.porcentaje;
                          }
                          else {
                            porcentajeSubtareasAux = 0;
                          }
                        }



                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell
                              align="left"
                              component="th"
                            >
                              <Typography variant="subtitle2">
                                {nombre}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              {sucursal}
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              {tipoTarea}
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              {
                                fechaInicio === "" ? (fechaInicio) :
                                  moment(fechaInicio, "YYYY/MM/DD").format("DD/MM/YYYY")
                              }
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              {
                                fechaTermino === "" ? (fechaTermino) :
                                  moment(fechaTermino, "YYYY/MM/DD").format("DD/MM/YYYY")
                              }
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let subTareaObtenida = subTareas.filter((e) => e.idTarea === id);
                                if (subTareaObtenida.length >= 1) {
                                  setSubTareaSeleccionada(subTareaObtenida)
                                  setMostrarSubTarea(true);

                                }
                                else {
                                  setMostrarAlerta(true);

                                }

                              }}
                            >
                              <Stack
                                direction="row"
                              >
                                <Tooltip title="Ver Progreso">
                                  <IconButton aria-label="delete">
                                    <Search />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={tareasCliente.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      </Container>

      {adjuntar && (
        <AdjuntarDocumento
          open={adjuntar}
          setOpen={setAdjuntar}
          tarea={elementoSeleccionado}
          setShowMessage={setShowAlert}
          setColor={setColor}
          setMessage={setMessage}
        />
      )}

      {mostrarSubTareas && subTareaSeleccionada && (
        <SubTareasDialogCliente
          open={mostrarSubTareas}
          setOpen={setMostrarSubTarea}
          subTareas={subTareaSeleccionada}
        />

      )}

      {mostrarAlerta && (
        <AlertaMensajeEliminacion
          mensaje="No existen tareas que mostrar."
          open={mostrarAlerta}
          setOpen={setMostrarAlerta}
          aceptar={() => {
            setMostrarAlerta(false)
          }}
          mensajeBotonAceptar="Cerrar"
          ocultar="Ocultar"
        />

      )

      }

      {showAlert && (
        <Alerta
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          color={color}
          message={message}
        />
      )}
    </Page>
  );
}
