import React, { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  Divider,
} from "@material-ui/core";
import viewIc from "@iconify/icons-carbon/view";
import deleteIc from "@iconify/icons-carbon/trash-can";
import CustomUploadComponent from "../tarea/componentes/CustomUploadComponent";
import FileListSkeleton from "./FileListSkeleton";
import { useTheme } from "@material-ui/styles";
import { fileFormat, fileThumb } from "../file-thumbnail";
import Iconify from "../iconify";

const FileList = ({
  tarea,
  archivos = [],
  cargandoArchivos,
  onDownload,
  onDeleteClick,
  generateDocument,
  loadingGenerateDocument,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fileCount = archivos?.length || 0;
  const fileText = fileCount === 1 ? "archivo" : "archivos";

  // Estado para manejar los archivos que se están eliminando
  const [deletingFiles, setDeletingFiles] = useState(new Set());

  // Manejador de eliminación que controla el estado de carga
  const handleDelete = async (file) => {
    setDeletingFiles((prev) => new Set([...prev, file.id]));
    try {
      await onDeleteClick(file);
    } finally {
      setDeletingFiles((prev) => {
        const newSet = new Set(prev);
        newSet.delete(file.id);
        return newSet;
      });
    }
  };

  const FileListItem = ({ file }) => {
    const format = fileFormat(file.nombreArchivo || "");

    return (
      <>
        <ListItem
          key={file.id}
          style={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
            padding: isMobile ? "16px" : "8px 16px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            width={isMobile ? "100%" : "auto"}
            mb={isMobile ? 1 : 0}
            sx={{ marginLeft: 2 }}
          >
            <ListItemIcon>
              <Box
                component="img"
                src={fileThumb(format)}
                sx={{
                  width: 25,
                  height: 25,
                  flexShrink: 0,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  style={{
                    wordBreak: "break-word",
                    paddingRight: isMobile ? 0 : "48px",
                  }}
                >
                  {file.nombreArchivo}
                </Typography>
              }
            />
          </Box>
          <Box
            component={isMobile ? "div" : ListItemSecondaryAction}
            display="flex"
            justifyContent={isMobile ? "flex-end" : "flex-end"}
            width={isMobile ? "100%" : "auto"}
          >
            <Tooltip title="Ver archivo">
              <IconButton
                edge="end"
                aria-label="ver"
                onClick={() => onDownload(file.link)}
                size="small"
              >
                <Iconify icon={viewIc} width={20} />
              </IconButton>
            </Tooltip>
            {deletingFiles.has(file.id) ? (
              <IconButton
                edge="end"
                aria-label="eliminar"
                onClick={() => handleDelete(file)}
                size="small"
                disabled
              >
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <Tooltip title="Eliminar archivo">
                <IconButton
                  edge="end"
                  aria-label="eliminar"
                  onClick={() => handleDelete(file)}
                  size="small"
                >
                  <Iconify icon={deleteIc} width={20} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </ListItem>
        <Divider variant="middle" />
      </>
    );
  };

  return (
    <>
      <CustomUploadComponent
        estadoTarea={tarea.estado}
        generateDocument={generateDocument}
        tipoTarea={tarea.tipoTarea}
        idTarea={tarea.id}
        loadingGenerateDocument={loadingGenerateDocument}
        cantidadArchivosCargados={fileCount}
      />
      {!cargandoArchivos ? (
        <Card sx={{ marginTop: 2 }}>
          <CardHeader title={`${fileCount} ${fileText} guardados`} />
          <List>
            {archivos.map((file) => (
              <FileListItem key={file.id} file={file} />
            ))}
          </List>
        </Card>
      ) : (
        <FileListSkeleton />
      )}
    </>
  );
};

export default FileList;
