import { Box } from "@material-ui/core";
import { memo } from "react";

// ----------------------------------------------------------------------

function TareasMensualesIlustracion({ ...other }) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="100%"
        height="100%"
        viewBox="-30 -140 750 750"
      >
        <path
          fill="#BFECFA"
          d="M627.868 440.036c15.309-4.623 29.994-12.838 39.618-25.609 3.96-5.255 7.062-12.258 4.405-18.277-3.996-9.057-16.666-8.754-25.797-12.578-1.478-.619-2.964-1.436-3.819-2.79-2.278-3.607 1.092-8.09 4.372-10.819 9.307-7.743 19.937-13.8 29.09-21.725 9.152-7.924 17.018-18.388 18.104-30.446.165-1.831.161-3.734-.538-5.435-2.499-6.082-11.127-5.676-17.559-4.309s-15.172 1.42-17.285-4.807c-.672-1.979-.407-4.154.086-6.187 2.1-8.639 8.03-15.837 11.109-24.177 3.08-8.339 1.968-19.745-6.132-23.407-7.4-3.347-15.941 1.813-21.227 7.979-8.548 9.973-13.026 22.757-16.447 35.438-1.009 3.739-2.527 8.121-6.273 9.104-2.094.55-4.322-.196-6.198-1.276-5.074-2.923-9.22-8.478-15.074-8.376-4.229.074-7.872 3.342-9.667 7.171s-2.104 8.162-2.386 12.382l-2.719 40.762c-.434 6.517-2.548 14.869-9.053 15.441-2.107.185-4.177-.597-6.288-.729-5.854-.364-11.044 4.501-12.882 10.071-1.837 5.57-1.041 11.644.109 17.395 2.813 14.07 8.252 40.87 21.379 49.32 12.321 7.93 38.479-.312 51.072-4.116z"
        />
        <path
          fill="none"
          stroke="#FFF"
          strokeMiterlimit={10}
          d="M580.968 446.238c4.138-42.084 19.644-82.996 44.438-117.252"
        />
        <path
          fill="#9BD1DD"
          d="M581.449 450.247c23.372-48.661 65.173-88.193 115.063-108.814-6.836 28.854-18.207 55.599-38.663 77.066-20.458 21.469-46.862 34.369-76.4 31.748z"
        />
        <path
          fill="none"
          stroke="#FFF"
          strokeMiterlimit={10}
          d="M581.472 450.018c10.047-6.632 19.078-14.668 28.069-22.674l58.447-52.04"
        />
        <path
          fill="#BFECFA"
          d="M102.968 358.717c-.636-2.559-1.458-5.311-3.595-6.854-3.297-2.382-7.946-.735-11.368 1.464-3.421 2.2-6.907 5.016-10.969 4.818-7.682-.373-10.404-10.314-10.479-18.005-.19-19.71 2.779-40.71-6.533-58.083-2.19-4.085-6.036-8.281-10.605-7.502-2.826.481-4.968 2.77-6.668 5.076-8.632 11.712-11.406 26.755-11.947 41.293-.125 3.358-.648 7.487-3.786 8.693-1.277.49-2.714.331-4.035-.024-3.437-.927-6.408-3.082-9.732-4.354-3.324-1.272-7.542-1.444-10.001 1.128-1.218 1.274-1.802 3.019-2.223 4.73-2.328 9.447-.651 19.813 4.538 28.045 6.119 9.705 16.547 16.111 22.542 25.894.982 1.603 1.86 3.389 1.753 5.266-.252 4.418-5.319 6.573-8.545 9.603-6.409 6.018-5.467 17.071-.117 24.046 5.351 6.976 13.85 10.709 22.171 13.545 12.542 4.275 53.634 19.147 62.552 3.375 5.483-9.699 3.365-28.567 3.002-39.334a206.316 206.316 0 0 0-5.955-42.82z"
        />
        <path
          fill="none"
          stroke="#FFF"
          strokeMiterlimit={10}
          d="M42.165 345.662c11.372 37.173 31.619 71.598 58.579 99.603"
        />
        <path
          fill="#9BD1DD"
          d="M98.438 446.726c-26.369-26.504-54.279-53.958-90.057-64.806 6.505 19.403 15.112 36.515 31.841 48.302s37.755 16.906 58.216 16.504z"
        />
        <path
          fill="none"
          stroke="#FFF"
          strokeMiterlimit={10}
          d="M24.812 399.605c20.541 20.834 45.818 36.972 73.363 46.835"
        />
        <path fill="#FFF" d="M63.427 7.104h540.402v345.66H63.427z" />
        <path fill="#F24964" d="M63.427 7.104h540.402v33.331H63.427z" />
        <path fill="none" d="M63.427 7.104h540.402v345.66H63.427z" />
        <path
          fill="none"
          stroke="#00AEEF"
          strokeMiterlimit={10}
          strokeWidth={0.98}
          d="M86.044 53.576h70.738V96.82H86.044zm70.739 0h70.738V96.82h-70.738zm70.738 0h70.738V96.82h-70.738zm70.738 0h70.738V96.82h-70.738zm70.738 0h70.738V96.82h-70.738zm70.738 0h70.738V96.82h-70.738zm70.738 0h70.737V96.82h-70.737zM86.044 96.82h70.738v242.874H86.044zm70.739 0h70.738v242.874h-70.738zm70.738 0h70.738v242.874h-70.738zm70.738 0h70.738v242.874h-70.738zm70.738 0h70.738v242.874h-70.738zm70.738 0h70.738v242.874h-70.738zm70.738 0h70.737v242.874h-70.737z"
        />
        <text
          fontFamily="'Comfortaa-Regular'"
          fontSize={10.404}
          transform="translate(108.607 80.515)"
        >
          {"MON"}
        </text>
        <text fontSize={10.404} transform="translate(180.88 80.515)">
          {"TUE"}
        </text>
        <text fontSize={10.404} transform="translate(249.018 80.515)">
          {"WED"}
        </text>
        <text fontSize={10.404} transform="translate(318.799 80.515)">
          {"THU"}
        </text>
        <text fontSize={10.404} transform="translate(392.3 80.515)">
          {"FRI"}
        </text>
        <text fontSize={10.404} transform="translate(459.06 80.515)">
          {"SAT"}
        </text>
        <text fontSize={10.404} transform="translate(526.925 80.515)">
          {"SUN"}
        </text>
        <path
          fill="#221F20"
          d="m444.407 170.361-55.868 3.183-2.653-42.334 42.196-14.026zM93.068 122.487h54.166v72.285H93.068z"
          opacity={0.51}
        />
        <path
          fill="#FBB231"
          d="M155.923 184.469c-7.637-20.194-9.366-40.537-7.797-62.267l-55.058.284c-1.966 22.287-2.107 44.335 6.627 64.933.213.502 1.165.824 1.046.292 18.151 1.482 36.998-2.258 55.182-3.242z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="M99.119 135.076h19.314m5.514 0h9.847M99.137 149.26h35.993m-34.694 11.641h35.993m-33.663 11.641h38.68"
        />
        <path
          fill="#221F20"
          d="M518.738 122.487h55.269v72.285h-55.269z"
          opacity={0.51}
        />
        <path
          fill="#FBB231"
          d="M581.592 184.469c-7.637-20.194-9.365-40.537-7.797-62.267l-55.058.284c-1.966 22.287-2.107 44.335 6.627 64.933.214.502 1.165.824 1.047.292 18.151 1.482 36.998-2.258 55.181-3.242z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="M524.789 135.076h19.313m5.515 0h9.847m-34.657 14.184H560.8m-34.695 11.641h35.994m-33.664 11.641h38.681"
        />
        <path
          fill="#221F20"
          d="M378.895 249.707h54.176v69.44h-54.176z"
          opacity={0.51}
        />
        <path
          fill="#F24964"
          d="M441.75 311.688c-7.637-20.194-9.365-40.536-7.796-62.267l-55.059.285c-1.966 22.287-2.107 44.334 6.628 64.933.213.502 1.164.824 1.046.292 18.15 1.482 36.997-2.258 55.181-3.243z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="m388.276 262.381 20.487.622m6.42-.382a25.383 25.383 0 0 1 7.573-.307m-32.855 14.754A224.598 224.598 0 0 0 424.35 275m-32.309 16.858a20.659 20.659 0 0 1 7.012-.474m7.498-.762a34.472 34.472 0 0 1 7.05-.904m8.622-.512h7.004m-33.945 12.048c9.722-.058 19.44-.677 29.091-1.855"
        />
        <path
          fill="#221F20"
          d="M232.828 117.013h54.461v71.041h-54.461z"
          opacity={0.51}
        />
        <path
          fill="#F24964"
          d="M295.682 178.996c-7.637-20.194-9.366-40.537-7.797-62.267-19.062-.538-36.082-1.604-55.058.284-1.966 22.287-2.107 44.335 6.627 64.933.213.502 1.165.824 1.046.292 18.151 1.482 36.998-2.258 55.182-3.242z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="m242.208 129.688 20.488.623m6.419-.383a25.35 25.35 0 0 1 7.573-.307m-32.855 14.754a224.618 224.618 0 0 0 34.45-2.068m-32.31 16.859a20.657 20.657 0 0 1 7.012-.474m7.498-.763a34.544 34.544 0 0 1 7.051-.905m8.621-.511h7.004m-33.945 12.048c9.722-.057 19.44-.677 29.091-1.855"
        />
        <path
          fill="#221F20"
          d="M95.993 201.642h51.241v68.986H95.993z"
          opacity={0.51}
        />
        <path
          fill="#0F87AB"
          d="M161.172 264.939c-8.074-20.549-12.604-42.754-12.923-64.83l-52.256 1.532a200.764 200.764 0 0 0 8.338 64.61c19.37.287 37.474-.878 56.841-1.312z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="m100.194 214.976 17.03.715m4.343-.361h10.353m-29.698 13.575 27.716-.475m5.666-.434 4.698-.407m-35.268 14.437 12.701-.84m5.679.073 18.698-.484"
        />
        <path
          fill="#221F20"
          d="M305.284 190.03h52.411v71.03h-52.411z"
          opacity={0.51}
        />
        <path
          fill="#0F87AB"
          d="M370.462 253.328c-8.073-20.549-12.604-42.754-12.923-64.83l-52.256 1.532a200.734 200.734 0 0 0 8.339 64.61c19.37.286 37.474-.879 56.84-1.312z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="m309.485 203.364 17.03.715m4.343-.361h10.353m-29.699 13.575 27.716-.475m5.667-.434 4.698-.407m-35.269 14.437c4.234-.279 8.467-.56 12.701-.84m5.68.072 18.698-.483"
        />
        <path
          fill="#221F20"
          d="M232.566 224.151h57.742v76.175h-57.742z"
          opacity={0.51}
        />
        <path
          fill="#FF8724"
          d="M302.897 286.415c-10.065-18.055-8.627-41.977-12.589-62.264h-57.742c.274 22.429 3.198 44.333 8.696 66.08 20.338-.86 41.324-2.457 61.635-3.816z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.999}
          d="M241.664 239.745a338.3 338.3 0 0 0 22.042.58m3.783.231c3.665.326 7.354.381 11.028.162m-33.469 14.373 37.313-1.323m-33.787 12.454c4.382.045 8.766-.086 13.138-.395m6.898.004c3.437.417 6.923.42 10.36.009m-27.846 11.309 39.184-2.681"
        />
        <path
          fill="#221F20"
          d="M143.82 446.319a127.301 127.301 0 0 0-37.864 3.579c-2.35.587-5.165 1.81-5.256 4.229-.042 1.098.541 2.122 1.216 2.987 4.698 6.025 13.428 6.626 21.067 6.715l32.724.382c16.126.188 30.789-.343 46.497-3.999 3.273-.762 7.235-2.497 7.216-5.857-.019-3.383-4.049-5.063-7.356-5.774-17.681-3.809-40.187-1.25-58.244-2.262z"
          opacity={0.51}
        />
        <path
          fill="#221F20"
          d="M142.578 210.337c-1.364-.729-2.933-.991-4.479-1.063-1.547-.073-3.115.036-4.6.475-3.435 1.016-6.185 3.769-7.762 6.985-1.577 3.217-2.086 6.864-2.097 10.447-.004 1.207-.015 2.57-.89 3.4-1.327 1.259-3.491.378-5.305.61-2.322.298-4.069 2.875-3.486 5.142.425 1.652 1.855 3.315 1.079 4.834-.41.803-1.324 1.21-2.201 1.419-1.736.414-3.562.287-5.312.64s-3.563 1.391-3.999 3.122c-.469 1.866.918 3.801 2.671 4.595.837.379 1.873.677 2.18 1.543a23.191 23.191 0 0 0-4.901 4.57c-.412.515-.821 1.105-.794 1.764.048 1.156 1.351 1.808 2.487 2.024 4.048.774 8.159-.768 12.278-.898 2.678-.085 5.339.429 8.017.516a25.265 25.265 0 0 0 7.551-.904c1.88-.522 3.784-1.324 5.005-2.846.68-.847 1.106-1.866 1.485-2.884 2.203-5.919 3.08-12.234 3.941-18.491l1.052-7.636c.719-5.208 4.036-14.18-1.92-17.364z"
        />
        <path
          fill="#ED8D5E"
          d="M210.036 215.364c.548 1.974-.255 2.69.814 4.438.695 1.136 1.206 2.089 2.48 1.702.654-.198 2.293-.736 2.821-1.17 3.5-2.873 3.209-3.172 3.088-5.609s-1.393-4.884-3.543-6.037c-3.534.33-6.61 3.256-5.66 6.676z"
        />
        <path
          fill="#ED8D5E"
          d="M209.618 213.667a57.523 57.523 0 0 0 8.522-6.594c.553-.518 1.123-1.094 1.275-1.835.153-.742-.331-1.658-1.088-1.662-.456-.002-.846.308-1.195.601l-8.623 7.264c-.313 1.391-.317 2.263 1.109 2.226z"
        />
        <path
          fill="#ED8D5E"
          d="M214.812 210.08a3.1 3.1 0 0 0-.979-1.365c-1.927-1.503-5.031-1.167-6.591.715-1.847 2.228 2.948 10.689 4.162 9.432 1.019-1.055.716-2.86-.153-4.042-.233-.317-1.363-2.696-1.389-3.089-.101-1.509 2.646-.899 4.03-.292.664.291 1.169-.664.92-1.359z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M212.148 217.147c.14-1.688-.641-3.311-1.517-4.762-.278-.461-.551-1.106-.163-1.479.313-.3.822-.175 1.23-.031l2.115.746c.303.107.632.215.939.119.36-.113.588-.493.616-.87.027-.376-.112-.746-.29-1.078a4.563 4.563 0 0 0-3.115-2.288"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M214.975 209.514c.315-.433 1.219-.642 1.672-.356.5.314 1.06.731 1.055 1.322-.002.276-.135.533-.275.772a9.215 9.215 0 0 1-3.721 3.516c-.315.162-.659.309-1.012.275-.353-.033-.707-.307-.709-.661-.133-.019-.233-.168-.229-.302a3.601 3.601 0 0 1 1.158-2.513"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M213.379 215.249c-.184.549.176 1.181.703 1.422.527.241 1.158.149 1.678-.107.519-.257.948-.662 1.362-1.067.698-.681 1.404-1.41 1.714-2.334.311-.925.104-2.097-.724-2.613"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M215.373 217.041c.011.589.289 1.179.768 1.522.479.344 1.156.412 1.672.126.278-.153.498-.394.694-.644.565-.719.988-1.567 1.11-2.474.123-.906-.076-1.872-.635-2.596"
        />
        <path
          fill="#F24964"
          d="M160.764 270.628c12.077-5.25 25.276-10.83 32.771-21.658 6.298-9.1 12.636-18.451 18.935-27.55a24.15 24.15 0 0 0-4.723-6.239c-7.527 7.316-15.648 15.238-23.175 22.555-1.707 1.66-5.528 2.381-7.818 3.031a103.743 103.743 0 0 1-31.331 3.89c.035 10.889 6.5 19.612 15.341 25.971z"
        />
        <path
          fill="#221F20"
          d="M123.969 447.596c-1.348.102-2.49-.895-3.693-1.51-.163-.083-.335-.144-.504-.213.735 2.814 1.472 5.629 2.228 8.438 6.111.328 12.563.916 18.674 1.244.287-.816.134-2.192-.027-3.042-4.057-1.778-8.176-4.311-11.893-6.784l-3.256 1.401c-.494.212-.996.426-1.529.466z"
        />
        <path
          fill="#5BA3F5"
          d="M163.537 444.411c5.768-37.202 12.058-73.241 10.792-110.306-20.897 9.527-45.592 10.462-67.16 2.572-.231.666-.528 1.311-.701 1.994-3.785 14.928-.505 49.395 5.065 73.644a1175.544 1175.544 0 0 0 8.238 33.558c.169.069.342.13.504.213 1.204.615 2.346 1.611 3.693 1.51.533-.04 1.036-.254 1.527-.465 1.085-.468 2.171-.935 3.256-1.401l-.504-.335c.299-28.637 2.655-57.683 8.406-85.737.426-2.078.898-4.226 2.164-5.929 1.266-1.702 3.556-2.836 5.548-2.106 6.587 30.56 5.456 62.366 10.384 93.199 1.168.624 2.303 1.324 3.603 1.337 1.815.017 3.48-1.328 5.185-1.748z"
        />
        <path
          fill="#F24964"
          d="M174.329 334.105a258.257 258.257 0 0 0-1.241-18.137c-2.589-24.641-9.145-48.71-18.681-71.577-6.461-.134-12.971-.207-19.33.945s-12.625 3.616-17.38 7.991c-.592 10.569-2.078 20.376-2.67 30.946-.992 17.696-2.058 35.689-7.857 52.403 21.566 7.891 46.261 6.957 67.159-2.571z"
        />
        <path
          fill="#BFECFA"
          d="m129.221 304.382-5.274 38.804 32.613 3.214 4.017-42.018z"
        />
        <path
          fill="#ED8D5E"
          d="M135.09 332.03c2.306.654 4.655 1.253 7.052 1.287 2.396.035 4.871-.541 6.752-2.025.704-.082.993-.464 1.549-.903.556-.44.805-1.348.318-1.863.609-.154.741-1.08.782-1.706a1.537 1.537 0 0 0-.941-1.49c.398-.307.228-1.177.051-1.647a1.217 1.217 0 0 0-1.183-.763c-1.735-.349-3.969-.691-5.737-.627-2.1-.102-4.056.11-6.082.672-.849.235-1.721.551-2.313 1.203-.67.738-.872 1.784-.947 2.778-.125 1.668.271 3.466.699 5.084z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M150.596 325.392a22.505 22.505 0 0 1-6.711.158m7.041 2.927a19.936 19.936 0 0 1-6.878.074m4.787 2.696-5.584-.07"
        />
        <path
          fill="#ED8D5E"
          d="m142.063 323.7 4.278-2.765c.305-.196.622-.406.792-.727s.131-.782-.18-.968c-.185-.109-.413-.1-.627-.086-2.081.134-4.263.299-6.017 1.427-.6.385-1.126.871-1.65 1.354l-1.369 1.263c1.321.704 3.517 1.315 4.773.502z"
        />
        <path
          fill="#F24964"
          d="M133.727 245.657c-9.848 3.217-19.701 7.228-27.427 14.131-10.133 9.055-15.788 22.998-14.482 36.525 1.306 13.527 9.665 26.251 21.722 32.521a40.126 40.126 0 0 0 22.768 4.252c.557-2.915.475-6.794.595-9.758-11.468-2.026-21.984-12.423-23.154-24.01-.506-5.011.453-10.22 3.054-14.533 2.601-4.313 6.897-7.655 11.814-8.748 5.413-8.954 5.148-19.917 5.11-30.38z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M128.255 275.64c-9.847 2.793-16.8 13.416-15.42 23.558 1.138 8.361 7.144 15.376 14.156 20.07 2.95 1.976 6.339 3.682 9.879 3.4.098 3.58-.789 7.188-.691 10.769-6.954.873-13.406-.211-19.846-2.976-14.933-6.411-24.939-22.614-24.421-38.857.519-16.243 9.495-37.175 43.165-46.267"
        />
        <path
          fill="#221F20"
          d="M163.537 444.411c-1.705.42-3.37 1.765-5.184 1.747-1.3-.013-2.435-.713-3.603-1.337a210.376 210.376 0 0 0 1.886 10.254c5.678.333 12.922-.07 18.6.263.384-1.125 1.309-1.64 1.288-2.828-4.725-1.773-9.01-3.791-13.116-7.265l.129-.834z"
        />
        <path
          fill="#ED8D5E"
          d="M131.128 225.295c-.372-.259-.757-.523-1.2-.619-.443-.096-.963.017-1.222.389-.21.302-.208.702-.153 1.066a4.624 4.624 0 0 0 1.537 2.785c.555.479 1.32.83 2.014.596.707 5.476 2.26 10.719 3.795 16.022.344 1.187.699 2.398 1.412 3.407s1.863 1.796 3.097 1.741c1.776-.078 3.044-1.804 3.561-3.505.716-2.358.518-4.885.313-7.341 1.982-.59 3.136-2.871 3.836-4.817.7-1.946.737-4.064.646-6.131a54.027 54.027 0 0 0-1.094-8.714c-.534-2.549-1.338-5.203-3.274-6.945-2.974-2.676-8.03-2.166-10.861.661-2.829 2.828-3.705 7.621-2.407 11.405z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeMiterlimit={10}
          strokeWidth={0.433}
          d="M163.462 269.064a60.053 60.053 0 0 0-5.801-14.636"
        />
        <path
          fill="#221F20"
          d="M452.455 446.422c-15.53-.58-31.21.765-46.342 3.975-2.88.611-6.334 1.863-6.472 4.286-.062 1.098.64 2.117 1.456 2.976 5.683 5.979 16.352 6.49 25.693 6.5l40.014.043c19.719.021 37.655-.664 56.902-4.484 4.01-.796 8.873-2.573 8.885-5.935.012-3.385-4.898-5.023-8.936-5.701-21.58-3.628-49.129-.835-71.2-1.66z"
          opacity={0.51}
        />
        <path
          fill="#ED8D5E"
          d="M415.521 184.537a6.317 6.317 0 0 0-2.743-4.108c-1.381-.895-3.219-1.348-3.947-2.824-.969-1.962.696-4.167.945-6.34.108-.941 1.14-1.958 2.076-2.097.938-.139 1.889.178 2.718.636 3.424 1.89 5.022 5.866 6.369 9.537.247.675.499 1.382.403 2.095-.09.674-.484 1.273-.957 1.763-1.417 1.468-2.893 1.865-4.864 1.338zm35.359-7.03c2.378.109 4.422-2.027 4.899-4.359.477-2.333-.279-4.746-1.323-6.886-.906-1.859-2.114-3.684-3.935-4.666-1.82-.981-4.371-.849-5.664.765.347 3.285.424 6.702 1.381 9.864.622 2.053 2.499 5.184 4.642 5.282z"
        />
        <path
          fill="#F24964"
          d="m402.153 172.286 21.819-.417c3.325-.063 6.655-.127 9.964-.47a59.584 59.584 0 0 0 26.745-9.618c-13.335-12.783-21.91-30.69-24.128-49.029a1036.448 1036.448 0 0 0-50.96 4.761s-.136 8.207 0 11.64c.315 7.958 1.979 14.337 4.186 21.99 2.207 7.652 6.905 15.353 12.374 21.143z"
        />
        <path
          fill="#ED8D5E"
          d="M451.476 177.375c-1.717.922-3.957-.006-5.142-1.554-1.185-1.548-1.573-3.543-1.928-5.46l-1.157-6.26c.374-.38 1.046-.285 1.453.059s.616.862.811 1.358l1.563 3.998c2.607 1.17 3.992 5.03 4.4 7.859zm-35.255-1.303c-.694-3.265-1.088-5.221-1.274-8.005 0-.781.85-1.469 1.274-.781 2.252 3.643 4.563 8.566 3.518 12.719-.553.235-1.271-.072-1.481-.634"
        />
        <path
          fill="#5BA3F5"
          d="M498.004 232.056c-.268-1.014-5.531-4.314-6.479-4.764-7.434-3.521-14.049-9.209-19.94-14.95-2.293-2.234-4.451-4.627-6.187-7.317-1.894-2.936-3.255-6.182-4.45-9.464a109.773 109.773 0 0 1-5.22-20.04 17.916 17.916 0 0 1-8.794 1.639c2.972 8.246 3.188 17.318 6.169 25.561 4.582 12.669 14.421 22.66 24.499 31.599.722.641 1.726.831 2.685.926l6.136.607c1.085.107 2.205.211 3.245-.115.969-.303 1.788-.956 2.517-1.663 1.07-1.039 6.2-.576 5.819-2.019z"
        />
        <path
          fill="#5BA3F5"
          d="M458.368 255.495c3.679 2.526 6.027 1.183 9.608-1.48 3.581-2.662 5.891-6.805 6.89-11.154.832-3.624.712-7.642-.162-11.256-9.813 1.513-20.363-1.024-27.856-7.538-3.714-3.228-6.595-7.294-9.244-11.44-6.39-10-11.44-20.661-15.443-31.832-1.908 2.336-5.01 3.641-8.015 3.372 9.422 25.98 18.686 53.797 44.222 71.328z"
        />
        <path
          fill="#221F20"
          d="m442.536 445.16-.004.388a31.608 31.608 0 0 1-13.175 5.905c-.734 1.09-1.216 2.503-.626 3.677.344.686 1.352.584 2.117.598 6.818.128 13.64.03 20.451-.291.492-.022 1.106-.141 1.227-.619a396.811 396.811 0 0 0 1.677-6.964c-4.02.925-8.482-.063-11.667-2.694z"
        />
        <path
          fill="#5BA3F5"
          d="M514.167 330.355c-2.149-26.313-4.651-52.693-7.998-79.453-.756-6.037-2.9-15.797-8.164-18.847-4.697-2.721-11.457-1.351-17.059-2.143-5.767 2.284-13.31.979-17.238 5.751-1.45 1.762-2.068 4.046-2.612 6.263-5.408 22.046-7.221 44.791-9.016 67.42l-1.901 23.984a277.844 277.844 0 0 0 63.988-2.975z"
        />
        <path
          fill="#221F20"
          d="M516.409 448.875c-1.975-.246-3.848-.931-5.666-1.762a.987.987 0 0 0 .01.053c-3.411 1.793-7.216 3.306-13.03 4.513-.64 1.589-.466 2.146.156 3.742.3.772 1.003.782 1.831.784l16.183.038c2.58.006 5.227.001 7.641-.909-.214-2.595-.418-5.18-.624-7.767-1.865 1.185-4.271 1.585-6.501 1.308z"
        />
        <path
          fill="#F24964"
          d="m450.178 333.331-4.026 50.787c-1.717 21.65-3.433 39.411-3.616 61.042 3.186 2.631 7.647 3.619 11.667 2.693 6.229-26.768 10.164-54.596 18.453-80.699.974-3.067 2.018-6.21 4.086-8.676 2.067-2.467 5.422-4.128 8.527-3.285 2.157.586 2.785 2.968 3.798 4.96 13.603 26.741 16.527 57.401 21.676 86.96 1.818.831 3.691 1.516 5.666 1.762 2.23.277 4.637-.123 6.5-1.308-3.164-39.595-5.568-78.335-8.742-117.212a277.906 277.906 0 0 1-63.989 2.976z"
        />
        <path
          fill="#ED8D5E"
          d="M491.051 230.85c-.254 0-.136.065.119.065-.733 1.192-2.091 1.891-3.467 2.145-1.377.254-2.791.121-4.185-.014-1.313-.127-2.729-.298-3.693-1.198-.898-.839-1.197-2.12-1.452-3.321-.341-1.605-1.216-3.577-1.557-5.181-2.235-.259-4.661-.609-6.223-2.23-1.546-1.606-1.814-4.021-1.935-6.247-.34-6.306-.143-12.64.59-18.912.488-4.18 4.971-7.245 9.148-6.736 4.177.509 7.668 3.733 9.437 7.552 1.768 3.819 2.052 8.158 1.871 12.362-.182 4.205-.798 8.393-.716 12.6.057 2.879 1.36 6.322 2.063 9.115z"
        />
        <path
          fill="#221F20"
          d="M489.409 194.716c.199-2.617-1.727-4.909-3.776-6.548-2.051-1.639-4.42-3.037-5.759-5.295-.549-.925-.917-1.985-1.692-2.73s-2.185-1.005-2.863-.171c-.438.538-.41 1.312-.28 1.993a8.992 8.992 0 0 0 1.131 2.95c-1.476-.328-4.184-.013-5.689.119-1.038.091-2.326.581-2.323 1.623.003 1.243 1.747 1.584 2.52 2.558-1.966.321-4.385.953-4.553 2.938-.149 1.779 1.733 3.025 3.441 3.547 3.146.96 6.801 1.044 9.145 3.352 2.622 2.582 2.451 6.785 2.098 10.447.823-.696 2.195-.068 2.633.917.437.986.229 2.124.014 3.181-.19.935-.401 1.913-1.022 2.637-.62.724-1.79 1.072-2.544.488 1.231 2.916 2.565 6.51 5.313 8.08 1.396.798 3.563.904 4.253-.549.237-.499.235-1.071.247-1.623.099-4.719 1.49-9.305 2.175-13.975.681-4.672.13-10-2.469-13.939z"
        />
        <path
          fill="none"
          stroke="#221F20"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.867}
          d="m392.562 131.21 30.545-3.336m-28.559 12.573 30.544-3.336m12.551-1.465-8.782.96m-31.24 12.302 8.923-1.014m3.277-.414 8.924-1.014m4.604-.53 8.925-1.015"
        />
      </svg>
    </Box>
  );
}

export default memo(TareasMensualesIlustracion);
