import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import AppTablaDetalleClientesFacturas from "src/components/estadisticas/app-tabla-detalle-clientes-facturas";
import AppTopPlan from "src/components/estadisticas/app-top-plan";

const ModalDetalleEstadisticasFacturas = ({
  isOpen,
  setOpen,
  title,
  facturas,
  cargandoFacturas,
}) => {
  const [top5Facturas, setTop5Facturas] = useState([]);

  const obtenerTop5Facturas = useCallback(() => {
    const facturasDesc = facturas.slice(0, 5);

    const top5Facturas = facturasDesc.map((factura) => ({
      nombre: factura.nombre,
      cantidad: factura.totalFacturas,
    }));
    setTop5Facturas(top5Facturas);
  }, [facturas]);

  useEffect(() => {
    if (!cargandoFacturas && facturas) {
      obtenerTop5Facturas();
    }
  }, [cargandoFacturas, facturas, obtenerTop5Facturas]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-modal-estadisticas-facturas"
      id="dialog-modal-estadisticas-facturas"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle htmlFor="dialog-modal-estadisticas-facturas">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4} lg={4}>
            <AppTopPlan
              list={top5Facturas}
              loading={cargandoFacturas}
              title="Clientes facturación más alta"
              subheader="Se muestran los clientes con la mayor cantidad de facturas emitidas"
              tipo="moneda"
            />
          </Grid>
          <Grid item sm={12} md={8} lg={8}>
            <AppTablaDetalleClientesFacturas
              loading={cargandoFacturas}
              rows={!cargandoFacturas && facturas ? facturas : []}
              title="Detalle facturación por clientes"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ModalDetalleEstadisticasFacturas.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  facturas: PropTypes.arrayOf(
    PropTypes.shape({
      rut: PropTypes.string.isRequired,
      nombre: PropTypes.string.isRequired,
      fechaIngreso: PropTypes.string.isRequired,
      correo: PropTypes.string.isRequired,
      telefono: PropTypes.string.isRequired,
      totalFacturas: PropTypes.number.isRequired,
    }).isRequired
  ),
  cargandoFacturas: PropTypes.bool.isRequired,
};

export default ModalDetalleEstadisticasFacturas;
