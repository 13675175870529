import { DialogActions, Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { facturasApi } from "src/services/facturasApi";
import * as Yup from "yup";
import AutoCompleteField from "../sharedFields/AutocompleteField";
import { useCallback, useEffect, useState } from "react";
import { tareasApi } from "src/services/tareasApi";
import { prorrateosApi } from "src/services/prorrateosApi";
import { LoadingButton } from "@material-ui/lab";
import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { AlertaToast } from "src/components/AlertaSwall";

const FormEnlaceSucursal = ({
  data,
  setOpen,
  statesModal,
  setIndiceCargandoEnlaceSucursal,
}) => {
  const [enlazarFacturaASucursal] =
    facturasApi.useEnlazarFacturaASucursalMutation();
  const [
    obtenerTareasParaFacturar,
    { data: datosTareasParaFacturar = [], isFetching: obteniendoDatosTareas },
  ] = tareasApi.useLazyObtenerTareasClienteFacturacionQuery();
  const { data: sucursales = [], isLoading: isLoadingSucursales } =
    prorrateosApi.useObtenerSucursalesConProrrateoQuery();
  const [cargando, setCargando] = useState(false);

  const ProrrateoSchema = Yup.object().shape({
    tipoFactura: Yup.object()
      .shape({
        id: Yup.string().required("El ID del tipo factura es requerido"),
        label: Yup.string().required("El label del tipo factura es requerido"),
      })
      .required("El tipo de factura es requerido"),
    tarea: Yup.object().when("tipoFactura.id", {
      is: "ESPECIAL",
      then: Yup.object()
        .shape({
          idTarea: Yup.string().required("El ID de la tarea es requerido"),
          label: Yup.string().required("El label de la tarea es requerido"),
        })
        .test(
          "tareaRequerida",
          "La tarea es requerida",
          (value) => value !== null
        ),
      otherwise: Yup.object().nullable(true),
    }),
    sucursal: Yup.object().when("tipoFactura.id", {
      is: "PLAN",
      then: Yup.object()
        .shape({
          id: Yup.number().required(),
          nombre: Yup.string().required(),
        })
        .test(
          "tareaRequerida",
          "La tarea es requerida",
          (value) => value !== null
        ),
      otherwise: Yup.object().nullable(true),
    }),
  });

  const formik = useFormik({
    initialValues: {
      tipoFactura: { id: "PLAN", label: "Plan" },
      tarea: datosTareasParaFacturar[0],
      sucursal: !isLoadingSucursales ? sucursales[0] : null,
    },
    validationSchema: ProrrateoSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { values, touched, errors, handleSubmit, isSubmitting, setFieldValue } =
    formik;

  const tareasParaFacturar = useCallback(() => {
    if (values.tipoFactura.id === "ESPECIAL") {
      setFieldValue("sucursal", null);
      obtenerTareasParaFacturar(data.refCliente);
    } else {
      setFieldValue("tarea", null);
    }
  }, [
    data?.refCliente,
    obtenerTareasParaFacturar,
    setFieldValue,
    values.tipoFactura.id,
  ]);

  useEffect(() => {
    tareasParaFacturar();
  }, [tareasParaFacturar]);

  const handleTipoTareaSelection = useCallback(
    (event, newValue) => {
      setFieldValue("tipoFactura", newValue);
    },
    [setFieldValue]
  );

  const handleOptionLabelTipoTarea = useCallback((option) => option.label, []);

  const handleIsOptionEqualToValueTipoTarea = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  const handleTareaSelection = useCallback(
    (event, newValue) => {
      setFieldValue("tarea", newValue);
    },
    [setFieldValue]
  );

  const handleOptionLabelTarea = useCallback((option) => option.label, []);

  const handleIsOptionEqualToValueTarea = useCallback(
    (option, value) => option.idTarea === value.idTarea,
    []
  );

  const handleSucursalSelection = useCallback(
    (event, newValue) => {
      setFieldValue("sucursal", newValue);
    },
    [setFieldValue]
  );

  const handleOptionLabelSucursal = useCallback((option) => option.nombre, []);

  const handleIsOptionEqualToValueSucursal = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  const guardarDatos = () => {
    const { id: idFactura, estadoFactura, folioRef, folio } = data;

    const { id: tipoTarea } = values.tipoFactura;

    let body = {
      idFactura: idFactura,
      tipoTarea: tipoTarea,
    };

    if (estadoFactura === "ANULADO") {
      if (folioRef) {
        body = { ...body, estadoFactura: estadoFactura, folioRef: folioRef };
      } else {
        body = { ...body, estadoFactura: estadoFactura, folio: folio };
      }
    }

    if (tipoTarea === "ESPECIAL") {
      const { idTarea } = values.tarea;
      body = {
        ...body,
        refTarea: idTarea,
      };
    } else {
      const { nombre: sucursal } = values.sucursal;
      body = {
        ...body,
        sucursal: sucursal,
      };
    }

    setCargando(true);
    enlazarFacturaASucursal(body)
      .then((response) => {
        if (response?.data) {
          AlertaToast({
            element: "Enlace",
            action: "añadido correctamente",
          }).fire();
          handleClose();
        } else {
          AlertaToast({
            action: "Ocurrió un error al enlazar la factura",
            fullText: true,
            type: "error",
          }).fire();
          console.error(
            "Error al enlazar facturas (FormEnlaceSucursal) Lin. 166"
          );
        }
      })
      .catch((error) => {
        console.error(
          "Error al enlazar facturas (FormEnlaceSucursal): ",
          error
        );
        AlertaToast({
          action: "Ocurrió un error al enlazar la factura",
          fullText: true,
          type: "error",
        }).fire();
      })
      .finally(() => {
        setIndiceCargandoEnlaceSucursal(false);
        setCargando(false);
        handleClose();
      });
  };

  const handleClose = () => {
    setOpen({
      ...statesModal,
      abrirModal: false,
    });
    setIndiceCargandoEnlaceSucursal(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={1} marginBottom={1}>
          <AutoCompleteField
            disableClearable={true}
            id="autocomplete-tipo-tarea"
            label="Factura pertenece a"
            value={values.tipoFactura}
            xs={12}
            handleSucursalSelection={handleTipoTareaSelection}
            options={TIPOS_TAREA}
            touched={touched.tipoFactura}
            error={errors.tipoFactura}
            getOptionLabel={handleOptionLabelTipoTarea}
            isOptionEqualToValue={handleIsOptionEqualToValueTipoTarea}
            typeRenderInput="normal"
          />
          {values.tipoFactura.id === "ESPECIAL" && (
            <AutoCompleteField
              disableClearable={true}
              id="autocomplete-tarea"
              label="Seleccione tarea"
              value={values.tarea}
              xs={12}
              handleSucursalSelection={handleTareaSelection}
              options={datosTareasParaFacturar}
              touched={touched.tarea}
              error={errors.tarea}
              getOptionLabel={handleOptionLabelTarea}
              isOptionEqualToValue={handleIsOptionEqualToValueTarea}
              typeRenderInput="loading"
              isLoading={obteniendoDatosTareas}
              disabled={obteniendoDatosTareas}
            />
          )}
          {values.tipoFactura.id === "PLAN" && (
            <AutoCompleteField
              disableClearable={true}
              id="autocomplete-sucursal"
              label="Seleccione sucursal"
              value={values.sucursal}
              xs={12}
              handleSucursalSelection={handleSucursalSelection}
              options={!isLoadingSucursales ? sucursales : []}
              touched={touched.sucursal}
              error={errors.sucursal}
              getOptionLabel={handleOptionLabelSucursal}
              isOptionEqualToValue={handleIsOptionEqualToValueSucursal}
              typeRenderInput="loading"
              isLoading={isLoadingSucursales}
              disabled={isLoadingSucursales}
            />
          )}
        </Grid>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cancelar
          </LoadingButton>
          <LoadingButton
            type="submit"
            disabled={isSubmitting || !formik.isValid}
            startIcon={<Icon icon={saveFill} />}
            color="primary"
            loading={cargando}
          >
            Enlazar
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

const TIPOS_TAREA = [
  { id: "PLAN", label: "Plan" },
  { id: "ESPECIAL", label: "Tarea especial" },
];

export default FormEnlaceSucursal;
