import { useEffect, useState } from "react";
import TareasKanban from "./TareasKanban";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "src/theme/kanbanStyles";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";

const ESTADOS_TAREAS = {
  pendiente: "PENDIENTE",
  enProceso: "EN PROCESO",
  terminada: "TERMINADA",
  canceladoRechazado: "CANCELADO O RECHAZADO",
  facturado: "FACTURADO",
  pendienteFactura: "PENDIENTE DE FACTURACION",
  observada: "OBSERVADA SII",
};

const ESTADOS_A_MOSTRAR = [
  ESTADOS_TAREAS["pendiente"],
  ESTADOS_TAREAS["enProceso"],
  ESTADOS_TAREAS["observada"],
  ESTADOS_TAREAS["terminada"],
];

// Función para ordenar tareas por fecha de inicio o fecha de término
const ordenarTareasPorFecha = (tareas, llave) => {
  return tareas.sort((a, b) => {
    if (a[llave] && b[llave]) {
      return new Date(a[llave]) - new Date(b[llave]);
    }
    return 0;
  });
};

export default function TableroKanban(props) {
  const { value } = props;
  const classes = useStyles();

  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const [tareasPorEstado, setTareasPorEstado] = useState([]);
  const [tareasEnEspera, setTareasEnEspera] = useState([]);
  const [tareasTerminadas, setTareasTerminadas] = useState([]);

  const [getTablero, { data: tableroData, isSuccess: isSuccessTableroData }] =
    tareasApi.useLazyGetTableroQuery();

  useEffect(() => {
    const request = {
      refEmpleado: user.rut,
      sucursal: sucursalObtenida.refSucursal,
    };
    getTablero(request);
  }, [getTablero, sucursalObtenida.refSucursal, user.rut]);

  useEffect(() => {
    if (isSuccessTableroData) {
      const tareasPorEstado = tableroData.reduce((acc, tarea) => {
        const { estado } = tarea;

        if (!acc[estado]) {
          acc[estado] = [];
        }

        acc[estado].push(tarea);

        if (estado === ESTADOS_TAREAS["pendiente"]) {
          acc[estado] = ordenarTareasPorFecha(acc[estado], "fechaInicio");
        }
        return acc;
      }, {});

      setTareasPorEstado(tareasPorEstado);
    }
  }, [isSuccessTableroData, tableroData]);

  useEffect(() => {
    let tareasTerminadas = [
      ...(tareasPorEstado[ESTADOS_TAREAS["terminada"]] || []),
      ...(tareasPorEstado[ESTADOS_TAREAS["canceladoRechazado"]] || []),
      ...(tareasPorEstado[ESTADOS_TAREAS["facturado"]] || []),
    ];

    tareasTerminadas = ordenarTareasPorFecha(
      tareasTerminadas,
      "fechaTermino"
    ).slice(0, 10);

    setTareasTerminadas(tareasTerminadas);

    let tareasEnEspera = [
      ...(tareasPorEstado[ESTADOS_TAREAS["observada"]] || []),
      ...(tareasPorEstado[ESTADOS_TAREAS["pendienteFactura"]] || []),
    ];

    tareasEnEspera = ordenarTareasPorFecha(tareasEnEspera, "fechaInicio");

    setTareasEnEspera(tareasEnEspera);
  }, [tareasPorEstado]);

  if (value !== 3) return null;

  return (
    <Grid className={classes.container} container>
      {ESTADOS_A_MOSTRAR.map((estado) => {
        if (estado === ESTADOS_TAREAS["terminada"]) {
          // Almaceno los estados terminado, cancelado o rechazado y facturado dentro de la columna "Terminado"
          return (
            <Grid item key={estado}>
              <Paper className={classes.kanbanColumn}>
                <div className={classes.columnHeader}>
                  <Typography>
                    {estado.charAt(0).toUpperCase() +
                      estado.slice(1).toLowerCase()}
                  </Typography>
                </div>
                <TareasKanban tareas={tareasTerminadas || []} />
              </Paper>
            </Grid>
          );
        }
        if (estado === ESTADOS_TAREAS["observada"]) {
          return (
            <Grid item key={estado}>
              <Paper className={classes.kanbanColumn}>
                <div className={classes.columnHeader}>
                  <Typography>En espera</Typography>
                </div>
                <TareasKanban tareas={tareasEnEspera || []} />
              </Paper>
            </Grid>
          );
        }
        // Creo con los estados restantes las demás columnas
        return (
          <Grid item key={estado}>
            <Paper className={classes.kanbanColumn}>
              <div className={classes.columnHeader}>
                <Typography>
                  {estado.charAt(0).toUpperCase() +
                    estado.slice(1).toLowerCase()}
                </Typography>
              </div>
              <TareasKanban tareas={tareasPorEstado[estado] || []} />
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
