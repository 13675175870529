import React, { useEffect, useRef, useState } from "react";
import {
  API_KEY_OPEN_FACTURA_DEV,
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_DEV,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
  URL_ORIGEN,
} from "../../utils/urlBack_End";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { LoadingButton } from "@material-ui/lab";
import DownloadIcon from "@material-ui/icons/Download";
import * as locales from "@material-ui/core/locale";
import { saveAs } from "file-saver";
// material
import {
  Button,
  Card,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import Label from "../Label";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";
import PagosFacturas from "./PagosFacturas";

const TABLE_HEAD = [
  { id: "nombreCliente", label: "Cliente", alignRight: false },
  { id: "folio", label: "Folio (*)", alignRight: false },
  { id: "estadoFactura", label: "Estado Facturación (*)", alignRight: false },
  { id: "fecha", label: "Fecha", alignRight: false },
  { id: "fechaFacturacion", label: "Fecha Facturación", alignRight: false },
  { id: "fechaCorreo", label: "Fecha Envio Correo", alignRight: false },
  { id: "estadoCorreo", label: "Estado Correo (*)", alignRight: false },
  {
    id: "empleadoEnviaCorreo",
    label: "Empleado Envia Correo",
    alignRight: false,
  },
  { id: "valorPesos", label: "Valor Pesos", alignRight: false },

  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => {
      return (
        _user.nombreCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.folio.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estadoFactura.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaFacturacion.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.fechaCorreo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.estadoCorreo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.empleadoEnviaCorreo.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        String(_user.valorPesos).toLowerCase().indexOf(query.toLowerCase()) !==
          -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const styles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginTop: 20,
  },
}));

export default function FacturaPlanesEmpleado(props) {
  const { valueFactura, empleado, setShowErrorMessage, setMessage } = props;
  const [locale] = useState("esES");
  const classes = styles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombreTarea");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [facturas, setFacturas] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingDescargar, setLoadingDescargar] = useState(false);
  const [openPagos, setOpenPagos] = useState(false);
  const [idFactura, setIdFactura] = useState("");
  const [folio, setFolio] = useState("");
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/facturas/planes/empleado/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setFacturas(response.data);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingTable(false);
        }
      });
  }, [empleado.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = facturas.map((n) => n.nombreTarea);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - facturas.length) : 0;

  const filteredUsers = applySortFilter(
    facturas,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const mostrarPagosFactura = (id, folio) => {
    setFolio(folio);
    setIdFactura(id);
    setOpenPagos(true);
  };

  const descargarFactura = (token, folio) => {
    setLoadingDescargar(true);
    if (token !== null) {
      axios
        .get(
          URL_ORIGEN.includes("http://localhost:3000")
            ? `${API_OPEN_FACTURA_DEV}/v2/dte/document/${token}/pdf`
            : `${API_OPEN_FACTURA_PROD}/v2/dte/document/${token}/pdf`,
          {
            headers: {
              apikey: URL_ORIGEN.includes("http://localhost:3000")
                ? API_KEY_OPEN_FACTURA_DEV
                : API_KEY_OPEN_FACTURA_PROD,
            },
          }
        )
        .then((response) => {
          saveAs(
            "data:application/pdf;base64," + response.data.pdf,
            folio + ".pdf"
          );
          setLoadingDescargar(false);
        })
        .catch(() => {
          setLoadingDescargar(false);
        });
    } else {
      setMessage("No hay archivos que descargar");
      setShowErrorMessage(true);
      setLoadingDescargar(false);
    }
  };

  return (
    <>
      {valueFactura === 0 && (
        <Card>
          <Typography variant="h5" component="h2" className={classes.title}>
            Facturas del Empleado
          </Typography>
          <Grid
            container
            style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <Grid item xs={12} md={3}>
              <TablaToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer>
              {!loadingTable ? (
                <Table>
                  <TablaHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={facturas.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          nombreCliente,
                          folio,
                          estadoFactura,
                          fecha,
                          fechaFacturacion,
                          fechaCorreo,
                          estadoCorreo,
                          empleadoEnviaCorreo,
                          valorPesos,
                          token,
                        } = row;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox">
                            <TableCell align="left">
                              <Typography variant="subtitle2">
                                {nombreCliente}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Button color="primary" disabled={true}>
                                {" "}
                                {folio ? folio : 0}
                              </Button>
                            </TableCell>
                            <TableCell align="left">
                              <Button disabled={true}>
                                <Label
                                  variant="ghost"
                                  color={
                                    estadoFactura === "FACTURADO"
                                      ? "success"
                                      : estadoFactura === "PAGADO"
                                      ? "info"
                                      : "error"
                                  }>
                                  {sentenceCase(estadoFactura)}
                                </Label>
                              </Button>
                            </TableCell>
                            <TableCell align="left">{fecha}</TableCell>
                            <TableCell align="left">
                              {fechaFacturacion}
                            </TableCell>
                            <TableCell align="left">{fechaCorreo}</TableCell>
                            <TableCell align="left">
                              <Button disabled={true}>
                                {" "}
                                <Label
                                  variant="ghost"
                                  color={
                                    (estadoCorreo === "ENVIADO" && "success") ||
                                    "error"
                                  }>
                                  {sentenceCase(estadoCorreo)}
                                </Label>
                              </Button>
                            </TableCell>
                            <TableCell align="left">
                              {empleadoEnviaCorreo}
                            </TableCell>
                            <TableCell align="left">
                              {"$" + formatNumber(valorPesos)}
                            </TableCell>
                            <TableCell align="center">
                              <LoadingButton
                                color="primary"
                                loading={loadingDescargar}
                                onClick={() => descargarFactura(token, folio)}>
                                <DownloadIcon />
                              </LoadingButton>
                              <Button
                                onClick={() => mostrarPagosFactura(id, folio)}>
                                Ver Pagos
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound
                            searchFilter={true}
                            searchQuery={filterName}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>

          <ThemeProvider
            theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={facturas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        </Card>
      )}
      {openPagos && valueFactura === 0 && (
        <PagosFacturas
          idFactura={idFactura}
          openPagos={openPagos}
          setOpenPagos={setOpenPagos}
          tipoFactura="Planes"
          folio={folio}
        />
      )}
    </>
  );
}

/*

*/
