import React, { useEffect, useRef, useState } from "react";
import { API_SERVER } from "../../utils/urlBack_End";
import { filter } from "lodash";
import { sentenceCase } from "change-case";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
// material
import {
  Box,
  Card,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import Label from "../Label";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";
import ModalChangeState from "../facturas/ModalChangeState";
import { actualizarEstadoPagoPlan, crearAbonoPagoPlan } from "./consultasPago";
import ModalForm from "../facturas/ModalForm";
import { Alerta } from "../Alerta";
import { DateRangePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { es } from "date-fns/locale";
import ExportToExcel from "../ExportToExcel";
import { useSelector } from "react-redux";

const TABLE_HEAD = [
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "folio", label: "Folio", alignRight: false },
  { id: "valorPesos", label: "Total ($)", alignRight: false },
  { id: "fecha", label: "Fecha", alignRight: false },
  { id: "fechaCreacion", label: "Fecha Creacion", alignRight: false },

  { id: "metodoPago", label: "Metodo de Pago", alignRight: false },
  { id: "deudaTotal", label: "Deuda Total", alignRight: false },
  { id: "fechaPago", label: "Fecha Pago", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
  {
    id: "empleadoRecibePago",
    label: "Empleado Recibe Pago",
    alignRight: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filters) {
  const filterDate = array.filter((factura) => {
    const startDate = new Date(filters[0].getFullYear(), filters[0].getMonth());
    const endDate = new Date(filters[1].getFullYear(), filters[1].getMonth());

    const dateUser = new Date(factura.fecha);
    return dateUser >= startDate && dateUser <= endDate;
  });
  const stabilizedThis = filterDate.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        String(_user.valorPesos).toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fecha.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaPago.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.metodoPago.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.deudaTotal.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaCreacion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.empleadoRecibePago.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.nombreCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PagosPlanes() {
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;
  const [locale] = useState("esES");

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [facturas, setFacturas] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //Terminar Pagos
  const newDate = new Date();
  const [filterDate, setFilterDate] = useState([
    new Date(newDate.getFullYear(), newDate.getMonth()),
    newDate,
  ]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/facturas/planes/sucursal/${sucursal}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();

        if (isMounted.current) {
          setFacturas(response.data);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingTable(false);
        }
      });
  }, [sucursal]);

  const recargarPagos = () => {
    axios
      .get(`${API_SERVER}/facturas/planes/sucursal/${sucursal}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setFacturas(response.data);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingTable(false);
        }
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = facturas.map((n) => n.nombre);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function formatNumber(n) {
    n = String(n).replace(/\D/g, "");
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - facturas.length) : 0;

  const filteredUsers = applySortFilter(
    facturas,
    getComparator(order, orderBy),
    filterName,
    filterDate
  );

  const isUserNotFound = filteredUsers.length === 0;

  // .---------------- Alerta ------------------------
  const [showAlerta, setShowAlerta] = useState(false);
  const [alerta, setAlerta] = useState({
    mensaje: "error inesperado",
    color: "error",
  });

  const mostrarAlerta = (mensaje, error = "error") => {
    setAlerta({ mensaje: mensaje, color: error });
    setShowAlerta(true);
  };

  //---------------- Pagos -----------------------

  const [selectedInvoice, setSelectedInvoice] = useState();
  const [statePay, setStatePay] = useState("PAGADO");
  const [showModalStatePay, setShowModalStatePay] = useState(false);
  const [showModalPaymentMethodPay, setShowModalPaymentMethodPay] =
    useState(false);

  const handleChangeStatePay = (state) => {
    if (state === "PAGADO") {
      setShowModalPaymentMethodPay(true);
      setShowModalStatePay(false);
      setStatePay("PAGADO");
    } else {
      saveStatePay({
        metodoPago: "",
        empleadoRecibePago: user.nombre + " " + user.apellidos,
        id: selectedInvoice.id,
        estado: state,
      });
    }
  };

  const handleChangePaymentMethodPay = (state) => {
    saveStatePay({
      metodoPago: state,
      empleadoRecibePago: user.nombre + " " + user.apellidos,
      id: selectedInvoice.id,
      estado: statePay,
    });
  };

  const saveStatePay = async (datos) => {
    let error = false;
    Promise.resolve((error = await actualizarEstadoPagoPlan(datos))).then(
      () => {
        if (error === true) {
          setShowModalPaymentMethodPay(false);
          setShowModalStatePay(false);

          recargarPagos();
          mostrarAlerta("Estado del pago actualizado con éxito.", "success");
        } else {
          mostrarAlerta("Error al cambiar estado del pago.");
        }
      }
    );
  };

  //-------- Abonar -------------------------

  const [showAbonar, setShowAbonar] = useState(false);
  const [abono, setAbono] = useState(0);

  const [showModalPaymentMethodAbono, setShowModalPaymentMethodAbono] =
    useState(false);

  const handleChangeAbono = (number) => {
    setAbono(Number(number));
  };

  const handleChangePaymentMethodAbono = async (state) => {
    let error = false;
    Promise.resolve(
      (error = await crearAbonoPagoPlan({
        metodoPago: state,
        empleadoRecibeAbono: user.nombre + " " + user.apellidos,
        refIdPago: selectedInvoice.id,
        valorAbono: abono,
      }))
    ).then(() => {
      if (error === true) {
        setShowModalPaymentMethodAbono(false);
        setShowAbonar(false);
        setAbono(0);
        recargarPagos();
        mostrarAlerta("Abono realizado con éxito.", "success");
      } else {
        mostrarAlerta("Error al ingresar abono.");
      }
    });
  };

  const submmitAbonar = () => {
    if (Number(abono) > 0) setShowModalPaymentMethodAbono(true);
  };

  return (
    <>
      <Card>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <Grid
            container
            style={{ padding: "1rem 1.5rem 0rem 1.5rem" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <Grid item xs={12} md={4}>
              <TablaToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </Grid>
            <Grid
              item
              style={{ padding: "2.5rem 0rem 0rem 2.5rem" }}
              md={5}
              xs={12}>
              <DateRangePicker
                calendars={1}
                value={filterDate}
                onChange={(newValue) => {
                  let fechaInicio = new Date(newValue[0]);
                  let fechaFin =
                    newValue[1] === null
                      ? new Date(newValue[0])
                      : new Date(newValue[1]);
                  setFilterDate([fechaInicio, fechaFin]);
                }}
                renderInput={(startProps, endProps) => {
                  return (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        label="Fecha Inicio"
                        helperText="dd/mm/yyyy"
                      />
                      <Box sx={{ mx: 2 }}> A </Box>
                      <TextField
                        {...endProps}
                        label="Fecha Fin"
                        helperText="dd/mm/yyyy"
                      />
                    </React.Fragment>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Container>
                <ExportToExcel
                  data={facturas}
                  filename="Pago Planes"
                  sheetName="Pago Planes"
                  type="objects"
                />
              </Container>
            </Grid>
          </Grid>
        </LocalizationProvider>
        <Scrollbar>
          <TableContainer>
            {!loadingTable ? (
              <Table>
                <TablaHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={facturas.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        valorPesos,
                        estado,
                        fecha,
                        fechaPago,
                        metodoPago,
                        deudaTotal,
                        fechaCreacion,
                        empleadoRecibePago,
                        nombreCliente,
                        folio,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(nombreCliente) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}>
                          <TableCell align="left">
                            <Typography variant="subtitle2">
                              {nombreCliente}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{folio}</TableCell>
                          <TableCell align="left">
                            {formatNumber(valorPesos)}
                          </TableCell>
                          <TableCell align="left">{fecha}</TableCell>
                          <TableCell align="left">{fechaCreacion}</TableCell>

                          <TableCell align="left">{metodoPago}</TableCell>
                          <TableCell align="left">
                            {formatNumber(deudaTotal)}
                          </TableCell>

                          <TableCell align="left">{fechaPago}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (estado === "PAGADO" && "success") ||
                                (estado === "NO APLICA" && "warning") ||
                                "error"
                              }>
                              {sentenceCase(estado)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {empleadoRecibePago}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </TableContainer>
        </Scrollbar>

        <ThemeProvider
          theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={facturas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Card>
      {selectedInvoice && (
        <ModalChangeState
          object={selectedInvoice}
          onSubmit={handleChangeStatePay}
          key="estadoPago"
          title="Cambiar estado pago"
          state="estadoPago"
          onClose={() => setShowModalStatePay(false)}
          states={["PENDIENTE", "PAGADO", "NO APLICA"]}
          disabled={optionsSelectedPay[selectedInvoice.estado]}
          open={showModalStatePay}
        />
      )}

      {selectedInvoice && (
        <ModalChangeState
          object={{ paymentMethodPay: "EFECTIVO" }}
          onSubmit={handleChangePaymentMethodPay}
          key="paymentMethodPay"
          title="Seleccionar medio de pago"
          state="paymentMethodPay"
          onClose={() => setShowModalPaymentMethodPay(false)}
          states={["EFECTIVO", "WEBPAY", "TRANSFERENCIA"]}
          open={showModalPaymentMethodPay}
        />
      )}

      {selectedInvoice && (
        <ModalForm
          open={showAbonar}
          setOpen={setShowAbonar}
          value={abono}
          title="Ingresa el abono"
          content=""
          label="Abono"
          type="number"
          error={abono <= 0}
          helperText="El abono debe ser mayor a 0"
          onSubmit={submmitAbonar}
          setUpdate={handleChangeAbono}
        />
      )}

      {selectedInvoice && (
        <ModalChangeState
          object={{ paymentMethodAbono: "EFECTIVO" }}
          onSubmit={handleChangePaymentMethodAbono}
          key="paymentMethodAbono"
          title="Seleccionar medio de pago"
          state="paymentMethodAbono"
          onClose={() => setShowModalPaymentMethodAbono(false)}
          states={["EFECTIVO", "WEBPAY", "TRANSFERENCIA"]}
          open={showModalPaymentMethodAbono}
        />
      )}

      <Alerta
        showAlert={showAlerta}
        setShowAlert={setShowAlerta}
        message={alerta.mensaje}
        color={alerta.color}
      />
    </>
  );
}

const optionsSelectedPay = {
  PENDIENTE: [true, false, false, false, false],
  PAGADO: [true, true, true, false],
  "NO APLICA": [false, true, true, true, true],
  ANULADO: [true, true, true, true, true],
  DEUDA: [true, false, true, true, true],
};
