import React from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Card, Typography, Stack, Button } from "@material-ui/core";

export default function VerPlanDatosCliente(props) {
  const { plan } = props;

  const navigate = useNavigate();

  return (
    <>
      <Card>
        <Typography variant="h5" style={{ textAlign: "center" }} gutterBottom>
          Datos Cliente
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px", marginTop: "30px" }}
        >
          <TextField
            margin="dense"
            label="Rut Cliente"
            value={plan ? plan.cliente.rut : ''}
            type="text"
            fullWidth
            disabled
          />

          <TextField
            margin="dense"
            label="Nombre Cliente"
            value={plan ? plan.cliente.nombre : ''}
            type="text"
            fullWidth
            disabled
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px", marginTop: "30px" }}
        >
          <TextField
            margin="dense"
            label="Telefono"
            value={plan ? plan.cliente.telefono : ''}
            type="text"
            fullWidth
            disabled
          />

          <TextField
            margin="dense"
            label="Correo"
            value={plan ? plan.cliente.correo : ''}
            type="email"
            fullWidth
            disabled
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px", marginTop: "30px" }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                navigate("/dashboard/clientes/" + plan.refCliente);
              }}
            >
              Más detalles...
            </Button>
          </div>
        </Stack>
      </Card>
    </>
  );
}
