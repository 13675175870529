import React, { useState, useEffect } from "react";
import TablaPermisos from "./TablaPermisos";
import { API_SERVER, dbx } from "../../../utils/urlBack_End";
import axios from "axios";
import { Skeleton } from "@material-ui/core";
import {
  comingSoonStyles,
  ComingSoonWrapper,
} from "src/components/coming-soon-screen/coming-soon-wrapper";

export default function Permisos(props) {
  const {
    valueLaboral,
    empleado,
    setShowMessagePermiso,
    setColor,
    setMessage,
    vista,
  } = props;

  const [permisos, setPermisos] = useState([]);
  const [permisosSolicitadas, setPermisosSolicitadas] = useState(0);
  const [cargandoDatos, setCargandoDatos] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/permisos/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        const resultado = response.data;
        setPermisos(resultado.permisos);
        setPermisosSolicitadas(resultado.permisosSolicitadas);
        setCargandoDatos(true);
      });
  }, [empleado.rut]);

  useEffect(() => {
    if (empleado.rut !== undefined) {
      dbx
        .filesListFolder({ path: `/Empleados/${empleado.rut}/Permisos` })
        .then(() => {})
        .catch(() => {
          dbx
            .filesCreateFolderV2({
              path: `/Empleados/${empleado.rut}/Permisos`,
            })
            .then((response) => {})
            .catch((error) => {});
        })
        .finally(() => {});
    }
  }, [empleado.rut]);

  return (
    <>
      {valueLaboral === 3 && (
        <ComingSoonWrapper style={comingSoonStyles.CARD}>
          {cargandoDatos ? (
            <TablaPermisos
              permisos={permisos}
              setPermisos={setPermisos}
              permisosSolicitadas={permisosSolicitadas}
              setPermisosSolicitadas={setPermisosSolicitadas}
              empleado={empleado}
              setShowMessagePermiso={setShowMessagePermiso}
              setColor={setColor}
              setMessage={setMessage}
              vista={vista}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height="426px" />
          )}
        </ComingSoonWrapper>
      )}
    </>
  );
}
