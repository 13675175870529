import {
  alpha,
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Iconify from "../iconify";
import closeFill from "@iconify/icons-eva/close-fill";
import SingleFilePreview from "./preview/singleFilePreview";
import RejectionFiles from "./errors/rejectionFiles";
import MultiFilePreview from "./preview/multiFilePreview";
import UploadIllustration from "src/assets/illustrations/uploadIllustration";
import { LoadingButton } from "@material-ui/lab";

// ----------------------------------------------------------------------

const StyledDropZone = styled("div")(({ theme }) => ({
  outline: "none",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  "&:hover": {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

Upload.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  files: PropTypes.array,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  thumbnail: PropTypes.bool,
  helperText: PropTypes.node,
  fileStatuses: PropTypes.object,
  onRemoveAll: PropTypes.func,
  loading: PropTypes.bool,
  loadingUpload: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
};

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  fileStatuses,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  //
  onUpload,
  onRemove,
  onDownload,
  onRemoveAll,
  sx,
  //
  loading,
  loadingUpload,
  //
  maxFiles = 10,
  maxSize = null,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple,
    disabled,
    maxFiles,
    maxSize,
    ...other,
  });

  if (loading) {
    return (
      <Box sx={{ width: 1, position: "relative" }}>
        <StyledDropZone>
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ height: 200 }}
          >
            <Skeleton variant="circular" width={80} height={80} />
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </Stack>
        </StyledDropZone>
      </Box>
    );
  }

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, position: "relative", ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            color: "error.main",
            bgcolor: "error.lighter",
            borderColor: "error.light",
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: "none",
          }),
          ...(hasFile && {
            padding: "12% 0",
          }),
        }}
      >
        <input {...getInputProps()} />

        <Placeholder
          helperText={helperText}
          sx={{
            ...(hasFile && {
              opacity: 0,
            }),
          }}
        />

        {hasFile && <SingleFilePreview file={file} />}
      </StyledDropZone>

      {/* {helperText && helperText} */}

      <RejectionFiles
        fileRejections={fileRejections}
        maxFiles={maxFiles}
        maxSize={maxSize}
      />

      {hasFile && onDelete && !disabled && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: "absolute",
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon={closeFill} width={18} />
        </IconButton>
      )}

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview
              files={files}
              thumbnail={thumbnail}
              onRemove={onRemove}
              onDownload={onDownload}
              fileStatuses={fileStatuses}
            />
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={onRemoveAll}
              >
                Eliminar todos
              </Button>
            )}

            {onUpload && (
              <LoadingButton
                size="small"
                variant="contained"
                onClick={onUpload}
                loading={loadingUpload}
              >
                Subir archivos
              </LoadingButton>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

Placeholder.propTypes = {
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

function Placeholder({ helperText, sx, ...other }) {
  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: "center",
          md: "left",
        },
        ...sx,
      }}
      {...other}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <div>
        <Typography gutterBottom variant="h5">
          Arrastra o selecciona un archivo
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Arrastra los archivos aquí o haz
          <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 0.5,
              color: "primary.main",
              textDecoration: "underline",
            }}
          >
            clic
          </Typography>
          para buscar en tu equipo
        </Typography>
        {helperText && (
          <Typography
            variant="caption"
            display="block"
            sx={{ color: "text.secondary" }}
          >
            {helperText}
          </Typography>
        )}
      </div>
    </Stack>
  );
}
