import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const informesApi = createApi({
  reducerPath: "informesApi",
  baseQuery: baseQuery(`${API_SERVER}/informes`),
  tagTypes: [],
  endpoints: (builder) => ({
    getTasaNegociacionConcreta: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro }) =>
        `/procesos/tasaNegociacionConcreta/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}`,
    }),
    getCapacidadPorCartera: builder.query({
      query: ({ id }) => `/procesos/capacidadPorCartera/${id}`,
    }),
    getTasaCrecimientoCliente: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro }) =>
        `/clientes/tasaCrecimientoCliente/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}`,
    }),
    getTasaClientesPerdidos: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro }) =>
        `/clientes/tasaClientesPerdidos/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}`,
    }),
    getTasaRetencionClientes: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro }) =>
        `/clientes/tasaRetencionClientes/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}`,
    }),
    getNLeads: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro }) =>
        `/clientes/nLeads/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}`,
    }),
    getRecaudacionesMensualesSucursales: builder.query({
      query: ({ fechaInicio, fechaTermino }) =>
        `/recaudacionesMensualesSucursales/?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}`,
    }),
    obtenerRecaudacionesYFacturasTotales: builder.query({
      query: ({ rut, fechaTermino }) => {
        return {
          url: `/obtenerRecaudacionesYFacturasTotales/${rut}`,
          method: "GET",
          params: {
            fechaActual: fechaTermino,
          },
        };
      },
    }),
    getRecaudacionesMensualesGav: builder.query({
      query: ({ fechaFacturacion }) =>
        `/recaudacionesMensualesGav/?fechaFacturacion=${fechaFacturacion}`,
    }),
    getInformeLiquidacion: builder.query({
      query: ({ fechaFacturacion, sucursal }) =>
        `/generarInformeLiquidacion/?fechaFacturacion=${fechaFacturacion}&filtro=${sucursal}`,
    }),
    getInformacionFacturacionAnio: builder.query({
      query: (rut) => `/informacionFacturacionRecaudacionPendienteAnio/${rut}`,
    }),
    getInformacionEstadisticasFacturacion: builder.query({
      query: (rut) => `/informacionEstadisticasFacturacion/${rut}`,
    }),
    getInformacionfacturacionPorSucursales: builder.query({
      query: () => `/informacionfacturacionPorSucursales`,
    }),
    getInformacionCrecimientoClientes: builder.query({
      query: () => `/informacionCrecimientoClientes`,
    }),
    getInformacionRecuentoTareas: builder.query({
      query: ({ fechaActual, rut }) => {
        return {
          url: `/informacionRecuentoTareas/${rut}`,
          method: "GET",
          params: {
            fechaActual,
          },
        };
      },
    }),
    getDetalleFacturasMensuales: builder.query({
      query: ({ fechaActual, rut }) => {
        return {
          url: `/detalleFacturasMensuales/${rut}`,
          method: "GET",
          params: {
            fechaActual,
          },
        };
      },
    }),
    getDetalleComisionesTarea: builder.query({
      query: (idTarea) => {
        return {
          url: `/detalleComisionesTarea/${idTarea}`,
          method: "GET",
        };
      },
    }),
    getDetalleFacturasTarea: builder.query({
      query: (idTarea) => `/detalleFacturasTarea/${idTarea}`,
    }),
    getDetalleFacturasTareasGeneral: builder.query({
      query: (rutEmpleado) => `/detalleFacturasTareasGeneral/${rutEmpleado}`,
    }),
    getDetalleRecaudacionFacturas: builder.query({
      query: ({ tipoFactura, accion, fechaActual, rut }) => {
        return {
          url: `/detalleRecaudacionFacturas/${rut}/${tipoFactura}/${accion}`,
          method: "GET",
          params: {
            fechaActual,
          },
        };
      },
    }),
    getFacturasPorDia: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro, refCliente }) =>
        `/clientes/facturasPorDia/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}&refCliente=${refCliente}`,
    }),
    getCobrosPorMes: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro, refCliente }) =>
        `/cobrosPorMes/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}&refCliente=${refCliente}`,
    }),
    getTodosCobrosPorMes: builder.query({
      query: ({
        fechaInicio,
        fechaTermino,
        filtro,
        refCliente,
        sucursales,
      }) => {
        const sucursalesQuery = sucursales
          .map((sucursal) => `sucursales=${sucursal.refSucursal}`)
          .join("&");
        return `/todosCobrosPorMes?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}&refCliente=${refCliente}&${sucursalesQuery}`;
      },
    }),
    getCobrosPendientesPorMes: builder.query({
      query: ({ id, fechaInicio, fechaTermino, filtro, refCliente }) =>
        `/cobrosPendientesPorMes/${id}?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}&refCliente=${refCliente}`,
    }),
    getCobrosTotalesPendientesPorMes: builder.query({
      query: ({
        fechaInicio,
        fechaTermino,
        filtro,
        refCliente,
        sucursales,
      }) => {
        const sucursalesQuery = sucursales
          .map((sucursal) => `sucursales=${sucursal.refSucursal}`)
          .join("&");
        return `/cobrosTotalesPendientesPorMes?fechaInicio=${fechaInicio}&fechaTermino=${fechaTermino}&filtro=${filtro}&refCliente=${refCliente}&${sucursalesQuery}`;
      },
    }),
    getPagosPromedioClientes: builder.query({
      query() {
        return {
          url: `/clientes/promedioFacturas`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetTasaNegociacionConcretaQuery,
  useGetCapacidadPorCarteraQuery,
  useGetTasaCrecimientoClienteQuery,
  useGetTasaClientesPerdidosQuery,
  useGetTasaRetencionClientesQuery,
  useGetNLeadsQuery,
  useGetFacturasPorDiaQuery,
  useGetPagosPromedioClientesQuery,
  useGetCobrosPorMesQuery,
  useGetTodosCobrosPorMesQuery,
  useGetInformacionRecuentoTareasQuery,
  useGetCobrosTotalesPendientesPorMesQuery,
  useGetRecaudacionesMensualesSucursalesQuery,
  useGetRecaudacionesMensualesGavQuery,
  useGetInformeLiquidacionQuery,
  useGetInformacionFacturacionAnioQuery,
  useGetInformacionEstadisticasFacturacionQuery,
  useGetInformacionCrecimientoClientesQuery,
  useObtenerRecaudacionesYFacturasTotalesQuery,
  useGetInformacionfacturacionPorSucursalesQuery,
  useGetDetalleFacturasMensualesQuery,
  useGetDetalleComisionesTareaQuery,
  useGetDetalleFacturasTareaQuery,
  useGetDetalleFacturasTareasGeneralQuery,
  useGetDetalleRecaudacionFacturasQuery,
  useLazyGetDetalleRecaudacionFacturasQuery,
  useLazyGetDetalleFacturasTareasGeneralQuery,
  useLazyGetDetalleComisionesTareaQuery,
  useLazyGetDetalleFacturasTareaQuery,
  useLazyGetDetalleFacturasMensualesQuery,
  useLazyGetInformacionRecuentoTareasQuery,
  useLazyGetInformacionfacturacionPorSucursalesQuery,
  useLazyGetCobrosPorMesQuery,
  useLazyGetInformacionFacturacionAnioQuery,
  useLazyGetInformacionEstadisticasFacturacionQuery,
  useLazyGetCobrosPendientesPorMesQuery,
  useGetCobrosPendientesPorMesQuery,
  useLazyObtenerRecaudacionesYFacturasTotalesQuery,
  useLazyGetTodosCobrosPorMesQuery,
  useLazyGetInformacionCrecimientoClientesQuery,
  useLazyGetPagosPromedioClientesQuery,
  useLazyGetFacturasPorDiaQuery,
  useLazyGetNLeadsQuery,
  useLazyGetTasaRetencionClientesQuery,
  useLazyGetTasaClientesPerdidosQuery,
  useLazyGetTasaCrecimientoClienteQuery,
  useLazyGetCapacidadPorCarteraQuery,
  useLazyGetTasaNegociacionConcretaQuery,
  useLazyGetCobrosTotalesPendientesPorMesQuery,
  useLazyGetRecaudacionesMensualesSucursalesQuery,
  useLazyGetRecaudacionesMensualesGavQuery,
  useLazyGetInformeLiquidacionQuery,
} = informesApi;
