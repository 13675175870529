import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import AppDetalleTareas from "src/components/estadisticas/app-detalle-tareas";
import AppEmployeesTask from "src/components/estadisticas/app-employees-task";
import PropTypes from "prop-types";
import AppDispersionTareasEmpleado from "src/components/estadisticas/app-dispersion-tareas-empleados";
import { useCallback, useState } from "react";
import { tareasApi } from "src/services/tareasApi";
import ModalDetalleTareaPorEstado from "../modalDetalleTareaPorEstado";

const ModalEstadisticasTareas = ({
  isOpen,
  setOpen,
  tipoModal,
  sucursal,
  nombreTarea,
  dataTable,
  datosTarjetaMensual,
  datosGraficoDispersion,
  cargandoDatosGraficoDispersion,
  loadingTable,
  cargandoDatosTarjetaMensual,
  defaultDataTareas,
  iconos,
  setDatosTarjetaMensual,
  setDatosGraficoDispersion,
  setDataTable,
  filtroFecha,
}) => {
  const [tituloModal, setTituloModal] = useState("");
  const [tituloTarjetaRecuentoTareas, setTituloTarjetaRecuentoTareas] =
    useState("");
  const [subtituloTarjetaRecuentoTareas, setSubtituloTarjetaRecuentoTareas] =
    useState("");
  const [
    tituloModalDetalleTareasPorEstado,
    setTituloModalDetalleTareasPorEstado,
  ] = useState("");
  const [abrirModalDetalleTareaPorEstado, setAbrirModalDetalleTareaPorEstado] =
    useState(false);

  const [
    obtenerTareasEmpleados,
    { data: tareasEmpleado, isFetching: obteniendoTareasEmpleado },
  ] = tareasApi.useLazyGetTareasTotalesPorSucursalTipoTareaYFechaQuery();
  const [
    obtenerDispersionEmpleados,
    {
      data: dispersionTareasEmpleado,
      isFetching: obteniendoDispersionTareasEmpleado,
    },
  ] = tareasApi.useLazyGetCantidadTareasEmpleadosAgrupadasQuery();

  const setearDatosModal = useCallback(() => {
    if (tipoModal && (tipoModal === "MES" || tipoModal === "AÑO")) {
      const tipoTarea =
        tipoModal === "MES"
          ? "mensuales"
          : tipoModal === "AÑO"
          ? "anuales"
          : "internas";

      const tituloTarjeta = `Tareas ${tipoTarea}`;
      const subtituloTarjeta = `Se muestra un detalle global de las tareas ${tipoTarea} de la sucursal`;
      const tituloModal = `Detalle tareas ${tipoTarea} sucursal ${sucursal}`;

      setTituloModal(tituloModal);
      setTituloTarjetaRecuentoTareas(tituloTarjeta);
      setSubtituloTarjetaRecuentoTareas(subtituloTarjeta);
    } else if (tipoModal && tipoModal === "DETALLETAREA" && nombreTarea) {
      const tituloModal = `Detalle tarea: ${nombreTarea} - Sucursal ${sucursal}`;
      setTituloModal(tituloModal);
    }
  }, [nombreTarea, sucursal, tipoModal]);

  useState(() => {
    if (isOpen) {
      setearDatosModal();
    }
  }, []);

  const manejarClickDetalleTareas = useCallback(
    (estadoTareaAux) => {
      let titulo = "";

      // Si es de tipo detalle tareas, es porque se ve el detalle de las tareas pero no nombre tarea y no solamente por tipo de tarea
      if (tipoModal.toLowerCase() === "detalletarea") {
        titulo = `Detalle tarea: ${nombreTarea} ${estadoTareaAux.toLowerCase()} - sucursal ${sucursal}`;
      } else if (
        tipoModal.toLowerCase() === "año" ||
        tipoModal.toLowerCase() === "mes"
      ) {
        const frecuencia =
          tipoModal.toLowerCase() === "año" ? "anuales" : "mensuales";
        titulo = `Detalle tareas ${frecuencia} ${estadoTareaAux.toLowerCase()} - sucursal ${sucursal}`;
      } else {
        titulo = "Información tareas";
      }

      let estadoTarea = "";
      if (estadoTareaAux.toLowerCase() === "pendientes") {
        estadoTarea = "PENDIENTE";
      } else if (estadoTareaAux.toLowerCase() === "canceladas o rechazadas") {
        estadoTarea = "CANCELADO O RECHAZADO";
      } else if (estadoTareaAux.toLowerCase() === "terminadas") {
        estadoTarea = ["FACTURADO", "TERMINADA"];
      } else if (estadoTareaAux.toLowerCase() === "observadas sii") {
        estadoTarea = "OBSERVADA SII";
      } else {
        estadoTarea = estadoTareaAux.toUpperCase();
      }

      // Crear el objeto `body` con los datos necesarios
      const body = {
        sucursal,
        fecha: filtroFecha,
        estadoTarea,
        tipoTarea: tipoModal,
        nombreTarea: nombreTarea.toUpperCase(), // Este campo se muestra solamente cuando el modal es de tipo detalle tarea (cuando se muestra el detalle por tarea y no por tipo tarea)
      };

      // Llamadas a las funciones de obtención de datos
      obtenerTareasEmpleados(body);
      obtenerDispersionEmpleados(body);

      // Actualizar el estado del componente
      setTituloModalDetalleTareasPorEstado(titulo);
      setAbrirModalDetalleTareaPorEstado(true);
    },
    [
      filtroFecha,
      nombreTarea,
      obtenerDispersionEmpleados,
      obtenerTareasEmpleados,
      sucursal,
      tipoModal,
    ]
  );

  const handleClose = () => {
    setOpen(false);
    resetStates();
  };

  const resetStates = () => {
    setDatosTarjetaMensual({});
    setDatosGraficoDispersion([]);
    setDataTable([]);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-estadisticas-tareas"
        id="dialog-estadisticas-tareas"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle style={styles.title} htmlFor="dialog-estadisticas-tareas">
          {tituloModal}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <AppDetalleTareas
                loading={cargandoDatosTarjetaMensual}
                total={
                  !cargandoDatosTarjetaMensual && datosTarjetaMensual
                    ? datosTarjetaMensual.totalTareas
                    : 0
                }
                title={tituloTarjetaRecuentoTareas}
                subheader={subtituloTarjetaRecuentoTareas}
                chart={{
                  series:
                    !cargandoDatosTarjetaMensual && datosTarjetaMensual
                      ? datosTarjetaMensual.porcentajeTerminadas
                      : 0,
                }}
                data={
                  !cargandoDatosTarjetaMensual &&
                  datosTarjetaMensual &&
                  datosTarjetaMensual.distribucionTareas
                    ? datosTarjetaMensual.distribucionTareas.map((tarea) => ({
                        ...tarea,
                        icon: iconos[tarea.name],
                      }))
                    : defaultDataTareas
                }
                manejarClickStack={manejarClickDetalleTareas}
                cursor="pointer"
              />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <AppEmployeesTask
                loading={loadingTable}
                rows={!loadingTable && dataTable ? dataTable : []}
                title="Tareas empleados"
                subheader="Se muestran las tareas agrupadas por empleado"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AppDispersionTareasEmpleado
                title="Dispersión tareas por empleado"
                subheader="Se muestra la distribución de tareas por empleado"
                chartData={
                  !cargandoDatosGraficoDispersion && datosGraficoDispersion
                    ? datosGraficoDispersion
                    : []
                }
                loadingChartData={cargandoDatosGraficoDispersion}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {abrirModalDetalleTareaPorEstado && (
        <ModalDetalleTareaPorEstado
          isOpen={abrirModalDetalleTareaPorEstado}
          setOpen={setAbrirModalDetalleTareaPorEstado}
          titulo={tituloModalDetalleTareasPorEstado}
          dataTable={tareasEmpleado}
          datosGraficoDispersion={dispersionTareasEmpleado}
          cargandoDatosGraficoDispersion={obteniendoDispersionTareasEmpleado}
          loadingDataTable={obteniendoTareasEmpleado}
        />
      )}
    </>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalEstadisticasTareas.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  tipoModal: PropTypes.string,
  sucursal: PropTypes.string,
  nombreTarea: PropTypes.string,
  dataTable: PropTypes.array,
  datosGraficoDispersion: PropTypes.array,
  cargandoDatosGraficoDispersion: PropTypes.bool,
  loadingTable: PropTypes.bool,
  datosTarjetaMensual: PropTypes.object,
  cargandoDatosTarjetaMensual: PropTypes.bool,
  defaultDataTareas: PropTypes.array,
  iconos: PropTypes.object,
  setDatosTarjetaMensual: PropTypes.func,
  setDatosGraficoDispersion: PropTypes.func,
  setDataTable: PropTypes.func,
  filtroFecha: PropTypes.string,
};

export default ModalEstadisticasTareas;
