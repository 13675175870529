import {
  Box,
  Container,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { memo, useMemo } from "react";
import { formatAsCLP } from "src/utils/formatNumber";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";

const GlosaConDescripcion = memo(({ glosa, descripcion }) => (
  <Box>
    <Typography>{glosa}</Typography>
    {descripcion && (
      <Box display="flex" alignItems="center" mt={1}>
        <Menu fontSize="small" color="action" sx={{ mr: 1 }} />
        <Typography variant="caption" color="text.secondary">
          {descripcion}
        </Typography>
      </Box>
    )}
  </Box>
));

const BorradorFactura = memo(({ setOpen, data }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const precioTotal = useMemo(() => {
    return data.items.reduce(
      (acc, curr) => Number(acc) + Number(curr.total),
      0
    );
  }, [data.items]);

  return (
    <>
      <Container>
        <Box
          textAlign="center"
          sx={{
            p: 2,
            borderTop: "1px solid #dee2e6",
            borderBottom: "1px solid #dee2e6",
          }}
          py={3}
          mb={5}
        >
          <Typography variant="h2" fontWeight="bold">
            Factura borrador
          </Typography>
          {/* <Typography variant="body1">Factura N°: XXXX</Typography> */}
          <Typography variant="body1">
            Sucursal: {capitalizarPrimeraLetra(data.sucursal)}
          </Typography>
          <Typography variant="body1">
            Tipo factura: {capitalizarPrimeraLetra(data.tipo)}
          </Typography>
          <Typography variant="body1">
            {"Fecha emisión: " + data.fechaEmision.format("dddd DD MMMM, YYYY")}
          </Typography>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} lg={6}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary">
                  Destinatario factura
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{data.RznSocRecep}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography marginLeft={2} component="ul" variant="body1">
                  <li>
                    {data.DirRecep}, {capitalizarPrimeraLetra(data.CmnaRecep)}
                  </li>
                  <li>{data.EmailRecep}</li>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary">
                  Emisor factura
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Taxtic</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="ul" marginLeft={2} variant="body1">
                  <li>Carmen #459</li>
                  <li>clientes@taxtic.com</li>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table sx={{ my: 5 }} aria-label="invoice-table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "primary.light" }}>
              <TableCell>N°.</TableCell>
              <TableCell>Glosa</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((item, index) => (
              <TableRow
                key={index}
                sx={{ backgroundColor: index % 2 ? "action.hover" : "inherit" }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <GlosaConDescripcion
                    glosa={item.glosa}
                    descripcion={item.descripcion}
                  />
                </TableCell>
                <TableCell>
                  {item.moneda === "PESOS"
                    ? formatAsCLP(item.precio)
                    : `${item.precio}${item.moneda}`}
                </TableCell>
                <TableCell>{item.cantidad}</TableCell>
                <TableCell>{formatAsCLP(item.total)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell fontWeight="bold" color="primary">
                <Typography variant="overline">Precio total:</Typography>
              </TableCell>
              <TableCell fontWeight="bold" color="primary">
                <Typography variant="overline">
                  {formatAsCLP(precioTotal)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box textAlign="left" my={5}>
          <Typography variant="body1" color="text.secondary">
            <Typography component="span" fontWeight="bold">
              Nota:{" "}
            </Typography>
            este documento es un borrador de factura y no constituye una factura
            oficial. Se proporciona únicamente con el propósito de revisión y
            confirmación de los datos antes de la emisión de la factura final.
            Por favor, verifique cuidadosamente la información presentada y
            asegúrese de que todos los detalles sean correctos antes de proceder
            con la emisión de la factura oficial.
          </Typography>
        </Box>
      </Container>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default BorradorFactura;
