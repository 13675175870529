import { configureStore } from "@reduxjs/toolkit";
import { ajustesApi } from "src/services/ajustesApi";
import { calendarioApi } from "src/services/calendarioApi";
import { clientesApi } from "src/services/clientesApi";
import { empleadosApi } from "src/services/empleadosApi";
import { facturasApi } from "src/services/facturasApi";
import { planesApi } from "src/services/planesApi";
import { propuestasApi } from "src/services/propuestasApi";
import { subtareasApi } from "src/services/subtareasApi";
import { sucursalesApi } from "src/services/sucursalesApi";
import { tareasApi } from "src/services/tareasApi";
import { tiposApi } from "src/services/tiposApi";
import { informesApi } from "src/services/informesApi";
import authReducers from "../reducers/authReducers";
import { dteApi } from "src/services/dteApi";
import settingsReducers from "src/reducers/settingsReducers";
import { categoriasApi } from "src/services/categoriasApi";
import { prorrateosApi } from "src/services/prorrateosApi";
import { gavsApi } from "src/services/gavsApi";

const reducers = {
  auth: authReducers,
  settings: settingsReducers,
  [tareasApi.reducerPath]: tareasApi.reducer,
  [planesApi.reducerPath]: planesApi.reducer,
  [empleadosApi.reducerPath]: empleadosApi.reducer,
  [calendarioApi.reducerPath]: calendarioApi.reducer,
  [subtareasApi.reducerPath]: subtareasApi.reducer,
  [propuestasApi.reducerPath]: propuestasApi.reducer,
  [sucursalesApi.reducerPath]: sucursalesApi.reducer,
  [ajustesApi.reducerPath]: ajustesApi.reducer,
  [clientesApi.reducerPath]: clientesApi.reducer,
  [tiposApi.reducerPath]: tiposApi.reducer,
  [facturasApi.reducerPath]: facturasApi.reducer,
  [informesApi.reducerPath]: informesApi.reducer,
  [dteApi.reducerPath]: dteApi.reducer,
  [categoriasApi.reducerPath]: categoriasApi.reducer,
  [prorrateosApi.reducerPath]: prorrateosApi.reducer,
  [gavsApi.reducerPath]: gavsApi.reducer,
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      tareasApi.middleware,
      planesApi.middleware,
      empleadosApi.middleware,
      calendarioApi.middleware,
      subtareasApi.middleware,
      propuestasApi.middleware,
      sucursalesApi.middleware,
      ajustesApi.middleware,
      clientesApi.middleware,
      tiposApi.middleware,
      facturasApi.middleware,
      informesApi.middleware,
      dteApi.middleware,
      categoriasApi.middleware,
      prorrateosApi.middleware,
      gavsApi.middleware
    ),
  devTools: false,
});

export default store;
