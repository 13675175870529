import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// material
import { Container, Typography, Box, Tabs, Tab } from "@material-ui/core";
import { Titulo } from "../components/Titulo";
// components
import Page from "../components/Page";
import PagoTareas from "src/components/pagos/PagoTarea";
import PagosPlanes from "src/components/pagos/PagoPlanes";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <label>{children}</label>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Pagos() {
  const [value, setValue] = useState(0);
  const { empleado: user } = useSelector((state) => state.auth.user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual
        }
      }).unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }, [user.rut]);

  return (
    <Page title="TAXTIC">
      <Container>
        <Titulo style={{ marginBottom: "-3rem" }}>
          <Typography variant="h4" gutterBottom>
            Pagos
          </Typography>
        </Titulo>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Pagos Planes" {...a11yProps(0)} />
              <Tab label="Pagos Tareas" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PagosPlanes />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PagoTareas />
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}
