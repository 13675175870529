import React, { useCallback, useEffect, useState } from "react";
import { Container, Typography, Grid } from "@material-ui/core";

// components
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import AppWidgetsFacturacionRecaudacionPendientes from "src/components/informes/App-widget-facturacion-recaudacion-pendientes";
import AppGraficoDeBarras from "src/components/informes/App-grafico-barras";
import { informesApi } from "src/services/informesApi";
import AppFacturasSucursales from "src/components/informes/App-facturas-sucursales";
import AppCrecimientoClientes from "src/components/informes/App-crecimiento-clientes";
import ModalDetalleFacturas from "src/components/Modal/modalDetalleFacturas";
import { facturasApi } from "src/services/facturasApi";
import { convertirFechaAFormatoNumerico } from "src/utils/formatTime";

export default function Informes() {
  const { empleado: user } = useSelector((state) => state.auth.user);
  const [
    abrirModalEstadisticasDeFacturacion,
    setAbrirModalEstadisticasDeFacturacion,
  ] = useState(false);
  const [
    tituloModalEstadisticasFacturacion,
    setTituloModalEstadisticasFacturacion,
  ] = useState("");

  const {
    data: datosFacturacionAnio = [],
    isLoading: cargandoDatosFacturacionAnio,
  } = informesApi.useGetInformacionFacturacionAnioQuery(user.rut);
  const {
    data: datosEstadisticasFacturacion = [],
    isLoading: cargandoDatosEstadisticasFacturacion,
  } = informesApi.useGetInformacionEstadisticasFacturacionQuery(user.rut);

  const {
    data: datosFacturacionPorSucursales = [],
    isLoading: cargandoDatosFacturacionPorSucursales,
  } = informesApi.useGetInformacionfacturacionPorSucursalesQuery();

  const {
    data: datosCrecimientoClientes = [],
    isLoading: cargandoDatosCrecimientoClientes,
  } = informesApi.useGetInformacionCrecimientoClientesQuery();

  const [
    obtenerFacturasEmitidas,
    { data: facturasEmitidas, isFetching: obteniendoFacturasEmitidas },
  ] = facturasApi.useLazyObtenerFacturasEmitidasQuery();

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  const abrirModalDetalleFacturacion = useCallback(
    (fecha, filtro) => {
      const [anio, mes] = fecha.split("-");
      const titulo = generarTituloModal(filtro, mes, anio);
      setTituloModalEstadisticasFacturacion(titulo);

      const fechaFormateadaANumero = formatearFecha(fecha);
      const body = crearBodySolicitud(user.rut, filtro, fechaFormateadaANumero);

      obtenerFacturasEmitidas(body);
      setAbrirModalEstadisticasDeFacturacion(true);
    },
    [obtenerFacturasEmitidas, user]
  );

  const generarTituloModal = (filtro, mes, anio) =>
    `${filtro} ${mes} del ${anio}`;

  const formatearFecha = (fecha) => {
    const [anio, mes] = fecha.split("-");
    return convertirFechaAFormatoNumerico(`${anio}/${mes}`);
  };

  const crearBodySolicitud = (rut, filtro, fecha) => ({
    rut,
    estadoFactura: filtro,
    fecha,
  });

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Informes 📄
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppWidgetsFacturacionRecaudacionPendientes
              loading={cargandoDatosFacturacionAnio}
              chart={{
                series:
                  !cargandoDatosFacturacionAnio && datosFacturacionAnio
                    ? datosFacturacionAnio
                    : [],
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppGraficoDeBarras
              title="Estadísticas de facturación"
              subheader="Se muestran los últimos 12 meses"
              loading={cargandoDatosEstadisticasFacturacion}
              manejarClickGrafico={abrirModalDetalleFacturacion}
              chart={{
                categories:
                  !cargandoDatosEstadisticasFacturacion &&
                  datosEstadisticasFacturacion
                    ? datosEstadisticasFacturacion?.labelChart
                    : [],
                series: {
                  data:
                    !cargandoDatosEstadisticasFacturacion &&
                    datosEstadisticasFacturacion
                      ? datosEstadisticasFacturacion?.dataChart
                      : [],
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppFacturasSucursales
              title="Facturación sucursales"
              subheader="Se muestra en detalle lo que factura cada sucursal en el mes en curso"
              loading={cargandoDatosFacturacionPorSucursales}
              chart={{
                series: !cargandoDatosFacturacionPorSucursales
                  ? datosFacturacionPorSucursales.facturasTotales
                  : [],
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppFacturasSucursales
              title="Recaudación sucursales"
              subheader="Se muestra en detalle lo que recauda cada sucursal en el mes en curso"
              loading={cargandoDatosFacturacionPorSucursales}
              chart={{
                series: !cargandoDatosFacturacionPorSucursales
                  ? datosFacturacionPorSucursales.recaudacionesTotales
                  : [],
              }}
            />
          </Grid>

          {/* <Grid item xs={12} md={12} lg={6}>
        <AppCrecimientoClientes
          title="Crecimiento de clientes"
          subheader="Crecimiento respecto al mes anterior"
          chart={{
            labels: !cargandoDatosCrecimientoClientes
              ? datosCrecimientoClientes.crecimientoRelacionMesAnterior.map(
                  (dato) => dato.fecha
                )
              : [],
            series: [
              {
                name: "Nuevos clientes",
                type: "column",
                fill: "solid",
                data: !cargandoDatosCrecimientoClientes
                  ? datosCrecimientoClientes.crecimientoRelacionMesAnterior.map(
                      (dato) => dato.nuevosClientes
                    )
                  : [],
              },
              {
                name: "Tasa de crecimiento",
                type: "area",
                fill: "gradient",
                data: !cargandoDatosCrecimientoClientes
                  ? datosCrecimientoClientes.crecimientoRelacionMesAnterior.map(
                      (dato) => dato.tasaCrecimiento
                    )
                  : [],
              },
            ],
          }}
        />
      </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <AppCrecimientoClientes
              title="Crecimiento de clientes"
              subheader="Tendencia de crecimiento de clientes por mes de los últimos 12 meses"
              chart={{
                labels: !cargandoDatosCrecimientoClientes
                  ? datosCrecimientoClientes.crecimientoRelacionAcumuladoMesAnterior.map(
                      (dato) => dato.fecha
                    )
                  : [],
                series: [
                  {
                    name: "Nuevos clientes",
                    type: "column",
                    fill: "solid",
                    data: !cargandoDatosCrecimientoClientes
                      ? datosCrecimientoClientes.crecimientoRelacionAcumuladoMesAnterior.map(
                          (dato) => dato.nuevosClientes
                        )
                      : [],
                  },
                  {
                    name: "Tasa de crecimiento",
                    type: "area",
                    fill: "gradient",
                    data: !cargandoDatosCrecimientoClientes
                      ? datosCrecimientoClientes.crecimientoRelacionAcumuladoMesAnterior.map(
                          (dato) => dato.tasaCrecimiento
                        )
                      : [],
                  },
                ],
              }}
            />
          </Grid>

          {/* <Grid item xs={12} md={12} lg={12}>
          <GraficoTasas
            getData={getTasaCrecimientoCliente}
            isSuccess={isSuccessTasaCrecimientoCliente}
            data={tasaCrecimientoClienteData}
            title={"Tasa de crecimiento de clientes"}
            simbolo={"%"}
          />
        </Grid> */}
        </Grid>
      </Container>
      {abrirModalEstadisticasDeFacturacion && (
        <ModalDetalleFacturas
          isOpen={abrirModalEstadisticasDeFacturacion}
          setOpen={setAbrirModalEstadisticasDeFacturacion}
          title={tituloModalEstadisticasFacturacion}
          cargandoDatos={obteniendoFacturasEmitidas}
          rows={
            !obteniendoFacturasEmitidas && facturasEmitidas
              ? facturasEmitidas
              : []
          }
          tipo="recaudadoFull"
        />
      )}
    </>
  );
}
