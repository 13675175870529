import React from "react";
import {
  Paper,
  Stack,
  Typography,
  FormControl,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { generateRut } from "@fdograph/rut-utilities";


export default function DatosSolicitante(props) {
  const {
    valor,
    rutSolicitante,
    setRutSolicitante,
    nombreSolicitante,
    setNombreSolicitante,
    correoSolicitante,
    setCorreoSolicitante,
    telefonoSolicitante,
    setTelefonoSolicitante,
    validatePhoneNumber,
    formatPhoneNumber
  } = props;

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Solo permitir números y algunos caracteres especiales al escribir
    if (/^[\d\s\-()+.]*$/.test(value)) {
      setTelefonoSolicitante(value);
    }
  };

  const handlePhoneBlur = (e) => {
    const value = e.target.value;
    if (validatePhoneNumber(value)) {
      // Formatear el número cuando el campo pierde el foco
      setTelefonoSolicitante(formatPhoneNumber(value));
    }
  };

  return (
    <>
      {valor === 2 && (
        <Paper
          style={{
            height: window.screen.height - 350,
            margin: "10px",
            overflow: "auto",
          }}
        >
          <Typography align="center" variant="h3">
            Solicitante
          </Typography>
          <Stack spacing={2}>
            <Grid container>
              <Grid item xs={10} md={10}>
                <FormControl fullWidth style={{ marginTop: "30px" }}>
                  <TextField
                    required
                    error={rutSolicitante === null}
                    label="Rut Solicitante"
                    value={rutSolicitante}
                    onChange={(event) => {
                      setRutSolicitante(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} md={2}>
                <FormControl fullWidth style={{ marginTop: "25%" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      let rutGenerado = generateRut();
                      setRutSolicitante(rutGenerado);
                    }}
                  >
                    Sin Rut
                  </Button>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                error={nombreSolicitante === null}
                label="Nombre Solicitante"
                value={nombreSolicitante}
                onChange={(event) => {
                  setNombreSolicitante(event.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                error={correoSolicitante === null}
                label="Correo Solicitante"
                value={correoSolicitante}
                onChange={(event) => {
                  setCorreoSolicitante(event.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "30px" }}>
              <TextField
                required
                label="Teléfono solicitante"
                value={telefonoSolicitante}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
                placeholder="+56 9 XXXX XXXX"
              />
            </FormControl>
          </Stack>
        </Paper>
      )}
    </>
  );
}
