import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useChart from "../charts/use-chart";
import Chart from "../charts/chart";
import { fNumber, formatAsCLP } from "src/utils/formatNumber";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { ArrowBackIosNew, ArrowForwardIos } from "@material-ui/icons";
import CustomSmallSelect from "../custom-input/CustomSmallSelect";

AppGraficoDeBarras.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chart: PropTypes.object,
  loading: PropTypes.bool,
  manejarClickGrafico: PropTypes.func,
  showDataLabels: PropTypes.bool,
  dataTypes: PropTypes.string,
  usePagination: PropTypes.bool,
  itemsPerPage: PropTypes.number,
};

export default function AppGraficoDeBarras({
  title,
  subheader,
  chart,
  usePagination = false,
  itemsPerPage = 5,
  loading,
  manejarClickGrafico = null,
  showDataLabels = false,
  shouldShowSelect = false,
  dataTypes = "moneda",
  optionsSelect,
  ...other
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const { categories, colors, series, options } = chart;

  const [visibleCategories, setVisibleCategories] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState([]);
  const [seriesData, setSeriesData] = useState("cantidad");

  const totalPages = Math.ceil(categories.length / itemsPerPage);
  const shouldShowPagination =
    usePagination && categories.length > itemsPerPage;

  useEffect(() => {
    if (shouldShowPagination) {
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      setVisibleCategories(categories.slice(startIndex, endIndex));

      if (seriesData === "cantidad" && series?.data) {
        setVisibleSeries(
          series.data.map((serie) => ({
            ...serie,
            data: serie.data.slice(startIndex, endIndex),
          }))
        );
      } else if (seriesData === "complejidad" && series?.datasetsComplexity) {
        setVisibleSeries(
          series.datasetsComplexity.map((serie) => ({
            ...serie,
            data: serie.data.slice(startIndex, endIndex),
          }))
        );
      } else {
        setVisibleSeries([]); // Manejo de casos en los que no hay datos
      }
    } else {
      setVisibleCategories(categories);
      setVisibleSeries(
        seriesData === "cantidad" && series?.data
          ? series.data
          : series?.datasetsComplexity || []
      );
    }
  }, [
    currentPage,
    categories,
    series,
    usePagination,
    itemsPerPage,
    shouldShowPagination,
    seriesData,
  ]);

  const chartOptions = useChart({
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (manejarClickGrafico) {
            const { dataPointIndex, seriesIndex } = config;
            const labelAxisX =
              chartContext.w.config.xaxis.categories[dataPointIndex];
            const labelSeriesGraph =
              chartContext.w.config.series[seriesIndex].name;
            manejarClickGrafico(labelAxisX, labelSeriesGraph);
          }
        },
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = manejarClickGrafico
            ? "pointer"
            : "default";
        },
      },
      height: 550,
    },
    colors,
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: visibleCategories,
      labels: {
        rotate: -45,
        trim: true,
        maxHeight: 120,
        style: {
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) =>
          `${dataTypes === "moneda" ? formatAsCLP(value) : fNumber(value)}`,
      },
      x: {
        formatter: (value) => categories[value],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: showDataLabels,
      formatter: (value) =>
        `${dataTypes === "moneda" ? formatAsCLP(value) : fNumber(value)}`,
      style: {
        fontSize: "12px",
        colors: ["#343434"],
      },
      offsetY: -20,
    },
    ...options,
  });

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };

  const renderPaginationControls = () => (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {currentPage !== 0 ? (
        <Tooltip title="Retroceder">
          <IconButton onClick={handlePrevious} disabled={currentPage === 0}>
            <ArrowBackIosNew />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={handlePrevious} disabled={currentPage === 0}>
          <ArrowBackIosNew />
        </IconButton>
      )}
      <Typography variant="body2">
        Página {currentPage + 1} de {totalPages}
      </Typography>
      {currentPage !== totalPages - 1 ? (
        <Tooltip title="Avanzar">
          <IconButton
            onClick={handleNext}
            disabled={currentPage === totalPages - 1}
          >
            <ArrowForwardIos />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Avanzar">
          <IconButton
            onClick={handleNext}
            disabled={currentPage === totalPages - 1}
          >
            <ArrowForwardIos />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const renderSelectComplexity = () => {
    return (
      <CustomSmallSelect
        value={seriesData}
        onChange={(event) => setSeriesData(event.target.value)}
      >
        {optionsSelect.map((option) => (
          <option key={option.type} value={option.type}>
            {option.label}
          </option>
        ))}
      </CustomSmallSelect>
    );
  };

  if (loading) {
    return (
      <Card {...other}>
        <CardHeader
          title={<Skeleton variant="text" animation="wave" width={200} />}
          subheader={<Skeleton variant="text" animation="wave" width={100} />}
        />
        <Box sx={{ mt: 3, mb: 3, mx: 3 }} dir="ltr">
          <Skeleton variant="rect" animation="wave" width="100%" height={364} />
        </Box>
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          (shouldShowPagination || shouldShowSelect) && (
            <>
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={1}
              >
                {shouldShowSelect && renderSelectComplexity()}
                {shouldShowPagination && renderPaginationControls()}
              </Stack>
            </>
          )
        }
      />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart
          type="bar"
          series={visibleSeries}
          options={chartOptions}
          height={400}
        />
      </Box>
    </Card>
  );
}
