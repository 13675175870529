import { memo } from "react";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const FormControlField = memo(
  ({
    tamanio,
    touched,
    errors,
    inputLabelText,
    id,
    label,
    getFieldProps,
    value,
    handleChange,
    values,
    loading,
  }) => {
    return (
      <Grid item xs={tamanio}>
        <FormControl
          fullWidth
          variant="outlined"
          error={touched && Boolean(errors)}
          helpertext={touched && errors}
        >
          <InputLabel id={`${id}-input-label`}>{inputLabelText}</InputLabel>
          <Select
            labelId={`${id}-select`}
            id={id}
            label={label}
            {...getFieldProps}
            value={value}
            onChange={handleChange}
            error={touched && Boolean(errors)}
            helpertext={touched && errors}
          >
            {loading && (
              <MenuItem disabled>
                <CircularProgress color="inherit" size={20} />
              </MenuItem>
            )}
            {values.map((value) => (
              <MenuItem key={value.id} value={value.id}>
                {value.nombre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            style={{
              visibility: touched && Boolean(errors) ? "visible" : "hidden",
            }}
          >
            {touched && errors}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  }
);

export default FormControlField;
