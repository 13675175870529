import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@material-ui/core";
import { ajustesApi } from "src/services/ajustesApi";
import { AlertaToast } from "./AlertaSwall";

export default function EditarComisionesPredefinida(props) {
  const { open, setOpen, comision } = props;

  const [comisionEntrada, setComisionEntrada] = useState(comision.comision);

  const [actualizarComisionPredefinida] = ajustesApi.useUpdateComisionesPredefinidasMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeComision = (e) => {
    if (e.target.value >= 0 && e.target.value <= 100) {
      setComisionEntrada(e.target.value);
    } else {
      setComisionEntrada(0);
    }
  };

  const guardarDatos = () => {
    if (
      comisionEntrada === "" ||
      comisionEntrada === "" ||
      Number.isNaN(comisionEntrada)
    ) {

      AlertaToast({
        action: 'Error, la comisión debe ser un numero',
        fullText: true,
        type: 'error',
        target: document.getElementById('dialogEditarComisionPredefinidas'),
      }).fire()
    }
    else {
      actualizarComisionPredefinida({
        id: comision.id,
        body: {
          comision: comisionEntrada,
        }
      })
        .unwrap()
        .then(() => {
          AlertaToast({
            element: 'Comisión',
            action: 'actualizada',
            target: document.getElementById('dialogComisionesPredefinidas'),
          }).fire()
          handleClose();
        }).catch(() => {
          AlertaToast({
            action: 'Error en el servidor',
            fullText: true,
            type: 'error',
            target: document.getElementById('dialogEditarComisionPredefinidas'),
          }).fire()
        })
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      id="dialogEditarComisionPredefinidas"
    >
      <DialogTitle>Editar Comisión Tareas Normales</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          style={{ marginTop: "10px" }}
          type="number"
          label="Porcentaje Comisión"
          value={comisionEntrada}
          onChange={handleChangeComision}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cerrar
        </Button>
        <Button
          onClick={guardarDatos}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
