import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import AppEstadisticasTareaEspecial from "src/components/estadisticas/app-estadistica-tarea-especial";
import AppWidgetSummary from "src/components/estadisticas/app-widget-summary";
import ModalTablaHistoricoTareas from "../modalTablaHistoricoTareas";
import { tareasApi } from "src/services/tareasApi";
import ModalFacturasTareasEmpleado from "../modalFacturasTareasEmpleado";
import { facturasApi } from "src/services/facturasApi";

const ModalDetalleEstadisticasEmpleado = ({
  isOpen,
  setOpen,
  nombreEmpleado,
  rutEmpleado,
  historicoTareasEmpleado,
  detalleFacturasTareasEmpleado,
  cargandoDetalleFacturasTareasEmpleado,
  cargandoHistoricoTareasEmpleado,
}) => {
  const [modalTablaHistoricoTareas, setModalTablaHistoricoTareas] =
    useState(false);
  const [tituloModalTabla, setTituloModalTabla] = useState("");
  const [
    getTareasPorEstado,
    { data: tareasPorEstado, isFetching: cargandoTareasPorEstado },
  ] = tareasApi.useLazyGetTareasPorEstadoQuery();
  const [abrirModalFacturas, setAbrirModalFacturas] = useState(false);
  const [
    obtenerFacturasTareasEmpleado,
    {
      data: facturasDetareasEmpleado,
      isFetching: obteniendoFacturasDeTareasEmpleado,
    },
  ] = facturasApi.useLazyGetFacturasDeTareasEmpleadoEmitidasQuery();
  const [
    obtenerTareasPendientesFacturacion,
    {
      data: tareasPendientesFacturacion,
      isFetching: obteniendoTareasPendientesFacturacion,
    },
  ] = facturasApi.useLazyGetPendientePorFacturarTareasEmpleadoQuery();
  const [
    obtenerTareasConRecaudacionesYPagos,
    {
      data: tareasConRecaudacionesYPagos,
      isFetching: obteniendoTareasConRecaudacionesYPagos,
    },
  ] = facturasApi.useLazyGetRecaudadoTareasEspecialesEmpleadoQuery();
  const [tituloModal, setTituloModal] = useState("");
  const [tipoTabla, setTipoTabla] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickTipo = useCallback(
    (tipo) => {
      const body = {
        rutEmpleado,
        tipo,
      };
      getTareasPorEstado(body);
      const titulo = `Tareas ${tipo.toLowerCase()} de ${nombreEmpleado}`;
      setTituloModalTabla(titulo);
      setModalTablaHistoricoTareas(true);
    },
    [getTareasPorEstado, nombreEmpleado, rutEmpleado]
  );

  const manejarFacturasEmitidastareas = useCallback(() => {
    setTituloModal(`Facturas tareas especiales de ${nombreEmpleado}`);
    setTipoTabla("emitidas");
    setAbrirModalFacturas(true);
    obtenerFacturasTareasEmpleado(rutEmpleado);
  }, [nombreEmpleado, obtenerFacturasTareasEmpleado, rutEmpleado]);

  const manejarFacturasPendientesPorFacturar = useCallback(() => {
    setTituloModal(`Tareas pendientes por facturar de ${nombreEmpleado}`);
    setTipoTabla("pendientes");
    setAbrirModalFacturas(true);
    obtenerTareasPendientesFacturacion(rutEmpleado);
  }, [nombreEmpleado, obtenerTareasPendientesFacturacion, rutEmpleado]);

  const manejarTareasConFacturasRecaudadasYPagadas = useCallback(() => {
    setTituloModal(`Tareas con facturas pagadas de ${nombreEmpleado}`);
    setTipoTabla("recaudacion");
    setAbrirModalFacturas(true);
    obtenerTareasConRecaudacionesYPagos(rutEmpleado);
  }, [nombreEmpleado, obtenerTareasConRecaudacionesYPagos, rutEmpleado]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-detalle-factura-empleados"
        id="dialog-detalle-factura-empleados"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          style={styles.title}
          htmlFor="dialog-detalle-factura-empleados"
        >
          {nombreEmpleado ? `Estadísticas ${nombreEmpleado}` : "Estadísticas"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <AppEstadisticasTareaEspecial
                title="Histórico de tareas"
                data={
                  !cargandoHistoricoTareasEmpleado
                    ? historicoTareasEmpleado
                    : []
                }
                loading={cargandoHistoricoTareasEmpleado}
                handleClick={handleClickTipo}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <AppWidgetSummary
                title="Precio total tareas especiales"
                total={
                  !cargandoDetalleFacturasTareasEmpleado
                    ? detalleFacturasTareasEmpleado.totalTareas || 0
                    : 0
                }
                type="peso"
                color="info"
                loading={cargandoDetalleFacturasTareasEmpleado}
                icon={
                  <img alt="icon" src="/assets/icons/glass/bolsa_dinero.svg" />
                }
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <AppWidgetSummary
                title="Facturas emitidas"
                total={
                  !cargandoDetalleFacturasTareasEmpleado
                    ? detalleFacturasTareasEmpleado.facturado || 0
                    : 0
                }
                type="peso"
                color="info"
                handleClick={() => manejarFacturasEmitidastareas()}
                cursor="pointer"
                loading={cargandoDetalleFacturasTareasEmpleado}
                icon={
                  <img
                    alt="icon"
                    src="/assets/icons/glass/facturas_icono2.svg"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <AppWidgetSummary
                title="Recaudado facturas emitidas"
                total={
                  !cargandoDetalleFacturasTareasEmpleado
                    ? detalleFacturasTareasEmpleado.recaudado || 0
                    : 0
                }
                type="peso"
                color="info"
                loading={cargandoDetalleFacturasTareasEmpleado}
                handleClick={() => manejarTareasConFacturasRecaudadasYPagadas()}
                cursor="pointer"
                icon={
                  <img
                    alt="icon"
                    src="/assets/icons/glass/recaudaciones_icono.svg"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <AppWidgetSummary
                title="Pendiente por facturar"
                total={
                  !cargandoDetalleFacturasTareasEmpleado
                    ? detalleFacturasTareasEmpleado.pendientePorFacturar || 0
                    : 0
                }
                type="peso"
                color="info"
                loading={cargandoDetalleFacturasTareasEmpleado}
                handleClick={() => manejarFacturasPendientesPorFacturar()}
                cursor="pointer"
                icon={
                  <img
                    alt="icon"
                    src="/assets/icons/glass/facturas_icono.svg"
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {modalTablaHistoricoTareas && (
        <ModalTablaHistoricoTareas
          isOpen={modalTablaHistoricoTareas}
          setOpen={setModalTablaHistoricoTareas}
          titulo={tituloModalTabla}
          rows={!cargandoTareasPorEstado ? tareasPorEstado : []}
          loading={cargandoTareasPorEstado}
        />
      )}
      {abrirModalFacturas && (
        <ModalFacturasTareasEmpleado
          isOpen={abrirModalFacturas}
          setOpen={setAbrirModalFacturas}
          facturasDetareasEmpleado={facturasDetareasEmpleado}
          tareasPendientesFacturacion={tareasPendientesFacturacion}
          tareasConRecaudacionesYPagos={tareasConRecaudacionesYPagos}
          obteniendoFacturasDeTareasEmpleado={
            obteniendoFacturasDeTareasEmpleado
          }
          obteniendoTareasPendientesFacturacion={
            obteniendoTareasPendientesFacturacion
          }
          obteniendoTareasConRecaudacionesYPagos={
            obteniendoTareasConRecaudacionesYPagos
          }
          title={tituloModal}
          tipoTabla={tipoTabla}
        />
      )}
    </>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalDetalleEstadisticasEmpleado.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  nombreEmpleado: PropTypes.string,
  rutEmpleado: PropTypes.string,
  historicoTareasEmpleado: PropTypes.array,
  detalleFacturasTareasEmpleado: PropTypes.object,
  cargandoDetalleFacturasTareasEmpleado: PropTypes.bool,
  cargandoHistoricoTareasEmpleado: PropTypes.bool,
};

export default ModalDetalleEstadisticasEmpleado;
