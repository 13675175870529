import React, { useEffect, useRef, useCallback, useState } from "react";
import { TemplateHandler } from "easy-template-x";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";
import FileList from "./FileList";
import {
  descargarArchivoDesdeDropbox,
  eliminarArchivoDropbox,
  FileNotFoundError,
} from "src/utils/dropboxUtils";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { clientesApi } from "src/services/clientesApi";
import { fechaActual } from "src/utils/formatTime";

export default function ArchivosAdjuntos(props) {
  const { tarea } = props;

  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const isMounted = useRef(true);

  const [loadingGenerateDocument, setLoadingGenerateDocument] = useState(false);

  const { data: archivos = [], isLoading: cargandoArchivos } =
    tareasApi.useGetArchivosQuery(tarea.id);
  const [obtenerDatosCliente] = clientesApi.useLazyGetClienteQuery();
  const [borrarArchivoTarea] = tareasApi.useBorrarArchivoTareaMutation();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDeleteClick = useCallback(
    async (file) => {
      const result = await AlertaModal({
        title: "eliminar el archivo de la tarea",
        confirmButtonText: "Eliminar",
        hasFooter: true,
        target: document.getElementById("editar-tarea"),
      }).fire();

      if (!result.isConfirmed) {
        return;
      }

      const ruta = `Tareas/${tarea.id}/`;
      const nombreArchivo = file.nombreArchivo;

      try {
        const { status } = await eliminarArchivoDropbox(ruta, nombreArchivo);

        if (status === 200) {
          const { data, error } = await borrarArchivoTarea({
            id: file.id,
          });
          if (error) {
            AlertaToast({
              action:
                "Error al eliminar el archivo en la base de datos. Comuníquese con su administrador",
              fullText: true,
              type: "error",
              target: document.getElementById("editar-tarea"),
            }).fire();
            return;
          }

          const { status, message } = data;

          if (status !== "success") {
            AlertaToast({
              action:
                "Ocurrió un error desconocido al eliminar el archivo de la base de datos",
              fullText: true,
              type: "error",
              target: document.getElementById("editar-tarea"),
            }).fire();
          }
          AlertaToast({
            action: message,
            fullText: true,
            type: "success",
            target: document.getElementById("editar-tarea"),
          }).fire();
        }
      } catch (error) {
        if (error instanceof FileNotFoundError) {
          console.error("El archivo ya no existe:", error.path);
          AlertaToast({
            action: "El archivo que desea eliminar no existe",
            fullText: true,
            type: "error",
            target: document.getElementById("editar-tarea"),
          }).fire();
        } else {
          console.error("Error al eliminar:", error.message);
          AlertaToast({
            action: "Ocurrió un error desconocido al eliminar el archivo",
            fullText: true,
            type: "error",
            target: document.getElementById("editar-tarea"),
          }).fire();
        }
        throw error;
      }
    },
    [borrarArchivoTarea, tarea.id]
  );

  function saveFile(filename, blob) {
    const blobUrl = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  function capitalizarPalabras(val) {
    return val
      .toLowerCase()
      .trim()
      .split(". ")
      .map((v) => v[0].toUpperCase() + v.substr(1))
      .join(". ");
  }

  const generarPropuestaModelo = useCallback(async () => {
    setLoadingGenerateDocument(true);

    try {
      const templateFile = await descargarArchivoDesdeDropbox(
        "Archivos/",
        "PropuestaMODELO.docx"
      );

      const { data: cliente, error } = await obtenerDatosCliente({
        rut: tarea.refCliente,
      });

      if (error) {
        console.error(error);
        AlertaToast({
          action: "Error al obtener los datos del cliente",
          fullText: true,
          type: "error",
          target: document.getElementById("editar-tarea"),
        }).fire();
        return;
      }

      let planesAux = [];

      let decripcionTareaAUX = "";

      if (tarea.comentario !== "") {
        decripcionTareaAUX = capitalizarPalabras(tarea.comentario);
      }

      planesAux.push({
        descripcion: decripcionTareaAUX,
        nombreCliente: cliente.nombre,
        nombrePlan: tarea.nombre,
        tipoMoneda: tarea.tipoMonedaTarea,
        valor: tarea.valorTarea,
      });

      const fecha = fechaActual().format("DD [de] MMMM [del] YYYY");
      const data = {
        ciudad: capitalizarPalabras(sucursalObtenida.comuna),
        fechaActual: fecha,
        nombreCliente: cliente.nombre,
        nombreEmpleado: user.nombre + " " + user.apellidos,
        Planes: planesAux,
      };
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      saveFile("Propuesta " + cliente.nombre + ".docx", doc);
    } catch (error) {
      console.error("Error in generateDocument", error);
      AlertaToast({
        action:
          "Ocurrió un error desconocido al generar el prototipo de la propuesta",
        fullText: true,
        type: "error",
        target: document.getElementById("editar-tarea"),
      }).fire();
    } finally {
      setLoadingGenerateDocument(false);
    }
  }, [
    obtenerDatosCliente,
    sucursalObtenida.comuna,
    tarea.comentario,
    tarea.nombre,
    tarea.refCliente,
    tarea.tipoMonedaTarea,
    tarea.valorTarea,
    user.apellidos,
    user.nombre,
  ]);

  const onDownload = (link) => {
    window.open(link, "_blank");
  };

  return (
    <FileList
      tarea={tarea}
      archivos={archivos}
      cargandoArchivos={cargandoArchivos}
      onDownload={onDownload}
      onDeleteClick={handleDeleteClick}
      generateDocument={generarPropuestaModelo}
      loadingGenerateDocument={loadingGenerateDocument}
    />
  );
}
