import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Skeleton
} from "@material-ui/core";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";
import { Box } from "@material-ui/system";
import moment from "moment";


export default function HistorialTiempoSubTareaDialog(props) {
  const { open, setOpen, subtarea } = props;
  const [tiempoTotal, setTiempoTotal] = useState(0);
  const [historialTiempo, setHistorialTiempo] = useState(subtarea.horasDedicadas);
  const [cargandoDatos, setCargandoDatos] = useState(false);

  function secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    hour = (hour < 10) ? '0' + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10) ? '0' + minute : minute;
    var second = seconds % 60;
    second = (second < 10) ? '0' + second : second;
    return hour + ':' + minute + ':' + Math.round(second);
  }


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let totalTiempo = 0;
    try {
      subtarea.horasDedicadas.map((item) => {
        totalTiempo = totalTiempo + item.segundos


      })

    } catch (error) {

    }
    setTiempoTotal(totalTiempo);

  }, [subtarea]);

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Historial Tiempo: {subtarea.nombre}
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell align="right">Tiempo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historialTiempo && historialTiempo.map((row, idx) => (
                <TableRow
                  key={"tablaHistorial_" + idx}
                >
                  <TableCell component="th" scope="row">
                    {moment(row.fecha, "YYYY/MM/DD").format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="right">{secondsToString(row.segundos)}</TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>
      <Box width="100%">
        <Alert severity="info">Tiempo Total: {secondsToString(tiempoTotal)}</Alert>
      </Box>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );

}
