import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Stack,
  Typography,
  Grid,
  FormControl,
  Card,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/core/Autocomplete";
import { API_SERVER } from "src/utils/urlBack_End";
import axios from "axios";
import { toLower, upperFirst } from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";

function obtenerDataPlanOptions(option, data) {
  let dataPlan = {};
  // eslint-disable-next-line array-callback-return
  data.map((value) => {
    if (value.nombre === option) dataPlan = value;
  });

  return dataPlan;
}

export default function Planes(props) {
  const { valor, planesSeleccionados, setPlanesSeleccionados } = props;
  const isMounted = useRef(true);

  const obtenerMes = () => {
    let fecha = new Date();
    let mes = fecha.getMonth() + 1;

    if (String(mes).length === 1) {
      mes = "0" + mes;
    }
    return mes;
  };

  const [dataPlan, setDataPlan] = useState([]);
  const [planSeleccionado, setPlanSeleccionado] = useState(null);

  const [frecuencia, setFrecuencia] = useState("MES");
  const [facturacionGestor, setFacturacionGestor] = useState("SI");
  const [tipoMonedaFinal, setTipoMonedaFinal] = useState("");
  const [precioFijoFinal, setPrecioFijoFinal] = useState(0);
  const [descuentoFinal, setDescuentoFinal] = useState(0);
  const [precioFinal, setPrecioFinal] = useState(0);
  const [mesCargaTareas, setMesCargaTareas] = useState(obtenerMes());
  const [tiempoDescuento, setTiempoDescuento] = useState(1);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tipos/tipoPlanes`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setDataPlan(response.data);
        }
      });
  }, []);

  function obtenerFormatoMoneda(tipoMoneda, valor) {
    let precio = new Intl.NumberFormat("cl-ES").format(valor);
    let moneda = {
      UTM: precio + " UTM",
      PESOS: "$" + precio,
      UF: precio + " UF",
      default: "$" + precio,
    };

    return moneda[tipoMoneda];
  }

  return (
    <>
      {valor === 4 && (
        <Paper
          style={{
            height: window.screen.height - 350,
            margin: "10px",
            overflow: "auto",
          }}
        >
          <Typography align="center" variant="h3">
            Planes
          </Typography>

          <Stack spacing={2}>
            <Grid container>
              <Grid
                item
                xs={planSeleccionado ? 8 : 12}
                md={planSeleccionado ? 8 : 12}
              >
                <FormControl fullWidth style={{ marginTop: "30px" }}>
                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) =>
                      option.nombre === value.nombre
                    }
                    getOptionLabel={(option) => option.nombre}
                    options={dataPlan}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        const data = obtenerDataPlanOptions(
                          newValue.nombre,
                          dataPlan
                        );
                        setPlanSeleccionado(data);
                        setTipoMonedaFinal(
                          data.tipoMoneda ? data.tipoMoneda : ""
                        );
                        setPrecioFijoFinal(data.valor ? Number(data.valor) : 0);
                        setPrecioFinal(data.valor ? data.valor : 0);
                      } else {
                        setPlanSeleccionado(null);
                        setTipoMonedaFinal("");
                        setPrecioFijoFinal(0);
                        setPrecioFinal(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Seleccionar Plan"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {planSeleccionado && (
                <Grid item xs={4} md={4}>
                  <Button
                    style={{ marginTop: "30px" }}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      let nuevosPlanesAux = planesSeleccionados;
                      let existePlan = nuevosPlanesAux.filter(
                        (e) => e.nombre === planSeleccionado.nombre
                      )[0];
                      if (existePlan) {
                      } else {
                        let precioFijo = "NO";
                        if (planSeleccionado.valor !== precioFijoFinal) {
                          precioFijo = "SI";
                        }
                        nuevosPlanesAux.push({
                          ...planSeleccionado,
                          frecuencia: frecuencia,
                          facturacionGestor: facturacionGestor,
                          tipoMonedaFinal: tipoMonedaFinal,
                          precioFijoFinal: precioFijoFinal,
                          descuentoFinal: descuentoFinal,
                          precioFinal: precioFinal,
                          precioFijo: precioFijo,
                          mesCargaTareas: mesCargaTareas,
                          tiempoDescuento: tiempoDescuento,
                        });
                        setFrecuencia("MES");
                        setTipoMonedaFinal("");
                        setPrecioFijoFinal(0);
                        setPrecioFinal(0);
                        setDescuentoFinal(0);
                        setMesCargaTareas(obtenerMes());
                        setPlanesSeleccionados(nuevosPlanesAux);
                        setPlanSeleccionado(null);
                      }
                    }}
                  >
                    Agregar
                  </Button>
                </Grid>
              )}
            </Grid>

            {planSeleccionado !== null && (
              <Paper>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Typography
                          gutterBottom
                          variant="caption  "
                          sx={{
                            color: "text.disabled",
                            display: "block",
                            paddingBottom: "0.75rem",
                          }}
                        >
                          {upperFirst(toLower(planSeleccionado.descripcion))}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={8} md={8}>
                        <Typography
                          gutterBottom
                          variant="caption  "
                          sx={{
                            color: "text.disabled",
                            display: "block",
                            paddingBottom: "0.75rem",
                          }}
                        >
                          {"Precio (referencia):"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Typography
                          gutterBottom
                          variant="caption  "
                          sx={{
                            color: "text.disabled",
                            display: "block",
                            paddingBottom: "0.75rem",
                          }}
                        >
                          {obtenerFormatoMoneda(
                            planSeleccionado.tipoMoneda,
                            planSeleccionado.valor
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Grid container>
                  <Grid item xs={6} md={6}>
                    <Card>
                      <Typography variant="h4" align="center">
                        Frecuencia
                      </Typography>

                      <CardContent>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label-frecuencia">
                                Frecuencia
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-frecuencia"
                                label="Frecuencia"
                                value={frecuencia}
                                onChange={(event) => {
                                  setFrecuencia(event.target.value);
                                }}
                              >
                                <MenuItem value={""}>Seleccionar...</MenuItem>
                                <MenuItem value={"MES"}>MENSUAL</MenuItem>
                                <MenuItem value={"AÑO"}>ANUAL</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {frecuencia === "AÑO" && (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ marginTop: "10px" }}
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label-cargaTareas">
                                  Mes Carga Tareas
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label-cargaTareas"
                                  label="Mes Carga Tareas"
                                  value={mesCargaTareas}
                                  onChange={(event) => {
                                    setMesCargaTareas(event.target.value);
                                  }}
                                >
                                  <MenuItem value="01">ENERO</MenuItem>
                                  <MenuItem value="02">FEBRERO</MenuItem>
                                  <MenuItem value="03">MARZO</MenuItem>
                                  <MenuItem value="04">ABRIL</MenuItem>
                                  <MenuItem value="05">MAYO</MenuItem>
                                  <MenuItem value="06">JUNIO</MenuItem>
                                  <MenuItem value="07">JULIO</MenuItem>
                                  <MenuItem value="08">AGOSTO</MenuItem>
                                  <MenuItem value="09">SEPTIEMBRE</MenuItem>
                                  <MenuItem value="10">OCTUBRE</MenuItem>
                                  <MenuItem value="11">NOVIEMBRE</MenuItem>
                                  <MenuItem value="12">DICIEMBRE</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card>
                      <Typography variant="h4" align="center">
                        ¿Facturación desde el gestor?
                      </Typography>
                      <CardContent>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "10px" }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="input-label-facturacion-gestor">
                                ¿Facturación desde gestor?
                              </InputLabel>
                              <Select
                                labelId="input-label-facturacion-gestor"
                                label="Facturación desde el gestor"
                                value={facturacionGestor}
                                onChange={(event) => {
                                  setFacturacionGestor(event.target.value);
                                }}
                              >
                                <MenuItem value={""}>Seleccionar...</MenuItem>
                                <MenuItem value={"SI"}>SI</MenuItem>
                                <MenuItem value={"NO"}>NO</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Card style={{ height: 300, overflow: "auto" }}>
                      <Typography variant="h4" align="center">
                        Precio
                      </Typography>

                      <CardContent style={{ overflowY: "auto" }}>
                        <Stack spacing={2}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-moneda">
                              Tipo Moneda
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label-moneda"
                              label="Tipo Moneda"
                              value={tipoMonedaFinal}
                              onChange={(event) => {
                                setTipoMonedaFinal(event.target.value);
                              }}
                            >
                              <MenuItem value={""}>Seleccionar...</MenuItem>
                              <MenuItem value={"DOLAR"}>DOLAR</MenuItem>
                              <MenuItem value={"PESOS"}>PESOS</MenuItem>
                              <MenuItem value={"UTM"}>UTM</MenuItem>
                              <MenuItem value={"UF"}>UF</MenuItem>
                            </Select>
                          </FormControl>

                          <Grid container>
                            <Grid item xs={6} md={6}>
                              <TextField
                                fullWidth
                                required
                                type="number"
                                label="Precio Fijo"
                                value={precioFijoFinal}
                                onChange={(event) => {
                                  let valor = String(
                                    event.target.value
                                  ).replace(",", ".");
                                  if (isNaN(valor)) {
                                    valor = planesSeleccionados.valor;
                                    setPrecioFijoFinal(valor);
                                    setPrecioFinal(
                                      valor - valor * descuentoFinal * 0.01
                                    );
                                  } else {
                                    setPrecioFijoFinal(valor);
                                    setPrecioFinal(
                                      valor - valor * descuentoFinal * 0.01
                                    );
                                  }
                                }}
                                InputProps={
                                  tipoMonedaFinal === "PESOS"
                                    ? {
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }
                                    : tipoMonedaFinal === "UTM"
                                    ? {
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            UTM
                                          </InputAdornment>
                                        ),
                                      }
                                    : tipoMonedaFinal === "UF"
                                    ? {
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            UF
                                          </InputAdornment>
                                        ),
                                      }
                                    : {
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }
                                }
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <TextField
                                fullWidth
                                required
                                value={descuentoFinal}
                                onChange={(event) => {
                                  let valor = event.target.value;
                                  if (isNaN(valor)) {
                                    setDescuentoFinal(0);
                                    setTiempoDescuento("");
                                    setPrecioFinal(
                                      precioFijoFinal -
                                        precioFijoFinal * 0 * 0.01
                                    );
                                  } else {
                                    const precioFinal = Number.parseFloat(precioFijoFinal) -
                                    Number.parseFloat(precioFijoFinal) *
                                      event.target.value *
                                      0.01;
                                    setPrecioFinal(
                                      precioFinal.toFixed(2)
                                    );
                                    setDescuentoFinal(valor);
                                  }
                                }}
                                label="Descuento"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>

                          {descuentoFinal > 0 && (
                            <Grid container>
                              <Grid item xs={6} md={6}>
                                <TextField
                                  fullWidth
                                  required
                                  label="Tiempo Descuento"
                                  value={tiempoDescuento}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        MES
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(event) => {
                                    let valor = event.target.value;
                                    if (isNaN(valor)) {
                                      setTiempoDescuento(0);
                                    } else {
                                      setTiempoDescuento(valor);
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}></Grid>
                            </Grid>
                          )}

                          <TextField
                            fullWidth
                            required
                            disabled
                            value={precioFinal}
                            variant="filled"
                            label="Precio Final"
                          />
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            )}

            <Card>
              <CardContent>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Plan</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {planesSeleccionados &&
                        planesSeleccionados.map((planObtenido, idx) => (
                          <TableRow key={"planObtenido_" + idx}>
                            <TableCell component="th" scope="row">
                              {planObtenido.nombre}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  let existePlan = planesSeleccionados.filter(
                                    (e) => e.nombre !== planObtenido.nombre
                                  );
                                  setPlanesSeleccionados(existePlan);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Stack>
        </Paper>
      )}
    </>
  );
}
