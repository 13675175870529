import React from "react";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  Button
} from "@material-ui/core";

export default function VerMasBitacora(props) {
  const {
    open,
    setOpen,
    descripcion
  } = props;


  const cerrar = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <TextField
              disabled
              value={descripcion}
              multiline
              margin="dense"
              label="Descripción"
              fullWidth
              rows={10}
            />

          </DialogContent>
          <DialogActions>
            <Button
              onClick={cerrar}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
