import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Tooltip,
  CardContent,
  Button,
  Grid,
  Stack,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "@material-ui/lab/LoadingButton";
import axios from "axios";

import {
  API_KEY_OPEN_FACTURA_PROD,
  API_OPEN_FACTURA_PROD,
  API_SERVER,
} from "../../utils/urlBack_End";
import DownloadIcon from "@material-ui/icons/Download";
import CancelIcon from "@material-ui/icons/Cancel";
import { saveAs } from "file-saver";
import PagosFacturaDialog from "./PagosFacturaDialog";
import FacturarDialog from "./FacturarDialog";
import ObtenerFacturaDialog from "./ObtenerFacturaDialog";
import { useSelector } from "react-redux";
import { AlertaModal } from "../AlertaSwall";
import { dteApi } from "src/services/dteApi";
import Label from "../Label";
import { sentenceCase } from "change-case";
import { obtenerValorMoneda } from "src/utils/sharedFunctions";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hidden: {
    display: "none",
  },
});

// ----------------------------------------------------------------------

export default function FacturasTarea({ tarea, monedas }) {
  const classes = useStyles();
  const { sucursal: sucursalObtenida, rol: rolObtenido } = useSelector(
    (state) => state.auth.user
  );

  const [facturas, setFacturas] = useState([]);
  const [mostrarPagos, setMostrarPagos] = useState(false);
  const [loadingDescargar, setLoadingDescargar] = useState(false);
  const [loadingAnular, setLoadingAnular] = useState(false);

  const [facturaSeleccionada, setFacturaSeleccionada] = useState(null);

  const [modalFactura, setModalFactura] = useState(false);
  const [modalObtenerFactura, setModalObtenerFactura] = useState(false);
  const [totalFacturado, setTotalFacturado] = useState(0);
  const [valorTareaPesos, setValorTareaPesos] = useState(0);

  const [crearDTE] = dteApi.useCrearDTEMutation();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && monedas) {
      const { tipoMonedaTarea, valorTarea } = tarea;
      const valorTareaEnPesos =
        tipoMonedaTarea === "PESOS"
          ? valorTarea
          : obtenerValorMoneda(monedas, tipoMonedaTarea) * valorTarea;

      if (valorTareaEnPesos === null || valorTareaEnPesos === undefined) {
        console.error("Error al obtener las monedas");
        throw Error("Error al obtener las monedas");
      }

      setValorTareaPesos(valorTareaEnPesos);
    }
  }, [monedas, tarea]);

  function formatNumber(n) {
    n = n > 0 ? String(n).replace(/\D/g, "") : n;
    return n === "" ? n : Number(n).toLocaleString("es-CL");
  }

  const calcularTotalFacturasEmitidas = useCallback(() => {
    const sumFacturasEmitidas = tarea.facturas.reduce((total, factura) => {
      if (
        factura.estadoFactura !== "ANULADO" &&
        factura.estadoFactura !== "INCOBRABLE"
      ) {
        return total + factura.total;
      }
      return total;
    }, 0);

    setTotalFacturado(sumFacturasEmitidas);
  }, [tarea.facturas]);

  useEffect(() => {
    calcularTotalFacturasEmitidas();
  }, [calcularTotalFacturasEmitidas]);

  const anadirFacturas = useCallback(() => {
    setFacturas(
      tarea.facturas.filter(
        (factura) => factura.sucursal === sucursalObtenida.refSucursal
      )
    );
  }, [sucursalObtenida.refSucursal, tarea.facturas]);

  useEffect(() => {
    anadirFacturas();
  }, [anadirFacturas]);

  const anularFactura = (row) => {
    setLoadingAnular(true);
    AlertaModal({
      title: "anular el documento",
      confirmButtonText: "Sí, anular",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          crearDTE({
            montoFactura: row.total,
            tipoDTE: 61,
            tokenAnular: row.token,
            tipoFactura: "TAREA",
            formatoPago: 2,
            montoPagado: "SI",
            metodoPago: "EFECTIVO",
            idTarea: row.refTarea,
            sucursal: row.sucursal,
          })
            .unwrap()
            .then((response2) => {
              axios
                .get(
                  `${API_SERVER}/facturas/tareas/${sucursalObtenida.refSucursal}/${row.refTarea}`,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then(function (response) {
                  if (Array.isArray(response.data)) {
                    let arregloFactura = response.data;
                    let totalFacutadoTareas = 0;

                    arregloFactura.forEach((element) => {
                      if (element.estado === "ANULADO") {
                      } else if (element.total) {
                        totalFacutadoTareas =
                          totalFacutadoTareas + parseFloat(element.total);
                      }
                    });
                    setTotalFacturado(totalFacutadoTareas);
                    setFacturas(arregloFactura);
                  }
                  saveAs(
                    "data:application/pdf;base64," + response2.PDF,
                    response2.FOLIO + ".pdf"
                  );
                })
                .catch(() => {});
            })
            .catch((error) => {})
            .finally(() => {
              setLoadingAnular(false);
            });
        } else {
          setLoadingAnular(false);
        }
      });
  };

  return (
    <Container>
      <RootStyle>
        <Card style={{ padding: "1rem" }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    totalFacturado >= valorTareaPesos ||
                    tarea.estado === "FACTURADO"
                  }
                  onClick={() => {
                    setModalFactura(true);
                  }}
                >
                  Generar Documento Tributario
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                {!(totalFacturado >= valorTareaPesos) &&
                  tarea.estado !== "FACTURADO" && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={totalFacturado >= valorTareaPesos}
                      onClick={() => {
                        setModalObtenerFactura(true);
                      }}
                    >
                      Obtener Facturas
                    </Button>
                  )}
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Folio</TableCell>
                    <TableCell>Total Facturado</TableCell>
                    <TableCell>Estado Factura</TableCell>
                    <TableCell>Estado Correo</TableCell>
                    <TableCell>Fecha Facturación</TableCell>
                    <TableCell>Empleado Envia Correo</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {facturas &&
                    facturas.map((row, idx) => {
                      return (
                        <TableRow key={"facturasAsociadas_" + idx}>
                          <TableCell component="th" scope="row">
                            {row.folio}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatNumber(row.total)}
                          </TableCell>
                          <TableCell>
                            <Label
                              variant="ghost"
                              color={
                                row.estadoFactura === "FACTURADO"
                                  ? "success"
                                  : row.estadoFactura === "PAGADO"
                                  ? "info"
                                  : "error"
                              }
                            >
                              {sentenceCase(row.estadoFactura)}
                            </Label>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button disabled>
                              <Label
                                variant="ghost"
                                color={
                                  (row.estadoCorreo === "ENVIADO" &&
                                    "success") ||
                                  "error"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {sentenceCase(
                                  row.estadoCorreo === ""
                                    ? "No enviado"
                                    : row.estadoCorreo
                                )}
                              </Label>
                            </Button>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.fechaFacturacion}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.empleadoEnviaCorreo}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="column">
                              {row.estadoFactura === "FACTURADO" &&
                                rolObtenido !== "EMPLEADO" &&
                                rolObtenido !== "SUPERVISOR" &&
                                rolObtenido !== "ADMIN_OPERACIONES" && (
                                  <LoadingButton
                                    color="error"
                                    loading={loadingAnular}
                                    onClick={() => {
                                      anularFactura(row);
                                    }}
                                  >
                                    <CancelIcon /> Anular
                                  </LoadingButton>
                                )}

                              <LoadingButton
                                color="primary"
                                loading={loadingDescargar}
                                onClick={() => {
                                  setLoadingDescargar(true);
                                  axios
                                    .get(
                                      `${API_OPEN_FACTURA_PROD}/v2/dte/document/${row.token}/pdf`,
                                      {
                                        headers: {
                                          apikey: API_KEY_OPEN_FACTURA_PROD,
                                        },
                                      }
                                    )
                                    .then((response) => {
                                      saveAs(
                                        "data:application/pdf;base64," +
                                          response.data.pdf,
                                        row.folio + ".pdf"
                                      );
                                      setLoadingDescargar(false);
                                    })
                                    .catch(() => {
                                      setLoadingDescargar(false);
                                    });
                                }}
                              >
                                <DownloadIcon />
                              </LoadingButton>

                              <Tooltip title="Ver Pagos">
                                <LoadingButton
                                  color="primary"
                                  onClick={() => {
                                    setFacturaSeleccionada(row);
                                    setMostrarPagos(true);
                                  }}
                                >
                                  Ver Pagos
                                </LoadingButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        {mostrarPagos && facturaSeleccionada && (
          <PagosFacturaDialog
            open={mostrarPagos}
            setOpen={setMostrarPagos}
            factura={facturaSeleccionada}
          />
        )}
        {modalFactura && (
          <FacturarDialog
            open={modalFactura}
            setOpen={setModalFactura}
            tarea={tarea}
            monedas={monedas}
            setFacturas={setFacturas}
            setTotalFacturadoEntrada={setTotalFacturado}
            setValorTareaPesos={setValorTareaPesos}
          />
        )}

        {modalObtenerFactura && (
          <ObtenerFacturaDialog
            open={modalObtenerFactura}
            setOpen={setModalObtenerFactura}
            tarea={tarea}
            monedas={monedas}
            setFacturas={setFacturas}
            setTotalFacturadoEntrada={setTotalFacturado}
            setValorTareaPesos={setValorTareaPesos}
          />
        )}
      </RootStyle>
    </Container>
  );
}
