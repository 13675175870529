import { memo, useCallback, useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Button,
  Checkbox,
  Chip,
  DialogActions,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import * as Yup from "yup";
import { empleadosApi } from "src/services/empleadosApi";
import TextFieldComponent from "../sharedFields/TextFieldComponent";
import AutoCompleteField from "../sharedFields/AutocompleteField";
import { sucursalesApi } from "src/services/sucursalesApi";
import { prorrateosApi } from "src/services/prorrateosApi";
import { AlertaToast } from "src/components/AlertaSwall";

const FormProrrateo = memo(({ data, editar, setStateModal, statesModal }) => {
  const { data: sucursales = [], isLoading: isLoadingSucursales } =
    sucursalesApi.useGetSucursalesQuery();
  const [isLoadingEmpleadosActivos, setIsLoadingEmpleadosActivos] =
    useState(false);
  const [empleadosPrincipalesActivos, setEmpleadosPrincipalesActivos] =
    useState([]);
  const [getEmpleadosActivos] = empleadosApi.useLazyGetEmpleadosActivosQuery();
  const [crearProrrateo] = prorrateosApi.useCrearProrrateoMutation();
  const [editarProrrateo] = prorrateosApi.useEditarProrrateoMutation();
  const [primeraEntrada, setPrimeraEntrada] = useState(true);
  const [primeraEntradaEmpleados, setPrimeraEntradaEmpleados] = useState(true);

  const ProrrateoSchema = Yup.object().shape({
    sucursal: Yup.object()
      .typeError("La sucursal es requerida")
      .required("La sucursal es requerida"),
    porcentaje: Yup.string().when("cantidadIguales", {
      is: false,
      then: Yup.string()
        .matches(
          /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?|0?\.\d{1,2})$/,
          "Porcentaje no válido"
        )
        .test(
          "is-valid-percentage",
          "El porcentaje debe estar entre 1 y 100",
          (value) => {
            const floatValue = parseFloat(value);
            return floatValue >= 1 && floatValue <= 100;
          }
        )
        .required("Porcentaje es requerido"),
      otherwise: Yup.string(),
    }),
    cantidadIguales: Yup.boolean().required("La opción es requerida"),
    empleadosPrincipales: Yup.array().min(1, "Seleccione al menos un empleado"),
  });

  const formik = useFormik({
    initialValues: {
      sucursal: editar ? data.sucursal : null,
      cantidadIguales: editar ? data.cantidadesIguales === 1 : true,
      porcentaje: editar ? data.porcentaje || "" : "",
      empleadosPrincipales: editar ? [] : [],
    },
    validationSchema: ProrrateoSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;

  const obtenerEmpleadosActivosQuery = useCallback(
    async (refSucursal) => {
      const body = {
        id: refSucursal,
        estado: "ACTIVO",
      };
      try {
        const response = await getEmpleadosActivos(body);
        setEmpleadosPrincipalesActivos(response.data);
      } catch (error) {
        console.error("Error en obtenerEmpleadosActivosQuery:", error);
      } finally {
        setIsLoadingEmpleadosActivos(false);
        setPrimeraEntrada(false);
      }
    },
    [getEmpleadosActivos]
  );

  const handleClose = () => {
    setStateModal({ ...statesModal, abrirModal: false, seEdita: false });
  };

  const handleChangeEmpleadosPrincipales = useCallback(
    (e, value) => setFieldValue("empleadosPrincipales", value),
    [setFieldValue]
  );

  const handleChangeSucursales = useCallback(
    async (e, value) => {
      setFieldValue("empleadosPrincipales", []);
      setEmpleadosPrincipalesActivos([]);
      setIsLoadingEmpleadosActivos(true);

      if (!value) {
        setFieldValue("sucursal", null);
        return;
      }
      setFieldValue("sucursal", value);
      const idSucursal = value.nombre;

      await obtenerEmpleadosActivosQuery(idSucursal);
    },
    [obtenerEmpleadosActivosQuery, setFieldValue]
  );

  const handleCheckboxChange = (event) => {
    // Si el checked es true, es porque el porcentaje de división es igual para todas las empresas, por ende, debería ser vacío.
    if (event.target.checked) {
      setFieldValue("porcentaje", "");
    }

    setFieldValue("cantidadIguales", event.target.checked);
  };

  const handleOptionLabel = useCallback(
    (option) => `${option.nombre} ${option.apellidos}`,
    []
  );

  const handleOptionLabelSucursales = useCallback((option) => {
    return option.nombre;
  }, []);

  const handleIsOptionEqualToValueInSucursales = useCallback(
    (option, value) => option.nombre === value.nombre,
    []
  );

  const handleRenderTagsSucursales = useCallback((value, getTagProps) => {
    return value.map((option, index) => (
      <Chip
        variant="outlined"
        label={`${option.nombre}`}
        {...getTagProps({ index })}
      />
    ));
  }, []);

  const handleIsOptionEqualToValue = useCallback(
    (option, value) => option.rut === value.rut,
    []
  );
  const handleRenderTags = useCallback((value, getTagProps) => {
    return value.map((option, index) => (
      <Chip
        variant="outlined"
        label={`${option.nombre} ${option.apellidos}`}
        {...getTagProps({ index })}
      />
    ));
  }, []);

  const handleDeleteEmpleadosPrincipales = (row) => {
    let empleadosFiltrados = values.empleadosPrincipales.filter(
      (dato) => dato.rut !== row.rut
    );
    setFieldValue("empleadosPrincipales", empleadosFiltrados);
  };

  useEffect(() => {
    if (editar && primeraEntrada) {
      setIsLoadingEmpleadosActivos(true);
      obtenerEmpleadosActivosQuery(data.refSucursal);
    }
  }, [data.refSucursal, editar, obtenerEmpleadosActivosQuery, primeraEntrada]);

  const ingresarEmpleadosDeBD = useCallback(
    (empleadosBD) => {
      const empleados = empleadosPrincipalesActivos.filter((itemA) =>
        empleadosBD.some((itemB) => itemB.refEmpleado === itemA.rut)
      );
      setPrimeraEntradaEmpleados(false);
      return empleados;
    },
    [empleadosPrincipalesActivos]
  );

  useEffect(() => {
    if (editar && primeraEntradaEmpleados && !primeraEntrada) {
      const empleadosBD = ingresarEmpleadosDeBD(data.empleadosProrrateo);
      setFieldValue("empleadosPrincipales", empleadosBD);
    }
  }, [
    data.empleadosProrrateo,
    editar,
    ingresarEmpleadosDeBD,
    isLoadingEmpleadosActivos,
    primeraEntrada,
    primeraEntradaEmpleados,
    setFieldValue,
  ]);

  const guardarDatos = () => {
    const porcentajeForm = values.porcentaje;
    const cantidadIgualesForm = values.cantidadIguales;
    const empleadosPrincipalesForm = values.empleadosPrincipales;
    const sucursal = values.sucursal;

    const body = {
      porcentaje: porcentajeForm,
      cantidadIguales: cantidadIgualesForm,
      refSucursal: sucursal.nombre,
      empleadosPrincipales: empleadosPrincipalesForm,
    };

    if (editar) {
      const bodyEditar = {
        ...body,
        id: data.id,
      };

      editarProrrateo(bodyEditar)
        .then((response) => {
          if (response && response.error && response.error.status === 409) {
            AlertaToast({
              action:
                response.error.data.message ||
                "Uno de los empleados asociados, se encuentra vinculado a otro prorrateo",
              fullText: true,
              type: "error",
            }).fire();
          } else if (
            response &&
            response.error &&
            response.error.status === 500
          ) {
            AlertaToast({
              action: "Error interno del servidor",
              fullText: true,
              type: "error",
            }).fire();
          } else {
            AlertaToast({
              element: "Prorrateo",
              action: "editado",
            }).fire();
          }
          handleClose();
        })
        .catch((error) => {
          console.error(
            "Error editando en FormProrrateo (fn:guardarDatos)",
            error
          );
          AlertaToast({
            action: "Error al editar el prorrateo",
            fullText: true,
            type: "error",
          }).fire();
        });
    } else {
      crearProrrateo(body)
        .then((response) => {
          if (response && response.error && response.error.status === 409) {
            AlertaToast({
              action: "El prorrateo ya existe",
              fullText: true,
              type: "error",
            }).fire();
          } else if (
            response &&
            response.error &&
            response.error.status === 500
          ) {
            AlertaToast({
              action: "Error interno del servidor",
              fullText: true,
              type: "error",
            }).fire();
          } else {
            AlertaToast({
              element: "Nuevo prorrateo",
              action: "creado",
            }).fire();
          }
          handleClose();
        })
        .catch((error) => {
          formik.resetForm();
          AlertaToast({
            action: "Error al crear el prorrateo",
            fullText: true,
            type: "error",
          }).fire();
          console.error(
            "Error guardando en FormProrrateo (fn:guardarDatos)",
            error
          );
        });
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={1}>
          <AutoCompleteField
            id="autocomplete-sucursales"
            label="Sucursales"
            value={values.sucursal}
            xs={12}
            disabled={editar ? true : false}
            handleSucursalSelection={handleChangeSucursales}
            options={!isLoadingSucursales ? sucursales : []}
            touched={touched.sucursal}
            error={errors.sucursal}
            getOptionLabel={handleOptionLabelSucursales}
            isOptionEqualToValue={handleIsOptionEqualToValueInSucursales}
            typeRenderInput="loading"
            renderTags={handleRenderTagsSucursales}
          />
          <Grid item xs={values.cantidadIguales ? 12 : 6}>
            {!values.cantidadIguales && (
              <TextFieldComponent
                campo="porcentaje"
                label="Porcentaje"
                value={values.porcentaje}
                touched={touched.porcentaje}
                errors={errors.porcentaje}
                getFieldProps={getFieldProps("porcentaje")}
                inputAdornment="percent"
              />
            )}
          </Grid>
          <Grid
            item
            xs={values.cantidadIguales ? 12 : 6}
            style={styles.checkboxRow}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.cantidadIguales}
                  id="cantidadIguales"
                  {...getFieldProps("cantidadIguales")}
                  onChange={handleCheckboxChange}
                />
              }
              label="¿Cantidad iguales?"
            />
          </Grid>
          <AutoCompleteField
            multiple={true}
            id="autocomplete-empleados-principales"
            label="Empleados principales"
            value={values.empleadosPrincipales}
            xs={12}
            handleSucursalSelection={handleChangeEmpleadosPrincipales}
            options={
              !isLoadingEmpleadosActivos ? empleadosPrincipalesActivos : []
            }
            touched={touched.empleadosPrincipales}
            error={errors.empleadosPrincipales}
            getOptionLabel={handleOptionLabel}
            isOptionEqualToValue={handleIsOptionEqualToValue}
            typeRenderInput="loading"
            renderTags={handleRenderTags}
            isLoading={isLoadingEmpleadosActivos}
          />
          {values.empleadosPrincipales.length > 0 && (
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table size="small" aria-label="tabla empleados principales">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre Empleado</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.empleadosPrincipales.map((row) => (
                      <TableRow key={row.rut}>
                        <TableCell component="th" scope="row">
                          {row.nombre + " " + row.apellidos}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteEmpleadosPrincipales.bind(
                              null,
                              row
                            )}
                          >
                            Eliminar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
        <DialogActions>
          <LoadingButton
            onClick={handleClose}
            color={styles.buttonCancel.color}
          >
            Cancelar
          </LoadingButton>
          <LoadingButton
            type="submit"
            disabled={isLoadingEmpleadosActivos || isSubmitting}
            startIcon={<Icon icon={saveFill} />}
            color={styles.button.color}
            loading={isSubmitting}
          >
            Guardar Prorrateo
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
});

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  checkboxRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    color: "primary",
  },
  buttonCancel: {
    color: "error",
  },
};

export default FormProrrateo;
