import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import AnalyticsWidgetSummary from "src/components/estadisticas/app-analytics-widget-summary";
import nuevoClientes from "@iconify/icons-ant-design/user-add-outlined";
import promedioPlanesIcono from "@iconify/icons-ant-design/solution-outline";
import promedioPagoPlanes from "@iconify/icons-ant-design/file-done-outlined";
import promedioPagoTareas from "@iconify/icons-ant-design/check-square-outlined";
import AppWidgetIcon from "src/components/estadisticas/app-widgets-icon";
import AppTopPlan from "src/components/estadisticas/app-top-plan";
import AppContribuyenteSegunPlanes from "src/components/estadisticas/app-contribuyente-segun-planes";
import { useCallback, useMemo, useState } from "react";
import ModalDetalleEstadisticasFacturas from "../modalDetalleEstadisticasFacturas";
import { facturasApi } from "src/services/facturasApi";
import ModalDetalleDivisionClientes from "../modalDetalleDivisionClientes";
import { clientesApi } from "src/services/clientesApi";
import CalendarField from "../modalGav/Fields/CalendarField";
import dayjs from "dayjs";
import { formatearFecha } from "src/utils/formatTime";
import ModalInformacionNuevosClientes from "../modalInformacionNuevosClientes";

const DIVISION_TIPOS = {
  "clientes planes": {
    tipo: "planes",
    titulo: "Clientes con solo planes",
  },
  "clientes tareas especiales": {
    tipo: "especiales",
    titulo: "Clientes con solo tareas especiales",
  },
};

const TIPOS_TARJETA = {
  planes: {
    tipoFactura: "plan",
    titulo: "Detalle facturas planes clientes",
  },
  tareas: {
    tipoFactura: "tarea",
    titulo: "Detalle facturas tareas clientes",
  },
};

const ModalDistribucionClientes = ({
  isOpen,
  setOpen,
  title,
  cantidadPromedioPlanes,
  divisionClientes,
  planesMasCotizados,
  contribuyentesPlanes,
  promedioFacturaClientes,
  cargandoContribuyentesPlanes,
  cargandoDivisionClientes,
  cargandoPlanesMasCotizados,
  cargandoCantidadPromedioPlanes,
  cargandoPromedioFacturaClientes,
}) => {
  const [
    abrirModalDetalleEstadisticasFacturas,
    setAbrirModalDetalleEstadisticasFacturas,
  ] = useState(false);
  const [
    abrirModalDetalleDivisionClientes,
    setAbrirModalDetalleDivisionClientes,
  ] = useState(false);
  const [
    abrirModalInformacionNuevosClientes,
    setAbrirModalInformacionNuevosClientes,
  ] = useState(false);

  const [titulo, setTitulo] = useState("");
  const [tituloDivisionClientes, setTituloDivisionClientes] = useState("");
  const [tituloInformacionNuevosClientes, setTituloInformacionNuevosClientes] =
    useState("");
  const [filtroFechaClientes, setFiltroFechaClientes] = useState(
    dayjs().format("YYYY/MM")
  );

  const {
    data: cantidadNuevosClientesPorMes,
    isFetching: obteniendoCantidadNuevosClientesPorMes,
  } = clientesApi.useGetNuevosClientesIngresadosPorMesQuery({
    fecha: filtroFechaClientes,
    tipo: "cantidad",
  });

  const [
    obtenerInformacionClientesNuevos,
    {
      data: informacionClientesNuevosPorMes,
      isFetching: obteniendoInformacionClientesNuevosPorMes,
    },
  ] = clientesApi.useLazyGetNuevosClientesIngresadosPorMesQuery();

  const [obtenerFacturas, { data: facturas, isFetching: obteniendoFacturas }] =
    facturasApi.useLazyObtenerClientesMayorCantidadFacturasPagadasQuery();
  const [
    obtenerDetalleDivisionClientes,
    {
      data: detalleDivisionClientes,
      isFetching: obteniendoDetalleDivisionClientes,
    },
  ] = clientesApi.useLazyObtenerDetalleDivisionClientesQuery();

  const handleClose = () => {
    setOpen(false);
  };

  const manejarClicTarjeta = useCallback(
    (tipo) => {
      const tipoConfig = TIPOS_TARJETA[tipo.toLowerCase()];

      if (!tipoConfig) {
        throw new Error("Tipo de tarjeta no válida");
      }

      obtenerFacturas(tipoConfig.tipoFactura);
      setTitulo(tipoConfig.titulo);
      setAbrirModalDetalleEstadisticasFacturas(true);
    },
    [obtenerFacturas]
  );

  const manejarClicTarjetaNuevosClientes = useCallback(() => {
    const body = {
      fecha: filtroFechaClientes,
      tipo: "informacion",
    };

    const fechaFormateada = formatearFecha(
      filtroFechaClientes,
      "MMMM [del] YYYY"
    );

    const titulo = `Clientes ingresados en el mes de ${fechaFormateada}`;
    setTituloInformacionNuevosClientes(titulo);
    obtenerInformacionClientesNuevos(body);
    setAbrirModalInformacionNuevosClientes(true);
  }, [filtroFechaClientes, obtenerInformacionClientesNuevos]);

  const manejarClicDivisionClientes = useCallback(
    (label) => {
      const tipoConfig = DIVISION_TIPOS[label.toLowerCase()];

      if (!tipoConfig) {
        throw new Error(`Tipo ${label} no permitido`);
      }

      obtenerDetalleDivisionClientes(tipoConfig.tipo);
      setTituloDivisionClientes(tipoConfig.titulo);
      setAbrirModalDetalleDivisionClientes(true);
    },
    [obtenerDetalleDivisionClientes]
  );

  const handleChangeFechaFacturacion = useCallback(
    (newValue) => setFiltroFechaClientes(dayjs(newValue).format("YYYY/MM")),
    [setFiltroFechaClientes]
  );

  const tituloTarjetaNuevosClientes = useMemo(() => {
    if (filtroFechaClientes && dayjs(filtroFechaClientes).isValid()) {
      const fechaFormateada = formatearFecha(filtroFechaClientes, "MMMM YYYY");
      return `Nuevos clientes en ${fechaFormateada}`;
    }
  }, [filtroFechaClientes]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-modal-estadisticas-facturacion"
        id="dialog-modal-estadisticas-facturacion"
        fullWidth
        maxWidth="xl"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <DialogTitle htmlFor="dialog-modal-estadisticas-facturacion">
                {title}
              </DialogTitle>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              padding={2}
            >
              <CalendarField
                id="fecha-filtro"
                label="Filtro para nuevos clientes"
                value={filtroFechaClientes}
                onChange={handleChangeFechaFacturacion}
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                tamanio={6}
                maxDate={dayjs().format("YYYY/MM/DD")}
              />
            </Grid>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12} md={8} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <AnalyticsWidgetSummary
                    title={tituloTarjetaNuevosClientes}
                    total={
                      !obteniendoCantidadNuevosClientesPorMes &&
                      cantidadNuevosClientesPorMes
                        ? cantidadNuevosClientesPorMes
                        : 0
                    }
                    color="success"
                    loading={obteniendoCantidadNuevosClientesPorMes}
                    icon={nuevoClientes}
                    cursor="pointer"
                    onClick={() => manejarClicTarjetaNuevosClientes()}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <AnalyticsWidgetSummary
                    title="Promedio de planes contratados por cliente"
                    total={
                      !cargandoCantidadPromedioPlanes && cantidadPromedioPlanes
                        ? cantidadPromedioPlanes
                        : 0
                    }
                    color="info"
                    loading={cargandoCantidadPromedioPlanes}
                    icon={promedioPlanesIcono}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <AnalyticsWidgetSummary
                    title="Promedio de facturas de planes pagadas por cliente"
                    total={
                      !cargandoPromedioFacturaClientes &&
                      promedioFacturaClientes
                        ? promedioFacturaClientes.planes
                        : 0
                    }
                    loading={cargandoPromedioFacturaClientes}
                    color="warning"
                    type="dinero"
                    icon={promedioPagoPlanes}
                    cursor="pointer"
                    onClick={() => manejarClicTarjeta("planes")}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <AnalyticsWidgetSummary
                    title="Promedio de facturas de tareas pagadas por cliente"
                    total={
                      !cargandoPromedioFacturaClientes &&
                      promedioFacturaClientes
                        ? promedioFacturaClientes.tareas
                        : 0
                    }
                    loading={cargandoPromedioFacturaClientes}
                    color="secondary"
                    type="dinero"
                    icon={promedioPagoTareas}
                    cursor="pointer"
                    onClick={() => manejarClicTarjeta("tareas")}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <AppWidgetIcon
                    loading={cargandoDivisionClientes}
                    onClick={manejarClicDivisionClientes}
                    series={[
                      {
                        label: "Clientes planes",
                        total:
                          !cargandoDivisionClientes && divisionClientes
                            ? divisionClientes.clientesPlan
                            : 0,
                        clic: true,
                        imagen: "/assets/icons/glass/clientes_planes.svg",
                      },
                      {
                        label: "Clientes tareas especiales",
                        total:
                          !cargandoDivisionClientes && divisionClientes
                            ? divisionClientes.clientesTareasEspeciales
                            : 0,
                        clic: true,
                        imagen:
                          "/assets/icons/glass/clientes_tareas_especiales.svg",
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={4} lg={4} sx={{ width: "100%" }}>
              <AppTopPlan
                list={
                  !cargandoPlanesMasCotizados && planesMasCotizados
                    ? planesMasCotizados
                    : []
                }
                loading={cargandoPlanesMasCotizados}
                title="Planes más cotizados"
                sx={{ height: 1 }}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <AppContribuyenteSegunPlanes
                loading={cargandoContribuyentesPlanes}
                rows={
                  !cargandoContribuyentesPlanes && contribuyentesPlanes
                    ? contribuyentesPlanes
                    : []
                }
                title="Clientes según planes"
                subheader="Se muestra qué clientes tienen contratado un determinado plan"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cerrar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {abrirModalDetalleEstadisticasFacturas && (
        <ModalDetalleEstadisticasFacturas
          isOpen={abrirModalDetalleEstadisticasFacturas}
          setOpen={setAbrirModalDetalleEstadisticasFacturas}
          title={titulo}
          facturas={facturas}
          cargandoFacturas={obteniendoFacturas}
        />
      )}
      {abrirModalDetalleDivisionClientes && (
        <ModalDetalleDivisionClientes
          isOpen={abrirModalDetalleDivisionClientes}
          setOpen={setAbrirModalDetalleDivisionClientes}
          title={tituloDivisionClientes}
          cargandoDetalleDivisionClientes={obteniendoDetalleDivisionClientes}
          detalleDivisionclientes={detalleDivisionClientes}
        />
      )}
      {abrirModalInformacionNuevosClientes && (
        <ModalInformacionNuevosClientes
          isOpen={abrirModalInformacionNuevosClientes}
          setOpen={setAbrirModalInformacionNuevosClientes}
          title={tituloInformacionNuevosClientes}
          cargandoNuevosClientes={obteniendoInformacionClientesNuevosPorMes}
          nuevosClientes={informacionClientesNuevosPorMes}
          setFiltroFecha={setFiltroFechaClientes}
          filtroFecha={filtroFechaClientes}
        />
      )}
    </>
  );
};

ModalDistribucionClientes.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  cantidadPromedioPlanes: PropTypes.number,
  divisionClientes: PropTypes.object,
  promedioFacturaClientes: PropTypes.object,
  planesMasCotizados: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      cantidad: PropTypes.number.isRequired,
    })
  ),
  contribuyentesPlanes: PropTypes.array,
  cargandoContribuyentesPlanes: PropTypes.bool,
  cargandoPlanesMasCotizados: PropTypes.bool,
  cargandoPromedioFacturaClientes: PropTypes.bool,
  cargandoDivisionClientes: PropTypes.bool,
  cargandoCantidadPromedioPlanes: PropTypes.bool,
  filtroFechaClientes: PropTypes.string,
  setFiltroFechaClientes: PropTypes.func,
};

export default ModalDistribucionClientes;
