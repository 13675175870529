import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const tareasApi = createApi({
  reducerPath: "tareasApi",
  baseQuery: baseQuery(`${API_SERVER}/tareas`),
  tagTypes: ["Tareas", "Tarea", "Comisiones", "archivosTarea"],
  endpoints: (builder) => ({
    getTarea: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Tarea"],
    }),
    agregarTareaInterna: builder.mutation({
      query: (body) => {
        return {
          url: `/interna`,
          method: "POST",
          body,
        };
      },
    }),
    tareasPendientesMasAntiguas: builder.query({
      query: () => "/tareasPendientesMasAntiguas",
    }),
    getTareasTotalesPorSucursalTipoTareaYFecha: builder.query({
      query: ({ sucursal, tipoTarea, fecha, estadoTarea, nombreTarea }) => {
        return {
          url: `/tareasTotalesPorSucursalTipoTareaYFecha/${sucursal}`,
          method: "GET",
          params: {
            tipoTarea,
            fecha,
            estadoTarea,
            nombreTarea,
          },
        };
      },
    }),
    getCantidadTareasAgrupadasPorNombreTarea: builder.query({
      query: ({ sucursal, nombre, fecha, tipo }) => {
        return {
          url: `/cantidadTareasAgrupadasPorNombreTarea/${sucursal}`,
          method: "GET",
          params: {
            nombre,
            fecha,
            tipo,
          },
        };
      },
    }),
    getTareasEmpleadosPorNombreTarea: builder.query({
      query: ({ sucursal, nombre, fecha }) => {
        return {
          url: `/tareasEmpleadosPorNombreTarea/${sucursal}`,
          method: "GET",
          params: {
            nombre,
            fecha,
          },
        };
      },
    }),
    getCantidadTareasAgrupadasPorNombreTareaYEmpleado: builder.query({
      query: ({ sucursal, nombre, fecha, tipo }) => {
        return {
          url: `/cantidadTareasAgrupadasPorNombreTareaYEmpleado/${sucursal}`,
          method: "GET",
          params: {
            nombre,
            fecha,
            tipo,
          },
        };
      },
    }),
    historialUltimasTareasFinalizadas: builder.query({
      query: (rut) => `/historialUltimasTareasFinalizadas/${rut}`,
    }),
    historialUltimasTareasEspecialesIngresadas: builder.query({
      query: (rut) => `/historialUltimasTareasEspecialesIngresadas/${rut}`,
    }),
    obtenerHistoricoTareasEmpleado: builder.query({
      query: (rut) => `/historicoTareasEmpleado/${rut}`,
    }),
    agregarTareaEspecial: builder.mutation({
      query(body) {
        return {
          url: `/especial`,
          method: "POST",
          body,
        };
      },
    }),
    obtenerComisionesTarea: builder.query({
      query: (id) => {
        return {
          url: `/comisiones/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Comisiones"],
    }),
    editarComisionesTarea: builder.mutation({
      query({ idTarea, ...body }) {
        return {
          url: `/comisiones/${idTarea}`,
          method: "PUT",
          body,
        };
      },
    }),
    obtenerTareasEmpleadoPorSucursalYEstadoTarea: builder.query({
      query: ({ sucursal, estado, rut, fecha }) => {
        return {
          url: `/tareasEmpleadoPorSucursalYEstadoTarea/${sucursal}`,
          method: "GET",
          params: {
            estado,
            rut,
            fecha,
          },
        };
      },
    }),
    obtenerTareasTotalesEmpleadosSucursal: builder.query({
      query: ({ sucursal, fecha }) => {
        return {
          url: `/tareasTotalesEmpleadosSucursal/${sucursal}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    getTareasAll: builder.query({
      query: ({ id, refEmpleado = null, rolObtenido = null }) => {
        return {
          url: `/all/${id}`,
          method: "GET",
          params: {
            refEmpleado: refEmpleado,
            rolObtenido: rolObtenido,
          },
        };
      },
      providesTags: ["Tarea"],
    }),
    getTareasParaFacturar: builder.query({
      query({ sucursal, rolObtenido = null }) {
        return {
          url: `/tareasParaFacturar/${sucursal}`,
          method: "GET",
          params: {
            rolObtenido: rolObtenido,
          },
        };
      },
      providesTags: ["Tareas"],
    }),
    obtenerTareasClienteFacturacion: builder.query({
      query(refCliente) {
        return {
          url: `/tareasClienteFacturacion/${refCliente}`,
          method: "GET",
        };
      },
    }),
    getCambioEstado: builder.query({
      query(id) {
        return {
          url: `/cambiaEstado/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Tareas"],
    }),
    getCliente: builder.query({
      query(id) {
        return {
          url: `/cliente/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Tareas"],
    }),
    getSucursal: builder.query({
      query(id) {
        return {
          url: `/sucursal/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Tareas"],
    }),
    getEmpleadosSinSucursal: builder.query({
      query(id) {
        return {
          url: `/empleadoSinSucursal/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Tareas"],
    }),
    getTipoPlan: builder.query({
      query(id) {
        return {
          url: `/tipoPlan/${id}`,
          method: "GET",
        };
      },
    }),
    getTipoTarea: builder.query({
      query({ id, tipoTarea }) {
        return {
          url: `/tipoTarea/${id}/${tipoTarea}`,
          method: "GET",
        };
      },
    }),
    getTareasPorEstado: builder.query({
      query: ({ rutEmpleado, tipo }) =>
        `/tareasPorEstado/${rutEmpleado}/${tipo}`,
    }),
    getCantidadTareasMensualesYTotales: builder.query({
      query: ({ sucursal, fecha }) => {
        return {
          url: `/cantidadTareasMensualesYTotales/${sucursal}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    getTareasAnualesYMensualesAgrupadas: builder.query({
      query: ({ sucursal, fecha }) => {
        return {
          url: `/tareasAnualesYMensualesAgrupadas/${sucursal}`,
          method: "GET",
          params: {
            fecha,
          },
        };
      },
    }),
    getTareasInformacion: builder.query({
      query: ({ sucursal, fecha, tipoTarea }) => {
        return {
          url: `/tareasInformacion/${sucursal}`,
          method: "GET",
          params: {
            fecha,
            tipoTarea,
          },
        };
      },
    }),
    getCantidadTareasEmpleadosAgrupadas: builder.query({
      query: ({ sucursal, fecha, tipoTarea, estadoTarea, nombreTarea }) => {
        return {
          url: `/cantidadTareasAgrupadasPorEmpleadoYTarea/${sucursal}`,
          method: "GET",
          params: {
            fecha,
            tipoTarea,
            estadoTarea,
            nombreTarea,
          },
        };
      },
    }),
    getEmpleado: builder.query({
      query({ refEmpleado, sucursal }) {
        return {
          url: `/empleado/${refEmpleado}/${sucursal}`,
          method: "GET",
        };
      },
    }),
    getTablero: builder.query({
      query({ refEmpleado, sucursal }) {
        return {
          url: `/inicio/${refEmpleado}/${sucursal}`,
          method: "GET",
        };
      },
      providesTags: ["Tareas"],
    }),
    getArchivos: builder.query({
      query(idTarea) {
        return {
          url: `/archivos/${idTarea}`,
          method: "GET",
        };
      },
      providesTags: ["archivosTarea"],
    }),
    subirArchivoATarea: builder.mutation({
      query: (body) => ({
        url: `/archivos`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["archivosTarea"],
    }),
    borrarArchivoTarea: builder.mutation({
      query: ({ id }) => ({
        url: `/archivos/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["archivosTarea"],
    }),
    getComisiones: builder.query({
      query(idTarea) {
        return {
          url: `/comisiones/${idTarea}`,
          method: "GET",
        };
      },
    }),
    updateCambiaEstado: builder.mutation({
      query: ({ id, body }) => ({
        url: `/cambiaEstado/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tarea", "Tareas"],
    }),
    addResponderCambioEstado: builder.mutation({
      query({ id, body }) {
        return {
          url: `/responderCambioEstado/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Tareas"],
    }),
    addSolicitudCambioEstado: builder.mutation({
      query(body) {
        return {
          url: `/solicitudCambioEstado`,
          method: "POST",
          body,
        };
      },
    }),
    deleteTarea: builder.mutation({
      query(id) {
        return {
          url: `/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Tareas"],
    }),
    updateTarea: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tarea"],
    }),
    updateTareaFacturada: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/cambioEstado/facturado/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tarea"],
    }),
  }),
});

export const {
  useGetArchivosQuery,
  useGetCambioEstadoQuery,
  useGetClienteQuery,
  useGetComisionesQuery,
  useGetEmpleadoQuery,
  useGetEmpleadosSinSucursalQuery,
  useGetSucursalQuery,
  useGetTableroQuery,
  useGetTareaQuery,
  useGetTipoPlanQuery,
  useGetTipoTareaQuery,
  useGetTareasPorEstadoQuery,
  useGetTareasParaFacturarQuery,
  useObtenerTareasTotalesEmpleadosSucursalQuery,
  useObtenerHistoricoTareasEmpleadoQuery,
  useObtenerTareasClienteFacturacionQuery,
  useObtenerComisionesTareaQuery,
  useGetCantidadTareasMensualesYTotalesQuery,
  useGetTareasAnualesYMensualesAgrupadasQuery,
  useGetTareasInformacionQuery,
  useGetCantidadTareasEmpleadosAgrupadasQuery,
  useObtenerTareasEmpleadoPorSucursalYEstadoTareaQuery,
  useTareasPendientesMasAntiguasQuery,
  useHistorialUltimasTareasFinalizadasQuery,
  useHistorialUltimasTareasEspecialesIngresadasQuery,
  useGetTareasTotalesPorSucursalTipoTareaYFechaQuery,
  useGetCantidadTareasAgrupadasPorNombreTareaQuery,
  useGetTareasEmpleadosPorNombreTareaQuery,
  useGetCantidadTareasAgrupadasPorNombreTareaYEmpleadoQuery,
  useAgregarTareaEspecialMutation,
  useEditarComisionesTareaMutation,
  useAgregarTareaInternaMutation,
  useLazyObtenerTareasEmpleadoPorSucursalYEstadoTareaQuery,
  useLazyObtenerTareasTotalesEmpleadosSucursalQuery,
  useLazyGetCantidadTareasAgrupadasPorNombreTareaQuery,
  useLazyGetTareasEmpleadosPorNombreTareaQuery,
  useLazyGetCantidadTareasAgrupadasPorNombreTareaYEmpleadoQuery,
  useLazyGetCantidadTareasEmpleadosAgrupadasQuery,
  useLazyGetTareasTotalesPorSucursalTipoTareaYFechaQuery,
  useLazyGetTareasInformacionQuery,
  useLazyGetCantidadTareasMensualesYTotalesQuery,
  useLazyGetTareasAnualesYMensualesAgrupadasQuery,
  useLazyObtenerHistoricoTareasEmpleadoQuery,
  useLazyHistorialUltimasTareasEspecialesIngresadasQuery,
  useLazyObtenerTareasClienteFacturacionQuery,
  useLazyObtenerComisionesTareaQuery,
  useLazyTareasPendientesMasAntiguasQuery,
  useLazyHistorialUltimasTareasFinalizadasQuery,
  useLazyGetTareasParaFacturarQuery,
  useLazyGetTareasPorEstadoQuery,
  useLazyGetArchivosQuery,
  useLazyGetCambioEstadoQuery,
  useLazyGetClienteQuery,
  useLazyGetComisionesQuery,
  useLazyGetEmpleadoQuery,
  useLazyGetEmpleadosSinSucursalQuery,
  useLazyGetSucursalQuery,
  useLazyGetTableroQuery,
  useLazyGetTareaQuery,
  useLazyGetTipoPlanQuery,
  useLazyGetTipoTareaQuery,
  useUpdateCambiaEstadoMutation,
  useSubirArchivoATareaMutation,
  useDeleteTareaMutation,
  useBorrarArchivoTareaMutation,
  useAddResponderCambioEstadoMutation,
  useAddSolicitudCambioEstadoMutation,
  useUpdateTareaMutation,
  useUpdateTareaFacturadaMutation,
  useGetTareasAllQuery,
  useLazyGetTareasAllQuery,
} = tareasApi;
