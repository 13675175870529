import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import BorradorFactura from "src/components/facturas/facturarManual/borradorFactura";

const ModalBorradorFactura = ({ isOpen, setOpen, data }) => {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="dialog-gav"
      id="dialog-gav"
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <BorradorFactura setOpen={setOpen} data={data} />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ModalBorradorFactura);
