import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { API_SERVER } from "../../../utils/urlBack_End";
import saveFill from "@iconify/icons-eva/save-outline";
import { LocalizationProvider } from "@material-ui/lab";
import * as Yup from "yup";
import DateRangePicker from "@material-ui/lab/DateRangePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";

import { es } from "date-fns/locale";

import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogTitle,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";

const styles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
    marginBottom: 7,
    marginTop: 12,
  },
  gridContainer: {},
  Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  textField: {
    width: "100%",
    marginBottom: 18,
    marginTop: 15,
  },
  textFieldComentario: {
    marginBottom: 18,
    marginTop: 20,
  },
}));

export default function EditarVacaciones(props) {
  const {
    open,
    setOpen,
    editingAdmin,
    editingEmpleado,
    setEditingEmpleado,
    vacacion,
    setVacacion,
    empleado,
    titulo,
    setShowMessagePermiso,
    setColor,
    setMessage,
    setVacaciones,
    visualizaEmpleado,
    setVisualizaEmpleado,
    vacacionesDisponibles,
    setVacacionesSolicitadas,
    setVacacionesDisponibles,
  } = props;

  const [errorFecha, setErrorFecha] = useState(false);

  const newDate = new Date();

  const [date, setDate] = useState(
    vacacion?.fechaInicio === ""
      ? [
          new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate()
          ),
          newDate,
        ]
      : [
          new Date(
            vacacion.fechaInicio.split("/", 3)[0],
            parseInt(vacacion.fechaInicio.split("/", 3)[1]) - 1,
            vacacion.fechaInicio.split("/", 3)[2]
          ),
          new Date(
            parseInt(vacacion.fechaTermino.split("/", 3)[0]),
            parseInt(vacacion.fechaTermino.split("/", 3)[1]) - 1,
            parseInt(vacacion.fechaTermino.split("/", 3)[2])
          ),
        ]
  );
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const classes = styles();

  const handleClose = () => {
    setOpen(false);
  };

  const actualizar = () => {
    axios
      .get(`${API_SERVER}/empleados/vacaciones/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setVacaciones(response.data.vacaciones);
        setVacacionesSolicitadas(response.data.vacacionesSolicitadas);
        setVacacionesDisponibles(response.data.vacacionesDisponibles);
      });
  };

  const validarDiasSolicitados = (value) => {
    if (value > vacacionesDisponibles) return false;
    return true;
  };

  const LoginSchema = Yup.object().shape({
    motivo: Yup.string().required("Motivo requerido"),
    diasSolicitados: Yup.number()
      .min(1, "Los días solicitados deben ser mayor a 0")
      .required("Días Solicitados requerido")
      .test(
        "diasSolicitados test",
        "Los días solicitados no deben sobrepasar a los días disponibles",
        (value) => validarDiasSolicitados(value)
      ),
  });

  const formik = useFormik({
    initialValues: {
      motivo: vacacion.motivo,
      fechaInicio: vacacion.fechaInicio,
      fechaTermino: vacacion.fechaTermino,
      estado: vacacion.estado,
      comentario: vacacion.comentario,
      diasSolicitados: vacacion.diasSolicitados,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    touched,
    errors,
    values,
  } = formik;

  const obtenerFecha = (fecha) => {
    if (fecha.getMonth() + 1 < 10) {
      if (fecha.getDate() < 10) {
        return (
          fecha.getFullYear() +
          "/0" +
          (fecha.getMonth() + 1) +
          "/0" +
          fecha.getDate()
        );
      } else {
        return (
          fecha.getFullYear() +
          "/0" +
          (fecha.getMonth() + 1) +
          "/" +
          fecha.getDate()
        );
      }
    } else {
      if (fecha.getDate() < 10) {
        return (
          fecha.getFullYear() +
          "/" +
          (fecha.getMonth() + 1) +
          "/0" +
          fecha.getDate()
        );
      } else {
        return (
          fecha.getFullYear() +
          "/" +
          (fecha.getMonth() + 1) +
          "/" +
          fecha.getDate()
        );
      }
    }
  };

  const guardarDatos = () => {
    if (editingAdmin) {
      if (vacacion.estado === "PENDIENTE") {
        const holiday = {
          estado: values.estado,
          id: vacacion.id,
          comentario: values.comentario,
          refEmpleadoSolicita: empleado.rut,
          refEmpleadoAcepta: user.nombre + " " + user.apellidos,
        };
        axios
          .put(`${API_SERVER}/empleados/solicitudVacaciones`, holiday, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(function (response) {
            actualizar();
            setShowMessagePermiso(true);
            setColor("success");
            setMessage("Solicitud de Vacaciones Actualizada Correctamente");
            setOpen(false);
          });
      } else {
        setOpen(false);
      }
    } else {
      if (!errorFecha && getFieldProps("motivo").value !== "") {
        if (visualizaEmpleado) {
          setVisualizaEmpleado(false);
          setOpen(false);
          setVacacion({
            fechaInicio: "",
            fechaTermino: "",
            refEmpleado: empleado.rut,
            urlArchivo: null,
            motivo: "",
            comentario: "",
            empleadoAcepta: "",
            sucursal: "",
            diasSolicitados: "",
          });
        } else {
          if (editingEmpleado) {
            if (Number(values.diasSolicitados) > vacacionesDisponibles) {
              setShowMessagePermiso(true);
              setColor("error");
              setMessage(
                "Solo tienes disponible " + vacacionesDisponibles + " días"
              );
              setSubmitting(false);
            } else {
              const holiday = {
                id: vacacion.id,
                fechaInicio: obtenerFecha(date[0]),
                diasSolicitados: parseInt(
                  getFieldProps("diasSolicitados").value
                ),
                fechaTermino: obtenerFecha(date[1]),
                urlArchivo: null,
                motivo: getFieldProps("motivo").value,
              };
              axios
                .put(
                  `${API_SERVER}/empleados/vacaciones`,
                  {
                    ...holiday,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then(function (response) {
                  actualizar();
                  setOpen(false);
                  setVacacion({
                    fechaInicio: "",
                    fechaTermino: "",
                    refEmpleado: empleado.rut,
                    urlArchivo: null,
                    motivo: "",
                    comentario: "",
                    empleadoAcepta: "",
                    sucursal: "",
                    diasSolicitados: "",
                  });
                  setEditingEmpleado(false);
                  setShowMessagePermiso(true);
                  setColor("success");
                  setMessage(
                    "Solicitud de Vacaciones Actualizada Correctamente"
                  );
                });
            }
          } else {
            if (Number(values.diasSolicitados) > vacacionesDisponibles) {
              setShowMessagePermiso(true);
              setColor("error");
              setMessage(
                "Solo tienes disponible " + vacacionesDisponibles + " días"
              );
              setSubmitting(false);
            } else {
              const holiday = {
                fechaInicio: obtenerFecha(date[0]),
                fechaTermino: obtenerFecha(date[1]),
                refEmpleado: empleado.rut,
                urlArchivo: "",
                motivo: getFieldProps("motivo").value,
                comentario: "",
                empleadoAcepta: "",
                sucursal: sucursalObtenida.refSucursal,
                diasSolicitados: parseInt(
                  getFieldProps("diasSolicitados").value
                ),
              };
              axios
                .post(
                  `${API_SERVER}/empleados/vacaciones`,
                  {
                    ...holiday,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then(function (response) {
                  if (response.data === -2) window.location.reload();
                  actualizar();
                  setOpen(false);
                  setShowMessagePermiso(true);
                  setColor("success");
                  setMessage("Vacaciones solicitadas Correctamente");
                });
            }
          }
        }
      } else {
        setSubmitting(false);
      }
    }
  };

  const cerrar = () => {
    setVacacion({
      fechaInicio: "",
      fechaTermino: "",
      refEmpleado: empleado.rut,
      urlArchivo: null,
      motivo: "",
      comentario: "",
      empleadoAcepta: "",
      sucursal: "",
      diasSolicitados: "",
    });
    setVisualizaEmpleado(false);
    setEditingEmpleado(false);
    handleClose();
  };

  return (
    <>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  disabled={editingAdmin || visualizaEmpleado}
                  id="motivo"
                  label="Motivo"
                  fullWidth
                  {...getFieldProps("motivo")}
                  error={Boolean(touched.motivo && errors.motivo)}
                  helperText={touched.motivo && errors.motivo}
                />

                {editingAdmin || visualizaEmpleado ? (
                  <>
                    <TextField
                      required
                      margin="dense"
                      disabled={editingAdmin || visualizaEmpleado}
                      id="fechaInicio"
                      label="Fecha de Inicio"
                      fullWidth
                      {...getFieldProps("fechaInicio")}
                    />

                    <TextField
                      margin="dense"
                      disabled={editingAdmin || visualizaEmpleado}
                      id="fechaTermino"
                      label="Fecha de Término"
                      fullWidth
                      {...getFieldProps("fechaTermino")}
                    />

                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      disabled={editingAdmin || visualizaEmpleado}
                      id="diasSolicitados"
                      label={
                        editingAdmin || visualizaEmpleado
                          ? "Días Solicitados"
                          : "Días a Solicitar"
                      }
                      fullWidth
                      {...getFieldProps("diasSolicitados")}
                    />

                    {visualizaEmpleado ? (
                      <>
                        <TextField
                          margin="dense"
                          id="comentario"
                          label="Comentario"
                          disabled={visualizaEmpleado}
                          multiline
                          minRows={3}
                          maxRows={6}
                          fullWidth
                          {...getFieldProps("comentario")}
                        />

                        <TextField
                          margin="dense"
                          id="estado"
                          label="Estado"
                          disabled={visualizaEmpleado}
                          fullWidth
                          {...getFieldProps("estado")}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          margin="dense"
                          id="comentario"
                          label={
                            vacacion.estado === "PENDIENTE"
                              ? "Ingrese un Comentario"
                              : "Comentario"
                          }
                          disabled={
                            vacacion.estado === "PENDIENTE" ? false : true
                          }
                          multiline
                          minRows={3}
                          maxRows={6}
                          fullWidth
                          {...getFieldProps("comentario")}
                        />

                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            {vacacion.estado === "PENDIENTE"
                              ? "Seleccione Estado"
                              : "Estado"}
                          </InputLabel>
                          <Select
                            disabled={
                              vacacion.estado === "PENDIENTE" ? false : true
                            }
                            labelId="demo-simple-select-label"
                            label={
                              vacacion.estado === "PENDIENTE"
                                ? "Seleccione Estado"
                                : "Estado"
                            }
                            id="demo-simple-select"
                            value={getFieldProps("estado").value}
                            onChange={(e) =>
                              setFieldValue("estado", e.target.value)
                            }>
                            <MenuItem value={"PENDIENTE"}>PENDIENTE</MenuItem>
                            <MenuItem value={"ACEPTADO"}>ACEPTADO</MenuItem>
                            <MenuItem value={"RECHAZADO"}>RECHAZADO</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={es}>
                      <DateRangePicker
                        calendars={1}
                        disablePast
                        value={date}
                        onChange={(newValue) => {
                          let fechaInicio = new Date(newValue[0]);
                          let fechaFin =
                            newValue[1] === null
                              ? new Date(newValue[0])
                              : new Date(newValue[1]);
                          setDate([fechaInicio, fechaFin]);
                        }}
                        renderInput={(startProps, endProps) => {
                          if (startProps.error === true) {
                            startProps.helperText =
                              "Se Debe Ingresar una Fecha futura";
                          }
                          if (endProps.error === true) {
                            endProps.helperText =
                              "Se Debe Ingresar una Fecha futura";
                          }
                          return (
                            <React.Fragment>
                              <TextField
                                {...startProps}
                                label="Fecha Inicio"
                                helperText="dd/mm/yyyy"
                                className={classes.textField}
                              />
                              <Box sx={{ mx: 2 }}> A </Box>
                              <TextField
                                {...endProps}
                                label="Fecha Fin"
                                helperText="dd/mm/yyyy"
                                className={classes.textField}
                              />
                            </React.Fragment>
                          );
                        }}
                        onError={(e) =>
                          e[0] === null && e[1] === null
                            ? setErrorFecha(false)
                            : setErrorFecha(true)
                        }
                      />
                    </LocalizationProvider>
                    <TextField
                      required
                      margin="dense"
                      disabled={editingAdmin || visualizaEmpleado}
                      id="diasSolicitados"
                      label="Días a Solicitar"
                      fullWidth
                      {...getFieldProps("diasSolicitados")}
                      error={Boolean(
                        touched.diasSolicitados && errors.diasSolicitados
                      )}
                      helperText={
                        touched.diasSolicitados && errors.diasSolicitados
                      }
                    />
                  </>
                )}

                <DialogActions>
                  <LoadingButton onClick={() => cerrar()} color="error">
                    Cancelar
                  </LoadingButton>
                  {editingAdmin ? (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      startIcon={
                        vacacion.estado === "PENDIENTE" ? (
                          <Icon icon={saveFill} />
                        ) : (
                          false
                        )
                      }
                      loading={isSubmitting}>
                      {vacacion.estado === "PENDIENTE"
                        ? "Guardar Cambios"
                        : "Aceptar"}
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      startIcon={
                        visualizaEmpleado ? false : <Icon icon={saveFill} />
                      }
                      loading={isSubmitting}>
                      {visualizaEmpleado ? "Aceptar" : "Guardar Cambios"}
                    </LoadingButton>
                  )}
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
