import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Container,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import { API_SERVER } from "../../utils/urlBack_End";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";

import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";

import * as locales from "@material-ui/core/locale";
import axios from "axios";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "nombre", label: "Cliente", alignRight: false },
  { id: "empleado", label: "Empleado", alignRight: false },
  { id: "fechaCreacion", label: "Fecha Creación", alignRight: false },
  { id: "contratoFirmado", label: "Contrato Firmado", alignRight: false },
  { id: "estado", label: "Estado", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.estado.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.fechaCreacion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.contratoFirmado.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.nombreCliente.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.nombreEmpleado.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.apellidoEmpleado.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PlanesEmpleado(props) {
  const { value, empleado } = props;
  const navigate = useNavigate();
  const {
    empleado: user,
    sucursal: sucursalObtenida,
    rol: rolObtenido,
  } = useSelector((state) => state.auth.user);
  const sucursal = sucursalObtenida.refSucursal;
  const [locale] = useState("esES");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("nombre");
  const [filterName, setFilterName] = useState("");
  const [loadingTable, setLoadingTable] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [planes, setPlanes] = useState([]);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/planes/empleado/${sucursal}/${empleado.rut}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        let resultado = response.data;
        axios
          .get(
            `${API_SERVER}/empleados/empleadoPlan/${empleado.rut}/${sucursal}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.data === -2) window.location.reload();

            if (res.data && Array.isArray(res.data)) {
              res.data.forEach((t) => {
                resultado.push(t);
              });
            }
            if (isMounted.current) {
              setPlanes(resultado);
            }
          })
          .catch(() => {
            if (isMounted.current) {
              setPlanes(resultado);
            }
          });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingTable(false);
        }
      });
  }, [sucursal, rolObtenido, empleado.rut]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = planes.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - planes.length) : 0;

  const filteredUsers = applySortFilter(
    planes,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      {empleado?.rut !== undefined && value === 3 && (
        <>
          <Container>
            <Card>
              <Grid container direction="row" alignItems="center">
                <Grid item xs md>
                  <TablaToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  />
                </Grid>
                <Grid item xs md>
                  <Grid container direction="row-reverse">
                    <Grid item xs={12} md={6}>
                      <Container style={{ marginLeft: ".5rem" }}></Container>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {!loadingTable ? (
                    <Table>
                      <TablaHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={planes.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              id,
                              estado,
                              fechaCreacion,
                              contratoFirmado,
                              nombreCliente,
                              nombreEmpleado,
                              apellidoEmpleado,
                            } = row;
                            const isItemSelected =
                              selected.indexOf(nombreCliente) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    navigate("/dashboard/planes/" + row.id);
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    {nombreCliente}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  onClick={() => {
                                    navigate("/dashboard/planes/" + row.id);
                                  }}
                                  align="left"
                                >
                                  {nombreEmpleado + " " + apellidoEmpleado}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={() => {
                                    navigate("/dashboard/planes/" + row.id);
                                  }}
                                >
                                  {fechaCreacion === ""
                                    ? ""
                                    : moment(fechaCreacion).format(
                                        "DD/MM/YYYY"
                                      )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={() => {
                                    navigate("/dashboard/planes/" + row.id);
                                  }}
                                >
                                  <Label
                                    variant="ghost"
                                    color={
                                      (contratoFirmado === "NO" && "error") ||
                                      "success"
                                    }
                                  >
                                    {sentenceCase(contratoFirmado)}
                                  </Label>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={() => {
                                    navigate("/dashboard/planes/" + row.id);
                                  }}
                                >
                                  <Label
                                    variant="ghost"
                                    color={
                                      (estado === "INACTIVO" && "error") ||
                                      "success"
                                    }
                                  >
                                    {sentenceCase(estado)}
                                  </Label>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="500px"
                    />
                  )}
                </TableContainer>
              </Scrollbar>

              <ThemeProvider
                theme={(outerTheme) => createTheme(outerTheme, locales[locale])}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={planes.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </ThemeProvider>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}
