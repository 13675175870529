import React, { useState } from "react";
import {
  Button,
  DialogContent,
  Dialog,
  Tabs,
  Typography,
  Tab,
  Skeleton,
  Box,
  AppBar,
  Toolbar,
  Grid,
} from "@material-ui/core";
import ProcesoFacturacionPlanesMensuales from "./ProcesoFacturacionPlanesMensuales";
import ProcesoFacturacionPlanesAnuales from "./ProcesoFacturacionPlanesAnuales";
// import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { facturasApi } from "src/services/facturasApi";
import { LoadingButton } from "@material-ui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ProcesoFacturacionDialog(props) {
  const { open, setOpen } = props;

  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );

  const [value, setValue] = useState(0);

  const [desactivado, setDesactivado] = useState(false);
  const [recargandoActualizacionPlanes, setRecargandoActualizacionPlanes] =
    useState(false);

  const { data: planes, isSuccess: isSuccessplanes } =
    facturasApi.useGetProcesoFacturaPlanesQuery(sucursalObtenida.refSucursal, {
      pollingInterval: 60000 /* , skip: !toggleNotificationPanel */,
    });

  const {
    data: periodoFacturacion,
    refetch: recargarPeriodo,
    isLoading: isLoadingPeriodoFacturacion,
  } = facturasApi.useGetPeriodoFacturacionQuery(sucursalObtenida.refSucursal, {
    pollingInterval: 5000 /* , skip: !toggleNotificationPanel */,
  });

  const [actualizarPlanes] =
    facturasApi.useActualizarYeliminarProcesoFacturaMutation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRecargarPlanes = () => {
    setRecargandoActualizacionPlanes(true);
    actualizarPlanes(periodoFacturacion.id)
      .then(() => {})
      .catch((error) => {
        console.error("Error al actualizar planes facturación planes", error);
      })
      .finally(() => {
        setRecargandoActualizacionPlanes(false);
      });
  };

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <Dialog
      fullScreen
      open={open}
      //TransitionComponent={Transition}
      id="dialogProcesoFacturacion"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Button
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </Button>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Proceso de Facturación
          </Typography>
        </Toolbar>
      </AppBar>
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          borderBottom={1}
          borderColor="divider"
        >
          {/* Columna Izquierda */}
          <Grid item>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Facturación Planes Mensuales" {...a11yProps(0)} />
              <Tab label="Facturación Planes Anuales" {...a11yProps(1)} />
            </Tabs>
          </Grid>

          <Grid item marginRight={2}>
            <LoadingButton
              variant="contained"
              onClick={() => handleRecargarPlanes()}
              loading={recargandoActualizacionPlanes}
              disabled={desactivado || isLoadingPeriodoFacturacion}
            >
              Actualizar Planes
            </LoadingButton>
          </Grid>
        </Grid>
      </>

      <DialogContent style={{ height: "500px" }}>
        {periodoFacturacion !== null &&
        isSuccessplanes &&
        !recargandoActualizacionPlanes &&
        !isLoadingPeriodoFacturacion ? (
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <ProcesoFacturacionPlanesMensuales
                planesMensuales={planes.facturasMensuales}
                periodoFacturacion={periodoFacturacion}
                recargarPeriodo={recargarPeriodo}
                setDesactivado={setDesactivado}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProcesoFacturacionPlanesAnuales
                planesAnuales={planes.facturasAnuales}
                periodoFacturacion={periodoFacturacion}
                recargarPeriodo={recargarPeriodo}
                setDesactivado={setDesactivado}
              />
            </TabPanel>
          </Box>
        ) : (
          <Skeleton variant="rectangular" height={500} />
        )}
      </DialogContent>
    </Dialog>
  );
}
