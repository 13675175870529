import React from "react";
import { Box, Card, Divider, Grid, Typography } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  exportButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    fontSize: 48,
    color: "#0078D4",
  },
  detailsSection: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
}));

const TarjetaContrato = ({ handleClickOpen, empresa, datosSucursal }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Box className={classes.exportButton} onClick={handleClickOpen}>
        <Description className={classes.icon} />
        <Typography variant="subtitle1" color="textPrimary" component="p">
          Exportar Contrato
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.detailsSection}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Datos Taxtic
            </Typography>
            <Typography variant="body2">
              <strong>RUT:</strong>{" "}
              {formatRut(
                datosSucursal?.rutRazonSocial || "",
                RutFormat.DOTS_DASH
              ) || "N/A"}
            </Typography>
            <Typography variant="body2">
              <strong>Razón social:</strong>{" "}
              {capitalizarPrimeraLetra(datosSucursal?.razonSocial || "") ||
                "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Datos Cliente
            </Typography>
            <Typography variant="body2">
              <strong>RUT:</strong>{" "}
              {formatRut(empresa?.rut || "", RutFormat.DOTS_DASH) || "N/A"}
            </Typography>
            <Typography variant="body2">
              <strong>Razón social:</strong>{" "}
              {capitalizarPrimeraLetra(empresa?.nombre || "") || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default React.memo(TarjetaContrato);
