import React from "react";
import {
    Paper,
    Typography,
    Stack,
    FormControl,
    TextField,

} from "@material-ui/core";


export default function Comentarios(props) {
    const {
        valor,
        comentarios,
        setComentarios
    } = props;

    return (
        <>
            {valor === 5 && (
                <Paper style={{ height: (window.screen.height - 350), margin: "10px", overflow: "auto" }} >
                    <Typography align="center" variant="h3">Comentarios</Typography>
                    <Stack spacing={2}>


                        <FormControl fullWidth style={{ marginTop: "30px" }}>
                            <TextField
                                label="Comentarios"
                                multiline
                                rows={4}
                                value={comentarios}
                                onChange={(event) => {
                                    setComentarios(event.target.value)
                                }}
                            />
                        </FormControl>
                    </Stack>

                </Paper>

            )}
        </>

    );
}