import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Button
} from "@material-ui/core";
import { subtareasApi } from "src/services/subtareasApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";


export default function EditarSubTareaDialog(props) {
  const { open, setOpen, subTarea } = props;

  const [editarSubtarea] = subtareasApi.useUpdateSubtareaMutation();
  const [nombreSubTarea, setNombreSubTarea] = useState(subTarea.nombre);


  const handleClose = () => {
    setOpen(false);
  };

  const editar = () => {
    AlertaModal({
      title: 'editar la subtarea',
      confirmButtonText: 'Sí, editar',
      target: document.getElementById('dialogEditarSubtarea'),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          editarSubtarea({
            nombre: String(nombreSubTarea).toUpperCase(),
            idSubtarea: subTarea.id,
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: 'Subtarea',
                action: 'editada',
              }).fire()
              setOpen(false);

            })
            .catch(() => {
              AlertaToast({
                action: 'Error en el servidor',
                fullText: true,
                target: document.getElementById('dialogEditarSubtarea'),
              }).fire()
            })

        }
      })
  };

  const handleChangeNombreSubTarea = (event) => {
    setNombreSubTarea(event.target.value);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      id="dialogEditarSubtarea"
    >
      <DialogTitle>
        Nueva Subtarea
      </DialogTitle>
      <DialogContent>

        <Stack style={{ marginTop: "10px" }}>
          <TextField
            value={nombreSubTarea}
            onChange={handleChangeNombreSubTarea}
            label="Nombre Subtarea"
            variant="outlined"
          />

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          onClick={editar}
          autoFocus
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );

}
