import React, { useState } from "react";
import * as XLSX from "xlsx";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { Icon } from "@iconify/react";
import documentExport from "@iconify/icons-carbon/document-export";
import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";
export default function ExportToExcelSubTareas(props) {
  const {
    filename,
    sheetName,
    setShowAlert,
    setColor,
    setMessage,
    empleado,
    tipoEmpleado,
    sucursal,
  } = props;

  const [loadingExport, setLoadingExport] = useState(false);

  function secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    hour = hour < 10 ? "0" + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    var second = seconds % 60;
    second = second < 10 ? "0" + second : second;
    return hour + ":" + minute + ":" + Math.round(second);
  }

  const exportar = async () => {
    setLoadingExport(true);
    if (tipoEmpleado === "EMPLEADO") {
      let subTareasCompleto = [];
      axios
        .get(`${API_SERVER}/subtareas/tareasEmpleado/${sucursal}/${empleado}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          if (Array.isArray(response.data)) {
            let subTareas = response.data;

            subTareas.forEach((subTarea) => {
              let subTareaArray = [
                subTarea.nombre,
                subTarea.tipoTarea,
                subTarea.nombreEmpleado,
                subTarea.rutCliente,
                subTarea.nombreCliente,
                subTarea.nombreSubTarea,
                subTarea.fechaInicio,
                subTarea.fechaTermino,
                subTarea.estado,
                secondsToString(subTarea.tiempoTotalSubtarea),
              ];

              subTareasCompleto.push(subTareaArray);
            });
            let worksheet = XLSX.utils.aoa_to_sheet([
              [
                "Tarea",
                "Tipo Tarea",
                "Nombre Empleado",
                "Rut Cliente",
                "Nombre Cliente",
                "Nombre Sub Tarea",
                "Fecha Inicio",
                "Fecha Termino",
                "Estado",
                "Tiempo",
              ],
              ...subTareasCompleto,
            ]);
            let new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName);
            XLSX.writeFile(new_workbook, filename + ".xlsx");
            setLoadingExport(false);
          }
        });
    } else if (tipoEmpleado === "OTRO") {
      let subTareasCompleto = [];
      axios
        .get(`${API_SERVER}/subtareas/tareas/${sucursal}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          if (Array.isArray(response.data)) {
            let subTareas = response.data;

            subTareas.forEach((subTarea) => {
              let subTareaArray = [
                subTarea.nombre,
                subTarea.tipoTarea,
                subTarea.nombreEmpleado,
                subTarea.rutCliente,
                subTarea.nombreCliente,
                subTarea.nombreSubTarea,
                subTarea.fechaInicio,
                subTarea.fechaTermino,
                subTarea.estado,
                secondsToString(subTarea.tiempoTotalSubtarea),
              ];

              subTareasCompleto.push(subTareaArray);
            });
            let worksheet = XLSX.utils.aoa_to_sheet([
              [
                "Tarea",
                "Tipo Tarea",
                "Nombre Empleado",
                "Rut Cliente",
                "Nombre Cliente",
                "Nombre Sub Tarea",
                "Fecha Inicio",
                "Fecha Termino",
                "Estado",
                "Tiempo",
              ],
              ...subTareasCompleto,
            ]);
            let new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName);
            XLSX.writeFile(new_workbook, filename + ".xlsx");
            setLoadingExport(false);
          }
        });
    } else {
      setLoadingExport(false);
      if (setShowAlert !== undefined) {
        setColor("error");
        setMessage("No hay datos que exportar");
        setShowAlert(true);
      }
    }
  };

  return (
    <LoadingButton
      variant="contained"
      onClick={exportar}
      startIcon={<Icon icon={documentExport} />}
      style={{ minWidth: "170px" }}
      loading={loadingExport}>
      Exportar Subtareas
    </LoadingButton>
  );
}
