import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  createTheme,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from "@material-ui/core";
import { TablaHead, TablaToolbar } from "src/components/_dashboard/tablas";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { ThemeProvider } from "@material-ui/styles";
import * as locales from "@material-ui/core/locale";
import CustomTablePagination from "src/components/CustomTablePagination";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import RowAppGroup from "./rowAppGroup";

//---------------------------------------------------

const TablaClientesPorGrupo = ({
  title,
  subheader,
  rows,
  loading,
  tabIndex,
}) => {
  const [state, setState] = useState(INITIAL_STATE_TABLE);

  const handleFilterByName = (event) => {
    setState({ ...state, filterName: event.target.value });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const filasFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["grupo"];
    return applySortFilter(
      rows,
      getComparator(state.order, state.orderBy),
      state.filterName,
      propiedadesParaFiltrar
    );
  }, [rows, state.filterName, state.order, state.orderBy]);

  const emptyRows =
    state.currentPage > 0
      ? Math.max(
          0,
          (1 + state.currentPage) * state.rowsPerPageOptions - rows.length
        )
      : 0;

  const esSucursalNoEncontrada = filasFiltradas.length === 0;

  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  if (tabIndex !== 0) {
    return null;
  }

  return (
    <Card sx={{ height: "100%" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ height: "100%" }}
      >
        <Grid item>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <CardHeader title={title} subheader={subheader} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item></Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  xs={12}
                  md={8}
                  lg={8}
                >
                  <TablaToolbar
                    filterName={state.filterName}
                    onFilterName={handleFilterByName}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Scrollbar sx={{ height: "100%" }}>
            <TableContainer component={Paper}>
              {!loading ? (
                <Table aria-label="collapsible table">
                  <TablaHead
                    order={state.order}
                    orderBy={state.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={rows?.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filasFiltradas
                      .slice(
                        state.currentPage * state.rowsPerPageOptions,
                        state.currentPage * state.rowsPerPageOptions +
                          state.rowsPerPageOptions
                      )
                      .map((row, index) => (
                        <RowAppGroup key={index} row={row} />
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={7} />
                      </TableRow>
                    )}
                  </TableBody>
                  {esSucursalNoEncontrada && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={state.filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow>
                      <ThemeProvider
                        theme={(outerTheme) =>
                          createTheme(outerTheme, locales[state.locale])
                        }
                      >
                        <CustomTablePagination
                          count={filasFiltradas.length}
                          rowsPerPage={state.rowsPerPageOptions}
                          page={state.currentPage}
                          colSpan={9}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </ThemeProvider>
                    </TableRow>
                  </TableFooter>
                </Table>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="500px" />
              )}
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </Card>
  );
};

const TABLE_HEAD = [
  { id: "action", label: "", alignRight: false },
  { id: "grupo", label: "Grupo", alignRight: false },
  // { id: "icons", label: "", alignRight: false },
];

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "grupo",
  locale: "esES",
  filterName: "",
};

TablaClientesPorGrupo.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default React.memo(TablaClientesPorGrupo);
