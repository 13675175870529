import PropTypes from "prop-types";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { visuallyHidden } from "@material-ui/utils";
import { Card, Collapse } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CardPlan from "./CardPlan";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";

function createData(
  rut,
  nombre,
  email,
  telefono,
  direccion,
  comuna,
  contacto,
  plan
) {
  return {
    rut,
    nombre,
    email,
    telefono,
    direccion,
    comuna,
    contacto,
    plan,
  };
}

function createListData(data) {
  return data.map((value) =>
    createData(
      value.rut,
      value.nombre,
      value.email,
      value.telefono,
      value.direccion,
      value.comuna,
      value.contacto,
      value.plan.nombre
    )
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "rut",
    numeric: false,
    disablePadding: false,
    label: "RUT",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: false,
    label: "Cliente",
  },
  {
    id: "plan",
    numeric: false,
    disablePadding: false,
    label: "Plan",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "Orden Descendente" : "Orden Ascendente"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, selected, updateRows, rows } = props;

  const deleteSelected = () => {
    const rowsAux = rows.filter((value) => {
      return !selected.includes(value.rut + "" + value.plan.nombre);
    });

    updateRows(rowsAux);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} seleccionado
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Planes
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Eliminar">
          <IconButton onClick={deleteSelected}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
};

const CellPlan = (props) => {
  const { dataEmpresa, rut, open, nombrePlan } = props;

  return (
    <TableRow key={rut + "1"}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Información Plan
            </Typography>

            {
              <CardPlan
                extra={true}
                plan={
                  dataEmpresa.find(
                    (value) =>
                      value.rut === rut && value.plan.nombre === nombrePlan
                  ).plan
                }
              />
            }
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default function TableEmpresa(props) {
  const { updateDataEmpresa, dataEmpresa } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState(createListData(dataEmpresa));
  const [opened, setOpened] = useState([]);

  useEffect(() => {
    setRows(dataEmpresa);
  }, [dataEmpresa]);

  const updateRows = (data) => {
    updateDataEmpresa([...data]);
    setRows([...data]);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.rut + "" + n.plan.nombre);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, rut) => {
    const selectedIndex = selected.indexOf(rut);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rut);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClickOpened = (event, rut) => {
    const openedIndex = opened.indexOf(rut);
    let newOpened = [];

    if (openedIndex === -1) {
      newOpened = newOpened.concat(opened, rut);
    } else if (openedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (openedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (openedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, openedIndex),
        opened.slice(openedIndex + 1)
      );
    }

    setOpened(newOpened);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (rut) => selected.indexOf(rut) !== -1;
  const isOpened = (rut) => opened.indexOf(rut) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card>
      {" "}
      <Box sx={{ width: "100%" }}>
        <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, esES)}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              selected={selected}
              updateRows={updateRows}
              rows={rows}
            />
            <TableContainer>
              <Box sx={{ margin: 1 }}>
                <Typography variant="caption" gutterBottom component="div">
                  (Presionar sobre una fila para ver la información del plan)
                </Typography>
              </Box>

              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(
                        row.rut + "" + row.plan.nombre
                      );
                      const isItemOpened = isOpened(
                        row.rut + "" + row.plan.nombre
                      );
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <React.Fragment
                          key={row.rut + "" + row.plan.nombre + "Fragment" + index}
                        >
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.rut + "" + row.plan.nombre}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onClick={(event) =>
                                  handleClick(
                                    event,
                                    row.rut + "" + row.plan.nombre
                                  )
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              onClick={(event) =>
                                handleClickOpened(
                                  event,
                                  row.rut + "" + row.plan.nombre
                                )
                              }
                            >
                              {row.rut}
                            </TableCell>
                            <TableCell
                              onClick={(event) =>
                                handleClickOpened(
                                  event,
                                  row.rut + "" + row.plan.nombre
                                )
                              }
                            >
                              {row.nombre}
                            </TableCell>
                            <TableCell
                              onClick={(event) =>
                                handleClickOpened(
                                  event,
                                  row.rut + "" + row.plan.nombre
                                )
                              }
                            >
                              {row.plan.nombre}
                            </TableCell>
                          </TableRow>
                          <CellPlan
                            rut={row.rut}
                            nombrePlan={row.plan.nombre}
                            dataEmpresa={rows}
                            open={isItemOpened}
                          />
                        </React.Fragment>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </ThemeProvider>
      </Box>
    </Card>
  );
}
