import axios from "axios";
import { API_SERVER } from "src/utils/urlBack_End";

export const actualizarEstadoPagoTarea = async (datos) => {
  let error = false;
  await axios
    .put(`${API_SERVER}/pagos/facturaTareas/${datos.id}`, {
      metodoPago: datos.metodoPago,
      empleadoRecibePago: datos.empleadoRecibePago,
      estado: datos.estado,
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("token")
      }
    })
    .then(function (response) {
      error = response.data;
    });
  return error;
};

export const actualizarEstadoPagoPlan = async (datos) => {
  let error = false;
  await axios
    .put(`${API_SERVER}/pagos/facturaPlanes/${datos.id}`, {
      metodoPago: datos.metodoPago,
      empleadoRecibePago: datos.empleadoRecibePago,
      estado: datos.estado
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("token")
      }
    })
    .then(function (response) {
      error = response.data;
    });
  return error;
};

export const crearAbonoPagoPlan = async (datos) => {
  let error = false;
  await axios
    .post(
      `${API_SERVER}/pagos/abonosFacturasPlanes`,
      {
        metodoPago: datos.metodoPago,
        empleadoRecibeAbono: datos.empleadoRecibeAbono,
        refIdPago: datos.refIdPago,
        valorAbono: datos.valorAbono,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(function (response) {
      if (response.data === -2) window.location.reload();
      error = response.data;
    });
  return error;
};
