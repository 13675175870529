import PropTypes from "prop-types";
import useChart from "../charts/use-chart";
import { Box, Card, CardHeader, Skeleton } from "@material-ui/core";
import Chart from "../charts/chart";

AppCrecimientoClientes.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chart: PropTypes.object,
  loading: PropTypes.bool,
};

export default function AppCrecimientoClientes({
  title,
  subheader,
  chart,
  loading,
  ...other
}) {
  const { labels, colors, series, options } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: "16%",
      },
    },
    fill: {
      type: series.map((i) => i.fill),
    },
    labels,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value, { seriesIndex }) => {
          if (typeof value !== "undefined") {
            if (seriesIndex === 0) {
              return `${value.toFixed(0)} clientes`;
            } else if (seriesIndex === 1) {
              return `${value.toFixed(0)}%`;
            }
          }
          return value;
        },
      },
    },
    ...options,
  });

  if (loading) {
    return (
      <Card>
        <CardHeader
          title={<Skeleton width={150} />}
          subheader={<Skeleton width={100} />}
        />
        <Box sx={{ p: 3, pb: 1 }}>
          <Skeleton variant="rectangular" width="100%" height={364} />
        </Box>
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Chart
          type="line"
          series={series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
