import React, { useEffect, useState, useRef } from "react";
import { AppBar, Tabs, Tab, Container } from "@material-ui/core";
import FacturasTareasEmpleado from "./FacturasTareasEmpleado";
import FacturasPlanesEmpleado from "./FacturasPlanesEmpleado";

export default function FacturasEmpleado(props) {
  const { value, empleado, setShowErrorMessage, setMessage } = props;
  const [valueFactura, setValueFactura] = useState(0);
  const isMounted = useRef(true);

  const handleChange = (event, newValue) => {
    setValueFactura(newValue);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {empleado?.rut !== undefined && value === 4 && (
        <>
          <Container>
            <div>
              <AppBar position="static" color="default">
                <Tabs
                  value={valueFactura}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="full width tabs example"
                >
                  <Tab label="Facturas Por Planes" />
                  <Tab label="Facturas Por Tareas" />
                </Tabs>
              </AppBar>
            </div>

            <FacturasTareasEmpleado
              valueFactura={valueFactura}
              empleado={empleado}
            />

            <FacturasPlanesEmpleado
              valueFactura={valueFactura}
              empleado={empleado}
              setShowErrorMessage={setShowErrorMessage}
              setMessage={setMessage}
            />
          </Container>
        </>
      )}
    </>
  );
}
