import React, { useState, useEffect } from "react";
import { API_SERVER, ACCESS_TOKEN } from "../../../utils/urlBack_End";
// material
import {
  Card,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import { TemplateHandler } from "easy-template-x";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { useSelector } from "react-redux";
import { AlertaToast } from "../../AlertaSwall";
import { LoadingButton } from "@material-ui/lab";
import { fNumber } from "src/utils/formatNumber";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { sucursalesApi } from "src/services/sucursalesApi";
import TarjetaContrato from "./TarjetaContrato";
import TarjetaContratoSkeleton from "./TarjetaContratoSkeleton";

function saveFile(filename, blob) {
  const blobUrl = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.download = filename;
  link.href = blobUrl;

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
    link = null;
  }, 0);
}

export default function VerPlanArchivo(props) {
  const { plan } = props;
  const { sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const { data = [], isLoading: cargandoDatosSucursal } =
    sucursalesApi.useGetSucursalIDQuery(sucursal);

  const [datosSucursal] = data;

  const [relacionPlanes, setRelacionPlanes] = useState([]);
  const [open, setOpen] = useState(false);

  const [nombreCliente, setNombreCliente] = useState("");
  const [rutCliente, setRutCliente] = useState("");

  const [empresa, setEmpresa] = useState(null);

  const [representantes, setRepresentantes] = useState([]);

  const [cargandoDocumento, setCargandoDocumento] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/planes/relacionPlanCliente/${plan.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response2) {
        if (response2.data === -2) window.location.reload();
        setRelacionPlanes(response2.data);
      })
      .catch((err) => {});
  }, [plan.id]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/clientes/${plan.refCliente}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response2) {
        if (response2.data === -2) window.location.reload();
        setEmpresa(response2.data);
      })
      .catch((err) => {});
  }, [plan.refCliente]);

  const generateDocument = async () => {
    setCargandoDocumento(true);
    if (representantes.length <= 0) {
      AlertaToast({
        action: "Se deben completar los datos",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogContrato"),
      }).fire();
      return;
    }
    let dropboxEndpoint1 = "https://content.dropboxapi.com/2/files/download";
    let dropBoxApiArg = { path: "/Archivos/Contrato.docx" };
    let postOptions1 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Dropbox-API-Arg": JSON.stringify(dropBoxApiArg),
      },
    };
    const response = await fetch(dropboxEndpoint1, postOptions1);
    const templateFile = await response.blob();
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let yyyy = today.getFullYear();

    let planesSubir = [];
    relacionPlanes.forEach((element) => {
      let frecuencia = "";
      if (element.tipoFrecuencia === "MES") {
        frecuencia = "mensual";
      } else if (element.tipoFrecuencia === "AÑO") {
        frecuencia = "anual";
      }

      planesSubir.push({
        nombrePlan: element.refTipoPlan,
        descripcionPlan: element.descripcionPlan,
        tarifaServicio:
          element.tipoMoneda === "PESOS" || element.tipoMoneda === "DOLAR"
            ? fNumber(element.valor)
            : element.valor,
        tipoMoneda:
          element.tipoMoneda === "DOLAR"
            ? "USD"
            : element.tipoMoneda === "PESOS"
            ? "CLP"
            : element.tipoMoneda,
        frecuencia: frecuencia,
        porPersona: element.refTipoPlan
          .toLowerCase()
          .includes("REMUNERACIONES".toLowerCase())
          ? "por trabajador"
          : null,
      });
    });

    let meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    today = dd + " de " + meses[today.getMonth()] + " de " + yyyy;
    const data = {
      ciudad: capitalizarPalabras(sucursalObtenida.comuna),
      fechaActual: today,
      nombreEmpresa: empresa.nombre,
      clientes: representantes,
      rutEmpresa: formatRut(empresa.rut, RutFormat.DOTS_DASH),
      domicilioCliente: empresa.direccion,
      comunaCliente: empresa.comuna,
      razonSocial: datosSucursal.razonSocial,
      rutRazonSocial: formatRut(
        datosSucursal.rutRazonSocial,
        RutFormat.DOTS_DASH
      ),
      representanteLegal: datosSucursal.representanteLegal,
      rutRepresentante: formatRut(
        datosSucursal.rutRepresentante,
        RutFormat.DOTS_DASH
      ),
      direccion: datosSucursal.direccion,
      comunaSucursal: datosSucursal.comuna,
      planes: planesSubir,
    };
    try {
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      const tituloDocumento = `Contrato cliente ${formatRut(
        plan.refCliente,
        RutFormat.DOTS_DASH
      )} ${sucursal}`;
      saveFile(`${tituloDocumento}.docx`, doc);
      setCargandoDocumento(false);
      setOpen(false);
      AlertaToast({
        action: "Documento generado",
        fullText: true,
        type: "success",
      }).fire();
    } catch (error) {
      setCargandoDocumento(false);
      AlertaToast({
        action: "Error al generar el documento",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogContrato"),
      }).fire();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRutCliente = (event) => {
    setRutCliente(event.target.value);
  };

  const handleChangeNombreCliente = (event) => {
    setNombreCliente(event.target.value);
  };

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          spacing={3}
          style={{ marginLeft: "25px", marginRight: "25px", marginTop: "30px" }}
        >
          {cargandoDatosSucursal ? (
            <TarjetaContratoSkeleton />
          ) : (
            <TarjetaContrato
              handleClickOpen={handleClickOpen}
              empresa={empresa}
              datosSucursal={datosSucursal}
            />
          )}
        </Stack>
      </Card>

      <Dialog open={open} onClose={handleClose} id="dialogContrato">
        <DialogTitle>Datos del Cliente para el Contrato</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={4} md={4}>
              <TextField
                autoFocus
                margin="dense"
                label="Rut Representante Legal"
                type="text"
                value={rutCliente}
                onChange={handleChangeRutCliente}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                margin="dense"
                label="Nombre Representante Legal"
                value={nombreCliente}
                onChange={handleChangeNombreCliente}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Button
                style={{ marginTop: 20 }}
                variant="contained"
                fullWidth
                onClick={() => {
                  if (rutCliente === "" || nombreCliente === "") {
                    AlertaToast({
                      action: "Ingresar rut y nombre del representante",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogContrato"),
                    }).fire();
                  } else {
                    const existeRepresentante = representantes.map(
                      (e) =>
                        e.rutCliente ===
                        formatRut(rutCliente, RutFormat.DOTS_DASH)
                    )[0];

                    if (existeRepresentante) {
                      AlertaToast({
                        action: "Ya se encuentra el rut ingresado.",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogContrato"),
                      }).fire();
                    } else {
                      setRutCliente("");
                      setNombreCliente("");
                      setRepresentantes([
                        ...representantes,
                        {
                          nombreCliente: nombreCliente,
                          rutCliente: formatRut(
                            rutCliente,
                            RutFormat.DOTS_DASH
                          ),
                        },
                      ]);
                    }
                  }
                }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Rut</TableCell>
                  <TableCell>Nombre</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {representantes.map((row) => (
                  <TableRow key={row.rutCliente}>
                    <TableCell>{row.rutCliente}</TableCell>
                    <TableCell>{row.nombreCliente}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancelar
          </Button>
          <LoadingButton
            loading={cargandoDocumento}
            onClick={generateDocument}
            color="primary"
          >
            Generar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
