import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const empleadosApi = createApi({
  reducerPath: "empleadosPlanApi",
  baseQuery: baseQuery(`${API_SERVER}/empleados`),
  tagTypes: [
    "Empleados",
    "EmpleadosPlan",
    "EmpleadosPropuesta",
    "EmpleadosTarea",
    "AtributosEmpleado",
  ],
  endpoints: (builder) => ({
    getEmpleados: builder.query({
      query: (id) => `/sucursal/${id}`,
      providesTags: ["Empleados"],
    }),
    obtenerEmpleadosActivosSucursal: builder.query({
      query: (rut) => `/empleadosActivosSucursal/${rut}`,
    }),
    cantidadEmpleados: builder.query({
      query: () => `/cantidadEmpleadosEmpresa`,
    }),
    getEmpleadosActivos: builder.query({
      query: ({ id, estado }) => `/empleadoSucursal/${id}/${estado}`,
      providesTags: ["Empleados"],
    }),
    getEmpleado: builder.query({
      query: (id) => `/empleados/${id}`,
      providesTags: ["Empleados"],
    }),
    getEmpleadosAdminSucursal: builder.query({
      query: (id) => `/administradores/${id}`,
    }),
    getEmpleadosPlan: builder.query({
      query: (id) => `/empleadoPlan/${id}`,
      providesTags: ["EmpleadosPlan"],
    }),
    getAtributosEmpleado: builder.query({
      query({ id, refSucursal }) {
        return {
          url: `/atributos/${id}`,
          method: "GET",
          params: {
            refSucursal,
          },
        };
      },
      providesTags: ["AtributosEmpleado"],
    }),
    addEmpleadosPlan: builder.mutation({
      query: (body) => ({
        url: `/empleadoPlan`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmpleadosPlan"],
    }),
    deleteEmpleadosPlan: builder.mutation({
      query: (id) => ({
        url: `/empleadoPlan/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmpleadosPlan"],
    }),
    getEmpleadosPropuesta: builder.query({
      query: (id) => `/empleadoPropuesta/${id}`,
      providesTags: ["EmpleadosPropuesta"],
    }),
    addEmpleadosPropuesta: builder.mutation({
      query: (body) => ({
        url: `/empleadoPropuesta`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmpleadosPropuesta"],
    }),
    addHistorialAcciones: builder.mutation({
      query: ({ user, body }) => ({
        url: `/historialAcciones/${user}`,
        method: "POST",
        body,
      }),
    }),
    deleteEmpleadosPropuesta: builder.mutation({
      query: (id) => ({
        url: `/empleadoPropuesta/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmpleadosPropuesta"],
    }),

    getEmpleadosTarea: builder.query({
      query: (id) => `/empleadoTarea/${id}`,
      providesTags: ["EmpleadosTarea"],
    }),
    addEmpleadosTarea: builder.mutation({
      query: (body) => ({
        url: `/empleadoTarea`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmpleadosTarea"],
    }),
    addEmpleado: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Empleados"],
    }),
    updateRelacionEmpleadoSucursal: builder.mutation({
      query: (body) => ({
        url: `/empleadoSucursal`,
        method: "PUT",
        body,
      }),
    }),
    updateAtributoEmpleado: builder.mutation({
      query: ({ id, refSucursal, atributo }) => ({
        url: `/atributos/${id}`,
        method: "PUT",
        body: {
          refSucursal,
          atributo,
        },
      }),
      invalidatesTags: ["AtributosEmpleado"],
    }),
    updateCambioContrasena: builder.mutation({
      query: (body) => ({
        url: `/cambioContrasena`,
        method: "PUT",
        body,
      }),
    }),
    addRelacionEmpleadoSucursal: builder.mutation({
      query: (body) => ({
        url: `/empleadoSucursal`,
        method: "POST",
        body,
      }),
    }),
    deleteRelacionEmpleadoSucursal: builder.mutation({
      query: ({ rut, sucursal, rol }) => ({
        url: `/empleadoSucursal/${rut}/${sucursal}/${rol}`,
        method: "DELETE",
      }),
    }),
    deleteEmpleadosTarea: builder.mutation({
      query: (id) => ({
        url: `/empleadoTarea/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmpleadosTarea"],
    }),
  }),
});

export const {
  useGetEmpleadosPlanQuery,
  useGetEmpleadosActivosQuery,
  useCantidadEmpleadosQuery,
  useObtenerEmpleadosActivosSucursalQuery,
  useLazyCantidadEmpleadosQuery,
  useLazyGetEmpleadosActivosQuery,
  useLazyGetEmpleadosPlanQuery,
  useLazyObtenerEmpleadosActivosSucursalQuery,
  useGetEmpleadosAdminSucursalQuery,
  useDeleteEmpleadosPlanMutation,
  useAddEmpleadosPlanMutation,
  useAddHistorialAccionesMutation,
  useGetEmpleadosQuery,
  useLazyGetEmpleadosQuery,
  useAddEmpleadoMutation,
  useUpdateRelacionEmpleadoSucursalMutation,
  useAddRelacionEmpleadoSucursalMutation,
  useDeleteRelacionEmpleadoSucursalMutation,
  useUpdateCambioContrasenaMutation,
  useGetAtributosEmpleadoQuery,
  useLazyGetAtributosEmpleadoQuery,
  useUpdateAtributoEmpleadoMutation,
  useGetEmpleadoQuery,
  useLazyGetEmpleadoQuery,
} = empleadosApi;
