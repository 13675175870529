import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/core/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_SERVER } from "../../utils/urlBack_End";

function createOptions(data) {
  const options = [];
  // eslint-disable-next-line array-callback-return
  data.map((value) => {
    options.push({ title: value.nombre });
  });

  return options;
}

function obtenerDataPlanOptions(option, data) {
  let dataPlan = {};
  // eslint-disable-next-line array-callback-return
  data.map((value) => {
    if (value.nombre === option) dataPlan = value;
  });

  return dataPlan;
}

export default function SelectPlan(props) {
  const { updateDataPlan, formik } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [dataPlan, setDataPlan] = useState([]);

  useEffect(() => {
    let active = true;
    let data = [];

    if (!loading) {
      return undefined;
    }

    (async () => {
      await axios
        .get(`${API_SERVER}/tipos/tipoPlanes`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
          }
        })
        .then(function (response) {
          if (response.data === -2) window.location.reload();
          setDataPlan(response.data);
          data = response.data;
        });

      if (active) {
        setOptions([...createOptions(data)]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 250 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      onChange={(event, newValue) => {
        if (newValue != null) {
          const data = obtenerDataPlanOptions(newValue.title, dataPlan);
          updateDataPlan(data);
          formik.setFieldValue("plan", newValue.title);
        } else {
          updateDataPlan({});
          formik.setFieldValue("plan", "");
        }
      }}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Seleccionar Plan"
          {...formik.getFieldProps("plan")}
          error={Boolean(formik.touched.plan && formik.errors.plan)}
          helperText={formik.touched.plan && formik.errors.plan}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
