import React, { useState, useEffect } from 'react';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    DialogTitle,
    Dialog,
    Skeleton,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { API_SERVER } from 'src/utils/urlBack_End';
import axios from 'axios';

export default function DialogVerEmpleadosActividad({
    open,
    setOpen,
    id,
}) {
    const [cargandoDatos, setCargandoDatos] = useState(false);
    const [empleados, setEmpleados] = useState(null);

    useEffect(() => {
        axios
            .get(`${API_SERVER}/calendarios/empleadosAsociados/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token")
                }
            }).then((resp) => {
                const empleadosAux = resp.data;
                setEmpleados(empleadosAux);
                setCargandoDatos(true)
            })
            .catch(() => {
                setEmpleados(null);
                setCargandoDatos(true)

            })

    }, [id])



    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Personas Asociadas</DialogTitle>
            {cargandoDatos ? (
                <List sx={{ pt: 0 }}>
                    {
                        empleados === null ? (
                            <ListItem>
                                <ListItemText primary="Error al obtener los datos" />
                            </ListItem>

                        ) : (
                            empleados.map((item, idx) => (
                                <ListItem
                                    key={`empleadosAsociados_${idx}`}
                                    secondaryAction={
                                        item.aceptado ? (
                                            <Typography
                                                color="primary"
                                            >
                                                {"Aceptado"}
                                            </Typography>

                                        ) : (
                                            <Typography
                                                color="error"
                                            >
                                                {"No Aceptado"}
                                            </Typography>

                                        )

                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${item.empleado.nombre} ${item.empleado.apellidos}`} />
                                </ListItem>
                            ))
                        )
                    }
                </List>


            ) : <Skeleton variant="rectangular" width="100%" height="200px" />}
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Cerrar
                </Button>
            </DialogActions>

        </Dialog>
    );
}