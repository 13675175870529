import React from "react";
import { Icon } from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";

import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { ajustesApi } from "src/services/ajustesApi";
import { clientesApi } from "src/services/clientesApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

export default function AgregarCliente(props) {
  const { open, setOpen } = props;

  const {
    data: comunas = []
  } = ajustesApi.useGetComunasQuery()

  const [agregarCliente] = clientesApi.useAddClienteMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Nombre del cliente requerido"),
    direccion: Yup.string().required("Dirección requerida"),
    comuna: Yup.string().required("Comuna requerida").nullable(),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      direccion: "",
      comuna: null,
      telefono: "",
      correo: "",
      contacto: "",
      comentarios: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const guardarDatos = () => {
    const cliente = {
      rut:
        deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
        deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
      nombre: getFieldProps("nombre").value.toUpperCase(),
      direccion: getFieldProps("direccion").value.toUpperCase(),
      comuna: getFieldProps("comuna").value.toUpperCase(),
      telefono: getFieldProps("telefono").value.toUpperCase(),
      correo: getFieldProps("correo").value,
      contacto: getFieldProps("contacto").value.toUpperCase(),
      comentarios: getFieldProps("comentarios").value.toUpperCase(),
    };

    AlertaModal({
      title: 'agregar el cliente',
      confirmButtonText: 'Sí, agregar',
      target: document.getElementById('dialogCrearCliente'),
    })
      .fire()
      .then((result) => {

        if (result.isConfirmed) {
          agregarCliente(cliente)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: 'Nuevo cliente',
                action: 'creado',
              }).fire()
              handleClose()
            }).catch(() => {
              AlertaToast({
                action: 'Error en el servidor o el cliente ya existe',
                fullText: true,
                type: 'error',
                target: document.getElementById('dialogCrearCliente'),
              }).fire()
            })

        }
      })
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearCliente"
        >
          <DialogTitle id="form-dialog-title">Clientes</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información del cliente
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="rut"
                  label="Rut"
                  fullWidth
                  {...getFieldProps("rut")}
                  error={Boolean(touched.rut && errors.rut)}
                  helperText={touched.rut && errors.rut}
                />
                <TextField
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />

                <TextField
                  required
                  margin="dense"
                  id="direccion"
                  label="Dirección"
                  fullWidth
                  {...getFieldProps("direccion")}
                  error={Boolean(touched.direccion && errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />
                <Autocomplete
                  style={{ marginTop: "10px" }}
                  {...getFieldProps("comuna")}
                  disablePortal
                  options={comunas}
                  isOptionEqualToValue={(option, value) => {
                    return option.nombre === value;
                  }}
                  getOptionLabel={(option) =>
                    option.nombre ? option.nombre : option
                  }
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setFieldValue("comuna", newValue.nombre, true);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.comuna && errors.comuna)}
                      fullWidth
                      helperText={touched.comuna && errors.comuna}
                      label="Seleccione Comuna"
                      name="comuna"
                      variant="outlined"
                    />
                  )}
                />

                <TextField
                  margin="dense"
                  id="telefono"
                  label="Teléfono"
                  fullWidth
                  {...getFieldProps("telefono")}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />
                <TextField
                  required
                  margin="dense"
                  id="correo"
                  label="Correo"
                  type="email"
                  fullWidth
                  {...getFieldProps("correo")}
                  error={Boolean(touched.correo && errors.correo)}
                  helperText={touched.correo && errors.correo}
                />

                <TextField
                  margin="dense"
                  id="contacto"
                  label="Contacto"
                  fullWidth
                  {...getFieldProps("contacto")}
                  error={Boolean(touched.contacto && errors.contacto)}
                  helperText={touched.contacto && errors.contacto}
                />

                <TextField
                  margin="dense"
                  id="comentarios"
                  label="comentarios"
                  multiline
                  maxRows={6}
                  fullWidth
                  {...getFieldProps("comentarios")}
                  error={Boolean(touched.comentarios && errors.comentarios)}
                  helperText={touched.comentarios && errors.comentarios}
                />
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="error"
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    startIcon={<Icon icon={saveFill} />}
                  >
                    Guardar Cliente
                  </Button>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
