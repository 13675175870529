import PropTypes from "prop-types";

// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Stack,
  Paper,
} from "@material-ui/core";
import { toLower, upperFirst } from "lodash";

CardPlan.propTypes = {
  plan: PropTypes.object.isRequired,
  index: PropTypes.number,
  extra: PropTypes.bool,
  factura: PropTypes.bool,
};

function obtenerFormatoMoneda(tipoMoneda, valor) {
  let precio = new Intl.NumberFormat("cl-ES").format(valor);
  let moneda = {
    UTM: precio + " UTM",
    PESOS: "$" + precio,
    UF: precio + " UF",
    default: "$" + precio,
  };

  return moneda[tipoMoneda];
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CardPlan(props) {
  const { plan, extra, factura } = props;
  const { valor, tipoMoneda, descripcion } = plan;

  return (
    <Grid item xs={4} sm={7} md={10}>
      <Card sx={{ position: "relative", minWidth: 270 }}>
        <CardContent>
          {!extra ? (
            <Typography
              gutterBottom
              variant="caption  "
              sx={{
                color: "text.disabled",
                display: "block",
                paddingBottom: "0.75rem",
              }}
            >
              {upperFirst(toLower(descripcion))}
            </Typography>
          ) : (
            <></>
          )}

          {extra && !factura ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={3}
            >
              <Item>
                <Typography
                  gutterBottom
                  variant="body2"
                  sx={{ color: "text.disabled", display: "block" }}
                >
                  Frecuencia
                </Typography>
              </Item>
              <Item>
                <Typography
                  gutterBottom
                  variant="body2"
                  sx={{ color: "text.disabled", display: "block" }}
                >
                  {plan.frecuencia +
                    " " +
                    upperFirst(toLower(plan.tipoFrecuencia))}
                </Typography>
              </Item>
            </Stack>
          ) : (
            <></>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <Item>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ color: "text.disabled", display: "block" }}
              >
                Precio (Referencial)
              </Typography>
            </Item>
            <Item>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ color: "text.disabled", display: "block" }}
              >
                {obtenerFormatoMoneda(tipoMoneda, valor)}
              </Typography>
            </Item>
          </Stack>

          {extra ? (
            <>
              {!factura && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Item>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "text.disabled", display: "block" }}
                    >
                      Precio Fijo
                    </Typography>
                  </Item>
                  <Item>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "text.disabled", display: "block" }}
                    >
                      {obtenerFormatoMoneda(
                        plan.tipoMonedaFinal,
                        plan.precioFijoFinal
                      )}
                    </Typography>
                  </Item>
                </Stack>
              )}
              {plan.descuentoFinal > 0 ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Item>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "red", display: "block" }}
                    >
                      Descuento
                    </Typography>
                  </Item>
                  <Item>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "red", display: "block" }}
                    >
                      {plan.descuentoFinal + "%"}
                    </Typography>
                  </Item>
                </Stack>
              ) : (
                <></>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
              >
                <Item>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    sx={{ color: "Green" }}
                  >
                    Precio Final
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    sx={{ color: "Green" }}
                  >
                    {obtenerFormatoMoneda(
                      plan.tipoMonedaFinal,
                      plan.precioFinal
                    )}
                  </Typography>
                </Item>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
