import {
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Edit,
  Delete,
} from "@material-ui/icons";
import { TablaHead, TablaToolbar } from "../_dashboard/tablas";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "../SearchNotFound";
import * as locales from "@material-ui/core/locale";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { applySortFilter, getComparator } from "src/utils/sharedTables";
import { categoriasApi } from "src/services/categoriasApi";
import { AlertaModal, AlertaToast } from "../AlertaSwall";

const FilaTabla = ({ row, setStateModal, setRowSelected, statesModal }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(INITIAL_STATE_TABLE);
  const [indexLoadingDelete, setIndexLoadingDelete] = useState(null);
  const [eliminarCategoria] = categoriasApi.useEliminarCategoriaMutation();
  const [eliminarSubcategoria] =
    categoriasApi.useEliminarSubcategoriaMutation();

  const handleClickEditarCategoria = useCallback(
    (row) => {
      setRowSelected(row);
      setStateModal({
        ...statesModal,
        abrirModal: true,
        seAgregaCategoria: false,
        type: 0,
      });
    },
    [setRowSelected, setStateModal, statesModal]
  );

  const handleClickEditarSubcategoria = useCallback(
    (row) => {
      setRowSelected(row);
      setStateModal({
        ...statesModal,
        abrirModal: true,
        seAgregaCategoria: false,
        type: 1,
      });
    },
    [setRowSelected, setStateModal, statesModal]
  );

  const handleClickAgregarSubcategoria = useCallback(
    (row) => {
      setRowSelected(row);
      setStateModal({
        ...statesModal,
        abrirModal: true,
        seAgregaCategoria: true,
        type: 1,
      });
    },
    [setRowSelected, setStateModal, statesModal]
  );

  const handleClickDeleteCategoria = (item) => {
    setIndexLoadingDelete(item.id);
    AlertaModal({
      title: "Eliminar categoría",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (!result.isConfirmed) {
          setIndexLoadingDelete(null);
          return;
        }
        const body = {
          id: item.id,
        };
        eliminarCategoria(body)
          .then((response) => {
            if (response.data.filasBorradas > 0) {
              AlertaToast({
                action: "Categoría eliminada correctamente",
                fullText: true,
              }).fire();
              setIndexLoadingDelete(null);
            } else {
              AlertaToast({
                action: "No se ha eliminado ninguna categoría",
                fullText: true,
                type: "warning",
              }).fire();
              setIndexLoadingDelete(null);
            }
          })
          .catch((error) => {
            console.error(error);
            AlertaToast({
              action: "No se pudo eliminar la categoría. Intente nuevamente",
              fullText: true,
              type: "error",
            }).fire();
            setIndexLoadingDelete(null);
          });
      });
  };

  const handleClickDeleteSubcategoria = (item) => {
    setIndexLoadingDelete(item.id);
    AlertaModal({
      title: "Eliminar subcategoría",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (!result.isConfirmed) {
          setIndexLoadingDelete(null);
          return;
        }
        const id = item.id;
        eliminarSubcategoria(id)
          .then((response) => {
            if (response.data.filasBorradas > 0) {
              AlertaToast({
                action: "Subcategoría eliminada correctamente",
                fullText: true,
              }).fire();
              setIndexLoadingDelete(null);
            } else {
              AlertaToast({
                action: "No se ha eliminado ninguna subcategoría",
                fullText: true,
                type: "warning",
              }).fire();
              setIndexLoadingDelete(null);
            }
          })
          .catch((error) => {
            console.error(error);
            AlertaToast({
              action: "No se pudo eliminar la subcategoría. Intente nuevamente",
              fullText: true,
              type: "error",
            }).fire();
            setIndexLoadingDelete(null);
          });
      });
  };

  const handleClickExpandRow = () => {
    setOpen(!open);
  };

  const handleFilterByName = (event) => {
    setState({ ...state, filterName: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPageOptions: Number(event.target.value),
      currentPage: 0,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const sucursalesFiltradas = useMemo(() => {
    const propiedadesParaFiltrar = ["nombre"];
    return applySortFilter(
      row.subcategorias,
      getComparator(state.order, state.orderBy),
      state.filterName,
      propiedadesParaFiltrar
    );
  }, [row.subcategorias, state.filterName, state.order, state.orderBy]);

  const esSucursalNoEncontrada = sucursalesFiltradas.length === 0;

  const emptyRows =
    state.currentPage > 0
      ? Math.max(
          0,
          (1 + state.currentPage) * state.rowsPerPageOptions -
            row.subcategorias.length
        )
      : 0;

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClickExpandRow.bind(null)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {row.nombre}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid item xs={12} md container justifyContent="flex-end">
            <Tooltip title="Editar categoría">
              <Button
                color="primary"
                onClick={handleClickEditarCategoria.bind(null, row)}
              >
                <Edit />
              </Button>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton
                color="error"
                onClick={handleClickDeleteCategoria.bind(null, row)}
              >
                {indexLoadingDelete === row.id ? (
                  <CircularProgress size={20} />
                ) : (
                  <Delete />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs="auto">
                <TablaToolbar
                  filterName={state.filterName}
                  onFilterName={handleFilterByName}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" align="center">
                  Subcategorías
                </Typography>
              </Grid>
              <Grid item xs md textAlign="right" marginRight={2}>
                <Button
                  onClick={handleClickAgregarSubcategoria.bind(null, row)}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Agregar subcategoría
                </Button>
              </Grid>
            </Grid>
            <Scrollbar>
              <TableContainer>
                <Table size="small">
                  <TablaHead
                    order={state.order}
                    orderBy={state.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={row.subcategorias.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {sucursalesFiltradas
                      .slice(
                        state.currentPage * state.rowsPerPageOptions,
                        state.currentPage * state.rowsPerPageOptions +
                          state.rowsPerPageOptions
                      )
                      .map((item, index) => (
                        <TableRow hover key={item.id}>
                          <TableCell>
                            {state.currentPage * state.rowsPerPageOptions +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {item.nombre}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Grid
                              item
                              xs={12}
                              md
                              container
                              justifyContent="flex-end"
                            >
                              <Tooltip title="Editar subcategoría">
                                <Button
                                  color="primary"
                                  onClick={handleClickEditarSubcategoria.bind(
                                    null,
                                    item
                                  )}
                                >
                                  <Edit />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  color="error"
                                  onClick={handleClickDeleteSubcategoria.bind(
                                    null,
                                    item
                                  )}
                                >
                                  {indexLoadingDelete === item.id ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Delete />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={3} />
                      </TableRow>
                    )}
                  </TableBody>
                  {esSucursalNoEncontrada && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={state.filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <ThemeProvider
                theme={(outerTheme) =>
                  createTheme(outerTheme, locales[state.locale])
                }
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={sucursalesFiltradas.length}
                  rowsPerPage={state.rowsPerPageOptions}
                  page={state.currentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </ThemeProvider>
            </Scrollbar>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TABLE_HEAD = [
  { id: "position", label: "N°", alignRight: false },
  { id: "nombre", label: "Nombre", alignRight: false },
  { id: "" },
];

const INITIAL_STATE_TABLE = {
  currentPage: 0,
  rowsPerPageOptions: 5,
  order: "asc",
  orderBy: "nombre",
  locale: "esES",
  filterName: "",
};

export default FilaTabla;
