import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import SimpleModal from "../../../layouts/SimpleModal";
import { toPascalCase } from "../../../utils/converter";
import { TextField } from "@material-ui/core";

export default function ModifyTaskStateModal(props) {
  const { task, onSubmit, onClose, states, open, cargandoDatos, tipoEstado } =
    props;

  const [value, setValue] = useState(`${states.indexOf(task.estado)}`);
  const [estadoActual, setEstadoActual] = useState("");
  const [rutReal, setRutReal] = useState("");

  useEffect(() => {
    setValue(`${states.indexOf(task.estado)}`);
  }, [task, states]);

  const handleChange = (event) => {
    setEstadoActual(states[event.target.value]);
    setValue(event.target.value);
  };

  return (
    <>
      <SimpleModal
        open={open}
        onClose={() => onClose()}
        onSubmit={() => onSubmit(states[value], rutReal)}
        title="Cambiar Estado"
        cargandoDatos={cargandoDatos}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Estado</FormLabel>
          <RadioGroup
            aria-label="sucursales"
            name="sucursal"
            onChange={handleChange}
            value={value}
          >
            {states.map((s, idx) => (
              <FormControlLabel
                control={<Radio />}
                key={idx}
                label={`${toPascalCase(s)}`}
                value={`${idx}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {tipoEstado &&
          tipoEstado === "PROPUESTA" &&
          estadoActual === "ACEPTADA" &&
          (task.rutAuxiliar === "1" || task.rutAuxiliar) && (
            <TextField
              required
              fullWidth
              value={rutReal}
              label="Rut Empresa Real"
              onChange={(e) => {
                setRutReal(e.target.value);
              }}
            />
          )}
      </SimpleModal>
    </>
  );
}
