import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faFileSignature,
  faFileInvoice,
  faUsers,
  faAddressBook,
  faStoreAlt,
  faCog,
  faCalendar,
  faChartBar,
  faThList,
  faPlus,
  faIdCard,
  faChartPie,
  faChartLine,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <FontAwesomeIcon icon={name} width={22} height={22} />
);

const sidebarConfig = [
  {
    title: "estadísticas",
    path: "/dashboard/estadisticas",
    icon: getIcon(faChartLine),
  },
  {
    title: "inicio",
    path: "/dashboard/inicio",
    icon: getIcon(faHome),
  },
  {
    title: "nueva tarea",
    path: "/dashboard/nueva-tarea",
    icon: getIcon(faPlus),
  },
  {
    title: "tareas",
    path: "/dashboard/tareas",
    icon: getIcon(faList),
  },
  {
    title: "propuestas",
    path: "/dashboard/propuestas",
    icon: getIcon(faThList),
  },
  {
    title: "planes",
    path: "/dashboard/planes",
    icon: getIcon(faFileSignature),
  },
  {
    title: "facturación",
    path: "/dashboard/facturacion",
    icon: getIcon(faFileInvoice),
  },
  {
    title: "clientes",
    path: "/dashboard/clientes",
    icon: getIcon(faAddressBook),
  },
  {
    title: "grupos clientes",
    path: "/dashboard/grupos-clientes",
    icon: getIcon(faUserFriends),
  },
  {
    title: "área laboral",
    path: "/dashboard/area-laboral",
    icon: getIcon(faIdCard),
  },
  {
    title: "empleados",
    path: "/dashboard/empleados",
    icon: getIcon(faUsers),
  },
  {
    title: "sucursales",
    path: "/dashboard/sucursales",
    icon: getIcon(faStoreAlt),
  },
  {
    title: "liquidación",
    path: "/dashboard/liquidacion",
    icon: getIcon(faChartPie),
  },
  {
    title: "configuraciones",
    path: "/dashboard/configuraciones",
    icon: getIcon(faCog),
  },
  {
    title: "informes",
    path: "/dashboard/informes",
    icon: getIcon(faChartBar),
  },
  {
    title: "agenda",
    path: "/dashboard/agenda",
    icon: getIcon(faCalendar),
  },
  {
    title: "login",
    path: "/login",
    icon: getIcon(faUsers),
  },
];

export default sidebarConfig;
