import { styled, useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import { fNumber } from "src/utils/formatNumber";
import { Card, CardHeader, Skeleton } from "@material-ui/core";
import useChart from "../charts/use-chart";
import Chart from "../charts/chart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 331;

const LEGEND_HEIGHT = 72;

const StyledChart = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": {
    height: CHART_HEIGHT,
  },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppDetalleClientes.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
  subheader: PropTypes.string,
};

export default function AppDetalleClientes({
  title,
  subheader,
  total,
  chart,
  loading,
  ...other
}) {
  const theme = useTheme();

  const { colors, series, options } = chart;

  const chartSeries = series.map((i) => i.value);

  const chartColors = colors || [
    [theme.palette.primary.light, theme.palette.primary.main],
    [theme.palette.warning.light, theme.palette.warning.main],
    [theme.palette.secondary.light, theme.palette.secondary.main],
  ];

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    labels: series.map((i) => i.label),
    legend: {
      floating: true,
      horizontalAlign: "center",
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: chartColors.map((colr) => [
          { offset: 0, color: colr[0] },
          { offset: 100, color: colr[1] },
        ]),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "58%" },
        dataLabels: {
          value: { offsetY: 16 },
          total: {
            formatter: () => fNumber(total),
          },
        },
      },
    },
    ...options,
  });

  if (loading) {
    return (
      <Card {...other}>
        <CardHeader title={<Skeleton variant="text" />} />
        <Skeleton variant="rectangular" height={390} />
      </Card>
    );
  }

  return (
    <Card sx={{height: 1}} {...other}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChart dir="ltr">
        <Chart
          type="radialBar"
          series={chartSeries}
          options={chartOptions}
          height={231}
        />
      </StyledChart>
    </Card>
  );
}
