import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Alert,
  IconButton,
  Grid,
  Snackbar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import { PDFDocument } from 'pdf-lib';
import { saveAs } from "file-saver";

const styles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  Container: {
    marginTop: 40,
  },
  space: {
    marginTop: 10,
  },
}));

export default function UnirPDF(props) {
  const { open, setOpen, cliente } = props;

  const classes = styles();

  const [loadingModificar, setLoadingModificar] = useState(false);
  const [archivo, setArchivo] = useState(null);
  const [archivoSubido, setArchivoSubido] = useState(null);
  const [archivos, setArchivos] = useState([]);

  const [mostrarAlerta, setMostrarAlerta] = useState(null);


  const handleDeleteFile = () => {
    setArchivo(null);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file)
    })
  }

  const guardarDatos = async () => {
    setLoadingModificar(true);
    if (archivos && archivos.length <= 0) {
      setLoadingModificar(false);
      setMostrarAlerta("No se ha seleccionado ningún archivo.")
      return;
    }

    if (archivos.length === 1) {
      setMostrarAlerta("No se puede unir un sólo archivo.")
      setLoadingModificar(false);
      return;
    }
    else if (archivos.length === 2) {
      const in1 = archivos[0];
      const in2 = archivos[1];
      let bytes1 = await readFileAsync(in1);
      let bytes2 = await readFileAsync(in2);
      const pdf1 = await PDFDocument.load(bytes1, { ignoreEncryption: true });
      const pdf2 = await PDFDocument.load(bytes2, { ignoreEncryption: true });

      const mergedPdf = await PDFDocument.create();
      const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
      copiedPagesA.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
      copiedPagesB.forEach((page) => mergedPdf.addPage(page));
      const mergedPdfFile = await mergedPdf.save();
      let blob = new Blob([mergedPdfFile], { type: "application/pdf" });

      saveAs(blob, "Poder_" + cliente.rut + ".pdf");
      setLoadingModificar(false);
      handleClose();


    }
    else if (archivos.length === 3) {
      const in1 = archivos[0];
      const in2 = archivos[1];
      const in3 = archivos[2];
      let bytes1 = await readFileAsync(in1);
      let bytes2 = await readFileAsync(in2);
      let bytes3 = await readFileAsync(in3);
      const pdf1 = await PDFDocument.load(bytes1, { ignoreEncryption: true });
      const pdf2 = await PDFDocument.load(bytes2, { ignoreEncryption: true });
      const pdf3 = await PDFDocument.load(bytes3, { ignoreEncryption: true });

      const mergedPdf = await PDFDocument.create();
      const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
      copiedPagesA.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
      copiedPagesB.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesC = await mergedPdf.copyPages(pdf3, pdf3.getPageIndices());
      copiedPagesC.forEach((page) => mergedPdf.addPage(page));
      const mergedPdfFile = await mergedPdf.save();
      let blob = new Blob([mergedPdfFile], { type: "application/pdf" });

      saveAs(blob, "Poder_" + cliente.rut + ".pdf");
      setLoadingModificar(false);
      handleClose();

    }
    else if (archivos.length === 4) {
      const in1 = archivos[0];
      const in2 = archivos[1];
      const in3 = archivos[2];
      const in4 = archivos[3];
      let bytes1 = await readFileAsync(in1);
      let bytes2 = await readFileAsync(in2);
      let bytes3 = await readFileAsync(in3);
      let bytes4 = await readFileAsync(in4);
      const pdf1 = await PDFDocument.load(bytes1, { ignoreEncryption: true });
      const pdf2 = await PDFDocument.load(bytes2, { ignoreEncryption: true });
      const pdf3 = await PDFDocument.load(bytes3, { ignoreEncryption: true });
      const pdf4 = await PDFDocument.load(bytes4, { ignoreEncryption: true });

      const mergedPdf = await PDFDocument.create();
      const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
      copiedPagesA.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
      copiedPagesB.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesC = await mergedPdf.copyPages(pdf3, pdf3.getPageIndices());
      copiedPagesC.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesD = await mergedPdf.copyPages(pdf4, pdf4.getPageIndices());
      copiedPagesD.forEach((page) => mergedPdf.addPage(page));
      const mergedPdfFile = await mergedPdf.save();
      let blob = new Blob([mergedPdfFile], { type: "application/pdf" });

      saveAs(blob, "Poder_" + cliente.rut + ".pdf");
      setLoadingModificar(false);
      handleClose();

    }
    else if (archivos.length === 5) {
      const in1 = archivos[0];
      const in2 = archivos[1];
      const in3 = archivos[2];
      const in4 = archivos[3];
      const in5 = archivos[4];
      let bytes1 = await readFileAsync(in1);
      let bytes2 = await readFileAsync(in2);
      let bytes3 = await readFileAsync(in3);
      let bytes4 = await readFileAsync(in4);
      let bytes5 = await readFileAsync(in5);
      const pdf1 = await PDFDocument.load(bytes1, { ignoreEncryption: true });
      const pdf2 = await PDFDocument.load(bytes2, { ignoreEncryption: true });
      const pdf3 = await PDFDocument.load(bytes3, { ignoreEncryption: true });
      const pdf4 = await PDFDocument.load(bytes4, { ignoreEncryption: true });
      const pdf5 = await PDFDocument.load(bytes5, { ignoreEncryption: true });

      const mergedPdf = await PDFDocument.create();
      const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
      copiedPagesA.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
      copiedPagesB.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesC = await mergedPdf.copyPages(pdf3, pdf3.getPageIndices());
      copiedPagesC.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesD = await mergedPdf.copyPages(pdf4, pdf4.getPageIndices());
      copiedPagesD.forEach((page) => mergedPdf.addPage(page));
      const copiedPagesE = await mergedPdf.copyPages(pdf5, pdf5.getPageIndices());
      copiedPagesE.forEach((page) => mergedPdf.addPage(page));
      const mergedPdfFile = await mergedPdf.save();
      let blob = new Blob([mergedPdfFile], { type: "application/pdf" });

      saveAs(blob, "Poder_" + cliente.rut + ".pdf");
      setLoadingModificar(false);
      handleClose();

    }
    else {
      setMostrarAlerta("Solo se pueden unir 5 archivos.")
      setLoadingModificar(false);

    }


  };

  const eliminarArchivo = (nombre) => {
    setArchivos(archivos.filter(e => e.nombreArchivo !== nombre))

  };

  const handleFileInput = (e) => {
    setArchivoSubido(true);
    const fileAux = e.target.files[0];
    let existe = false;
    if (fileAux === undefined) {
      setArchivoSubido(false);
      return;
    }
    const nombre = e.target.files[0].name;
    archivos.forEach((e) => {
      if (e.name === nombre) {
        existe = true;
      }

    })
    if (existe) {
      setArchivoSubido(false);
      return;


    }
    else {
      if (
        fileAux.type !==
        "application/pdf"
      ) {
        setArchivoSubido(false);
        return;
      }
      setArchivo(fileAux);
      setArchivos([...archivos, fileAux]);
      setArchivoSubido(false)
      setArchivo(null);
    }

  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Unir PDF</DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Container className={classes.Container}>
            <Alert severity="info">
              Haga click en el botón 'Seleccionar Archivo' para subir
              un archivo PDF. Máximo 5 archivos.
            </Alert>
          </Container>

          <Container className={classes.space}>
            <Grid container>
              <Grid item xs={5} md={5}>
                <input
                  accept="application/pdf"
                  className={classes.hidden}
                  id="contained-button-file"
                  onChange={handleFileInput}
                  type="file"
                />

                <label htmlFor={"contained-button-file"}>
                  <LoadingButton
                    loading={archivoSubido}
                    fullWidth
                    component="span"
                    m={1}
                    variant="contained"
                  >
                    Seleccionar Archivo

                  </LoadingButton>
                </label>

              </Grid>
              <Grid item xs={7} md={7}>
                {archivo && (
                  <span>
                    <span>
                      <> {archivo.name}</>
                    </span>

                    <span>
                      <IconButton
                        onClick={handleDeleteFile}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </span>
                )}

              </Grid>

            </Grid>


          </Container>
        </React.Fragment>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre Archivo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archivos && archivos.length >= 1 ? (
                archivos.map((e, index) => {

                  return (<TableRow
                    key={"archivos_" + index}
                  >
                    <TableCell component="th" scope="row">
                      {e.name}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          eliminarArchivo(e.name)
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>);


                })
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row">
                    No existen datos para mostrar.
                  </TableCell>
                </TableRow>

              )
              }

            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loadingModificar} onClick={handleClose}>
          Cerrar
        </LoadingButton>
        <LoadingButton
          loading={loadingModificar}
          onClick={guardarDatos}
          variant="contained"
        >
          Guardar
        </LoadingButton>
      </DialogActions>
      {mostrarAlerta && (
        <Snackbar
          onClose={() => {
            setMostrarAlerta(false)
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={mostrarAlerta}
          autoHideDuration={6000}>
          <Alert
            onClose={() => {
              setMostrarAlerta(false)
            }}
            severity="info"
            sx={{ width: '100%' }}
          >
            {mostrarAlerta}
          </Alert>
        </Snackbar>

      )

      }
    </Dialog>
  );
}
