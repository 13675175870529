import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  alpha,
} from "@material-ui/core";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import Iconify from "../iconify";
import { fNumber, formatAsCLP } from "src/utils/formatNumber";
import iconoTrofeo from "@iconify/icons-ant-design/trophy-filled";
import promedioPlanesIcono from "@iconify/icons-ant-design/solution-outline";
import iconoMoneda from "@iconify/icons-ant-design/file-done-outlined";
import {
  Assignment,
  Dashboard,
  Description,
  ListAlt,
  Work,
} from "@material-ui/icons";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";

function AppTopPlanSkeleton() {
  return (
    <Card sx={{ height: 1 }}>
      <CardHeader
        title={<Skeleton variant="text" width="60%" />}
        subheader={<Skeleton variant="text" width="40%" />}
      />

      <Stack spacing={2} sx={{ p: 3 }}>
        {[...Array(5)].map((_, index) => (
          <PlanItemSkeleton key={index} />
        ))}
      </Stack>
    </Card>
  );
}

function PlanItemSkeleton() {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Skeleton variant="circular" width={30} height={30} />

      <Box sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="40%" />
      </Box>

      <Skeleton variant="circular" width={25} height={25} />
    </Stack>
  );
}

AppTopPlan.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  loading: PropTypes.bool,
  subheader: PropTypes.string,
  tipo: PropTypes.string,
};

export default function AppTopPlan({
  title,
  subheader,
  list,
  loading = true,
  tipo = "planes",
  ...other
}) {
  if (loading) {
    return <AppTopPlanSkeleton />;
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ p: 3 }}>
        {orderBy(list, ["cantidad"], ["desc"]).map((plan, index) => (
          <PlanItem key={index} plan={plan} index={index} tipo={tipo} />
        ))}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

PlanItem.propTypes = {
  plan: PropTypes.shape({
    nombre: PropTypes.string,
    cantidad: PropTypes.number,
  }),
  index: PropTypes.number,
  tipo: PropTypes.string,
};

function PlanItem({ plan, index, tipo }) {
  const label =
    tipo !== "planes"
      ? formatAsCLP(plan.cantidad)
      : `${fNumber(plan.cantidad)} clientes`;
  const icono = tipo !== "planes" ? iconoMoneda : promedioPlanesIcono;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar
        alt={plan.nombre}
        sx={{
          color: "primary.light",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
        }}
      >
        {index === 0 ? (
          <Assignment />
        ) : index === 1 ? (
          <ListAlt />
        ) : index === 2 ? (
          <Description />
        ) : index === 3 ? (
          <Work />
        ) : (
          <Dashboard />
        )}
      </Avatar>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">
          {capitalizarPrimeraLetra(plan.nombre)}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: "flex",
            alignItems: "center",
            color: "text.secondary",
          }}
        >
          <Iconify icon={icono} width={16} sx={{ mr: 0.5 }} />
          {label}
        </Typography>
      </Box>

      <Iconify
        icon={iconoTrofeo}
        sx={{
          p: 1,
          width: 40,
          height: 40,
          borderRadius: "50%",
          color: "warning.main",
          bgcolor: (theme) => alpha(theme.palette.warning.main, 0.08),
          ...(index === 1 && {
            color: "green.main",
            bgcolor: (theme) => alpha(theme.palette.green.main, 0.08),
          }),
          ...(index === 2 && {
            color: "info.main",
            bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
          }),
          ...(index === 3 && {
            color: "error.main",
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
          ...(index === 4 && {
            color: "secondary.darker",
            bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.08),
          }),
        }}
      />
    </Stack>
  );
}
