import React, { useCallback, useEffect, useState } from "react";
// material
import { TextField, InputAdornment, Grid } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { round } from "lodash";
import { es } from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { tiposApi } from "src/services/tiposApi";
import FormikSelect from "../hook-form/FormikSelect";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

// ----------------------------------------------------------------------

export default function FormDefinirPrecio(props) {
  const { formik } = props;
  const [value, setValue] = useState(
    formik.values.fechaTerminoDesc === ""
      ? null
      : new Date(
          formik.values.fechaTerminoDesc.split("/", 3)[0],
          parseInt(formik.values.fechaTerminoDesc.split("/", 3)[1]) - 1,
          formik.values.fechaTerminoDesc.split("/", 3)[2]
        )
  );

  const { data: monedasApi = [], isLoading: cargandoTiposMoneda } =
    tiposApi.useGetTipoMonedaQuery();

  const [monedas, setMonedas] = useState([]);
  const { errors, touched, setFieldValue, getFieldProps, values } = formik;

  const manejarMonedas = useCallback(() => {
    if (!cargandoTiposMoneda) {
      const monedasFormateadas = monedasApi.map((moneda) => ({
        label: moneda.nombre,
        value: moneda.nombre,
      }));
      setMonedas(monedasFormateadas);
    }
  }, [cargandoTiposMoneda, monedasApi]);

  useEffect(() => {
    manejarMonedas();
  }, [manejarMonedas]);

  const getAdornment = (tipoMoneda) => {
    switch (tipoMoneda) {
      case "PESOS":
        return {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        };
      case "UTM":
        return {
          endAdornment: <InputAdornment position="end">UTM</InputAdornment>,
        };
      case "UF":
        return {
          endAdornment: <InputAdornment position="end">UF</InputAdornment>,
        };
      case "DOLAR":
        return {
          endAdornment: <InputAdornment position="end">Dólares</InputAdornment>,
        };
      default:
        return null;
    }
  };

  return (
    <RootStyle>
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={6}>
              <FormikSelect
                name="tipoMoneda"
                label="Tipo moneda"
                options={monedas}
                loading={cargandoTiposMoneda}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} sm={6}>
              <TextField
                fullWidth
                // autoComplete="username"
                type="precioFijoFinal"
                label="Precio Fijo"
                required
                InputProps={{ ...getAdornment(values.tipoMoneda) }}
                onChangeCapture={(e) => {
                  const { descuento } = values;
                  const precioFijoFinal = e.target.value;
                  if (precioFijoFinal >= 0 && descuento >= 0) {
                    setFieldValue(
                      "valorDesc",
                      round(
                        precioFijoFinal - precioFijoFinal * (descuento / 100),
                        2
                      )
                    );
                  }
                }}
                {...getFieldProps("valor")}
                error={Boolean(touched.valor && errors.valor)}
                helperText={touched.valor && errors.valor}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={6}>
              <TextField
                fullWidth
                // autoComplete="username"
                type="descuentoFinal"
                label="Descuento"
                disabled={parseFloat(values.valor) <= 0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChangeCapture={(e) => {
                  const { valor } = values;
                  const descuentoFinal = e.target.value;
                  if (valor >= 0 && descuentoFinal >= 0) {
                    setFieldValue(
                      "valorDesc",
                      round(valor - valor * (descuentoFinal / 100), 2)
                    );
                  }
                }}
                {...getFieldProps("descuento")}
                error={Boolean(touched.descuento && errors.descuento)}
                helperText={touched.descuento && errors.descuento}
              />
            </Grid>
            <Grid item xs={8} sm={6}>
              <TextField
                // autoComplete="username"
                disabled={true}
                type="precioFinal"
                fullWidth
                color="success"
                focused
                label="Precio Final"
                value={new Intl.NumberFormat("cl-ES").format(values.valorDesc)}
                InputProps={{ ...getAdornment(values.tipoMoneda) }}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        {values.descuento > 0 && (
          <Grid item xs={8} sm={11}>
            <LocalizationProvider
              style={{ marginTop: "10px" }}
              dateAdapter={AdapterDateFns}
              locale={es}
            >
              <DatePicker
                label="Fecha Término Descuento*"
                minDate={new Date()}
                value={value}
                onChange={(newValue) => {
                  let variable = new Date(newValue);
                  let mesActual = "";
                  if (variable.getMonth() + 1 < 10) {
                    mesActual = "0" + (variable.getMonth() + 1);
                  } else {
                    mesActual = variable.getMonth() + 1;
                  }
                  let fechaTerm =
                    variable.getFullYear() +
                    "/" +
                    mesActual +
                    "/" +
                    variable.getDate();

                  setFieldValue("fechaTerminoDesc", fechaTerm);
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
      </Grid>
    </RootStyle>
  );
}
