import { dbx } from "src/utils/urlBack_End";

/**
 * Errores personalizados para operaciones de Dropbox
 */
export class DropboxError extends Error {
  constructor(message, originalError) {
    super(message);
    this.name = "DropboxError";
    this.originalError = originalError;
  }
}

export class FileNotFoundError extends DropboxError {
  constructor(path, originalError) {
    super(`El archivo no fue encontrado: ${path}`, originalError);
    this.name = "FileNotFoundError";
    this.path = path;
  }
}

// Función para generar el hash de un buffer
// Esta función toma un ArrayBuffer (representando el contenido de un archivo) y genera un hash SHA-256.
// El hash resultante es convertido en una cadena hexadecimal para ser utilizado en la creación de un nombre único para el archivo.
const hashBuffer = async (buffer) => {
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

/**
 * Elimina un archivo de Dropbox
 * @param {string} ruta - Ruta de la carpeta donde está el archivo
 * @param {string} nombreArchivo - Nombre del archivo a eliminar
 * @returns {Promise} - Resultado de la operación de eliminación
 * @throws {FileNotFoundError} - Cuando el archivo no existe
 * @throws {DropboxError} - Para otros errores de Dropbox
 */
export const eliminarArchivoDropbox = async (ruta, nombreArchivo) => {
  const rutaCompleta = `/${ruta}${nombreArchivo}`;
  try {
    return await dbx.filesDeleteV2({ path: rutaCompleta });
  } catch (error) {
    // Manejar error de archivo no encontrado
    if (
      error?.status === 409 &&
      error?.error?.error?.[".tag"] === "path_lookup"
    ) {
      throw new FileNotFoundError(rutaCompleta, error);
    }

    // Manejar otros errores de Dropbox
    if (error?.status) {
      throw new DropboxError(
        `Error de Dropbox: ${
          error.error?.error_summary || "Error desconocido"
        }`,
        error
      );
    }

    // Errores inesperados
    throw new DropboxError("Error inesperado al eliminar archivo", error);
  }
};

// Función para subir un archivo a Dropbox
// Esta función sube un archivo a Dropbox en una ruta específica, generando un nombre único basado en un timestamp y el hash del contenido del archivo.
// También maneja la creación de un enlace compartido para el archivo subido y retorna el enlace junto con el `body` proporcionado si existe.
export const subirArchivoADropbox = async (
  archivo,
  ruta,
  body = null, // Parámetro opcional, puede contener información adicional que se retorna junto con el enlace del archivo subido
  isLoading = null, // Parámetro opcional para manejar estados de carga, como mostrar un spinner mientras se sube el archivo
  campoArchivo = "archivo", // Nombre del campo donde se almacenará la URL del archivo subido
  generarNombreAutomatico = true,
  nombreArchivo,
  mostrarExcepcion = false
) => {
  const timestamp = Date.now();
  // Obtener el hash del contenido del archivo
  const contenidoBuffer = await archivo.arrayBuffer();
  const contenidoHash = await hashBuffer(contenidoBuffer);
  const extension = archivo.name.split(".").pop();
  const nombreArchivoUnico = generarNombreAutomatico
    ? `archivo_${timestamp}_${contenidoHash}.${extension}`
    : nombreArchivo;

  try {
    const rutaCompleta = `/${ruta}/${nombreArchivoUnico}`;
    await dbx.filesUpload({
      path: rutaCompleta,
      contents: archivo,
      mode: "overwrite",
    });

    try {
      const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
        path: rutaCompleta,
      });
      const url = shareResponse.result.url;

      if (body) {
        return { ...body, [campoArchivo]: url };
      }

      return { [campoArchivo]: url, nombreArchivoUnico };
    } catch (ex) {
      if (!mostrarExcepcion) {
        const { url } = ex.error.error.shared_link_already_exists.metadata;

        if (body) {
          return { ...body, [campoArchivo]: url };
        }

        return { [campoArchivo]: url };
      }

      return ex;
    }
  } catch (error) {
    if (isLoading) isLoading(false);
    throw error;
  }
};

// Función para descargar un archivo desde Dropbox y convertirlo en un objeto File
export const descargarArchivoDesdeDropbox = async (ruta, nombreArchivo) => {
  try {
    const rutaCompleta = `/${ruta}${nombreArchivo}`;

    // Descarga el contenido del archivo desde Dropbox
    const response = await dbx.filesDownload({ path: rutaCompleta });
    const blob = response.result.fileBlob; // Obtiene el archivo como un Blob

    // Crea un objeto File a partir del Blob descargado
    const archivo = new File([blob], nombreArchivo, { type: blob.type });

    return archivo;
  } catch (error) {
    throw error;
  }
};
