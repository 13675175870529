import PropTypes from "prop-types";
import { Box, Card, CardHeader, Skeleton } from "@material-ui/core";
import Chart from "../charts/chart";
import useChart from "../charts/use-chart";

AppDispersionTareasEmpleado.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  loadingChartData: PropTypes.bool,
};

export default function AppDispersionTareasEmpleado({
  title,
  subheader,
  chartData,
  loadingChartData = true,
  ...other
}) {
  // Extraer los nombres únicos de los empleados
  const employeeNames = [
    ...new Set(chartData.flatMap((item) => item.data.map((data) => data[0]))),
  ];

  // Ordenar cada conjunto de datos según el orden de employeeNames
  const sortedChartData = chartData.map((item) => ({
    ...item,
    data: item.data
      .map((data) => [...data]) // Crear copia de cada sub-array
      .sort(
        (a, b) => employeeNames.indexOf(a[0]) - employeeNames.indexOf(b[0])
      ),
  }));

  // Extrayendo nombres de empleados y valores del gráfico de datos
  const series = sortedChartData.map((item) => ({
    name: item.name,
    data: item.data
      .filter((data) => Array.isArray(data) && data.length >= 2)
      .map((data) => ({ x: data[0], y: data[1] })),
  }));

  const chartOptions = useChart({
    chart: {
      id: "graficoDispersionTareas",
      height: 364,
      type: "scatter",
      zoom: {
        type: "xy",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: employeeNames,
      tickAmount: series && series.length > 0 ? series[0].data.length - 1 : 5,
      min: 1,
      forceNiceScale: true,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${Math.round(val)} tareas`;
        },
      },
      min: 0,
      tickAmount: series && series.length > 0 ? series[0].data.length : 5,
    },
    noData: {
      text: "No hay datos disponibles",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#888",
        fontSize: "16px",
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  });

  if (loadingChartData) {
    return (
      <Card {...other}>
        <CardHeader
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Box sx={{ mt: 3, mx: 3, mb: 3 }} dir="ltr">
          <Skeleton animation="wave" variant="rectangular" height={364} />
        </Box>
      </Card>
    );
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart
          type="scatter"
          series={series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
