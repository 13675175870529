import { Skeleton, Stack } from "@material-ui/core";
import React, { useMemo } from "react";
import FormikDatePicker from "src/components/hook-form/FormikDatePicker";
import FormikSelect from "src/components/hook-form/FormikSelect";
import FormikTextField from "src/components/hook-form/FormikTextField";
import { fechaActual } from "src/utils/formatTime";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";

const TIPOS_FACTURA = [
  {
    label: "Factura Tarea",
    value: "TAREA",
  },
  {
    label: "Factura Plan",
    value: "PLAN",
  },
];

const SeleccionFechaSucursalFactura = ({
  sucursales,
  loading,
  loadingSearch,
}) => {
  sucursales = useMemo(
    () =>
      sucursales.map((sucursal) => ({
        label: capitalizarPrimeraLetra(sucursal.refSucursal),
        value: sucursal.refSucursal,
      })),
    [sucursales]
  );

  const skeletonContent = (
    <Stack spacing={2} direction={{ xs: "column", sm: "row" }} sx={{ p: 3 }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={48}
        sx={{ borderRadius: "10px" }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={48}
        sx={{ borderRadius: "10px" }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={48}
        sx={{ borderRadius: "10px" }}
      />
    </Stack>
  );

  if (loading) {
    return skeletonContent;
  }

  return (
    <Stack spacing={2} direction={{ xs: "column", sm: "row" }} sx={{ p: 3 }}>
      {!loading && (
        <>
          <FormikTextField
            loadingSearch={loadingSearch}
            name="rutCliente"
            label="Ingrese rut cliente"
            disabled={loadingSearch}
            // onSearchClick={onSearchClick}
            // inputAdornment="search"
          />

          <FormikSelect
            name="tipo"
            label="Tipo"
            options={TIPOS_FACTURA}
            fullWidth
          />

          <FormikSelect
            name="sucursal"
            label="Sucursal"
            options={sucursales}
            fullWidth
          />

          <FormikDatePicker
            name="fechaEmision"
            label="Fecha Emisión"
            maxDate={fechaActual().format("YYYY/MM/DD")}
          />
        </>
      )}
    </Stack>
  );
};

export default React.memo(SeleccionFechaSucursalFactura);
