import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Typography, AppBar, Tabs, Tab, Container } from "@material-ui/core";
import { Alerta } from "../components/Alerta";
import { Titulo } from "../components/Titulo";
import EditarCamposEmpleado from "../components/gestionEmpleado/EditarCamposEmpleado";
import TareasEmpleado from "../components/gestionEmpleado/TareasEmpleado";
import FacturasEmpleado from "../components/gestionEmpleado/FacturasEmpleado";
import ComisionesEmpleado from "../components/gestionEmpleado/ComisionesEmpleado";
import Laboral from "../components/gestionEmpleado/gestionLaboral/Laboral";
import axios from "axios";
import { API_SERVER } from "../utils/urlBack_End";
import PlanesEmpleado from "src/components/gestionEmpleado/PlanesEmpleado";
import { useSelector } from "react-redux";
import { empleadosApi } from "src/services/empleadosApi";
import AtributosEmpleados from "src/components/gestionEmpleado/AtributosEmpleados";

export default function VerEmpleado() {
  const { id } = useParams();
  const [tareas, setTareas] = useState([]);
  const [empleado, setEmpleado] = useState();
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;
  const [value, setValue] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const isMounted = useRef(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [user.rut]);

  useEffect(() => {
    axios
      .get(`${API_SERVER}/empleados/sucursal/${sucursal}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        if (isMounted.current) {
          setEmpleado(response.data.find((emp) => emp.rut === id));
        }
      });
  }, [sucursal, id]);

  return (
    <>
      {empleado?.rut !== undefined && (
        <>
          <Container>
            <Titulo>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingRight: "1rem",
                  paddingLeft: "1rem",
                }}
                variant="h5"
              >
                Empleado: {empleado.nombre} {empleado.apellidos}
              </Typography>
            </Titulo>
            <div>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Datos" />
                  <Tab label="Atributos" />
                  <Tab label="Tareas" />
                  <Tab label="Planes" />
                  <Tab label="Facturas" />
                  <Tab label="Comisiones" />
                  <Tab label="Laboral" />
                </Tabs>
              </AppBar>
            </div>

            <EditarCamposEmpleado
              value={value}
              empleado={empleado}
              setEmpleado={setEmpleado}
              setShowAlert={setShowAlert}
              setShowErrorMessage={setShowErrorMessage}
              setMessage={setMessage}
            />

            <AtributosEmpleados
              value={value}
              empleado={empleado}
              setEmpleado={setEmpleado}
              tareas={tareas}
              setTareas={setTareas}
            />

            <TareasEmpleado
              value={value}
              empleado={empleado}
              setEmpleado={setEmpleado}
              tareas={tareas}
              setTareas={setTareas}
            />

            <PlanesEmpleado
              value={value}
              empleado={empleado}
              setShowErrorMessage={setShowErrorMessage}
              setMessage={setMessage}
            />

            <FacturasEmpleado
              value={value}
              empleado={empleado}
              setShowErrorMessage={setShowErrorMessage}
              setMessage={setMessage}
            />

            <ComisionesEmpleado
              value={value}
              empleado={empleado}
              tareas={tareas}
            />

            <Laboral value={value} empleado={empleado} vista="SUPER_ADMIN" />

            {showAlert && (
              <Alerta
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                color="success"
                message="Datos del Empleado guardados"
              />
            )}

            {showErrorMessage && (
              <Alerta
                showAlert={showErrorMessage}
                setShowAlert={setShowErrorMessage}
                setShowErrorMessage={setShowErrorMessage}
                color="error"
                message={message}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
}
