import { LoadingButton } from "@material-ui/lab";
import { useCallback, useEffect } from "react";
import { AlertaToast } from "src/components/AlertaSwall";
import AppDistribucionSaldos from "src/components/estadisticas/app-distribucion-saldos";
import AppWidgetSummary from "src/components/estadisticas/app-widget-summary";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

const ModalDetalleFacturaTareas = ({
  isOpen,
  setOpen,
  dataDivisiones,
  dataFacturado,
  cargandoDivisiones,
  cargandoFacturado,
  errorDivisiones,
  nombreTarea,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const existeErrorEnComisiones = useCallback(() => {
    if (errorDivisiones) {
      const mensaje =
        errorDivisiones && errorDivisiones.data && errorDivisiones.data.message
          ? errorDivisiones.data.message
          : "Ocurrió un error desconocido al obtener las comisiones";
      AlertaToast({
        action: mensaje,
        fullText: true,
        type: "error",
        target: document.getElementById("dialog-detalle-factura-tareas"),
      }).fire();
    }
  }, [errorDivisiones]);

  useEffect(() => {
    existeErrorEnComisiones();
  }, [existeErrorEnComisiones]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-detalle-factura-tareas"
      id="dialog-detalle-factura-tareas"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle style={styles.title} htmlFor="dialog-gav">
        {nombreTarea ? nombreTarea : "Detalle tarea"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <AppDistribucionSaldos
              title="Precio tarea"
              data={!cargandoDivisiones ? dataDivisiones : {}}
              loading={cargandoDivisiones}
              errorDetalles={errorDivisiones}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <AppWidgetSummary
              title="Facturas emitidas"
              total={
                !cargandoFacturado && dataFacturado
                  ? dataFacturado.facturado
                  : 0
              }
              type="peso"
              color="info"
              loading={cargandoFacturado}
              icon={
                <img alt="icon" src="/assets/icons/glass/facturas_icono2.svg" />
              }
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <AppWidgetSummary
              title="Recaudado facturas emitidas"
              total={
                !cargandoFacturado && dataFacturado
                  ? dataFacturado.recaudado
                  : 0
              }
              type="peso"
              color="info"
              loading={cargandoFacturado}
              icon={
                <img
                  alt="icon"
                  src="/assets/icons/glass/recaudaciones_icono.svg"
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <AppWidgetSummary
              title="Pendiente por facturar"
              total={
                !cargandoFacturado && dataFacturado
                  ? dataFacturado.pendiente
                  : 0
              }
              type="peso"
              color="info"
              loading={cargandoFacturado}
              icon={
                <img alt="icon" src="/assets/icons/glass/facturas_icono.svg" />
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error">
          Cerrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

ModalDetalleFacturaTareas.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  dataDivisiones: PropTypes.object,
  dataFacturado: PropTypes.object,
  cargandoDivisiones: PropTypes.bool,
  cargandoFacturado: PropTypes.bool,
  errorDivisiones: PropTypes.object,
  nombreTarea: PropTypes.string,
};

export default ModalDetalleFacturaTareas;
