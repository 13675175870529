import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/core/Autocomplete";

const SelectMenu = ({
  options,
  label,
  value,
  onChange,
  disabled = false,
  disableClearable = false,
  getOptionLabel = (option) =>
    typeof option === "object"
      ? `${option.tipo} (Peso: ${option.complejidad})`
      : option,
  getOptionValue = (option) =>
    typeof option === "object" ? option.id : option,
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      value={value}
      inputValue={inputValue}
      disableClearable={disableClearable}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        // Si el nuevo valor es una cadena, busca el objeto correspondiente en options
        if (typeof newValue === "string") {
          const selectedOption = options.find(
            (option) => getOptionLabel(option) === newValue
          );
          onChange(selectedOption || null);
        } else {
          onChange(newValue || null);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" />
      )}
      getOptionLabel={(option) => getOptionLabel(option)}
      isOptionEqualToValue={(option, value) =>
        getOptionValue(option) === getOptionValue(value)
      }
    />
  );
};

export default React.memo(SelectMenu);
