import Scrollbar from "../Scrollbar";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@material-ui/core";
import { fToNow } from "src/utils/formatTime";

// ----------------------------------------------------------------------

export default function AppNewCustomer({
  title,
  subheader,
  list,
  loading = false,
  ...other
}) {
  return (
    <Card sx={{ height: 1 }} {...other}>
      <CardHeader
        title={
          loading ? (
            <Skeleton animation="wave" height={20} width="80%" />
          ) : (
            title
          )
        }
        subheader={
          loading ? (
            <Skeleton animation="wave" height={10} width="40%" />
          ) : (
            subheader
          )
        }
      />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {loading
            ? Array.from({ length: 3 }).map((_, index) => (
                <NewsItemSkeleton key={index} />
              ))
            : list.map((news) => <NewsItem key={news.id} news={news} />)}
        </Stack>
      </Scrollbar>

      <Divider sx={{ borderStyle: "dashed" }} />
    </Card>
  );
}

// ----------------------------------------------------------------------

function NewsItem({ news }) {
  const { image, title, description, postedAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        alt={title}
        src={image}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Typography color="inherit" variant="subtitle2">
          {title}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>

      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
      >
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}

function NewsItemSkeleton() {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Skeleton variant="circular" width={48} height={48} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Skeleton animation="wave" height={16} width="80%" />
        <Skeleton animation="wave" height={12} width="50%" />
      </Box>

      <Skeleton animation="wave" height={12} width="10%" />
    </Stack>
  );
}
