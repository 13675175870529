import { DialogActions, Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import TextFieldComponent from "../sharedFields/TextFieldComponent";
import { LoadingButton } from "@material-ui/lab";
import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import { categoriasApi } from "src/services/categoriasApi";
import { AlertaToast } from "src/components/AlertaSwall";
import { useState } from "react";

const FormCategoria = ({ setOpen, agregar, data, statesModal }) => {
  const [crearCategoria] = categoriasApi.useCrearCategoriaMutation();
  const [editarCategoria] = categoriasApi.useEditarCategoriaMutation();
  const [crearSubcategoria] = categoriasApi.useCrearSubcategoriaMutation();
  const [editarSubcategoria] = categoriasApi.useEditarSubcategoriaMutation();
  const [loading, setLoading] = useState(false);

  const ProrrateoSchema = Yup.object().shape({
    nombre: Yup.string().required("El nombre del GAV es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: agregar ? "" : data.nombre,
    },
    validationSchema: ProrrateoSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { values, getFieldProps, touched, errors, handleSubmit, isSubmitting } =
    formik;

  const handleClose = () => {
    setOpen({
      ...statesModal,
      abrirModal: false,
      seAgregaCategoria: false,
    });
  };

  const guardarDatos = () => {
    setLoading(true);
    //Modal abierto para categorías
    if (statesModal.type === 0) {
      if (agregar) {
        const body = {
          nombre: values.nombre,
        };

        crearCategoria(body)
          .then(() => {
            AlertaToast({
              element: "Nueva categoría",
              action: "creada",
            }).fire();
            handleClose();
          })
          .catch((error) => {
            console.error(error);
            AlertaToast({
              action: "Error al crear la categoria",
              fullText: true,
              type: "error",
            }).fire();
            formik.resetForm();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const body = {
          id: data.id,
          nombre: values.nombre,
        };
        editarCategoria(body)
          .then((response) => {
            if (response.data.mensaje === "Nada para actualizar") {
              AlertaToast({
                action: "Nada para actualizar",
                fullText: true,
                type: "info",
              }).fire();
            } else {
              AlertaToast({
                action: "Categoría editada correctamente",
                fullText: true,
              }).fire();
            }

            handleClose();
          })
          .catch((error) => {
            console.error(error);
            AlertaToast({
              action: "Error al editar la categoria",
              fullText: true,
              type: "error",
            }).fire();
            formik.resetForm();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    //Modal abierto para subcategorías
    else if (statesModal.type === 1) {
      if (agregar) {
        const body = {
          nombre: values.nombre,
          refCategoria: data.id,
        };
        crearSubcategoria(body)
          .then(() => {
            AlertaToast({
              element: "Nueva subcategoría",
              action: "creada",
            }).fire();
            handleClose();
          })
          .catch((error) => {
            console.error(error);
            AlertaToast({
              action: "Error al crear la subcategoría",
              fullText: true,
              type: "error",
            }).fire();
            formik.resetForm();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const body = {
          id: data.id,
          nombre: values.nombre,
        };
        editarSubcategoria(body)
          .then((response) => {
            if (response.data.mensaje === "Nada para actualizar") {
              AlertaToast({
                action: "Nada para actualizar",
                fullText: true,
                type: "info",
              }).fire();
            } else {
              AlertaToast({
                action: "Subcategoría editada correctamente",
                fullText: true,
              }).fire();
            }
            handleClose();
          })
          .catch((error) => {
            console.error(error);
            AlertaToast({
              action: "Error al editar la subcategoría",
              fullText: true,
              type: "error",
            }).fire();
            formik.resetForm();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={1} marginBottom={1}>
          <Grid item xs={12}>
            <TextFieldComponent
              campo="nombre"
              label="Nombre"
              value={values.nombre}
              touched={touched.nombre}
              errors={errors.nombre}
              getFieldProps={getFieldProps("nombre")}
              inputAdornment="description"
            />
          </Grid>
        </Grid>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cancelar
          </LoadingButton>
          <LoadingButton
            type="submit"
            disabled={isSubmitting}
            loading={loading}
            startIcon={<Icon icon={saveFill} />}
            color="primary"
          >
            {statesModal.type === 0
              ? "Guardar categoría"
              : "Guardar subcategoría"}
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default FormCategoria;
