import React, { useState } from "react";
import { motion } from "framer-motion";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Container,
  Skeleton,
  TextField,
} from "@material-ui/core";
// components
import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { tareasApi } from "src/services/tareasApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { LoadingButton } from "@material-ui/lab";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

const ESTADOS_PERMITIDO_ACTUALIZAR = [
  "TERMINADA",
  "CANCELADO O RECHAZADO",
  "FACTURADO",
  "PENDIENTE DE FACTURACION",
];

export default function CambioEstadoTarea() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { rol } = useSelector((state) => state.auth.user);

  const [cargandoDatos, setCargandoDatos] = useState(false);

  const [responderCambioTarea] =
    tareasApi.useAddResponderCambioEstadoMutation();

  const {
    data: tarea = null,
    isSuccess: isSuccesTarea,
    isError: isErrorTarea,
  } = tareasApi.useGetCambioEstadoQuery(id);

  const cambiarEstado = () => {
    AlertaModal({
      title: "cambiar el estado",
      confirmButtonText: "Aceptar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          setCargandoDatos(true);
          responderCambioTarea({
            id: id,
            body: {
              estado: "EN PROCESO",
            },
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Estado",
                action: "cambiado",
              }).fire();
              setCargandoDatos(false);
              if (rol === "SUPER_ADMIN") {
                navigate("/dashboard/estadisticas", { replace: true });
              } else {
                navigate("/dashboard/inicio", { replace: true });
              }
            })
            .catch(() => {
              AlertaToast({
                action: "Error al responder la solicitud",
                fullText: true,
                type: "error",
              }).fire();
              setCargandoDatos(false);
            });
        }
      });
  };

  const cancelar = () => {
    AlertaModal({
      title: "cancelar la solicitud",
      confirmButtonText: "Sí, cancelar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          setCargandoDatos(true);
          responderCambioTarea({
            id: id,
            body: {
              estado: "NADA",
            },
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "Estado",
                action: "no cambiado",
              }).fire();
              setCargandoDatos(false);
              if (rol === "SUPER_ADMIN") {
                navigate("/dashboard/estadisticas", { replace: true });
              } else {
                navigate("/dashboard/inicio", { replace: true });
              }
            })
            .catch(() => {
              AlertaToast({
                action: "Error al responder la solicitud",
                fullText: true,
                type: "error",
              }).fire();
              setCargandoDatos(false);
            });
        }
      });
  };

  useEffect(() => {
    if (isSuccesTarea && tarea) {
      let estadoActualTarea = tarea.estado;
      if (
        estadoActualTarea &&
        ESTADOS_PERMITIDO_ACTUALIZAR.includes(estadoActualTarea)
      ) {
      } else {
        if (rol === "SUPER_ADMIN") {
          navigate("/dashboard/estadisticas", { replace: true });
        } else {
          navigate("/dashboard/inicio", { replace: true });
        }
      }
    } else if (isErrorTarea) {
      if (rol === "SUPER_ADMIN") {
        navigate("/dashboard/estadisticas", { replace: true });
      } else {
        navigate("/dashboard/inicio", { replace: true });
      }
    }
  }, [isErrorTarea, isSuccesTarea, navigate, rol, tarea]);

  return (
    <RootStyle title="TAXTIC">
      <Container>
        {!tarea ? (
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
              <motion.div variants={varBounceIn}>
                <Skeleton variant="rectangular" width={500} height={600} />
              </motion.div>
            </Box>
          </MotionContainer>
        ) : (
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
              <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                  Tarea: {tarea.nombre}
                </Typography>
              </motion.div>

              <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                  Rut: {tarea.refCliente}
                </Typography>
              </motion.div>

              <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                  Cliente: {tarea.nombreCliente}
                </Typography>
              </motion.div>

              <motion.div variants={varBounceIn}>
                <Typography variant="h5" paragraph color="primary">
                  Estado Actual: {tarea.estado}
                </Typography>
              </motion.div>

              <motion.div variants={varBounceIn}>
                <TextField
                  fullWidth
                  label="Nuevo Estado"
                  value={"EN PROCESO"}
                  disabled
                />
              </motion.div>

              <LoadingButton
                loading={cargandoDatos}
                style={{ marginTop: "20px", marginRight: "10px" }}
                size="large"
                variant="contained"
                onClick={cambiarEstado}
              >
                Aceptar cambio
              </LoadingButton>

              <LoadingButton
                loading={cargandoDatos}
                style={{ marginTop: "20px" }}
                size="large"
                color="error"
                variant="contained"
                onClick={cancelar}
              >
                Rechazar cambio
              </LoadingButton>
            </Box>
          </MotionContainer>
        )}
      </Container>
    </RootStyle>
  );
}
