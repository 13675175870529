import { useTheme } from "@material-ui/styles";
import { sumBy } from "lodash";
import PropTypes from "prop-types";
import useChart from "../charts/use-chart";
import { formatAsCLP } from "src/utils/formatNumber";
import {
  Box,
  Card,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  alpha,
} from "@material-ui/core";
import Chart from "../charts/chart";

AppTarjetaFacturas.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default function AppTarjetaFacturas({
  title,
  subheader,
  chart,
  handleClick = null,
  cursor = "default",
  loading = false,
  ...other
}) {
  const theme = useTheme();

  const { colors, series, options } = chart;

  const total = sumBy(series, "value");

  const chartSeries =
    (series.filter((i) => i.label === "Recaudado")[0].value / total) * 100 || 0;

  const chartColors = colors || [
    theme.palette.primary.light,
    theme.palette.primary.main,
  ];

  const chartOptions = useChart({
    legend: {
      show: false,
    },
    grid: {
      padding: { top: -32, bottom: -32 },
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [chartColors].map((colr) => [
          { offset: 0, color: colr[0] },
          { offset: 100, color: colr[1] },
        ]),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: "Facturación",
            formatter: () => formatAsCLP(total),
          },
        },
      },
    },
    ...options,
  });

  if (loading) {
    return (
      <Card>
        <CardHeader
          title={<Skeleton animation="wave" width={100} />}
          subheader={<Skeleton animation="wave" width={80} />}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <Skeleton variant="circular" width="80%" height={300} />
        </Box>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
        </Stack>
      </Card>
    );
  }

  return (
    <Card sx={{ cursor: cursor }} {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 8 }} />
      <Chart
        type="radialBar"
        series={[chartSeries]}
        options={chartOptions}
        height={310}
      />
      <Stack spacing={2} sx={{ p: 5 }}>
        {series.map((item) => (
          <Legend key={item.label} item={item} func={handleClick} />
        ))}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

Legend.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
  }),
  func: PropTypes.func,
};

function Legend({ item, func }) {
  let color = "";
  if (item.label === "Recaudado" && item.type === "plan") {
    color = "primary";
  } else if (item.label === "Recaudado" && item.type === "tarea") {
    color = "green";
  }

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ px: { xs: 1, sm: 3 }, cursor: "pointer" }}
        alignItems="center"
        spacing={1}
        onClick={() => func(item.label, item.type)}
      >
        <Box
          sx={{
            width: 16,
            height: 16,
            bgcolor: (theme) =>
              color
                ? theme.palette[color].main
                : alpha(theme.palette.grey[500], 0.16),
            borderRadius: 0.75,
          }}
        />

        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
          {item.label}
        </Typography>
      </Stack>

      <Typography variant="subtitle1">{formatAsCLP(item.value)}</Typography>
    </Stack>
  );
}
