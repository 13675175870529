import { Box, Skeleton, Stack, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import FormikSelect from "src/components/hook-form/FormikSelect";
import FormikTextField from "src/components/hook-form/FormikTextField";
import { capitalizarPrimeraLetra } from "src/utils/sharedFunctions";

const DetalleInformacionCliente = ({
  comunas,
  loading = false,
  loadingSearch,
}) => {
  comunas = useMemo(
    () =>
      comunas.map((comuna) => ({
        label: capitalizarPrimeraLetra(comuna.nombre),
        value: comuna.nombre,
      })),
    [comunas]
  );
  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Skeleton
          variant="text"
          width="15%"
          height={30}
          sx={{ color: "text.disabled", mb: 3 }}
        />
        <Stack
          direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
        </Stack>
      </Box>
    );
  } else if (loadingSearch) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
          Contribuyente:
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1 }}
          />
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
        Contribuyente:
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        spacing={2}
      >
        <FormikTextField
          name="RznSocRecep"
          size="small"
          label="Razón social*"
          InputLabelProps={{ shrink: true }}
        />
        <FormikTextField
          name="GiroRecep"
          size="small"
          label="Giro*"
          InputLabelProps={{ shrink: true }}
        />
        <FormikTextField
          name="DirRecep"
          size="small"
          label="Dirección*"
          InputLabelProps={{ shrink: true }}
        />
        <FormikSelect
          name="CmnaRecep"
          label="Comuna*"
          options={comunas}
          size="small"
          shrink={true}
          acceptNewElementsSelect={true}
          fullWidth
        />
        <FormikTextField
          name="EmailRecep"
          size="small"
          label="Correo*"
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </Box>
  );
};

export default React.memo(DetalleInformacionCliente);
